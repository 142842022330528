import MICROSERVICO from "../global/enumeradores/microServico";
import obterApi from "./api";

const api = () => obterApi(MICROSERVICO.MANAGEMENT);

export const listarOrganizarPor = () => {
  const ordernacoes = [
    {
      key: "0",
      label: "Alfabética"
    },
    {
      key: "3",
      label: "Recentes"
    }
  ];

  return ordernacoes;
};

export const listarFiltrarPor = () => {
  const filtros = [
    {
      key: true,
      label: "Ativo"
    },
    {
      key: false,
      label: "Inativo"
    }
  ];

  return filtros;
};

export const ordenacaoPorColuna = () => {
  const sortOrder = [
    {
      key: "0",
      label: "Alfabética"
    },
    {
      key: "3",
      label: "Recentes"
    }
  ];

  return sortOrder;
};

// Listar GrupoAssociativo paginados
export const ListarGrupoAssociativo = async () => {
  return api().get(`v1/GrupoAssociativo`);
};

export const ListarPaginado = async (filtro) => {
  const filtroQueryString = new URLSearchParams(filtro).toString();
  return api().get(`v1/GrupoAssociativo?${filtroQueryString}`);
};

// Mudar status
export const MudarStatus = async (id, status) => {
  return api().patch(`v1/GrupoAssociativo/${id}/habilita/${status}`);
};

// Busca Por Id
export const buscarPorId = async (id) => {
  return api().get(`v1/GrupoAssociativo/${id}`);
};

// Salvar Cliente
export const salvarGrupoAssociativo = async (id, dados) => {
  let metodo = "post";
  let url = "v1/GrupoAssociativo";
  if (id > 0) {
    metodo = "put";
    url = `${url}/${id}`;
  }
  return api()[metodo](url, dados);
};
