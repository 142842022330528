import { createTheme } from '@mui/material/styles';
import { makeStyles } from "@mui/styles";
import { Base } from "../cores";
import theme from "themes";

export const useStyles = makeStyles(() => ({
  root: {
    "& label.Mui-focused": {
      color: `${Base.GreyDim} !important`
    }
  },
  container: {
    "& .MuiFormControl-root": {
      margin: "0",
      width: "100%"
    },
    "& .MuiInputBase-root": {
      fontSize: "1rem"
    }
  }
}));

export const materialTheme = createTheme({
  palette: {
    primary: {
      main: theme.color.primary,
      contrastText: Base.White,
    },
  },
});
