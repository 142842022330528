import { Box, Card, Grid, Link, Button } from "@mui/material";

import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { montaAtualizaLog } from "servicos/utilsLogs";
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import Dynamsoft from "global/utils/dynamsoft";
import { validacoesInputs } from "global/constantes";
import enumeradorBotao from "../../../constantes/constanteParceiro";
import {
  tipoProduto,
  tipoProdutoDescricao,
  tipoProdutoLabel,
  tipoProdutoId
} from "../../../../../global/utils/tipoProdutoUtil";
import { ReactComponent as Upload } from "../../../../../assets/cotacoesGd/upload.svg";
import {
  Botao,
  InputTextoCEP,
  Loader,
  SelectArredondado,
  SelectAutocomplete
} from "../../../../../componentes";
import MaterialInputTexto from "../../../../../componentes/inputTexto/materialInput";
import MaterialInputTextoMoeda from "../../../../../componentes/inputTexto/materialInputMoeda";
import MaterialInputTelefone from "../../../../../componentes/inputTexto/materialInputTelefone";
import MaterialInputMascara from "../../../../../componentes/inputTextoMascara";
import ModalLuz from "../../../../../componentes/modalLuz";
import ModalMensagemErro from "../../../../../componentes/ModalMensagemErro";
import UploadCustomizado from "../../../../../componentes/uploadCustomizado";
import { InterfaceDTO } from "../../../../../global/dto/interfacesDto";
import { store } from "../../../../../global/redux";
import { responseFatura } from "../../../../../global/redux/modulos/log/actions";
import { RotasDTO } from "../../../../../global/rotas/rotasUrlDto";
import { validarCpfCnpj } from "../../../../../global/utils/formatCpfCnpj";
import { useQuery } from "../../../../../global/utils/useQuery";
import { usuarioPossuiFuncionalidade } from "../../../../../servicos/funcionalidadesServico";
import {
  formatarBrasileira,
  removeMaskCep,
  removeMaskCpfCnpj,
  removeMaskTelefone,
  removerAcentos,
  validacaoTrimObject,
  validarDDDs,
  validarNomeCompleto
} from "../../../../../servicos/utils";
import InputRadioGroupLuz from "../../componentes/inputRadioGroupLuz";
import SimulacaoSlider from "../../componentes/Slider";
import CotacaoGDHelper from "./helper";
import { useStyles } from "./style";
import CheckInputs from "../../componentes/CheckInputs";
import mapeamentoEtapaFluxo from "../../../constantes/constanteMapeamentoFluxo";
import ModalCamera from "../../../../../componentes/modalCamera";
import Passos from "../../../../../componentes/modalCamera/Passos";
import ModalLuzFluxo from "../../../../../componentes/modalLuzFluxo";

const Simulacao = ({
  externoIdSemFatura,
  setPassoAtual,
  setTipoCliente,
  obterCepCobertura,
  setNomeContratante,
  setEmailContratante,
  setTelefoneContratante,
  obterArvoreGerencialAltoNivel,
  setResultFatura,
  setDadosMatrizGD,
  setDadosLeadSalesForce,
  setDadosFormSimulador
}) => {
  const { register, handleSubmit, errors } = useForm({
    reValidateMode: "onSubmit"
  });

  const {
    register: registerModal,
    handleSubmit: handleSubmitModal,
    errors: errorsModal
  } = useForm({
    reValidateMode: "onSubmit"
  });

  const classes = useStyles();

  const usuario = useSelector((state) => state.usuario);
  const rotas = useSelector((state) => state.rotas);
  const { menu } = usuario;
  const { empresaSelecionada } = usuario;

  const permissaoAlterarFiltrarOfficer = usuarioPossuiFuncionalidade(
    menu,
    rotas.rotaAtual.caminho,
    RotasDTO.CotacoesGD,
    InterfaceDTO.PodeAlterarFiltrarOfficer
  );
  const editarInputs = usuarioPossuiFuncionalidade(
    menu,
    rotas.rotaAtual.caminho,
    RotasDTO.CotacoesGD,
    InterfaceDTO.PermissaoEditarCamposCotacoes
  );

  const inserirPlanoPersonalizado = usuarioPossuiFuncionalidade(
    menu,
    rotas.rotaAtual.caminho,
    RotasDTO.CotacoesGD,
    InterfaceDTO.InserirPlanoPersonalizado
  );

  const podeEditarCpfCnpj = usuarioPossuiFuncionalidade(
    menu,
    rotas.rotaAtual.caminho,
    RotasDTO.CotacoesGD,
    InterfaceDTO.PodeEditarCPFCNPJ
  );

  const podeEditarDesconto = usuarioPossuiFuncionalidade(
    menu,
    rotas.rotaAtual.caminho,
    RotasDTO.CotacoesGD,
    InterfaceDTO.PodeEditarDesconto
  );

  const permissaoContratacao = usuarioPossuiFuncionalidade(
    menu,
    rotas.rotaAtual.caminho,
    RotasDTO.CotacoesGD,
    InterfaceDTO.PermissaoContratacao
  );

  const [carregandoOfficer, setCarregandoOfficer] = useState(false);
  const [colaboradorId, setColaboradorId] = useState(null);
  const [listaOfficer, setListaOfficer] = useState([]);
  const [carregandoArquivo, setCarregandoArquivo] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [progress, setProgress] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [showLoader, setShowLoader] = useState(false);
  const [tipoClienteSelecionado, setTipoClienteSelecionado] = useState("PF");
  const [dadosFatura, setDadosFatura] = useState([]);
  const [nomeCompleto, setNomeCompleto] = useState("");
  const [email, setEmail] = useState("");
  const [telefone, setTelefone] = useState("");
  const [cep, setCep] = useState("");
  const [consumo, setConsumo] = useState("");
  const [fases, setFases] = useState("");
  const [carregandoDistribuidora, setCarregandoDistribuidora] = useState(false);
  const [listaDistribuidora, setListaDistribuidora] = useState([]);
  const [distribuidoraId, setDistribuidoraId] = useState("");
  const [listaNumerofases, setListaNumerofases] = useState([]);
  const [carregandoNumeroFases, setCarregandoNumeroFases] = useState(false);
  const [listaNivelTensao, setListaNivelTensao] = useState([]);
  const [carregandoNivelTensao, setCarregandoNivelTensao] = useState(false);
  const [nivelTensaoId, setNivelTensaoId] = useState("");
  const [carregandoCep, setCarregandoCep] = useState(false);
  const [carregandoMatriz, setCarregandoMatriz] = useState(false);
  const [valorSemGd, setValorSemGd] = useState(null);
  const [tipoPlanoSelecionado, setTipoPlanoSelecionado] = useState("PD");
  const [cupom, setCupom] = useState(null);
  const [fileNameLead, setFileName] = useState("");
  const [gdApp, setGdApp] = useState(null);
  const [error, setError] = useState(false);
  const [nomeEmpresa, setNomeEmpresa] = useState("");
  const [validacaoFatura, setValidacaoFatura] = useState(true);
  const [taxaIluminacao, setTaxaIluminacao] = useState(null);
  const [cnpjCliente, setCnpjCliente] = useState(null);
  const [municipioId, setMunicipioId] = useState(null);
  const [externoId, setExternoId] = useState(null);
  const [statusBaixaRenda, setStatusBaixaRenda] = useState(false);
  const [statusTarifaBranca, setStatusTarifaBranca] = useState(false);
  const [usuarioId, setUsuarioId] = useState(null);
  const [salesForcesId, setSalesForcesId] = useState(null);
  const [dadosCotacaoId, setDadosCotacaoId] = useState(null);
  const [numeroInstalacao, setNumeroInstalacao] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [dadosCotacaoGd, setCotacaoGd] = useState([]);
  const [exibirModalMensagem, setExibirModalMensagem] = useState(false);
  const [cpfCnpj, setCpfCnpj] = useState(null);
  const [cotacaoId, setCotacaoId] = useState(null);
  const [faturaProcessada, setFaturaProcessada] = useState(null);
  const history = useHistory();

  const { id } = useParams();
  const [statusVigencia, setStatusVigencia] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [envelopeId, setEnvelopeId] = useState(null);
  const [statusVigenciaFechado, setStatusVigenciaFechado] = useState(false);
  const [mensagemModal, setMensagemModal] = useState("");
  const [modalAcao, setModalAcao] = useState(false);
  const [validacaoInjetada, setValidacaoInjetada] = useState(false);
  const [ICMS, setICMS] = useState(null);
  const query = useQuery();
  const [carregandoCupom, setCarregandoCupom] = useState(false);
  const [mensagemModalCupom, setMensagemModalCupom] = useState("");
  const [modalAcaoCupom, setModalAcaoCupom] = useState(false);
  const [usuarioCupom, setUsuarioCupom] = useState(null);
  const [consumoHistorico, setConsumoHistorico] = useState(null);
  const [uf, setUf] = useState(null);
  const [cidade, setCidade] = useState(null);
  const [custoTotal, setCustoTotal] = useState(null);
  const [consumoMensal, setConsumoMensal] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [genero, setGenero] = useState(null);
  const [usuarioCupomMGM, setUsuarioCupomMGM] = useState(null);
  const [statusBOptante, setStatusBOptante] = useState(false);
  const [checked, setChecked] = useState(false);
  const [nivelTensao, setNivelTensao] = useState(null);
  const [cpfCnpjAntigo, setCpfCnpjAntigo] = useState(null);
  const [statusLiminar, setStatusLiminar] = useState(false);
  const [modalMensagem, setModalMensagem] = useState("");
  const [distribuidoraIdModal, setDistribuidoraIdModal] = useState(null);
  const [arquivo, setArquivo] = useState([]);
  const [exibirModalInput, setExibirModalInput] = useState(false);
  const [modalMensagemInput, setModalMensagemInput] = useState("");
  const [validacaoNaturezaJuridica, setValidacaoNaturezaJuridica] = useState(
    false
  );
  const [statusCancelada, setStatusCancelada] = useState(false);
  const [cpfCnpjInexistente, setCpfCnpjInexistente] = useState(false);
  const [parceiro, setParceiro] = useState(false);
  const [modalCamera, setModalCamera] = useState(false);
  const [passos, setPassos] = useState(false);
  const [carregouScript, setCarregouScript] = useState(false);
  const [exibirModalDesconto, setExibirModalDesconto] = useState(false);
  const [modalMensagemDesconto, setModalMensagemDesconto] = useState("");
  const [descontos, setDescontos] = useState({
    liberdadeTotal: null,
    desconto3Anos: null,
    desconto5Anos: null
  });
  const [enviarDescontos, setEnviarDescontos] = useState(false);
  const [mostrarDescontos, setMostrarDescontos] = useState(false);
  const [modalDesconto, setModalDesconto] = useState("");
  const [dadosOfficer, setDadosOfficer] = useState({});
  const [validacoesParceiros, setValidacoesParceiros] = useState({
    leituraFase: false,
    leituraSubGrupo: false,
    leituraICMS: false
  });

  const [modalCompanhas, setModalCampanhas] = useState(false);

  const habiltarEditar = useMemo(() => {
    return podeEditarCpfCnpj && checked;
  }, [podeEditarCpfCnpj, checked]);

  const [etapaFluxo, setEtapaFluxo] = useState(false);

  const editaDesconto = useMemo(() => {
    return !usuarioCupom && podeEditarDesconto && id;
  }, [podeEditarDesconto, usuarioCupom, id]);

  const permissaoContratar = useMemo(() => {
    return !permissaoContratacao && Number(distribuidoraId) === 1071;
  }, [permissaoContratacao, distribuidoraId]);

  const obterDistribuidoraPorId = async (distribuidora) => {
    if (!distribuidora) return;
    const {
      data,
      sucesso,
      mensagem
    } = await CotacaoGDHelper.obterDistribuidoraPorId(distribuidora);
    if (!sucesso) {
      CotacaoGDHelper.exibirErro(mensagem);
      setEtapaFluxo(true);
      return;
    }

    setGdApp(data?.gdApp);
  };

  useEffect(() => {
    obterDistribuidoraPorId(distribuidoraId);
  }, [distribuidoraId]);

  const onBlurCep = async (valorCep, cotacaoGd = {}, dadosFatura = {}) => {
    setCep(valorCep);
    if (valorCep.length > 7) {
      const result = await obterCepCobertura(valorCep);
      setDistribuidoraId(
        cotacaoGd?.distribuidoraId || result?.data?.idDistribuidora
      );
      setError(result?.error);
      setCarregandoCep(result?.carregandoCep);
      setMunicipioId(result?.data?.codigoMunicipioIBGE);
      setUf(
        cotacaoGd?.uf || result?.data?.uf || dadosFatura?.estadoEnderecoCliente
      );
      setCidade(
        cotacaoGd?.cidade ||
          result?.data?.cidade ||
          dadosFatura?.cidadeEnderecoCliente
      );
    }
  };

  const obterCotacoesIdRepresentantes = async (idGD, tipoCliente) => {
    if (tipoCliente === "PF") return;
    const {
      data,
      sucesso,
      mensagem
    } = await CotacaoGDHelper.obterCotacoesIdRepresentantes(idGD);
    if (!sucesso) {
      CotacaoGDHelper.exibirErro(mensagem);
      setEtapaFluxo(true);
      return;
    }

    setNomeCompleto(
      data?.representantes && data?.representantes[0]?.nomeCompleto
    );
    setNomeContratante(
      data?.representantes && data?.representantes[0]?.nomeCompleto
    );
  };

  const obterOfficerArvore = async (empresas, colaborador) => {
    setCarregandoOfficer(true);
    const {
      data,
      sucesso,
      mensagem
    } = await CotacaoGDHelper.obterOfficerArvore(
      empresas,
      setCarregandoOfficer,
      setError
    );
    if (!sucesso) {
      CotacaoGDHelper.exibirErro(mensagem);
      setEtapaFluxo(true);
      return;
    }

    if (!permissaoAlterarFiltrarOfficer) {
      const dados = data.filter((d) => d.id === colaborador);
      setListaOfficer(dados);
      return;
    }

    setListaOfficer(data);
  };

  const obterCotacoesGdIdSemFatura = async (idGd) => {
    const {
      data,
      sucesso,
      mensagem
    } = await CotacaoGDHelper.obterCotacaoGdIdSemFatura(
      idGd,
      setCarregandoMatriz
    );

    if (!sucesso) {
      CotacaoGDHelper.exibirErro(mensagem);
      setCarregandoMatriz(false);
      setEtapaFluxo(true);
      return;
    }

    if (data?.pessoaFisicaJuridica === "PJ") {
      setNomeEmpresa(data?.nomeContato);
    } else {
      setNomeCompleto(data?.nomeContato);
    }

    await onBlurCep(data?.cep, {}, {});

    setColaboradorId(data?.colaboradorId);
    setTipoClienteSelecionado(data?.pessoaFisicaJuridica);
    setNomeContratante(data?.nomeContato);
    setEmailContratante(data?.email);
    setEmail(data?.email);
    setTelefone(data?.telefone);
    setValidacaoNaturezaJuridica(data?.pessoaFisicaJuridica === "PJ");

    history.push("/cotacaogd/inicial");
  };

  const habiltarNivelTensao = useMemo(() => {
    const nivelTensao = listaNivelTensao?.find(
      (item) => item.id === Number(nivelTensaoId)
    )?.descricao;

    return (
      editarInputs ||
      nivelTensao === "B1" ||
      nivelTensao === "B3" ||
      nivelTensao === undefined
    );
  }, [editarInputs, listaNivelTensao, nivelTensao]);

  const obterCotacoesGdId = async (idGD) => {
    const { data, sucesso, mensagem } = await CotacaoGDHelper.obterCotacaoGdId(
      idGD,
      setCarregandoMatriz
    );
    if (!sucesso) {
      CotacaoGDHelper.exibirErro(mensagem);
      setCarregandoMatriz(false);
      setEtapaFluxo(true);
      setStatusCancelada(false);
      return;
    }

    const cosip = data?.cosip ? data?.cosip?.toFixed(2) : "0";
    const icms = data?.icms?.toFixed(2);

    setDadosCotacaoId(data?.id);
    setColaboradorId(data?.colaboradorId);
    setTipoClienteSelecionado(data?.pessoaFisicaJuridica);
    setTipoCliente(data?.pessoaFisicaJuridica);
    if (data?.pessoaFisicaJuridica === "PF") {
      setNomeCompleto(data?.nome);
      setNomeContratante(data?.nome);
    } else {
      setNomeEmpresa(data?.nome);
    }
    setEmailContratante(data?.email);
    setTelefoneContratante(data?.telefone);
    setEmail(data?.email);
    setTelefone(data?.telefone);
    setCep(data?.cep);
    setFases(data?.nFases);
    setNivelTensaoId(data?.nivelTensao);
    setTipoPlanoSelecionado(data?.plano === "Padrao" ? "PD" : "PS");
    setSalesForcesId(data?.preLead);
    setCnpjCliente(data?.cpfCnpj);
    setConsumo(data?.consumoKwh?.toString());
    setTaxaIluminacao(formatarBrasileira(cosip));
    setValorSemGd(data?.valorDaConta);
    setNumeroInstalacao(data?.numeroInstalacao);
    setCpfCnpj(data?.cpfCnpj);
    setFaturaProcessada(data?.faturaProcessada);
    setCotacaoGd(data);
    obterCotacoesIdRepresentantes(data?.id, data?.pessoaFisicaJuridica);
    setStatusVigencia(data?.status);
    setEnvelopeId(data?.envelopeId);
    setStatusVigenciaFechado(data?.status === "FE");
    setICMS(icms);

    await onBlurCep(data?.cep, data, {});

    setGenero(data?.genero);
    setUsuarioCupomMGM(data?.cupomMGM);
    setCpfCnpjAntigo(data?.cpfCnpj);
    setValidacaoNaturezaJuridica(data?.pessoaFisicaJuridica === "PJ");
    setStatusCancelada(data?.status === "CA");
  };

  useEffect(() => {
    const semFatura = query.get("semFatura")
      ? Boolean(query.get("semFatura"))
      : false;

    if (id && semFatura) {
      obterCotacoesGdIdSemFatura(id);
    }
    if (id && !semFatura) {
      obterCotacoesGdId(id);
    }
  }, [id]);

  const obterColaboradorId = async (colaboradorSelecionadoId) => {
    const {
      data,
      sucesso,
      mensagem
    } = await CotacaoGDHelper.obterColaboradorId(
      colaboradorSelecionadoId,
      setError
    );
    if (!sucesso) {
      if (mensagem === "403") {
        setCarregandoDistribuidora(false);
        return;
      }
      CotacaoGDHelper.exibirErro(mensagem);
      setCarregandoDistribuidora(false);
      setEtapaFluxo(true);
      return;
    }

    setCupom(data?.cupom);
    setParceiro(data?.cargoId === 5 || data?.permiteIndicarCpfCnpj === "S");
    obterArvoreGerencialAltoNivel(data);
    setDadosOfficer(data);
  };

  useEffect(() => {
    if (
      dadosOfficer?.cargoId === enumeradorBotao.Parceiro &&
      dadosFatura?.[0]?.inadimplencia
    ) {
      setValidacaoFatura(true);
    } else if (dadosFatura && dadosFatura?.[0]?.inadimplencia) {
      setValidacaoFatura(false);
    }
  }, [dadosFatura, dadosOfficer]);

  useEffect(() => {
    if (colaboradorId) {
      obterColaboradorId(colaboradorId);
    }
  }, [colaboradorId]);

  const obterDistribuidora = useCallback(async () => {
    const {
      data,
      sucesso,
      mensagem
    } = await CotacaoGDHelper.obterDistribuidora(
      setCarregandoDistribuidora,
      setError
    );

    if (!sucesso) {
      CotacaoGDHelper.exibirErro(mensagem);
      setCarregandoDistribuidora(false);
      setEtapaFluxo(true);
      return;
    }
    setListaDistribuidora(data);
  }, []);

  const obterNumerosFases = useCallback(async () => {
    const { data, sucesso, mensagem } = await CotacaoGDHelper.obterNumerosFases(
      setCarregandoNumeroFases,
      setError
    );

    if (!sucesso) {
      CotacaoGDHelper.exibirErro(mensagem);
      setCarregandoNumeroFases(false);
      setEtapaFluxo(true);
      return;
    }
    setListaNumerofases(data?.numerofases);
  }, []);

  const obterNivelTensao = useCallback(async () => {
    const { data, sucesso, mensagem } = await CotacaoGDHelper.obterNivelTensao(
      setCarregandoNivelTensao,
      setError
    );

    if (!sucesso) {
      CotacaoGDHelper.exibirErro(mensagem);
      setCarregandoNumeroFases(false);
      setEtapaFluxo(true);
      return;
    }
    setListaNivelTensao(data);
  }, []);

  const obterbaixaRendaDeltaProcessamento = async (externoIdFatura) => {
    const model = {
      externoId: externoIdFatura
    };

    const {
      data,
      sucesso,
      mensagem
    } = await CotacaoGDHelper.obterbaixaRendaDeltaProcessamento(model);
    if (!sucesso) {
      CotacaoGDHelper.exibirErro(mensagem);
      setStatusBaixaRenda(false);
      setEtapaFluxo(true);
      setStatusTarifaBranca(false);
      return;
    }

    setStatusBaixaRenda(data?.resultObject?.baixaRenda);
    setStatusTarifaBranca(data?.resultObject?.tarifaBranca);
  };

  const handleDistribuidora = (ids) => {
    switch (ids) {
      case 1251:
      case 1246:
      case 1232:
        return true;
      default:
        return false;
    }
  };

  const obterDistribuidoraCnpjOuNome = async (dados) => {
    const cnpjFormatado = removeMaskCpfCnpj(dados?.fornecedorCnpj);

    const {
      data,
      sucesso
    } = await CotacaoGDHelper.obterDistribuidoraCnpjOuNome(
      cnpjFormatado,
      setError
    );

    const faseId = listaNumerofases?.find(
      (item) =>
        removerAcentos(item?.descricao?.toLowerCase()) ===
        removerAcentos(dados?.fase?.toLowerCase())
    )?.id;
    const nivelTensaoFaturaId = listaNivelTensao?.find(
      (item) =>
        item?.descricao?.toLowerCase() === dados?.subGrupo?.toLowerCase()
    )?.id;

    const cosip = dados?.iluminacaoPublica
      ? dados?.iluminacaoPublica?.toFixed(2)
      : "0";

    const icms = dados?.tributosICMSAliquota
      ? Number(dados?.tributosICMSAliquota?.replace(",", "."))?.toFixed(2)
      : null;

    setNomeEmpresa(dados?.cliente?.nome);
    setCep(dados?.cepEnderecoCliente);
    setDistribuidoraId(data?.id);
    setConsumo(dados?.consumoForaPonta);
    setFases(faseId);
    setNivelTensaoId(nivelTensaoFaturaId);
    setTipoClienteSelecionado(dados?.cliente?.isPJ ? "PJ" : "PF");
    setGdApp(data?.gdApp);
    setTipoCliente(dados?.cliente?.isPJ ? "PJ" : "PF");
    setNomeCompleto(
      dados?.cliente?.isPF || (!dados?.cliente?.isPJ && !dados?.cliente?.isPF)
        ? dados?.cliente?.nome
        : ""
    );
    setNomeContratante(
      dados?.cliente?.isPF || (!dados?.cliente?.isPJ && !dados?.cliente?.isPF)
        ? dados?.cliente?.nome
        : ""
    );
    setTaxaIluminacao(formatarBrasileira(cosip));
    setCnpjCliente(dados?.cliente?.isPJ ? dados?.cliente?.cpf : "");
    setExternoId(dados?.externoId);
    obterbaixaRendaDeltaProcessamento(dados?.externoId);
    setNumeroInstalacao(dados?.numeroInstalacao);
    setCpfCnpj(dados?.cliente?.cpf);

    await onBlurCep(removeMaskCep(dados?.cepEnderecoCliente), {}, dados);

    const calculo = dados?.custoTotal * 0.06;

    if (dados?.multa && dados?.custoTotal === 0) {
      setValidacaoFatura(false);
    } else if (Number(dados?.multa?.replace(",", ".")) > calculo) {
      setValidacaoFatura(false);
    } else if (dados?.inadimplencia) {
      setValidacaoFatura(
        dados?.inadimplencia &&
          dadosOfficer?.cargoId === enumeradorBotao.Parceiro
      );
    } else if (dados?.injetada) {
      setValidacaoInjetada(dados?.injetada);
    } else {
      setValidacaoFatura(true);
    }
    setValorSemGd(dados?.valorMedioContaTarifa ?? dados?.custoTotal);

    setConsumoHistorico(dados?.consumoHistorico);
    setCustoTotal(dados?.custoTotal);
    setConsumoMensal(dados?.consumoForaPonta);
    if (dados?.bOptante) {
      setStatusBOptante(dados?.bOptante);
    }

    const tributosIcms =
      dados?.tributosICMSAliquota === 0 ||
      dados?.tributosICMSAliquota === "" ||
      dados?.tributosICMSAliquota === null;

    if (
      handleDistribuidora(data?.id) &&
      tributosIcms &&
      dados?.consumoForaPonta < 200
    ) {
      setICMS(12);
    } else if (
      handleDistribuidora(data?.id) &&
      tributosIcms &&
      dados?.consumoForaPonta > 200
    ) {
      setICMS(18);
    } else {
      setICMS(icms);
    }

    if (!sucesso) {
      setEtapaFluxo(true);
    }

    setNivelTensao(dados?.subGrupo?.toLowerCase());
    setCpfCnpjAntigo(dados?.cliente?.cpf);

    if (dados?.liminar) {
      setStatusLiminar(dados?.liminar);
    }
    setValidacaoNaturezaJuridica(dados?.cliente?.isPJ);

    setCpfCnpjInexistente(
      (dados?.cliente?.isPJ === false && dados?.cliente?.isPF === false) ||
        (dados?.cliente?.isPJ === null && dados?.cliente?.isPF === null)
    );
  };

  useEffect(() => {
    obterDistribuidora();
    obterNumerosFases();
    obterNivelTensao();
    setUsuarioId(Number(usuario?.usuario?.id));
  }, []);

  useEffect(() => {
    if (empresaSelecionada) setColaboradorId(usuario?.usuario?.colaboradorId);
  }, [empresaSelecionada]);

  const atualizarExternoIdDeltaProcessamento = async (
    salesForcesIdFatura,
    status,
    nomeArquivo
  ) => {
    if (!dadosCotacaoId) {
      const model = {
        externoIdOrigem: externoId,
        externoIdDestino: salesForcesIdFatura,
        nomeArquivo
      };

      const {
        sucesso,
        mensagem
      } = await CotacaoGDHelper.atualizarExternoIdDeltaProcessamento(
        model,
        setError,
        setCarregandoMatriz
      );
      if (!sucesso) {
        CotacaoGDHelper.exibirErro(mensagem);
        setCarregandoMatriz(false);
        setEtapaFluxo(true);
        return;
      }
    }

    if (
      statusBaixaRenda ||
      statusTarifaBranca ||
      validacaoInjetada ||
      statusBOptante ||
      statusLiminar
    ) {
      setPassoAtual("2contratacaoBaixaRenda");
      return;
    }

    if (status === "Reprovado") {
      setPassoAtual("2contratacaoMulta");
    } else {
      setPassoAtual("2contratacao");
    }
  };

  const formataValor = (valor) => {
    if (typeof valor === "number") return valor;
    if (!valor) return "";
    return valor
      ? parseFloat(
          valor
            .trim()
            .replace(",00", "")
            .replace("R$", "")
            .replace(".", "")
            .replace(",", ".")
            .replace(" ", "")
        )
      : valor;
  };

  const formatarValorSalvar = (valor) => {
    return valor
      ? parseFloat(
          String(valor)
            ?.replace(/[^0-9,.]/g, "")
            ?.trim()
        )
      : valor;
  };

  const updateCotacaoGdIdPrelead = async (preLead) => {
    if (!statusCancelada) return;
    const model = {
      preLead: preLead || ""
    };

    const { sucesso, mensagem } = await CotacaoGDHelper.criarCotacaoGD(
      model,
      setCarregandoMatriz,
      dadosCotacaoId
    );

    if (!sucesso) {
      CotacaoGDHelper.exibirErro(mensagem);
      setCarregandoMatriz(false);
      setEtapaFluxo(true);
    }
  };

  const faturaDigitalizada = useSelector((state) => state.log);
  const criarLeadSalesForceServicoSerasaPJ = async (
    dados,
    status,
    serasa,
    etapa = null
  ) => {
    const faseDescricao = listaNumerofases.find(
      (item) => Number(item.id) === Number(fases)
    )?.descricao;

    const model = {
      nome: nomeCompleto,
      email,
      telefone: removeMaskTelefone(telefone),
      cep: removeMaskCep(cep),
      pessoaFisicaJuridica: tipoClienteSelecionado,
      valorDaConta: Number(valorSemGd) ?? 0,
      consumoAbaixoRange: false,
      distribuidoraNaoAtendida: !gdApp,
      origemDaInformacao: "AreaLogada",
      cupom: usuarioCupomMGM || usuarioCupom || cupom,
      scoreSerasa: dados?.score ?? 0,
      numeroInstalacao: numeroInstalacao ?? null,
      dataSimulacao: window.moment().format(),
      empresaDoGrupo: process.env.REACT_APP_EMPRESA_GRUPO_API_URL,
      cidade: cidade ?? "",
      uf: uf ?? "",
      descontoOfertadoLiberdadeTotal:
        dados?.matrizDescontoConsumoCotacaosColl?.[0]?.descontoSemFidelidade,
      descontoOfertado3Anos:
        dados?.matrizDescontoConsumoCotacaosColl?.[0]?.desconto3Anos,
      descontoOfertado5Anos:
        dados?.matrizDescontoConsumoCotacaosColl?.[0]?.desconto5Anos,
      reducaoCustoSemFidelidade:
        dados?.matrizDescontoConsumoCotacaosColl?.[0]?.economiaSemFidelidade,
      reducaoCusto3Anos:
        dados?.matrizDescontoConsumoCotacaosColl?.[0]?.economia3Anos,
      reducaoCusto5Anos:
        dados?.matrizDescontoConsumoCotacaosColl?.[0]?.economia5Anos,
      consumoUtilizado: formatarValorSalvar(consumo) ?? 0,
      proprietario: Number(colaboradorId) ?? 0,
      medium: usuarioCupomMGM ? "MGM" : "",
      edicaoDesconto: dados?.edicaoDesconto === "S",
      produto: tipoProdutoDescricao(distribuidoraId),
      fase: removerAcentos(faseDescricao),
      valorIluminacaoPublica: formataValor(taxaIluminacao)
    };

    if (externoId) {
      if (serasa === "Serasa") {
        model.multaDistribuidora = true;
      } else {
        model.multaDistribuidora =
          (dadosFatura[0]?.inadimplencia ||
            Number(dadosFatura[0]?.multa) > 6) ??
          false;
      }
      model.erroLeituraFatura = dadosFatura[0]?.isErro ?? false;
      model.consumoHistorico = consumoHistorico ?? 0;
      model.consumoMensal = consumoMensal ?? 0;
      model.valorFatura = custoTotal ?? 0;
      model.baixaRenda = statusBaixaRenda;
      model.tarifaBranca = statusTarifaBranca;
      model.bOptante = statusBOptante;
    } else if (dadosCotacaoId === null) {
      model.multaDistribuidora = false;
      model.erroLeituraFatura = false;
      model.consumoHistorico = 0;
      model.consumoMensal = 0;
      model.valorFatura = 0;
      model.baixaRenda = statusBaixaRenda;
      model.tarifaBranca = statusTarifaBranca;
      model.bOptante = statusBOptante;
    }

    if (tipoClienteSelecionado === "PJ") {
      model.nomeFantasia = dadosFatura[0]?.cliente?.nome || nomeEmpresa || "";
    } else {
      model.nomeFantasia = dadosFatura[0]?.cliente?.nome || nomeCompleto || "";
    }

    if (tipoClienteSelecionado === "PJ" && cnpjCliente) {
      model.cpfCnpj = removeMaskCpfCnpj(cnpjCliente) ?? "";
    }

    if (cpfCnpj) {
      model.cpfCnpj = removeMaskCpfCnpj(cpfCnpj) ?? "";
    }

    if (validacaoInjetada) {
      etapa = mapeamentoEtapaFluxo.injetada;
    } else if (statusTarifaBranca) {
      etapa = mapeamentoEtapaFluxo.tarifaBranca;
    } else if (statusBOptante) {
      etapa = mapeamentoEtapaFluxo.bOptante;
    } else if (!validacaoFatura) {
      etapa = mapeamentoEtapaFluxo.debitos;
    } else if (statusBaixaRenda) {
      etapa = mapeamentoEtapaFluxo.baixaRenda;
    } else if (!gdApp) {
      etapa = mapeamentoEtapaFluxo.distribuidoraSimulador;
    } else if (dados?.matrizDescontoConsumoCotacaosColl?.length === 0) {
      etapa = mapeamentoEtapaFluxo.semMatriz;
    } else if (etapaFluxo) {
      etapa = mapeamentoEtapaFluxo.erroApi1;
    }

    model.etapa = etapa ?? "";

    if (
      cpfCnpjAntigo &&
      removeMaskCpfCnpj(cpfCnpj) !== removeMaskCpfCnpj(cpfCnpjAntigo)
    ) {
      model.edicaoCPFCNPJ = true;
      model.dataAlteracaoCPFCNPJ = window.moment().format();
    }

    const {
      data,
      sucesso,
      mensagem
    } = await CotacaoGDHelper.criarLeadSalesForceServico(
      validacaoTrimObject(model),
      setCarregandoMatriz,
      setError,
      salesForcesId
    );
    sessionStorage.removeItem("idDoPreLeadNoSalesforce");
    sessionStorage.setItem(
      "idDoPreLeadNoSalesforce",
      data?.data?.dados?.idDoPreLeadNoSalesforce
    );
    if (!sucesso) {
      CotacaoGDHelper.exibirErro(mensagem);
      setCarregandoMatriz(false);
      return;
    }
    montaAtualizaLog(
      {},
      faturaDigitalizada,
      "post_fatura_importarfatura",
      "idDoPreLeadNoSalesforce",
      data?.data?.dados?.idDoPreLeadNoSalesforce,
      `cotacao-gd-${process.env.REACT_APP_ENVIRONMENT}`
    );

    await updateCotacaoGdIdPrelead(
      data?.data?.dados?.idDoPreLeadNoSalesforce ?? salesForcesId
    );

    if (externoId) {
      atualizarExternoIdDeltaProcessamento(
        data?.data?.dados?.idDoPreLeadNoSalesforce ?? salesForcesId,
        status,
        fileNameLead
      );
      setDadosLeadSalesForce(data);
    } else {
      setDadosLeadSalesForce(data);
      setPassoAtual("2contratacaoMulta");
    }
  };

  const criarLeadSalesForceServicoMultaPF = async (
    dados,
    status,
    etapa = null
  ) => {
    const faseDescricao = listaNumerofases.find(
      (item) => Number(item.id) === Number(fases)
    )?.descricao;

    const model = {
      nome: nomeCompleto,
      email,
      telefone: removeMaskTelefone(telefone),
      cep: removeMaskCep(cep),
      pessoaFisicaJuridica: tipoClienteSelecionado,
      valorDaConta: Number(valorSemGd) ?? 0,
      consumoAbaixoRange: false,
      distribuidoraNaoAtendida: !gdApp,
      origemDaInformacao: "AreaLogada",
      cupom: usuarioCupomMGM || usuarioCupom || cupom,
      scoreSerasa: dados?.score ?? 0,
      numeroInstalacao: numeroInstalacao ?? null,
      dataSimulacao: window.moment().format(),
      empresaDoGrupo: process.env.REACT_APP_EMPRESA_GRUPO_API_URL,
      cidade: cidade ?? "",
      uf: uf ?? "",
      descontoOfertadoLiberdadeTotal:
        dados?.matrizDescontoConsumoCotacaosColl?.[0]?.descontoSemFidelidade,
      descontoOfertado3Anos:
        dados?.matrizDescontoConsumoCotacaosColl?.[0]?.desconto3Anos,
      descontoOfertado5Anos:
        dados?.matrizDescontoConsumoCotacaosColl?.[0]?.desconto5Anos,
      reducaoCustoSemFidelidade:
        dados?.matrizDescontoConsumoCotacaosColl?.[0]?.economiaSemFidelidade,
      reducaoCusto3Anos:
        dados?.matrizDescontoConsumoCotacaosColl?.[0]?.economia3Anos,
      reducaoCusto5Anos:
        dados?.matrizDescontoConsumoCotacaosColl?.[0]?.economia5Anos,
      consumoUtilizado: formatarValorSalvar(consumo) ?? 0,
      proprietario: Number(colaboradorId) ?? 0,
      medium: usuarioCupomMGM ? "MGM" : "",
      edicaoDesconto: dados?.edicaoDesconto === "S",
      produto: tipoProdutoDescricao(distribuidoraId),
      fase: removerAcentos(faseDescricao),
      valorIluminacaoPublica: formataValor(taxaIluminacao)
    };

    if (externoId) {
      model.multaDistribuidora =
        (dadosFatura[0]?.inadimplencia || Number(dadosFatura[0]?.multa) > 6) ??
        false;
      model.erroLeituraFatura = dadosFatura[0]?.isErro ?? false;
      model.consumoHistorico = consumoHistorico ?? 0;
      model.consumoMensal = consumoMensal ?? 0;
      model.valorFatura = custoTotal ?? 0;
      model.baixaRenda = statusBaixaRenda;
      model.tarifaBranca = statusTarifaBranca;
      model.bOptante = statusBOptante;
    } else if (dadosCotacaoId === null) {
      model.multaDistribuidora = false;
      model.erroLeituraFatura = false;
      model.consumoHistorico = 0;
      model.consumoMensal = 0;
      model.valorFatura = 0;
      model.baixaRenda = statusBaixaRenda;
      model.tarifaBranca = statusTarifaBranca;
      model.bOptante = statusBOptante;
    }

    if (tipoClienteSelecionado === "PJ") {
      model.nomeFantasia = dadosFatura[0]?.cliente?.nome || nomeEmpresa || "";
    } else {
      model.nomeFantasia = dadosFatura[0]?.cliente?.nome || nomeCompleto || "";
    }

    if (tipoClienteSelecionado === "PJ" && cnpjCliente) {
      model.cpfCnpj = removeMaskCpfCnpj(cnpjCliente) ?? "";
    }

    if (cpfCnpj) {
      model.cpfCnpj = removeMaskCpfCnpj(cpfCnpj) ?? "";
    }

    if (validacaoInjetada) {
      etapa = mapeamentoEtapaFluxo.injetada;
    } else if (statusTarifaBranca) {
      etapa = mapeamentoEtapaFluxo.tarifaBranca;
    } else if (statusBOptante) {
      etapa = mapeamentoEtapaFluxo.bOptante;
    } else if (statusBaixaRenda) {
      etapa = mapeamentoEtapaFluxo.baixaRenda;
    } else if (!validacaoFatura) {
      etapa = mapeamentoEtapaFluxo.debitos;
    } else if (!gdApp) {
      etapa = mapeamentoEtapaFluxo.distribuidoraSimulador;
    } else if (dados?.matrizDescontoConsumoCotacaosColl?.length === 0) {
      etapa = mapeamentoEtapaFluxo.semMatriz;
    } else if (etapaFluxo) {
      etapa = mapeamentoEtapaFluxo.erroApi1;
    }

    model.etapa = etapa ?? "";

    if (
      cpfCnpjAntigo &&
      removeMaskCpfCnpj(cpfCnpj) !== removeMaskCpfCnpj(cpfCnpjAntigo)
    ) {
      model.edicaoCPFCNPJ = true;
      model.dataAlteracaoCPFCNPJ = window.moment().format();
    }

    const {
      data,
      sucesso,
      mensagem
    } = await CotacaoGDHelper.criarLeadSalesForceServico(
      validacaoTrimObject(model),
      setCarregandoMatriz,
      setError,
      salesForcesId
    );
    sessionStorage.removeItem("idDoPreLeadNoSalesforce");
    sessionStorage.setItem(
      "idDoPreLeadNoSalesforce",
      data?.data?.dados?.idDoPreLeadNoSalesforce
    );
    if (!sucesso) {
      CotacaoGDHelper.exibirErro(mensagem);
      setCarregandoMatriz(false);
      return;
    }
    montaAtualizaLog(
      {},
      faturaDigitalizada,
      "post_fatura_importarfatura",
      "idDoPreLeadNoSalesforce",
      data?.data?.dados?.idDoPreLeadNoSalesforce,
      `cotacao-gd-${process.env.REACT_APP_ENVIRONMENT}`
    );

    await updateCotacaoGdIdPrelead(
      data?.data?.dados?.idDoPreLeadNoSalesforce ?? salesForcesId
    );

    if (externoId) {
      atualizarExternoIdDeltaProcessamento(
        data?.data?.dados?.idDoPreLeadNoSalesforce ?? salesForcesId,
        status,
        fileNameLead
      );
      setDadosLeadSalesForce(data);
    } else {
      setDadosLeadSalesForce(data);
      setPassoAtual("2contratacaoMulta");
    }
  };

  const criarLeadSalesForceServico = async (
    dados,
    score,
    etapa,
    dadosEdicao
  ) => {
    const decricaoDistribuidora = listaDistribuidora?.find(
      (item) => Number(item?.id) === Number(distribuidoraId)
    )?.nomeFantasia;

    const faseDescricao = listaNumerofases.find(
      (item) => Number(item.id) === Number(fases)
    )?.descricao;

    const dadosLead = {
      pessoaFisicaJuridica: tipoClienteSelecionado,
      nome: nomeCompleto,
      email,
      telefone: removeMaskTelefone(telefone),
      cep: removeMaskCep(cep),
      valorDaConta: Number(valorSemGd) ?? 0,
      consumoAbaixoRange: dados.length === 0,
      distribuidoraNaoAtendida: !gdApp,
      distribuidora: decricaoDistribuidora ?? "",
      origemDaInformacao: "AreaLogada",
      dataDaCriacaoNoSalesforce: window.moment().format(),
      cupom: usuarioCupomMGM || usuarioCupom || cupom,
      produto: tipoProdutoDescricao(distribuidoraId),
      scoreSerasa: score ?? 0,
      erroGenericoSimulacao: error,
      observacoes: "",
      faturaProcessada:
        faturaProcessada === "S" ||
        dadosFatura[0]?.status?.nome === "Processado com Sucesso",
      numeroInstalacao: numeroInstalacao ?? null,
      dataSimulacao: window.moment().format(),
      empresaDoGrupo: process.env.REACT_APP_EMPRESA_GRUPO_API_URL,
      cidade: cidade ?? "",
      uf: uf ?? "",
      consumoUtilizado: formatarValorSalvar(consumo) ?? 0,
      proprietario: Number(colaboradorId) ?? 0,
      medium: usuarioCupomMGM ? "MGM" : "",
      edicaoDesconto: dadosEdicao?.edicaoDesconto === "S",
      fase: removerAcentos(faseDescricao),
      valorIluminacaoPublica: formataValor(taxaIluminacao)
    };

    if (tipoClienteSelecionado === "PJ") {
      dadosLead.nomeFantasia =
        dadosFatura[0]?.cliente?.nome || nomeEmpresa || "";
    } else {
      dadosLead.nomeFantasia =
        dadosFatura[0]?.cliente?.nome || nomeCompleto || "";
    }

    if (externoId) {
      dadosLead.multaDistribuidora = dadosFatura[0]?.inadimplencia ?? false;
      dadosLead.erroLeituraFatura = dadosFatura[0]?.isErro ?? false;
      dadosLead.consumoHistorico = consumoHistorico ?? 0;
      dadosLead.consumoMensal = consumoMensal ?? 0;
      dadosLead.valorFatura = custoTotal ?? 0;
      dadosLead.baixaRenda = statusBaixaRenda;
      dadosLead.tarifaBranca = statusTarifaBranca;
      dadosLead.bOptante = statusBOptante;
    } else if (dadosCotacaoId === null) {
      dadosLead.multaDistribuidora = false;
      dadosLead.erroLeituraFatura = false;
      dadosLead.consumoHistorico = 0;
      dadosLead.consumoMensal = 0;
      dadosLead.valorFatura = 0;
      dadosLead.baixaRenda = statusBaixaRenda;
      dadosLead.tarifaBranca = statusTarifaBranca;
      dadosLead.bOptante = statusBOptante;
    }

    if (dados?.length > 0) {
      dadosLead.descontoOfertadoLiberdadeTotal =
        dados?.[0]?.descontoSemFidelidade;
      dadosLead.descontoOfertado3Anos = dados?.[0]?.desconto3Anos;
      dadosLead.descontoOfertado5Anos = dados?.[0]?.desconto5Anos;
      dadosLead.reducaoCustoSemFidelidade = dados?.[0]?.economiaSemFidelidade;
      dadosLead.reducaoCusto3Anos = dados?.[0]?.economia3Anos;
      dadosLead.reducaoCusto5Anos = dados?.[0]?.economia5Anos;
    }

    if (fileNameLead) {
      dadosLead.nomeDoAnexoComExtensao = fileNameLead;
      dadosLead.guidArquivoDaDigitalizacao = externoIdSemFatura;
    }

    if (tipoClienteSelecionado === "PJ" && cnpjCliente) {
      dadosLead.cpfCnpj = removeMaskCpfCnpj(cnpjCliente) ?? "";
    }

    if (cpfCnpj) {
      dadosLead.cpfCnpj = removeMaskCpfCnpj(cpfCnpj) ?? "";
    }

    if (validacaoInjetada) {
      etapa = mapeamentoEtapaFluxo.injetada;
    } else if (statusTarifaBranca) {
      etapa = mapeamentoEtapaFluxo.tarifaBranca;
    } else if (statusBOptante) {
      etapa = mapeamentoEtapaFluxo.bOptante;
    } else if (statusBaixaRenda) {
      etapa = mapeamentoEtapaFluxo.baixaRenda;
    } else if (!validacaoFatura) {
      etapa = mapeamentoEtapaFluxo.debitos;
    } else if (!gdApp) {
      etapa = mapeamentoEtapaFluxo.distribuidoraSimulador;
    } else if (dados.length === 0) {
      etapa = mapeamentoEtapaFluxo.semMatriz;
    } else if (etapaFluxo) {
      etapa = mapeamentoEtapaFluxo.erroApi1;
    }

    dadosLead.etapa = etapa ?? "";

    if (
      cpfCnpjAntigo &&
      removeMaskCpfCnpj(cpfCnpj) !== removeMaskCpfCnpj(cpfCnpjAntigo)
    ) {
      dadosLead.edicaoCPFCNPJ = true;
      dadosLead.dataAlteracaoCPFCNPJ = window.moment().format();
    }

    const {
      data,
      sucesso,
      mensagem
    } = await CotacaoGDHelper.criarLeadSalesForceServico(
      validacaoTrimObject(dadosLead),
      setCarregandoMatriz,
      setError,
      statusCancelada ? null : salesForcesId
    );

    sessionStorage.removeItem("idDoPreLeadNoSalesforce");
    sessionStorage.setItem(
      "idDoPreLeadNoSalesforce",
      dadosLead?.id ? dadosLead?.id : data?.data?.dados?.idDoPreLeadNoSalesforce
    );
    if (!sucesso) {
      CotacaoGDHelper.exibirErro(mensagem);
      setCarregandoMatriz(false);
      return;
    }

    montaAtualizaLog(
      {},
      faturaDigitalizada,
      "post_fatura_importarfatura",
      "idDoPreLeadNoSalesforce",
      dadosLead?.id
        ? dadosLead?.id
        : data?.data?.dados?.idDoPreLeadNoSalesforce,
      `cotacao-gd-${process.env.REACT_APP_ENVIRONMENT}`
    );

    if (dados.length === 0) {
      setModalAcao(true);
      setMensagemModal(
        "Não foi possível gerar os planos para os dados informados!"
      );
      return;
    }

    await updateCotacaoGdIdPrelead(
      data?.data?.dados?.idDoPreLeadNoSalesforce ?? salesForcesId
    );

    if (externoId) {
      atualizarExternoIdDeltaProcessamento(
        data?.data?.dados?.idDoPreLeadNoSalesforce ?? salesForcesId,
        "Aprovado",
        fileNameLead
      );
      setDadosLeadSalesForce(data);
    } else {
      setDadosLeadSalesForce(data);
      setPassoAtual("2contratacao");
    }
  };

  const criarCotacaoGD = async () => {
    const model = {
      numeroInstalacao,
      cpfCnpj,
      distribuidoraId,
      pessoaFisicaJuridica: tipoClienteSelecionado || "",
      preLead: salesForcesId || "",
      aprovaContrato: dadosCotacaoGd?.aprovaContrato
        ? dadosCotacaoGd?.aprovaContrato
        : "N",
      aprovaContratoMaster: dadosCotacaoGd?.aprovaContratoMaster
        ? dadosCotacaoGd?.aprovaContratoMaster
        : "N",
      leituraFase:
        dadosFatura?.[0]?.leituraFase ?? dadosCotacaoGd?.leituraFase ?? false,
      leituraSubGrupo:
        dadosFatura?.[0]?.leituraSubGrupo ??
        dadosCotacaoGd?.leituraSubgrupo ??
        false,
      leituraICMS:
        dadosFatura?.[0]?.leituraICMS ?? dadosCotacaoGd?.leituraIcms ?? false,
      inadimplencia:
        dadosFatura?.[0]?.inadimplencia ??
        dadosCotacaoGd?.inadimplencia ??
        false,
      cotacaoId: dadosCotacaoId ?? null
    };

    if (tipoClienteSelecionado === "PJ") {
      model.naoExcluiRepresentante = true;
    }

    const { sucesso, mensagem } = await CotacaoGDHelper.criarCotacaoGD(
      model,
      setCarregandoMatriz,
      null
    );

    if (!sucesso) {
      CotacaoGDHelper.exibirErro(mensagem);
      setCarregandoMatriz(false);
      setEtapaFluxo(true);
    }
  };

  const verificarConsumo = (valorConsumo) => {
    return valorConsumo > 1000;
  };

  const normalizaPlano = (matriz, valorConsumo) => {
    const {
      descontoSemFidelidade,
      desconto1Ano,
      desconto2Anos,
      desconto3Anos,
      desconto5Anos,
      economiaSemFidelidade,
      economia1Ano,
      economia2Anos,
      economia3Anos,
      economia5Anos,
      descontoNAnos,
      economiaNanos
    } = matriz;

    const planos = verificarConsumo(valorConsumo);
    const tipoProdutoExists = tipoProduto(distribuidoraId);
    const produtoLabel = tipoProdutoLabel(1261);

    const normalizaPlanos = {};

    if (tipoPlanoSelecionado === "PD") {
      normalizaPlanos.planoDesconto1 = descontoSemFidelidade;
      normalizaPlanos.periodo1 = 99;
      normalizaPlanos.planoEconomia1 = economiaSemFidelidade;
      normalizaPlanos.label1 = "LIBERDADE TOTAL";
      normalizaPlanos.rodape1 = tipoProdutoExists ? "" : "Sem fidelidade";
      normalizaPlanos.planos1 = "DescontoSemFidelidade";

      normalizaPlanos.planoDesconto2 = desconto3Anos;
      normalizaPlanos.periodo2 = 36;
      normalizaPlanos.planoEconomia2 = economia3Anos;
      normalizaPlanos.label2 = `${produtoLabel} 3 ANOS`;
      normalizaPlanos.rodape2 = tipoProdutoExists ? "" : "Fidelidade de 3 anos";
      normalizaPlanos.planos2 = "Desconto3Anos";

      normalizaPlanos.planoDesconto3 = desconto5Anos;
      normalizaPlanos.periodo3 = 60;
      normalizaPlanos.planoEconomia3 = economia5Anos;
      normalizaPlanos.label3 = `${produtoLabel} 5 ANOS`;
      normalizaPlanos.rodape3 = tipoProdutoExists ? "" : "Fidelidade de 5 anos";
      normalizaPlanos.planos3 = "Desconto5Anos";

      normalizaPlanos.showPlano1 =
        descontoSemFidelidade && economiaSemFidelidade;
      normalizaPlanos.showPlano2 = desconto3Anos && economia3Anos;
      normalizaPlanos.showPlano3 = desconto5Anos && economia5Anos;
    } else {
      normalizaPlanos.planoDesconto1 = planos
        ? descontoNAnos
        : descontoSemFidelidade;
      normalizaPlanos.periodo1 = 99;
      normalizaPlanos.planoEconomia1 = planos
        ? economiaNanos
        : economiaSemFidelidade;
      normalizaPlanos.label1 = "LIBERDADE TOTAL";
      normalizaPlanos.rodape1 = tipoProdutoExists ? "" : "Sem fidelidade";
      normalizaPlanos.planos1 = "DescontoSemFidelidade";

      normalizaPlanos.planoDesconto2 = desconto1Ano;
      normalizaPlanos.periodo2 = 12;
      normalizaPlanos.planoEconomia2 = economia1Ano;
      normalizaPlanos.label2 = `${produtoLabel} 1 ANO`;
      normalizaPlanos.rodape2 = tipoProdutoExists ? "" : "Fidelidade de 1 ano";
      normalizaPlanos.planos2 = "Desconto1Ano";

      normalizaPlanos.planoDesconto3 = desconto2Anos;
      normalizaPlanos.periodo3 = 24;
      normalizaPlanos.planoEconomia3 = economia2Anos;
      normalizaPlanos.label3 = `${produtoLabel} 2 ANOS`;
      normalizaPlanos.rodape3 = tipoProdutoExists ? "" : "Fidelidade de 2 anos";
      normalizaPlanos.planos3 = "Desconto2Anos";

      normalizaPlanos.showPlano1 =
        (descontoSemFidelidade && economiaSemFidelidade) ||
        (descontoNAnos && economiaNanos);
      normalizaPlanos.showPlano2 = desconto1Ano && economia1Ano;
      normalizaPlanos.showPlano3 = desconto2Anos && economia2Anos;
    }

    return normalizaPlanos;
  };

  const handleDadosFormulario = () => {
    const decricaoDistribuidora = listaDistribuidora?.find(
      (item) => Number(item?.id) === Number(distribuidoraId)
    )?.nomeFantasia;
    const cnpjDistribuidora = listaDistribuidora?.find(
      (item) => Number(item?.id) === Number(distribuidoraId)
    )?.cnpj;

    const modelForm = {
      colaboradorId,
      tipoPlanoSelecionado,
      tipoClienteSelecionado,
      nomeCompleto,
      email,
      telefone,
      distribuidoraId,
      consumo,
      faseId: fases,
      nivelTensaoId,
      fileNameLead,
      decricaoDistribuidora,
      cnpjDistribuidora,
      nomeEmpresa,
      cupom: usuarioCupomMGM || usuarioCupom || cupom,
      cnpjCliente,
      usuarioId,
      taxaIluminacao: formataValor(taxaIluminacao),
      valorSemGd,
      cep,
      icms: formatarValorSalvar(ICMS),
      cpfCnpj,
      cupomMGM: usuarioCupomMGM ?? "",
      parceiro,
      usuarioCupom: usuarioCupom ?? ""
    };

    setDadosFormSimulador(validacaoTrimObject(modelForm));
  };

  const descontoGdBaixa = (data, consumoAtual, analiseCredito) => {
    if (data?.matrizDescontoConsumoCotacaosColl?.length > 0) {
      const planos = normalizaPlano(
        data?.matrizDescontoConsumoCotacaosColl[0],
        consumoAtual
      );
      planos.score = data?.score;
      planos.matrizId = data?.matrizDescontoConsumoCotacaosColl[0]?.id;
      planos.edicaoDesconto = data?.edicaoDesconto;
      setDadosMatrizGD(planos);

      if (tipoClienteSelecionado === "PF") {
        if (validacaoFatura) {
          criarLeadSalesForceServico(
            data?.matrizDescontoConsumoCotacaosColl,
            data?.score,
            mapeamentoEtapaFluxo.planosGerado,
            data
          );
        } else {
          criarLeadSalesForceServicoMultaPF(
            data,
            "Reprovado",
            mapeamentoEtapaFluxo.debitos
          );
        }
      }

      if (tipoClienteSelecionado === "PJ") {
        if (validacaoFatura && data?.nomeStatusSerasa === "Aprovado") {
          criarLeadSalesForceServico(
            data?.matrizDescontoConsumoCotacaosColl,
            data?.score,
            mapeamentoEtapaFluxo.planosGerado,
            data
          );
        } else if (!validacaoFatura && data?.nomeStatusSerasa === "Aprovado") {
          criarLeadSalesForceServicoSerasaPJ(
            data,
            "Reprovado",
            "semSerasa",
            mapeamentoEtapaFluxo.debitos
          );
        } else if (
          !validacaoFatura ||
          data?.nomeStatusSerasa === "Reprovado" ||
          data?.nomeStatusSerasa === null
        ) {
          criarLeadSalesForceServicoSerasaPJ(
            data,
            "Reprovado",
            "Serasa",
            mapeamentoEtapaFluxo.serasa
          );
        }
      }
    } else {
      criarLeadSalesForceServico(
        data?.matrizDescontoConsumoCotacaosColl,
        data?.score
      );
    }
  };

  const obterMatrizDescontoGdBaixa = async (dados, consumoAtual) => {
    const {
      data,
      sucesso,
      mensagem
    } = await CotacaoGDHelper.obterMatrizDescontoGdBaixa(
      dados,
      setCarregandoMatriz,
      setError
    );

    if (!sucesso) {
      CotacaoGDHelper.exibirErro(mensagem);
      setCarregandoMatriz(false);
      setEtapaFluxo(true);
      return;
    }

    if (dadosCotacaoId && statusVigencia !== "FE") criarCotacaoGD();

    data.edicaoDesconto = "N";

    descontoGdBaixa(data, consumoAtual);
  };

  useEffect(() => {
    if (empresaSelecionada && colaboradorId) {
      obterOfficerArvore(empresaSelecionada, colaboradorId);
    }
  }, [empresaSelecionada, colaboradorId]);

  const enviarFormulario = async () => {
    const faseDescricao = listaNumerofases.find(
      (item) => Number(item.id) === Number(fases)
    )?.descricao;
    const nivelDescricao = listaNivelTensao.find(
      (item) => Number(item.id) === Number(nivelTensaoId)
    )?.descricao;

    const data = {
      idDistribuidora: distribuidoraId,
      NumeroFases: removerAcentos(faseDescricao),
      ClasseTarifaria: removerAcentos(nivelDescricao),
      Consumo: consumo,
      PessoaFisicaJuridica: tipoClienteSelecionado,
      ValorSemGD: valorSemGd || 0,
      Cosip: formataValor(taxaIluminacao),
      Icms: formatarValorSalvar(ICMS) ?? null,
      cupom: usuarioCupomMGM || usuarioCupom || cupom,
      Cpf_Cnpj: removeMaskCpfCnpj(cpfCnpj),
      cep: removeMaskCep(cep) || "",
      produtoId: tipoProdutoId(distribuidoraId)
    };

    if (municipioId) {
      data.idMunicipio = municipioId;
    }

    obterMatrizDescontoGdBaixa(validacaoTrimObject(data), consumo);
    handleDadosFormulario();
  };

  const onAddUpload = async (valor, modal = true) => {
    if (!empresaSelecionada) {
      setModalAcao(true);
      setMensagemModal("Selecione uma empresa!");
      return;
    }

    if (!distribuidoraId && !distribuidoraIdModal && modal) {
      setArquivo(valor);
      setExibirModalInput(true);
      setModalMensagemInput("Por favor selecione a distribuidora");
      return;
    }

    const fileBytes = valor[0]?.data?.split(",")[1];
    const fileName = removerAcentos(valor[0]?.file?.name);
    const empresaCodigo = "32";
    const colaborador = colaboradorId ? Number(colaboradorId) : null;

    setFileName(fileName);

    const {
      data,
      sucesso,
      mensagem
    } = await CotacaoGDHelper.digitalizacaoFaturaDeltaProcessamentoOcr(
      fileBytes,
      fileName,
      externoIdSemFatura,
      empresaCodigo,
      setCarregandoArquivo,
      setError,
      colaborador,
      distribuidoraIdModal || distribuidoraId
    );

    if (!sucesso) {
      setModalAcao(true);
      setMensagemModal(mensagem);
      setFileName("");
      setExternoId(null);
      setFileName("");
      setCarregandoArquivo(false);
      setValidacaoFatura(true);
      setNumeroInstalacao(null);
      setCpfCnpj(null);
      setValidacaoInjetada(false);
      setStatusBOptante(false);
      setEtapaFluxo(true);
      setNivelTensao(null);
      setCpfCnpjAntigo(null);
      setStatusLiminar(false);
      setDistribuidoraIdModal(null);
      return;
    }
    // eslint-disable-next-line no-shadow
    const faturaDigitalizada = data;
    delete faturaDigitalizada?.resultObject[0]?.faturaHistoricos;
    delete faturaDigitalizada?.resultObject[0]?.faturaItens;
    delete faturaDigitalizada?.resultObject[0]?.faturaLote;
    store.dispatch(responseFatura(faturaDigitalizada));

    setResultFatura(data?.resultObject);
    setDadosFatura(data?.resultObject);
    obterDistribuidoraCnpjOuNome(data?.resultObject[0]);
  };

  const onDropRejected = (valor) => {
    const arquivo = valor[0];

    setModalAcao(true);
    setMensagemModal(`Tipo do arquivo ${arquivo.name} não é permitido!`);
  };

  const onChangeTipoCliente = async (valor) => {
    setTipoClienteSelecionado(valor);
    setTipoCliente(valor);
  };

  const obterCotacaoGd = async (dados, enviarForm = "formCotacao") => {
    if (!cpfCnpjInexistente) {
      if (validacaoNaturezaJuridica && tipoClienteSelecionado === "PF") {
        setModalAcao(true);
        setMensagemModal("Verifique a Natureza Jurídica.");
        return;
      }
    }

    if (!numeroInstalacao || !cpfCnpj || !distribuidoraId) {
      if (enviarForm === "formDesconto") edicaoDesconto(dados);
      else enviarFormulario(dados);
      return;
    }

    const model = {
      numeroInstalacao,
      cpfCnpj,
      distribuidoraId
    };

    if (dadosCotacaoId) {
      if (enviarForm === "formDesconto") edicaoDesconto(dados);
      else enviarFormulario(dados);
      return;
    }

    const { data, sucesso, mensagem } = await CotacaoGDHelper.obterCotacaoGd(
      model,
      setCarregandoMatriz
    );

    if (!sucesso) {
      if (mensagem === "CotacaoGDEncontrada") {
        if (enviarForm === "formDesconto") edicaoDesconto(dados);
        else enviarFormulario(dados);
      }
      setEtapaFluxo(true);
    } else {
      setExibirModalMensagem(true);
      setModalMensagem("Cotação já cadastrada. Deseja editar?");
      setCotacaoId(data);
    }
  };

  const handleEditarCotacaoGd = () => {
    if (cotacaoId) history.push(`${RotasDTO.CotacoesGD}/${cotacaoId}`);
    obterCotacoesGdId(cotacaoId);
    setExibirModalMensagem(false);
  };

  const aoEnviarFormulario = (
    dados = {},
    enviarSemDesconto = "comDesconto"
  ) => {
    if (
      !usuarioCupom &&
      !usuarioCupomMGM &&
      dadosOfficer?.cargoId !== enumeradorBotao.Parceiro &&
      modalDesconto !== "desconto" &&
      enviarSemDesconto !== "semDesconto"
    ) {
      setModalCampanhas(true);
      return;
    }

    if (permissaoContratar) {
      setModalAcao(true);
      setMensagemModal(
        "Não é possível realizar a venda para a distribuidora Light via esse canal."
      );
      return;
    }

    const nivelDescricao = listaNivelTensao.find(
      (item) => Number(item.id) === Number(nivelTensaoId)
    )?.descricao;

    if (nivelDescricao !== "B1" && nivelDescricao !== "B3") {
      setModalAcao(true);
      setMensagemModal("Verifique o nível de tensão!");
      return;
    }

    if (modalDesconto === "desconto") {
      setExibirModalDesconto(true);
      setModalMensagemDesconto("Deseja editar desconto?");
      setModalDesconto("");
      return;
    }
    obterCotacaoGd(dados);
  };

  const handlePassoDois = () => {
    const decricaoDistribuidora = listaDistribuidora?.find(
      (item) => Number(item?.id) === Number(distribuidoraId)
    )?.nomeFantasia;
    const cnpjDistribuidora = listaDistribuidora?.find(
      (item) => Number(item?.id) === Number(distribuidoraId)
    )?.cnpj;

    const modelForm = {
      colaboradorId,
      tipoPlanoSelecionado,
      tipoClienteSelecionado,
      nomeCompleto,
      email,
      telefone,
      distribuidoraId,
      consumo,
      faseId: fases,
      nivelTensaoId,
      fileNameLead,
      decricaoDistribuidora,
      cnpjDistribuidora,
      nomeEmpresa,
      cupom: usuarioCupomMGM || usuarioCupom || cupom,
      cnpjCliente,
      usuarioId,
      taxaIluminacao: formataValor(taxaIluminacao),
      valorSemGd,
      cep,
      icms: formatarValorSalvar(ICMS),
      cpfCnpj,
      parceiro
    };

    setDadosFormSimulador(validacaoTrimObject(modelForm));
    setPassoAtual("2contratacao");
  };

  const obterPorCupom = async (cupomUser) => {
    const { sucesso, mensagem } = await CotacaoGDHelper.obterPorCupom(
      cupomUser?.trim(),
      setCarregandoCupom
    );

    if (!sucesso) {
      if (mensagem === "204") {
        setModalAcaoCupom(true);
        setCarregandoMatriz(false);
        setMensagemModalCupom("Cupom nâo encontrado, tente outro!");
      }
      setModalAcaoCupom(true);
      setMensagemModalCupom("Cupom nâo encontrado, tente outro!");
      setCarregandoMatriz(false);
      setEtapaFluxo(true);
    }
  };

  const obterClienteCupomIndicadorPorCodigo = async (cupomUserMGM) => {
    const model = {
      codigo: cupomUserMGM?.trim()
    };
    const {
      sucesso,
      mensagem
    } = await CotacaoGDHelper.obterClienteCupomIndicadorPorCodigo(
      model,
      setCarregandoCupom
    );

    if (!sucesso) {
      if (mensagem === "204") {
        setModalAcaoCupom(true);
        setCarregandoMatriz(false);
        setMensagemModalCupom("Cupom nâo encontrado, tente outro!");
      }
      setModalAcaoCupom(true);
      setMensagemModalCupom("Cupom nâo encontrado, tente outro!");
      setCarregandoMatriz(false);
      setEtapaFluxo(true);
    }
  };

  const handleFileSelect = (file) => {
    setCarregouScript(true);
    if (file) {
      return new Promise(() => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
          const arquivos = [{ data: reader.result.toString(), file }];
          onAddUpload(arquivos);
        };
      });
    }
  };

  useEffect(() => {
    if (!usuarioCupom) return;
    obterPorCupom(usuarioCupom);
  }, [usuarioCupom]);

  useEffect(() => {
    if (!usuarioCupomMGM) return;
    obterClienteCupomIndicadorPorCodigo(usuarioCupomMGM);
  }, [usuarioCupomMGM]);

  const handleMensagemModalCupom = (mensagem) => {
    return (
      <Box component="div" fontWeight="700" textAlign="center">
        {mensagem}
      </Box>
    );
  };

  const handleMensagemModal = (mensagem) => {
    return (
      <Box component="div" fontWeight="700" textAlign="center">
        {mensagem}
      </Box>
    );
  };

  const aoEnviarFormularioDesconto = (dados) => {
    if (permissaoContratar) {
      setModalAcao(true);
      setMensagemModal(
        "Não é possível realizar a venda para a distribuidora Light via esse canal."
      );
      return;
    }

    setDescontos({ ...dados });
    obterCotacaoGd(dados, "formDesconto");
  };

  const obterSerasaReduzido = async (dadosMatriz) => {
    const dadosEditMatriz = {
      matrizDescontoConsumoCotacaosColl: [
        {
          id: dadosMatriz?.planosGeradosModel?.[0]?.matrizGdId,
          descontoSemFidelidade:
            dadosMatriz?.planosGeradosModel?.[0]?.percentualDesconto,
          desconto1Ano: null,
          desconto2Anos: null,
          desconto3Anos:
            dadosMatriz?.planosGeradosModel?.[1]?.percentualDesconto,
          desconto5Anos:
            dadosMatriz?.planosGeradosModel?.[2]?.percentualDesconto,
          descontoNAnos: null,
          contaSemGD: null,
          pessoaFisicaJuridica: tipoClienteSelecionado,
          economiaSemFidelidade:
            dadosMatriz?.planosGeradosModel?.[0]?.economiaAnual,
          economia1Ano: null,
          economia2Anos: null,
          economia3Anos: dadosMatriz?.planosGeradosModel?.[1]?.economiaAnual,
          economia5Anos: dadosMatriz?.planosGeradosModel?.[2]?.economiaAnual,
          economiaNanos: null,
          icms: null
        }
      ]
    };

    dadosEditMatriz.edicaoDesconto = "S";

    if (tipoClienteSelecionado === "PJ") {
      const model = {
        cnpj: removeMaskCpfCnpj(cnpjCliente)
      };

      const {
        data,
        sucesso,
        mensagem
      } = await CotacaoGDHelper.obterSerasaReduzido(model, setCarregandoMatriz);

      dadosEditMatriz.status = data?.status;
      dadosEditMatriz.nomeStatusSerasa = data?.nomeStatusSerasa;
      dadosEditMatriz.probabilidadeInadimplencia =
        data?.probabilidadeInadimplencia;
      dadosEditMatriz.score = data?.score;

      if (!sucesso) {
        if (mensagem === "400") {
          descontoGdBaixa(dadosEditMatriz, consumo);
          handleDadosFormulario();
          setExibirModalDesconto(false);
        }

        setCarregandoMatriz(false);
        setExibirModalDesconto(false);
        CotacaoGDHelper.exibirErro(mensagem);
        return;
      }
    }

    descontoGdBaixa(dadosEditMatriz, consumo);
    handleDadosFormulario();
    setExibirModalDesconto(false);
  };

  const edicaoDesconto = async (dados) => {
    const model = [
      {
        descontoSemFidelidade: formataValor(dados?.liberdadeTotal),
        valorSemGD: valorSemGd || 0,
        cotacaoSelecionada: "N",
        cotacaoGdId: Number(id) || 0,
        tipoPlanoCotacaoId: 14,
        produtoId: tipoProdutoId(distribuidoraId)
      },
      {
        desconto3Anos: formataValor(dados?.desconto3Anos),
        valorSemGD: valorSemGd || 0,
        cotacaoSelecionada: "N",
        cotacaoGdId: Number(id) || 0,
        tipoPlanoCotacaoId: 12,
        produtoId: tipoProdutoId(distribuidoraId)
      },
      {
        desconto5Anos: formataValor(dados?.desconto5Anos),
        valorSemGD: valorSemGd || 0,
        cotacaoSelecionada: "N",
        cotacaoGdId: Number(id) || 0,
        tipoPlanoCotacaoId: 13,
        produtoId: tipoProdutoId(distribuidoraId)
      }
    ];

    const { data, sucesso, mensagem } = await CotacaoGDHelper.edicaoDesconto(
      model,
      setCarregandoMatriz
    );

    if (!sucesso) {
      CotacaoGDHelper.exibirErro(mensagem);
      setCarregandoMatriz(false);
      setEtapaFluxo(true);
      setModalDesconto(false);
      return;
    }

    obterSerasaReduzido(data);
  };

  useEffect(() => {
    if (envelopeId) return;
    if (
      usuario?.usuario?.cargoId !== enumeradorBotao.Parceiro &&
      id &&
      dadosFatura.length === 0 &&
      editarInputs
    ) {
      return;
    }

    if (dadosOfficer?.cargoId === enumeradorBotao.Parceiro) {
      setValidacoesParceiros({
        leituraFase:
          dadosFatura?.[0]?.leituraFase ?? dadosCotacaoGd?.leituraFase,
        leituraSubGrupo:
          dadosFatura?.[0]?.leituraSubGrupo ?? dadosCotacaoGd?.leituraSubgrupo,
        leituraICMS:
          dadosFatura?.[0]?.leituraICMS ?? dadosCotacaoGd?.leituraIcms
      });
    } else {
      setValidacoesParceiros({
        leituraFase: !editarInputs,
        leituraSubGrupo: !editarInputs,
        leituraICMS: !editarInputs
      });
    }
  }, [dadosCotacaoGd, dadosOfficer, dadosFatura, editarInputs]);

  const handleMensagemCampanhas = () => {
    return (
      <>
        <Box
          fontSize="24px"
          fontWeight="700"
          className={classes.tituloModal}
          pb={1}
        >
          Deseja simular sem cupom?
        </Box>
        <Box
          component="div"
          fontWeight="400"
          lineHeight="16.9px"
          fontSize="14px"
        >
          Os campos de
          <Box component="span" fontWeight="700" px="3px">
            cupom
          </Box>
          e
          <Box component="span" fontWeight="700" px="3px">
            cupom MGM
          </Box>
          não estão preenchidos. Deseja continuar com a simulação mesmo assim?
        </Box>
      </>
    );
  };

  return (
    <>
      <ModalMensagemErro
        item={modalAcaoCupom}
        titulo=""
        mensagem={handleMensagemModalCupom(mensagemModalCupom)}
        onCancelar={() => {
          setModalAcaoCupom(false);
          setMensagemModalCupom("");
          setUsuarioCupom(null);
          setUsuarioCupomMGM(null);
        }}
        onConfirmar={() => {}}
        texto=""
        footer={false}
      />
      <ModalMensagemErro
        item={modalAcao}
        titulo="Algo deu errado"
        mensagem={handleMensagemModal(mensagemModal)}
        onCancelar={() => {
          setModalAcao(false);
          setMensagemModal("");
          setModalCampanhas(false);
        }}
        onConfirmar={() => {}}
        texto="Tentar novamente"
      />
      <ModalLuz
        item={exibirModalMensagem}
        mensagem={handleMensagemModal(modalMensagem)}
        onCancelar={() => {
          setExibirModalMensagem(false);
          setModalMensagem("");
          setModalCampanhas(false);
        }}
        onConfirmar={handleEditarCotacaoGd}
      />

      <ModalLuzFluxo
        item={modalCompanhas}
        mensagem={handleMensagemCampanhas()}
        textbtn1="Simular sem cupom"
        textbtn2="Inserir cupom"
        onCancelar={() => {
          enviarFormulario();
        }}
        onConfirmar={() => {
          setModalCampanhas(false);
        }}
        onCancelarHeader={() => {
          setModalCampanhas(false);
        }}
      />

      <ModalLuz
        item={exibirModalDesconto}
        mensagem={handleMensagemModal(modalMensagemDesconto)}
        onCancelar={() => {
          setExibirModalDesconto(false);
          setModalMensagemDesconto("");
          setEnviarDescontos(false);
        }}
        onConfirmar={() => {
          setEnviarDescontos(true);
        }}
        header
        footer={false}
      >
        <form
          className="needs-validation"
          onSubmit={handleSubmitModal(aoEnviarFormularioDesconto)}
        >
          {!mostrarDescontos && (
            <Box textAlign="center" mb={4}>
              <Box
                component="span"
                id="buttonNao"
                name="buttonNao"
                value="buttonNao"
                bgcolor="#fff"
                fontSize="16px"
                fontWeight="700"
                borderRadius="24px"
                color="#FA3EB1"
                border="1px solid #FA3EB1"
                onClick={() => {
                  aoEnviarFormulario({}, "semDesconto");
                  setExibirModalDesconto(false);
                  setEnviarDescontos(false);
                }}
                py={1}
                px={6}
                mr="10px"
                sx={{ cursor: "pointer" }}
              >
                Não
              </Box>
              <Box
                component="span"
                id="buttonSim"
                name="buttonSim"
                value="buttonSim"
                fontSize="16px"
                fontWeight="700"
                borderRadius="24px"
                border="none"
                color="#fff"
                bgcolor="#FA3EB1"
                onClick={() => {
                  setMostrarDescontos(true);
                }}
                py={1}
                px={6}
                ml="10px"
                sx={{ cursor: "pointer" }}
              >
                Sim
              </Box>
            </Box>
          )}
          {mostrarDescontos && (
            <>
              <Box mb={4} className={classes.container}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} sm={12} xl={12}>
                    <MaterialInputMascara
                      type="text"
                      id="liberdadeTotal"
                      name="liberdadeTotal"
                      label="LIBERDADE TOTAL"
                      defaultValue={descontos?.liberdadeTotal ?? ""}
                      renderIconShowHide={false}
                      ref={registerModal({
                        required: enviarDescontos
                          ? "LIBERDADE TOTAL é obrigatório!"
                          : false
                      })}
                      errors={errorsModal}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} xl={12}>
                    <MaterialInputMascara
                      type="text"
                      id="desconto3Anos"
                      name="desconto3Anos"
                      label="DESCONTO 3 ANOS"
                      defaultValue={descontos?.desconto3Anos ?? ""}
                      renderIconShowHide={false}
                      ref={registerModal({
                        required: enviarDescontos
                          ? "DESCONTO 3 ANOS é obrigatório!"
                          : false
                      })}
                      errors={errorsModal}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} xl={12}>
                    <MaterialInputMascara
                      type="text"
                      id="desconto5Anos"
                      name="desconto5Anos"
                      label="DESCONTO 5 ANOS"
                      defaultValue={descontos?.desconto5Anos ?? ""}
                      renderIconShowHide={false}
                      ref={registerModal({
                        required: enviarDescontos
                          ? "DESCONTO 5 ANOS é obrigatório!"
                          : false
                      })}
                      errors={errorsModal}
                    />
                  </Grid>
                </Grid>
                <Box display="flex" justifyContent="center" mt={6}>
                  <Box
                    component="button"
                    id="buttonSim"
                    name="buttonSim"
                    value="buttonSim"
                    fontSize="16px"
                    fontWeight="700"
                    borderRadius="24px"
                    border="none"
                    color="#fff"
                    bgcolor="#FA3EB1"
                    onClick={() => {
                      setEnviarDescontos(true);
                    }}
                    py={1}
                    px={16}
                    type="submit"
                  >
                    Enviar
                  </Box>
                </Box>
              </Box>
            </>
          )}
        </form>
      </ModalLuz>

      {passos && (
        <Passos
          fechar={() => setPassos(false)}
          onConfirmar={() => {
            setPassos(false);
            setModalCamera(true);
          }}
        />
      )}
      {modalCamera && (
        <ModalCamera
          onVoltar={() => {
            setPassos(true);
            setModalCamera(false);
            setCarregouScript(true);
          }}
          fechar={() => {
            setModalCamera(false);
            setCarregouScript(true);
          }}
          onCapturar={(imagem) => handleFileSelect(imagem)}
          carregouScript={carregouScript}
        />
      )}
      <ModalMensagemErro
        item={exibirModalInput}
        mensagem={handleMensagemModal(modalMensagemInput)}
        onCancelar={() => {
          setExibirModalInput(false);
          setModalMensagemInput("");
          setDistribuidoraIdModal(null);
        }}
        header
        enviarFatura
        textoEnviarFatura="Enviar fatura"
        disabled={distribuidoraIdModal}
        onConfirmar={() => {
          onAddUpload(arquivo);
          setExibirModalInput(false);
        }}
        footer={false}
      >
        <Box mb={3}>
          <Box textAlign="center" mb={3}>
            <Loader loading={carregandoDistribuidora}>
              <SelectAutocomplete
                disableClearable
                disableOpenOnFocus
                id="distribuidoraIdModal"
                name="distribuidoraIdModal"
                options={listaDistribuidora}
                label="Distribuidora"
                getOptionLabel={(option) =>
                  option?.nomeFantasia ?? distribuidoraIdModal
                }
                onChange={(e, item) => {
                  setDistribuidoraIdModal(item?.id);
                }}
                value={distribuidoraIdModal}
                allowClear
                disabled={statusVigenciaFechado}
                valueName="nomeFantasia"
              />
            </Loader>
          </Box>

          <Box
            style={{ color: "#FA3EB1", cursor: "pointer" }}
            component={Link}
            fontSize="16px"
            fontWeight="700"
            underline="always"
            onClick={() => {
              onAddUpload(arquivo, false);
              setExibirModalInput(false);
            }}
          >
            Não sei a distribuidora
          </Box>
        </Box>
      </ModalMensagemErro>
      <Loader loading={carregandoMatriz}>
        <form
          id="hook-form"
          className="needs-validation"
          onSubmit={handleSubmit(aoEnviarFormulario)}
        >
          <Card className={classes.cardCadastro}>
            <Box
              component={Grid}
              container
              spacing={2}
              py={6}
              px={4}
              className={classes.container}
            >
              <Box component={Grid} item xs={12} lg={6}>
                <Box component={Grid} container spacing={2}>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Loader loading={carregandoOfficer}>
                      <SelectArredondado
                        id="colaboradorId"
                        name="colaboradorId"
                        valueKey="id"
                        valueName="nomeColaborador"
                        dataSource={listaOfficer}
                        label="Officer"
                        value={colaboradorId}
                        onChange={(e) => setColaboradorId(e.target.value)}
                        placeholder="Officer"
                        allowClear={!statusVigenciaFechado}
                        ref={register(
                          { name: "colaboradorId" },
                          {
                            required: !colaboradorId
                              ? validacoesInputs.obrigatorio
                              : false
                          }
                        )}
                        errors={errors}
                        disabled={statusVigenciaFechado}
                      />
                    </Loader>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Box
                      bgcolor="rgba(250, 62, 177, 0.08)"
                      border="2px dashed #FA3EB1"
                      borderRadius="8px"
                    >
                      <UploadCustomizado
                        dropAreaText="Anexar fatura"
                        loader={carregandoArquivo}
                        onAdd={onAddUpload}
                        progressValue={progress}
                        showLoader={showLoader}
                        onDropRejected={onDropRejected}
                        acceptedFiles={["image/*", "application/pdf"]}
                        icon={Upload}
                        disabled={statusVigenciaFechado}
                      />
                    </Box>
                  </Grid>
                  <Grid item sm={6} xs={12} sx={{ display: { md: "none" } }}>
                    <Loader loading={carregandoArquivo} className="w-auto">
                      <Box
                        bgcolor="rgba(250, 62, 177, 0.08)"
                        border="2px dashed #FA3EB1"
                        borderRadius="8px"
                      >
                        <Button
                          type="button"
                          className={classes.botaoCamera}
                          onClick={() => {
                            if (!empresaSelecionada) {
                              setModalAcao(true);
                              setMensagemModal("Selecione uma empresa!");
                              return;
                            }
                            setPassos(true);
                          }}
                        >
                          <CameraAltOutlinedIcon />
                          Envie uma foto da sua fatura
                        </Button>
                      </Box>
                    </Loader>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <InputRadioGroupLuz
                      type="text"
                      id="perfilCliente"
                      name="perfilCliente"
                      label="Perfil"
                      renderIconShowHide={false}
                      customValue={tipoClienteSelecionado}
                      onChange={(valor) =>
                        onChangeTipoCliente(valor?.target?.value)
                      }
                      ref={register({
                        required: validacoesInputs.obrigatorio
                      })}
                      errors={errors}
                      itens={CotacaoGDHelper.obterTipoCliente()}
                      disabled={statusVigenciaFechado}
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12} className="mt-3">
                    <Loader loading={carregandoCupom}>
                      <Grid container spacing={2}>
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                          <MaterialInputTexto
                            type="text"
                            id="cupom"
                            name="cupom"
                            label="Cupom (opcional)"
                            defaultValue={usuarioCupom ?? ""}
                            permiteValorBranco
                            renderIconShowHide={false}
                            onBlur={(e) => {
                              setUsuarioCupom(e.target.value);
                            }}
                            ref={register({
                              maxLength: {
                                value: 200,
                                message: "Quantidade máxima de 200 caracteres."
                              }
                            })}
                            errors={errors}
                            allowClear
                            disabled={!!usuarioCupomMGM}
                          />
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                          <MaterialInputTexto
                            type="text"
                            id="cupom"
                            name="cupom"
                            label="Cupom MGM (opcional)"
                            defaultValue={usuarioCupomMGM ?? ""}
                            permiteValorBranco
                            renderIconShowHide={false}
                            onBlur={(e) => {
                              setUsuarioCupomMGM(e.target.value);
                            }}
                            ref={register({
                              maxLength: {
                                value: 200,
                                message: "Quantidade máxima de 200 caracteres."
                              }
                            })}
                            errors={errors}
                            allowClear
                            disabled={!!usuarioCupom}
                          />
                        </Grid>
                      </Grid>
                    </Loader>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <MaterialInputTexto
                      type="text"
                      id="nomeCompleto"
                      name="nomeCompleto"
                      label="Nome completo"
                      defaultValue={nomeCompleto ?? ""}
                      permiteValorBranco
                      renderIconShowHide={false}
                      onBlur={(e) => {
                        setNomeCompleto(e.target.value);
                        setNomeContratante(e.target.value);
                      }}
                      ref={register({
                        required: validacoesInputs.obrigatorio,
                        maxLength: {
                          value: 200,
                          message: "Quantidade máxima de 200 caracteres."
                        },
                        validate: (value) =>
                          validarNomeCompleto(value, "Nome Completo")
                      })}
                      errors={errors}
                      allowClear
                      disabled={statusVigenciaFechado}
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <MaterialInputTexto
                      type="text"
                      id="email"
                      name="email"
                      label="E-mail"
                      defaultValue={email ?? ""}
                      permiteValorBranco
                      renderIconShowHide={false}
                      onBlur={(e) => {
                        setEmail(e.target.value);
                        setEmailContratante(e.target.value);
                      }}
                      ref={register({
                        required: validacoesInputs.obrigatorio,
                        pattern: {
                          value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                          message: validacoesInputs.email
                        },
                        maxLength: {
                          value: 100,
                          message: "Quantidade máxima de 100 caracteres."
                        }
                      })}
                      errors={errors}
                      disabled={statusVigenciaFechado}
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <MaterialInputTelefone
                      type="text"
                      id="telefone"
                      name="telefone"
                      label="Telefone"
                      defaultValue={removeMaskTelefone(telefone) ?? ""}
                      permiteValorBranco
                      renderIconShowHide={false}
                      onBlur={(e) => {
                        setTelefone(e.target.value);
                        setTelefoneContratante(e.target.value);
                      }}
                      ref={register({
                        required: validacoesInputs.obrigatorio,
                        minLength: {
                          value: 18,
                          message: "Quantidade mínima de 12 caracteres."
                        },
                        validate: (value) => validarDDDs(value)
                      })}
                      errors={errors}
                      disabled={statusVigenciaFechado}
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <InputTextoCEP
                      id="Cep"
                      name="Cep"
                      label="CEP"
                      carregandoEndereco={carregandoCep}
                      onBlurCep={onBlurCep}
                      defaultValue={cep ?? ""}
                      register={register}
                      errors={errors}
                      disabled={statusVigenciaFechado}
                    />
                  </Grid>

                  {podeEditarCpfCnpj && !statusVigenciaFechado && (
                    <Grid item xs={12}>
                      <CheckInputs setChecked={setChecked} checked={checked} />
                    </Grid>
                  )}

                  {tipoClienteSelecionado === "PJ" && (
                    <>
                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <MaterialInputMascara
                          type="text"
                          id="cnpj"
                          name="cnpj"
                          label="CNPJ"
                          mask="##.###.###/####-##"
                          renderIconShowHide={false}
                          defaultValue={cnpjCliente ?? ""}
                          onInputChange={(value) => {
                            setCnpjCliente(value);
                            setCpfCnpj(value);
                          }}
                          ref={register({
                            required: validacoesInputs.obrigatorio,
                            maxLength: {
                              value: 18,
                              message: "Quantidade máxima de 18 caracteres."
                            },
                            minLength: {
                              value: 18,
                              message: "Quantidade mínima de 18 caracteres."
                            },
                            validate: (value) =>
                              validarCpfCnpj(value) || validacoesInputs.cnpj
                          })}
                          errors={errors}
                          disabled={!habiltarEditar || statusVigenciaFechado}
                        />
                      </Grid>

                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <MaterialInputTexto
                          type="text"
                          id="nomeEmpresa"
                          name="nomeEmpresa"
                          label="Nome da empresa (opcional)"
                          defaultValue={nomeEmpresa ?? ""}
                          permiteValorBranco
                          renderIconShowHide={false}
                          onBlur={(e) => {
                            setNomeEmpresa(e.target.value);
                          }}
                          ref={register({
                            maxLength: {
                              value: 200,
                              message: "Quantidade máxima de 200 caracteres."
                            }
                          })}
                          errors={errors}
                          allowClear
                          disabled={!editarInputs || statusVigenciaFechado}
                        />
                      </Grid>
                    </>
                  )}

                  {tipoClienteSelecionado === "PF" && (
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <MaterialInputMascara
                        type="text"
                        id="cpf"
                        name="cpf"
                        label="CPF"
                        mask="###.###.###-##"
                        renderIconShowHide={false}
                        defaultValue={cpfCnpj ?? ""}
                        onInputChange={(value) => {
                          setCpfCnpj(value);
                        }}
                        ref={register({
                          validate: (value) =>
                            value
                              ? validarCpfCnpj(value) || validacoesInputs.cpf
                              : true
                        })}
                        errors={errors}
                        disabled={!habiltarEditar || statusVigenciaFechado}
                      />
                    </Grid>
                  )}

                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Loader loading={carregandoDistribuidora}>
                      <SelectAutocomplete
                        disableClearable
                        disableOpenOnFocus
                        id="distribuidoraId"
                        name="distribuidoraId"
                        options={listaDistribuidora}
                        label="Distribuidora"
                        getOptionLabel={(option) =>
                          option?.nomeFantasia ?? distribuidoraId
                        }
                        onChange={(e, item) => {
                          setDistribuidoraId(item?.id);
                        }}
                        value={distribuidoraId}
                        ref={register(
                          { name: "distribuidoraId" },
                          {
                            required: !distribuidoraId
                              ? validacoesInputs.obrigatorio
                              : false
                          }
                        )}
                        error={errors}
                        allowClear
                        disabled={statusVigenciaFechado}
                        valueName="nomeFantasia"
                      />
                    </Loader>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <MaterialInputMascara
                      type="text"
                      id="consumo"
                      name="consumo"
                      sufixo=" KWH"
                      label="Consumo"
                      defaultValue={consumo ?? ""}
                      renderIconShowHide={false}
                      onInputChange={(valor) =>
                        setConsumo(formatarValorSalvar(valor))
                      }
                      ref={register(
                        { name: "consumo" },
                        {
                          required: !consumo
                            ? validacoesInputs.obrigatorio
                            : false
                        }
                      )}
                      errors={errors}
                      disabled={!editarInputs || statusVigenciaFechado}
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Loader loading={carregandoNumeroFases}>
                      <SelectArredondado
                        id="numerofasesId"
                        name="numerofasesId"
                        valueKey="id"
                        valueName="descricao"
                        dataSource={listaNumerofases}
                        label="Nº de fases"
                        value={fases ?? ""}
                        onChange={(e) => setFases(e.target.value)}
                        ref={register(
                          { name: "numerofasesId" },
                          {
                            required: !fases
                              ? validacoesInputs.obrigatorio
                              : false
                          }
                        )}
                        placeholder="Nº de fases"
                        allowClear={
                          !(
                            !editarInputs ||
                            statusVigenciaFechado ||
                            validacoesParceiros?.leituraFase
                          )
                        }
                        errors={errors}
                        disabled={
                          statusVigenciaFechado ||
                          (validacoesParceiros?.leituraFase ?? !editarInputs)
                        }
                      />
                    </Loader>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Loader loading={carregandoNivelTensao}>
                      <SelectArredondado
                        id="nivelTensao"
                        name="nivelTensao"
                        valueKey="id"
                        marginBottom="0"
                        valueName="descricao"
                        dataSource={listaNivelTensao}
                        label="Nível de tensão"
                        value={nivelTensaoId}
                        onChange={(e) => setNivelTensaoId(e.target.value)}
                        placeholder="Nível de tensão"
                        allowClear={
                          validacoesParceiros?.leituraSubGrupo
                            ? !validacoesParceiros?.leituraSubGrupo
                            : habiltarNivelTensao || statusVigenciaFechado
                        }
                        errors={errors}
                        ref={register(
                          { name: "nivelTensao" },
                          {
                            required: !nivelTensaoId
                              ? validacoesInputs.obrigatorio
                              : false
                          }
                        )}
                        disabled={
                          !habiltarNivelTensao ||
                          statusVigenciaFechado ||
                          validacoesParceiros?.leituraSubGrupo
                        }
                      />
                    </Loader>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <MaterialInputTextoMoeda
                      type="text"
                      id="taxaIluminacao"
                      name="taxaIluminacao"
                      label="Iluminação Pública"
                      defaultValue={taxaIluminacao ?? ""}
                      renderIconShowHide={false}
                      onInput={(e) => {
                        setTaxaIluminacao(formatarBrasileira(e.target.value));
                      }}
                      ref={register({
                        required: !taxaIluminacao
                          ? validacoesInputs.obrigatorio
                          : false
                      })}
                      errors={errors}
                      disabled={!editarInputs || statusVigenciaFechado}
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <MaterialInputMascara
                      type="text"
                      id="valorSemGd"
                      name="valorSemGd"
                      prefixo="R$ "
                      sufixo=""
                      label="Valor médio da conta de energia"
                      defaultValue={valorSemGd ?? ""}
                      renderIconShowHide={false}
                      onInputChange={(valor) =>
                        setValorSemGd(formatarValorSalvar(valor))
                      }
                      ref={register({
                        required: !valorSemGd
                          ? validacoesInputs.obrigatorio
                          : false
                      })}
                      errors={errors}
                      disabled={!editarInputs || statusVigenciaFechado}
                    />
                  </Grid>

                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <MaterialInputMascara
                      type="text"
                      id="ICMS"
                      name="icms"
                      label="Icms (%)"
                      sufixo=" %"
                      defaultValue={ICMS ?? ""}
                      permiteValorBranco
                      renderIconShowHide={false}
                      onBlur={(e) => setICMS(formataValor(e?.target?.value))}
                      ref={register({
                        required: validacoesInputs.obrigatorio,
                        validate: (value) =>
                          formataValor(value) < 100 || validacoesInputs.icms
                      })}
                      errors={errors}
                      allowClear
                      disabled={
                        statusVigenciaFechado ||
                        (validacoesParceiros?.leituraICMS ?? !editarInputs)
                      }
                    />
                  </Grid>

                  {inserirPlanoPersonalizado && (
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <InputRadioGroupLuz
                        type="text"
                        id="plano"
                        name="plano"
                        label="Plano"
                        renderIconShowHide={false}
                        customValue={tipoPlanoSelecionado}
                        onChange={(valor) =>
                          setTipoPlanoSelecionado(valor?.target?.value)
                        }
                        ref={register({
                          required: validacoesInputs.obrigatorio
                        })}
                        errors={errors}
                        itens={CotacaoGDHelper.obterTipoPlano()}
                        disabled={statusVigenciaFechado}
                      />
                    </Grid>
                  )}
                </Box>
              </Box>
              <Box component={Grid} item xs={12} lg={6}>
                <SimulacaoSlider
                  distribuidoraId={distribuidoraId}
                  tipoClienteSelecionado={tipoClienteSelecionado}
                  nome={nomeCompleto}
                  email={email}
                  telefone={telefone}
                  cep={cep}
                  valorDaConta={Number(valorSemGd)}
                  gdApp={gdApp}
                  cupom={usuarioCupom?.trim() ?? cupom}
                  colaboradorId={colaboradorId}
                  municipioId={municipioId}
                />
              </Box>
            </Box>
          </Card>

          {statusVigenciaFechado ? (
            <Box component={Grid} container mt={3}>
              <Grid item xs={12} lg={3} mt={2}>
                <Botao
                  onClick={handlePassoDois}
                  id="cotacaoGdId"
                  label="Próximo"
                />
              </Grid>
            </Box>
          ) : (
            <Box component={Grid} container mt={3} spacing={2}>
              <Grid item xs={12} lg={3} mt={2}>
                <Botao id="cotacaoGdId" label="Simular" type="submit" />
              </Grid>
              {editaDesconto && (
                <Grid item xs={12} lg={3} mt={2}>
                  <Botao
                    id="edicaoDescontoId"
                    label="Edição de descontos"
                    onClick={() => {
                      setModalDesconto("desconto");
                      setMostrarDescontos(false);
                    }}
                    type="submit"
                  />
                </Grid>
              )}
            </Box>
          )}
        </form>
      </Loader>
      <Dynamsoft />
    </>
  );
};
Simulacao.propTypes = {
  externoIdSemFatura: PropTypes.string,
  setPassoAtual: PropTypes.oneOfType([PropTypes.func]),
  setTipoCliente: PropTypes.oneOfType([PropTypes.func]),
  obterCepCobertura: PropTypes.oneOfType([PropTypes.func]),
  setNomeContratante: PropTypes.oneOfType([PropTypes.func]),
  setEmailContratante: PropTypes.oneOfType([PropTypes.func]),
  setTelefoneContratante: PropTypes.oneOfType([PropTypes.func]),
  obterArvoreGerencialAltoNivel: PropTypes.oneOfType([PropTypes.func]),
  setResultFatura: PropTypes.oneOfType([PropTypes.func]),
  setDadosMatrizGD: PropTypes.oneOfType([PropTypes.func]),
  setDadosLeadSalesForce: PropTypes.oneOfType([PropTypes.func]),
  setDadosFormSimulador: PropTypes.oneOfType([PropTypes.func])
};
Simulacao.defaultProps = {
  externoIdSemFatura: "",
  setPassoAtual: () => {},
  setTipoCliente: () => {},
  obterCepCobertura: () => {},
  setNomeContratante: () => {},
  setEmailContratante: () => {},
  setTelefoneContratante: () => {},
  obterArvoreGerencialAltoNivel: () => {},
  setResultFatura: () => {},
  setDadosMatrizGD: () => {},
  setDadosLeadSalesForce: () => {},
  setDadosFormSimulador: () => {}
};

export default Simulacao;
