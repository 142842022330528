import { makeStyles } from '@mui/styles';
import styled from "styled-components";
import { Base } from "../../../../componentes/cores";
import _theme from 'themes';
import { createTheme } from "@mui/material/styles";

const theme = createTheme();

export const useStyles = makeStyles(() => ({
  topo: {
    padding: theme.spacing(2),
    "& > div + div": {
      paddingLeft: theme.spacing(2)
    },
    "& img": {
      maxWidth: "100%"
    }
  },
  info: {
    fontSize: "18px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "-0.1px"
  },
  dados: {
    fontFamily: _theme.font.family,
    fontSize: "50px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",

    "& span": {
      fontSize: "30px"
    }
  },
  subCard: {
    margin: "10px",
    padding: "10px",
    borderRadius: "4px",
    backgroundColor: _theme.color.bgMedidor,
    height: "295px",
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      display: "none"
    }
  },
  status: {
    width: "12px",
    height: "12px",
    background: "#32da66",
    borderRadius: "50px"
  },
  trace: {
    borderBottomColor: "#fff",
    borderBottomStyle: "groove",
    borderBottomWidth: "6px"
  }
}));

export const Icone = styled.img`
  max-height: 65px;
  object-fit: cover;
`;

export const BoxOption = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
`;

export const Option = styled.button`
  background: transparent;
  border: none;
  width: 100%;
  padding: 6px 16px;
  margin: 5px 0;
  color: #ffffff;

  &.active {
    background: ${_theme.color.bgFaixaMedidores};
    color: ${_theme.color.textoBarra};
  }

  &:focus {
    outline: none;
  }
`;

export const Status = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50px;

  &.green {
    background: ${_theme.color.ledVerde};
  }

  &.red {
    background: ${_theme.color.ledVermelho};
  }
`;
