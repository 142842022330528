import styled from "styled-components";
import { makeStyles } from '@mui/styles';
import { Base } from "../../cores";
import theme from "themes";
const BorderColor = (tipo, childs) => {
  if (tipo === 3 && !childs) return Base.White;
  if (tipo === 4 && !childs) return Base.BlueViolet;
  if (tipo === 5 && !childs) return Base.BlueDodger;
  return theme.color.fourthBackgroud;
};

const BorderWidth = (tipo, childs) => {
  if (tipo === 3 && !childs) return "6px";
  if (tipo === 4 && !childs) return "6px";
  if (tipo === 5 && !childs) return "6px";
  return 0;
};

const BackgroundColor = (level) => {
  const cores = [theme.color.treeViewBackground1, theme.color.treeViewBackground2, theme.color.treeViewBackground2, theme.color.treeViewBackground2];
  return cores[level];
};

export const useStyles = makeStyles({
  container: {
    height: "54px !important",
    margin: "0 !important",
    opacity: (props) => (props.ativo ? 1 : 0.5),
    width: "100% !important"
  },
  gridItem: {
    marginRight: "2px !important",
    padding: "0 !important",
    "&:last-child": {
      marginRight: "0 !important"
    }
  },
  labelItem: {
    borderLeftColor: (props) => BorderColor(props.tipo, props.childs),
    borderLeftWidth: (props) => BorderWidth(props.tipo, props.childs),
    borderLeftStyle: "solid !important",
    position: "relative !important",
    width: "100% !important"
  },
  treeItem: {
    backgroundColor: (props) => BackgroundColor(props.level),
    color: `${theme.color.secondaryText} !important`,
    height: "54px !important"
  },
  wrapper: {
    alignItems: "center !important",
    display: "flex !important",
    height: "100% !important"
  },
  start: {
    alignItems: "flex-start !important"
  },
  center: {
    justifyContent: "center !important"
  },
  left: {
    justifyContent: "start !important",
    paddingLeft: "20px !important"
  },
  right: {
    justifyContent: "flex-end !important",
    paddingRight: "20px !important"
  },
  squareWidth: {
    flexBasis: "100% !important",
    maxWidth: "54px !important",
    width: "54px !important",
    "& svg": {
      "pointer-events": "all !important"
    }
  },
  deletar: {
    "pointer-events": "all !important"
  },
  checkbox: {
    "pointer-events": "all !important",
    "&.MuiCheckbox-root": {
      color: `${theme.color.textoBarra} !important`
    },
    "&.Mui-checked": {
      color: `${theme.color.checkButonColor} !important`
    }
  },
  descricao: {
    fontSize: "14px !important"
  },
  plataformasItem: {
    marginRight: "20px !important",
    position: "absolute !important",
    right: "0 !important"
  }
});

export const Item = styled.div``;
