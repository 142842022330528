import MICROSERVICO from "../global/enumeradores/microServico";
import obterApi from "./api";
import { baixarArquivo } from "./arquivoServico";

const api = () => obterApi(MICROSERVICO.MANAGEMENT);

export const baixarArquivoFatura = async (cotacaoId, nomeArquivo) => {
  var url = `v1/ClienteArquivoFatura/download/${cotacaoId}/nomearquivo/${nomeArquivo}`;

  return baixarArquivo(api, url, nomeArquivo);
};
