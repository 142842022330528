import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { useHistory } from "react-router-dom";
import { useForm, useWatch } from "react-hook-form";
import { useSelector } from "react-redux";

// Componentes
import { Grid } from "@mui/material";
import TuneIcon from "@mui/icons-material/Tune";
import TabelaPaginada from "../../../componentes/tabelaPaginada";
import Coluna from "../../../componentes/tabelaPaginada/colunas/coluna";
import ColunaCampoDropDown from "../../../componentes/tabelaPaginada/colunas/colunaCampoDropDown";
import MaterialInputTexto from "../../../componentes/inputTexto/materialInput";
import BotaoFiltroOrdenacao from "../../../componentes/botaoFiltroOrdenar";
import ListagemCotacaoHelper from "./helper";
import SelectArredondado from "../../../componentes/selectArredondado";
import ColunaComponentePersonalizado from "../../../componentes/tabelaPaginada/colunas/colunaComponentePersonalizado";
import Loader from "../../../componentes/loader";
import { Base } from "../../../componentes/cores";
import ResultadoPaginadoDto from "../../../componentes/tabelaPaginada/resultadoPaginadoDto";
import ColunaMultiplosBotoes from "./componentes/colunaMultiplosBotoes";
import ColunaInputDescontos from "./componentes/colunaInputDescontos";

// Redux
import { store } from "../../../global/redux";
import { alertaExibir } from "../../../global/redux/modulos/alertas/actions";

// DTO
import FiltroDto from "./dto/filtroDto";
import ListagemCotacaoDto from "./dto/listagemCotacaoDto";
import FiltroAdicionalTabela from "./dto/filtroAdicionalTabela";
import { RotasDTO } from "../../../global/rotas/rotasUrlDto";
import enumeradorBotao from "./enumeradores/enumeradorBotao";
import { InterfaceDTO } from "global/dto/interfacesDto";

// Constantes
import statusCotacao from "../constantes/constanteStatusCotacao";

// Styles
import { useStyles } from "./style";

// Serviços
import {
  listarFiltrarPor,
  listarOrganizarPor,
  updatePlano,
  ordenacaoPorColuna,
  descontoSelecionado
} from "../../../servicos/listagemCotacoesServico";
import { listarColaboradoresPorArvoreGerencial } from "../../../servicos/colaboradoresServico";
import { buscarPorId } from "../../../servicos/usuariosServico";

// Útils
import {
  desabilitarCliente,
  desabilitarEmpresa,
  desabilitarUnidade
} from "../../../global/redux/modulos/usuario/actions";
import { mdiFileKey } from "@mdi/js";
import MaterialInputBusca from "componentes/inputBusca";
import CustomLoader from "componentes/customLoader";

const CotacaoEditarDesconto = () => {
  const [ordemSelecionada, setOrdemSelecionada] = useState();
  const [filtroSelecionado, setFiltroSelecionado] = useState();
  const [ordemColunaSelecionada, setOrdemColunaSelecionada] = useState();
  const [filtroAdicionalTabela, setFiltroAdicionalTabela] = useState({});
  const [filtroPersonalizado, setFiltroPersonalizado] = useState({});
  const [valorOrdenacao, setValorOrdenacao] = useState();

  const history = useHistory();
  const classes = useStyles();

  const refTabela = useRef(null);

  const { register, control } = useForm({
    reValidateMode: "onSubmit"
  });

  const textoBusca = useWatch({
    control,
    name: "textoBusca",
    defaultValue: ""
  });

  useEffect(() => {
    store.dispatch(desabilitarCliente(true));
    store.dispatch(desabilitarUnidade(true));

    return () => {
      store.dispatch(desabilitarEmpresa(false));
      store.dispatch(desabilitarCliente(false));
      store.dispatch(desabilitarUnidade(false));
    };
  }, []);

  const [listaOfficer, setListaOfficer] = useState([]);
  const [carregandoOfficer, setCarregandoOfficer] = useState(false);

  const obterOfficer = async (empresa) => {
    try {
      setCarregandoOfficer(true);
      const lista = await listarColaboradoresPorArvoreGerencial(empresa ?? 0);
      if (lista?.status === 200 && lista?.data) {
        setListaOfficer(
          lista?.data
            ?.concat({
              id: -1,
              nomeColaborador: "Todos"
            })
            .sort((x, y) => x.id - y.id)
        );
        setColaboradorId(-1);
      }
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
    } finally {
      setCarregandoOfficer(false);
    }
  };

  const obterDadosColaborador = async (idUsuario) => {
    try {
      setCarregandoOfficer(true);
      const dados = await buscarPorId(idUsuario);
      if (dados?.data) {
        setListaOfficer([
          {
            id: dados?.data?.usuario?.colaboradorId,
            nomeColaborador: dados?.data?.usuario?.nome
          }
        ]);
      }
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
    } finally {
      setCarregandoOfficer(false);
    }
  };

  const [colaboradorId, setColaboradorId] = useState();

  const onChangeSelecionarOfficer = (event) => {
    setColaboradorId(event);
  };

  useEffect(() => {
    if (colaboradorId) {
      setFiltroPersonalizado({
        ...(colaboradorId > -1 && { colaboradorId: colaboradorId })
      });
    }
  }, [colaboradorId]);

  const rotas = useSelector((state) => state.rotas);
  const usuario = useSelector((state) => state.usuario);
  const { menu } = usuario;

  const filtroOfficer = useMemo(() => {
    const rota = Object.keys(RotasDTO).find(
      (key) => RotasDTO[key] === history?.location?.pathname
    );

    if (menu?.length && rota) {
      const funcionalidade = menu.find((item) =>
        item.functionality.funcionalidadeColecao.find(
          (funcionalidade) => funcionalidade.caminho === rota
        )
      );

      if (!funcionalidade) return false;

      if (funcionalidade?.functionality?.funcionalidadeColecao?.length) {
        const segmento = funcionalidade?.functionality?.funcionalidadeColecao.find(
          (item) => RotasDTO[item.caminho] === RotasDTO.Cotacoes
        );

        if (!segmento) return false;

        if (segmento?.funcionalidadeColecao?.length) {
          const filtro = segmento?.funcionalidadeColecao.find(
            (item) =>
              InterfaceDTO[item.caminho] ===
              InterfaceDTO.PodeAlterarFiltrarOfficer
          );

          return Boolean(filtro);
        }
      }
    }
  }, [menu, history]);

  const { empresaSelecionada } = usuario;

  useEffect(() => {
    if (filtroOfficer) obterOfficer(empresaSelecionada);
    else if (usuario?.usuario?.id) obterDadosColaborador(usuario?.usuario?.id);
  }, [filtroOfficer, empresaSelecionada]);

  useEffect(() => {
    if (listaOfficer?.length && listaOfficer?.length === 1)
      setColaboradorId(usuario?.usuario?.colaboradorId);
  }, [listaOfficer]);

  const onChangeSelecionarPlano = async (event, params) => {
    const idPlano = Number(event);

    const idPlanoSelecionado =
      params?.plano?.find((p) => Number(p.id) === Number(idPlano))
        ?.planoGeradoId ?? 0;

    if (params.statusCotacao !== statusCotacao.Aberta) return;

    await updatePlano(idPlanoSelecionado, params);

    if (refTabela?.current) refTabela.current.obterDadosPaginados();
  };

  const onClickEditar = (id) => {
    history.push(`${RotasDTO.CotacaoIncial}/${id}`);
  };

  const onClickPDF = () => {};

  const onClickContratar = async (id) => {
    try {
      const resultado = await ListagemCotacaoHelper.contratarCotacao(id);

      if (!resultado.sucesso) {
        ListagemCotacaoHelper.exibirErro(resultado.mensagem);
        return;
      }

      if (resultado.sucesso) {
        ListagemCotacaoHelper.exibirSucesso("Cotação fechada com sucesso");
      }
    } catch (error) {
      ListagemCotacaoHelper.exibirErro(
        "Erro interno, entre em contato com o suporte!"
      );
    }
  };

  const onClickBotao = (tipo, parametros) => {
    switch (tipo) {
      case enumeradorBotao.Edicao:
        onClickEditar(parametros.id);
        break;
      case enumeradorBotao.PDF:
        onClickPDF(parametros.id);
        break;
      case enumeradorBotao.Contrato:
        if (parametros.statusCotacao == "AT") {
          onClickContratar(parametros.id);
        }
        break;
      default:
        break;
    }
  };

  const onClickDesconto = () => {};

  const onClickBotaoDescontos = (tipo, parametros) => {
    switch (tipo) {
      case enumeradorBotao.Desconto:
        onClickDesconto(parametros?.id);
        if (refTabela?.current) refTabela.current.obterDadosPaginados();
        break;
      default:
        break;
    }
  };

  const colunas = [
    new Coluna("nomeFantasia", "Nome Fantasia", false, "26.3%", "26.3%"),
    new Coluna("cnpj", "CNPJ", false, "15.7%", "15.7%"),
    new ColunaCampoDropDown(
      "plano",
      "Plano",
      false,
      onChangeSelecionarPlano,
      "10%",
      "10%"
    ),
    new ColunaComponentePersonalizado(
      "desconto",
      "Desconto",
      ColunaInputDescontos,
      onClickBotaoDescontos,
      false,
      true,
      "12%",
      "12%",
      "pl-2"
    ),
    new Coluna("dataValidade", "Validade cotação", false, "12%"),
    new Coluna("status", "Status", false, "10%"),
    new ColunaComponentePersonalizado(
      "opcoes",
      "",
      ColunaMultiplosBotoes,
      onClickBotao,
      false,
      true,
      "12%",
      "12%",
      "pl-2"
    )
  ];

  const obterStatusDescricao = (status) => {
    switch (status) {
      case statusCotacao.Aberta:
        return "Aberta";
      case statusCotacao.Fechada:
        return "Fechada";
      case statusCotacao.Expirada:
        return "Expirada";
      case statusCotacao.Cancelada:
        return "Cancelada";
      case statusCotacao.Rejeitada:
        return "Rejeitada";
      case statusCotacao.Anulada:
        return "Anulada";
      default:
        return "";
    }
  };

  const obterDesconto = async (idDesconto) => {
    return await idDesconto;
  };

  var getGenres = function () {
    return new Promise(function (resolve) {
      setTimeout(function () {
        resolve("bee");
      }, 2000);
    });
  };

  const obterListagem = useCallback(async (parametros) => {
    try {
      if (
        !parametros?.filtroPersonalizado?.colaboradorId ||
        !parametros?.filtroAdicional?.empresaId
      )
        return null;

      const filtro = new FiltroDto(
        parametros.tamanhoPagina,
        parametros.pagina,
        parametros.pesquisar,
        parametros.filtroAdicional.ordenacao ?? "",
        [parametros.filtroAdicional.filtro] ?? "",
        {
          nome: "colaboradorId",
          valor: parametros?.filtroPersonalizado?.colaboradorId ?? ""
        },
        parametros.filtroAdicional.sortOrder,
        parametros?.filtroAdicional?.empresaId ?? ""
      );

      const resultado = await ListagemCotacaoHelper.listarCotacoes(filtro);
      const planos = (await ListagemCotacaoHelper.listarTodosTipos()).data
        ?.tipoPlanoCotacoes;

      if (!resultado.sucesso) {
        store.dispatch(
          alertaExibir({
            tipo: "danger",
            mensagem: resultado.mensagem
          })
        );
        return new ResultadoPaginadoDto([], 1, 0, 0);
      }

      if (resultado.data.cotacoesIniciais?.length === 0 ?? true)
        return new ResultadoPaginadoDto([], 1, 0, 0);

      if (resultado.data === "") return new ResultadoPaginadoDto([], 1, 0, 0);

      const resLinhas = resultado?.data?.cotacoesInicias?.map((res) => {
        const planoSelecionadoId = res?.planosGerados?.find(
          (plano) => plano?.selecionado
        );

        const desconto =
          typeof planoSelecionadoId?.percentualDesconto !== "undefined"
            ? planoSelecionadoId.percentualDesconto
            : "";

        const dataCotacao = window
          .moment(res?.dataCotacao)
          .format("DD-MM-YYYY");
        const dataValidade = window
          .moment(res?.dataValidade)
          .format("DD-MM-YYYY");
        const cotacaoStatus = obterStatusDescricao(res.status);

        const planosFiltrados = planos
          .filter(
            (item) =>
              res?.planosGerados?.findIndex(
                (plano) => plano.tipoPlano === item.id
              ) > -1
          )
          .map((plano) => {
            const planoGerado = res?.planosGerados?.find(
              (itemPlano) => Number(itemPlano.tipoPlano) === Number(plano.id)
            );

            return { ...plano, planoGeradoId: planoGerado?.id };
          });

        if (planosFiltrados.length > 0) {
          planosFiltrados.push({ id: "", descricao: "", planoGeradoId: "0" });
        } else {
          planosFiltrados.push({ id: "", descricao: "-", planoGeradoId: "0" });
        }

        const cnpjFormatado = res?.cnpj.replace(
          /(\d{2})?(\d{3})?(\d{3})?(\d{4})?(\d{2})/,
          "$1.$2.$3/$4-$5"
        );

        return new ListagemCotacaoDto(
          res?.id,
          res?.nomeFantasia,
          cnpjFormatado,
          desconto,
          planosFiltrados,
          dataCotacao,
          dataValidade,
          cotacaoStatus,
          true,
          planoSelecionadoId?.tipoPlano,
          res.status,
          res?.consumidorLivre,
          res?.valorMedioConta,
          res?.dataCotacao,
          res?.dataMigracao
        );
      });

      if (!resLinhas) return false;

      return new ResultadoPaginadoDto(
        resLinhas,
        resultado.data.paginaAtual,
        resultado.data.totalItens,
        resultado.data.totalPaginas
      );
    } catch (error) {
      console.info(error);
    }
  }, []);

  const onChangeFiltrosTabela = useCallback(
    async (parametros) => {
      try {
        if (!filtroOfficer && (!colaboradorId || colaboradorId === -1))
          return null;

        return obterListagem({
          tamanhoPagina: parametros.totalPagina,
          pagina: parametros.pagina,
          pesquisar: parametros.pesquisar,
          filtroAdicional: new FiltroAdicionalTabela(
            parametros.filtrosAdicionais?.ordenacao,
            parametros.filtrosAdicionais?.filtro,
            parametros.filtrosAdicionais?.sortOrder,
            parametros.filtrosAdicionais?.empresaId
          ),
          filtroPersonalizado: parametros?.filtroPersonalizado
        });
      } catch (error) {
        return null;
      }
    },
    [filtroOfficer, colaboradorId]
  );

  const handleAlterarFiltroLateral = () => {
    setFiltroAdicionalTabela(
      new FiltroAdicionalTabela(
        ordemSelecionada,
        filtroSelecionado,
        valorOrdenacao,
        empresaSelecionada
      )
    );
  };

  useEffect(() => {
    handleAlterarFiltroLateral();
  }, [empresaSelecionada]);

  const handleClickAtivarFiltro = () => {
    setFiltroAdicionalTabela(
      new FiltroAdicionalTabela(
        ordemSelecionada,
        filtroSelecionado,
        valorOrdenacao,
        empresaSelecionada
      )
    );
  };

  const handleClickOrdemSelecionada = (event) => {
    const val = ordemSelecionada === event ? "" : event;
    setOrdemSelecionada(val);
  };

  const handleClickFiltroSelecionado = (event) => {
    const val = filtroSelecionado === event ? "" : event;
    setFiltroSelecionado(val);
  };

  const handleClickSortOrderSelecionado = (valor, idCampoSelecionado) => {
    const val =
      ordemColunaSelecionada === idCampoSelecionado && valor === ""
        ? ""
        : idCampoSelecionado;
    setOrdemColunaSelecionada(val);
    setValorOrdenacao(valor);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          spacing={2}
        >
          <Grid item md={5} sm={9}>
            <MaterialInputBusca
              type="text"
              id="textoBusca"
              name="textoBusca"
              label="Buscar"
              renderIconShowHide
              searchAdornment
              permiteValorBranco
              defaultValue={textoBusca ?? ""}
              ref={register}
            />
          </Grid>
          <Grid item md={4}>
            <Loader loading={carregandoOfficer}>
              <SelectArredondado
                select
                id="officer"
                name="officer"
                label="Officer"
                placeholder="Officer"
                valueKey="id"
                valueName="nomeColaborador"
                dataSource={listaOfficer}
                value={colaboradorId}
                onChange={(event) => {
                  onChangeSelecionarOfficer(event.target.value);
                }}
                permiteValorBranco
                ref={register}
                marginBottom="0"
                styleForm={false}
              />
            </Loader>
          </Grid>
          <Grid item xs={3} className={classes.itemFiltro}>
            <BotaoFiltroOrdenacao
              type="button"
              color={Base.OffWhite}
              background="transparent"
              label="Filtrar / Ordernar"
              icon={<TuneIcon />}
              className={classes.buttonFiltro}
              ordenacao={listarOrganizarPor()}
              ordenacaoColuna={ordenacaoPorColuna()}
              ordenador="Ordenação"
              ordenadorUm="Status"
              ordenadorDois="Planos"
              ordemSelecionada={ordemSelecionada}
              filtros={listarFiltrarPor()}
              filtrosSelecionados={filtroSelecionado}
              ordemColunaSelecionada={ordemColunaSelecionada}
              onClickOrdenacao={handleClickOrdemSelecionada}
              onClickFiltro={handleClickFiltroSelecionado}
              onClickSortOrder={handleClickSortOrderSelecionado}
              onClickAtivarAgora={handleClickAtivarFiltro}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className={`${classes.tabela} mt-4`}>
        <CustomLoader>
          <TabelaPaginada
            ref={refTabela}
            onChangeFiltrosTabela={onChangeFiltrosTabela}
            colunas={colunas}
            pesquisar={textoBusca}
            filtrosAdicionais={filtroAdicionalTabela}
            filtroPersonalizado={filtroPersonalizado}
            paginaAtual={1}
            paginationPerPage={20}
            noHeader
          />
        </CustomLoader>
      </Grid>
    </Grid>
  );
};

export default CotacaoEditarDesconto;
