import React, { useCallback, useEffect, useMemo, useState } from "react";

// ApexChart
import ReactApexChart from "react-apexcharts";

// Redux
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";

// Ícones
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";

// Componentes
import { Grid, Box } from "@mui/material";
import { useTheme,  } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

// Utils
import { groupBy } from "../../../global/utils/arrayUtil";

// Componentes
import { Base } from "../../../componentes/cores";
import Loader from "../../../componentes/loader";
import SelectAutocompleteCheckbox from "componentes/selectAutocompleteCheckbox";
import InputCalendario from "../../../componentes/inputCalendario";
import UnidadeNaoSelecionada from "componentes/unidadeNaoSelecionada";

// DTO
import FiltroDto from "./dto/filtroDto";

// Styles
import { Container } from "../Dashboard/style";
import { useStyles } from "./style";

// Helper
import ListagemMedicaoHelper from "./helper";

import theme from 'themes';

const ListagemMedicao = () => {
  const classes = useStyles();
  const { register } = useForm({
    reValidateMode: "onSubmit"
  });
  const _theme = useTheme();
  const mobile = useMediaQuery(_theme.breakpoints.down('lg'));
  const [carregandoCodigoCCEE, setCarregandoCodigoCCEE] = useState(false);
  const [carregandoDados, setCarregandoDados] = useState(false);
  const [codigosMedidoresCCEE, setCodigosMedidoresCCEE] = useState([]);
  const [codigoMedidorCCEE, setCodigoMedidorCCEE] = useState([]);
  const [grafico, setGrafico] = useState(<div />);
  const [mes, setMes] = useState(new Date());
  const [mesValido, setMesValido] = useState(false);

  const usuario = useSelector((state) => state.usuario);
  const { unidadeSelecionada } = usuario;

  const onChangeCodigoMedidorCCEE = (item) => {
    const todosCampo = item?.findIndex((x) => x.id === -1) >= 0;
    const todosLista = codigoMedidorCCEE?.findIndex((x) => x.id === -1) >= 0;

    const quemEntrou = item.find(
      (i) => codigoMedidorCCEE.findIndex((u) => u.id === i.id) === -1
    );
    const quemSaiu = codigoMedidorCCEE.find(
      (u) => item.findIndex((i) => i.id === u.id) === -1
    );

    const selecionarTodasOpcoes = quemEntrou?.id === -1;
    const removerTodasOpcoes = quemSaiu;
    const selecionarOpcaoETodos =
      !todosCampo && !todosLista && item.length >= 2;
    const removerOpcaoTodos = todosCampo && quemSaiu !== -1;
    const manterOpcaoSecionada = codigoMedidorCCEE?.length === 3;

    if (selecionarTodasOpcoes || selecionarOpcaoETodos)
      setCodigoMedidorCCEE(
        codigosMedidoresCCEE.map((x) => {
          return { id: x.id, codigoMedidorCCEE: x.nome };
        })
      );
    else if (manterOpcaoSecionada && removerOpcaoTodos)
      setCodigoMedidorCCEE((oldState) =>
        oldState.filter((x) => x.id !== -1 && x.id !== quemSaiu.id)
      );
    else if (removerTodasOpcoes) setCodigoMedidorCCEE([]);
    else
      setCodigoMedidorCCEE(
        item.map((x) => {
          return { id: x.id, codigoMedidorCCEE: x.nome };
        })
      );
  };

  // Listar codigos medidor CCEE
  const obterCodigosMedidorCCEE = useCallback(async (unidadeId) => {
    try {
      setCarregandoCodigoCCEE(true);

      const lista = await ListagemMedicaoHelper.obterCodigosMedidorCCEE(
        unidadeId
      );

      const listaEditada = lista?.data
        ?.filter((x) => x.situacao === "AT")
        .map((x) => {
          return { id: x.id, nome: x.codigoMedidorCCEE };
        });

      setCodigosMedidoresCCEE(listaEditada);
    } catch (error) {
      ListagemMedicaoHelper.exibirErro(error?.mensagem);
    } finally {
      setCarregandoCodigoCCEE(false);
    }
  }, []);

  useEffect(() => {
    onChangeCodigoMedidorCCEE(codigosMedidoresCCEE ?? []);
  }, [codigosMedidoresCCEE]);

  const medidoresSelecionados = useMemo(() => {
    if (codigoMedidorCCEE.length === 0 || !unidadeSelecionada) return [];

    const selecionadas = codigosMedidoresCCEE.filter(
      (x) => codigoMedidorCCEE.findIndex((y) => y.id === x.id) >= 0
    );

    return selecionadas;
  }, [codigosMedidoresCCEE, codigoMedidorCCEE]);

  useEffect(() => {
    obterCodigosMedidorCCEE(unidadeSelecionada);
  }, [obterCodigosMedidorCCEE, unidadeSelecionada]);

  const [listaMedicoes, setListaMedicoes] = useState([]);

  // Obter dados
  const obterDados = useCallback(async (unidadeId, data, medidor) => {
    try {
      setCarregandoDados(true);

      const filtro = new FiltroDto(medidor);

      const lista = await ListagemMedicaoHelper.obterMedicoes(
        filtro,
        unidadeId,
        window.moment(data).format("yyyy-MM-DD")
      );

      if (lista?.data?.medicoesMensais) {
        const transformed = lista?.data?.medicoesMensais.map(
          ({ dia, hora, legendaMedicao, montanteConsumido }) => ({
            x: String(dia),
            data: window
              .moment(data)
              .set("date", dia)
              .set({ hour: hora })
              .format("DD / MMM - HH:00"),
            dia: String(dia),
            hora,
            y: legendaMedicao,
            description: montanteConsumido
          })
        );

        setListaMedicoes(groupBy(transformed, "hora"));
      } else {
        setListaMedicoes([]);
      }
    } catch (error) {
      setListaMedicoes([]);
      ListagemMedicaoHelper.exibirErro(error?.mensagem);
    } finally {
      setCarregandoDados(false);
    }
  }, []);

  useEffect(() => {
    if (unidadeSelecionada && mes && codigoMedidorCCEE.length > 0)
      obterDados(unidadeSelecionada, mes, codigoMedidorCCEE);
  }, [obterDados, unidadeSelecionada, mes, codigoMedidorCCEE]);

  // Configuração da tabela
  const dados = useMemo(
    () => ({
      series: [
        {
          name: "23h",
          data: listaMedicoes[23]
        },
        {
          name: "",
          data: listaMedicoes[22]
        },
        {
          name: "",
          data: listaMedicoes[21]
        },
        {
          name: "20h",
          data: listaMedicoes[20]
        },
        {
          name: "",
          data: listaMedicoes[19]
        },
        {
          name: "",
          data: listaMedicoes[18]
        },
        {
          name: "",
          data: listaMedicoes[17]
        },
        {
          name: "16h",
          data: listaMedicoes[16]
        },
        {
          name: "",
          data: listaMedicoes[15]
        },
        {
          name: "",
          data: listaMedicoes[14]
        },
        {
          name: "",
          data: listaMedicoes[13]
        },
        {
          name: "12h",
          data: listaMedicoes[12]
        },
        {
          name: "",
          data: listaMedicoes[11]
        },
        {
          name: "",
          data: listaMedicoes[10]
        },
        {
          name: "",
          data: listaMedicoes[9]
        },
        {
          name: "08h",
          data: listaMedicoes[8]
        },
        {
          name: "",
          data: listaMedicoes[7]
        },
        {
          name: "",
          data: listaMedicoes[6]
        },
        {
          name: "",
          data: listaMedicoes[5]
        },
        {
          name: "04h",
          data: listaMedicoes[4]
        },
        {
          name: "",
          data: listaMedicoes[3]
        },
        {
          name: "",
          data: listaMedicoes[2]
        },
        {
          name: "",
          data: listaMedicoes[1]
        },
        {
          name: "00h",
          data: listaMedicoes[0]
        }
      ],
      options: {
        chart: {
          fontFamily: theme.font.family,
          background: theme.color.bgColorCard,
          color: `${Base.White} !important`,
          height: 784,
          type: "heatmap",
          toolbar: {
            show: false
          }
        },
        dataLabels: {
          enabled: false
        },
        grid: {
          show: false
        },
        xaxis: {
          type: "numeric",
          position: "top",
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          tooltip: {
            enabled: false
          },
          labels: {
            show: !mobile,
            formatter: (value) => {
              return value < 10 ? `0${value}` : value;
            },
            style: {
              colors: theme.appBar.TextColor
            }
          }
        },
        yaxis: {
          width: "100%",
          labels: {
            show: true,
            style: {
              colors: theme.appBar.TextColor
            }
          }
        },
        tooltip: {
          enabled: true,
          theme: "dark",
          followCursor: false,
          fixed: {
            enabled: true,
            position: "topRight"
          },
          custom: (opts) => {
            const desc =
              opts.ctx.w.config.series[opts.seriesIndex].data[
                opts.dataPointIndex
              ].description;
            const { data } = opts.ctx.w.config.series[opts.seriesIndex].data[
              opts.dataPointIndex
            ];

            return `<div class=${classes.tooltip}>
              ${
                desc !== " - "
                  ? `<div class=${classes.tooltipData}>${data}</div>`
                  : ""
              }
              <div class=${classes.tooltipDescricao}>${desc}</div>
              </div>`;
          }
        },
        stroke: {
          show: true,
          curve: "stepline",
          lineCap: "square",
          colors: [theme.color.bgColorCard],
          width: 7
        },
        legend: {
          show: false
        },
        plotOptions: {
          heatmap: {
            radius: 0,
            enableShades: false,
            useFillColorAsStroke: false,
            colorScale: {
              ranges: [
                {
                  from: 1,
                  to: 1,
                  color: theme.color.legendaMedicao1
                },
                {
                  from: 2,
                  to: 2,
                  color: theme.color.legendaMedicao2
                },
                {
                  from: 3,
                  to: 3,
                  color: theme.color.legendaMedicao3
                }
              ]
            }
          }
        }
      }
    }),
    [mes, listaMedicoes]
  );

  // Chamada para renderizar Gráfico
  const RenderizarGrafico = useCallback(() => {
    const graficoRender = (
      <ReactApexChart
        options={dados?.options}
        series={dados?.series}
        type="heatmap"
        height={784}
        width="100%"
      />
    );

    setGrafico(graficoRender);
  }, [setGrafico, mes, listaMedicoes]);

  useEffect(() => RenderizarGrafico(), [RenderizarGrafico]);

  // Manipulando datas
  const handlerAvancarMes = () => {
    setMes(
      window.moment(mes) <= window.moment().startOf("day")
        ? window.moment(mes).add(1, "month")
        : window.moment(mes)
    );
  };

  const handlerVoltarMes = () => {
    setMes(window.moment(mes).subtract(1, "month"));
  };

  useEffect(() => {
    setMesValido(window.moment(mes) >= window.moment().startOf("day"));
  }, [mes]);


  const definirMesInicial = () => {
    const now = window.moment();
    if (now.date() === 1 || now.date() === 2) {
        const date = window.moment().subtract(1, 'month').toDate();
        setMes(date);
    }
  }

  useEffect(() => {
    definirMesInicial();
  }, []);

  return <>
    {unidadeSelecionada ? (
      <Grid container spacing={2}>
        <Grid
          container
          spacing={0}
          className={classes.container}
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid item xs={12} md={6}>
            <Loader loading={carregandoCodigoCCEE}>
              <SelectAutocompleteCheckbox
                disableClearable
                disableOpenOnFocus
                id="codigoMedidorCCEE"
                name="codigoMedidorCCEE"
                options={codigosMedidoresCCEE}
                label="Código medidor CCEE"
                style={classes}
                getOptionLabel={(option) => option.nome}
                onChange={(e, item) => {
                  onChangeCodigoMedidorCCEE(item);
                }}
                ref={register({ name: "codigoMedidorCCEE" })}
                value={medidoresSelecionados}
                defaultValue={medidoresSelecionados}
              />
            </Loader>
          </Grid>
          <Grid item xs={12} md={6} className="d-flex justify-content-end">
            <ul className={classes.filter}>
              <li>
                <div className="icon" />
                <div className="texto">Consolidada</div>
              </li>
              <li>
                <div className="icon" />
                <div className="texto">Incompleta</div>
              </li>
              <li>
                <div className="icon" />
                <div className="texto">Não disponibilizada</div>
              </li>
            </ul>
          </Grid>
        </Grid>
        <Grid container className="m-0 w-100">
          <Grid item xs={12} className={classes.content}>
            <Grid
              container
              justifyContent="space-between"
              className={`m-0 w-100 ${classes.containerCalendario}`}
            >
              <Grid
                xs={2}
                item
                className={classes.containerArrowLeft}
              >
                <KeyboardArrowLeftIcon
                  onClick={() => handlerVoltarMes()}
                  className={classes.arrow}
                />
              </Grid>
              <Grid
                xs={8}
                item
                className={classes.calendario}
                justifyContent="center"
                alignItems="center"
                alignContent="center"
              >
                <InputCalendario
                  type="text"
                  id="mes"
                  name="mes"
                  format="MMMM / yyyy"
                  maxDate={window.moment().endOf("month").toDate()}
                  customValue={mes}
                  onChange={(valor) => setMes(valor)}
                  className={classes}
                  views={["month", "year"]}
                />
              </Grid>
              <Grid
                item
                xs={2}
                className={classes.containerArrowRight}
              >
                <KeyboardArrowRightIcon
                  onClick={() => handlerAvancarMes()}
                  className={`
                  ${classes.arrow}
                  ${mesValido && classes.disabled}`}
                />
              </Grid>
            </Grid>
            <Loader loading={carregandoDados}>
              {listaMedicoes[0] ? (
                grafico
              ) : codigoMedidorCCEE?.length === 0 ? (
                <Container className={classes.noData}>
                  Selecione um medidor para visualizar as medições
                </Container>
              ) : (
                <Container className={classes.noData}>
                  Nenhuma medição encontrada
                </Container>
              )}
            </Loader>
          </Grid>
        </Grid>
      </Grid>
    ) : (
      <UnidadeNaoSelecionada />
    )}
  </>;
};

export default ListagemMedicao;
