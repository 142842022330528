import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { Card, Grid } from "@mui/material";
import { store } from "../../../../global/redux";
import { alertaExibir } from "../../../../global/redux/modulos/alertas/actions";
import { buscarPorId } from "../../../../servicos/unidadesServico";
import { BotaoRetornarListagem, InputRadioGroup } from "componentes";
import UnidadeHelper from "../helper";
import MercadoLivreForm from "./Componentes/MercadoLivre";
import GeracaoDistribuidaForm from "./Componentes/GeracaoDistribuida";
import { useStyles } from "./style";
import { useSelector } from "react-redux";
import { buscarPorId as buscarEmpresaPorId } from "servicos/empresaServico";
import { buscarPorId as buscarClientePorId } from "servicos/clientesServico";
import { RotasDTO } from "global/rotas/rotasUrlDto";
import { getTipoPerfil } from "../../../../global/getTipoPerfil";
import { usuarioPossuiFuncionalidade } from "../../../../servicos/funcionalidadesServico";
import { InterfaceDTO } from "../../../../global/dto/interfacesDto";

const UnidadesCadastro = () => {
  const { id } = useParams();
  const classes = useStyles();
  const rotas = useSelector((state) => state.rotas);
  const usuario = useSelector((state) => state.usuario);
  const { menu } = usuario;
  const { tipo } = usuario?.usuario;

  const produtos = UnidadeHelper.obterProdutos();
  const [produtoSelecionado, setProdutoSelecionado] = useState(
    produtos[0].valor
  );
  const [dadosUnidade, setDadosUnidade] = useState(null);

  const podeEditarUnidade = usuarioPossuiFuncionalidade(
    menu,
    rotas.rotaAtual.caminho,
    RotasDTO.UnidadesConsumidoras,
    InterfaceDTO.PodeEditarUnidade
  );

  const editarUnidade = useMemo(() => {
    return podeEditarUnidade && id;
  }, [podeEditarUnidade, id]);

  const onChangeProduto = async (valor) => {
    setProdutoSelecionado(valor);
  };

  const obterUnidade = useCallback(async (idUnidade) => {
    try {
      const response = await buscarPorId(idUnidade);
      if (response?.status === 200 && response?.data) {
        setDadosUnidade(response?.data);
        setProdutoSelecionado(`${response?.data.produtoId}`);
      }
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
    }
  }, []);

  useEffect(() => {
    if (id) {
      obterUnidade(id);
    }
  }, [id, obterUnidade]);

  const [empresaUnidade, setEmpresaUnidade] = useState();
  const [clienteUnidade, setClienteUnidade] = useState();

  // Nome da empresa selecionada
  const nomeEmpresa = useMemo(() => {
    if (empresaUnidade?.nomeFantasia) return empresaUnidade?.nomeFantasia;

    const empresa = usuario?.empresas.find(
      (item) => String(item?.id) === String(usuario?.empresaSelecionada)
    );

    return empresa?.nomeFantasia ?? "";
  }, [usuario, dadosUnidade, empresaUnidade]);

  const obterEmpresa = useCallback(async () => {
    if (clienteUnidade?.empresaGrupoDeltaId) {
      try {
        const empresa = await buscarEmpresaPorId(
          clienteUnidade?.empresaGrupoDeltaId
        );

        if (empresa?.data) {
          setEmpresaUnidade(empresa?.data);
        }
      } catch (error) {
        console.info(error);
      }
    }
  }, [clienteUnidade]);

  useEffect(() => {
    obterEmpresa();
  }, [obterEmpresa]);

  const obterCliente = useCallback(async () => {
    if (dadosUnidade?.clienteId) {
      try {
        const cliente = await buscarClientePorId(dadosUnidade?.clienteId);

        if (cliente?.data) {
          setClienteUnidade(cliente?.data);
        }
      } catch (error) {
        console.info(error);
      }
    }
  }, [dadosUnidade]);

  useEffect(() => {
    obterCliente();
  }, [obterCliente]);

  // Nome da cliente selecionado
  const nomeCliente = useMemo(() => {
    let cliente;

    if (dadosUnidade?.clienteId) {
      cliente = usuario?.clientes.find(
        (item) => String(item?.id) === String(dadosUnidade?.clienteId)
      );

      if (!cliente && clienteUnidade) return clienteUnidade?.nomeFantasia ?? "";
    } else {
      cliente = usuario?.clientes.find(
        (item) => String(item?.id) === String(usuario?.clienteSelecionado)
      );
    }

    return cliente?.nomeFantasia ?? "";
  }, [usuario, dadosUnidade, clienteUnidade]);

  return (
    <>
      <Card className={classes.cardCadastro}>
        <Grid container p={2} spacing={2} className={classes.container}>
          <Grid item xs={6} className="font-weight-bold">
            {dadosUnidade?.id
              ? "Editar unidade consumidora"
              : "Nova unidade consumidora"}
            {nomeEmpresa !== "" ? ` na empresa ${nomeEmpresa}` : ""}
            {nomeCliente !== "" ? ` e cliente ${nomeCliente}` : ""}
          </Grid>
          <BotaoRetornarListagem
            urlListagem={{
              pathname: RotasDTO.UnidadesConsumidoras,
              state: { dadosUnidade }
            }}
            zerarCombo={getTipoPerfil(tipo)}
          />
          <Grid item xs={12} className="font-weight-bold">
            {"Produto"}
            <InputRadioGroup
              type="text"
              id="produtoId"
              name="produtoId"
              renderIconShowHide={false}
              customValue={produtoSelecionado}
              onChange={(valor) => onChangeProduto(valor.target.value)}
              defaultValue={produtoSelecionado}
              className={classes.inputRadio}
              classNameLabel={classes.inputRadioLabel}
              itens={produtos}
            />
          </Grid>
        </Grid>
        {produtoSelecionado == "1" ? (
          <MercadoLivreForm
            id={id}
            dadosUnidade={dadosUnidade}
            produtoSelecionado={produtoSelecionado}
            nomeEmpresa={nomeEmpresa}
            nomeCliente={nomeCliente}
            empresaUnidade={empresaUnidade}
            clienteUnidade={clienteUnidade}
            usuario={usuario}
            rotas={rotas}
            menu={menu}
          />
        ) : (
          <GeracaoDistribuidaForm
            id={id}
            dadosUnidade={dadosUnidade}
            produtoSelecionado={produtoSelecionado}
            nomeEmpresa={nomeEmpresa}
            nomeCliente={nomeCliente}
            empresaUnidade={empresaUnidade}
            clienteUnidade={clienteUnidade}
            usuario={usuario}
            rotas={rotas}
            menu={menu}
            editarUnidade={editarUnidade}
          />
        )}
      </Card>
    </>
  );
};

export default UnidadesCadastro;
