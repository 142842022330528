import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";

// Componentes
import CloseIcon from "@mui/icons-material/Close";

// Styles
import { useStyles } from "./style";

const Video = React.forwardRef(({ src, cancelar, finalizarVideo }) => {
  const classes = useStyles();
  const videoRef = useRef(null);

  const VerificarTempoVideo = () => {
    if (videoRef?.current) {
      if (videoRef.current.currentTime === videoRef.current.duration) {
        finalizarVideo(videoRef.current);
      }
    }
  };

  return (
    <div className={classes.container}>
      <video
        width="100%"
        controls
        controlsList="nodownload"
        autoPlay
        ref={videoRef}
        onTimeUpdate={() => VerificarTempoVideo()}
      >
        <source src={src} />
        Your browser does not support HTML video.
      </video>
      <CloseIcon className={classes.fechar} onClick={() => cancelar()} />
    </div>
  );
});

Video.propTypes = {
  src: PropTypes.string.isRequired,
  cancelar: PropTypes.func,
  finalizarVideo: PropTypes.func
};

Video.defaultProps = {
  cancelar: () => {},
  finalizarVideo: () => {}
};

export default Video;
