import styled from 'styled-components';


export const RelatoriosTitleGroup = styled.div`
    font-family: CenturyGothic;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.4px;
    margin-bottom: 16px;
`;

export const RelatoriosList = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

export const RelatoriosListItem = styled.div`
    width: 25%;
    padding-bottom: 8px;
`;

export const RelatoriosListItemText = styled.span`
    padding-left:  8px;
    font-family: CenturyGothic;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.31px;
`;