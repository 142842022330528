import { makeStyles } from '@mui/styles';
import { Base } from "../../../../componentes/cores";
import theme from 'themes';

export const useStyles = makeStyles({
  container: {
    margin: "0",
    width: "100%",
    "& .MuiInputBase-root": {
      height: "46px"
    },
    "& .MuiFormLabel-root": {
      lineHeight: "1"
    }
  },
  button: {
    fontSize: "18px"
  },
  trace: {
    width: "112px",
    height: "47px",
    borderBottom: "1px solid black",
    position: "absolute"
  },
  inputTelefone: {
    "&.Mui-error": {
      borderColor: Base.RedError,
      color: Base.RedError
    }
  },
  containerTelefone: {
    "& .MuiInputBase-input": {
      height: "7px"
    }
  },
  containerSalvar: {
    margin: "16px 16px 32px"
  },
  divider: {
    margin: "8px 18px 16px"
  },
  cardCadastro: {
    backgroundColor: theme.color.bgColorCardCadastro
  },
  inputRadio: {
    marginBottom: "-16px"
  },
  inputRadioLabel: {
    color: `${theme.color.radioButton} !important`
  },
});
