import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useParams, useHistory } from "react-router-dom";

// Form Hooks
import { useForm, useWatch } from "react-hook-form";

// Componentes
import { Card, Grid } from "@mui/material";
import Loader from "../../../../componentes/loader";
import Botao from "../../../../componentes/botao";
import MaterialInputTexto from "../../../../componentes/inputTexto/materialInput";
import SelectArredondado from "../../../../componentes/selectArredondado";
import Funcionalidades from "./componentes/funcionalidades";
import BotaoRetornarListagem from "../../../../componentes/botaoRetornarListagem";

// Serviços
import {
  buscarPorId,
  salvarPerfil,
  listarTiposPerfil,
  listarPerfis
} from "../../../../servicos/perfisServico";

// Rotas
import { RotasDTO } from "../../../../global/rotas/rotasUrlDto";

// Styles
import { useStyles } from "./style";
import { alertaExibir } from "../../../../global/redux/modulos/alertas/actions";

// Redux
import { store } from "../../../../global/redux";
import { desabilitarFiltroLateral } from "../../../../global/redux/modulos/usuario/actions";
import MaterialInputBusca from "componentes/inputBusca";

const PerfilAcessoCadastro = () => {
  const { id } = useParams();
  const classes = useStyles();
  const history = useHistory();
  const { register, errors, control, handleSubmit } = useForm({
    reValidateMode: "onSubmit"
  });

  const [carregandoTiposPerfil, setCarregandoTiposPerfil] = useState(false);
  const [tiposPerfil, setTiposPerfil] = useState([]);

  const obterTiposPerfil = async () => {
    try {
      setCarregandoTiposPerfil(true);
      const lista = await listarTiposPerfil();
      if (lista?.status === 200 && lista?.data) {
        setTiposPerfil(lista?.data);
      }
      setCarregandoTiposPerfil(false);
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
      setCarregandoTiposPerfil(false);
    }
  };

  const [carregandoPerfil, setCarregandoPerfil] = useState(false);
  const [dadosPerfil, setDadosPerfil] = useState();

  const [listaPerfis, setListaPerfis] = useState([]);
  const [tipoPerfil, setTipoPerfil] = useState();

  const obterPerfil = useCallback(async (idPerfil) => {
    try {
      const perfil = await buscarPorId(idPerfil);
      if (perfil?.status === 200 && perfil?.data) {
        setDadosPerfil(perfil?.data);
      }
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
    }
  }, []);

  useEffect(() => {
    if (listaPerfis?.length && dadosPerfil?.idTipoPerfil) {
      setTipoPerfil(dadosPerfil.idTipoPerfil);
    }
  }, [listaPerfis, dadosPerfil]);

  useEffect(() => {
    if (id) obterPerfil(id);
  }, [id, obterPerfil]);

  const [carregandoPerfis, setCarregandoPerfis] = useState(false);

  const obterPerfis = async () => {
    try {
      setCarregandoPerfis(true);
      const lista = await listarPerfis();
      if (lista?.status === 200 && lista?.data) {
        setListaPerfis(lista?.data);
      }
      setCarregandoPerfis(false);
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
      setCarregandoPerfis(false);
    }
  };

  useEffect(() => {
    obterTiposPerfil();
    obterPerfis();
  }, []);

  const [
    funcionalidadesSelecionadas,
    setFuncionalidadesSelecionadas
  ] = useState([]);

  const enviarFormulario = async (dados) => {
    try {
      const listaFuncionalidades = [];
      if (funcionalidadesSelecionadas?.length) {
        funcionalidadesSelecionadas.forEach((item) => {
          listaFuncionalidades.push({ IdFuncionalidade: item });
        });
      }

      const salvou = await salvarPerfil(id ?? 0, {
        ...dados,
        IdTipoPerfil: tipoPerfil,
        Ativo: true,
        perfilFuncionalidades: listaFuncionalidades
      });

      if (salvou) {
        store.dispatch(
          alertaExibir({
            tipo: "success",
            mensagem: "O perfil foi salvo com sucesso!"
          })
        );
        setTimeout(() => {
          history.push(RotasDTO.PerfisAcesso);
        }, 2000);
      }
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
    }
    setCarregandoPerfil(false);
  };

  const aoEnviarFormulario = (dados) => {
    setCarregandoPerfil(true);
    enviarFormulario(dados);
  };

  const onChangeTipoPerfil = (tipo) => {
    setTipoPerfil(tipo.target.value);
  };

  const [cloneId, setCloneId] = useState();

  const onChangeClone = (clone) => {
    setCloneId(clone.target.value);
  };

  const obterFuncionalidadesClone = useCallback(async (idPerfil) => {
    try {
      const perfil = await buscarPorId(idPerfil);
      if (perfil?.status === 200 && perfil?.data) {
        if (perfil?.data?.perfilFuncionalidades?.length) {
          const perfilFuncionalidades = perfil?.data?.perfilFuncionalidades.map(
            (funcionalidade) => funcionalidade.idFuncionalidade
          );
          setFuncionalidadesSelecionadas(perfilFuncionalidades ?? []);
        }
      }
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
    }
  }, []);

  useEffect(() => {
    if (cloneId) obterFuncionalidadesClone(cloneId);
  }, [cloneId, obterFuncionalidadesClone]);

  useEffect(() => {
    if (dadosPerfil?.perfilFuncionalidades?.length) {
      const perfilFuncionalidades = dadosPerfil.perfilFuncionalidades.map(
        (funcionalidade) => funcionalidade.idFuncionalidade
      );
      setFuncionalidadesSelecionadas(perfilFuncionalidades ?? []);
    }
  }, [dadosPerfil]);

  const obterNodePaiCasoTodosFilhosSelecionados = (
    nodePaiId,
    itens,
    auxiliar
  ) => {
    if (!nodePaiId) return null;

    var nodePai = obterNodePaiQualquerNivel(nodePaiId, itens);

    if (!nodePai) return null;

    var todasSelecionadas = funcionalidadesSelecionadas.concat(auxiliar);

    var naoSelecionadasNodePai = nodePai.funcionalidadeColecao.filter(
      (x) =>
        todasSelecionadas.findIndex((z) => z === x.id) < 0 && x.ativo === true
    );

    return naoSelecionadasNodePai.length === 0 ? nodePai.id : null;
  };

  const obterNodePaiQualquerNivel = (idDesejado, itens) => {
    var nodePai = itens.find((item) => item.id === idDesejado);

    if (nodePai) return nodePai;

    let noDesejado = null;

    itens.forEach((node) => {
      if (noDesejado) return;

      if (node.funcionalidadeColecao.length > 0)
        noDesejado = obterNodePaiQualquerNivel(
          idDesejado,
          node.funcionalidadeColecao
        );

      if (noDesejado) return;
    });

    return noDesejado;
  };

  const onClickSelecionar = (node, itens) => {
    if (!node) return;

    const naoExiste = funcionalidadesSelecionadas.findIndex(
      (x) => x === node.id
    );
    if (naoExiste === -1) {
      const auxiliar = [node.id];

      var nodePaiParaSelecionar = obterNodePaiCasoTodosFilhosSelecionados(
        node.idFuncionalidadePai,
        itens,
        auxiliar
      );

      if (nodePaiParaSelecionar > 0) auxiliar.push(nodePaiParaSelecionar);

      if (!node?.funcionalidadeColecao ?? true) {
        setFuncionalidadesSelecionadas(auxiliar);
        return;
      }

      node.funcionalidadeColecao.forEach((filho) => {
        const naoExisteFilho =
          funcionalidadesSelecionadas.findIndex((x) => x === filho.id) < 0;
        if (naoExisteFilho) {
          auxiliar.push(filho.id);
        }
      });

      setFuncionalidadesSelecionadas(
        funcionalidadesSelecionadas.concat(auxiliar)
      );
    } else {
      const lista = [...funcionalidadesSelecionadas];
      lista.splice(naoExiste, 1);
      if (node?.funcionalidadeColecao) {
        node.funcionalidadeColecao.forEach((filho) => {
          const existeFilho = lista.indexOf(filho.id);
          if (existeFilho > -1) {
            lista.splice(existeFilho, 1);
            if (filho?.funcionalidadeColecao) {
              filho.funcionalidadeColecao.forEach((neto) => {
                const existeNeto = lista.indexOf(neto.id);
                if (existeNeto > -1) {
                  lista.splice(existeNeto, 1);
                }
              });
            }
          }
        });
      }
      setFuncionalidadesSelecionadas(lista);
    }
  };

  const textoBusca = useWatch({
    control,
    name: "textoBusca",
    defaultValue: ""
  });

  useEffect(() => {
    store.dispatch(desabilitarFiltroLateral(true));

    return () => {
      store.dispatch(desabilitarFiltroLateral(false));
    };
  }, [desabilitarFiltroLateral]);

  return (
    <>
      <form
        className="needs-validation"
        onSubmit={handleSubmit(aoEnviarFormulario)}
      >
        <Card className={classes.cardCadastro}>
          <Grid container p={2} spacing={4} className={classes.container}>
            <Grid item xs={6} className="font-weight-bold">
              {dadosPerfil?.id
                ? "Editar perfil de acesso"
                : "Novo perfil de acesso"}
            </Grid>
            <BotaoRetornarListagem urlListagem={RotasDTO.PerfisAcesso} />
            <Grid item lg={3} md={4} sm={6}>
              <MaterialInputTexto
                type="text"
                id="nome"
                name="nome"
                label="Perfil"
                renderIconShowHide={false}
                defaultValue={dadosPerfil?.nome}
                ref={register({
                  required: "Campo perfil é obrigatório!"
                })}
                errors={errors}
              />
            </Grid>
            <Grid item lg={3} md={4} sm={6}>
              <Loader loading={carregandoTiposPerfil}>
                <SelectArredondado
                  id="idTipoPerfil"
                  name="idTipoPerfil"
                  valueKey="id"
                  valueName="nome"
                  dataSource={tiposPerfil}
                  placeholder="Selecione o Tipo"
                  label="Tipo"
                  value={tipoPerfil}
                  onChange={(tipo) => onChangeTipoPerfil(tipo)}
                  ref={register(
                    { name: "idTipoPerfil" },
                    {
                      required: !tipoPerfil
                        ? "Campo tipo é obrigatório!"
                        : false
                    }
                  )}
                  errors={errors}
                />
              </Loader>
            </Grid>
            <Grid item lg={3} md={4} sm={6}>
              <Loader loading={carregandoPerfis}>
                <SelectArredondado
                  id="cloneId"
                  name="cloneId"
                  valueKey="id"
                  valueName="nome"
                  dataSource={listaPerfis}
                  label="Clone"
                  value={cloneId}
                  onChange={(clone) => onChangeClone(clone)}
                  placeholder="Selecione o Clone"
                  allowClear
                />
              </Loader>
            </Grid>
          </Grid>
        </Card>
        <br />
        <Grid
          container
          spacing={0}
          className={classes.container}
          alignItems="center"
        >
          <Grid item lg={7} className="mb-4">
            <MaterialInputBusca
              type="text"
              id="textoBusca"
              name="textoBusca"
              label="Nome da funcionalidade"
              renderIconShowHide
              searchAdornment
              defaultValue={textoBusca ?? ""}
              ref={register}
            />
          </Grid>
          <Grid item lg={5} className="d-flex justify-content-end">
            <ul className={classes.filter}>
              <li>
                <div className="icon" />
                <div className="texto">Setor</div>
              </li>
              <li>
                <div className="icon" />
                <div className="texto">Segmento</div>
              </li>
              <li>
                <div className="icon" />
                <div className="texto">Interface</div>
              </li>
            </ul>
          </Grid>
          <Funcionalidades
            selecionados={funcionalidadesSelecionadas}
            onSelecionar={(node, lista) => onClickSelecionar(node, lista)}
            onBuscar={textoBusca ?? ""}
          />
        </Grid>
        <Grid container spacing={0} className={classes.container}>
          <Grid item lg={3} md={4} sm={6}>
            <Loader loading={carregandoPerfil}>
              <Botao type="submit" label="Salvar" className={classes.button} />
            </Loader>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default PerfilAcessoCadastro;
