import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState
} from "react";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepIcone from "./componentes/icone";
import { ColorlibConnector, useStyles } from "./style";

// import { Container } from './styles';

const AssistenteHorizontal = forwardRef((props, ref) => {
  const { steps, passoInicialFluxo, onChangeStep, orientation = 'horizontal'} = props;
  const [activeStep, setActiveStep] = useState(passoInicialFluxo ?? 0);
  const vertical = orientation === 'vertical';
  const classes = useStyles({vertical});

  //console.log(passoInicialFluxo);

  const icons = useMemo(() => {
    return steps?.map((element, index) => index + 1) ?? [];
  }, [steps]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleCurrent = () => {
    return activeStep;
  };

  useEffect(() => onChangeStep(activeStep), [activeStep]);

  useEffect(() => setActiveStep(passoInicialFluxo), [passoInicialFluxo]);

  useImperativeHandle(ref, () => ({
    proximoPasso() {
      handleNext();
    },
    voltarPasso() {
      handleBack();
    },
    resetarPasso() {
      handleReset();
    },
    obterPassoAtual() {
      return handleCurrent();
    }
  }));

  return (
    <Stepper
      orientation={orientation}
      alternativeLabel={!vertical}
      activeStep={activeStep}
      connector={<ColorlibConnector />}
      className={classes.stepper}
    >
      {steps?.map((label) => (
        <Step key={label}>
          <StepLabel
            StepIconComponent={(props) => StepIcone({ ...props, icons, vertical })}
          >
            <label className={classes.labelStep}>{label}</label>
          </StepLabel>
        </Step>
      )) ?? <></>}
    </Stepper>
  );
});

export default AssistenteHorizontal;
