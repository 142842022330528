import { makeStyles } from '@mui/styles';
import { Base } from "../../cores";
import theme from 'themes';

const BorderColor = (color) => {
  if (color === 3) return Base.White;
  if (color === 4) return Base.BlueViolet;
  if (color === 5) return Base.BlueDodger;
  return Base.White;
};

const BackgroundColor = (level) => {
  if (level === 3) return theme.color.fourthBackgroud;
  if (level === 4) return theme.color.thirdBackgroud;
  if (level === 5) return Base.GreyPaynes;
  return theme.color.fourthBackgroud;
};

export const useStyles = makeStyles({
  squareWidth: {
    height: "54px !important",
    width: "54px !important"
  },
  treeViewItemExpand: {
    color: `${Base.GreyDim}  !important`,
    justifyContent: "end !important",
    padding: "0  !important",
    position: "relative !important"
  },
  treeViewBorder: {
    borderLeftColor: (props) => BorderColor(props.color),
    borderLeftWidth: "6px !important",
    borderLeftStyle: "solid !important"
  },
  gridItem: {
    flexBasis: "100% !important",
    fontSize: "2.1875rem !important",
    margin: "0 !important",
    maxWidth: "100% !important",
    opacity: (props) => (props.ativo ? 1 : 0.5),
    "pointer-events": "all !important",
    width: "54px !important"
  },
  treeItem: {
    backgroundColor: (props) => BackgroundColor(props.color),
    color: `${theme.color.secondaryText} !important`,
    height: "54px !important"
  },
  wrapper: {
    alignItems: "center !important",
    display: "flex !important",
    height: "100% !important",
    minHeight: "100% !important"
  },
  start: {
    alignItems: "flex-start !important"
  },
  center: {
    justifyContent: "center !important"
  },
  left: {
    justifyContent: "start !important",
    paddingLeft: "20px !important"
  },
  right: {
    justifyContent: "flex-end !important"
  }
});
