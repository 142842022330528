import React, { useState, useMemo, useEffect } from "react";
import { useParams } from "react-router-dom";

// FormHooks
import { useForm } from "react-hook-form";

// Componentes
import { Box, Grid } from "@mui/material";

import CardLogin from "../../../componentes/cardLogin";
import Botao from "../../../componentes/botao";
import Alerta from "../../../componentes/alerta";
import Loader from "../../../componentes/loader";

// Styles
import {
  Fundo,
  Logo,
  Wrapper,
  Titulo,
  Mensagem,
  LogoLuz,
  ButtonLuz,
  SubTituloLuz
} from "../style";
import Campos from "../campos";
import Medidor from "./medidor";

// Serviços
import { validarChave, ativarUsuario } from "../../../servicos/loginServico";

import getDomain from "../../../global/getDomain";

import InputCheckBoxTermo from "../inputCheckBoxTermo";

const Registro = () => {
  const { register, handleSubmit, watch, errors } = useForm();
  const senha = watch("senha");
  const confirmarSenha = watch("confirmarSenha");

  const [tokenValido, setTokenValido] = useState(false);
  const { token } = useParams();

  const [mensagemAlerta, setMensagemAlerta] = useState("");
  const [carregandoAtualizacao, setCarregandoAtualizacao] = useState(false);
  const [statusMedidor, setStatusMedidor] = useState("");
  const [confirmacaoSenha, setConfirmacaoSenha] = useState("");

  useEffect(() => {
    if (senha?.trim() === confirmarSenha?.trim()) setConfirmacaoSenha("");
  }, [senha, confirmarSenha]);

  const formatarMensagem = (senhaDigitada) => {
    const caracteres = senhaDigitada?.length > 7;
    const maisculas = senhaDigitada?.match(/([A-Z]{1})/);
    const minusculas = senhaDigitada?.match(/([a-z]{1})/);
    const numeros = senhaDigitada?.match(/([0-9]{1})/);
    const simbolos = senhaDigitada?.match(
      /([!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]{1})/
    );

    return (
      <Box component="div" textAlign="center" fontSize="15px" fontWeight="400">
        <Box component="span" mr="2px">
          Requisitos de segurança:
        </Box>
        <Box component="span" mr="2px" color={caracteres ? "#2ABF6F" : ""}>
          8 caracteres,
        </Box>
        <Box component="span" mr="2px" color={maisculas ? "#2ABF6F" : ""}>
          letras maiúsculas e
        </Box>
        <Box component="span" mr="2px" color={minusculas ? "#2ABF6F" : ""}>
          minúsculas,
        </Box>
        <Box component="span" mr="2px" color={numeros ? "#2ABF6F" : ""}>
          números e
        </Box>
        <Box component="span" mr="2px" color={simbolos ? "#2ABF6F" : ""}>
          símbolos ($#@!)
        </Box>
      </Box>
    );
  };

  const forcaSenha = useMemo(() => {
    setStatusMedidor(formatarMensagem(senha));
    if (!senha) return -1;
    setStatusMedidor(formatarMensagem(senha));

    const validacoes = [
      senha.match(/([A-Z]{1})/) ? 1 : 0,
      senha.match(/([a-z]{1})/) ? 1 : 0,
      senha.match(/([0-9]{1})/) ? 1 : 0,
      senha.match(/([!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]{1})/) ? 1 : 0,
      senha.length > 7 ? 1 : 0
    ];

    return validacoes.reduce((soma, valor) => soma + valor, -1) ?? -1;
  }, [senha]);

  const validarToken = useMemo(async () => {
    setCarregandoAtualizacao(true);
    try {
      if (token) {
        const validacao = await validarChave(token);
        if (validacao?.data?.isRedirect) {
          window.location.href = "/login";
          return false;
        }
        
          setCarregandoAtualizacao(false);
          return validacao?.data ?? false;
        
      }
      setMensagemAlerta({
        tipo: "warning",
        mensagem: "Token de acesso não informado!"
      });
    } catch (error) {
      setMensagemAlerta({
        tipo: "warning",
        mensagem:
          error?.response?.data?.message ??
          "Erro interno, entre em contato com o suporte!"
      });
    }
    setCarregandoAtualizacao(false);
    return false;
  }, [token]);

  useEffect(() => {
    async function validar() {
      const validacao = await validarToken;
      setTokenValido(validacao);
    }

    validar();
  }, [validarToken]);

  const enviarFormulario = async (dados) => {
    if (senha?.trim() !== confirmarSenha?.trim()) {
      setConfirmacaoSenha("Senhas diferentes!");
      return;
    }

    if (tokenValido?.colaboradorId && forcaSenha < 3) {
      setMensagemAlerta({
        tipo: "warning",
        mensagem: "É necessário cadastrar uma senha forte para avançar!"
      });
      return;
    }

    setCarregandoAtualizacao(true);
    try {
      const UsuarioPrimeiroAcessoRquestDto = {};
      UsuarioPrimeiroAcessoRquestDto.Id = tokenValido.id;
      UsuarioPrimeiroAcessoRquestDto.Password = dados.senha;
      UsuarioPrimeiroAcessoRquestDto.Active = true;
      UsuarioPrimeiroAcessoRquestDto.Token = token;
      if (!tokenValido?.possuiTermoAceito) {
        UsuarioPrimeiroAcessoRquestDto.AceitaTermos = dados.AceitaTermos;
        UsuarioPrimeiroAcessoRquestDto.TermoId = tokenValido?.termoId;
      }

      const atualizacao = await ativarUsuario(UsuarioPrimeiroAcessoRquestDto);
      if (atualizacao?.status === 200) {
        setMensagemAlerta({
          tipo: "success",
          mensagem:
            atualizacao?.data?.message ?? "Senha cadastrada com sucesso!"
        });
        setTimeout(() => {
          window.location.href = "/login";
        }, 2000);
      }
    } catch (error) {
      setMensagemAlerta({
        tipo: "warning",
        mensagem:
          error?.response?.data?.Message || error?.response?.data?.message ||
          "Erro interno, entre em contato com o suporte!"
      });
    }
    setCarregandoAtualizacao(false);
  };

  const aoEnviarFormulario = (dados) => enviarFormulario(dados);

  const siteLuzSimulador = () => {
    window.open(
      `${process.env.REACT_APP_SITE_LUZ_API_URL}/#simulador`,
      "_target"
    );
  };

  return (
    <>
      {getDomain() === "luz" ? (
        <>
          <Box
            minHeight="10vh"
            pl={{ xs: 1, md: 6 }}
            pr={{ xs: 2, md: 6 }}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center"
            }}
          >
            <Box>
              <LogoLuz src={`../themes/${getDomain()}/assets/logo.png`} />
            </Box>
            <Box>
              <ButtonLuz onClick={siteLuzSimulador}>Mude para a LUZ</ButtonLuz>
            </Box>
          </Box>
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{ minHeight: "90vh", backgroundColor: "#332053" }}
          >
            <Box
              borderRadius="4px"
              justifyContent="center"
              alignItems="center"
              bgcolor="#FFFFFF"
              px={{ xs: 2, md: 8 }}
              py={{ xs: 8, md: 4 }}
              mx={{ xs: 0.5 }}
              width={{ sm: 500, md: 500 }}
            >
              <Box mb={{ xs: 6 }} display="flex" justifyContent="center">
                <SubTituloLuz>Cadastro de senha</SubTituloLuz>
              </Box>
              <Mensagem forca={forcaSenha} />
              <form onSubmit={handleSubmit(aoEnviarFormulario)}>
                <Loader loading={carregandoAtualizacao}>
                  <Campos
                    register={register}
                    errors={errors}
                    valores={{ acesso: tokenValido?.email ?? "" }}
                    travarAcesso={!!tokenValido?.email}
                    acesso="registro"
                  />
                  <Box
                    component="div"
                    fontSize="17px"
                    fontWeight="400"
                    my="15px"
                    color="#BF2A2A"
                    textAlign="center"
                  >
                    {confirmacaoSenha}
                  </Box>
                  <Medidor forca={forcaSenha} />
                  <Box component="div" color="#332053">
                    {statusMedidor}
                  </Box>
                  <Botao
                    loginLuz
                    type="submit"
                    label="Próximo"
                    style={{ marginTop: 24 }}
                  />
                  <Mensagem
                    style={{
                      marginTop: 0,
                      marginBottom: 10,
                      display: tokenValido?.possuiTermoAceito ? "none" : "block"
                    }}
                  >
                    <InputCheckBoxTermo
                      name="AceitaTermos"
                      termoDescricao={tokenValido?.termoDeUsoDescricao}
                      ref={register({
                        required: !tokenValido?.possuiTermoAceito
                          ? "É necessário aceitar os termos de uso da plataforma!"
                          : false
                      })}
                      errors={errors}
                    />
                  </Mensagem>
                </Loader>
              </form>
              {mensagemAlerta && (
                <Alerta
                  {...mensagemAlerta}
                  onClose={() => setMensagemAlerta("")}
                />
              )}
            </Box>
          </Grid>
        </>
      ) : (
        <>
          <Fundo
            url={`../themes/${getDomain()}/assets/bg-login.jpg`}
            className="w-100 h-100 position-absolute overflow-hidden"
          />
          <Box
            component={Grid}
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{ minHeight: "100vh", backgroundColor: "#332053" }}
          >
            <Logo src={`../themes/${getDomain()}/assets/logo-217x132.png`} />
            <CardLogin height={533} width={468} className="mx-auto">
              <Wrapper className="mx-auto">
                <Titulo italic="italic">Cadastro de senha</Titulo>
                <Mensagem forca={forcaSenha} />
                <form onSubmit={handleSubmit(aoEnviarFormulario)}>
                  <Loader loading={carregandoAtualizacao}>
                    <Campos
                      register={register}
                      errors={errors}
                      valores={{ acesso: tokenValido?.email ?? "" }}
                      travarAcesso={!!tokenValido?.email}
                      acesso="registro"
                    />
                    <Box
                      component="div"
                      fontSize="17px"
                      fontWeight="400"
                      my="15px"
                      color="#BF2A2A"
                      textAlign="center"
                    >
                      {confirmacaoSenha}
                    </Box>
                    <Medidor forca={forcaSenha} />
                    <Box component="div" color="#332053">
                      {statusMedidor}
                    </Box>
                    <Botao
                      type="submit"
                      label="Próximo"
                      disabled={!tokenValido}
                      style={{ marginTop: 24 }}
                    />
                    <Mensagem
                      style={{
                        marginTop: 0,
                        marginBottom: 10,
                        display: !tokenValido?.possuiTermoAceito
                          ? "block"
                          : "none"
                      }}
                    >
                      <InputCheckBoxTermo
                        name="AceitaTermos"
                        termoDescricao={tokenValido?.termoDeUsoDescricao}
                        ref={register({
                          required: !tokenValido?.possuiTermoAceito
                            ? "É necessário aceitar os termos de uso da plataforma!"
                            : false
                        })}
                        errors={errors}
                      />
                    </Mensagem>
                  </Loader>
                </form>
                {mensagemAlerta && (
                  <Alerta
                    {...mensagemAlerta}
                    onClose={() => setMensagemAlerta("")}
                  />
                )}
              </Wrapper>
            </CardLogin>
          </Box>
        </>
      )}
    </>
  );
};

export default Registro;
