import { makeStyles } from '@mui/styles';
import theme from "themes";
import { Base } from "../../../../componentes/cores";

export const useStyles = makeStyles(() => ({
  tableCellBranco: {
    background: Base.White,
    marginLeft: "6px",
    padding: "4px 9px 4px 4px"
  },
  tableCellDestaque: {
    color: `${Base.White} !important`,
    paddingLeft: "6px",
    display: "flex",
    alignSelf: "center",
    justifyContent: "center",
    alignItems: "center"
  },
  tableCellAzul: {
    backgroundColor: theme.color.primary
  },
  tableCellCinza: {
    backgroundColor: Base.GreyDim
  },
  bordaEsquerda: {
    borderTopLeftRadius: "8px",
    borderBottomLeftRadius: "8px"
  },
  bordaDireita: {
    borderTopRightRadius: "8px",
    borderBottomRightRadius: "8px"
  },
  margin: {
    marginLeft: "6px"
  },
  container: {
    width: "100%",
    margin: 0,
    paddingBottom: 0,
    paddingRight: 0,
    "& fieldset": {
      borderColor: "rgba(0, 0, 0, 0.26) !important"
    },
    "& input": {
      padding: "7px 5px",
      fontSize: "14px"
    },
    "& button": {
      padding: "0"
    },
    "& .MuiInputAdornment-positionEnd": {
      marginLeft: 0
    },
    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: 0
    }
  }
}));

export const customStyles = {
  table: {
    style: {
      background: "transparent"
    }
  },
  headRow: {
    style: {
      background: "transparent",
      marginBottom: "2px",
      borderBottom: "none"
    }
  },
  headCells: {
    style: {
      background: Base.GreyDisabled,
      marginLeft: "6px",
      fontSize: "16px",
      textAlign: "center",
      color: theme.color.primary
    }
  },
  rows: {
    style: {
      background: "transparent",
      marginBottom: "3px",
      marginTop: "3px",
      minHeight: "30px !important",
      ":not(:last-of-type)": {
        borderBottom: "none"
      }
    }
  },
  cells: {
    style: {
      marginLeft: "0",
      background: "transparent",
      fontSize: "14px",
      paddingLeft: "0",
      paddingRight: "0",
      textAlign: "center",
      width: "100%",
      "& div:nth-child(3n+1)": {
        width: "100%",
        height: "100%"
      }
    }
  }
};
