import { makeStyles } from '@mui/styles';
import { Base } from "../../../../componentes/cores";
import styled from "styled-components";
import theme from 'themes';

export const useStyles = makeStyles({
  container: {
    margin: "0 0 0px",
    width: "100%",
    "& .MuiOutlinedInput-input": {
      color: `${theme.color.secondaryText} !important`
    },
    "& .MuiFormLabel-root": {
      color: `${theme.color.secondaryText} !important`,
      backgroundColor: theme.color.secondaryBackgroud
    },
    "& .MuiFormControl-marginNormal": {
      borderRadius: "4px"
    } 
  },  
  select: {
    "& label.Mui-focused": {
      color: `${theme.color.secondaryBorderColor} !important`
    },
    "& .MuiInput-underline:after": {
      borderColor: `${theme.color.secondaryBorderColor} !important`
    },
    "& .MuiFormLabel-root": {
      color: `${theme.color.secondaryBorderColor} !important`,
    },
    "& .MuiOutlinedInput-root": {
      "& .MuiOutlinedInput-input": {
        color: `${theme.color.secondaryBorderColor} !important`
      },
      "& .MuiInputAdornment-root": {
        color: `${theme.color.secondaryBorderColor} !important`
      },
      "& fieldset": {
        borderColor: `${theme.color.secondaryBorderColor} !important`
      },
      "&:hover fieldset": {
        borderColor: `${theme.color.secondaryBorderColor} !important`
      },
      "&.Mui-focused fieldset": {
        borderColor: `${theme.color.secondaryBorderColor} !important`
      }
    },
    "& .MuiSelect-icon": {
      color: `${theme.color.secondaryText} !important`
    }
  },
  itemFiltro: {
    display: "flex",
    justifyContent: "flex-end"
  },
  buttonExcel: {
    fontSize: "18px",
    padding: "9.5px 5px",
    "& img": {
      width: "18px",
      marginLeft: "16px"
    }
  },
  buttonFiltro: {
    padding: "9.5px 5px"
  },
  tabela: {    
    "& div": { opacity: "1 !important"}    
  },
  selectData: {
    color: `${theme.color.secondaryText} !important`
  },
  linhaTooltip:{
    cursor: 'default',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
});

export const Icone = styled.img`
  margin-left: 2px;
  max-width: 18px;
  object-fit: cover;
`;
