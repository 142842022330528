import { makeStyles } from "@mui/styles";
import styled from "styled-components";
import theme from "themes";

export const useStyles = makeStyles({
  container: {
    "& .MuiAutocomplete-inputRoot .MuiAutocomplete-input": {
      height: "1.01em",
      padding: "0 0 0 20px"
    },
    margin: "0",
    width: "100%",
    "& .MuiInputBase-root": {
      height: "46px"
    },
    "& .MuiFormLabel-root": {
      lineHeight: "1"
    }
  },
  button: {
    color: `${theme.color.secondaryText} !important`,
    fontSize: "16px",
    lineHeight: "16px",
    minHeight: "46px",
    paddingTop: "5px",
    paddingBottom: "5px",

    "&:disabled": {
      backgroundColor: "#9F9F9F",
      opacity: 1,
      color: "#4F4F4F !important",
      borderColor: "#9F9F9F"
    }
  },
  botaoStatus: {
    fontSize: "14px",
    lineHeight: "14px",
    minHeight: "36px",
    padding: "8px 16px",
    borderRadius: "96px!important",
    color: "#FFFFFF",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "fit-content"
  },
  ativo: {
    background: "linear-gradient(90deg, #F722B5 0%, #FD9507 100%)"
  },
  cancelado: {
    background: "#86868B"
  },
  solicitacao: {
    background: "#332053"
  },
  devolucaoAndamento: {
    background: "#F4A330"
  },
  devolucaoAtrasada: {
    background: "#BF2A2A"
  },
  devolucaoConcluida: {
    background: "#2ABF6F"
  },
  aguardandoAtendimento: {
    background: "#5BA9D0"
  },
  suspensao: {
    backgroundColor: "#F4A330",
    color: "#FFFFFF",
    padding: "16px 8px",
    borderRadius: "5px",
    display: "flex",
    justifyContent: "space-between",
    marginTop: "10px",
    "& button": {
      border: "none",
      backgroundColor: "transparent",
      color: "#FFFFFF",
      textDecoration: "underline"
    }
  },
  card: {
    marginTop: "10px",
    marginBottom: "20px",
    background: "#FFF !important"
  },
  title: {
    color: theme.color.primary,
    fontWeight: "bold",
    fontSize: "18px !important"
  },
  link: {
    fontSize: "15px",
    fontWeight: "400",
    lineHeight: "18px",
    letterSpacing: "0em",
    textAlign: "left",
    color: "#000000",
    border: "none",
    "& span": {
      textDecoration: "underline",
      marginRight: "2px"
    },
    "& svg": {
      width: "17px",
      paddingBottom: "7px"
    },
    "&:hover": {
      color: "#000000"
    }
  }
});

export const ButtonLinkLuz = styled.button`
  font-family: "Plau";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  //margin-left: auto;
  text-decoration-line: underline;
  color: #fa3eb1;
  border: none;
  background: rgb(255, 255, 255);
  min-height: 46px;
`;

export const TabelaContent = styled.div`
  width: 100%;
  border: 2px solid #d9d9d9;
  min-height: 90px;
`;

export const Tabela = styled.table`
  width: 100%;
`;

Tabela.Thead = styled.thead``;

Tabela.Tr = styled.tr``;

Tabela.Th = styled.th`
  padding: 4px;
  text-align: center;
  border-bottom: 2px solid #d9d9d9;
  background: ${(props) => (props?.background ? props.background : "#FFFFFF")};
`;

Tabela.ThLeft = styled.th`
  padding: 4px;
  text-align: center;
  border-bottom: 2px solid #d9d9d9;
  border-right: 2px solid #d9d9d9;
  background: ${(props) => (props?.background ? props.background : "#FFFFFF")}
    ${(props) => props?.borderBottom && "border-bottom : 2px solid #D9D9D9;"};
`;

Tabela.Tbody = styled.tbody``;

Tabela.Td = styled.td`
  border-bottom: ${(props) =>
    props?.borderBottom ? "2px solid #d9d9d9" : "none"};
  border-top: 2px solid #d9d9d9;
  text-align: center;
`;

Tabela.TdLeft = styled.td`
  border-bottom: ${(props) =>
    props?.borderBottom ? "2px solid #d9d9d9" : "none"};
  border-top: 2px solid #d9d9d9;
  border-right: 2px solid #d9d9d9;
  text-align: center;
`;

export const Icone = styled.img`
  max-width: 20px;
  height: 20px;
  margin: 5px;
`;

export const TextoTroca = styled.p`
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 0;
  color: #000000;
`;
