import { makeStyles } from '@mui/styles';
import { Base } from "componentes/cores";
import themes from "themes";
import { createTheme } from "@mui/material/styles";

const theme = createTheme();

export const useStyles = makeStyles(() => ({
  containerGrafico: {
    height: "100%",
    minHeight: "300px",
    width: "100%",
    paddingBottom: "15px",
    "& .apexcharts-tooltip": {
      border: "0 none",
      background: "#647480",
      textAlign: "center",
      fontSize: "15px",
      marginTop: "-3px"
    },
    "& .apexcharts-tooltip-title": {
      background: "#647480!important",
      borderBottom: "0!important",
      color: Base.GreyCasper,
      fontSize: "10px",
      display: "none"
    },
    "& .apexcharts-tooltip-series-group": {
      marginBottom: "-5px"
    }
  },
  titulo: {
    fontWeight: "bold"
  },
  container: {
    "& .MuiInputBase-root": {
      color: themes.color.labelColorGrafico
    },
    "& .MuiInputBase-input": {
      maxWidth: "90px"
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "0 none"
    },
    "& .MuiButtonBase-root": {
      color: themes.color.labelColorGrafico
    }
  },
  itemDados:{
    display: 'inline-table',
    color:'black', 
    fontSize:14, 
    textAlign:'center',
    width: 49,
    height: 30,
    // border:1,
    // borderStyle:'solid',
    // borderColor:'red',
    marginLeft: 12
  },
  boxLegendaMenor:{
    marginLeft:50,
    marginTop:8,
    width:50,
    height:10
  },
  boxTotal:{
    position: 'absolute',
    marginTop:232,
    zIndex: 9999,
    marginLeft: 15
  },
  noData: {
    textAlign: "center",
    width: "100%",
    "& div": {
      margin: "50px auto 0",
      maxWidth: "80%"
    }
  },
  arrowBox: {
    position: "relative",
    background: "#647480",
    padding: "8px"
  },
  arrowBoxTitle: {
    color: Base.GreyCasper,
    fontSize: "10px"
  },
  info: {
    color: "#FFFFFF",
    marginTop: "17px"
  },
  datePickerWrapper: {
    padding: '0!important',
    width: '100%',
    color: '#fff',
    '& svg': {
      color: '#fff',
    },
    '& fieldset': {
      borderColor: 'transparent!important',
      color: '#fff',
    },
    "& > div.MuiGrid-container": {
      width: '100%',
      padding: 0,
      margin: 0,
      "& > div.MuiGrid-item": {
        width: '100%',
        padding: 0,
        margin: 0,
        color: '#fff',
        "& > div.MuiGrid-container": {
          width: '100%',
          padding: 0,
          margin: 0,
          "& > div": {
            width: '100%',
            padding: 0,
            margin: 0,
            "& div": {
              color: '#fff'
            }
          }
        }
      }
    }
  }
}));
