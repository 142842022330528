import getDomain from "global/getDomain";

import thymos from "./thymos/paginas/home";
import delta from "./delta/paginas/home";
import luz from "./luz/paginas/home";

let pagina = {
  delta: delta,
  thymos: thymos,
  luz: luz
};

export default pagina[getDomain()];
