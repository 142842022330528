import { makeStyles } from '@mui/styles';
import styled from "styled-components";
import { Base } from "../cores";
import theme from 'themes';

export const useStyles = makeStyles(() => ({
  formControl: {
    fontFamily: theme.font.family
  },
  select: {
    borderRadius: "4px",
    fontSize: "16px",
    marginBottom: (props) => props.marginBottom,
    maxHeight: (props) => props.maxHeight,
    maxWidth: (props) => props.maxWidth,
    "& .MuiSelect-selectMenu div": {
      padding: 0
    }
  },
  menuItem: {
    backgroundColor: Base.White,
    paddingTop: "0",
    "&:hover": {
      backgroundColor: Base.White
    },
    "& .MuiMenuItem-root": {
      paddingTop: "0",
      paddingBottom: "0"
    },
    "& .MuiListItem-button:hover": {
      backgroundColor: Base.White
    }
  },
  selectIcon: {
    color: theme.color.primaryBackgroud,
    marginRight: "4px",
    position: "absolute",
    right: 0,
    top: "calc(50% - 12px)",
    "pointer-events": "none"
  },
  menuIcon: {
    marginRight: "10px"
  },
  adornment: {
    marginRight: "10px"
  }
}));

export const Label = styled.div`
  padding-top: 12px;
  padding-bottom: 12px;
  pointer-events: auto;
`;
