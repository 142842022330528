import MICROSERVICO from "global/enumeradores/microServico";
import obterApi from "servicos/api";
import { saveAs } from "file-saver";

const api = () => obterApi(MICROSERVICO.PROJECAOTARIFARIA);

export const getExcelComposicaoCCC = async (
  idcenario,
  anoini,
  anofim,
  nomeArquivo
) => {
  let resposta;
  await api()
    .get(
      `v1/ExportarExcel/exportarplanilha/composicaoccc?idcenario=${idcenario}&anoini=${anoini}&anofim=${anofim}`,
      {
        headers: {
          "Content-Disposition": "attachment;",
          "Content-Type":
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        },
        responseType: "arraybuffer"
      }
    )
    .then((response) => {
      if (response.status === 200) {
        const blob = new Blob([response.data], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        });
        saveAs(blob, nomeArquivo);
        resposta = "Sucesso";
      } else if (response.status === 202) {
        resposta = new TextDecoder().decode(response.data);
      }
    });
  return resposta;
};

export const getExcelOrcamentoCDE = async (
  idcenario,
  anoini,
  anofim,
  nomeArquivo
) => {
  let resposta;
  await api()
    .get(
      `v1/ExportarExcel/exportarplanilha/orcamentocde?idcenario=${idcenario}&anoini=${anoini}&anofim=${anofim}`,
      {
        headers: {
          "Content-Disposition": "attachment;",
          "Content-Type":
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        },
        responseType: "arraybuffer"
      }
    )
    .then((response) => {
      if (response.status === 200) {
        const blob = new Blob([response.data], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        });
        saveAs(blob, nomeArquivo);
        resposta = "Sucesso";
      } else if (response.status === 202) {
        resposta = new TextDecoder().decode(response.data);
      }
    });
  return resposta;
};

export const getExcelEfeitoConsumidorGrupo = async (
  idgrupo,
  listadistrib,
  listasubgrupos,
  listaanos,
  nomeArquivo
) => {
  let resposta;
  await api()
    .get(
      `v1/ExportarExcel/exportarplanilha/efeito-consumidor-grupo?idgrupoprojecoes=${idgrupo}&listadistribuidora=${listadistrib}&listasubgrupo=${listasubgrupos}&listaanos=${listaanos}`,
      {
        headers: {
          "Content-Disposition": "attachment;",
          "Content-Type":
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        },
        responseType: "arraybuffer"
      }
    )
    .then((response) => {
      if (response.status === 200) {
        const blob = new Blob([response.data], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        });
        saveAs(blob, nomeArquivo);
        resposta = "Sucesso";
      } else if (response.status === 202) {
        resposta = new TextDecoder().decode(response.data);
      }
    });
  return resposta;
};

export const getExcelTarifaConsolidadaGrupo = async (
  idgrupo,
  listadistrib,
  anoiniciohist,
  nomeArquivo
) => {
  let resposta;
  await api()
    .get(`v1/ExportarExcel/exportarplanilha/tarifa-consolidada-grupo?idgrupoprojecoes=${idgrupo}&listadistribuidora=${listadistrib}&anoiniciohist=${anoiniciohist}&nomeArquivo=${nomeArquivo}`)
    .then((response) => {
      resposta = response;
    });
  return resposta;
};

export const getPlanilhaTarifaConsolidadaGrupo = async (nomeArquivo) => {
  let resposta;
  await api()
    .get(`v1/ExportarExcel/obterplanilha/tarifa-consolidada-grupo?nomeArquivo=${nomeArquivo}`,
      {
      headers: {
        "Content-Disposition": "attachment;",
        "Content-Type":
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      },
      responseType: "arraybuffer"
    })
    .then((response) => {
      resposta = response;
    });
  return resposta;
};

export const getExcelRevisaoParcelaB = async (idgrupo, nomeArquivo) => {
  let resposta;
  await api()
    .get(
      `v1/ExportarExcel/exportarplanilha/revisaoparcelab?idgrupoprojecoes=${idgrupo}`,
      {
        headers: {
          "Content-Disposition": "attachment;",
          "Content-Type":
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        },
        responseType: "arraybuffer"
      }
    )
    .then((response) => {
      if (response.status === 200) {
        const blob = new Blob([response.data], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        });
        saveAs(blob, nomeArquivo);
        resposta = "Sucesso";
      } else if (response.status === 202) {
        resposta = new TextDecoder().decode(response.data);
      }
    });
  return resposta;
};

export const getExcelCustosProjetados = async (idgrupo, nomeArquivo) => {
  let resposta;
  await api()
    .get(
      `v1/ExportarExcel/exportarplanilha/custosprojetados?idgrupoprojecoes=${idgrupo}`,
      {
        headers: {
          "Content-Disposition": "attachment;",
          "Content-Type":
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        },
        responseType: "arraybuffer"
      }
    )
    .then((response) => {
      if (response.status === 200) {
        const blob = new Blob([response.data], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        });
        saveAs(blob, nomeArquivo);
        resposta = "Sucesso";
      } else if (response.status === 202) {
        resposta = new TextDecoder().decode(response.data);
      }
    });
  return resposta;
};

export const getExcelRelatorioRiscoHidrologico = async (
  idgrupo,
  nomeArquivo
) => {
  let resposta;
  await api()
    .get(
      `v1/ExportarExcel/exportarplanilha/memoria-rh?idgrupoprojecoes=${idgrupo}`,
      {
        headers: {
          "Content-Disposition": "attachment;",
          "Content-Type":
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        },
        responseType: "arraybuffer"
      }
    )
    .then((response) => {
      if (response.status === 200) {
        const blob = new Blob([response.data], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        });
        saveAs(blob, nomeArquivo);
        resposta = "Sucesso";
      } else if (response.status === 202) {
        resposta = new TextDecoder().decode(response.data);
      }
    });
  return resposta;
};

export const getExcelRelatorioFinanceiro = async (idgrupo, nomeArquivo) => {
  let resposta;
  await api()
    .get(
      `v1/ExportarExcel/exportarplanilha/financeiro?idgrupoprojecoes=${idgrupo}`,
      {
        headers: {
          "Content-Disposition": "attachment;",
          "Content-Type":
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        },
        responseType: "arraybuffer"
      }
    )
    .then((response) => {
      if (response.status === 200) {
        const blob = new Blob([response.data], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        });
        saveAs(blob, nomeArquivo);
        resposta = "Sucesso";
      } else if (response.status === 202) {
        resposta = new TextDecoder().decode(response.data);
      }
    });
  return resposta;
};

export const getExcelMercadoGrupo = async (
  idgrupo,
  listadistrib,
  anoiniciohist,
  nomeArquivo
) => {
  let resposta;
  await api()
    .get(
      `v1/ExportarExcel/exportarplanilha/mercado-grupo?idgrupoprojecoes=${idgrupo}&listadistribuidora=${listadistrib}&anoiniciohist=${anoiniciohist}`,
      {
        headers: {
          "Content-Disposition": "attachment;",
          "Content-Type":
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        },
        responseType: "arraybuffer"
      }
    )
    .then((response) => {
      if (response.status === 200) {
        const blob = new Blob([response.data], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        });
        saveAs(blob, nomeArquivo);
        resposta = "Sucesso";
      } else if (response.status === 202) {
        resposta = new TextDecoder().decode(response.data);
      }
    });
  return resposta;
};

export const getExcelCustoTarifa = async (
  idgrupo,
  listadistrib,
  anoiniciohist,
  nomeArquivo
) => {
  let resposta;
  await api()
    .get(
      `v1/ExportarExcel/exportarplanilha/custo-tarifa-grupo?idgrupoprojecoes=${idgrupo}&listadistribuidora=${listadistrib}&anoiniciohist=${anoiniciohist}`,
      {
        headers: {
          "Content-Disposition": "attachment;",
          "Content-Type":
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        },
        responseType: "arraybuffer"
      }
    )
    .then((response) => {
      if (response.status === 200) {
        const blob = new Blob([response.data], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        });
        saveAs(blob, nomeArquivo);
        resposta = "Sucesso";
      } else if (response.status === 202) {
        resposta = new TextDecoder().decode(response.data);
      }
    });
  return resposta;
};

export const getExcelComposicaoEER = async (
  idcenario,
  anoini,
  anofim,
  nomeArquivo
) => {
  let resposta;
  await api()
    .get(
      `v1/ExportarExcel/exportarplanilha/composicao-eer?idcenario=${idcenario}&anoini=${anoini}&anofim=${anofim}`,
      {
        headers: {
          "Content-Disposition": "attachment;",
          "Content-Type":
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        },
        responseType: "arraybuffer"
      }
    )
    .then((response) => {
      if (response.status === 200) {
        const blob = new Blob([response.data], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        });
        saveAs(blob, nomeArquivo);
        resposta = "Sucesso";
      } else if (response.status === 202) {
        resposta = new TextDecoder().decode(response.data);
      }
    });
  return resposta;
};

export const getExcelEfetividadePorGrupo = async (
  idgrupo,
  listadist,
  nomeArquivo
) => {
  let resposta;
  await api()
    .get(
      `v1/ExportarExcel/exportarplanilha/efetividade-grupo?idgrupoprojecoes=${idgrupo}&listadistribuidora=${listadist}`,
      {
        headers: {
          "Content-Disposition": "attachment;",
          "Content-Type":
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        },
        responseType: "arraybuffer"
      }
    )
    .then((response) => {
      if (response.status === 200) {
        const blob = new Blob([response.data], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        });
        saveAs(blob, nomeArquivo);
        resposta = "Sucesso";
      } else if (response.status === 202) {
        resposta = new TextDecoder().decode(response.data);
      }
    });
  return resposta;
};

export const getExcelCotacoesSeries = async (
  listaseries,
  idcenario,
  nomeArquivo
) => {
  let resposta;
  await api()
    .get(
      `v1/ExportarExcel/exportarplanilha/cotacoes-por-series?series=${listaseries}&idcenario=${idcenario}`,
      {
        headers: {
          "Content-Disposition": "attachment;",
          "Content-Type":
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        },
        responseType: "arraybuffer"
      }
    )
    .then((response) => {
      if (response.status === 200) {
        const blob = new Blob([response.data], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        });
        saveAs(blob, nomeArquivo);
        resposta = "Sucesso";
      } else if (response.status === 202) {
        resposta = new TextDecoder().decode(response.data);
      }
    });
  return resposta;
};

export const getExcelCustosConsolidados = async (
  idgrupo,
  anoiniciohist,
  nomeArquivo
) => {
  let resposta;
  await api()
    .get(
      `v1/ExportarExcel/exportarplanilha/custo-tarifa-consolidada?idgrupoprojecoes=${idgrupo}&anoiniciohist=${anoiniciohist}`,
      {
        headers: {
          "Content-Disposition": "attachment;",
          "Content-Type":
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        },
        responseType: "arraybuffer"
      }
    )
    .then((response) => {
      if (response.status === 200) {
        const blob = new Blob([response.data], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        });
        saveAs(blob, nomeArquivo);
        resposta = "Sucesso";
      } else if (response.status === 202) {
        resposta = new TextDecoder().decode(response.data);
      }
    });
  return resposta;
};

export const getExcelResumoEnergia = async (
  idgrupo,
  anoiniciohist,
  nomeArquivo
) => {
  let resposta;
  await api()
    .get(
      `v1/ExportarExcel/exportarplanilha/resumo-energia?idgrupoprojecoes=${idgrupo}&anoiniciohist=${anoiniciohist}`,
      {
        headers: {
          "Content-Disposition": "attachment;",
          "Content-Type":
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        },
        responseType: "arraybuffer"
      }
    )
    .then((response) => {
      if (response.status === 200) {
        const blob = new Blob([response.data], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        });
        saveAs(blob, nomeArquivo);
        resposta = "Sucesso";
      } else if (response.status === 202) {
        resposta = new TextDecoder().decode(response.data);
      }
    });
  return resposta;
};

export const getExcelPmix = async (
  idgrupo,
  listadist,
  anoinicio,
  nomeArquivo,
  consolidado,
  tipoTarifa
) => {
  let resposta;
  await api()
    .get(
      `v1/ExportarExcel/exportarplanilha/pmix?idgrupoprojecoes=${idgrupo}&listadistribuidora=${listadist}&AnoInicioHist=${anoinicio}&consolidado=${consolidado}&tipotarifa=${tipoTarifa}`,
      {
        headers: {
          "Content-Disposition": "attachment;",
          "Content-Type":
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        },
        responseType: "arraybuffer"
      }
    )
    .then((response) => {
      if (response.status === 200) {
        const blob = new Blob([response.data], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        });
        saveAs(blob, nomeArquivo);
        resposta = "Sucesso";
      } else if (response.status === 202) {
        resposta = new TextDecoder().decode(response.data);
      }
    });
  return resposta;
};

export const getExcelProinfaGeral = async (
  idcenario,
  anoini,
  anofim,
  nomeArquivo
) => {
  let resposta;
  await api()
    .get(
      `v1/ExportarExcel/exportarplanilha/proinfa-brasil?AnoInicio=${anoini}&AnoFim=${anofim}&Cenario=${idcenario}`,
      {
        headers: {
          "Content-Disposition": "attachment;",
          "Content-Type":
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        },
        responseType: "arraybuffer"
      }
    )
    .then((response) => {
      if (response.status === 200) {
        const blob = new Blob([response.data], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        });
        saveAs(blob, nomeArquivo);
        resposta = "Sucesso";
      } else if (response.status === 202) {
        resposta = new TextDecoder().decode(response.data);
      }
    });
  return resposta;
};

export const getExcelComposicaoCCCeOrcamentoCDE = async (
  idcenario,
  anoini,
  anofim,
  nomeArquivo
) => {
  let resposta;
  await api()
    .get(
      `v1/ExportarExcel/exportarplanilha/compccc-orcamcde?idcenario=${idcenario}&anoini=${anoini}&anofim=${anofim}`,
      {
        headers: {
          "Content-Disposition": "attachment;",
          "Content-Type":
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        },
        responseType: "arraybuffer"
      }
    )
    .then((response) => {
      if (response.status === 200) {
        const blob = new Blob([response.data], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        });
        saveAs(blob, nomeArquivo);
        resposta = "Sucesso";
      } else if (response.status === 202) {
        resposta = new TextDecoder().decode(response.data);
      }
    });
  return resposta;
};

export const getExcelEfeitoConsTarifMed = async (
  idgrupo,
  listadistrib,
  anoiniciohist,
  nomeArquivo,
  usuario,
  consolidado,
  tipoTarifa
) => {
  let resposta;
  await api()
    .get(
      `v1/ExportarExcel/exportarplanilha/tarifa-media-efeito-cons?idgrupoprojecoes=${idgrupo}&distribuidoras=${listadistrib}&anoinicialhist=${anoiniciohist}&usuario=${usuario}&consolidado=${consolidado}&tipotarifa=${tipoTarifa}&nomearquivo=${nomeArquivo}`
    )
    .then((response) => {
      if (response.status === 200) {
        resposta = response;
      } else if (response.status === 202) {
        resposta = new TextDecoder().decode(response.data);
      }
    });
  return resposta;
};

export const getExcelReceitaBandeiraGrupo = async (
  idgrupo,
  listadist,
  anoinicio,
  nomeArquivo
) => {
  let resposta;
  await api()
    .get(
      `v1/ExportarExcel/exportarplanilha/calcular-receita-bandeira-grupo?idProjecaoGrupo=${idgrupo}&distribuidoras=${listadist}&AnoInicioHist=${anoinicio}`,
      {
        headers: {
          "Content-Disposition": "attachment;",
          "Content-Type":
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        },
        responseType: "arraybuffer"
      }
    )
    .then((response) => {
      if (response.status === 200) {
        const blob = new Blob([response.data], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        });
        saveAs(blob, nomeArquivo);
        resposta = "Sucesso";
      } else if (response.status === 202) {
        resposta = new TextDecoder().decode(response.data);
      }
    });
  return resposta;
};

export const getExcelTarifaAberta = async (
  idgrupo,
  listadist,
  tipoTarifa,
  nomeArquivo,
  nomeUsuario
) => {
  let resposta;
  await api()
    .get(
      `v1/ExportarExcel/exportarplanilha/tarifa-aberta?idProjecaoGrupo=${idgrupo}&distribuidoras=${listadist}&tipotarifa=${tipoTarifa}&usuario=${nomeUsuario}`,
      {
        headers: {
          "Content-Disposition": "attachment;",
          "Content-Type":
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        },
        responseType: "arraybuffer"
      }
    )
    .then((response) => {
      if (response.status === 200) {
        const blob = new Blob([response.data], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        });
        saveAs(blob, nomeArquivo);
        resposta = "Sucesso";
      } else if (response.status === 202) {
        resposta = new TextDecoder().decode(response.data);
      }
    });
  return resposta;
};

export const getExcelImpactoCustos = async (listadist, nomeArquivo) => {
  let resposta;
  await api()
    .get(
      `v1/ExportarExcel/exportarplanilha/impacto-custo-distribuidora?distribuidoras=${listadist}`,
      {
        headers: {
          "Content-Disposition": "attachment;",
          "Content-Type":
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        },
        responseType: "arraybuffer"
      }
    )
    .then((response) => {
      if (response.status === 200) {
        const blob = new Blob([response.data], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        });
        saveAs(blob, nomeArquivo);
        resposta = "Sucesso";
      } else if (response.status === 202) {
        resposta = new TextDecoder().decode(response.data);
      }
    });
  return resposta;
};

export const getExcelNivelContratacao = async (
  listadist,
  cenario,
  nomeArquivo
) => {
  let resposta;
  await api()
    .get(
      `v1/ExportarExcel/exportarplanilha/nivel-contratacao?distribuidoras=${listadist}&idcenario=${cenario}`,
      {
        headers: {
          "Content-Disposition": "attachment;",
          "Content-Type":
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        },
        responseType: "arraybuffer"
      }
    )
    .then((response) => {
      if (response.status === 200) {
        const blob = new Blob([response.data], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        });
        saveAs(blob, nomeArquivo);
        resposta = "Sucesso";
      } else if (response.status === 202) {
        resposta = new TextDecoder().decode(response.data);
      }
    });
  return resposta;
};
