import React from "react";
import PropTypes from "prop-types";
import ReactApexChart from "react-apexcharts";
import { Grid, Card, Box } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Refrigerador from "../../../../assets/icones/categorias/refrigerador.svg";
import Fogao from "../../../../assets/icones/categorias/fogao.svg";
import Chuveiro from "../../../../assets/icones/categorias/chuveiro.svg";
import MaquinaLavar from "../../../../assets/icones/categorias/maquina-lavar.svg";
import ArCondicionado from "../../../../assets/icones/categorias/ar-condicionado.svg";
import Lampada from "../../../../assets/icones/categorias/lampada.svg";
import IconeInformacao from "../../../../assets/icones/categorias/informacao.svg";
import formatarValor from "../../../../global/utils/formatarValor";
import Percentual from "../Percentual";
import { useStyles, Kwh } from "./style";

const ConsumoCategorias = ({
  setModalAberto,
  selecionarGrupo,
  dados,
  carregando
}) => {
  const classes = useStyles();

  const seriesDados = dados?.grupos?.map((item) => item.consumo) || [];
  const labelsDados = dados?.grupos?.map((item) => item.descricao) || [];
  const cores = [
    {
      id: 1,
      cor: "#2ABF6F",
      icone: Refrigerador
    },
    { id: 2, cor: "#5BA9D0", icone: ArCondicionado },
    { id: 3, cor: "#FA5A61", icone: Chuveiro },
    { id: 4, cor: "#FA3EB1", icone: MaquinaLavar },
    { id: 5, cor: "#F4A330", icone: Fogao },
    { id: 9, cor: "#A72474", icone: Lampada }
  ];

  const dadosFiltrados = cores.filter((cor) =>
    dados?.grupos?.find((item) => item.id === cor.id)
  );
  const coresGrafico = dadosFiltrados?.map((dado) => dado.cor) || [];

  const dadosGrafico = {
    series: seriesDados,
    options: {
      chart: {
        type: "donut"
      },
      legend: {
        show: false
      },
      colors: coresGrafico,
      stroke: {
        width: 0
      },
      plotOptions: {
        pie: {
          expandOnClick: false,
          offsetX: 0,
          donut: {
            labels: {
              show: false
            },
            size: "60%"
          },
          customScale: 1
        }
      },
      labels: labelsDados
    }
  };

  return (
    <>
      <Grid container p={2}>
        <Grid
          item
          xs={12}
          sx={{ display: "flex", justifyContent: { xs: "space-between", sm: "flex-start" } }}
        >
          <h1 className={classes.titulo}>Consumo por categoria</h1>
          <button
            type="button"
            className={classes.botao}
            onClick={() => setModalAberto(true)}
          >
            <img
              src={IconeInformacao}
              className={classes.cardIcone}
              alt="ícone consumo"
            />
          </button>
        </Grid>
        {dados?.grupos?.length > 0 &&
        dadosFiltrados?.length === dados?.grupos?.length ? (
          <>
            <Grid
              item
              xs={0}
              sm={2}
              lg={4}
              sx={{ display: { xs: "none", sm: "block" } }}
            />
            <Grid item xs={12} sm={8} lg={4} sx={{ position: "relative" }}>
              <ReactApexChart
                options={dadosGrafico?.options}
                series={dadosGrafico?.series}
                type="donut"
                height="300"
              />
              <div className={classes.textoCentral}>
                <p>Consumo de</p>
                <p>energia no período:</p>
                <h2>{formatarValor(dados?.valorFinanceiroTotal)}</h2>
                <h3>{dados?.consumoTotal && `${dados?.consumoTotal} kwh`}</h3>
              </div>
            </Grid>
            <Grid item xs={0} sm={2} lg={4} sx={{ display: "none" }} />
            <Grid container>
              {dados.grupos.map((item, index) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  lg={4}
                  onClick={() =>
                    selecionarGrupo({
                      id: item.id,
                      ...dadosFiltrados[index]
                    })
                  }
                  className={classes.card}
                  key={item.id}
                >
                  <Card className={`px-2 py-3 m-2 ${classes.sombra}`}>
                    <Box marginBottom={1} className={classes.cardConteudo}>
                      <div>
                        <img
                          src={dadosFiltrados[index]?.icone || Lampada}
                          className={classes.cardIcone}
                          alt="ícone refrigerador"
                        />
                        {item.descricao}
                      </div>
                      <ArrowForwardIosIcon className={classes.cardSeta} />
                    </Box>
                    <div className={classes.cardConteudo}>
                      <h1 className={classes.cardPreco}>
                        {formatarValor(item.valorFinanceiro)}
                      </h1>
                      <div className={classes.cardConteudo}>
                        <Kwh corTexto={dadosFiltrados[index].cor}>
                          {`${item.consumo} kWh`}
                        </Kwh>
                        <Percentual
                          valor={item?.percentualComparativoMesAnterior}
                        />
                      </div>
                    </div>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </>
        ) : (
          <Grid item xs={12} display="flex">
            <div className={classes.semDados}>
              {!carregando && "Dados não encontrados!"}
            </div>
          </Grid>
        )}
      </Grid>
    </>
  );
};

ConsumoCategorias.propTypes = {
  carregando: PropTypes.bool,
  dados: PropTypes.shape({
    consumoTotal: PropTypes.number,
    valorFinanceiroTotal: PropTypes.number,
    grupos: PropTypes.arrayOf({
      id: PropTypes.number,
      consumo: PropTypes.number,
      percentualComparativoMesAnterior: PropTypes.number,
      valorFinanceiro: PropTypes.number,
      descricao: PropTypes.string
    })
  }),
  setModalAberto: PropTypes.func,
  selecionarGrupo: PropTypes.func
};

ConsumoCategorias.defaultProps = {
  carregando: false,
  dados: {},
  setModalAberto: () => {},
  selecionarGrupo: () => {}
};

export default ConsumoCategorias;
