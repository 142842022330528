import React, { useState, useEffect, useRef } from "react";
import Grid from "@mui/material/Grid";
import moment from "moment";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { InputTextoData, Botao } from "componentes";
import { useStyles, Celula, BotaoMenu, Menu } from "./style";

export default class ColunaData {
  constructor(
    label,
    nome,
    datas,
    onSelecionarDatas,
    minWidth = null,
    maxWidth = null
  ) {
    const classes = useStyles();
    const menuRef = useRef(null);
    const [open, setOpen] = useState(false);
    const [dataInicial, setDataInicial] = useState(datas?.inicial || "");
    const [dataFinal, setDataFinal] = useState(datas?.final || "");

    const handleClick = () => {
      setOpen(!open);
    };

    const handleClose = (event) => {
      const classes = document.getElementsByClassName("MuiPickersLayout-root");
      if (
        menuRef &&
        menuRef.current &&
        !menuRef.current.contains(event.target) &&
        classes.length === 0
      ) {
        setOpen(false);
      }
    };

    const limparCampos = () => {
      if (!datas) {
        setDataFinal();
        setDataInicial();
      }
    };

    const alterarDatas = () => {
      onSelecionarDatas({ dataInicial, dataFinal });
      setOpen(false);
    };

    useEffect(() => {
      document.addEventListener("click", handleClose, true);
      limparCampos();
      return () => {
        document.removeEventListener("click", handleClose, true);
      };
    }, [open]);

    this.name = (
      <div>
        <BotaoMenu
          id={`${nome}Botao`}
          aria-controls={`${nome}Menu`}
          aria-haspopup="true"
          component="button"
          onClick={handleClick}
          disableElevation
          endIcon={open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        >
          <span className={classes.textoBotao}>{label}</span>
        </BotaoMenu>
        {open && (
          <Menu
            open={Boolean(open?.id === `${nome}Botao`)}
            className={classes.menu}
            ref={menuRef}
          >
            <Grid container spacing={1}>
              <Grid item xs={4}>
                <InputTextoData
                  type="text"
                  id="dataInicial"
                  name="dataInicial"
                  label="Data inicial"
                  customValue={dataInicial}
                  onChange={(data, contexto) => {
                    if (!contexto?.validationError) setDataInicial(data);
                  }}
                  renderIconShowHide={false}
                  className={{ container: classes.inputData }}
                  minDate={window.moment().subtract(100, "years").toDate()}
                  placement="top"
                />
              </Grid>
              <Grid item xs={4}>
                <InputTextoData
                  type="text"
                  id="dataFinal"
                  name="dataFinal"
                  label="Data final"
                  customValue={dataFinal}
                  onChange={(data, contexto) => {
                    if (!contexto?.validationError) setDataFinal(data);
                  }}
                  renderIconShowHide={false}
                  className={{ container: classes.inputData }}
                  minDate={window.moment().subtract(100, "years").toDate()}
                  placement="top"
                />
              </Grid>
              <Grid item xs={4}>
                <Botao
                  label="Buscar"
                  className={classes.botaoBuscar}
                  onClick={alterarDatas}
                  selected
                  disabled={!dataFinal || !dataInicial}
                />
              </Grid>
            </Grid>
          </Menu>
        )}
      </div>
    );

    this.selector = label;
    this.cell = (row) => (
      <Celula>
        {row[nome] ? moment(row[nome]).format("DD/MM/YYYY") : "-"}
      </Celula>
    );
    this.maxWidth = maxWidth;
    this.minWidth = minWidth;
  }
}
