import MICROSERVICO from "../global/enumeradores/microServico";
import obterApi from "./api";

const apiManagement = () => obterApi(MICROSERVICO.MANAGEMENT);

export const listarTipoDocumento  = async () => {
  return apiManagement().get(
    `v1/TipoDocumento`
  );
};

export const listarTipoDocumentoPorUnidadeId  = async (unidadeId) => {
  return apiManagement().get(
    `v1/TipoDocumento/ListaPorUnidadeId/${unidadeId}`
  );
};

export const listarTipoDocumentoOperacaoControle  = async () => {
  return apiManagement().get(
    `v1/TipoDocumento/ListaParaOperacaoControle`
  );
};
