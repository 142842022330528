import { makeStyles } from '@mui/styles';
import { Base } from "../../../componentes/cores";
import styled from "styled-components";
import theme from 'themes';
import { createTheme } from "@mui/material/styles";

const th = createTheme();

export const useStyles = makeStyles(() => ({
  container: {
    margin: "0 0 24px",
    width: "100%"
  },
  search: {
    "& label.Mui-focused": {
      color: `${Base.OffWhite} !important`
    },
    "& .MuiInput-underline:after": {
      borderColor: Base.OffWhite
    },
    "& .MuiFormLabel-root": {
      color: `${Base.OffWhite} !important`
    },
    "& .MuiOutlinedInput-root": {
      "& .MuiOutlinedInput-input": {
        color: `${Base.OffWhite} !important`
      },
      "& .MuiInputAdornment-root": {
        color: Base.BlackBastille
      },
      "& fieldset": {
        borderColor: Base.OffWhite
      },
      "&:hover fieldset": {
        borderColor: Base.OffWhite
      },
      "&.Mui-focused fieldset": {
        borderColor: Base.OffWhite
      }
    },
    "& .MuiOutlinedInput-input": {
      padding: "13.5px 14px"
    }
  },
  select: {
    "& .MuiOutlinedInput-input": {
      padding: "13.5px 14px"
    },
    "& label.Mui-focused": {
      color: `${Base.OffWhite} !important`
    },
    "& .MuiInput-underline:after": {
      borderColor: Base.OffWhite
    },
    "& .MuiFormLabel-root": {
      color: `${Base.OffWhite} !important`
    },
    "& .MuiOutlinedInput-root": {
      "& .MuiOutlinedInput-input": {
        color: `${Base.OffWhite} !important`
      },
      "& .MuiInputAdornment-root": {
        color: `${Base.OffWhite} !important`
      },
      "& fieldset": {
        borderColor: Base.OffWhite
      },
      "&:hover fieldset": {
        borderColor: Base.OffWhite
      },
      "&.Mui-focused fieldset": {
        borderColor: Base.OffWhite
      }
    },
    "& .MuiSelect-icon": {
      color: Base.White
    }
  },
  itemFiltro: {
    display: "flex",
    justifyContent: "flex-end",
    [th.breakpoints.down('lg')]: {
      justifyCcontent: "normal",
      width: '100% !important',
      display: "inline",
    },

    

  },
  buttonFiltro: {
    fontSize: "16px",
    maxWidth: "100%",
    fontWeight: "700",
    marginTop: "5px"
  },
  tabela: {
    "& .MuiButton-root": {
      minWidth: "0px",
      "& .MuiSvgIcon-root": {
        fontSize: "20px",
        marginLeft: "2px"
      }
    }
  },
  linhaTooltip:{
    cursor: 'default',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  fundoCelulaMobile:{
    backgroundColor: '#282E3D',
    marginBottom: '23px'
  },
  botao: {
    color: `${theme.color.secondaryText} !important`,
    fontSize: '42px'
  },
  tituloAccordion: {
    color: `${theme.color.primary} !important`,
    fontWeight: "700 !important"
  },
  subtituloAccordion: {
    color: `${theme.color.secondary} !important`,
    fontWeight: "700 !important"
  }
}));

export const Icone = styled.img`
  margin-left: 2px;
  max-width: 18px;
  object-fit: cover;
`;
