import { getByUnidadeId } from "../../../servicos/boletasGDServico";
import RetornoEndpointDto from "../../../global/dto/retornoEndpointDto";
import { store } from "../../../global/redux";
import { alertaExibir } from "../../../global/redux/modulos/alertas/actions";
import { listarUsuarioOperacaoPorUnidadeId } from "../../../servicos/usuariosServico";
import {
  obterOperacaoControlePorUnidadeId,
  listarOperacaoTipoProblema,
  atualizarOperacaoControle,
  enviarEmailOperacaoControle,
  salvarOperacaoTrocaTitularidade,
  atualizarOperacaoTrocaTitularidade,
  associarClienteNaUC,
  atualizarPassoOnboarding,
  listarOperacaoStatusTroca,
  uploadOperacaoTrocaTitularidadeFatura,
  downloadFatura,
  verificarCadastroBoleta
} from "../../../servicos/operacaoControleServico";
import {
  listarTipoDocumentoPorUnidadeId,
  listarTipoDocumentoOperacaoControle
} from "../../../servicos/tipoDocumentoServico";
import { obterUrlDocumentoCliente } from "../../../servicos/clienteDocumento";
import {
  obterClienteUnidade,
  obterListaReduzida
} from "../../../servicos/clientesServico";
import { obterListaComunicacao } from "../../../servicos/comunicacaoCasosServico";
import { obterUnidadeUsinaPorUnidadeId } from "../../../servicos/unidadesServico";
import {
  getCancelamentoPaginado,
  getCancelamentoSuspensao,
  getCancelamentoMotivos,
  postarCancelamentoSimulacao,
  getCancelamento,
  postarCancelamento,
  getCancelamentoCargoOfficer,
  suspenderCancelamento,
  validarSuspenderCancelamento,
  getSupensaoCancelamento,
  atualizarDevolverCancelamento,
  postarDevolverCancelamento,
  getDevolucao,
  getStatusCancelamento,
  atualizarSFIntegracaoEnergiaChamado,
  postCancelamentoMotivo
} from "../../../servicos/cancelamentoServico";

export default class preFaturamentoHelper {
  static async obterOperacaoControlePorUnidadeId(id) {
    try {
      const resultado = await obterOperacaoControlePorUnidadeId(id);

      if (resultado.status === 204) {
        store.dispatch(
          alertaExibir({
            tipo: "danger",
            mensagem: "Nenhuma operação encontrada!"
          })
        );

        return;
      }

      return resultado;
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "danger",
          mensagem:
            error?.response?.data?.message ??
            "Ocorreu um erro ao obter ao obter operação!"
        })
      );
    }
  }

  static async listarOperacaoTipoProblema() {
    try {
      const resultado = await listarOperacaoTipoProblema();

      if (resultado.status === 204) {
        store.dispatch(
          alertaExibir({
            tipo: "danger",
            mensagem: "Nenhuma Operação Tipo Problema encontrada!"
          })
        );

        return;
      }

      return resultado;
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "danger",
          mensagem:
            error?.response?.data?.message ??
            "Ocorreu um erro ao obter Lista de Problemas!"
        })
      );
    }
  }

  static async listarUsuarioOperacaoPorUnidadeId(id) {
    try {
      const resultado = await listarUsuarioOperacaoPorUnidadeId(id);

      if (resultado.status === 204) return null;

      return resultado;
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "danger",
          mensagem:
            error?.response?.data?.message ??
            "Ocorreu um erro ao obter Tipo de Problema!"
        })
      );
    }
  }

  static async listarTipoDocumentoOperacaoControle() {
    try {
      const resultado = await listarTipoDocumentoOperacaoControle();

      if (resultado.status === 204) {
        store.dispatch(
          alertaExibir({
            tipo: "danger",
            mensagem: "Nenhuma Operação Tipo de Documento encontrado!"
          })
        );

        return;
      }

      return resultado;
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "danger",
          mensagem:
            error?.response?.data?.message ??
            "Ocorreu um erro ao obter Lista de Tipo Documento!"
        })
      );
    }
  }

  static async listarTipoDocumentoPorUnidadeId(unidadeId) {
    try {
      const resultado = await listarTipoDocumentoPorUnidadeId(unidadeId);

      if (resultado.status === 204) return;

      return resultado;
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "danger",
          mensagem:
            error?.response?.data?.message ??
            "Ocorreu um erro ao obter Tipo de Documento!"
        })
      );
    }
  }

  // eslint-disable-next-line consistent-return
  static async downloadDocumentoCliente(id, nomeArquivo) {
    try {
      const resultado = await obterUrlDocumentoCliente(id, nomeArquivo);

      if (resultado.status === 204)
        return RetornoEndpointDto.Erro("Arquivo não encontrados!");
    } catch (error) {
      if (error === !"undefined") {
        store.dispatch(
          alertaExibir({
            tipo: "danger",
            mensagem:
              error?.response?.data?.message ??
              "Ocorreu um erro ao obter Documento do Cliente!"
          })
        );
      }
    }
  }

  static async downloadFatura(guid, nomeArquivo) {
    try {
      const resultado = await downloadFatura(guid, nomeArquivo);

      if (resultado.status === 204)
        return RetornoEndpointDto.Erro("Fatura não encontrada!");
    } catch (error) {
      if (error === !"undefined") {
        store.dispatch(
          alertaExibir({
            tipo: "danger",
            mensagem:
              error?.response?.data?.message ??
              "Ocorreu um erro ao obter Fatura!"
          })
        );
      }
    }
  }

  static async obterClienteUnidade(queryString) {
    try {
      const resultado = await obterClienteUnidade(queryString);

      if (resultado.status === 204) {
        store.dispatch(
          alertaExibir({
            tipo: "danger",
            mensagem: "Nenhuma cliente encontrado!"
          })
        );

        return;
      }

      return resultado;
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "danger",
          mensagem:
            error?.response?.data?.message ??
            "Ocorreu um erro ao obter Cliente Unidade!"
        })
      );
    }
  }

  static async obterListaReduzida(queryString) {
    try {
      const resultado = await obterListaReduzida(queryString);

      if (resultado.status === 204) return;

      return resultado;
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "danger",
          mensagem:
            error?.response?.data?.message ??
            "Ocorreu um erro ao obter Cliente Unidade!"
        })
      );
    }
  }

  static async atualizarOperacaoControle(id, dados) {
    try {
      const resultado = await atualizarOperacaoControle(id, dados);

      

      if (resultado.status === 200) {
        store.dispatch(
          alertaExibir({
            tipo: "success",
            mensagem: "Operação atualizada com sucesso!"
          })
        );

        console.log(resultado)

        return resultado;
      }
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "danger",
          mensagem:
            error?.response?.data?.message ?? "Ocorreu um erro ao atualizar!"
        })
      );
    }
  }

  static async atualizarPassoOnboarding(
    operacaoControleId,
    operacaoPassoOnboardingId
  ) {
    try {
      const resultado = await atualizarPassoOnboarding(
        operacaoControleId,
        operacaoPassoOnboardingId
      );

      if (resultado.status === 200) {
        store.dispatch(
          alertaExibir({
            tipo: "success",
            mensagem: "Operação atualizada com sucesso!"
          })
        );

        return resultado;
      }
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "danger",
          mensagem:
            error?.response?.data?.message ?? "Ocorreu um erro ao atualizar!"
        })
      );
    }
  }

  static async enviarEmailOperacaoControle(dados) {
    try {
      const resultado = await enviarEmailOperacaoControle(dados);

      if (resultado.status === 200) {
        store.dispatch(
          alertaExibir({
            tipo: "success",
            mensagem: "E-mail enviado com sucesso!"
          })
        );

        return resultado;
      }
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "danger",
          mensagem:
            error?.response?.data?.message ??
            "Ocorreu um erro ao enviar e-mail!"
        })
      );
    }
  }

  static async salvarOperacaoTrocaTitularidade(dados) {
    try {
      const resultado = await salvarOperacaoTrocaTitularidade(dados);

      if (resultado.status === 200) {
        store.dispatch(
          alertaExibir({
            tipo: "success",
            mensagem: "Operação Troca Titularidade criada com sucesso!"
          })
        );

        return resultado;
      }
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "danger",
          mensagem:
            error?.response?.data?.message ?? "Ocorreu um erro ao atualizar!"
        })
      );
    }
  }

  static async atualizarOperacaoTrocaTitularidade(
    operacaoTrocaTitularidadeId,
    dados
  ) {
    try {
      const resultado = await atualizarOperacaoTrocaTitularidade(
        operacaoTrocaTitularidadeId,
        dados
      );

      if (resultado.status === 200) {
        store.dispatch(
          alertaExibir({
            tipo: "success",
            mensagem: "Operação Troca Titularidade atualizada com sucesso!"
          })
        );

        return resultado.data;
      }
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "danger",
          mensagem:
            error?.response?.data?.message ?? "Ocorreu um erro ao atualizar!"
        })
      );
    }
  }

  static async associarClienteNaUC(operacaoTrocaTitularidadeId) {
    try {
      const resultado = await associarClienteNaUC(operacaoTrocaTitularidadeId);

      if (resultado.status === 200) {
        store.dispatch(
          alertaExibir({
            tipo: "success",
            mensagem: "Cliente associado com sucesso!"
          })
        );

        return resultado.data;
      }
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "danger",
          mensagem:
            error?.response?.data?.message ?? "Ocorreu um erro ao atualizar!"
        })
      );
    }
  }

  static async listarOperacaoStatusTroca() {
    try {
      const resultado = await listarOperacaoStatusTroca();

      if (resultado.status === 204) {
        store.dispatch(
          alertaExibir({
            tipo: "danger",
            mensagem: "Nenhuma Operação Status Troca encontrada!"
          })
        );

        return;
      }

      return resultado;
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "danger",
          mensagem:
            error?.response?.data?.message ?? "Ocorreu um erro ao obter Lista!"
        })
      );
    }
  }

  static async uploadOperacaoTrocaTitularidadeFatura(dados) {
    try {
      const resultado = await uploadOperacaoTrocaTitularidadeFatura(dados);

      if (resultado.status === 200) {
        store.dispatch(
          alertaExibir({
            tipo: "success",
            mensagem: "Operação Troca Titularidade atualizada com sucesso!"
          })
        );

        return resultado.data;
      }
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "danger",
          mensagem:
            error?.response?.data?.message ?? "Ocorreu um erro ao atualizar!"
        })
      );
    }
  }

  static async getBoletaGDByUnidadeId(unidadeId) {
    try {
      const resultado = await getByUnidadeId(unidadeId);

      if (resultado.status === 204) {
        store.dispatch(
          alertaExibir({
            tipo: "danger",
            mensagem: "Nenhuma Boleta GD encontrada!"
          })
        );
        return;
      }
      return resultado;
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "danger",
          mensagem:
            error?.response?.data?.message ??
            "Ocorreu um erro ao obter a Boleta GD!"
        })
      );
    }
  }

  static async getListaComunicacao(cpfcnpj) {
    try {
      const resultado = await obterListaComunicacao(cpfcnpj);

      if (resultado.status === 204) {
        store.dispatch(
          alertaExibir({
            tipo: "danger",
            mensagem: "Nenhuma Boleta GD encontrada!"
          })
        );
        return false;
      }
      return resultado;
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "danger",
          mensagem:
            error?.response?.data?.message ??
            "Ocorreu um erro ao obter a Boleta GD!"
        })
      );
    }
  }

  static async verificarCadastroBoleta(id) {
    try {
      const resultado = await verificarCadastroBoleta(id);

      if (resultado.status === 204) {
        store.dispatch(
          alertaExibir({
            tipo: "danger",
            mensagem: "Nenhuma Boleta GD encontrada!"
          })
        );
        return false;
      }
      return resultado;
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "danger",
          mensagem:
            error?.response?.data?.message ??
            "Ocorreu um erro ao obter a Boleta GD!"
        })
      );
    }
  }

  static async obterUnidadeUsinaPorUnidadeId(unidadeId) {
    try {
      const resultado = await obterUnidadeUsinaPorUnidadeId(unidadeId);

      if (resultado.status !== 204) {
        return resultado;
      }
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "danger",
          mensagem:
            error?.response?.data?.message ??
            "Ocorreu um erro ao obter a Boleta GD!"
        })
      );
    }
    return true;
  }

  static async obterCancelamentoPaginadoPorUnidadeId(unidadeId) {
    try {
      const resultado = await getCancelamentoPaginado(unidadeId);

      if (resultado.status !== 204) {
        return resultado;
      }
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "danger",
          mensagem:
            error?.response?.data?.message ??
            "Ocorreu um erro ao obter Cancelamento!"
        })
      );
    }
    return true;
  }

  static async obterCancelamentoSuspensao(unidadeId) {
    try {
      const resultado = await getCancelamentoSuspensao(unidadeId);

      if (resultado.status !== 204) {
        return resultado;
      }
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "danger",
          mensagem:
            error?.response?.data?.message ??
            "Ocorreu um erro ao obter Suspensao!"
        })
      );
    }
    return true;
  }

  static async obterCancelamentoMotivos(unidadeId) {
    try {
      const resultado = await getCancelamentoMotivos(unidadeId);

      if (resultado.status !== 204) {
        return resultado;
      }
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "danger",
          mensagem:
            error?.response?.data?.message ??
            "Ocorreu um erro ao obter Motivos!"
        })
      );
    }
    return true;
  }

  static async salvarCancelamentoSimulacao(dados) {
    try {
      const resultado = await postarCancelamentoSimulacao(dados);

      return resultado;
    } catch (error) {
      return error?.response?.data?.message ?? "Ocorreu um erro ao simular!";
    }
  }

  static async obterCancelamento(unidadeId, setNotificacao) {
    try {
      const resultado = await getCancelamento(unidadeId);

      return resultado;
    } catch (error) {
      setNotificacao({
        aberto: true,
        tipo: "error",
        texto: "Ocorreu um erro ao obter cancelamento!"
      });
    }
    return true;
  }

  static async obterCancelamentoCargoOfficer(unidadeId, setNotificacao) {
    try {
      const resultado = await getCancelamentoCargoOfficer(unidadeId);

      return resultado;
    } catch (error) {
      if (error?.response.status === 404) {
        return;
      }
      setNotificacao({
        aberto: true,
        tipo: "error",
        texto: "Ocorreu um erro ao obter Officer!"
      });
    }
    return true;
  }

  static async salvarCancelamento(dados, setNotificacao) {
    try {
      const resultado = await postarCancelamento(dados);

      return resultado;
    } catch (error) {
      setNotificacao({
        aberto: true,
        tipo: "error",
        texto: "Ocorreu um erro ao salvar!"
      });
    }
  }

  static async suspenderCancelamento(dados, setNotificacao) {
    try {
      const resultado = await suspenderCancelamento(dados);

      return resultado;
    } catch (error) {
      setNotificacao({
        aberto: true,
        tipo: "error",
        texto: "Ocorreu um erro ao suspender!"
      });
    }
  }

  static async validarSuspenderCancelamento(dados, setNotificacao) {
    try {
      const resultado = await validarSuspenderCancelamento(dados);

      return resultado;
    } catch (error) {
      setNotificacao({
        aberto: true,
        tipo: "error",
        texto: "Ocorreu um erro ao suspender!"
      });
    }
  }

  static async obterSupensaoCancelamento(id, setNotificacao) {
    try {
      const resultado = await getSupensaoCancelamento(id);

      return resultado;
    } catch (error) {
      setNotificacao({
        aberto: true,
        tipo: "error",
        texto: "Ocorreu um erro ao obter Suspensao!"
      });
    }
    return true;
  }

  static async postarDevolverCancelamento(dados, setNotificacao) {
    try {
      const resultado = await postarDevolverCancelamento(dados);

      return resultado;
    } catch (error) {
      setNotificacao({
        aberto: true,
        tipo: "error",
        texto: "Ocorreu um erro ao devolver!"
      });
    }
  }

  static async atualizarDevolverCancelamento(dados, setNotificacao) {
    try {
      const resultado = await atualizarDevolverCancelamento(dados);

      return resultado;
    } catch (error) {
      setNotificacao({
        aberto: true,
        tipo: "error",
        texto: "Ocorreu um erro ao devolver!"
      });
    }
  }

  static async atualizarSFIntegracaoEnergiaChamado(dados, setNotificacao) {
    try {
      const resultado = await atualizarSFIntegracaoEnergiaChamado(dados);

      return resultado;
    } catch (error) {
      setNotificacao({
        aberto: true,
        tipo: "error",
        texto: "Ocorreu um erro ao abrir chamado!"
      });
    }
  }

  static async obterDevolucao(id, setNotificacao) {
    try {
      const resultado = await getDevolucao(id);

      return resultado;
    } catch (error) {
      if (error?.response.status === 404) {
        return;
      }
      setNotificacao({
        aberto: true,
        tipo: "error",
        texto: "Ocorreu um erro ao obter devolução!"
      });
    }
    return true;
  }

  static async obterStatusCancelamento(unidadeId, setNotificacao) {
    try {
      const resultado = await getStatusCancelamento(unidadeId);

      return resultado;
    } catch (error) {
      if (error?.response.status === 404) {
        return error?.response;
      }
      setNotificacao({
        aberto: true,
        tipo: "error",
        texto: "Ocorreu um erro ao obter status!"
      });
    }
    return true;
  }

  static async salvarCancelamentoMotivo(dados, setNotificacao) {
    try {
      const resultado = await postCancelamentoMotivo(dados);

      return resultado;
    } catch (error) {
      setNotificacao({
        aberto: true,
        tipo: "error",
        texto: "Ocorreu um erro ao salvar motivo!"
      });
    }
    return true;
  }

  static listaDias() {
    const lista = [
      { id: 1, valor: "1" },
      { id: 2, valor: "2" },
      { id: 3, valor: "3" },
      { id: 4, valor: "4" },
      { id: 5, valor: "5" },
      { id: 6, valor: "6" },
      { id: 7, valor: "7" },
      { id: 8, valor: "8" },
      { id: 9, valor: "9" },
      { id: 10, valor: "10" },
      { id: 11, valor: "11" },
      { id: 12, valor: "12" },
      { id: 13, valor: "13" },
      { id: 14, valor: "14" },
      { id: 15, valor: "15" },
      { id: 16, valor: "16" },
      { id: 17, valor: "17" },
      { id: 18, valor: "18" },
      { id: 19, valor: "19" },
      { id: 20, valor: "20" },
      { id: 21, valor: "21" },
      { id: 22, valor: "22" },
      { id: 23, valor: "23" },
      { id: 24, valor: "24" },
      { id: 25, valor: "25" },
      { id: 26, valor: "26" },
      { id: 27, valor: "27" },
      { id: 28, valor: "28" },
      { id: 29, valor: "29" },
      { id: 30, valor: "30" },
      { id: 31, valor: "31" }
    ];

    return lista;
  }

  static exibirErro(mensagem) {
    store.dispatch(
      alertaExibir({
        tipo: "danger",
        mensagem
      })
    );
  }

  static exibirSucesso(mensagem) {
    store.dispatch(
      alertaExibir({
        tipo: "success",
        mensagem
      })
    );
  }
}
