import React, { useState } from "react";
import PropTypes from "prop-types";

// Icones
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import AssignmentTurnedInOutlined from "@mui/icons-material/AssignmentTurnedInOutlined";

// Componentes
import { Box, Collapse, Grid, IconButton } from "@mui/material";
import ToogleIcon from "./componentes/toggleIcon";

// Styles
import { useStyles } from "./style";
import { HtmlTooltip } from "../../paginas/Graficos/ConsumoAtual/style"

import theme from "themes";

const ItemListaExpansivelResponsiva = ({
  nomeFantasia,
  color,
  cnpj,
  aberto,
  status,
  validade,
  plano,
  onClickEditar,
  onClickPDF,
  onClickContratar,
  botaoDesabilitado,
}) => {
  const classes = useStyles();
  const [itemAberto, setItemAberto] = useState(aberto);

  return (
    <Grid className={classes.boxItem} container>
            <Grid className={classes.gridItem} item xs={2}>
              <div className="w-100">
                <ToogleIcon
                  aberto={itemAberto}
                  color={color}
                  onClick={() => setItemAberto(!itemAberto)}
                />
              </div>
            </Grid>
            <Grid item xs={10}>
              <Grid xs={12} className={classes.label}>{nomeFantasia}</Grid>
              <Grid xs={12}>{cnpj}</Grid>
              <Grid xs={6}>Status: {status}</Grid>
              <Grid xs={6}>Validade: {validade}</Grid>
              <Grid xs={12}>Plano: {plano}</Grid>
            </Grid>

      <Collapse className="w-100" in={itemAberto}>
        <Grid container spacing={0}>
          <Grid
            item
            className={`${classes.gridItem} ${classes.treeItem} ${classes.expandItem} fade show`}
          >
            <Grid container>
            <Grid item xs={4}>{onClickPDF}</Grid>

              <Grid item xs={4}>  
                <IconButton onClick={onClickEditar} size="large">
                  <Box display="flex" flexDirection="column">
                    <EditOutlinedIcon />
                    <Box component="span" fontSize="7px" mt="7px">Editar</Box> 
                  </Box>
                </IconButton> 
              </Grid>
              <Grid item xs={4}>
                <IconButton
                  disabled={botaoDesabilitado || onClickContratar === null ? true : false}
                  onClick={onClickContratar}
                  className={`${botaoDesabilitado || onClickContratar === null ? classes.inativo : classes.ativo
                    }`}
                  size="large">
                  <Box display="flex" flexDirection="column">
                    <AssignmentTurnedInOutlined />
                    <Box component="span" fontSize="7px" mt="7px">{botaoDesabilitado ? "Contratado" :"Contratar"}</Box>  
                  </Box>
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Collapse>
    </Grid>
  );
};

ItemListaExpansivelResponsiva.propTypes = {
  botaoDesabilitado: PropTypes.bool,
  nomeFantasia: PropTypes.string,
  cnpj: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  color: PropTypes.string,
  status: PropTypes.string,
  validade: PropTypes.string,
  plano: PropTypes.string,
  aberto: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.node]),
  onClickEditar: PropTypes.oneOfType([PropTypes.func]),
  onClickPDF: PropTypes.oneOfType([PropTypes.func]),
  onClickContratar: PropTypes.oneOfType([PropTypes.func]),
};

ItemListaExpansivelResponsiva.defaultProps = {
  botaoDesabilitado: false,
  color: theme.color.primary,
  aberto: false,
  status: '',
  validade: '',
  plano: '',
  children: <></>,
  onClickEditar: () => { },
  onClickPDF: () => { },
  onClickContratar: () => { }
};

export default ItemListaExpansivelResponsiva;
