import RetornoEndpointDto from "global/dto/retornoEndpointDto";
import { ExportarPlanilha } from "submodules/AreaLogada/servicos/metricasRelatorioServico";
import { alertaExibir } from "global/redux/modulos/alertas/actions";
import { store } from "global/redux";


export default class Helper {
  

  static async ExportarPlanilha( dataInicio, dataFim ) {
    try {
      const   resultado  = await ExportarPlanilha( dataInicio, dataFim );
      return RetornoEndpointDto.Sucesso(
        "Planilha obtida com sucesso",
          resultado
      );
    } catch (error) {
        return RetornoEndpointDto.Erro(
          "Ocorreu um erro ao obter a planilha"
        );
    }
  }
  static exibirErro(mensagem) {
    store.dispatch(
        alertaExibir({
        tipo: "danger",
        mensagem
        })
    );
    
  }
  static exibirSucesso(mensagem) {
    store.dispatch(
        alertaExibir({
        tipo: "success",
        mensagem
        })
    );
  }
}
