import { makeStyles } from '@mui/styles';
import { Base } from "componentes/cores";
import themes from "themes";
import { createTheme } from "@mui/material/styles";

const theme = createTheme();

export const useStyles = makeStyles(() => ({
  titulo: {
    fontWeight: "bold"
  },
  container: {
    "& .MuiInputBase-root": {
      color: themes.color.labelColorGrafico
    },
    "& .MuiInputBase-input": {
      maxWidth: "90px"
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "0 none"
    },
    "& .MuiButtonBase-root": {
      color: themes.color.labelColorGrafico
    }
  },
  itemGrafico: {
    "& .apexcharts-tooltip.apexcharts-theme-light": {
      border: "0 none",
      background: "none",
      width: "fit-content",
      height: "fit-content",
      textAlign: "center",
      fontSize: "13px"
    }
  },
  noData: {
    textAlign: "center",
    width: "100%",
    minHeight: "200px",
    "& div": {
      margin: "125px auto 0",
      maxWidth: "80%"
    }
  },
  arrowBox: {
    position: "relative",
    background: "#647480",
    padding: "8px",
    borderColor: "#647480",
    color: Base.White
  },
  arrowBoxTitle: {
    color: `${Base.White} !important`,
    fontSize: "10px"
  },
  datePickerWrapper: {
    padding: '0!important',
    width: '100%',
    color: '#fff',
    '& svg': {
      color: '#fff',
    },
    '& fieldset': {
      borderColor: 'transparent!important',
      color: '#fff',
    },
    "& > div.MuiGrid-container": {
      width: '100%',
      padding: 0,
      margin: 0,
      "& > div.MuiGrid-item": {
        width: '100%',
        padding: 0,
        margin: 0,
        color: '#fff',
        "& > div.MuiGrid-container": {
          width: '100%',
          padding: 0,
          margin: 0,
          "& > div": {
            width: '100%',
            padding: 0,
            margin: 0,
            "& div": {
              color: '#fff',
              paddingRight: 0
            }
          }
        }
      }
    }
  },
  tableColumnDia:{
    fontSize: 16,
    backgroundColor: '#f0f0f0'
  },
  tableColumnConsumo:{
    fontSize: 16
  }
}));
