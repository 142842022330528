import React, { useRef, useState, useEffect } from "react";

// Componentes
import TuneIcon from "@mui/icons-material/Tune";
import { Grid } from "@mui/material";
import Coluna from "../../../../componentes/tabelaPaginada/colunas/coluna";
import BotaoFiltroOrdenacao from "../../../../componentes/botaoFiltroOrdenar";
import FaturasHelper from "./helper";
import ColunaComponentePersonalizado from "../../../../componentes/tabelaPaginada/colunas/colunaComponentePersonalizado";
import ColunaRelatorio from "./colunasPersonalizadas/ColunaRelatorio";
import ColunaNotaFiscal from "./colunasPersonalizadas/ColunaNotaFiscal";
import ColunaStatus from "../../../Financeiro/Fatura/listagem/colunasPersonalizadas/ColunaStatus";
import ColunaBoleto from "./colunasPersonalizadas/ColunaBoleto";
import { TabelaPaginada, SelectArredondado } from 'componentes';
import InputDataWhite from "../../../../componentes/inputTextoDataBranco";

// DTO
import listagemFaturasDto from "./dto/listagemFaturasDto";
import FiltroDto from "./dto/filtroDto";
import ResultadoPaginadoDto from "../../../../componentes/tabelaPaginada/resultadoPaginadoDto";

// Redux
import { store } from "../../../../global/redux";
import { alertaExibir } from "../../../../global/redux/modulos/alertas/actions";
import { useSelector } from "react-redux";

// Styles
import { useStyles } from "./style";
import theme from 'themes';
import { HtmlTooltip } from "paginas/Graficos/ConsumoAtual/style";

// Serviços
import {
  filtroStatusPor,
  listarColaboradorOrdenarPor
} from "../../../../servicos/faturasServico";

const ListagemFaturasColaborador = () => {
  const [ordemSelecionada, setOrdemSelecionada] = useState("");
  const [ordemColunaSelecionada, setOrdemColunaSelecionada] = useState();
  const [filtroAdicionalTabela, setFiltroAdicionalTabela] = useState(
    new FiltroDto()
  );

  const [mes, setMes] = useState(0);
  const [ano, setAno] = useState(0);
  const [filtroStatusSelecionado, setFiltroStatusSelecionado] = useState(0);
  const [valorOrdenacao, setValorOrdenacao] = useState(0);

  const classes = useStyles();
  const refTabela = useRef(null);

  const global = useSelector((state) => state.usuario);
  const { empresaSelecionada, clienteSelecionado, unidadeSelecionada } = global;

  const ColunaCliente = ({ parametros }) => {
    const classes = useStyles();
    return (
      <HtmlTooltip title={parametros.cliente}>
        <div className={`${classes.linhaTooltip}`}>{parametros.cliente}</div>
      </HtmlTooltip>
    );
  };

  const ColunaUnidade = ({ parametros }) => {
    const classes = useStyles();
    return (
      <HtmlTooltip title={parametros.unidadeConsumidora}>
        <div className={`${classes.linhaTooltip}`}>{parametros.unidadeConsumidora}</div>
      </HtmlTooltip>
    );
  };

  const colunas = [
    new ColunaComponentePersonalizado(
      "cliente",
      "Cliente",
      ColunaCliente,
      null,
      false,
      false,
      null,
      null
    ),
    new ColunaComponentePersonalizado(
      "unidadeConsumidora",
      "Unidade Consumidora",
      ColunaUnidade,
      null,
      false,
      false,
      null,
      null
    ),
    new Coluna("mesAnoReferenciaFormatado", "Mês referente", true),
    new Coluna("dataVencimentoFormatada", "Vencimento", true),
    new ColunaComponentePersonalizado(
      "opcoes",
      "Status",
      ColunaStatus,
      null,
      false,
      true,
      "10%",
      "10%",
      "pl-2"
    ),
    new Coluna("atraso", "Atraso", true, '8%', '8%', 'pl-2'),
    new Coluna("dataPagamentoFormatada", "Pagamento", true),
    new Coluna("valorFormatado", "Valor sem ICMS", true),
    new Coluna("valorPagoFormatado", "Valor pago", true),
    new ColunaComponentePersonalizado(
      "opcoes",
      "NF",
      ColunaNotaFiscal,
      null,
      false,
      true,
      "5%",
      "5%",
      "pl-2"
    ),
    new ColunaComponentePersonalizado(
      "opcoes",
      "Fat.",
      ColunaRelatorio,
      null,
      false,
      true,
      "5%",
      "5%",
      "pl-2"
    ),
    new ColunaComponentePersonalizado(
      "opcoes",
      "Bol.",
      ColunaBoleto,
      null,
      false,
      true,
      "6%",
      "6%",
      "pl-2"
    )
  ];

  const obterGrupos = async (parametros) => {
    const filtro = new FiltroDto();
    filtro.linhasPorPagina = parametros?.tamanhoPagina;
    filtro.pagina = parametros?.pagina;
    filtro.ordenacao = Number(valorOrdenacao) != 0 ? valorOrdenacao : 0;
    filtro.empresaId = Number(empresaSelecionada) !== 0 ? empresaSelecionada : 0;
    filtro.clienteId = Number(clienteSelecionado) !== 0 ? clienteSelecionado : 0;
    filtro.unidadeConsumidoraId = Number(unidadeSelecionada) !== 0 ? unidadeSelecionada : 0;
    filtro.anoReferencia = Number(ano) != 0 ? ano : 0;
    filtro.mesReferencia = Number(mes) != 0 ? mes : 0;
    filtro.statusId = Number(filtroStatusSelecionado) != 0 ? filtroStatusSelecionado : 0;

    const resultado = await FaturasHelper.listarFaturas(filtro);

    if (!resultado.sucesso) {
      store.dispatch(
        alertaExibir({
          tipo: "danger",
          mensagem: resultado.mensagem
        })
      );
      return new ResultadoPaginadoDto([], 1, 0, 0);
    }

    if (resultado?.data.length === 0 ?? true)
      return new ResultadoPaginadoDto([], 1, 0, 0);

    const resLinhas = resultado?.data?.lista?.map((res) => {
      return new listagemFaturasDto(
        res.id,
        res.unidadeConsumidoraId,
        res.ultimoIdentificadorBoleto,
        res.ultimoNomeBoleto,
        res.ultimoIdentificadorNotaFiscal,
        res.ultimoNomeNotaFiscal,
        res.mesAnoReferenciaFormatado,
        res.mesReferencia,
        res.anoReferencia,
        res.statusNome,
        res.statusId,
        res.dataPagamentoFormatada,
        res.valorFormatado,
        res.valorPagoFormatado,
        res.dataVencimentoFormatada,
        res.empresaId,
        res.cliente,
        res.unidadeConsumidora,
        res.atraso
      );
    });

    return new ResultadoPaginadoDto(
      resLinhas,
      resultado?.data?.paginaAtual,
      resultado?.data?.totalItens,
      resultado?.data?.totalPaginas
    );
  };

  const onChangeFiltrosTabela = async (parametros) => {
      return obterGrupos({
        tamanhoPagina: parametros?.totalPagina,
        pagina: parametros?.pagina,
        filtroAdicional: new FiltroDto(
          parametros?.filtrosAdicionais?.ordenacao,
          parametros?.filtrosAdicionais?.statusId,
          parametros?.filtroAdicional?.anoReferencia,
          parametros?.filtroAdicional?.mesReferencia,
          parametros?.filtroAdicional?.empresaId,
          parametros?.filtroAdicional?.clienteId,
          parametros?.filtroAdicional?.unidadeConsumidoraId
        )
      });
  };

  const handleClickAtivarFiltro = () => {

    setValorOrdenacao(ordemSelecionada);

    setFiltroAdicionalTabela(
      new FiltroDto(
        ordemSelecionada,
        filtroStatusSelecionado,
        ano,
        mes,
        empresaSelecionada,
        clienteSelecionado,
        unidadeSelecionada
      )
    );
  };

  useEffect(() => {
    setFiltroAdicionalTabela(
      new FiltroDto(
        ordemSelecionada,
        filtroStatusSelecionado,
        ano,
        mes,
        empresaSelecionada,
        clienteSelecionado,
        unidadeSelecionada
      )
    );
  }, [empresaSelecionada, clienteSelecionado, unidadeSelecionada, ano, mes]);

  const handleClickSortOrderSelecionado = (valor, idCampoSelecionado) => {
    
    const val =
      ordemColunaSelecionada === idCampoSelecionado && valor === ""
        ? ""
        : idCampoSelecionado;

    setOrdemColunaSelecionada(val);
    setOrdemSelecionada(valor);
  };

  const handleClickFiltroStatusSelecionado = (event) => {
    const val = filtroStatusSelecionado === event ? "" : event;
    setFiltroStatusSelecionado(val);
  };

  return <>
    <Grid container xs={12}>
      <Grid container item xs={12} className="ml-1 mr-2">
        <Grid
          container
          item
          alignItems="center"
          justifyContent="space-between"
          spacing={2}
        >
          <Grid item md={2}>
            <InputDataWhite
              type="text"
              id="Ano"
              label="Ano"
              format="yyyy"
              customValue={ ano == 0 ? null : new Date(ano,1,1) }
              onChange={(e) => {
                if(!isNaN(e.getFullYear())){
                  setAno(e.getFullYear());
                }
                else{
                  setAno(0)
                }
              }}
              views={["year"]}
              className={classes}
              maxDate={new Date(2090,1,1)}
            />            
          </Grid>
          <Grid item md={4}>
            <SelectArredondado
              select
              id="Mes"
              name="Mes"
              label="Mês"
              className={classes.select}
              valueKey="valor"
              valueName="nome"
              dataSource={[{
                nome: 'Mês',
                valor: "0"
              }, {
                nome: 'Janeiro',
                valor: 1
              }, {
                nome: 'Fevereiro',
                valor: 2
              }, {
                nome: 'Março',
                valor: 3
              }, {
                nome: 'Abril',
                valor: 4
              }, {
                nome: 'Maio',
                valor: 5
              }, {
                nome: 'Junho',
                valor: 6
              }, {
                nome: 'Julho',
                valor: 7
              }, {
                nome: 'Agosto',
                valor: 8
              }, {
                nome: 'Setembro',
                valor: 9
              }, {
                nome: 'Outubro',
                valor: 10
              }, {
                nome: 'Novembro',
                valor: 11
              }, {
                nome: 'Dezembro',
                valor: 12
              }]}
              value={mes}
              onChange={(event) => {
                setMes(event.target.value);
              }}
              permiteValorBranco
              marginBottom="0"
            />
          </Grid>
          <Grid
            container
            item
            xs={6}
            md={6}
            sm={6}
            spacing={2}
            className={classes.itemFiltro}
          >
            <Grid item xs={6}>
              <BotaoFiltroOrdenacao
                type="button"
                color={theme.color.secondaryBorderColor}
                background="transparent"
                label="Filtrar / Ordernar"
                icon={<TuneIcon />}
                className={classes.buttonFiltro}
                ordenadorUm="Ordenador"
                ordenacaoColuna={listarColaboradorOrdenarPor()}
                ordenadorSeis="Status"
                filtroStatus={filtroStatusPor()}
                ordemSelecionada={ordemSelecionada}
                ordemColunaSelecionada={ordemColunaSelecionada}
                filtroStatusSelecionados={filtroStatusSelecionado}
                onClickFiltroStatus={handleClickFiltroStatusSelecionado}
                onClickSortOrder={handleClickSortOrderSelecionado}
                onClickAtivarAgora={handleClickAtivarFiltro}
                larguraBotao="4"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className="mt-4">
        <TabelaPaginada
          className={classes.tabela}
          ref={refTabela}
          onChangeFiltrosTabela={onChangeFiltrosTabela}
          colunas={colunas}
          filtrosAdicionais={filtroAdicionalTabela}
          paginaAtual={1}
          paginationPerPage={20}
          noHeader
        />
      </Grid>
    </Grid>
  </>;
};

export default ListagemFaturasColaborador;
