import { makeStyles } from '@mui/styles';
import theme from "themes";

export const customStyles = makeStyles(() => ({
  formControl: {
    fontFamily: theme.font.family
  },
  label: {
    color: "white !important",
    fontFamily: `${theme.font.family} !important`,
    fontSize: "16px !important",
    margin: 0,
    pointerEvents: "none",
    transform: "translate(0, 4px) scale(1) !important",
    zIndex: "1 !important",
    width: "100%",
    textAlign: "center",
    "&.MuiInputLabel-shrink": {
      display: "none"
    }
  },
  select: {
    fontFamily: theme.font.family,
    marginBottom: (props) => props.marginBottom,
    maxHeight: (props) => props.maxHeight,
    maxWidth: (props) => props.maxWidth,
    "& .MuiInputBase-root": {
      backgroundColor: theme.color.primary,
      borderColor: "none",
      fontFamily: theme.font.family,
      maxHeight: "30px",
      "&:before": {
        border: "0"
      },
      "& .MuiSelect-root": {
        color: "white !important",
        fontFamily: theme.font.family,
        padding: "10px",
        textAlign: "center"
      },
      "& .MuiInput-notched": {
        borderColor: `none !important`,
        fontFamily: theme.font.family
      }
    },
    "& label.Mui-focused": {
      color: "transparent"
    },
    "& .MuiInput-underline:after": {
      borderColor: "transparent"
    },
    "& .MuiFormLabel-root": {
      color: "transparent"
    },
    "& .MuiInput-root": {
      "& .MuiInput-input": {
        color: "white",
        textAlign: "center"
      },
      "& .MuiInputAdornment-root": {
        color: "#FFF !important",
        position: "absolute !important",
        right: "16px !important",
        "& button": {
          color: "white"
        }
      },
      "& fieldset": {
        borderColor: "transparent"
      },
      "&:hover fieldset": {
        borderColor: "transparent"
      },
      "&.Mui-focused fieldset": {
        borderColor: "transparent"
      }
    },
    "& .MuiSelect-icon": {
      color: "transparent"
    },
    "& .MuiIconButton-label": {
      color: "#FFF !important"
    }
  },
  selectIcon: {
    color: "white !important",
    marginRight: "4px",
    position: "absolute",
    right: 0,
    top: "calc(50% - 12px)",
    "pointer-events": "none"
  },
  menuIcon: {
    marginRight: "10px"
  }
}));
