import styled from "styled-components";
import { makeStyles } from '@mui/styles';
import { Base } from "../../../../componentes/cores";
import theme from 'themes';

export const useStyles = makeStyles({
  container: {
    margin: "0",
    width: "100%",
    "& .MuiInputBase-root": {
      borderColor: `${theme.color.secondaryBorderColor} !important`,
      color: `${theme.color.secondaryBorderColor} !important`,
      "&:hover": {
        borderColor: `${theme.color.secondaryBorderColor} !important`,
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: `${theme.color.secondaryBorderColor} !important`
        }
      }
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: `${theme.color.secondaryBorderColor} !important`,
      "&:hover": {
        borderColor: `${theme.color.secondaryBorderColor} !important`
      }
    },
    "& .MuiButtonBase-root": {
      color: `${theme.color.secondaryBorderColor} !important`
    }
  },
  search: {
    "& .MuiOutlinedInput-input": {
      padding: "18.5px ​14p"
    },
    "& label.Mui-focused": {
      color: `${theme.color.secondaryBorderColor} !important`
    },
    "& .MuiInput-underline:after": {
      borderColor: `${theme.color.secondaryBorderColor} !important`
    },
    "& .MuiFormLabel-root": {
      color: `${theme.color.secondaryBorderColor} !important`
    },
    "& .MuiOutlinedInput-root": {
      "& .MuiOutlinedInput-input": {
        color: `${theme.color.secondaryBorderColor} !important`
      },
      "& .MuiInputAdornment-root": {
        color: `${theme.color.secondaryBorderColor} !important`
      },
      "& fieldset": {
        borderColor: `${theme.color.secondaryBorderColor} !important`
      },
      "&:hover fieldset": {
        borderColor: `${theme.color.secondaryBorderColor} !important`
      },
      "&.Mui-focused fieldset": {
        borderColor: `${theme.color.secondaryBorderColor} !important`
      }
    }
  },
  itemFiltro: {}
});

const statusColor = (statusId) => {

  //Importação incompleta
  if (statusId === 1) return Base.RedError;
  //Fio calculado
  if (statusId === 2) return theme.color.metalic;
  //Análise fio realizada
  if (statusId === 3) return Base.BlueAqua;
  //Aguardando confirmação fio
  if (statusId === 4) return theme.color.metalic;
  //Refazer cálculo
  if (statusId === 5) return Base.BlueAqua;
  //A faturar
  if (statusId === 6) return Base.BlueAqua;
  //Em processamento
  if (statusId === 7) return Base.YellowMustard;
  //Não processado
  if (statusId === 8) return Base.RedError;
  //Faturado
  if (statusId === 9) return Base.GreenFern;

  return theme.color.primaryBackgroud;
};

export const Container = styled.div`
  border-left-color: ${(props) => statusColor(props.status)};
  border-left-style: solid;
  border-left-width: 10px;
`;
