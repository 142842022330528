import { makeStyles } from "@mui/styles";
import { Base } from "../cores";
import themes from "themes";

export const useStyles = makeStyles(() => ({
  containerDropZone: {
    "& .MuiDropzoneArea-root": {
      cursor: "default",
      alignItems: "center",
      backgroundColor: "transparent",
      borderRadius: themes.borda.input,
      borderColor: Base.GreyDim,
      borderWidth: "5px",
      borderStyle: "dashed",
      color: `${themes.color.secondaryText} !important`,
      height: "54px",
      paddingLeft: "0px",
      width: "100%",
      textAlign: "center",
      minHeight: "117px",
      marginBottom: "20px"
    },
    "& .MuiDropzoneArea-textContainer": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "row-reverse",
      marginTop: "10px"
    },
    "& .MuiDropzoneArea-icon": {
      color: `${themes.color.labelBotaoInicial} !important`,
      marginRight: "20px",
      width: "51px !important",
      height: "51px !important",
    },
  
    "& .MuiDropzoneArea-text": {
      color: `${themes.color.secondaryText}`,
      "&:hover": {
        color: Base.BlueDodger,
        cursor: "pointer"
      }
    },
    "& .MuiTypography-h5": {
      fontSize: "18px"
    }
  }
}));
