import {
  obterClienteContratoPorUnidadeId,
  downloadClienteContrato
} from "servicos/clienteContratoServico";
import RetornoEndpointDto from "../../../global/dto/retornoEndpointDto";
import { store } from "../../../global/redux";
import { alertaExibir } from "../../../global/redux/modulos/alertas/actions";
import {
  obterUrlDocumentoCliente,
  listarDocumentosParaAprovacaoPorOperacaoControleId
} from "../../../servicos/clienteDocumento";
import {
  atualizarOperacaoControlePJ,
  uploadControlePJDocumentoAprovado,
  adicionaRegistroDocumentoPJAprovado,
  gerarUrlDownload
} from "../../../servicos/operacaoControleServico";
import RadioDto from "../../../componentes/inputRadioGroup/radioDto";

export default class analiseDocumentoHelper {
  static async listarDocumentoParaAprovacao(operacaoControleId, setCarregando) {
    try {
      setCarregando(true);
      const resultado = await listarDocumentosParaAprovacaoPorOperacaoControleId(
        operacaoControleId
      );

      return RetornoEndpointDto.Sucesso(
        "Documento encontrado com sucesso",
        resultado.data
      );
    } catch (ex) {
      return RetornoEndpointDto.Erro(
        ex?.response?.data?.message ?? "Ocorreu um erro"
      );
    } finally {
      setCarregando(false);
    }
  }

  static async downloadDocumentoCliente(id, nomeArquivo) {
    try {
      const resultado = await obterUrlDocumentoCliente(id, nomeArquivo);

      if (resultado?.status === 204)
        return RetornoEndpointDto.Erro("Arquivo não encontrados!");
    } catch (error) {
      if (error !== "undefined") {
        store.dispatch(
          alertaExibir({
            tipo: "danger",
            mensagem:
              error?.response?.data?.message ??
              "Ocorreu um erro ao obter Documento do Cliente!"
          })
        );
      }
    }
  }

  static async atualizarOperacaoControlePJ(
    operacaoControlePJId,
    dados,
    setCarregando
  ) {
    try {
      setCarregando(true);
      const resultado = await atualizarOperacaoControlePJ(
        operacaoControlePJId,
        dados
      );

      return RetornoEndpointDto.Sucesso(
        "Operação atualizada com sucesso!",
        resultado.data
      );
    } catch (ex) {
      return RetornoEndpointDto.Erro(
        ex?.response?.data?.message ?? "Não foi possivel atualizar operação!"
      );
    } finally {
      setCarregando(true);
    }
  }

  static listarItemIncorreto() {
    return [
      new RadioDto(
        "Documentação enviada incorretamente",
        "Documentação enviada incorretamente"
      ),
      new RadioDto("Divergências no contrato", "Divergências no contrato"),
      new RadioDto(
        "Impossibilidade de negociação com a contraparte",
        "Impossibilidade de negociação com a contraparte"
      )
    ];
  }

  static async obterClienteContrato(unidadeId) {
    try {
      const response = await obterClienteContratoPorUnidadeId(unidadeId);

      return RetornoEndpointDto.Sucesso(
        "Contrato obtido com sucesso",
        response.data
      );
    } catch (ex) {
      return RetornoEndpointDto.Erro(
        ex?.response?.data?.message ?? "Não foi possivel obter contrato!"
      );
    }
  }

  static async downloadContrato(unidadeId, nomeGuid) {
    try {
      const response = await downloadClienteContrato(unidadeId, nomeGuid);

      return response?.data;
    } catch (ex) {
      return RetornoEndpointDto.Erro(
        ex?.response?.data?.message ?? "Não foi possivel baixar arquivo"
      );
    }
  }

  static async adicionaRegistroDocumentoPJAprovado(dados, setCarregando) {
    try {
      setCarregando(true);
      const response = await adicionaRegistroDocumentoPJAprovado(dados);

      return RetornoEndpointDto.Sucesso(
        "Registro documento inserido com sucesso!",
        response.data
      );
    } catch (ex) {
      return RetornoEndpointDto.Erro(
        ex?.response?.data?.message ?? "Não foi possivel inserir registro"
      );
    } finally {
      setCarregando(false);
    }
  }

  static async uploadControlePJDocumentoAprovado(dados, setCarregando) {
    try {
      setCarregando(true);
      const response = await uploadControlePJDocumentoAprovado(dados);

      return RetornoEndpointDto.Sucesso(
        "Upload documento inserido com sucesso!",
        response.data
      );
    } catch (ex) {
      return RetornoEndpointDto.Erro(
        ex?.response?.data?.message ?? "Não foi realizar upload do arquivo"
      );
    } finally {
      setCarregando(false);
    }
  }

  static async geraUrlDownload(params, setCarregando) {
    try {
      setCarregando(true);
      const resultado = await gerarUrlDownload(params);

      return RetornoEndpointDto.Sucesso(
        "Url download gerada com sucesso",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro("Erro ao gerar url documentos!");
    } finally {
      setCarregando(false);
    }
  }

  static exibirErro(mensagem) {
    store.dispatch(
      alertaExibir({
        tipo: "danger",
        mensagem
      })
    );
  }

  static exibirSucesso(mensagem) {
    store.dispatch(
      alertaExibir({
        tipo: "success",
        mensagem
      })
    );
  }
}
