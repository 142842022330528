import MICROSERVICO from "../global/enumeradores/microServico";
import obterApi from "./api";

const api = () => obterApi(MICROSERVICO.MANAGEMENT);

export const listarOrganizarPor = () => {
  const ordernacoes = [
    {
      key: "0",
      label: "Alfabética"
    },
    {
      key: "3",
      label: "Recentes"
    }
  ];

  return ordernacoes;
};

export const listarFiltrarPor = () => {
  const filtros = [
    {
      key: "AT",
      label: "Ativo"
    },
    {
      key: "IN",
      label: "Inativo"
    }
  ];

  return filtros;
};

export const listarFiltrarClientePor = () => {
  const filtros = [
    {
      key: "PF",
      label: "PF"
    },
    {
      key: "PJ",
      label: "PJ"
    }
  ];

  return filtros;
};

// Listar Clientes
export const listarClientes = async (
  ordemSelecionada,
  filtroSelecionado,
  paginaAtual = 0,
  empresaSelecionada,
  searchString,
  tamanhoPaginacao = 60,
  filtroTipoClienteSelecionado,
  campoPesquisa
) => {

  const params = {
    sortOrder: ordemSelecionada,
    empresaGrupoDeltaId: empresaSelecionada,
    filter: filtroSelecionado,
    tipoCliente: filtroTipoClienteSelecionado,
    pagina: paginaAtual,
    tamanhoPaginacao,
    searchString,
    campoTextoSelecionado: campoPesquisa
  };

  return api().get(`v1/Cliente?${new URLSearchParams(params).toString()}`);
};

// Buscar Cliente por CNPJ
export const buscarPorCnpj = async (cnpj) => {
  return api().get(`v1/Cliente/GetByCnpj/${cnpj}`);
};

// Busca Por Id
export const buscarPorId = async (id) => {
  return api().get(`v1/Cliente/${id}`);
};

// Busca Status
export const buscarStatus = async () => {
  return api().get("v1/Cliente/Status");
};

// Deletar Cliente
export const deletarCliente = async (id, ativo) => {
  let hoje = new Date();
  const dd = String(hoje.getDate()).padStart(2, "0");
  const mm = String(hoje.getMonth() + 1).padStart(2, "0");
  const yyyy = hoje.getFullYear();
  hoje = `${yyyy}-${mm}-${dd}`;

  const situacao = ativo ? "AT" : "IN";

  return api().patch(
    `v1/Cliente/${id}/situacao/${situacao}?dataSituacao=${hoje}`
  );
};

// Agente CCEE
export const obterClienteAgenteCCEE = async () => {
  return api().get("v1/Cliente/listar-clientes-para-agenteccee");
};

// Autocomplete
export const buscarClientePorNomeFantasia = async (nomeFantasia) => {
  return api().get(`v1/Cliente/busca-nome-fantasia/${nomeFantasia}`);
};

// Salvar Cliente
export const salvarCliente = async (id, dados) => {
  let metodo = "post";
  const url = "v1/Cliente";
  if (id > 0) {
    metodo = "put";
    dados.Id = id;
  }
  return api()[metodo](url, dados);
};

// Listar Clientes
export const listarClientesPorEmpresa = async (idEmpresa) => {
  return api().get(`v1/Cliente?empresa=${idEmpresa}`);
};

// Listar Clientes
export const listarClientesCombo = async (empresaSelecionada, token) => {
  const IdEmpresa = parseInt(empresaSelecionada, 0);
  return api().get(
    `v1/Cliente/ListarClientesColaboradorPorArvoreEEmpresa/${IdEmpresa}`,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  );
};

// Listar Clientes
export const listarClientesComboFiltro = async (empresaSelecionada, search) => {
  const IdEmpresa = parseInt(empresaSelecionada, 10);
  return api().get(
    `v1/Cliente/ListarClientesColaboradorPorArvoreEEmpresa/${IdEmpresa}?search=${search}`
  );
};

export const obterClientes = async () => {
  return api().get("v1/Cliente/UsuarioLogado");
};

export const obterClienteUnidade = async (filtro) => {
  const filtroQueryString = new URLSearchParams(filtro).toString();
  return api().get(`v1/Cliente/ObterClienteUnidade?${filtroQueryString}`);
};

export const obterListaReduzida = async (filtro) => {
  const filtroQueryString = new URLSearchParams(filtro).toString();
  return api().get(`v1/Cliente/ObterListaReduzida?${filtroQueryString}`);
};
