import React, { useState, useEffect, useCallback } from "react";
import { useParams, useHistory } from "react-router-dom";
import moment from "moment";

// Form Hooks
import { useForm } from "react-hook-form";

// Redux
import { desabilitarFiltroLateral } from "../../../../global/redux/modulos/usuario/actions";

// Componentes
import { Card, Grid } from "@mui/material";
import Loader from "../../../../componentes/loader";
import Botao from "../../../../componentes/botao";
import BotaoRetornarListagem from "../../../../componentes/botaoRetornarListagem";
import MaterialInputMascara from "../../../../componentes/inputTextoMascara";
import SelectAutoComplete from "../../../../componentes/selectAutocomplete";
import InputData from "../../../../componentes/inputTextoData";

// Serviços
import {
  salvarDistribuidoraPisCofins,
  buscarPorId
} from "../../../../servicos/distribuidoraPisCofinsServico";
import { buscarDistribuidoras } from "../../../../servicos/distribuidorasServico";

// Rotas
import { RotasDTO } from "../../../../global/rotas/rotasUrlDto";

// Styles
import { useStyles } from "./style";

// Redux
import { store } from "../../../../global/redux";
import { alertaExibir } from "../../../../global/redux/modulos/alertas/actions";

const DistribuidoraPisCofinsCadastro = () => {
  const { id } = useParams();
  const classes = useStyles();
  const history = useHistory();
  const { register, errors, handleSubmit } = useForm({
    reValidateMode: "onSubmit"
  });

  const [
    carregandoDistribuidoraPisCofins,
    setCarregandoDistribuidoraPisCofins
  ] = useState();
  const [
    dadosDistribuidoraPisCofins,
    setDadosDistribuidoraPisCofins
  ] = useState();
  const [carregandoDistribuidora, setCarregandoDistribuidora] = useState();
  const [distribuidoraId, setDistribuidoraId] = useState();
  const [listaDistribuidora, setListaDistribuidora] = useState([]);
  const [pisCofins, setPisCofins] = useState();
  const [inicioValidade, setInicioValidade] = useState(null);
  const [fimValidade, setFimValidade] = useState(null);

  // Distribuidora
  const obterDistribuidora = async () => {
    try {
      setCarregandoDistribuidora(true);
      const lista = await buscarDistribuidoras();
      if (lista?.status === 200 && lista?.data) {
        setListaDistribuidora(lista?.data);
      }
      setCarregandoDistribuidora(false);
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
      setCarregandoDistribuidora(false);
    }
  };

  useEffect(() => {
    obterDistribuidora();
  }, []);

  const enviarFormulario = async (dados) => {
    try {
      const salvou = await salvarDistribuidoraPisCofins(id ?? 0, {
        ...dados,
        distribuidoraId: distribuidoraId,
        pisCofins: formatarValorSalvar(dados?.pisCofins),
        dataIniciaVigencia: moment(inicioValidade).format("YYYY-MM-DD"),
        dataFimVigencia: moment(fimValidade).format("YYYY-MM-DD")
      });

      if (salvou?.status === 200 || salvou?.status === 204) {
        store.dispatch(
          alertaExibir({
            tipo: "success",
            mensagem: "Distribuidora pis/cofins salva com sucesso!"
          })
        );
        setTimeout(() => {
          history.push(RotasDTO.DistribuidoraPisCofins);
        }, 2000);
      }
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
    }
    setCarregandoDistribuidoraPisCofins(false);
  };

  const aoEnviarFormulario = (dados) => {
    setCarregandoDistribuidoraPisCofins(true);
    enviarFormulario(dados);
  };

  useEffect(() => {
    store.dispatch(desabilitarFiltroLateral(true));
    return () => store.dispatch(desabilitarFiltroLateral(false));
  }, []);

  function formatarValorSalvar(valor) {
    return valor
      ? parseFloat(
          String(valor)
            ?.replace(/[^0-9,.]/g, "")
            ?.replace(".", "")
            ?.replace(",", ".")
            ?.replace("%", "")
            ?.trim()
        )
      : valor;
  }

  const obterDistribuidoraPisCofins = useCallback(async (id) => {
    try {
      const distribuidoraPisCofins = await buscarPorId(id);
      if (
        distribuidoraPisCofins?.status === 200 &&
        distribuidoraPisCofins?.data
      ) {
        setDadosDistribuidoraPisCofins(distribuidoraPisCofins?.data);
        setDistribuidoraId(distribuidoraPisCofins?.data?.distribuidoraId);
        setPisCofins(distribuidoraPisCofins?.data?.pisCofins);
        setInicioValidade(distribuidoraPisCofins?.data?.dataIniciaVigencia);
        setFimValidade(distribuidoraPisCofins?.data?.dataFimVigencia);
      }
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
    }
  }, []);

  useEffect(() => {
    if (id) obterDistribuidoraPisCofins(id);
  }, [id, obterDistribuidoraPisCofins]);

  return (
    <>
      <form
        className="needs-validation"
        onSubmit={handleSubmit(aoEnviarFormulario)}
      >
        <Card className={classes.cardCadastro}>
          <Grid container p={2} spacing={4} className={classes.container}>
            <Grid item xs={6} className="font-weight-bold">
              {dadosDistribuidoraPisCofins?.id
                ? "Editar distribuidora pis/cofins"
                : "Nova distribuidora pis/cofins"}
            </Grid>
            <BotaoRetornarListagem
              urlListagem={RotasDTO.DistribuidoraPisCofins}
              zerarCombo
            />
            <Grid item lg={3} md={3} sm={4}>
              <Loader loading={carregandoDistribuidora}>
                <SelectAutoComplete
                  disableClearable
                  disableOpenOnFocus
                  id="distribuidoraId"
                  name="distribuidoraId"
                  options={listaDistribuidora}
                  label="Distribuidora"
                  getOptionLabel={(option) => option?.nomeFantasia ?? ""}
                  onChange={(e, item) => {
                    setDistribuidoraId(item?.id ?? 0);
                  }}
                  value={distribuidoraId}
                  ref={register(
                    { name: "distribuidoraId" },
                    {
                      required: !distribuidoraId
                    }
                  )}
                  error={errors}
                  allowClear
                  valueName="nomeFantasia"
                />
              </Loader>
            </Grid>
            <Grid item lg={3} md={3} sm={4}>
              <MaterialInputMascara
                type="text"
                id="pisCofins"
                name="pisCofins"
                sufixo="%"
                label="Pis/Cofins"
                defaultValue={pisCofins}
                renderIconShowHide={false}
                thousandSeparator=""
                ref={register({
                  required: "Campo Pis/Cofins é obrigatório!"
                })}
                errors={errors}
              />
            </Grid>
            <Grid item lg={3} md={3} sm={4}>
              <InputData
                type="text"
                id="dataIniciaVigencia"
                name="dataIniciaVigencia"
                label="Válido de"
                customValue={inicioValidade}
                minDate={moment().toDate()}
                onChange={(data) => setInicioValidade(data)}
                renderIconShowHide={false}
                ref={register({
                  required: "Campo Válido de é obrigatório!",
                  validate: (value) =>
                    (moment(value, "DD/MM/YYYY").isValid() &&
                      moment(value, "DD/MM/YYYY") >
                        moment("01/01/1900", "DD/MM/YYYY") &&
                      moment(value, "DD/MM/YYYY").startOf("day") >=
                        moment(moment(), "DD/MM/YYYY").startOf("day")) ||
                    "Data de Início da validade inválida!"
                })}
                errors={errors}
                className={classes}
              />
            </Grid>
            <Grid item lg={3} md={3} sm={4}>
              <InputData
                type="text"
                id="dataFimVigencia"
                name="dataFimVigencia"
                label="Válido até"
                customValue={fimValidade}
                minDate={new Date(inicioValidade)}
                onChange={(data) => setFimValidade(data)}
                renderIconShowHide={false}
                ref={register({
                  required: "Campo Válido até é obrigatório!",
                  validate: (value) =>
                    (moment(value, "DD/MM/YYYY").isValid() &&
                      moment(value, "DD/MM/YYYY") >
                        moment("01/01/1900", "DD/MM/YYYY") &&
                      moment(inicioValidade) < moment(value, "DD/MM/YYYY") &&
                      moment(value, "DD/MM/YYYY").startOf("day") >=
                        moment(moment(), "DD/MM/YYYY").startOf("day")) ||
                    "Data de Fim da validade inválida!"
                })}
                errors={errors}
                className={classes}
              />
            </Grid>

            <Grid item lg={3} md={4} sm={6} className={classes.containerSalvar}>
              <Loader loading={carregandoDistribuidoraPisCofins}>
                <Botao
                  type="submit"
                  label="Salvar"
                  className={classes.button}
                />
              </Loader>
            </Grid>
          </Grid>
        </Card>
      </form>
    </>
  );
};

export default DistribuidoraPisCofinsCadastro;
