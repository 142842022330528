import React, { useState, useEffect, useMemo } from "react";
import { useForm, useWatch } from "react-hook-form";

// Componentes
import { Grid, Button, Box, Tooltip } from "@mui/material";
import Modal from "../../../../componentes/modal";
import Coluna from "../../../../componentes/tabelaPaginada/colunas/coluna";
import ResultadoPaginadoDto from "../../../../componentes/tabelaPaginada/resultadoPaginadoDto";
import ColunaComponentePersonalizado from "componentes/tabelaPaginada/colunas/colunaComponentePersonalizado";
import { Loader, TabelaPaginada, SelectArredondado } from 'componentes';
import { saveAs } from "file-saver";
import MaterialInputBusca from "componentes/inputBusca";
import ColunaMultiplosBotoes from "./colunaCustomizada";

// Serviços
import { listarFaturaDistribuicaoCliente } from "../../../../servicos/faturaDistribuidoraServico";
import { obterDadosFaturaArquivo } from "../../../../servicos/digitalizacaoServico";

//dtos
import FaturaDistribuidoraDto from './faturaDistribuidoraDto';

// Redux
import { store } from "../../../../global/redux";
import { alertaExibir } from "../../../../global/redux/modulos/alertas/actions";
import { useSelector } from "react-redux";

import { useStyles } from "./style";
import { b64toBlob, isPdfFile } from "global/utils/blobUtil";
import enumerador from "./colunaCustomizada/enumerador";

const ListagemFaturaDistribuidoraCliente = () => {

  const global = useSelector((state) => state.usuario);
  const { empresaSelecionada, clienteSelecionado, unidadeSelecionada } = global;

  const [carregandoFaturaDistribuidora, setCarregandoFaturaDistribuidora] = useState(false);
  const [lista, setLista] = useState([]);
  const [filtroAdicionalTabela, setFiltroAdicionalTabela] = useState();
  const [modalAcao, setModalAcao] = useState(false);
  const [detalhes, setDetalhes] = useState([]);
  const [mes, setMes] = useState(0);
  const [ano, setAno] = useState(0);
  const [campoPesquisa, setCampoPesquisa] = useState("");
  const classes = useStyles();

  const { register, control } = useForm({
    reValidateMode: "onSubmit"
  });

  const textoBusca = useWatch({
    control,
    name: "textoBusca",
    defaultValue: ""
  });

  const textoParaBusca = useMemo(() => {
    return textoBusca?.length >= 3 ? textoBusca : "";
  }, [textoBusca]);

  const montarModal = () => {

    return (
      <Loader loading={carregandoFaturaDistribuidora} className="w-auto">
        <Box className={classes.dataConsulta}><b>Data de consulta:</b> {detalhes.dataUploadFormatada}</Box>
        <Grid container>
          <Grid xs={4} className={classes.gridPaddingTopo}>
            <div><b>Nome: </b>{detalhes.nomeUnidade}</div>
          </Grid>
          <Grid xs={4} className={classes.gridPaddingTopo}>
            <div><b>CNPJ: </b> {detalhes.cnpjCpfFormatado}</div>
          </Grid>
          <Grid xs={4} className={classes.gridPaddingTopo}>
            <div><b>Telefone: </b> {detalhes.telefone}</div>
          </Grid>
          <Grid xs={6} className={classes.gridPadding}>
            <div><b>Externo Id:</b> {detalhes.guidFatura} </div>
            <div><b>Protocolo demanda:</b> {detalhes.protocoloDemanda} </div>
            <div><b>Tipo Cliente:</b> {detalhes.pessoaFisicaJuridica} </div>
          </Grid>
          <Grid xs={6} className={classes.gridPadding}>
            <div><b>CEP Cliente:</b> {detalhes.cep}</div>
            <div><b>Distribuidora:</b> {detalhes.nomeDistribuidora} </div>
            <div><b>Origem:</b> {detalhes.origem} </div>
          </Grid>
          <Grid xs={12} className={classes.btnAcoes}>
            <Button onClick={() => onClickDownload(detalhes.guidFatura)} className={classes.btnDownload}>
              <div>
                Download
              </div>
            </Button>
          </Grid>
        </Grid>
      </Loader>
    )
  }

  const onClickDownload = async (guidFatura) => {

    setCarregandoFaturaDistribuidora(true);

    try {
      const retornoFaturaArquivo = await obterDadosFaturaArquivo(guidFatura);

      if (retornoFaturaArquivo.arquivoOriginal == null) {
        store.dispatch(
          alertaExibir({
            tipo: "warning",
            mensagem: "Arquivo não encontrado!"
          })
        );
      }
      else {
        isPdfFile(retornoFaturaArquivo.arquivoOriginal);
        const blob = b64toBlob(retornoFaturaArquivo.arquivoOriginal, isPdfFile(retornoFaturaArquivo.arquivoOriginal) && 'application/pdf');
        saveAs(blob, retornoFaturaArquivo.arquivoNome);
      }
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
    }

    setCarregandoFaturaDistribuidora(false);
  };

  const onClickDetalhar = (parametros) => {
    setDetalhes(parametros);
    setModalAcao(true);
  };

  const onClickBotao = (tipo, parametros) => {
    switch (tipo) {
      case enumerador.Download:
        onClickDownload(parametros.guidFatura);
        break;
      case enumerador.Detalhar:
        onClickDetalhar(parametros);
        break;
      default:
        break;
    }
  };
  
  const colunas = [
    new Coluna("protocoloDemanda", "Protocolo Demanda"),
    new Coluna("cnpjCpfFormatado", "CNPJ/CPF"),
    new Coluna("nomeUnidade", "Unidade"),
    new Coluna("pessoaFisicaJuridica", "Tipo Cliente"),
    new Coluna("dataUploadFormatada", "Data Upload"),
    new ColunaComponentePersonalizado(
      "opcoes",
      "Opções",
      ColunaMultiplosBotoes,
      onClickBotao
    )
  ];

  const onChangeFiltrosTabela = async (parametros) => {
    return await obterLeadClientes({
      pagina: parametros.pagina,
      pesquisar: parametros.pesquisar,
      mes: mes,
      ano: ano,
      campoPesquisa: campoPesquisa
    });
  };

  useEffect(() => {
    setFiltroAdicionalTabela({
      mes,
      ano,
      campoPesquisa,
      empresaSelecionada,
      clienteSelecionado,
      unidadeSelecionada
    })
  }, [mes, ano, empresaSelecionada, clienteSelecionado, unidadeSelecionada]);

  const obterLeadClientes = async (parametros) => {

    try {
      setCarregandoFaturaDistribuidora(true);

      const resultado = await listarFaturaDistribuicaoCliente(
        parametros.pagina,
        parametros.campoPesquisa,
        parametros.pesquisar,
        parametros.ano,
        parametros.mes,
        empresaSelecionada,
        clienteSelecionado,
        unidadeSelecionada
      );

      setCarregandoFaturaDistribuidora(false);

      if (!resultado.status === 200) {
        store.dispatch(
          alertaExibir({
            tipo: "danger",
            mensagem: resultado.mensagem
          })
        );
        return new ResultadoPaginadoDto([], 1, 0, 0);
      }

      if (resultado?.length === 0 ?? true)
        return new ResultadoPaginadoDto([], 1, 0, 0);

      const resLinhas = await resultado.data.faturaDistribuidora?.map((res) => {
        return new FaturaDistribuidoraDto(
          res.unidadeId,
          (
            <Tooltip
              title={
                <span style={{ fontSize: "14px" }}>{res.nomeUnidade}</span>
              }
              aria-label={res.nomeUnidade}
            >
              <span>{res.nomeUnidade}</span>
            </Tooltip>
          ),
          res.pessoaFisicaJuridica,
          (
            <Tooltip
              title={
                <span style={{ fontSize: "14px" }}>{res.cnpjCpfFormatado}</span>
              }
              aria-label={res.cnpjCpfFormatado}
            >
              <span>{res.cnpjCpfFormatado}</span>
            </Tooltip>
          ),
          res.dataUploadFormatada,
          res.guidFatura,
          res.protocoloDemanda,
          res.cep,
          res.nomeDistribuidora,
          res.origem,
          res.telefone
        );
      });
      setLista(resLinhas);

      return new ResultadoPaginadoDto(
        resLinhas,
        resultado.data.paginaAtual,
        resultado.data.totalItens,
        resultado.data.totalPaginas
      );
    } catch (error) {
      setCarregandoFaturaDistribuidora(false);
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
    }
  };

  return (
    <Loader loading={carregandoFaturaDistribuidora} className="w-auto">
      <Modal
        item={modalAcao}
        mensagem={montarModal()}
        onCancelar={() => setModalAcao(false)}
      />
      <Grid container spacing={3} alignItems="center" justifyContent="space-between">
        <Grid item md={2} xs={3}>
          <SelectArredondado
            select
            id="CampoPesquisa"
            name="CampoPesquisa"
            label="Filtrar por campo"
            placeholder="Filtrar por campo"
            valueKey="valor"
            valueName="nome"
            className={classes.select}
            dataSource={[
              { nome: 'Sem filtro', valor: "" },
              { nome: 'CPF/CNPJ', valor: "CpfCnpj" },
              { nome: 'Nome Unidade', valor: "NomeUnidade" },
              { nome: 'Protocolo Demanda', valor: "ProtocoloDemanda" }
            ]}
            value={campoPesquisa}
            onChange={(e) => {
              setCampoPesquisa(e.target.value);
            }}
            permiteValorBranco
            styleForm={false}
          />
        </Grid>
        <Grid item md={4} xs={3}>
          {campoPesquisa != "" ? (
            <>
              <MaterialInputBusca
                type="text"
                id="textoBusca"
                name="textoBusca"
                label="Buscar"
                className={classes.search}
                renderIconShowHide
                searchAdornment
                defaultValue={textoBusca ?? ""}
                ref={register}
                permiteValorBranco
              />
            </>
          ) : null}
        </Grid>
        <Grid item md={3} xs={3}>
          <SelectArredondado
            select
            id="Ano"
            name="Ano"
            label="Ano"
            placeholder="Ano"
            valueKey="valor"
            valueName="nome"
            className={classes.select}
            dataSource={[
              { nome: 'Todos', valor: "" },
              { nome: '2022', valor: 2022 },
              { nome: '2023', valor: 2023 },
              { nome: '2024', valor: 2024 },
              { nome: '2025', valor: 2025 },
              { nome: '2026', valor: 2026 },
              { nome: '2027', valor: 2027 },
              { nome: '2028', valor: 2028 },
              { nome: '2029', valor: 2029 },
              { nome: '2030', valor: 2030 },
              { nome: '2031', valor: 2031 },
              { nome: '2032', valor: 2032 },
              { nome: '2033', valor: 2033 },
              { nome: '2034', valor: 2034 },
              { nome: '2035', valor: 2035 },
              { nome: '2036', valor: 2036 },
              { nome: '2037', valor: 2037 },
              { nome: '2038', valor: 2038 },
              { nome: '2039', valor: 2039 },
              { nome: '2040', valor: 2040 }
            ]}
            value={ano}
            onChange={(e) => {
              setAno(e.target.value);
            }}
            permiteValorBranco
            styleForm={false}
          />
        </Grid>
        <Grid item md={3} xs={3}>
          <SelectArredondado
            select
            id="Mes"
            name="Mes"
            label="Mês"
            placeholder="Mês"
            valueKey="valor"
            valueName="nome"
            className={classes.select}
            dataSource={[
              { nome: 'Todos', valor: "" },
              { nome: 'Janeiro', valor: 1 },
              { nome: 'Fevereiro', valor: 2 },
              { nome: 'Março', valor: 3 },
              { nome: 'Abril', valor: 4 },
              { nome: 'Maio', valor: 5 },
              { nome: 'Junho', valor: 6 },
              { nome: 'Julho', valor: 7 },
              { nome: 'Agosto', valor: 8 },
              { nome: 'Setembro', valor: 9 },
              { nome: 'Outubro', valor: 10 },
              { nome: 'Novembro', valor: 11 },
              { nome: 'Dezembro', valor: 12 }
            ]}
            value={mes}
            onChange={(event) => {
              setMes(event.target.value);
            }}
            permiteValorBranco
            styleForm={false}
          />
        </Grid>
        <Grid item xs={12} className="mt-1">
          <TabelaPaginada
            onChangeFiltrosTabela={onChangeFiltrosTabela}
            linhas={lista}
            colunas={colunas}
            pesquisar={textoParaBusca}
            filtrosAdicionais={filtroAdicionalTabela}
            paginaAtual={1}
            paginationPerPage={2}
            className={classes.tabelaPaginada}
            noHeader
          />
        </Grid>
      </Grid>
    </Loader>
  );
};

export default ListagemFaturaDistribuidoraCliente;
