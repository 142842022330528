import MICROSERVICO from "../global/enumeradores/microServico";
import obterApi from "./api";
import { projecoesTipo } from "../global/constantes";
import moment from "moment";

const api = () => obterApi(MICROSERVICO.MEASUREMENT);

export const obterProjecaoDinamica = (
  tipoProjecao,
  unidadeId,
  dataReferencia
) => {
  switch (tipoProjecao) {
    case projecoesTipo.diaUtilNaoUtil:
      return obterProjecaoMediaUtil(unidadeId, dataReferencia);
    case projecoesTipo.diasSemanaHora:
      return obterProjecaoExtrapolacaoDiasSemanaHora(unidadeId, dataReferencia);
    case projecoesTipo.horasDePontaEForaPonta:
      return obterProjecaoHoraPontaForaPonta(unidadeId, dataReferencia);
    case projecoesTipo.mediaSimples:
      return obterProjecaoMedia(unidadeId, dataReferencia);
    case projecoesTipo.mesesAnteriores:
      return obterProjecaoMesesAnosAnteriores(unidadeId, dataReferencia);
    default:
      return null;
  }
};

export const obterProjecaoMedia = (unidadeId, dataReferencia) => {
  return api().get(
    `/v1/projecaoconsumo/media/${unidadeId}/${moment(dataReferencia).format(
      "YYYY-MM-DD"
    )}`
  );
};

export const obterProjecaoMesesAnosAnteriores = (unidadeId, dataReferencia) => {
  return api().get(
    `/v1/projecaoconsumo/mesesanosanteriores/${unidadeId}/${moment(
      dataReferencia
    ).format("YYYY-MM-DD")}`
  );
};

export const obterProjecaoHoraPontaForaPonta = (unidadeId, dataReferencia) => {
  return api().get(
    `/v1/projecaoconsumo/horapontahoraforaponta/${unidadeId}/${moment(
      dataReferencia
    ).format("YYYY-MM-DD")}`
  );
};

export const obterProjecaoMediaUtil = (unidadeId, dataReferencia) => {
  return api().get(
    `/v1/projecaoconsumo/mediadiautil/${unidadeId}/${moment(
      dataReferencia
    ).format("YYYY-MM-DD")}`
  );
};

export const obterProjecaoExtrapolacaoDiasSemanaHora = (
  unidadeId,
  dataReferencia
) => {
  return api().get(
    `/v1/projecaoconsumo/extrapolacaodiasemanahora/${unidadeId}/${moment(
      dataReferencia
    ).format("YYYY-MM-DD")}`
  );
};
