import { Card, Grid } from "@mui/material";
import { ProtocolosEtapas, tipoArquivoWorkFlow } from "global/constantes";
import React from "react";
import PropTypes from "prop-types";
import EsqueletoEtapa from "../../Componentes/EsqueletoEtapa";

const AnaliseSeguranca = ({ prazo, showButton }) => {
  return (
    <EsqueletoEtapa
      prazo={prazo}
      titulo="Análise de segurança"
      subtitulo="Aguarde enquanto realizamos a análise de segurança dos documentos e informações enviadas. Entraremos em contato por e-mail assim que a análise estiver completa."
      esconderBotaoProximo={false}
      showButton={showButton}
    />
  );
};

AnaliseSeguranca.propTypes = {
  prazo: PropTypes.node,
  showButton: PropTypes.bool
};

AnaliseSeguranca.defaultProps = {
  prazo: "",
  showButton: false
};

export default AnaliseSeguranca;
