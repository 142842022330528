import React from "react";
import PropTypes from "prop-types";

// Ícones
import CloseIcon from "@mui/icons-material/Close";

// Componentes
import Botao from "../botao";
import { Base } from "componentes/cores";
import { Grid } from "@mui/material";
import Modal from "@mui/material/Modal";

// Styles
import { useStyles } from "./style";

const ModalInformacao = React.forwardRef((props, ref) => {
  const {
    exibir,
    onClose,
    labelBotao,
    titulo,
    mensagem,
    telaInteira,
    showBotao,
    showBotaoLink,
    className,
    possuiHtml
  } = props;

  const classes = useStyles();

  return (
    <Modal
      aria-labelledby="tituloModalConfirmacao"
      aria-describedby="descricaoModalConfirmacao"
      open={exibir}
      onClose={onClose}
    >
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        className={telaInteira ? classes.containerTelaCheia : classes.container}
        spacing={1}
      >
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          className="mb-4"
        >
          <Grid item xs={6}>
            <h3 className={classes.titulo} id="tituloModalConfirmacao">
              {titulo}
            </h3>
          </Grid>
          <Grid item xs={6} className={classes.item}>
            <CloseIcon className={classes.icon} onClick={onClose} />
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          className={classes.divMensagem}
        >
          <Grid
            item
            xs={12}
            className={telaInteira ? className || classes.itemMensagem : ""}
          >
            { possuiHtml && (<div className="h-100 w-100" ref={ref} id="descricaoModalConfirmacao" dangerouslySetInnerHTML={{ __html: mensagem }} />) }
            { !possuiHtml && (<label className="h-100 w-100" ref={ref} id="descricaoModalConfirmacao" > {mensagem} </label>) }
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          spacing={0}
          xs={12}
        >
          {showBotao && (
            <Grid item xs={1} direction="row" justifyContent="flex-end">
              <Botao
                onClick={onClose}
                color="transparent"
                label={labelBotao}
                style={{
                  color: Base.BlueLochmara
                }}
                className={classes.button}
              />
            </Grid>
          )}
          {showBotaoLink && (
            <Grid item xs={1} direction="row" justifyContent="flex-end">
              <Botao
                onClick={onClose}
                label={labelBotao}
                className={`${classes.buttonLink} pb-3 font-weight-bold`}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Modal>
  );
});

ModalInformacao.propTypes = {
  exibir: PropTypes.oneOfType([PropTypes.bool]).isRequired,
  onClose: PropTypes.oneOfType([PropTypes.func]).isRequired,
  mensagem: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  titulo: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  labelBotao: PropTypes.oneOfType([PropTypes.node, PropTypes.string])
    .isRequired,
  telaInteira: PropTypes.oneOfType([PropTypes.bool]),
  showBotao: PropTypes.oneOfType([PropTypes.bool]),
  showBotaoLink: PropTypes.oneOfType([PropTypes.bool]),
  className: PropTypes.oneOfType([PropTypes.any])
};

ModalInformacao.defaultProps = {
  showBotao: true,
  className: false,
  showBotaoLink: false
};

export default ModalInformacao;
