import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import { Grid, Button, InputAdornment, IconButton } from "@mui/material";
import { Edit, Clear, Check, Block } from "@mui/icons-material";
import TextField from "@mui/material/TextField";
import NumberFormat from "react-number-format";

import { useStyles } from "./style";
import { HtmlTooltip } from "../../../Graficos/ConsumoAtual/style";

const InputEditavel = ({
  id,
  name,
  type,
  valorInput,
  onClick,
  mask,
  onBlur,
  onInputChange,
  readOnly,
  prefixo,
  sufixo,
  thousandSeparator,
  disabled,
  bloqueado,
  editar,
  erro,
  editarInputs
}) => {
  const classes = useStyles();
  const [valor, setValor] = useState(valorInput);
  const [desabilitado, setDesabilitado] = useState(disabled);

  const onClickSalvar = () => {
    if (!bloqueado) {
      onClick(valor);
      setDesabilitado(!desabilitado);
    }
  };

  const onClickEditar = () => {
    if (!bloqueado) {
      setDesabilitado(false);
      editar();
    }
  };

  const CustomMask = useCallback(
    (customProps) => {
      const { inputRef, ...rest } = customProps;

      const customMask =
        mask === null
          ? ""
          : {
              ...(mask && { format: mask }),
              ...(!mask && {
                thousandSeparator: thousandSeparator,
                decimalSeparator: ",",
                fixedDecimalScale: true,
                allowedDecimalSeparators: [","]
              })
            };

      return (
        <NumberFormat
          {...rest}
          getInputRef={inputRef}
          onValueChange={(value) => {
            setValor(value.formattedValue);
            onInputChange(value.formattedValue);
          }}
          {...customMask}
          prefix={prefixo}
          suffix={sufixo}
          isNumericString
          onBlur={onBlur}
          onFocus={() => inputRef?.current?.focus()}
          readOnly={readOnly}
        />
      );
    },
    [mask, onBlur, readOnly, prefixo, sufixo]
  );

  return (
    <Grid container spacing={0}>
      <Grid item xs={9}>
        <TextField
          inputRef={(input) => {
            if (input != null) {
              input.focus();
            }
          }}
          id={id}
          name={name}
          type={type}
          value={valor}
          className={`${classes.input} ${
            desabilitado ? classes.inputDisabled : classes.inputEnable
          } ${erro && classes.inputErro}`}
          disabled={desabilitado}
          variant="outlined"
          InputProps={{
            inputComponent: CustomMask,
            endAdornment: !desabilitado ? (
              <InputAdornment position="end">
                <HtmlTooltip title="Limpar">
                  <IconButton
                    aria-label="Limpar"
                    edge="end"
                    onClick={() => setValor("")}
                    className={classes.botaoInput}
                    size="large"
                  >
                    <Clear fontSize="small" className={classes.icon} />
                  </IconButton>
                </HtmlTooltip>
              </InputAdornment>
            ) : null
          }}
        />
      </Grid>
      <Grid item xs={3}>
        {desabilitado ? (
          <Button
            key={id}
            onClick={() => onClickEditar()}
            className={`${classes.botao} ${classes.ativo}`}
            disabled={editarInputs}
          >
            <HtmlTooltip
              title={!bloqueado ? (editarInputs ? "" : "Editar") : "Bloqueado"}
            >
              {!bloqueado ? (
                <Edit
                  color="disabled"
                  fontSize="small"
                  className={classes.icon}
                />
              ) : (
                <Block
                  color="disabled"
                  fontSize="small"
                  className={classes.icon}
                />
              )}
            </HtmlTooltip>
          </Button>
        ) : (
          <Button
            key={id}
            onClick={() => onClickSalvar()}
            className={`${classes.botao} ${classes.ativo}`}
          >
            <HtmlTooltip
              title="Salvar"
              fontSize="small"
              className={classes.icon}
            >
              <Check />
            </HtmlTooltip>
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

InputEditavel.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onClick: PropTypes.func,
  onBlur: PropTypes.func,
  valorInput: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  readOnly: PropTypes.bool,
  mask: PropTypes.string,
  prefixo: PropTypes.string,
  sufixo: PropTypes.string,
  thousandSeparator: PropTypes.string,
  disabled: PropTypes.bool,
  bloqueado: PropTypes.bool,
  editar: PropTypes.func,
  onInputChange: PropTypes.func,
  erro: PropTypes.bool,
  editarInputs: PropTypes.bool
};

InputEditavel.defaultProps = {
  type: "text",
  onClick: () => {},
  mask: "",
  valorInput: "",
  onBlur: () => {},
  onInputChange: () => {},
  readOnly: false,
  sufixo: "",
  prefixo: "",
  disabled: true,
  bloqueado: false,
  editar: () => {},
  erro: false,
  editarInputs: false
};

export default InputEditavel;
