import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";

// ApexChart
import ReactApexChart from "react-apexcharts";

// Styles
import { useStyles } from "./style";
import { Base } from "../../../../../componentes/cores";
import theme from 'themes';

const CustoLivre = ({ dadosLivre }) => {
	const classes = useStyles();

	const [custoLivre, setCustoLivre] = useState();
	const [labels, setLabels] = useState();

	const formatarTotal = (valor) => {
		return valor?.toLocaleString("pt-br", {
			style: "currency",
			currency: "BRL",
			maximumFractionDigits: 0,
			minimumFractionDigits: 0
		});
	};

	const legendaTooltip = (legenda) => {
		return legenda.charAt(0).toUpperCase() + legenda.slice(1);
	};

	const dados = useMemo(
		() => ({
			series: custoLivre,
			options: {
				chart: {
					type: "donut"
				},
				labels,
				colors: [theme.color.donutEnergiaLivre, theme.color.donutDistribuidoraLivre, theme.color.donutImpostoLivre, theme.color.donutOutrosLivre],
				stroke: {
					width: 0
				},
				legend: {
					fontWeight: "bold",
					fontSize: "16px",
					formatter(seriesName, opts) {
						return [
							legendaTooltip(seriesName),
							" ",
							opts.w.globals.series[opts.seriesIndex]?.toLocaleString("pt-br", {
								style: "currency",
								currency: "BRL",
								maximumFractionDigits: 0,
								minimumFractionDigits: 0
							})
						];
					},
					labels: {
						colors: theme.color.textoBarra,
						fontFamily: theme.font.family,
						fontSize: "18px"
					},
					markers: {
						radius: 0
					},
					// offsetY: "auto",
					position: "bottom"
				},
				plotOptions: {
					pie: {
						offsetX: -90,
						donut: {
							labels: {
								show: true,
								name: {
									show: true
								},
								value: {
									show: true,
									color: theme.color.textoBarra,
									fontSize: "22px",
									fontWeight: 800,
									formatter(value) {
										return formatarTotal(parseFloat(value));
									}
								},
								total: {
									show: true,
									showAlways: true,
									color: theme.color.textoBarra,
									label: "Custo Livre",
									formatter(value) {
										return formatarTotal(
											value.globals.seriesTotals.reduce((a, b) => {
												return a + b;
											}, 0)
										);
									}
								}
							},
							size: "25%"
						},
						customScale: 0.9
					}
				},
				dataLabels: {
					enabled: false
				},
				yaxis: {
					labels: {
						formatter(val) {
							return val?.toLocaleString("pt-br", {
								style: "currency",
								currency: "BRL",
								maximumFractionDigits: 0,
								minimumFractionDigits: 0
							});
						}
					}
				},
				tooltip: {
					y: {
						title: {
							formatter: (seriesName) => {
								return legendaTooltip(seriesName);
							}
						}
					}
				}
			}
		}),
		[custoLivre, labels]
	);

	useEffect(() => {
		if (dadosLivre) {
			setLabels(Object.keys(dadosLivre));
			setCustoLivre([
				parseFloat(dadosLivre?.energia),
				parseFloat(dadosLivre?.distribuidora),
				parseFloat(dadosLivre?.impostos),
				parseFloat(dadosLivre?.outros)
			]);
		}
	}, [dadosLivre]);

	return (
		<div className={classes.container}>
			{dadosLivre && dados.options && dados.series ? (
				<ReactApexChart
					options={dados.options}
					series={dados.series}
					type="donut"
				/>
			) : null}
		</div>
	);
};

CustoLivre.propTypes = {
	dadosLivre: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
};

CustoLivre.defaultProps = {
	dadosLivre: {}
};

export default CustoLivre;
