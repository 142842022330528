import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import { useStyles } from "./style";
import enumeradorStatus from "../../enumeradores/enumeradorStatus";

const ColunaStatus = ({ parametros }) => {
  const classes = useStyles();
  
  return (    
    <Grid container spacing={0} className={classes.fundoBotoes}>
      <Grid item xs={1} className={
                                    parametros.statusId == enumeradorStatus.PENDENTE ? classes.fundoPendente : 
                                    parametros.statusId == enumeradorStatus.ATRASADO ? classes.fundoAtrasado : 
                                    parametros.statusId == enumeradorStatus.PAGO_PARCIAL ? classes.fundoPagoParcial : 
                                    parametros.statusId == enumeradorStatus.PAGO ? classes.fundoPago : ""
                                  }
      ></Grid>
      <Grid item xs={11} className={classes.textoStatus}>
        {parametros.statusNome}
      </Grid>
    </Grid>
  );
};

ColunaStatus.propTypes = {
  onClick: PropTypes.oneOfType([PropTypes.func]).isRequired,
  parametros: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
    .isRequired
};

export default ColunaStatus;
