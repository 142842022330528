import styled from "styled-components";
import { makeStyles } from '@mui/styles';
import { Base } from "../../cores";
import theme from 'themes';

export const useStyles = makeStyles({
  forcePosition: {
    left: "-108px",
    position: "absolute"
  },
  squareWidth: {
    height: "54px",
    width: "54px"
  },
  treeViewItemExpand: {
    color: Base.GreyDim,
    justifyContent: "end",
    padding: 0,
    position: "relative"
  },
  treeViewBorder: {
    borderLeftColor: (props) => props.color,
    borderLeftWidth: "6px",
    borderLeftStyle: "solid"
  },
  gridItem: {
    flexBasis: "100%",
    fontSize: "2.1875rem",
    margin: 0,
    maxWidth: "100%",
    width: "54px"
  },
  treeItem: {
    backgroundColor: theme.color.bgItemExpansivel,
    color: `${Base.OffWhite} !important`,
    height: "54px"
  },
  wrapper: {
    alignItems: "center",
    display: "flex",
    height: "100%",
    minHeight: "100%"
  },
  start: {
    alignItems: "flex-start"
  },
  center: {
    justifyContent: "center"
  },
  left: {
    justifyContent: "start",
    paddingLeft: "20px"
  },
  right: {
    justifyContent: "flex-end"
  }
});

export const Icon = styled.div`
  border-left-color: ${theme.color.fourthBackgroud};
  border-left-style: solid;
  border-left-width: 6px;
  border-bottom-color: ${theme.color.fourthBackgroud};
  border-bottom-width: 6px;
  border-bottom-style: solid;
  display: block;
  height: 30px;
  width: 30px;
`;
