import { makeStyles } from '@mui/styles';
import { Base } from "componentes/cores";

export const useStyles = makeStyles({
  botaoAdicionar: {
    backgroundColor: Base.White,
    border: "none"
  },
  botaoExcluir: {
    backgroundColor: Base.White,
    width: "100%",
    display: "flex",
    alignItems: "center",
    border: "none",
    height: "100%"
  }
});
