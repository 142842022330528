import styled from "styled-components";
import theme from 'themes';

export const BotaoFiltroEstilo = styled.button`
  background-color: ${theme.color.bgBotaoInicial} !important;
  border-color: ${theme.color.bgBotaoInicial} !important;
  color: ${theme.color.labelBotaoInicial} !important;
  &:hover {
    color: #000;
  }
  & > svg {
    margin-left: 15px;
  }
`;
