import React, { useMemo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import ModalInformacao from "componentes/modalVerificacao";

const InputCheckBoxTermo = React.forwardRef((props, ref) => {
  const { name, errors, termoDescricao } = props;
  const isInvalid = useMemo(() => {
    return errors[name]?.message ?? false;
  }, [errors, name]);

  const classNameCampo = useMemo(() => {
    let classe = "";

    if (!isInvalid) {
      classe = "w-auto mb-3";
    } else classe = "w-auto is-invalid";

    return classe;
  }, [isInvalid]);

  // const [exibirModalTermo, setExibirModalTermo] = useState(false);

  //   const handleClick = () => {
  //     setExibirModalTermo(true);
  //   };

  return (
    <>
      <ModalInformacao
        telaInteira={true}
        exibir={false}
        titulo="Termo de uso e privacidade"
        mensagem={termoDescricao}
        labelBotao="OK"
        onClose={() => {}}
        showBotao={false}
        possuiHtml={true}
      />
      <div className="form-group" style={{ marginTop: 15 }}>
        {/* <input
                    type="checkbox"
                    id="aceitatermos"
                    name={name}
                    className={classNameCampo}
                    ref={ref}
                />
                <span>
                    &nbsp; Eu aceito os{" "}
                    <a href="#" onClick={handleClick}>
                        termos de uso e privacidade
                    </a>
                </span> */}
        {isInvalid && <div className="invalid-feedback mb-3">{isInvalid}</div>}
      </div>
    </>
  );
});

InputCheckBoxTermo.propTypes = {
  onChange: PropTypes.oneOfType([PropTypes.func]),
  disabled: PropTypes.bool,
  label: PropTypes.string,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  legenda: PropTypes.string,
  type: PropTypes.string,
  errors: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  defaultValue: PropTypes.string
};

InputCheckBoxTermo.defaultProps = {
  onChange: () => {},
  disabled: false,
  label: "",
  legenda: "",
  icon: true,
  type: "text",
  errors: {},
  defaultValue: ""
};

export default InputCheckBoxTermo;
