import { makeStyles } from '@mui/styles';
import theme from "themes";
import { Base } from "../../../componentes/cores";

export const useStyles = makeStyles(() => ({
  titulo: {
    fontWeight: "bold"
  },
  container: {
    "& .MuiInputBase-root": {
      color: theme.color.labelColorGrafico
    },
    "& .MuiInputBase-input": {
      maxWidth: "65px"
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "0 none"
    },
    "& .MuiButtonBase-root": {
      color: theme.color.labelColorGrafico
    }
  },
  itemGrafico: {
    display: "block",
    maxWidth: "100%",
    "& .apexcharts-tooltip.apexcharts-theme-light": {
      border: "0 none",
      background: "none",
      width: "fit-content",
      height: "fit-content",
      textAlign: "center",
      fontSize: "13px"
    }
  },
  noData: {
    textAlign: "center",
    width: "100%",
    minHeight: "200px",
    "& div": {
      margin: "125px auto 0",
      maxWidth: "80%"
    }
  },
  arrowBox: {
    position: "relative",
    background: Base.GreyPaleSky,
    padding: "8px",
    color: Base.White
  },
  arrowBoxTitle: {
    color: `${Base.White} !important`,
    fontSize: "10px"
  },
  datePickerWrapper: {
    padding: "0!important",
    width: "100%",
    color: `${Base.White} !important`,
    "& svg": {
      color: Base.White
    },
    "& fieldset": {
      borderColor: "transparent!important",
      color: Base.White
    },
    "& > div.MuiGrid-container": {
      width: "100%",
      padding: 0,
      margin: 0,
      "& > div.MuiGrid-item": {
        width: "100%",
        padding: 0,
        margin: 0,
        color: `${Base.White} !important`,
        "& > div.MuiGrid-container": {
          width: "100%",
          padding: 0,
          margin: 0,
          "& > div": {
            width: "100%",
            padding: 0,
            margin: 0,
            "& div": {
              color: Base.White
            }
          }
        }
      }
    }
  },
  tableColumn:{
    backgroundColor: '#f0f0f0',
    maxWidth: 50,
    padding: 6
  },
  tableColumnCons:{
    padding: 8,
    paddingRight: '0 !important'
  },
  table:{
    fontSize: '11 !important',
  },
  itemGraficoInferior: {
    maxWidth: "100%",
    "& .apexcharts-tooltip.apexcharts-theme-light": {
      border: "0 none",
      background: "none",
      width: "fit-content",
      height: "fit-content",
      textAlign: "center",
      fontSize: "13px"
    }
  },
}));
