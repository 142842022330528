import { createTheme } from "@mui/material";
import { makeStyles } from '@mui/styles';
import { Base } from "../cores";
import theme from "../../themes";

export const useStyles = makeStyles(() => ({
  root: {
    "& label.Mui-focused": {
      color: `${theme.color.secondaryText}`
    }
  },
  container: {
    "& .MuiFormControl-marginNormal": {
      margin: "0",
      width: "100%"
    },
    "& .MuiInputBase-root": {
      color: `${theme.color.secondaryText} !important`
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      border: "none"
    }
  }
}));

export const materialTheme = createTheme({
  palette: {
    primary: {
      main: theme.color.primary,
      contrastText: Base.White,
    },
  },
});
