import React, { useCallback, useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import QRCode from "react-qr-code";

// Form Hooks
import { useForm, useWatch } from "react-hook-form";

// Componentes
import { Close, FileCopyRounded } from "@mui/icons-material";
import { Card, Grid, Box } from "@mui/material";
import Modal from "@mui/material/Modal";
import { ImQrcode } from "react-icons/im";
import { validacaoTrimObject } from "../../../../servicos/utils";
import Botao from "../../../../componentes/botao";
import BotaoRetornarListagem from "../../../../componentes/botaoRetornarListagem";
import MaterialInputTexto from "../../../../componentes/inputTexto/materialInput";
import Loader from "../../../../componentes/loader";
import SelectArredondado from "../../../../componentes/selectArredondado";
import MaterialSwitch from "../../../../componentes/switchMaterial";
import ArvoresGerenciasEmpresas from "./componentes/arvoreGerencial";

// Serviços
import {
  salvarColaborador,
  buscarPorId,
  listarAreaFuncional,
  listarCargo
} from "../../../../servicos/colaboradoresServico";

// Rotas
import { RotasDTO } from "../../../../global/rotas/rotasUrlDto";

// Styles
import { useStyles } from "./style";

// Redux
import { store } from "../../../../global/redux";
import { alertaExibir } from "../../../../global/redux/modulos/alertas/actions";
import {
  desabilitarCliente,
  desabilitarEmpresa,
  desabilitarUnidade
} from "../../../../global/redux/modulos/usuario/actions";
import CheckInputs from "../../../../componentes/checkInputs";

const ColaboradorCadastro = () => {
  const { id } = useParams();
  const classes = useStyles();
  const history = useHistory();
  const { register, errors, control, handleSubmit } = useForm({
    reValidateMode: "onSubmit"
  });

  useEffect(() => {
    store.dispatch(desabilitarEmpresa(true));
    store.dispatch(desabilitarCliente(true));
    store.dispatch(desabilitarUnidade(true));

    return () => {
      store.dispatch(desabilitarCliente(false));
      store.dispatch(desabilitarUnidade(false));
      store.dispatch(desabilitarUnidade(false));
    };
  }, [desabilitarEmpresa, desabilitarCliente, desabilitarUnidade]);

  // Situacao
  const [situacao, setSituacao] = useState(true);
  const [areaFuncionalId, setAreaFuncionalId] = useState();
  const [cupom, setCupom] = useState();
  const [cargoId, setCargoId] = useState();
  const [listaCargo, setListaCargo] = useState([]);
  const [carregandoCargo, setCarregandoCargo] = useState(false);
  const [listaAreaFuncional, setListaAreaFuncional] = useState([]);
  const [carregandoAreaFuncional, setCarregandoAreaFuncional] = useState(false);
  const [exibirQrCode, setExibirQrCode] = useState(false);
  const [checkedIndicador, setCheckedIndicador] = useState(false);

  const onChangeSituacao = (valor) => {
    setSituacao(valor);
  };

  const onChangeCargo = (cargo) => {
    setCargoId(cargo.target.value);
  };

  const onChangeAreaFuncional = (areaFuncional) => {
    setAreaFuncionalId(areaFuncional.target.value);
  };

  const copiarUrlCupom = () => {
    if (!cupom) return;
    const textField = document.createElement("textarea");
    textField.innerText = `https://sualuz.com.br/simulacao?utm_cupom=${cupom}`;
    document.body.appendChild(textField);
    textField.select();
    if (navigator?.clipboard) {
      navigator.clipboard.writeText(
        `https://sualuz.com.br/simulacao?utm_cupom=${cupom}`
      );
    } else {
      document.execCommand("copy");
    }

    textField.remove();

    store.dispatch(
      alertaExibir({
        tipo: "success",
        mensagem: "Link do Cupom copiado com sucesso!"
      })
    );
  };

  const onImageDownload = () => {
    const svg = document.getElementById("QRCode");
    if (svg && cupom) {
      const svgData = new XMLSerializer().serializeToString(svg);
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      const img = new Image();
      img.onload = () => {
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0);
        const pngFile = canvas.toDataURL("image/png");
        const downloadLink = document.createElement("a");
        downloadLink.download = dadosColaborador?.nomeColaborador
          ? `${dadosColaborador?.nomeColaborador}-${cupom}`
          : cupom;
        downloadLink.href = `${pngFile}`;
        downloadLink.click();
      };
      img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
    }
  };

  const obterCargo = async () => {
    try {
      setCarregandoCargo(true);
      const lista = await listarCargo();
      if (lista?.status === 200 && lista?.data?.cargoColaboradorResponseList) {
        setListaCargo(lista?.data?.cargoColaboradorResponseList);
      }
      setCarregandoCargo(false);
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
      setCarregandoCargo(false);
    }
  };

  useEffect(() => {
    obterCargo();
  }, []);

  const obterAreaFuncional = async () => {
    try {
      setCarregandoAreaFuncional(true);
      const lista = await listarAreaFuncional();
      if (lista?.status === 200 && lista?.data?.areaFuncionalResponseList) {
        setListaAreaFuncional(lista?.data?.areaFuncionalResponseList);
      }
      setCarregandoAreaFuncional(false);
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
      setCarregandoAreaFuncional(false);
    }
  };

  useEffect(() => {
    obterAreaFuncional();
  }, []);

  // Carregar Colaborador
  const [carregandoColaborador, setCarregandoColaborador] = useState(false);
  const [dadosColaborador, setDadosColaborador] = useState();

  const [
    arvoresGerenciasSelecionadas,
    setArvoresGerenciaisSelecionadas
  ] = useState([]);

  const enviarFormulario = async (dados) => {
    try {
      const listaArvoreGerencial = [];
      if (arvoresGerenciasSelecionadas?.length > 0) {
        arvoresGerenciasSelecionadas.forEach((item) => {
          listaArvoreGerencial.push({
            EmpresaGrupoDeltaId: item.idEmpresa,
            ArvoreGerencialId: item.idArvore
          });
        });

        const salvou = await salvarColaborador(
          id ?? 0,
          validacaoTrimObject({
            ...dados,
            CargoId: cargoId,
            AreaFuncionalId: areaFuncionalId,
            Situacao: situacao === true ? "AT" : "IN",
            ArvoreGerencialEmpresa: listaArvoreGerencial,
            Cupom: cupom,
            permiteIndicarCpfCnpj: checkedIndicador ? "S" : "N"
          })
        );
        if (salvou?.status === 200 || salvou?.status === 204) {
          store.dispatch(
            alertaExibir({
              tipo: "success",
              mensagem: "Colaborador salvo com sucesso!"
            })
          );
          setTimeout(() => {
            history.push({
              pathname: RotasDTO.Colaboradores,
              state: { colaborador: salvou?.data }
            });
          }, 2000);
        }
      } else {
        store.dispatch(
          alertaExibir({
            tipo: "warning",
            mensagem:
              "Não foi possível cadastrar o colaborador, associar à uma árvore"
          })
        );
      }
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
    }
    setCarregandoColaborador(false);
  };

  const aoEnviarFormulario = (dados) => {
    setCarregandoColaborador(true);
    enviarFormulario(dados);
  };

  const obterColaborador = useCallback(async (idColaborador) => {
    try {
      const listaArvoreGerencial = [];
      const colaborador = await buscarPorId(idColaborador);
      if (colaborador?.status === 200 && colaborador?.data) {
        setDadosColaborador(colaborador?.data);
        setCargoId(colaborador?.data?.cargoId);
        setAreaFuncionalId(colaborador?.data?.areaFuncionalId);
        setCupom(colaborador?.data?.cupom);
        setCheckedIndicador(colaborador?.data?.permiteIndicarCpfCnpj === "S");

        if (colaborador?.data?.arvoreGerencialEmpresa?.length) {
          colaborador.data.arvoreGerencialEmpresa.forEach((item) => {
            listaArvoreGerencial.push({
              idArvore: item.arvoreGerencialId,
              idEmpresa: item.empresaGrupoDeltaId
            });
          });
          setArvoresGerenciaisSelecionadas(listaArvoreGerencial);
        }
        if (colaborador?.data?.situacao === "AT") setSituacao(true);
        else setSituacao(false);
      }
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
    }
  }, []);

  useEffect(() => {
    if (id) obterColaborador(id);
  }, [id, obterColaborador]);

  const [empresasSelecionadas, setEmpresasSelecionadas] = useState([]);

  const onClickSelecionar = (node, itens) => {
    if (node) {
      const listaNova = [...arvoresGerenciasSelecionadas];

      if (node?.nomeFantasia) {
        // É empresa!
        const listaEmpresas = [...empresasSelecionadas];
        const estaNaLista = listaEmpresas.indexOf(node.id);

        if (estaNaLista === -1) {
          listaEmpresas.push(node.id);

          node.arvoreGerencialColecao.forEach((arvore) => {
            const arvoreExiste = listaNova.find(
              (item) =>
                item.idArvore === arvore.id && item.idEmpresa === node.id
            );
            if (!arvoreExiste) {
              listaNova.push({ idArvore: arvore.id, idEmpresa: node.id });

              arvore.arvoreGerencialColecao.forEach((arvoreFilho) => {
                const arvoreFilhoExiste = listaNova.find(
                  (item) =>
                    item.idArvore === arvoreFilho.id &&
                    item.idEmpresa === node.id
                );
                if (!arvoreFilhoExiste) {
                  listaNova.push({
                    idArvore: arvoreFilho.id,
                    idEmpresa: node.id
                  });
                  arvoreFilho.arvoreGerencialColecao.forEach((arvoreNeto) => {
                    const arvoreNetoExiste = listaNova.find(
                      (item) =>
                        item.idArvore === arvoreNeto.id &&
                        item.idEmpresa === node.id
                    );
                    if (!arvoreNetoExiste) {
                      listaNova.push({
                        idArvore: arvoreNeto.id,
                        idEmpresa: node.id
                      });
                      arvoreNeto.arvoreGerencialColecao.forEach(
                        (arvoreBisneto) => {
                          const arvoreBisnetoExiste = listaNova.find(
                            (item) =>
                              item.idArvore === arvoreBisneto.id &&
                              item.idEmpresa === node.id
                          );
                          if (!arvoreBisnetoExiste) {
                            listaNova.push({
                              idArvore: arvoreBisneto.id,
                              idEmpresa: node.id
                            });
                          }

                          arvoreBisneto.arvoreGerencialColecao.forEach(
                            (arvoreTataraneto) => {
                              const arvoreTataranetoExiste = listaNova.find(
                                (item) =>
                                  item.idArvore === arvoreTataraneto.id &&
                                  item.idEmpresa === itens.id
                              );

                              if (!arvoreTataranetoExiste) {
                                listaNova.push({
                                  idArvore: arvoreTataraneto.id,
                                  idEmpresa: itens.id
                                });
                              }
                            }
                          );
                        }
                      );
                    }
                  });
                }
              });
            } else {
              // Remove seleção filh
              const listaAux = [];
              listaNova.forEach((item) => {
                if (item.idEmpresa !== node.id) {
                  listaAux.push(item);
                }
              });
              listaNova.length = 0;
              listaAux.forEach((item) => {
                listaNova.push(item);
              });
            }
          });
        } else {
          listaEmpresas.splice(estaNaLista, 1);

          const listaAux = [];
          listaNova.forEach((item) => {
            if (item.idEmpresa !== node.id) {
              listaAux.push(item);
            }
          });
          listaNova.length = 0;
          listaAux.forEach((item) => {
            listaNova.push(item);
          });

          setArvoresGerenciaisSelecionadas(listaNova);
        }

        setEmpresasSelecionadas(listaEmpresas);
      } else {
        const existe = listaNova.find(
          (item) => item.idArvore === node.id && item.idEmpresa === itens.id
        );

        if (!existe) {
          listaNova.push({ idArvore: node.id, idEmpresa: itens.id });

          node.arvoreGerencialColecao.forEach((arvoreFilho) => {
            const arvoreFilhoExiste = listaNova.find(
              (item) =>
                item.idArvore === arvoreFilho.id && item.idEmpresa === itens.id
            );

            if (!arvoreFilhoExiste) {
              listaNova.push({
                idArvore: arvoreFilho.id,
                idEmpresa: itens.id
              });

              arvoreFilho.arvoreGerencialColecao.forEach((arvoreNeto) => {
                const arvoreNetoExiste = listaNova.find(
                  (item) =>
                    item.idArvore === arvoreNeto.id &&
                    item.idEmpresa === itens.id
                );

                if (!arvoreNetoExiste) {
                  listaNova.push({
                    idArvore: arvoreNeto.id,
                    idEmpresa: itens.id
                  });

                  arvoreNeto.arvoreGerencialColecao.forEach((arvoreBisneto) => {
                    const arvoreBisnetoExiste = listaNova.find(
                      (item) =>
                        item.idArvore === arvoreBisneto.id &&
                        item.idEmpresa === itens.id
                    );

                    if (!arvoreBisnetoExiste) {
                      listaNova.push({
                        idArvore: arvoreBisneto.id,
                        idEmpresa: itens.id
                      });
                    }

                    arvoreBisneto.arvoreGerencialColecao.forEach(
                      (arvoreTataraneto) => {
                        const arvoreTataranetoExiste = listaNova.find(
                          (item) =>
                            item.idArvore === arvoreTataraneto.id &&
                            item.idEmpresa === itens.id
                        );

                        if (!arvoreTataranetoExiste) {
                          listaNova.push({
                            idArvore: arvoreTataraneto.id,
                            idEmpresa: itens.id
                          });
                        }
                      }
                    );
                  });
                }
              });
            }
          });
        } else if (node?.arvoreGerencialColecao?.length) {
          //Percorre os filhos
          node.arvoreGerencialColecao.forEach((filho) => {
            if (filho.arvoreGerencialColecao?.length) {
              //Percorre os netos
              filho.arvoreGerencialColecao.forEach((neto) => {
                neto.arvoreGerencialColecao.forEach((bisneto) => {
                  bisneto.arvoreGerencialColecao.forEach((tataraneto) => {
                    // Desmarca os Tataranetos
                    if (tataraneto.arvoreGerencialVinculadaId === bisneto.id) {
                      const tataranetoParaDesmarcar = listaNova.find(
                        (itemTataraneto) =>
                          itemTataraneto.idArvore === tataraneto.id &&
                          itemTataraneto.idEmpresa === itens.id
                      );

                      if (tataranetoParaDesmarcar != null) {
                        const indexTataranetoParaDesmarcar = listaNova.indexOf(
                          tataranetoParaDesmarcar
                        );
                        listaNova.splice(indexTataranetoParaDesmarcar, 1);
                      }
                    }
                  });
                  // Desmarca os bisnetos
                  if (bisneto.arvoreGerencialVinculadaId === neto.id) {
                    const bisnetoParaDesmarcar = listaNova.find(
                      (itemBisneto) =>
                        itemBisneto.idArvore === bisneto.id &&
                        itemBisneto.idEmpresa === itens.id
                    );

                    if (bisnetoParaDesmarcar != null) {
                      const indexBisnetoParaDesmarcar = listaNova.indexOf(
                        bisnetoParaDesmarcar
                      );
                      listaNova.splice(indexBisnetoParaDesmarcar, 1);
                    }
                  }
                });

                // Desmarca os netos
                if (neto.arvoreGerencialVinculadaId === filho.id) {
                  const netoParaDesmarcar = listaNova.find(
                    (itemNeto) =>
                      itemNeto.idArvore === neto.id &&
                      itemNeto.idEmpresa === itens.id
                  );

                  if (netoParaDesmarcar != null) {
                    const indexNetoParaDesmarcar = listaNova.indexOf(
                      netoParaDesmarcar
                    );
                    listaNova.splice(indexNetoParaDesmarcar, 1);
                  }
                }
              });
            }

            //Desmarca os filhos
            if (filho.arvoreGerencialVinculadaId === existe.idArvore) {
              const filhoParaDesmarcar = listaNova.find(
                (itemFilho) =>
                  itemFilho.idArvore === filho.id &&
                  itemFilho.idEmpresa === itens.id
              );

              if (filhoParaDesmarcar != null) {
                const indexFilhoParaDesmarcar = listaNova.indexOf(
                  filhoParaDesmarcar
                );
                listaNova.splice(indexFilhoParaDesmarcar, 1);
              }
            }
          });

          //Desmarca o pai
          listaNova.splice(listaNova.indexOf(existe), 1);
        } else {
          listaNova.splice(listaNova.indexOf(existe), 1);
        }
      }

      setArvoresGerenciaisSelecionadas(listaNova);
    }
  };

  const textoBusca = useWatch({
    control,
    name: "textoBusca",
    defaultValue: ""
  });

  return (
    <>
      <form
        className="needs-validation"
        onSubmit={handleSubmit(aoEnviarFormulario)}
      >
        <Card className={classes.cardCadastro}>
          <Grid container p={2} spacing={2}>
            <Grid item xs={6} className="font-weight-bold">
              {dadosColaborador?.id ? "Editar colaborador" : "Novo colaborador"}
            </Grid>
            <Grid item xs={6}>
              <BotaoRetornarListagem
                urlListagem={{
                  pathname: RotasDTO.Colaboradores,
                  state: { colaborador: dadosColaborador }
                }}
              />
            </Grid>
            <Grid item lg={3} md={4} sm={6} xs={12}>
              <MaterialInputTexto
                type="text"
                id="NomeColaborador"
                name="NomeColaborador"
                label="Nome"
                renderIconShowHide={false}
                defaultValue={dadosColaborador?.nomeColaborador}
                ref={register({
                  required: "Campo Nome Colaborador é obrigatório!",
                  maxLength: {
                    value: 100,
                    message: "Quantidade máxima de 100 caracteres!"
                  }
                })}
                errors={errors}
              />
            </Grid>
            <Grid item lg={3} md={4} sm={6} xs={12}>
              <Loader loading={carregandoCargo}>
                <SelectArredondado
                  id="CargoId"
                  name="CargoId"
                  valueKey="id"
                  valueName="descricao"
                  dataSource={listaCargo}
                  label="Cargo"
                  value={cargoId}
                  onChange={(cargo) => onChangeCargo(cargo)}
                  placeholder="Cargo"
                  allowClear
                  ref={register(
                    { name: "CargoId" },
                    {
                      required: !cargoId ? "Campo Cargo é obrigatório!" : false
                    }
                  )}
                  errors={errors}
                />
              </Loader>
            </Grid>
            <Grid item lg={3} md={4} sm={6} xs={12}>
              <Loader loading={carregandoAreaFuncional}>
                <SelectArredondado
                  id="AreaFuncionalId"
                  name="AreaFuncionalId"
                  valueKey="id"
                  valueName="descricao"
                  dataSource={listaAreaFuncional}
                  label="Área Funcional"
                  value={areaFuncionalId}
                  onChange={(areaFuncional) =>
                    onChangeAreaFuncional(areaFuncional)
                  }
                  placeholder="Área Funcional"
                  allowClear
                  ref={register(
                    { name: "AreaFuncionalId" },
                    {
                      required: !areaFuncionalId
                        ? "Campo Area Funcional é obrigatório!"
                        : false
                    }
                  )}
                  errors={errors}
                />
              </Loader>
            </Grid>
            <Grid item lg={3} md={6} sm={6} xs={12}>
              <MaterialInputTexto
                type="text"
                id="Cupom"
                name="Cupom"
                label="Cupom"
                renderIconShowHide={false}
                defaultValue={dadosColaborador?.cupom}
                onBlur={(valor) => setCupom(valor.target.value)}
              />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <MaterialInputTexto
                type="text"
                id="urlCupom"
                name="urlCupom"
                label="URL"
                renderIconShowHide={false}
                defaultValue={
                  cupom
                    ? `https://sualuz.com.br/simulacao?utm_cupom=${cupom}`
                    : ""
                }
                disabled
              />
            </Grid>

            <Grid item lg={2} md={5} sm={5} xs={8} mt={1}>
              <CheckInputs
                id="permiteIndicarCpfCnpj"
                name="permiteIndicarCpfCnpj"
                setChecked={setCheckedIndicador}
                checked={checkedIndicador}
                label="Permitir Indicar CPF ou CNPJ"
              />
            </Grid>

            {dadosColaborador?.id && (
              <Grid item lg={2} md={3} sm={3} xs={4} mt={1}>
                <MaterialSwitch
                  labelPlacement="right"
                  label="Ativo"
                  id="Ativo"
                  name="Ativo"
                  checked={situacao}
                  onChange={(valor) => onChangeSituacao(valor)}
                  className={classes.switch}
                />
              </Grid>
            )}

            <Grid item lg={1} md={2} sm={2} xs={4} mt={1}>
              <Box display="flex" justifyContent="space-around">
                <Box>
                  {/* eslint-disable-next-line */}
                  <button
                    type="button"
                    onClick={() => copiarUrlCupom()}
                    className={classes.containerIcone}
                  >
                    <FileCopyRounded />
                  </button>
                </Box>
                <Box ml={{ xs: 2 }}>
                  {/* eslint-disable-next-line */}
                  <button
                    type="button"
                    onClick={() => {
                      if (cupom) {
                        setExibirQrCode(true);
                        onImageDownload();
                      }
                    }}
                    className={classes.containerIcone}
                  >
                    <ImQrcode />
                  </button>
                </Box>
              </Box>
            </Grid>

            <QRCode
              id="QRCode"
              style={{ display: "none" }}
              value={
                cupom
                  ? `https://sualuz.com.br/simulacao?utm_cupom=${cupom}`
                  : ""
              }
            />
            {cupom && (
              <Modal
                aria-labelledby="tituloModalConfirmacao"
                aria-describedby="descricaoModalConfirmacao"
                open={exibirQrCode}
                onClose={() => setExibirQrCode(false)}
                className={classes.containerModal}
              >
                <div className={classes.modalItem}>
                  <Close
                    className={classes.closeIcon}
                    onClick={() => {
                      setExibirQrCode(false);
                    }}
                  />
                  <QRCode
                    size={256}
                    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                    value={
                      cupom
                        ? `https://sualuz.com.br/simulacao?utm_cupom=${cupom}`
                        : ""
                    }
                    viewBox={`0 0 256 256`}
                  />
                </div>
              </Modal>
            )}
          </Grid>

          <Grid container p={2} className={classes.containerSalvar}>
            <Grid item lg={3} md={4} sm={6} xs={7}>
              <Loader loading={carregandoColaborador}>
                <Botao
                  type="submit"
                  label="Salvar"
                  className={classes.button}
                />
              </Loader>
            </Grid>
          </Grid>
        </Card>
        <Grid
          container
          spacing={0}
          className={classes.containerLegendas}
          alignItems="center"
        >
          <Grid item lg={12} className="d-flex justify-content-end">
            <ul className={classes.filter}>
              <li>
                <div className="icon" />
                <div className="texto">Grupo</div>
              </li>
              <li>
                <div className="icon" />
                <div className="texto">Árvore Principal</div>
              </li>
              <li>
                <div className="icon" />
                <div className="texto">Árvore Nível 1</div>
              </li>
              <li>
                <div className="icon" />
                <div className="texto">Árvore Nível 2</div>
              </li>
              <li>
                <div className="icon" />
                <div className="texto">Árvore Nível 3</div>
              </li>
              <li>
                <div className="icon" />
                <div className="texto">Árvore Nível 4</div>
              </li>
            </ul>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={0}
          className={classes.container}
          alignItems="center"
        >
          <ArvoresGerenciasEmpresas
            empresasSelecionadas={empresasSelecionadas}
            selecionados={arvoresGerenciasSelecionadas}
            onSelecionar={(node, lista) => onClickSelecionar(node, lista)}
            onBuscar={textoBusca ?? ""}
          />
        </Grid>
      </form>
    </>
  );
};

export default ColaboradorCadastro;
