import { makeStyles } from "@mui/styles";
import withStyles from "@mui/styles/withStyles";
import Tooltip from "@mui/material/Tooltip";
import themes from "themes";
import { Base } from "../../../componentes/cores";
import _theme from "themes";
import { createTheme } from "@mui/material/styles";

const theme = createTheme();

export const useStyles = makeStyles(() => ({
  titulo: {
    fontWeight: "bold",
    width: "50%"
  },
  container: {
    "& .MuiInputBase-root": {
      color: themes.color.labelColorGrafico
    },
    "& .MuiInputBase-input": {
      maxWidth: "85px"
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "0 none"
    },
    "& .MuiButtonBase-root": {
      color: themes.color.labelColorGrafico
    },
    "& div": {
      color: Base.red
    }
  },
  consumoValor: {
    color: themes.color.labelColorGrafico,
    fontFamily: "LucidaGrande",
    fontSize: "20px",
    fontWeight: "bold",
    textAlign: "right",
    [theme.breakpoints.down("lg")]: {
      fontSize: "16px"
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "18px"
    }
  },
  consumoLabel: {
    color: themes.color.labelColorGrafico,
    fontFamily: themes.font.family,
    fontSize: "12px",
    fontWeight: "normal",
    letterSpacing: "-0.07px",
    textAlign: "right"
  },
  containerTituloSuperior: {
    marginRight: "-23px"
  },
  containerGraficoSuperior: {
    padding: "0 20px 0 25px"
  },
  containerInferior: {
    background: themes.color.consumoAtualInferior,
    backgroundColor: themes.color.consumoAtualInferior,
    borderBottomLeftRadius: "4px",
    borderBottomRightRadius: "4px",
    padding: "0 0 0 1rem",
    textAlign: "center"
  },
  containerSuperior: {
    padding: "0 0 0 1em",
    textAlign: "center"
  },
  noData: {
    minHeight: "270px",
    width: "100%",
    textAlign: "center"
  },
  tooltip: {
    width: "100%",
    textAlign: "center",
    alignItems: "center",
    padding: "8px",
    borderImageSlice: 1,
    backgroundImage:
      "linear-gradient(to bottom, #647480, #647480), linear-gradient(to bottom, #374148, #647480 87%)",
    borderImageSource: "linear-gradient(to bottom, #374148, #647480 87%)"
  },
  iconInfo: {
    marginLeft: "1%"
  },
  contratoMaximo: {
    position: "absolute",
    marginTop: 5,
    fontSize: 14,
    borderLeftStyle: "solid",
    borderLeftWidth: 10,
    borderLeftColor: "#969696",
    paddingLeft: 10,
    marginLeft: 15,
    color: "black"
  }
}));

export const HtmlTooltip = withStyles(() => ({
  tooltip: {
    fontSize: "14px",
    width: "100%",
    textAlign: "center",
    alignItems: "center",
    padding: "8px",
    borderImageSlice: 1,
    backgroundImage:
      "linear-gradient(to bottom, #647480, #647480), linear-gradient(to bottom, #374148, #647480 87%)",
    borderImageSource: "linear-gradient(to bottom, #374148, #647480 87%)",
    borderRadius: "5px"
  }
}))(Tooltip);
