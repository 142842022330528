import styled from "styled-components";
import { makeStyles } from '@mui/styles';
import theme from "themes";
import { Base } from "../../../componentes/cores";

export const useStyles = makeStyles(() => ({
  adicionar: {
    borderColor: theme.color.primary
  },
  container: {
    width: "100%",
    margin: 0,
    paddingBottom: "15px",
    "& fieldset": {
      borderColor: `${theme.color.selectArredondadoColor} !important`
    },
    "& label, & button, & input": {
      color: `${theme.color.selectArredondadoColor} !important`
    }
  }
}));

export const BotaoFiltro = styled.button`
  background-color: ${theme.color.primary};
  border-color: ${theme.color.primary};
  color: ${Base.White};
  outline: none;
  width: 100%;
  min-height: 30px;
`;
