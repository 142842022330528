import MICROSERVICO from "../global/enumeradores/microServico";
import obterApi from "./api";

const api = () => obterApi(MICROSERVICO.MANAGEMENT);

export const obterPorSigla = (sigla) => {
  return api().get(`v1/PerfilAgenteCCEE/sigla/${sigla}`);
};

export const obterPorId = (id) => {
  return api().get(`v1/PerfilAgenteCCEE/${id}`);
};

export const salvarPerfilAgente = async (perfilAgenteDto) => {
  if (!perfilAgenteDto.DataFimVigenciaPefilParticipante.getDate())
    perfilAgenteDto.DataFimVigenciaPefilParticipante = new Date(2090, 11, 31);

  if(perfilAgenteDto.Id == 0)
    return api().post(`v1/PerfilAgenteCCEE/Agente/${perfilAgenteDto.IdAgenteCCEE}`,perfilAgenteDto);
  else
    return api().put(`v1/PerfilAgenteCCEE/${perfilAgenteDto.Id}`,perfilAgenteDto);
    
};

export const executarIntegracaoPerfilCCEE = async () => {
  try {
    const dados = {
      EmpresaId: 1,
      CodigoPerfilPrincipalAgenteRequisitante: 115,
      CodigoAgente: 115
    };

    return await api().post(
      `v1/PerfilAgenteCCEE/PostPerfilAgenteCCEE?${new URLSearchParams(
        dados
      ).toString()}`
    );
  } catch (error) {
    return null;
  }
};
