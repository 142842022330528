import React, { useState, useMemo, useEffect } from "react";
import { Box, Button, Grid } from "@mui/material";
import DataTable from "react-data-table-component";
import { Delete } from "@mui/icons-material";
import FiltrosSerasa from "../Componentes/Filtros";
import Colunas from "./Colunas";
import { customStyles, useStyles } from "./style";
import { Botao, SelectArredondado } from "../../../componentes";
import TabelaSemDados from "../Componentes/TabelaSemDados";
import ScoreSerasaACLHelper from "./helper";

const ScoreSerasaACL = () => {
  const classes = useStyles();
  const [ultimaAtualizacao, setUltimaAtualizacao] = useState(null);
  const [inativarAdd, setInativarAdd] = useState(false);
  const [serasaAclEnviar, setSearsaAclEnviar] = useState({
    id: 0,
    limitePrinadML: 0,
    scoreDe: 0,
    scorePara: 0,
    status: "AT"
  });

  const [dadosSerasaAcl, setDadosSerasaAcl] = useState([]);
  const [dadosSerasaPrinad, setDadosSerasaPrinad] = useState([]);
  const [idSelecionado, setIdSelecionado] = useState(null);
  const [carregandoSerasa, setCarregandoSerasa] = useState(false);

  const handleDadosPrinad = (dados) => {
    return dados?.map((item) => ({
      id: item.id,
      prinadPorcentagem: item.prinadPorcentagem,
      scoreDe: item.scoreDe,
      scorePara: item.scorePara
    }));
  };

  const obterTabelaPrinad = async () => {
    const {
      data,
      sucesso,
      mensagem
    } = await ScoreSerasaACLHelper.obterTabelaPrinad(setCarregandoSerasa);

    if (!sucesso) {
      ScoreSerasaACLHelper.exibirErro(mensagem);
      setCarregandoSerasa(false);
      return;
    }

    const dadosPrinadFormatado = handleDadosPrinad(data || []);
    setDadosSerasaPrinad(dadosPrinadFormatado);
    setInativarAdd(false);
    setIdSelecionado(null);
  };

  const handleDadosProbabilidade = (dados) => {
    return dados?.map((item) => ({
      id: item.id,
      limitePrinadML: item.limitePrinadML,
      scoreDe: item.scoreDe,
      scorePara: item.scorePara,
      status: item.status
    }));
  };

  const obterProbilidadeInadimplências = async () => {
    const {
      data,
      sucesso,
      mensagem
    } = await ScoreSerasaACLHelper.obterProbilidadeInadimplências(
      setCarregandoSerasa
    );

    if (!sucesso) {
      ScoreSerasaACLHelper.exibirErro(mensagem);
      setCarregandoSerasa(false);
      return;
    }
    setUltimaAtualizacao(data?.limites?.[0]?.dataAtualizacao);

    const dadosListagemAcl = data?.limites?.filter(
      (item) => item.limitePrinadML !== null
    );

    const dadosProbabilidadeFormatado = handleDadosProbabilidade(
      dadosListagemAcl || []
    );

    setDadosSerasaAcl(dadosProbabilidadeFormatado);
    obterTabelaPrinad();
  };

  useEffect(() => {
    obterProbilidadeInadimplências();
  }, []);

  const adicionarLinha = () => {
    setInativarAdd(true);
    const linha = {
      id: 0,
      limitePrinadML: null,
      scoreDe: null,
      scorePara: null,
      status: "AT"
    };

    setSearsaAclEnviar({
      id: 0,
      limitePrinadML: null,
      scoreDe: null,
      scorePara: null
    });

    setDadosSerasaAcl((prevState) => [linha, ...prevState]);
  };

  const handleChange = (e, id) => {
    setIdSelecionado(Number(e?.target?.value));

    setSearsaAclEnviar({
      ...serasaAclEnviar,
      [e?.target?.name]: e?.target?.value
    });
  };

  const removerLinha = () => {
    setInativarAdd(false);
    setIdSelecionado(null);
    const linha = dadosSerasaAcl?.filter((item) => item.id !== 0);
    setDadosSerasaAcl(linha);

    setSearsaAclEnviar({
      id: 0,
      limitePrinadML: null,
      scoreDe: null,
      scorePara: null
    });
  };

  const dataFormatada = useMemo(() => {
    const dadosFormatados =
      dadosSerasaAcl &&
      dadosSerasaAcl.map((item) => {
        return {
          scoreDe:
            item.id !== 0 ? (
              <SelectArredondado
                id={item.id}
                name="scoreDe"
                valueKey="id"
                valueName="scoreDe"
                dataSource={dadosSerasaAcl}
                label=""
                value={item?.id}
                disabled={item.id}
                inputProps={{ "aria-label": "controlled" }}
                className={classes.formControl}
              />
            ) : (
              <SelectArredondado
                id={item.id}
                name="scoreDe"
                valueKey="id"
                valueName="scoreDe"
                dataSource={dadosSerasaPrinad}
                label=""
                value={idSelecionado}
                onChange={(event) => handleChange(event, item?.id)}
                disabled={item.id}
                inputProps={{ "aria-label": "controlled" }}
                className={classes.formControl}
              />
            ),
          scorePara:
            item.id !== 0 ? (
              <SelectArredondado
                id={item.id}
                name="scorePara"
                valueKey="id"
                valueName="scorePara"
                dataSource={dadosSerasaAcl}
                label=""
                value={item?.id}
                disabled={item.id}
                inputProps={{ "aria-label": "controlled" }}
                className={classes.formControl}
              />
            ) : (
              <SelectArredondado
                id={item.id}
                name="scorePara"
                valueKey="id"
                valueName="scorePara"
                dataSource={dadosSerasaPrinad}
                label=""
                value={idSelecionado}
                onChange={(event) => handleChange(event, item?.id)}
                inputProps={{ "aria-label": "controlled" }}
                className={classes.formControl}
              />
            ),
          limitePrinadML:
            item.id !== 0 ? (
              <SelectArredondado
                id={item.id}
                name="limitePrinadML"
                valueKey="id"
                valueName="limitePrinadML"
                dataSource={dadosSerasaAcl}
                label=""
                value={item.id}
                disabled={item.id}
                inputProps={{ "aria-label": "controlled" }}
                className={classes.formControl}
              />
            ) : (
              <SelectArredondado
                id={item.id}
                name="prinadPorcentagem"
                valueKey="id"
                valueName="prinadPorcentagem"
                dataSource={dadosSerasaPrinad}
                label=""
                value={idSelecionado}
                onChange={(event) => handleChange(event, item?.id)}
                inputProps={{ "aria-label": "controlled" }}
                className={classes.formControl}
              />
            ),
          status: (
            <Box sx={{ opacity: `${item?.id ? "0.6" : ""}` }}>
              {item.status === "AT" ? "Ativo" : "Inativo"}
            </Box>
          ),
          acoes: (
            <Box textAlign="center" key={item.id}>
              <Button
                name={item?.id}
                id={item?.id}
                className={`${classes.botao} ${classes.ativo}`}
                disabled={item.id}
              >
                <Delete
                  onClick={() => removerLinha(item)}
                  htmlColor={item.id ? "" : "#332053"}
                />
              </Button>
            </Box>
          )
        };
      });

    return dadosFormatados;
  }, [dadosSerasaAcl, dadosSerasaPrinad, serasaAclEnviar, idSelecionado]);

  const inserirLimiteProbalidadeInadimplencias = async (dados) => {
    const model = {
      limitePrinadML: dados?.prinadPorcentagem,
      scoreDe: dados.scoreDe,
      scorePara: dados.scorePara
    };

    const {
      data,
      sucesso,
      mensagem
    } = await ScoreSerasaACLHelper.inserirLimiteProbalidadeInadimplencias(
      model,
      setCarregandoSerasa
    );

    if (!sucesso) {
      ScoreSerasaACLHelper.exibirErro(mensagem);
      setCarregandoSerasa(false);
      return;
    }

    obterProbilidadeInadimplências();
  };

  const inserirProbalidadeInadimplencias = async (dados) => {
    const model = {
      limitePrinadML: dados?.prinadPorcentagem,
      scoreDe: dados.scoreDe,
      scorePara: dados.scorePara
    };

    const {
      data,
      sucesso,
      mensagem
    } = await ScoreSerasaACLHelper.inserirProbalidadeInadimplencias(
      model,
      setCarregandoSerasa
    );

    if (!sucesso) {
      ScoreSerasaACLHelper.exibirErro(mensagem);
      setCarregandoSerasa(false);
      return;
    }

    inserirLimiteProbalidadeInadimplencias(dados);
  };

  const salvarValorConta = () => {
    const dadosEnviar = dadosSerasaPrinad.filter(
      (item) => item.id === idSelecionado
    );
    inserirProbalidadeInadimplencias(dadosEnviar[0]);
  };

  return (
    <Grid container>
      <FiltrosSerasa
        adicionarLinha={adicionarLinha}
        label="Inserir Score / Prinad"
        ultimaAtualizacao={
          ultimaAtualizacao
            ? window.moment(ultimaAtualizacao).format("DD/MM/yyyy HH:mm")
            : ""
        }
        inativarAdd={inativarAdd}
      />
      <Grid item xs={12} className="mt-3">
        <DataTable
          columns={Colunas}
          data={dataFormatada}
          theme="DeltaEnergia"
          persistTableHead
          customStyles={customStyles}
          noDataComponent={<TabelaSemDados />}
          noHeader
          responsive
          progressPending={carregandoSerasa}
          dense
        />
      </Grid>
      <Grid container className="mt-3 w-100">
        <Grid item md={3}>
          <Botao
            label="Salvar"
            onClick={() => salvarValorConta()}
            disabled={!inativarAdd}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ScoreSerasaACL;
