import React from "react";
import PropTypes from "prop-types";
import { Box, Grid } from "@mui/material";
import CheckBoxLuz from "../CheckBoxLuz";
import { useStyles } from "./style";

const CardsPlanos = ({
  titulo,
  porcentagem,
  anual,
  rodape,
  nomeCard,
  onChecked,
  onName,
  checked,
  disabled,
  economiaMensal,
  economiaAnual,
  funcionalidade,
  periodoEconomia
}) => {
  const classes = useStyles();

  return (
    <>
      <Box
        bgcolor="#FFFFFF"
        border={`1px solid ${checked ? "#F722B5" : "#D2D2D7"}`}
        boxShadow="0px 2px 4px 1px rgba(0, 0, 0, 0.16)"
        borderRadius="8px"
        position="relative"
        width="100%"
      >
        {funcionalidade === "serasa" && (
          <Box
            component="span"
            fontSize="40px"
            color="#332053"
            position="absolute"
            sx={{
              opacity: "0.3",
              transform: "rotate(-30deg)",
              verticalAlign: "center"
            }}
            textAlign="center"
            width="100%"
            top="50%"
          >
            Crédito Reprovado
          </Box>
        )}
        <Box
          component={Grid}
          container
          bgcolor="#f5f5f7"
          py={2}
          className={classes.headerCard}
        >
          {funcionalidade === "planos" && (
            <>
              <Box component={Grid} pl={2} item xs={5}>
                <CheckBoxLuz
                  onChecked={onChecked}
                  nomeCard={nomeCard}
                  onName={onName}
                  checked={checked}
                  disabled={disabled}
                />
              </Box>
              <Box
                component={Grid}
                item
                xs={7}
                color="#332053"
                fontSize="20px"
                fontWeight="700"
              >
                {titulo}
              </Box>
            </>
          )}

          {funcionalidade === "serasa" && (
            <Box
              component={Grid}
              color="#332053"
              fontSize="20px"
              fontWeight="700"
              xs={12}
              textAlign="center"
            >
              {titulo}
            </Box>
          )}
        </Box>
        <Box padding={2}>
          <Box
            sx={{
              background: "linear-gradient(90deg, #F722B5 0%, #FD9507 100%)"
            }}
            color="#FFFFFF"
            borderRadius="8px"
            py={2}
          >
            <Box textAlign="center">
              <Box fontSize="24px" fontWeight="700">
                {`${porcentagem} de desconto`}
              </Box>
              <Box fontSize="16px" fontWeight="400">
                na fatura de energia, todo mês
              </Box>
            </Box>
          </Box>
          <Box component="div" color="#332053" fontSize="16px" fontWeight="700">
            <Box textAlign="center">
              <Box fontSize="25px" fontWeight="700" pt={2}>
                <Box
                  color="rgba(50, 32, 83, 1)"
                  fontSize="20px"
                  fontWeight="300"
                >
                  Flexibilidade
                </Box>
                Total
              </Box>
              <Box fontSize="25px" fontWeight="700" pt={1}>
                <Box
                  color="rgba(50, 32, 83, 1)"
                  fontSize="20px"
                  fontWeight="300"
                >
                  Economia mensal*
                </Box>
                {economiaMensal}
              </Box>
              <Box fontSize="25px" fontWeight="700" pt={1}>
                <Box
                  color="rgba(50, 32, 83, 1)"
                  fontSize="20px"
                  fontWeight="300"
                >
                  Economia anual*
                </Box>
                {economiaAnual}
              </Box>
              <Box fontSize="15px" fontWeight="700" pt={1}>
                <Box
                  color="rgba(50, 32, 83, 1)"
                  fontSize="20px"
                  fontWeight="300"
                >
                  Suprimento*
                </Box>
                {periodoEconomia}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

CardsPlanos.propTypes = {
  titulo: PropTypes.string,
  porcentagem: PropTypes.string,
  anual: PropTypes.string,
  rodape: PropTypes.string,
  nomeCard: PropTypes.string,
  onChecked: PropTypes.oneOfType([PropTypes.func]),
  onName: PropTypes.oneOfType([PropTypes.func]),
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  economiaMensal: PropTypes.string,
  economiaAnual: PropTypes.string,
  funcionalidade: PropTypes.string,
  periodoEconomia: PropTypes.string
};

CardsPlanos.defaultProps = {
  titulo: "",
  porcentagem: "",
  anual: "",
  rodape: "",
  nomeCard: "",
  onChecked: () => {},
  onName: () => {},
  checked: false,
  disabled: false,
  economiaMensal: "",
  economiaAnual: "",
  funcionalidade: "planos",
  periodoEconomia: ""
};

export default CardsPlanos;
