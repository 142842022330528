export default class ClienteDemoDto {
  constructor(
    cnpjCpf,
    razaoSocial,
    nomeFantasia,
    arvoreGerencialId,
    inscricaoEstadual,
    inscricaoMunicipal,
    status,
    cep,
    endereco,
    numero,
    complemento,
    uf,
    bairro,
    cidade,
    pessoaFisicaJuridica,
    situacao,
    dataSituacao,
    telefone,
    contatoInicial,
    representantes,
    colaboradorId,
    consumidorLivre,
    agenteCCEEId,
    empresaGrupoDeltaId
  ) {
    this.cnpjCpf = cnpjCpf;
    this.razaoSocial = razaoSocial;
    this.nomeFantasia = nomeFantasia;
    this.arvoreGerencialId = arvoreGerencialId;
    this.inscricaoEstadual = inscricaoEstadual;
    this.inscricaoMunicipal = inscricaoMunicipal;
    this.status = status;
    this.cep = cep;
    this.endereco = endereco;
    this.numero = numero;
    this.complemento = complemento;
    this.uf = uf;
    this.bairro = bairro;
    this.cidade = cidade;
    this.pessoaFisicaJuridica = pessoaFisicaJuridica;
    this.situacao = situacao;
    this.dataSituacao = dataSituacao;
    this.telefone = telefone;
    this.contatoInicial = contatoInicial;
    this.representantes = representantes;
    this.colaboradorId = colaboradorId;
    this.consumidorLivre = consumidorLivre;
    this.agenteCCEEId = agenteCCEEId;
    this.empresaGrupoDeltaId = empresaGrupoDeltaId;
  }

  obterContato() {
    if (!this.contatoInicial) return null;
  }

  static receitaFederalParaDto(receita, nomeFantasiaParam) {
    return new ClienteDemoDto(
      receita.cnpj,
      receita.razaoSocial,
      nomeFantasiaParam,
      34,
      81667032000147,
      81667032000147,
      "PP",
      receita.cep,
      receita.logradouro,
      1,
      receita.bairro,
      receita.uf,
      receita.bairro,
      receita.municipio,
      "PJ",
      "AT",
      window.moment().format("YYYY-MM-DD"),
      null,
      null,
      null,
      1,
      false,
      12029,
      1
    );
  }
}
