import MICROSERVICO from "global/enumeradores/microServico";
import obterApi from "servicos/api";

const api = () => obterApi(MICROSERVICO.AREALOGADA);

export const Salvar = async (params) => {
  const edicao = params.Id && params.Id !== 0;

  const url = `v1/tiporelatorio${edicao ? `/atualiza` : "/cria"}`;
  const method = edicao ? "patch" : "post";

  return api()[method](url, params);
};

export const Listar = async (params) => {
  return api().get(`v1/tiporelatorio/lista?${new URLSearchParams(params).toString()}`);
};

export const ObterPorId = async (params) => {
  return api().get(`v1/tiporelatorio/obterporid/${params.id}`);
};

export const MudarStatus = async (params) => {
  // let params = {Id: id, Ativo: status};
  
  return api().patch(`v1/tiporelatorio/atualizastatus`, params);
};