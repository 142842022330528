import React, { useState, useEffect } from "react";

import PropTypes from "prop-types";
import { Modal, Box } from "@mui/material";
import IconeRejeitar from "../../../../../assets/icones/operacoes/rejeitar.png";
import { useStyles, Iframe, Icone } from "./style";

const ModalExibirDocumento = ({ item, onCancelar }) => {
  const classes = useStyles();
  const [aberto, setAberto] = useState(false);

  useEffect(() => {
    setAberto(Boolean(item));
  }, [item]);

  const onClose = () => {
    setAberto(false);
    onCancelar();
  };

  return (
    <Modal open={aberto} onClose={onClose}>
      <Box className={classes.container}>
        <button
          type="button"
          className={classes.button}
          onClick={() => onClose()}
        >
          <Icone className={`${classes.button}`} src={IconeRejeitar} />
        </button>
        <Box className={classes.conteudo}>
          <Iframe
            srcdoc="<div id='mydiv'>hi</div>"
            src="https://sualuz.s3.us-east-1.amazonaws.com/Prod/DocumentosClientes/geral/00729741-cdab-4738-b081-fba0d4b871d1.jpg?response-content-disposition=inline&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEHUaCXNhLWVhc3QtMSJHMEUCIGe7gH6yF%2FzIFoUf%2BPsoCJpQcfpKhIRcs%2FVxtRBBgPKNAiEAu9t9YFDZvvz%2FNyQ69b1bvfvvrGeRXcDRrjuR%2BjGcCTQqiAMIjv%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARAAGgw0NDg2NTYzOTY0MDUiDA8yDaJuKU1IUWeO%2ByrcAupZhxy1e2P%2B4vXdBZ75cJB7dYR0FQs7O4A5D%2BvG23IdMn%2BDvWT3sRv0t931TBdipNfXFmGy5axHFVHJ6EcylixuH2R4pox07ykYNV6kwnPRI%2BHQBg%2BILoUZfiV%2B2dr%2FwWHkbs1qfDSSZ%2BU8wIRUVeXf9gcGgSq%2F6B8vSYco0SCHsA6ijnsdUZFl4UQpyxHqgB1xbyy1loVO0O%2Bmn42MP0iLD4DlimHJjgbC%2BX9YM7h7dU8HzPnzux5NIITYRF6aW3oCut3p0ef1vTYKVExlOnuGHcUHbU%2B0cGnqso7f2a4ValIl09HNn1eDBaZ5dxKaUKtDSvi6pKAP4lv0lTfeeikquBvgHztqMCwPDHsS6l7LIEtgfUSM%2F5r9azeqUVnH2%2FxmepYvq0WXBE9vIKmn0nY%2B7%2F5KdsBWs0XrLHgz8XWs2NVMGf%2FJsNoFziohVc3uLK7wt0H6HwzYwHgIXzCXjrqpBjqzAjtJJZF1bh8HJCQqyjZpKuXsP9BYH6HV2IN7wannk%2Fumbyt4fUTvqRr8vyPF35b2kattUw%2Bv9cHI7a1YgDhhAJDEC56IuOj3a2iPH8%2B2lPsM%2FyMFgflhoswqcxVWJDFOJZ5b8KToWr3I8EW6zZiJt%2FeqpeBSMCCwz%2BsmEuOrPKPnBD59MGWtQ9bdmeTgiSIDHda5ldOkj3OYtVHzK9dgtomMxfAm41jx1xu1cjx%2FwslQ9UmUFHNjmBJ3nlja2jEpxVm7Aj9Z7Up9M67CWsyFl9vzRyKjFHIzkHdV71v1oa9UuCsclDk%2BQsCbaQs4ikr4rtrHQeaEGeieNhZaGDk1Ww5mZTRHO8%2FCFRwJiN8xuNQzCwhfPGHzhGEH%2F%2B%2BPx5apvlASRxOL%2FV98KgDn2SxDrBgTGB0%3D&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231017T140922Z&X-Amz-SignedHeaders=host&X-Amz-Expires=36000&X-Amz-Credential=ASIAWQ5QBKB2TNQHNOGC%2F20231017%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Signature=b342e78f5a6d476876c9ff0a5ef96253cf7b1010fdd735a9cce0e0d125fe0942"
            name="imgbox"
            id="imgbox"
            title="Documento"
          />
        </Box>
      </Box>
    </Modal>
  );
};

ModalExibirDocumento.propTypes = {
  item: PropTypes.oneOfType([PropTypes.any]),
  onCancelar: PropTypes.func
};

ModalExibirDocumento.defaultProps = {
  item: false,
  onCancelar: () => {}
};

export default ModalExibirDocumento;
