import RetornoEndpointDto from "../../../global/dto/retornoEndpointDto";
import { exportarExcel, listarOperacaoControlePaginado } from "../../../servicos/listagemOperacaoControleServico";
import { obterListaReduzidaDistribuidoraGD } from "../../../servicos/distribuidorasServico";
import { alertaExibir } from "../../../global/redux/modulos/alertas/actions";
import { store } from "../../../global/redux";

export default class ListagemOperacoesHelper {
  static async listarOperacaoControlePaginado(filtro, paramsSecundarios) {
    try {
      const resultado = await listarOperacaoControlePaginado(
        filtro,
        paramsSecundarios
      );

      if (resultado.status === 204)
        return RetornoEndpointDto.Sucesso(
          "Lista de Operação Controle não encontrada!"
        );

      return RetornoEndpointDto.Sucesso(
        "Listagem de Operação Controle obtidas com sucesso!",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro(
        error?.response?.data?.message ?? "Ocorreu um erro ao obter a lista de Operação Controle!"
      );
    }
  }

  static async listarDistribuidoraGD() {
    try {
      const resultado = await obterListaReduzidaDistribuidoraGD();

      if (resultado.status === "204") {
        store.dispatch(
          alertaExibir({ tipo: "danger", mensagem: "Nenhuma Distribuidora encontrada!" })
        );

        return false;
      }

      return resultado;
    } catch (error) {
      store.dispatch(
        alertaExibir({ tipo: "danger", mensagem: error?.response?.data?.message ?? "Ocorreu um erro ao obter Lista de Distribuidora!" })
      );
    }

    return false;
  }

  static async exportarExcel() {
    try {
      const resultado = await exportarExcel();

      if (resultado.status === "204") {
        store.dispatch(
          alertaExibir({ tipo: "danger", mensagem: "Nenhum Excel encontrado!" })
        );

        return false;
      }

      store.dispatch(
        alertaExibir({ tipo: "success", mensagem: "Excel enviado por e-mail!" })
      );
      return true;
    } catch (error) {
      store.dispatch(
        alertaExibir({ tipo: "danger", mensagem: error?.response?.data?.message ?? "Ocorreu um erro ao obter Excel!" })
      );
    }

    return false;
  }

  static exibirErro(mensagem) {
    store.dispatch(
      alertaExibir({
        tipo: "danger",
        mensagem
      })
    );
  }

  static exibirSucesso(mensagem) {
    store.dispatch(
      alertaExibir({
        tipo: "success",
        mensagem
      })
    );
  }
}
