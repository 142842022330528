export default class OperacaoDto {
  constructor(
    unidadeNome,
    id,
    unidadesSelecionadas,
    disabled,
    status,
    campoSelecionar
  ) {
    this.unidadeNome = unidadeNome;
    this.id = id;
    this.unidadesSelecionadas = unidadesSelecionadas;
    this.disabled = disabled;
    this.status = status;
    this.campoSelecionar = campoSelecionar;
  }
}
