import styled from "styled-components";
import { createTheme } from "@mui/material/styles";
import { makeStyles } from '@mui/styles';
import theme from "../../../themes";

const th = createTheme();

export const useStyles = makeStyles({
    cardResumo: {
        backgroundColor: theme.color.bgColorCardCadastro
    }
})


export const TituloEconomia = styled.span`
    font-family: 'Plau';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 120%;
    color: #86868B;
    margin-right: 10px;
    margin-top: -4px;
`;


export const ProgressBar = styled.div`
    width: 100%;
    height: 100%;
    border-radius: 0px 24px 24px 0px;
    background: ${(props) => props.backgroundColor};
`;

export const ProgressBarLuz = styled.div`
    background: ${(props) => props.color};
    border-radius: 0px 24px 24px 0px;
    width: ${(props) => props.width};
    padding-top: 5px;
    padding-bottom: 5px;
`;

export const ProgressBarText = styled.span`
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    color: #FFFFFF;
    margin-left: 20px;
    @media (min-width: ${[th.breakpoints.up('xs')]}) {
        font-size: 14px !important;
    }
`;
