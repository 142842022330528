import React from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";

import { useStyles } from "./style";
import "./style.css";

const InputDataLuz = ({ onChange, descricao  }) => {
    const classes = useStyles();
    return (
        <Box component="div" display="flex">
            <Box component="div">{descricao}</Box>
            <input type="date" id="date-luz" className={classes.dataLuz} name="data-luz" onChange={(e) => onChange(e.target.value)} />
        </Box>  
    )    
}

InputDataLuz.propTypes = {
    onChange: PropTypes.oneOfType([PropTypes.func]),
    descricao: PropTypes.string,
}

InputDataLuz.defaultProps = {
    onChange: () => {},
    descricao: "",
}

export default InputDataLuz;


