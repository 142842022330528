import React from "react";
import "date-fns";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import { ThemeProvider } from "@mui/styles";
import StyledEngineProvider from "@mui/material/StyledEngineProvider";
import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";
import prbrLocale from "date-fns/locale/pt-BR";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import { ptBR } from "@mui/x-date-pickers/locales";
import { TextField } from "@mui/material";
import { materialTheme, useStyles } from "./style";

const InputHora = React.forwardRef((props, ref) => {
  const {
    label,
    id,
    name,
    defaultValue,
    errors,
    onChange,
    customValue,
    className,
    disabled,
    InputProps
  } = props;

  const classes = useStyles();

  return (
    <Grid
      container
      className={`${className} ${className.container} ${classes.container}`}
    >
      <LocalizationProvider
        adapterLocale={prbrLocale}
        dateAdapter={AdapterDateFns}
        localeText={
          ptBR.components.MuiLocalizationProvider.defaultProps.localeText
        }
      >
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={materialTheme}>
            <MobileTimePicker
              disabled={disabled}
              id={id}
              name={name}
              ampm={false}
              label={label}
              defaultValue={defaultValue}
              onChange={onChange}
              value={customValue}
              inputRef={ref}
              slotProps={{
                actionBar: { actions: ["cancel", "accept", "clear"] }
              }}
              slots={{
                textField: (params) => (
                  <TextField
                    {...params}
                    name={name}
                    placeholder=" "
                    error={Boolean(errors[name]?.message)}
                    helperText={errors[name]?.message ?? ""}
                    InputProps={{
                      endAdornment: <QueryBuilderIcon />,
                      ...InputProps
                    }}
                  />
                )
              }}
            />
          </ThemeProvider>
        </StyledEngineProvider>
      </LocalizationProvider>
    </Grid>
  );
});

InputHora.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
  errors: PropTypes.oneOfType([PropTypes.object]),
  className: PropTypes.oneOfType([PropTypes.object]),
  onChange: PropTypes.oneOfType([PropTypes.func]),
  customValue: PropTypes.oneOfType([PropTypes.any]),
  disabled: PropTypes.bool,
  InputProps: PropTypes.object
};

InputHora.defaultProps = {
  label: "",
  id: "",
  defaultValue: "",
  errors: {},
  className: {},
  onChange: () => {},
  customValue: "",
  disabled: false,
  InputProps: { readOnly: false }
};

export default InputHora;
