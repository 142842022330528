import styled from "styled-components";
import { makeStyles } from '@mui/styles';
import { Base } from "../../cores";
import theme from'themes';

export const useStyles = makeStyles({
  forcePosition: {
    left: "-108px !important",
    position: "absolute !important"
  },
  squareWidth: {
    height: "54px !important",
    width: "54px !important"
  },
  treeViewItemExpand: {
    color: `${Base.GreyDim} !important`,
    justifyContent: "end !important",
    padding: "0 !important",
    position: "relative !important"
  },
  treeViewBorder: {
    borderLeftColor: (props) => props.color,
    borderLeftWidth: "6px",
    borderLeftStyle: "solid"
  },
  gridItem: {
    flexBasis: "100% !important",
    fontSize: "2.1875rem !important",
    margin: "0 !important",
    maxWidth: "100% !important",
    width: "54px !important"
  },
  treeItem: {
    backgroundColor: `${theme.color.bgItemExpansivel} !important`,
    color: `${theme.color.secondaryText} !important`,
    height: "54px !important"
  },
  wrapper: {
    alignItems: "center !important",
    display: "flex !important",
    height: "100% !important",
    minHeight: "100% !important"
  },
  start: {
    alignItems: "flex-start !important"
  },
  center: {
    justifyContent: "center !important"
  },
  left: {
    justifyContent: "start !important",
    paddingLeft: "20px !important"
  },
  right: {
    justifyContent: "flex-end !important"
  }
});

export const Icon = styled.div`
  border-left-color: ${theme.color.fourthBackgroud};
  border-left-style: solid;
  border-left-width: 6px;
  border-bottom-color: ${theme.color.fourthBackgroud};
  border-bottom-width: 6px;
  border-bottom-style: solid;
  display: block;
  height: 30px;
  width: 30px;
`;
