import React, { useRef, useState, useEffect } from "react";

//import React, { useEffect, useState } from "react";

// Redux
import { Grid, Button,Box } from "@mui/material";
import { Tune, Search } from "@mui/icons-material";
import { Botao } from "componentes";
import Helper from "./helper";


import InputDataWhite from "componentes/inputTextoDataBranco"
import { useStyles } from "./style";
import themes from "../../../../../themes";

const DownloadMetricas = () => {
    const classes = useStyles();
    const [dataInicialPesquisa, setDataInicialPesquisa] = useState(null);
    const [dataFinalPesquisa, setDataFinalPesquisa] = useState(null);

    const onClickDownloadsEVisualizacoes = async () => {

        const dataInicio = dataInicialPesquisa ? window.moment(dataInicialPesquisa).format("YYYY-MM-DD") : window.moment().subtract(6,'months').format("YYYY-MM-DD");
        const dataFim = dataFinalPesquisa ? window.moment(dataFinalPesquisa).format("YYYY-MM-DD") : window.moment().format("YYYY-MM-DD");
        const resultado  = await Helper.ExportarPlanilha( dataInicio, dataFim );

        if (!resultado.sucesso) {
          Helper.exibirErro(resultado.mensagem);
          return;
        }else{
          const blob = new Blob([resultado.data]);
          saveAs(blob,"MétricasRelatório.xlsx");
          Helper.exibirSucesso(resultado.mensagem);
          return;
        }
    };

    return (
    <Grid container p={2} spacing={2} >
      <Grid item xs={12} lg={4}>
        <Botao
            type="submit"
            label="Downloads e Visualizações"
            onClick={onClickDownloadsEVisualizacoes}
            className={classes.button}
        />
        </Grid>
        <Grid item xs={12} lg={4}>
        <InputDataWhite
          styleForm={false}
          type="text"
          id="DataInicial"
          label="Data Inicial"
          format="dd-MM-yyyy"
          customValue={dataInicialPesquisa}
          onChange={(data) => setDataInicialPesquisa(data)}
          minDate={window.moment().subtract(200, "years").toDate()}
          className={{ container: classes.inputData }}
        />
      </Grid>
      <Grid item xs={12} lg={4}>
        <InputDataWhite
          styleForm={false}
          type="text"
          id="DataFinal"
          label="Data Final"
          format="dd-MM-yyyy"
          customValue={dataFinalPesquisa}
          onChange={(data) => setDataFinalPesquisa(data)}
          maxDate={window.moment().add(200, "years").toDate()}
          minDate={window.moment(dataInicialPesquisa).toDate()}
          className={{ container: classes.inputData }}
        />
      </Grid>
    </Grid>
  );
};

export default DownloadMetricas;

