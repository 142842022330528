import React, { useState } from "react";
import PropTypes from "prop-types";

import { Grid, Button } from "@mui/material";
import { Receipt } from "@mui/icons-material";

import { useStyles } from "./style";
import FaturasHelper from "../helper";

import { HtmlTooltip } from "../../../../Graficos/ConsumoAtual/style";
import Loader from "../../../../../componentes/loader";

const ColunaNotaFiscal = ({ parametros }) => {

  const classes = useStyles();
  const [carregandoDownload, setcarregandoDownload] = useState(false);

  const onClickDownloadNotaFiscal = async (params) => {

    setcarregandoDownload(true)
    const { sucesso, mensagem, data } = await FaturasHelper.BaixarNotaFiscal(params);

    if (!sucesso) return FaturasHelper.exibirErro(mensagem);

    setcarregandoDownload(false)
  };

  return (
    <Loader loading={carregandoDownload}>
      <Grid container spacing={0} className={classes.fundoCelula}>
        <Grid item xs={12}>
          <Button
            onClick={() => onClickDownloadNotaFiscal(parametros)}
            className={`text-white ${classes.botao}`}
          >
            <HtmlTooltip title="Baixar">
              <Receipt />
            </HtmlTooltip>
          </Button>
        </Grid>
      </Grid>
    </Loader>
  );
};

ColunaNotaFiscal.propTypes = {
  onClick: PropTypes.oneOfType([PropTypes.func]).isRequired,
  parametros: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
    .isRequired
};

export default ColunaNotaFiscal;
