import React from "react";
import { Radio } from "@mui/material";
import { withStyles } from "@mui/styles";

export const RadioLuz = withStyles({
  root: {
      "&$checked": {
        color: "#E851B1 !important"
      }
  },
  checked: {}
})((props) => <Radio color="primary" {...props} />);
