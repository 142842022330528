import { makeStyles } from "@mui/styles";
import theme from "themes";
import { Base } from "../cores";

export const useStyles = makeStyles(() => ({
  paginacaoContainer: {
    textAlign: "center",
    "& strong": {
      color: `${theme.color.primary} !important`,
    },
    "& nav ul li button": {
      color: `${Base.OffWhite} !important`
    },
    "& nav ul li button:hover": {
      color: `${Base.OffWhite} !important`,
      backgroundColor: `${theme.color.primary} !important`
    },
    "& nav ul li div": {
      color: `${Base.OffWhite} !important`
    },
    "& .MuiPaginationItem-outlinedPrimary": {
      backgroundColor: `${theme.color.bgPaginacao} !important`,
      color: `${theme.color.numeroPagina} !important`,
    },
    "& .MuiPaginationItem-outlinedPrimary.Mui-selected": {
      backgroundColor: `${theme.color.bgPaginacaoAtiva} !important`,
      border: `${theme.color.primary} !important`,
      color: `${Base.OffWhite} !important`
    },
    "& .MuiPaginationItem-outlinedPrimary.Mui-selected:hover": {
      backgroundColor: `${theme.color.bgPaginacaoHover} !important`,
      color: `${theme.color.numeroPaginaHover} !important`
    },
    "& .MuiPaginationItem-page.Mui-selected:hover": {
      backgroundColor: `${theme.color.secondary} !important`,
      color: `${Base.White} !important`
    }
  },
  centralizar: {
    content: "center"
  }
}));
