import React from "react";
import PropTypes from "prop-types";

// Componentes
import Grid from "@mui/material/Grid";
import { Box, Checkbox, Tooltip } from "@mui/material";

// Icones
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import OpenWithOutlinedIcon from "@mui/icons-material/OpenWithOutlined";

// Styles
import { useStyles, Item } from "./style";
import MaterialSwitch from "../../switch";
import TreeViewParentIcon from "../treeViewParentIcon";
import MenuImg from "../../../assets/menu.png";
import getDomain from "../../../global/getDomain";

import { cmainhoFuncionalidadeDefaultEnum } from "../../../global/constantes";

const TreeViewItem = ({
  level,
  childs,
  label,
  icone,
  editable,
  onEditar,
  deletable,
  onDeletar,
  draggable,
  dragHandleProps,
  selectable,
  onSelecionar,
  selected,
  node,
  checked
}) => {
  const domain = getDomain();
  const classes = useStyles({
    level,
    tipo: node?.idTipoFuncionalidade,
    childs,
    ativo: node?.ativo ?? false
  });

  const onClickEditar = (item) => {
    onEditar(item);
  };

  const onClickDeletar = () => {
    onDeletar(node);
  };

  const onClickSelecionar = (item) => {
    onSelecionar(item);
  };

  const handleIcone = (hash) => {
    return `${process.env.REACT_APP_ICONE_PROFILE_API_URL}/${hash}`;
  };

  const funcionalidadesDefault = (item) =>
    item.caminho === "AlterarSenha" ||
    item.id === cmainhoFuncionalidadeDefaultEnum.unidadesConsumidoras;

  const retornaListaPlataformas = (item) => {
    let retorno = "";
    if (item?.flagMobile) retorno += "Mobile | ";
    if (item?.flagWeb) retorno += "Web | ";
    if (item?.flagIntranet) retorno += "Intranet | ";
    return retorno.trim().replace(/.$/, "").trim();
  };

  return (
    <Item>
      <Grid container spacing={1} classes={{ root: classes.container }}>
        {level === 1 ? (
          <TreeViewParentIcon force={Boolean(level === 1 && childs)} />
        ) : null}
        <Grid item xs className={`${classes.gridItem} ${classes.labelItem}`}>
          <div
            className={`${level > 0 && node?.descricao ? "d-block pt-1" : ""} ${
              classes.treeItem
            } ${classes.wrapper} ${classes.left} ${classes.right}`}
          >
            <Grid container spacing={0} justifyContent="space-between">
              <Grid item>
                {domain === "luz" ? (
                  icone !== null ? (
                    <Box
                      component="img"
                      width="17px"
                      height="17px"
                      sx={{ filter: "opacity(0.55)" }}
                      onError={(e) => (
                        (e.target.onerror = null), (e.target.src = MenuImg)
                      )}
                      src={`${handleIcone(icone)}`}
                    />
                  ) : (
                    <Box
                      component="img"
                      width="17px"
                      height="17px"
                      sx={{ filter: "opacity(0.55)" }}
                      src={MenuImg}
                    />
                  )
                ) : icone !== null ? (
                  <Box
                    component="img"
                    width="17px"
                    height="17px"
                    sx={{ filter: "opacity(0.90) invert(100%)" }}
                    onError={(e) => (
                      (e.target.onerror = null), (e.target.src = MenuImg)
                    )}
                    src={`${handleIcone(icone)}`}
                  />
                ) : (
                  <Box
                    component="img"
                    width="17px"
                    height="17px"
                    sx={{ filter: "opacity(0.90) invert(100%)" }}
                    src={MenuImg}
                  />
                )}
                <Box component="label" ml={2}>
                  {label}
                </Box>
              </Grid>
              {level > 0 ? (
                <Grid item className={classes.plataformasItem}>
                  {retornaListaPlataformas(node)}
                </Grid>
              ) : null}
            </Grid>
            {level > 0 ? (
              <Grid container spacing={0}>
                <Grid item className={classes.descricao}>
                  {node?.descricao}
                </Grid>
              </Grid>
            ) : null}
          </div>
        </Grid>
        {editable ? (
          <Grid
            item
            xs={1}
            className={`${classes.squareWidth} ${classes.gridItem}`}
          >
            <div
              className={`${classes.treeItem} ${classes.wrapper} ${classes.center}`}
              onKeyDown={() => onClickEditar(node)}
              onClick={() => onClickEditar(node)}
              role="button"
              tabIndex={0}
            >
              <EditOutlinedIcon />
            </div>
          </Grid>
        ) : null}
        {deletable ? (
          <Grid
            item
            xs={1}
            className={`${classes.squareWidth} ${classes.gridItem}`}
          >
            <div
              className={`${classes.treeItem} ${classes.wrapper} ${classes.center} ${classes.deletar}`}
              onKeyDown={() => onClickDeletar(node)}
              onClick={() => onClickDeletar(node)}
              role="button"
              tabIndex={0}
            >
              <MaterialSwitch checked={node?.ativo} />
            </div>
          </Grid>
        ) : null}
        {draggable && node?.ativo ? (
          <Grid
            item
            xs={1}
            className={`${classes.squareWidth} ${classes.gridItem}`}
          >
            <div
              className={`${classes.treeItem} ${classes.wrapper} ${classes.center}`}
              role="button"
              tabIndex={0}
              {...dragHandleProps}
            >
              <OpenWithOutlinedIcon
                htmlColor={checked ? "rgb(100, 100, 100, 0.4)" : ""}
              />
            </div>
          </Grid>
        ) : null}
        {selectable ? (
          <Grid
            item
            xs={1}
            className={`${classes.squareWidth} ${classes.gridItem}`}
          >
            <div
              className={`${classes.treeItem} ${classes.wrapper} ${classes.center}`}
              role="button"
            >
              {funcionalidadesDefault(node) ? (
                <Tooltip
                  title={<h6>Não é possível desmarcar esta funcionalidade</h6>}
                >
                  <Checkbox
                    checked={selected}
                    className={classes.checkbox}
                    onClick={() => onClickSelecionar(node)}
                  />
                </Tooltip>
              ) : (
                <Checkbox
                  checked={selected}
                  className={classes.checkbox}
                  onClick={() => onClickSelecionar(node)}
                />
              )}
            </div>
          </Grid>
        ) : null}
      </Grid>
    </Item>
  );
};

TreeViewItem.propTypes = {
  level: PropTypes.number.isRequired,
  childs: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]).isRequired,
  label: PropTypes.string.isRequired,
  icone: PropTypes.string,
  editable: PropTypes.bool,
  onEditar: PropTypes.oneOfType([PropTypes.func]),
  deletable: PropTypes.bool,
  onDeletar: PropTypes.oneOfType([PropTypes.func]),
  draggable: PropTypes.bool,
  dragHandleProps: PropTypes.oneOfType([PropTypes.any]),
  selectable: PropTypes.bool,
  onSelecionar: PropTypes.oneOfType([PropTypes.func]),
  selected: PropTypes.bool,
  node: PropTypes.oneOfType([PropTypes.object]).isRequired,
  checked: PropTypes.bool
};

TreeViewItem.defaultProps = {
  editable: false,
  onEditar: () => {},
  deletable: false,
  onDeletar: () => {},
  draggable: false,
  dragHandleProps: {},
  selectable: false,
  onSelecionar: () => {},
  selected: false,
  icone: "",
  checked: false
};

export default TreeViewItem;
