import { makeStyles } from '@mui/styles';
import { Base } from "../../../../../componentes/cores";
import theme from "themes";

export const useStyles = makeStyles({
  containerColorido: {
    padding: "24px",
    background: (props) => props.planoCor,
    color: (props) => props.corTexto,
    borderRadius: '20px 20px 0 0',
    //borderRadius: '20px',
    opacity: (props) => props.opacity
  },
  containerCard: {
    borderRadius: '20px',
    height: '100%'
  },
  containerBranco: {
    padding: "24px",
    background:  (props) => props.print? props.fundoColor : 'none'
  },
  containerPadding: {
    height: '100%',
    padding: "0px 24px",
    opacity: (props) => (props.cotacaoFechada ? 0.5 : 1),
  },
  containerPlano: {
    textAlign: 'center'
  },
  labelPlano: {
    fontFamily: theme.font.family,
    fontSize: (props) => props.print ? "26px" : "18px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "italic",
    lineHeight: "normal",
    letterSpacing: "-0.4px"
  },
  labelInformacoes: {
    fontFamily: theme.font.family,
    fontSize: (props) => props.print ? "24px" : "16px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "italic",
    lineHeight: "normal",
    letterSpacing: "-0.4px"
  },
  valorInformacoes: {
    fontFamily: theme.font.family,
    fontSize: (props) => props.print ? "32px" : "20px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "italic",
    lineHeight: "normal",
    letterSpacing: "-0.53px"
  },
  valorReducaoCustos: {
    fontFamily: theme.font.family,
    fontSize: (props) => props.print ? "40px" : "24px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "italic",
    lineHeight: "normal",
    letterSpacing: "-0.67px"
  },
  planoNome: {
    fontFamily: theme.font.family,
    fontSize: (props) => props.print ? "42px" : "26px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "italic",
    lineHeight: "normal",
    letterSpacing: "-0.67px"
  },
  containerInformacoes: {
    paddingBottom: "15px"
  },
  botaoEscolher: {
    borderRadius: "21px",
    backgroundColor: theme.color.primary,
    color: theme.color.secondaryBackgroud,
    width: "100%",
    padding: "10px 22px",
    "&:hover": {
      backgroundColor: theme.color.primary,
      color: theme.color.secondaryBackgroud,
      }
  },
  containerSelecao: {
    width: '100%',
    display: 'flex',
    borderTop: `2px solid ${Base.RedAmaranth}`,
    paddingTop: "15px"
  },
  caixaSelecao: {
    backgroundColor: Base.White,
    display: 'block',
    height: '28px',
    position: 'relative',
    width: '28px',
    marginRight: '10px',
    textAlign: 'center',
    color: Base.RedError,
    fontSize: '20px',
    fontWeight: 'bold'
  },
  labelSelecao: {
    fontSize: '18px'
  }
});
