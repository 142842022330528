import { makeStyles } from "@mui/styles";
import { createTheme } from "@mui/material/styles";
import styled from "styled-components";
import theme from "../../../themes";

export const useStyles = makeStyles({
  buttonFiltro: {
    padding: "13px 5px",
  },
  container: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "8px",
      borderColor: "rgb(38, 41, 56) !important"
    }
  },
  card: {
    background: "#FBFBFD",
    padding: "20px 15px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
    "& h5": {
      fontSize: "16px",
      fontWeight: 700,
      color: theme.color.primary,
      margin: 0
    },
    "& p": {
      margin: 0
    }
  },
  endereco: {
    display: "flex",
    alignItems: "center",
    color: "#332053",
    textAlign: "left",
    "& svg": {
      margin: "10px"
    }
  },
  preco: {
    borderBottom: "1px solid #D2D2D7",
    marginBottom: "10px",
    paddingBottom: "10px",
    "& h3": { fontSize: "24px", fontWeight: 700, color: theme.color.primary }
  },
  borda: {
    borderBottom: "1px solid #D2D2D7",
    width: "100%",
    marginBottom: "10px",
    paddingBottom: "10px"
  },
  status: {
    display: "flex",
    textTransform: "uppercase",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "16px",
    padding: "5px 0",
    margin: "0 auto",
    width: "100%"
  },
  botaoBaixar: {
    backgroundColor: theme.color.botaoDownloadFatura,
    color: theme.color.secondaryBackgroud,
    padding: "5px 10px",
    border: 0,
    borderRadius: "30px"
  },
  naoSelecionado: {
    color: `${theme.color.secondaryBorderColor} !important`,
    fontSize: '28px',
    margin: '150px auto 150px',
    textAlign: 'center',
  }
});

export const tema = createTheme({
  palette: {
    background: {
      paper: "#FBFBFD" // your color
    }
  }
});

export const StatusIcone = styled.div`
  background-color: ${(props) =>
    props.color || theme.color.botaoDownloadFatura};
  width: 24px;
  height: 24px;
  border-radius: 8px;
  margin-right: 5px;
`;
