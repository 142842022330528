import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  item: {
    display: "flex",
    justifyContent: "flex-end",
    marginLeft: "auto !important",
    paddingRight: 0,
    textAlign: "right"
  },
  icon: {
    cursor: "pointer"
  }
});
