import { Card, Grid } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { cnpj } from "cpf-cnpj-validator";
import { useHistory, useParams } from "react-router-dom";
import { fieldEmpty } from "servicos/utils";
import BotaoRetornarListagem from "../../../../componentes/botaoRetornarListagem";
import { useStyles } from "./style";
import { RotasDTO } from "../../../../global/rotas/rotasUrlDto";
import MaterialInputTexto from "../../../../componentes/inputTexto/materialInput";
import Botao from "../../../../componentes/botao";
import { Base } from "../../../../componentes/cores";
import SelectAutoComplete from "../../../../componentes/selectAutocomplete";
import FormValidation from "../../../../componentes/formValidation";
import MaterialInputMascara from "../../../../componentes/inputTextoMascara";
import CadastroAgenteHelper from "./helper";
import { store } from "../../../../global/redux";
import { alertaExibir } from "../../../../global/redux/modulos/alertas/actions";
import Loader from "../../../../componentes/loader";
import AgenteDto from "./dto/agenteDto";
import ModalVerificacao from "../../../../componentes/modalVerificacao";
import MaterialSwitch from "../../../../componentes/switch";
import { desabilitarFiltroLateral } from "../../../../global/redux/modulos/usuario/actions";

// import { Container } from './styles';

function AgenteCadastro() {
  const history = useHistory();
  const { id: idUrl } = useParams();

  const [limparCnpj, setLimparCnpj] = useState(false);
  const [listaClientes, setListaClientes] = useState([]);
  const [carregandoCliente, setCarregandoCliente] = useState();
  const [carregando, setCarregando] = useState();

  const [id, setId] = useState();
  const [clienteId, setClienteId] = useState(null);
  const [cnpjCliente, setCnpjCliente] = useState();
  const [razaoSocialCliente, setRazaoSocialCliente] = useState();
  const [sigla, setSigla] = useState("");
  const [siglaEdicao, setSiglaEdicao] = useState("");
  const [codigoAgente, setCodigoAgente] = useState();
  const [ativo, setAtivo] = useState(true);
  const [descricao, setDescricao] = useState();

  const [dadosBuscaSigla, setDadosBuscaSigla] = useState();
  const [exibirModalSigla, setExibirModalSigla] = useState(false);

  const { register, errors, handleSubmit, setValue } = useForm({
    reValidateMode: "onSubmit"
  });

  const clientRequired = useMemo(
    () => !clienteId && "Campo Cliente é obrigatório!",
    [clienteId]
  );

  const obterClientes = useCallback(async () => {
    setCarregandoCliente(true);
    const resultado = await CadastroAgenteHelper.obterClientesAgenteCCEE();
    setCarregandoCliente(false);

    if (resultado.sucesso) {
      setListaClientes(resultado.data);
      return;
    }

    store.dispatch(
      alertaExibir({
        tipo: "danger",
        mensagem: resultado.mensagem
      })
    );
  }, []);

  const setarCliente = (idCliente) => {
    setClienteId(idCliente);

    if (idCliente === 0) {
      setClienteId(null);
      return;
    }

    const cli = listaClientes.find((c) => c.id === idCliente);

    setClienteId(cli.id);
    setCnpjCliente(cli.cnpjCpf);
    setRazaoSocialCliente(cli.razaoSocial);
  };

  const classes = useStyles();

  const onSubmit = async (parametros) => {
    setCarregando(true);

    const retorno = await CadastroAgenteHelper.SalvarAgente(
      new AgenteDto(
        parametros.cnpj,
        parametros.razaoSocial,
        parametros.agenteCCEE,
        parametros.siglaCCEE,
        ativo,
        clienteId,
        parametros.descricaoCCEE,
        id
      )
    );

    setCarregando(false);

    if (!retorno.sucesso) {
      store.dispatch(
        alertaExibir({
          tipo: "danger",
          mensagem: retorno.mensagem
        })
      );
      return;
    }

    store.dispatch(
      alertaExibir({
        tipo: "success",
        mensagem: retorno.mensagem
      })
    );
    history.push(RotasDTO.Agentes);
  };

  const ObterPorSigla = async (siglaBusca) => {
    setSigla(siglaBusca);

    if (!siglaBusca || siglaBusca === "") return;

    if (siglaBusca === siglaEdicao) return;

    setCarregando(true);

    const retorno = await CadastroAgenteHelper.ObterPorSigla(siglaBusca);

    if (!retorno.sucesso) {
      setCarregando(false);
      return;
    }

    const agenteRetorno = retorno?.data;

    if (!agenteRetorno) {
      setCarregando(false);
      return;
    }

    setDadosBuscaSigla(agenteRetorno);

    setExibirModalSigla(true);

    setCarregando(false);
  };

  const obterPorIdEdicao = useCallback(async () => {
    if (!idUrl || idUrl <= 0) return;

    setCarregando(true);

    const retorno = await CadastroAgenteHelper.ObterPorId(idUrl);

    if (!retorno.sucesso) {
      store.dispatch(
        alertaExibir({
          tipo: "danger",
          mensagem: retorno.mensagem
        })
      );
      return;
    }

    const agenteBanco = retorno.data;

    setId(agenteBanco.Id);
    setCnpjCliente(agenteBanco.Cnpj);
    setClienteId(agenteBanco.ClienteId);
    setRazaoSocialCliente(agenteBanco.RazaoSocial);
    setCodigoAgente(agenteBanco.CodigoAgenteCCEE);
    setSigla(agenteBanco.SiglaAgenteCCEE);
    setSiglaEdicao(agenteBanco.SiglaAgenteCCEE);
    setAtivo(agenteBanco.Ativo);
    setDescricao(agenteBanco.DescricaoDoAgente);

    setCarregando(false);
  }, [idUrl]);

  useEffect(() => {
    obterPorIdEdicao();
  }, [obterPorIdEdicao]);

  useEffect(() => {
    obterClientes();
  }, [obterClientes]);

  useEffect(() => {
    store.dispatch(desabilitarFiltroLateral(true));

    return () => store.dispatch(desabilitarFiltroLateral(false));
  }, [desabilitarFiltroLateral]);

  return <>
    <ModalVerificacao
      exibir={exibirModalSigla}
      titulo="Atenção"
      mensagem="Sigla já cadastrada. Deseja ir para a tela de edição?"
      labelBotaoPrincipal="Sim"
      labelBotaoSecundario="Não"
      onAcaoPrincipal={() => {
        setExibirModalSigla(false);
        history.push(`${RotasDTO.Agentes}/cadastro/${dadosBuscaSigla.Id}`);
      }}
      onAcaoSecundaria={() => {
        setSigla("");
        setValue("siglaCCEE", "");
        setExibirModalSigla(false);
      }}
    />
    <Loader loading={carregando}>
      <FormValidation onSubmit={onSubmit} handleSubmit={handleSubmit}>
        <Card className={classes.cardCadastro}>
          <Grid container p={2} spacing={4} className={classes.container}>
            <Grid item xs={6} className="font-weight-bold">
              {`${id ? "Editar" : "Novo"} Agente`}
            </Grid>
            <BotaoRetornarListagem urlListagem={RotasDTO.Agentes} />
            <Grid item lg={3} md={3} sm={6}>
              <Loader loading={carregandoCliente}>
                <SelectAutoComplete
                  disableClearable
                  disableOpenOnFocus
                  id="cliente"
                  name="cliente"
                  valueKey="id"
                  marginBottom="0"
                  label="Cliente"
                  placeholder="Cliente"
                  defaultValue={clienteId}
                  value={clienteId}
                  options={listaClientes.filter(cliente => cliente.nomeFantasia)}
                  getOptionLabel={(option) => option.nomeFantasia}
                  onChange={(e, item) => {
                    setarCliente(item?.id ?? 0);
                  }}
                  ref={register(
                    { name: "cliente" },
                    {
                      required: clientRequired
                    }
                  )}
                  error={errors}
                  allowClear
                  valueName="nomeFantasia"
                />
              </Loader>
            </Grid>
            <Grid item lg={3} md={3} sm={6}>
              <MaterialInputMascara
                type="text"
                id="cnpj"
                name="cnpj"
                label="CNPJ"
                mask="##.###.###/####-##"
                limparValor={limparCnpj}
                redefineLimpar={(estado) => setLimparCnpj(estado)}
                renderIconShowHide={false}
                defaultValue={cnpjCliente}
                ref={register({
                  required: "Campo CNPJ é obrigatório!",
                  maxLength: {
                    value: 18,
                    message: "Quantidade máxima de 18 caracteres!"
                  },
                  minLength: {
                    value: 18,
                    message: "Quantidade mínima de 18 caracteres!"
                  },
                  validate: (value) => cnpj.isValid(value) || "CNPJ inválido!"
                })}
                errors={errors}
              />
            </Grid>
            <Grid item lg={6} md={6} sm={12}>
              <MaterialInputTexto
                type="text"
                id="razaoSocial"
                name="razaoSocial"
                permiteValorBranco
                defaultValue={razaoSocialCliente}
                label="Razão Social"
                renderIconShowHide={false}
                ref={register({
                  required: "Campo Razão Social é obrigatório!",
                  maxLength: {
                    value: 100,
                    message: "Quantidade máxima de 100 caracteres!"
                  },
                  validate: (value) =>
                    fieldEmpty(value) || "Campo Razão Social é obrigatório!"
                })}
                errors={errors}
              />
            </Grid>
            <Grid item lg={3} md={3} sm={6}>
              <MaterialInputTexto
                type="text"
                id="siglaCCEE"
                name="siglaCCEE"
                label="Sigla Agente CCEE"
                permiteValorBranco
                onBlur={(event) => ObterPorSigla(event.target.value)}
                defaultValue={sigla}
                renderIconShowHide={false}
                ref={register({
                  required: "Campo Sigla Agente CCEE é obrigatório!",
                  maxLength: {
                    value: 50,
                    message: "Quantidade máxima de 50 caracteres!"
                  },
                  validate: (value) =>
                    fieldEmpty(value) ||
                    "Campo Sigla Agente CCEE é obrigatório!"
                })}
                errors={errors}
              />
            </Grid>
            <Grid item lg={3} md={3} sm={6}>
              <MaterialInputTexto
                type="number"
                id="agenteCCEE"
                name="agenteCCEE"
                label="Código do Agente CCEE"
                defaultValue={codigoAgente}
                renderIconShowHide={false}
                ref={register({
                  required: "Campo Codigo do Agente CCEE é obrigatório!",
                  maxLength: {
                    value: 50,
                    message: "Quantidade máxima de 50 caracteres!"
                  },
                  validate: (value) =>
                    fieldEmpty(value) ||
                    "Campo Codigo do Agente CCEE é obrigatório!"
                })}
                errors={errors}
              />
            </Grid>
            <Grid item lg={6} md={6} sm={12}>
              <MaterialInputTexto
                type="text"
                id="descricaoCCEE"
                name="descricaoCCEE"
                defaultValue={descricao}
                label="Descrição Agente CCEE"
                renderIconShowHide={false}
                ref={register({
                  maxLength: {
                    value: 100,
                    message: "Quantidade máxima de 100 caracteres!"
                  }
                })}
                errors={errors}
              />
            </Grid>
            {id && (
              <>
                <Grid item lg={3} md={4} sm={6} justifyContent="flex-start">
                  <MaterialSwitch
                    label="Ativo"
                    labelPlacement="top"
                    id="ativo"
                    justify="flex-start"
                    name="ativo"
                    checked={ativo}
                    onChange={(param) => setAtivo(param)}
                  />
                </Grid>
                <Grid item lg={9} md={8} sm={6} />
              </>
            )}
            <Grid item lg={3} md={3} sm={6}>
              <Botao
                type="submit"
                label="Salvar"
                color={Base.Mango}
                className={classes.button}
              />
            </Grid>
          </Grid>
        </Card>
      </FormValidation>
    </Loader>
  </>;
}

export default AgenteCadastro;
