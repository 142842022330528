import { makeStyles } from '@mui/styles';
import { Base } from "../../cores";
import theme from 'themes';

const colors = [
  theme.color.secondaryText, 
  Base.OrangeLuz, 
  Base.BlueViolet, 
  Base.BlueDodger,
  Base.GreenFern, 
  Base.Aurora
]

const BackgroundColor = (level) => {
  const cores = [theme.color.treeViewBackground1, theme.color.treeViewBackground2, theme.color.treeViewBackground2, theme.color.treeViewBackground2, theme.color.treeViewBackground2];
  return cores[level];
};

export const useStyles = makeStyles({
  squareWidth: {
    height: "54px",
    width: "54px"
  },
  treeViewItemExpand: {
    color: Base.GreyDim,
    justifyContent: "end",
    padding: 0,
    position: "relative"
  },
  treeViewBorder: {
    borderLeftColor: (props) => colors[props.color],
    borderLeftWidth: "6px",
    borderLeftStyle: "solid"
  },
  gridItem: {
    flexBasis: "100% !important",
    fontSize: "2.1875rem",
    margin: "0 !important",
    maxWidth: "100% !important",
    opacity: (props) => (props.ativo ? 1 : 0.5),
    "pointer-events": "all",
    width: "54px"
  },
  treeItem: {
    backgroundColor: (props) => BackgroundColor( props.color),
    color: `${theme.color.secondaryText} !important`,
    height: "54px"
  },
  wrapper: {
    alignItems: "center",
    display: "flex",
    height: "100%",
    minHeight: "100%"
  },
  start: {
    alignItems: "flex-start"
  },
  center: {
    justifyContent: "center"
  },
  left: {
    justifyContent: "start",
    paddingLeft: "20px"
  },
  right: {
    justifyContent: "flex-end"
  }
});
