import { createTheme, adaptV4Theme } from "@mui/material";
import { makeStyles } from '@mui/styles';
import { Base } from "../cores";
import theme from "themes";

export const useStyles = makeStyles(() => ({
  root: {
    "& label.Mui-focused": {
      color: `${Base.GreyDim}`
    }
  },
  container: {
    "& .MuiFormControl-marginNormal": {
      margin: "0"
    }
  }
}));

export const materialTheme = createTheme(adaptV4Theme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: theme.color.primary
      }
    },
    datePicker: {
      selectColor: theme.color.primary
    }
  }
}));
