import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";

// ApexChart
import ReactApexChart from "react-apexcharts";

// Styles
import { useStyles } from "./style";
import { Base } from "../../../../../componentes/cores";
import theme from 'themes';

const CustoCativoPDF = ({ dadosCativo }) => {
    const classes = useStyles();

    const [custoCativo, setCustoCativo] = useState();
    const [labels, setLabels] = useState();

    const formatarTotal = (valor) => {
        return valor?.toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL",
            maximumFractionDigits: 0,
            minimumFractionDigits: 0
        });
    };

    const legendaTooltip = (legenda) => {
        return legenda.charAt(0).toUpperCase() + legenda.slice(1);
    };

    const dados = useMemo(
        () => ({
            series: custoCativo,
            options: {
                chart: {
                    type: "donut"
                },
                labels,
                colors: [Base.GreyHit, "#86999c", "#687b7f", "#394345"],
                stroke: {
                    width: 0
                },
                legend: {
                    show: false,
                    // formatter(seriesName, opts) {
                    //     return [
                    //         legendaTooltip(seriesName),
                    //         " ",
                    //         opts.w.globals.series[opts.seriesIndex]?.toLocaleString("pt-br", {
                    //             style: "currency",
                    //             currency: "BRL",
                    //             maximumFractionDigits: 2,
                    //             minimumFractionDigits: 0
                    //         })
                    //     ];
                    // },
                    labels: {
                        colors: Base.Black,
                        fontFamily: theme.font.family,
                        fontSize: "16px !important"
                    },
                    markers: {
                        radius: 0
                    },
                    offsetY: -80,
                    offsetX: 140,
                    position: "bottom",
                    width: 200,
                    float: "left",
                    horizontalAlign: "left"
                },
                plotOptions: {
                    pie: {
                        customScale: 0,
                        offsetX: -180,
                        donut: {
                            labels: {
                                show: true,
                                name: {
                                    show: true
                                },
                                value: {
                                    show: true,
                                    color: Base.Black,
                                    fontSize: "16px",
                                    fontWeight: 800,
                                    formatter(value) {
                                        return formatarTotal(parseFloat(value));
                                    }
                                },
                                total: {
                                    show: true,
                                    showAlways: true,
                                    color: Base.Black,
                                    label: "Custo Cativo",
                                    fontSize: 16,
                                    formatter(value) {
                                        return formatarTotal(
                                            value.globals.seriesTotals.reduce((a, b) => {
                                                return a + b;
                                            }, 0)
                                        );
                                    }
                                }
                            },
                            size: "0%"
                        }
                    }
                },
                dataLabels: {
                    enabled: false
                },
                yaxis: {
                    show: false,
                    // labels: {
                    //     formatter(val) {
                    //         return val?.toLocaleString("pt-br", {
                    //             style: "currency",
                    //             currency: "BRL",
                    //             maximumFractionDigits: 2,
                    //             minimumFractionDigits: 0
                    //         });
                    //     }
                    // }
                },
                tooltip: {
                    show: false,
                    // y: {
                    //     title: {
                    //         formatter: (seriesName) => {
                    //             return legendaTooltip(seriesName);
                    //         }
                    //     }
                    // }
                }
            }
        }),
        [custoCativo, labels]
    );

    useEffect(() => {
        if (dadosCativo) {
            setLabels(Object.keys(dadosCativo));
            setCustoCativo([
                parseFloat(dadosCativo?.energia),
                parseFloat(dadosCativo?.distribuidora),
                parseFloat(dadosCativo?.impostos),
                parseFloat(dadosCativo?.outros)
            ]);
        }
    }, [dadosCativo]);

    console.log("+++++++",dadosCativo)

    return (
        <div className={classes.container}>
            {dadosCativo && dados.options && dados.series ? (
                <ReactApexChart
                    options={dados.options}
                    series={dados.series}
                    type="donut"
                    height={200}
                    width={"90%"}
                />
            ) : null}
        </div>
    );
};

CustoCativoPDF.propTypes = {
    dadosCativo: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
};

CustoCativoPDF.defaultProps = {
    dadosCativo: {}
};

export default CustoCativoPDF;