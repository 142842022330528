import styled from "styled-components";
import { makeStyles } from '@mui/styles';
import { Base } from "../cores";
import theme from "themes";

export const useStyles = makeStyles(() => ({
  menu: {
    backgroundColor: Base.WhiteGhost,
    maxWidth: "366px"
  },
  filterContainer: {
    maxWidth: "366px",
    outline: "none",
    padding: "10px"
  },
  itemContainer: {
    margin: "5px 0",
    padding: "5px 0"
  },
  containerTitulo: {
    marginLeft: "10px"
  },
  asc: {
    transform: "rotate(0deg)",
    maxHeight: 14,
    objectFit: 'cover',
    marginLeft: 2,
  },
  desc: {
    maxHeight: 14,
    objectFit: 'cover',
    marginLeft: 2,
    transform: "rotate(-180deg)"
  }
}));