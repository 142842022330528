import { makeStyles } from '@mui/styles';
import styled from "styled-components";
import { Base } from "../../../../componentes/cores";
import theme from "themes";

export const useStyles = makeStyles(() => ({
  containerGrafico: {
    height: "100%",
    minHeight: "300px",
    paddingBottom: "0",
    width: "100%",
    "& .apexcharts-tooltip": {
      border: "0 none",
      background: Base.GreyPaleSky,
      textAlign: "center",
      fontSize: "15px",
      marginTop: "-3px"
    },
    "& .apexcharts-tooltip-title": {
      background: `${Base.GreyPaleSky} !important`,
      borderBottom: "0 !important",
      color: Base.GreyCasper,
      fontSize: "10px",
      display: "none"
    },
    "& .apexcharts-tooltip-series-group": {
      marginBottom: "-5px"
    },
    "& .MuiInputBase-input": {
      maxWidth: "75px"
    },
    "& .MuiButtonBase-root": {
      color: theme.color.textoBarra,
      paddingLeft: 0
    }
  },
  titulo: {
    fontWeight: "bold",
    fontSize: "24px"
  },
  container: {
    "& .MuiInputBase-root": {
      color: theme.color.textoBarra
    },
    "& .MuiInputBase-input": {
      maxWidth: "75px"
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "0 none"
    },
    "& .MuiButtonBase-root": {
      color: theme.color.textoBarra,
      paddingLeft: 0
    }
  },
  noData: {
    textAlign: "center",
    width: "100%",
    "& div": {
      margin: "50px auto 0",
      maxWidth: "80%"
    }
  },
  arrowBox: {
    position: "relative",
    background: Base.GreyPaleSky,
    padding: "8px"
  },
  arrowBoxTitle: {
    color: Base.GreyCasper,
    fontSize: "10px"
  },
  info: {
    color: theme.color.textoBarra,
    marginTop: "17px"
  },
  ajusteAltura:{
    marginTop:-35
  },
  ajusteBoxEconomia:{
    backgroundColor: '#FFF !important',
    color: `${Base.Black} !important`,
    position: 'absolute',
    marginTop:"0px !important"
  },
  containerGraficoPDF: {
    height: "100%",
    minHeight: "300px",
    paddingBottom: "0",
    width: "100%",
    background: 'white !important',
    "& .apexcharts-tooltip": {
      border: "0 none",
      background: Base.White,
      textAlign: "center",
      fontSize: "15px",
      marginTop: "-3px"
    },
    "& .apexcharts-tooltip-title": {
      background: `${Base.White} !important`,
      borderBottom: "0 !important",
      color: Base.Black,
      fontSize: "10px",
      display: "none"
    },
    "& .apexcharts-tooltip-series-group": {
      marginBottom: "-5px"
    },
    "& .MuiInputBase-input": {
      maxWidth: "75px"
    },
    "& .MuiButtonBase-root": {
      color: Base.Black,
      paddingLeft: 0
    }
  },
  colorBlack:{
    color: `${Base.Black} !important`,
  },
  tituloPDF:{
    fontWeight: 'bold',
    fontSize: 18,
    color: `${Base.Black} !important`,
    height: '100px !important'
  }
}));

export const Economia = styled.div`
  align-items: center;
  background-color: ${theme.color.bgColorThirdCard};
  display: flex;
  height: 38px;
  justify-content: space-between;
  margin-top: 0;
  width: 100%;
`;

export const Label = styled.div`
  color: ${Base.White};
  font-weight: bold;
  margin-left: 25px;
`;

export const Valor = styled.div`
  color: ${Base.White};
  font-weight: bold;
  margin-right: 25px;
`;

export const EconomiaPDF = styled.div`
  align-items: center;
  background-color: #fbfbfb;
  display: flex;
  height: 38px;
  justify-content: space-between;
  margin-top: -30px !important;
  width: 100%;
`;

export const LabelPDF = styled.div`
  color: ${Base.Black};
  font-weight: bold;
  margin-left: 40px;
  font-size: 14px;
`;

export const ValorPDF = styled.div`
  color: ${Base.Black};
  font-weight: bold;
  font-size: 14px;
  margin-left: 25px;
`;