import { store } from "../redux";
import { alertaExibir } from "../redux/modulos/alertas/actions";

export default class AlertaUtil {
  static alertaSucesso(mensagem) {
    this.alertaGenerico("success", mensagem);
  }

  static alertaWarning(mensagem) {
    this.alertaGenerico("warning", mensagem);
  }

  static alertaErro(mensagem) {
    this.alertaGenerico("danger", mensagem);
  }

  static alertaGenerico(tipo, mensagem) {
    store.dispatch(
      alertaExibir({
        tipo: tipo,
        mensagem: mensagem
      })
    );
  }
}
