import MICROSERVICO from "global/enumeradores/microServico";
import obterApi from "servicos/api";

const api = () => obterApi(MICROSERVICO.AREALOGADA);

export const Listar = async (params) => {
  return api().get(`v1/permissaoclientetiporelatorio/listaclientetiporelatoriopermitido?${new URLSearchParams(params).toString()}`);
};

export const ListaTipoRelatorioParaAssociacao = async (params) => {
  return api().get(`v1/permissaoclientetiporelatorio/listatiporelatorioparaassociacao?${new URLSearchParams(params).toString()}`);
};

export const Associa = async (params) => {
  return api().patch(`v1/permissaoclientetiporelatorio/associa`, params);
};

export const Desassocia = async (params) => {
  return api().patch(`v1/permissaoclientetiporelatorio/desassocia`, params);
};
