import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";

// Componentes
import { Grid, Button, IconButton, Box, CircularProgress } from "@mui/material";
import { useHistory } from "react-router-dom";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import { formataPercentual } from "../../../servicos/utils";
import Loader from "../../../componentes/loader";
import UnidadeNaoSelecionada from "componentes/unidadeNaoSelecionada";
import EconomiaMensal from "./EconomiaMensal";
import Historico from "./Historico";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

// Styles
import {
  Icone,
  IconeImagem,
  Legenda,
  Texto,
  TotalCativo,
  TotalEconomia,
  TotalLivre,
  Valor,
  CarregandoPDF,
  useStyles
} from "./style";

// Serviços
import { calculoTotal } from "../../../servicos/economiaServico";

// Imagens
import totalCativoIcone from "../../../assets/totalCativo.png";
import totalCustoLivreIcone from "../../../assets/totalCustoLivre.png";
import totalEconomiaIcone from "../../../assets/totalEconomia.png";

import totalCativoIconePreto from "../../../assets/totalCativo-preto.png";
import totalCustoLivreIconePreto from "../../../assets/totalCustoLivre-preto.png";
import totalEconomiaIconePreto from "../../../assets/totalEconomia-preto.png";
import { store } from "../../../global/redux";
import { selecionarUnidade } from "../../../global/redux/modulos/usuario/actions";

import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import moment from "moment";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import _theme from "themes";

const Economia = () => {
  const classes = useStyles();
  const usuario = useSelector((state) => state.usuario);
  const [pdf, setPDF] = React.useState({
    status: false
  });
  const { unidadeSelecionada, clienteSelecionado } = usuario;

  const [totalCustoCativo, setTotalCustoCativo] = useState(null);
  const [totalCustoLivre, setTotalCustoLivre] = useState(null);
  const [totalEconomia, setTotalEconomia] = useState(null);
  const [descontoPercentual, setDescontoPercentual] = useState(null);

  const [carregandoDados, setCarregandoDados] = useState(false);
  const [showBotaoPDF, setShowBotaoPDF] = useState(false);

  const history = useHistory();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("lg"));

  useEffect(() => {
    setShowBotaoPDF(false);
    let timeout = setTimeout(() => {
      setShowBotaoPDF(true);
      clearTimeout(timeout);
    }, 3000);
  }, [carregandoDados]);

  const obterCalculoTotal = useCallback(async () => {
    try {
      setCarregandoDados(true);

      if (unidadeSelecionada) {
        const calculo = await calculoTotal(unidadeSelecionada);
        if (calculo?.status === 200 && calculo?.data) {
          setTotalCustoCativo(
            calculo?.data.custoTotalCativo !== 0
              ? calculo?.data.custoTotalCativo
              : ""
          );
          setTotalCustoLivre(
            calculo?.data.custoTotalLivre !== 0
              ? calculo?.data.custoTotalLivre
              : ""
          );
          setTotalEconomia(
            calculo?.data.totalEconomia !== 0 ? calculo?.data.totalEconomia : ""
          );
          setDescontoPercentual(
            calculo?.data?.descontoPercentual &&
              calculo?.data?.descontoPercentual !== "NaN"
              ? calculo?.data?.descontoPercentual * 100
              : ""
          );
        }
      }
    } finally {
      setCarregandoDados(false);
    }
  }, [unidadeSelecionada]);

  useEffect(() => {
    store.dispatch(selecionarUnidade(null));
  }, [clienteSelecionado]);

  useEffect(() => {
    obterCalculoTotal();
    getNomeCliente();
    getNomeUnidade();
  }, [obterCalculoTotal]);

  const getNomeCliente = () => {
    var localizado = -1;
    for (var i = 0; i < usuario.clientes.length; i++) {
      if (usuario.clientes[i].id == clienteSelecionado) {
        return usuario.clientes[i].nomeFantasia;
        localizado = i;
        break;
      }
    }
  };

  const getNomeUnidade = () => {
    var localizado = -1;
    for (var i = 0; i < usuario.unidades.length; i++) {
      if (usuario.unidades[i].id == unidadeSelecionada) {
        return usuario.unidades[i].nomeUnidade;
        localizado = i;
        break;
      }
    }
  };

  const print = () => {
    setPDF({
      status: true
    });

    setTimeout(function () {
      const forPDF = document.querySelectorAll(".printDiv");

      const len = forPDF.length;
      const thisPDF = new jsPDF("portrait");

      thisPDF.addFileToVFS(_theme.pdf.fontNameJsPDF, _theme.pdf.fontJsPDF);
      thisPDF.addFont(
        _theme.pdf.fontNameJsPDF,
        _theme.pdf.fontStyleJsPDF,
        "normal"
      );

      thisPDF.addFileToVFS(
        _theme.pdf.fontNameJsPDFBold,
        _theme.pdf.fontJsPDFBold
      );
      thisPDF.addFont(
        _theme.pdf.fontNameJsPDFBold,
        _theme.pdf.fontStyleJsPDFBold,
        "normal"
      );

      thisPDF.setFont(_theme.pdf.fontStyleJsPDF);

      function header() {
        thisPDF.setDrawColor(
          _theme.pdf.drawColorR,
          _theme.pdf.drawColorG,
          _theme.pdf.drawColorB
        );
        thisPDF.setLineWidth(5.0);
        thisPDF.line(0, 0, 300, 0);
        thisPDF.text(87, 35, "ECONOMIA");
        thisPDF.setFontSize(11);
        thisPDF.text(8, 44, "Cliente:");
        thisPDF.setFont(_theme.pdf.fontStyleJsPDFBold);
        thisPDF.text(27, 44, getNomeCliente());
        thisPDF.setFont(_theme.pdf.fontStyleJsPDF);
        thisPDF.text(8, 52, "Unidade:");
        thisPDF.setFont(_theme.pdf.fontStyleJsPDFBold);
        thisPDF.text(27, 52, getNomeUnidade());
        thisPDF.addImage(_theme.pdf.logo, "PNG", 8, 10, 35, 25);
      }

      function footer(n) {
        thisPDF.setPage(n);
        thisPDF.setTextColor(_theme.pdf.fontFooterColor);
        thisPDF.setFontSize(9);
        thisPDF.setFont(_theme.pdf.fontStyleJsPDF);
        thisPDF.text(13, 288, moment().format("DD/MM/YYYY"));

        //Na Luz não fica com problema se alinha a direita
        /*thisPDF.text(_theme.texto.empresa, 200, 273, "right");
        thisPDF.text( _theme.texto.enderecoRua + ',' + _theme.texto.enderecoNumero + '-' + _theme.texto.enderecoComplemento, 200, 278, "right");
        thisPDF.text(_theme.texto.enderecoBairro + ' - CEP ' + _theme.texto.enderecoCep + ' - ' + 
                    _theme.texto.enderecoCidade + ' - ' + _theme.texto.enderecoEstado, 200, 283, "right");
        thisPDF.text(_theme.texto.site, 200, 288, "right");
        */

        thisPDF.text(_theme.texto.empresa, 120, 273);
        thisPDF.text(
          _theme.texto.enderecoRua +
            "," +
            _theme.texto.enderecoNumero +
            "-" +
            _theme.texto.enderecoComplemento,
          120,
          278
        );
        thisPDF.text(
          _theme.texto.enderecoBairro +
            " - CEP " +
            _theme.texto.enderecoCep +
            " - " +
            _theme.texto.enderecoCidade +
            " - " +
            _theme.texto.enderecoEstado,
          120,
          283
        );
        thisPDF.text(_theme.texto.site, 120, 288);
      }

      var z = 0;
      for (var i = 0; i < forPDF.length; i++) {
        html2canvas(forPDF[i]).then((canvas) => {
          z++;
          const imgData = canvas.toDataURL("image/png");

          if (z === 1) {
            // Grafico 1 - Consumo Atual
            thisPDF.addImage(imgData, "JPEG", 3, 53, 205, 28);
            header();
            thisPDF.addImage(_theme.pdf.footer, "JPEG", 0, 246, 213, 51);
            footer(1);
          }

          if (z === 2) {
            thisPDF.addImage(imgData, "JPEG", 20, 73, 160, 100);
          }
          if (z === 3) {
            thisPDF.addImage(imgData, "JPEG", 3, 172, 205, 75);
          }
          if (z === len) {
            thisPDF.setFontSize(14);
            thisPDF.setFont(_theme.pdf.fontStyleJsPDFBold);
            thisPDF.text(50, 178, "HISTÓRICO");
            thisPDF.text(50, 90, "ECONOMIA");
            thisPDF.save(
              "Relatório de Economia - " + moment().format("MMM_Y") + ""
            );
            setPDF({
              status: false
            });
          }
        });
      }
    }, 4000);
  };

  return (
    <>
      {!unidadeSelecionada ? (
        <UnidadeNaoSelecionada />
      ) : (
        <Box className={mobile ? classes.boxTotal : ""}>
          {mobile ? (
            <>
              <Box className={classes.barraSuperior}>
                <IconButton
                  className={classes.arrowBack}
                  onClick={() => history.push(`/dashboard/`)}
                  size="small"
                >
                  <ArrowBackIosIcon fontSize="small" />
                </IconButton>
                Economia
              </Box>
            </>
          ) : (
            ""
          )}
          <Loader loading={carregandoDados}>
            {!mobile && showBotaoPDF && (
              <Box className={classes.boxPDF}>
                <Button
                  style={{ width: "100%", textAlign: "right" }}
                  onClick={print}
                >
                  {pdf.status ? (
                    ""
                  ) : (
                    <IconButton
                      aria-label="Gerar PDF"
                      className={classes.bordaPDF}
                      size="large"
                    >
                      <PictureAsPdfOutlinedIcon
                        className={classes.estiloBotaoPDF}
                      />
                    </IconButton>
                  )}
                </Button>
                {pdf.status ? (
                  <CircularProgress
                    size={24}
                    className={classes.loaderBranco}
                  />
                ) : (
                  <></>
                )}
              </Box>
            )}

            {pdf.status ? (
              <CarregandoPDF>Gerando PDF, por favor aguarde...</CarregandoPDF>
            ) : (
              <></>
            )}

            <Box
              style={
                pdf.status ? { position: "absolute", marginTop: "-3500px" } : {}
              }
            >
              <Grid
                style={{
                  width: pdf.status ? 1780 : "auto",
                  height: pdf.status ? 190 : "auto"
                }}
                container
                spacing={3}
                className="printDiv mb-2"
              >
                <Grid item lg={4} xs={12}>
                  <TotalCativo
                    className={pdf.status ? classes.boxSuperiorEconomia : ""}
                  >
                    <Icone>
                      <IconeImagem
                        src={
                          pdf.status ? totalCativoIconePreto : totalCativoIcone
                        }
                      />
                    </Icone>
                    <Texto>
                      <Legenda>Total custo cativo (R$)</Legenda>
                      <Valor>
                        {totalCustoCativo?.toLocaleString("pt-br", {
                          style: "decimal",
                          currency: "BRL",
                          maximumFractionDigits: 0,
                          minimumFractionDigits: 0
                        })}
                      </Valor>
                    </Texto>
                  </TotalCativo>
                </Grid>
                <Grid item lg={4} xs={12}>
                  <TotalLivre
                    className={pdf.status ? classes.boxSuperiorEconomia : ""}
                  >
                    <Icone>
                      <IconeImagem
                        src={
                          pdf.status
                            ? totalCustoLivreIconePreto
                            : totalCustoLivreIcone
                        }
                      />
                    </Icone>
                    <Texto>
                      <Legenda>Total custo livre (R$)</Legenda>
                      <Valor>
                        {totalCustoLivre?.toLocaleString("pt-br", {
                          style: "decimal",
                          currency: "BRL",
                          maximumFractionDigits: 0,
                          minimumFractionDigits: 0
                        })}
                      </Valor>
                    </Texto>
                  </TotalLivre>
                </Grid>
                <Grid item lg={4} xs={12}>
                  <TotalEconomia
                    className={pdf.status ? classes.boxSuperiorEconomia : ""}
                  >
                    <Icone>
                      <IconeImagem
                        src={
                          pdf.status
                            ? totalEconomiaIconePreto
                            : totalEconomiaIcone
                        }
                      />
                    </Icone>
                    <Texto>
                      <Legenda>Total economia (R$)</Legenda>
                      <Valor>
                        {totalEconomia?.toLocaleString("pt-br", {
                          style: "decimal",
                          currency: "BRL",
                          maximumFractionDigits: 0,
                          minimumFractionDigits: 0
                        })}
                        {descontoPercentual &&
                          descontoPercentual !== "NAN" &&
                          ` (${formataPercentual(descontoPercentual || "")})`}
                      </Valor>
                    </Texto>
                  </TotalEconomia>
                </Grid>
              </Grid>
              <Grid container className={mobile ? "mb-3 mt-3" : "mb-3"}>
                <Grid item md={12} xs={12} lg={12} className="printDiv">
                  <EconomiaMensal pdfStatus={pdf} status={pdf} />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item md={12} xs={12} className="printDiv">
                  <Historico {...pdf} />
                </Grid>
              </Grid>
            </Box>
          </Loader>
        </Box>
      )}
    </>
  );
};

export default Economia;
