import React, { useState, useEffect } from "react";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Grid from "@mui/material/Grid";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import PropTypes from "prop-types";
import { InserirValorDominio } from "../../submodules/DeltaTarifa/servicos/validacaoDominios";
import { store } from "global/redux";
import { alertaExibir } from "global/redux/modulos/alertas/actions";

// Componentes
import TabPanel from "../tabPanel";
import Botao from "../botao";
import ModalConfirmacao from "../modalConfirmacao";

// Styles
import { useStyles } from "./style";
import { Base } from "../cores";

const ValidacaoDominios = ({
  onConfirmar,
  onCancelar,
  item,
  cabecalhoValidacao
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [aberto, setAberto] = useState(false);
  const [modalConfirmacao, setModalConfirmacao] = useState(false);
  const [mensagemModalConfirmacao, setMensagemModalConfirmacao] = useState("");
  const [objValidaDominio, setObjValidaDominio] = useState({
    contexto: "",
    entidades: []
  });

  useEffect(() => {
    setObjValidaDominio(item);
    setAberto(item !== null && item.contexto !== "");
  }, [item]);

  const validarValores = (_entidade) => {
    let totalValores = 0;
    let totalDesmarcado = 0;
    _entidade.atributos.forEach((atributo) => {
      totalValores += atributo.valores.length;
      totalDesmarcado += atributo.valores.filter((x) => x.checked !== true)
        .length;
    });
    return {
      totalValores: totalValores,
      totalDesmarcado: totalDesmarcado
    };
  };

  const onClose = () => {
    setAberto(false);
    onCancelar();
  };

  const onSave = async (isValidate) => {
    let validateError = false;
    let totalValores = 0;
    let totalDesmarcado = 0;
    const newObj = {
      contexto: objValidaDominio.contexto,
      entidades: []
    };

    objValidaDominio.entidades.forEach((entidade) => {
      const indicadoresValidacao = validarValores(entidade);
      totalValores += indicadoresValidacao.totalValores;
      totalDesmarcado += indicadoresValidacao.totalDesmarcado;
    });

    if (isValidate) {
      objValidaDominio.entidades.forEach((entidade) => {
        const indicadoresValidacao = validarValores(entidade);
        if (
          indicadoresValidacao.totalDesmarcado !== 0 &&
          validateError === false
        ) {
          validateError = true;
          const index = objValidaDominio.entidades.indexOf(entidade);
          setValue(index);
        }
      });

      if (totalDesmarcado !== 0) {
        const totalSelecionados = totalValores - totalDesmarcado;
        setMensagemModalConfirmacao(
          `${totalSelecionados} de ${totalValores} valores foram selecionados para registrar como válidos.`
        );
        setModalConfirmacao(true);
      }
    }

    if (!validateError) {
      objValidaDominio.entidades.forEach((entidade) => {
        const entidadeObj = {
          entidade: entidade.entidade,
          atributos: []
        };
        entidade.atributos.forEach((atributo) => {
          const atributoObj = {
            id: atributo.id,
            atributo: atributo.atributo,
            valores: []
          };
          atributo.valores.forEach((valor) => {
            if (valor.checked) {
              atributoObj.valores.push({
                valor: valor.valor
              });
            }
          });
          if (atributoObj.valores.length > 0) {
            entidadeObj.atributos.push(atributoObj);
          }
        });
        if (entidadeObj.atributos.length > 0) {
          newObj.entidades.push(entidadeObj);
        }
      });

      try {
        const response = await InserirValorDominio(newObj);
        setModalConfirmacao(false);
        setAberto(false);
        onConfirmar({
          totalValores: totalValores,
          totalDesmarcado: totalDesmarcado,
          responseInsert: response.data
        });
      } catch (error) {
        console.info(error);
        setModalConfirmacao(false);
        store.dispatch(
          alertaExibir({
            tipo: "danger",
            mensagem: "Não foi possivel inserir os valores informados!"
          })
        );
      }
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const handleChangeCheck = (_contexto, _entidade, _atributo, _valor) => (
    event
  ) => {
    const valor = _atributo.valores.filter((x) => x.valor === _valor.valor);
    valor[0].checked = event.target.checked;

    const newObj = {
      contexto: _contexto,
      entidades: []
    };
    newObj.entidades = objValidaDominio.entidades;
    setObjValidaDominio(newObj);
  };

  const handleChangeCheckFull = (_contexto, _entidade) => (event) => {
    objValidaDominio.entidades
      .filter((x) => x.entidade === _entidade.entidade)
      .forEach((entidade) => {
        entidade.atributos.forEach((atributo) => {
          atributo.valores.forEach((valor) => {
            valor.checked = event.target.checked;
          });
        });
      });
    const newObj = {
      contexto: _contexto,
      entidades: []
    };
    newObj.entidades = objValidaDominio.entidades;
    setObjValidaDominio(newObj);
  };

  return (
    <div className={classes.root} style={!aberto ? { display: "none" } : {}}>
      <ModalConfirmacao
        item={modalConfirmacao}
        onConfirmar={() => onSave(false)}
        mensagem={mensagemModalConfirmacao}
        onCancelar={() => setModalConfirmacao(false)}
      />
      <div className={classes.titleValidation}>
        <h6>
          <b>Validação de valores para </b>
          <i>
            <b>{`${cabecalhoValidacao}`}</b>
          </i>
        </h6>
        <h6>
          <b>A seguir estão os valores não reconhecidos durante a validação.</b>
        </h6>
        <h6>
          <b>
            Selecione aqueles que você quer que o sistema passe a reconhecer
            como válidos.
          </b>
        </h6>
      </div>

      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          {objValidaDominio !== null &&
            objValidaDominio.entidades.length > 0 &&
            objValidaDominio.entidades.map((entidade, index) => {
              return (
                <Tab
                  label={entidade.entidade}
                  id={`full-width-tab-${index}`}
                  aria-controls={`full-width-tabpanel-${index}`}
                />
              );
            })}
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        {objValidaDominio !== null &&
          objValidaDominio.entidades.length > 0 &&
          objValidaDominio.entidades.map((entidade, index) => {
            return (
              <TabPanel value={value} index={index} dir={theme.direction}>
                <Grid
                  container
                  spacing={2}
                  className={classes.container}
                  justifyContent="left"
                >
                  {entidade !== null &&
                    entidade.atributos.length > 0 &&
                    entidade.atributos.map((atributo) => {
                      return (
                        <Grid item sm={3} justifyContent="center">
                          <Grid
                            container
                            spacing={2}
                            className={classes.container}
                          >
                            <Grid item sm={12}>
                              <FormControl variant="standard" component="fieldset" className={classes.formControl}>
                                <FormLabel
                                  className={classes.lineHeight}
                                  component="legend"
                                >
                                  Valores não encontrados para o atributo
                                  <b>{` ${atributo.atributo}`}</b>
                                </FormLabel>
                                <FormGroup className={classes.listValues}>
                                  {atributo !== null &&
                                    atributo.valores.length > 0 &&
                                    atributo.valores.map((valor) => {
                                      return (
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              className={classes.checkColor}
                                              onChange={handleChangeCheck(
                                                objValidaDominio.contexto,
                                                entidade,
                                                atributo,
                                                valor
                                              )}
                                              name={valor.valor}
                                              checked={valor.checked}
                                            />
                                          }
                                          label={valor.valor}
                                        />
                                      );
                                    })}
                                </FormGroup>
                              </FormControl>
                            </Grid>
                          </Grid>
                        </Grid>
                      );
                    })}
                  <Grid item sm={3} justifyContent="center">
                    <Grid container spacing={2} className={classes.container}>
                      <Grid item sm={12}>
                        <FormControl variant="standard" component="fieldset" className={classes.formControl}>
                          <FormGroup className={classes.listValues}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  onChange={handleChangeCheckFull(
                                    objValidaDominio.contexto,
                                    entidade
                                  )}
                                  name={entidade.entidade}
                                />
                              }
                              label="Selecionar Todos"
                            />
                          </FormGroup>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </TabPanel>
            );
          })}
      </SwipeableViews>
      <Grid item sm={12}>
        <Grid
          container
          spacing={5}
          className={classes.container}
          justifyContent="center"
        >
          <Grid item sm={3}>
            <Botao
              type="button"
              color={Base.Mango}
              label="Cancelar"
              onClick={onClose}
              className={classes.buttonCustom}
            />
          </Grid>
          <Grid item sm={3}>
            <Botao
              type="button"
              color={Base.Mango}
              label="Salvar"
              onClick={() => onSave(true)}
              className={classes.button}
            />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

ValidacaoDominios.propTypes = {
  onConfirmar: PropTypes.oneOfType([PropTypes.func]),
  onCancelar: PropTypes.oneOfType([PropTypes.func]),
  item: PropTypes.oneOfType([PropTypes.any]),
  cabecalhoValidacao: PropTypes.oneOfType([PropTypes.string])
};

ValidacaoDominios.defaultProps = {
  onConfirmar: () => {},
  onCancelar: () => {},
  item: () => {},
  cabecalhoValidacao: () => ""
};

export default ValidacaoDominios;
