import RetornoEndpointDto from "../../../../../global/dto/retornoEndpointDto";
import { store } from "../../../../../global/redux";
import { alertaExibir } from "../../../../../global/redux/modulos/alertas/actions";
import { obterDataMigracao } from "servicos/demandaAtendServico";

export default class AprovadoHelper {
  static async obterData(protocoloDemanda) {
    const resultado = await obterDataMigracao(protocoloDemanda);

    if (!resultado.data || resultado.data === null || resultado.status === 204)
      return RetornoEndpointDto.Erro(
        "Ocorreu uma falha ao obter a data de migração!"
      );

    return RetornoEndpointDto.Sucesso(
      "Dados obtidos com sucesso!",
      resultado.data
    );
  }
  catch(ex) {
    return RetornoEndpointDto.Erro(
      "Ocorreu uma falha ao obter a data de migração!"
    );
  }

  static exibirErro(mensagem) {
    store.dispatch(
      alertaExibir({
        tipo: "danger",
        mensagem
      })
    );
  }

  static exibirSucesso(mensagem) {
    store.dispatch(
      alertaExibir({
        tipo: "success",
        mensagem
      })
    );
  }
}
