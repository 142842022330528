import React from "react";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";

// Styles
import { useStyles, Icone } from "./style";

const CardDownload = React.forwardRef((props, ref) => {
  const { icon, link, text, onClickDownload } = props;

  const classes = useStyles();

  return (
    <Card className={classes.root} border={3}>
      {/* <Link
        href={link}
        onClick={() => onClickDownload()}
        color="inherit"
        className={classes.noHover}
      > */}
        <CardContent>
          <Typography className={classes.content} variant="h5" component="h2">
            <Icone className="ml-4" src={icon} />
            <span className="ml-2">{text}</span>
          </Typography>
        </CardContent>
      {/* </Link> */}
    </Card>
  );
});

CardDownload.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.any]),
  link: PropTypes.string,
  text: PropTypes.string
};

CardDownload.defaultProps = {
  icon: false,
  link: "#",
  text: ""
};

export default CardDownload;
