import React from "react";
import PropTypes from "prop-types";

// Componentes
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Grid } from "@mui/material";
import IOSStyledSwitch from "./IOSStyledSwitch";

const IOSStyleSwitch = IOSStyledSwitch;

const MaterialSwitch = React.forwardRef((props, ref) => {
  const {
    label,
    labelPlacement,
    id,
    name,
    checked,
    disabled,
    onChange,
    exibirLabels
  } = props;

  const handleChange = (event) => {
    onChange(event.target.checked);
  };

  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Grid
            component="label"
            container
            justifyContent="flex-start"
            alignItems="center"
            className="mt-1 mb-0 text-left"
          >
            <Grid item>
              <IOSStyleSwitch
                checked={checked}
                onChange={handleChange}
                disabled={disabled}
                name={name}
                id={id}
                reference={ref}
              />
            </Grid>
            {exibirLabels && <Grid item ml={1}>{checked ? "Sim" : "Não"}</Grid>}
          </Grid>
        }
        className="m-0 align-items-start"
        label={label}
        labelPlacement={labelPlacement}
      />
    </FormGroup>
  );
});

MaterialSwitch.propTypes = {
  label: PropTypes.string,
  labelPlacement: PropTypes.oneOfType([PropTypes.any]),
  id: PropTypes.string,
  name: PropTypes.string,
  checked: PropTypes.oneOfType([PropTypes.any]),
  onChange: PropTypes.oneOfType([PropTypes.func]),
  exibirLabels: PropTypes.bool,
  disabled: PropTypes.bool
};

MaterialSwitch.defaultProps = {
  label: "",
  labelPlacement: "top",
  id: "",
  name: "",
  checked: false,
  onChange: () => {},
  exibirLabels: false,
  disabled: false
};

export default MaterialSwitch;
