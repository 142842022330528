import React from "react";
import moment from "moment";

// Ícones
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";

// Styles
import { Wrapper, useStyles, Mes, Ola } from "./style";

const Data = (props) => {
  const classes = useStyles();

  return (
    <Wrapper className={classes.wrapper}>
      <Ola>{`Olá, ${props.usuario}`}</Ola>
      <CalendarTodayIcon className={classes.icon} />
      <Mes>
        {`${moment().format("D MMM")}`}
        &nbsp;
      </Mes>
      {moment().format("YYYY")}
    </Wrapper>
  );
};

export default Data;
