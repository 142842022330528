import { makeStyles } from '@mui/styles';
import styled from "styled-components";
import theme from 'themes';

export const useStyles = makeStyles({
  fundoCelula: {
    backgroundColor: "transparent",
    width: "100%",
    height: "100%"
  },
  fundoBotoes: {
    backgroundColor: theme.color.bgTabela,
    padding: 10
  },
  fundoPendente: {
    backgroundColor: `${theme.color.bgPendente}`,
  },
  fundoAtrasado: {
    backgroundColor: `${theme.color.bgAtrasado}`,
  },
  fundoPago: {
    backgroundColor: `${theme.color.bgPago}`,
  },
  fundoPagoParcial: {
    backgroundColor: `${theme.color.bgPagoParcial}`,
  },
  fundoVencido: {
    backgroundColor: `${theme.color.bgVencido}`,
  },
  textoStatus: {
    paddingLeft: 15
  },
  botaoCentral: {
    borderLeft: `3px ${theme.color.secondaryBackgroud} solid`,
    justifyContent: "center",
    justifyItems: "center",
    alignContent: "center",
    alignItems: "center",
    textAlign: "center"
  },
  botao: {
    color: `${theme.color.secondaryText} !important`,
    width: "100%",
    height: "100%",
    justifyContent: "center",
    justifyItems: "center",
    alignContent: "center",
    alignItems: "center"
  },
  inativo: {
    opacity: 0.5
  },
  ativo: {
    opacity: 1
  }
});

export const Icone = styled.img`
  margin-left: 2px;
  max-width: 18px;
  object-fit: cover;
`;
