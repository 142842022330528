import React, { useMemo, useState, useEffect } from "react";
import { Box, Button, Grid } from "@mui/material";
import { Delete, Edit, Cancel } from "@mui/icons-material";
import DataTable from "react-data-table-component";
import FiltroDto from "./dto/filtroDto";
import FiltrosMemberGetMember from "../Componentes/Filtros";
import Colunas from "./Colunas";
import { customStyles, useStyles } from "./style";
import TabelaSemDados from "../Componentes/TabelaSemDados";
import { SelectArredondado, Botao } from "../../../componentes";
import InputData from "../Componentes/InputData";
import MemberGetMemberFatura from "./helper";

import ModalMensagemErro from "../../../componentes/ModalMensagemErro";
import ModalLuz from "../../../componentes/modalLuz";
import InputTexto from "../Componentes/inputTexto";

const FaturaMemberGetMember = () => {
  const classes = useStyles();
  const [dadosFatura, setDadosFatura] = useState([]);
  const [inativarAdd, setInativarAdd] = useState(false);
  const [ultimaAtualizacao, setUltimaAtualizacao] = useState(null);
  const [carregandoFatura, setCarregandoFatura] = useState(false);
  const [idSelecionado, setIdSelecionado] = useState(null);
  const [dadosEnviarFatura, setDadosEnviarFatura] = useState({
    id: 0,
    descricao: "",
    ordemPrioritaria: null,
    elasticidadeBeneficioId: null,
    valorFaturaDe: null,
    valorFaturaAte: null,
    valorIndicador: null,
    valorAmigoIndicado: null,
    dataInicioVigencia: null,
    dataFimVigencia: null,
    status: "S",
    enabled: false
  });
  const [mensagemModal, setMensagemModal] = useState("");
  const [modalAcao, setModalAcao] = useState(false);
  const [mensagemModalVigencia, setMensagemModalVigencia] = useState("");
  const [modalAcaoVigencia, setModalAcaoVigencia] = useState(false);
  const [podeEditar, setPodeEditar] = useState(true);
  const [modalEditar, setModalEditar] = useState(false);
  const [atualizaStatusId, setAtualizaStatusId] = useState(null);

  const handleDadosFatura = (dados) => {
    return dados?.map((item) => ({
      descricao: item?.descricao,
      ordemPrioritaria: item?.ordemPrioritaria,
      elasticidadeBeneficioId: item?.elasticidadeBeneficioId,
      valorFaturaDe: item?.valorFaturaDe,
      valorFaturaAte: item?.valorFaturaAte,
      valorIndicador: item?.valorIndicador,
      valorAmigoIndicado: item?.valorAmigoIndicado,
      dataInicioVigencia: item?.dataInicioVigencia,
      dataFimVigencia: item?.dataFimVigencia,
      status: item?.status,
      enabled: item?.enabled,
      id: item.id
    }));
  };

  const obterBeneficioFatura = async () => {
    const filtro = new FiltroDto(null, "S", "", "");

    const {
      data,
      sucesso,
      mensagem
    } = await MemberGetMemberFatura.obterBeneficioFatura(
      filtro,
      setCarregandoFatura
    );

    if (!sucesso) {
      MemberGetMemberFatura.exibirErro(mensagem);
      setCarregandoFatura(false);
      return;
    }

    const dadosFaturaFormatado = handleDadosFatura(data || []);
    setUltimaAtualizacao(data?.[0]?.dataAtualizacao);
    setDadosFatura(dadosFaturaFormatado);
    setIdSelecionado(null);
  };

  useEffect(() => {
    obterBeneficioFatura();
  }, []);

  const handleChangeData = (valor, id, nameInput) => {
    setIdSelecionado(id);
    setDadosEnviarFatura({
      ...dadosEnviarFatura,
      [nameInput]: window.moment(valor).isValid()
        ? window.moment(valor).format()
        : null
    });
  };

  const formataValor = (valor) => {
    if (typeof valor === "number") return valor;
    if (!valor) return "";
    return valor
      ? parseFloat(
          String(valor)
            .trim()
            .replace(",00", "")
            .replace("R$", "")
            .replace(" ", "")
            .replaceAll(".", "")
            .replace(",", ".")
        )
      : valor;
  };

  const handleChange = (e, id) => {
    setIdSelecionado(id);
    setDadosEnviarFatura({
      ...dadosEnviarFatura,
      [e?.target?.name]: formataValor(e?.target?.value)
    });
  };

  const atualizaStatusBeneficioFatura = async () => {
    const model = {
      idBeneficioFatura: atualizaStatusId,
      Status: "N"
    };

    const {
      data,
      sucesso,
      mensagem
    } = await MemberGetMemberFatura.atualizaStatusBeneficioFatura(
      model,
      setCarregandoFatura
    );

    if (!sucesso) {
      MemberGetMemberFatura.exibirErro(mensagem);
      setCarregandoFatura(false);
      setModalEditar(false);
      return;
    }

    setModalEditar(false);
    obterBeneficioFatura();
  };

  const handleInativar = () => {
    atualizaStatusBeneficioFatura(dadosEnviarFatura);
  };

  const removerLinha = (dados) => {
    if (dados.id !== 0) {
      setModalEditar(true);
      setAtualizaStatusId(dados.id);
      return;
    }

    const linha = dadosFatura?.filter((item) => item.id !== 0);

    setDadosFatura(linha);

    setInativarAdd(false);
    setPodeEditar(true);
    setDadosEnviarFatura({
      id: 0,
      descricao: "",
      ordemPrioritaria: null,
      elasticidadeBeneficioId: null,
      valorFaturaDe: null,
      valorFaturaAte: null,
      valorIndicador: null,
      valorAmigoIndicado: null,
      dataInicioVigencia: null,
      dataFimVigencia: null,
      status: "S",
      enabled: false
    });
  };

  const editarLinha = (item) => {
    setIdSelecionado(item.id);
    setPodeEditar(false);

    setDadosEnviarFatura({
      id: item.id,
      descricao: item.descricao,
      ordemPrioritaria: item.ordemPrioritaria,
      elasticidadeBeneficioId: item.elasticidadeBeneficioId,
      valorFaturaDe: item.valorFaturaDe,
      valorFaturaAte: item.valorFaturaAte,
      valorIndicador: item.valorIndicador,
      valorAmigoIndicado: item.valorAmigoIndicado,
      dataInicioVigencia: window.moment(item.dataInicioVigencia).format(),
      dataFimVigencia: window.moment(item.dataFimVigencia).format(),
      status: item.status,
      enabled: false
    });
  };

  const cancelarEdicao = () => {
    setInativarAdd(false);
    setPodeEditar(true);
    setIdSelecionado(null);
    obterBeneficioFatura();
  };

  const dataFormatada = useMemo(() => {
    const dadosFormatados =
      dadosFatura &&
      dadosFatura?.map((item) => {
        return {
          valorFaturaDe: (
            <InputTexto
              name="valorFaturaDe"
              id="valorFaturaDe"
              defaultValue={
                item?.valorFaturaDe === null ? "" : item?.valorFaturaDe
              }
              disabled={!(item.id === idSelecionado && !podeEditar)}
              onBlur={(e) => handleChange(e, item.id)}
              className={classes.formControl}
            />
          ),
          valorFaturaAte: (
            <InputTexto
              name="valorFaturaAte"
              id="valorFaturaAte"
              defaultValue={
                item?.valorFaturaAte === null ? "" : item?.valorFaturaAte
              }
              disabled={!(item.id === idSelecionado && !podeEditar)}
              onBlur={(e) => handleChange(e, item.id)}
              className={classes.formControl}
            />
          ),
          dataInicioVigencia: (
            <InputData
              type="text"
              key={item?.id}
              id={item?.id}
              name="dataInicioVigencia"
              label=""
              customValue={
                item?.dataInicioVigencia
                  ? new Date(item?.dataInicioVigencia)
                  : null
              }
              onChange={(data) =>
                handleChangeData(data, item?.id, "dataInicioVigencia")
              }
              errors={!item.dataInicioVigencia}
              disabled={!(item.id === idSelecionado && !podeEditar)}
              className={classes}
            />
          ),
          dataFimVigencia: (
            <InputData
              type="text"
              key={item?.id}
              id={item?.id}
              name="dataFimVigencia"
              label=""
              customValue={
                item.dataFimVigencia ? new Date(item.dataFimVigencia) : null
              }
              onChange={(data) =>
                handleChangeData(data, item?.id, "dataFimVigencia")
              }
              errors={!item.dataFim}
              minDate={window
                .moment(dadosEnviarFatura?.dataInicioVigencia)
                .toDate()}
              disabled={!(item.id === idSelecionado && !podeEditar)}
              className={classes}
            />
          ),
          valorAmigoIndicado: (
            <InputTexto
              name="valorAmigoIndicado"
              id="valorAmigoIndicado"
              defaultValue={
                item?.valorAmigoIndicado === null
                  ? ""
                  : item?.valorAmigoIndicado
              }
              disabled={!(item.id === idSelecionado && !podeEditar)}
              onBlur={(e) => handleChange(e, item.id)}
              className={classes.formControl}
            />
          ),
          valorIndicador: (
            <InputTexto
              name="valorIndicador"
              id="valorIndicador"
              defaultValue={
                item?.valorIndicador === null ? "" : item?.valorIndicador
              }
              disabled={!(item.id === idSelecionado && !podeEditar)}
              onBlur={(e) => handleChange(e, item.id)}
              className={classes.formControl}
            />
          ),
          ordemPrioritaria: (
            <SelectArredondado
              id={item.id}
              name="ordemPrioritaria"
              valueKey="id"
              valueName="value"
              dataSource={[
                { id: 1, value: "1" },
                { id: 2, value: "2" },
                { id: 3, value: "3" }
              ]}
              label=""
              value={
                item?.id === idSelecionado
                  ? dadosEnviarFatura?.ordemPrioritaria
                  : item?.ordemPrioritaria
              }
              onChange={(event) => handleChange(event, item?.id)}
              disabled={!(item.id === idSelecionado && !podeEditar)}
              inputProps={{ "aria-label": "controlled" }}
              className={classes.formControl}
            />
          ),
          acoes: (
            <Box textAlign="center" key={item.id}>
              <Button
                name={item?.id}
                id={item?.id}
                className={`${classes.botao} ${classes.ativo}`}
                disabled={item.id === 0 ? false : inativarAdd}
              >
                {item.id === 0 ? (
                  <Delete
                    onClick={() => removerLinha(item)}
                    htmlColor="#332053"
                  />
                ) : (
                  <Delete
                    onClick={() => removerLinha(item)}
                    htmlColor={inativarAdd ? "" : "#332053"}
                  />
                )}
              </Button>
              <Button
                name={item?.id}
                id={item?.id}
                className={`${classes.botao} ${classes.ativo}`}
                disabled={inativarAdd}
              >
                {idSelecionado === item.id ? (
                  <Cancel
                    htmlColor={inativarAdd ? "" : "#332053"}
                    onClick={() => {
                      cancelarEdicao(item);
                    }}
                  />
                ) : (
                  <Edit
                    onClick={() => editarLinha(item)}
                    htmlColor={inativarAdd ? "" : "#332053"}
                  />
                )}
              </Button>
            </Box>
          )
        };
      });

    return dadosFormatados;
  }, [dadosFatura, dadosEnviarFatura, podeEditar, inativarAdd, idSelecionado]);

  const adicionarLinha = () => {
    setInativarAdd(true);
    setPodeEditar(false);
    setIdSelecionado(0);

    const linha = {
      id: 0,
      descricao: "",
      ordemPrioritaria: null,
      elasticidadeBeneficioId: null,
      valorFaturaDe: null,
      valorFaturaAte: null,
      valorIndicador: null,
      valorAmigoIndicado: null,
      dataInicioVigencia: null,
      dataFimVigencia: null,
      status: "S",
      enabled: false
    };

    setDadosEnviarFatura({
      id: 0,
      descricao: "",
      ordemPrioritaria: null,
      elasticidadeBeneficioId: null,
      valorFaturaDe: null,
      valorFaturaAte: null,
      valorIndicador: null,
      valorAmigoIndicado: null,
      dataInicioVigencia: null,
      dataFimVigencia: null,
      status: "S",
      enabled: false
    });

    setDadosFatura((prevState) => [linha, ...prevState]);
  };

  const verificarCampos = (dados) => {
    if (
      dados?.ordemPrioritaria &&
      dados?.valorFaturaDe &&
      dados?.valorFaturaAte &&
      dados?.valorIndicador &&
      dados?.valorAmigoIndicado &&
      window.moment(dados?.dataInicioVigencia).isValid() &&
      window.moment(dados?.dataFimVigencia).isValid()
    ) {
      return false;
    }

    return true;
  };

  const salvarBeneficioFatura = async (dadosPreenchidos) => {
    const model = {
      elasticidadeBeneficioId: 1,
      valorFaturaDe: dadosPreenchidos?.valorFaturaDe,
      valorFaturaAte: dadosPreenchidos?.valorFaturaAte,
      valorIndicador: dadosPreenchidos?.valorIndicador,
      valorAmigoIndicado: dadosPreenchidos?.valorAmigoIndicado,
      dataInicioVigencia: window
        .moment(dadosPreenchidos?.dataInicioVigencia)
        .format("YYYY-MM-DD"),
      dataFimVigencia: window
        .moment(dadosPreenchidos?.dataFimVigencia)
        .format("YYYY-MM-DD"),
      status: dadosPreenchidos?.status,
      ordemPrioritaria: dadosPreenchidos?.ordemPrioritaria
    };

    const {
      data,
      sucesso,
      mensagem
    } = await MemberGetMemberFatura.salvarBeneficioFatura(
      dadosPreenchidos?.id,
      model,
      setCarregandoFatura
    );

    if (!sucesso) {
      MemberGetMemberFatura.exibirErro(mensagem);
      setCarregandoFatura(false);
      return;
    }

    obterBeneficioFatura();
    setInativarAdd(false);
    setPodeEditar(true);
    setModalEditar(false);
  };

  const verificarVigencia = (dadosEnviar, dadosSemAdicao) => {
    const ranges = dadosSemAdicao.filter(
      (item) =>
        (item.valorFaturaDe >= dadosEnviar.valorFaturaDe &&
          item.valorFaturaAte <= dadosEnviar.valorFaturaAte) ||
        (dadosEnviar.valorFaturaDe >= item?.valorFaturaDe &&
          dadosEnviar.valorFaturaDe <= item?.valorFaturaAte) ||
        (dadosEnviar.valorFaturaAte <= item?.valorFaturaAte &&
          dadosEnviar.valorFaturaAte >= item?.valorFaturaDe)
    );

    const dataInicio = ranges.map((item) => new Date(item.dataInicioVigencia));
    const dataFIm = ranges.map((item) => new Date(item.dataFimVigencia));

    const datas = [...dataInicio, ...dataFIm];
    const dataMenor = new Date(Math.min(...datas));
    const dataMaior = new Date(Math.max(...datas));

    if (
      window.moment(dadosEnviar?.dataInicioVigencia).format("YYYY-MM-DD") <
        window.moment(dataMenor).format("YYYY-MM-DD") &&
      window.moment(dadosEnviar?.dataFimVigencia).format("YYYY-MM-DD") >
        window.moment(dataMaior).format("YYYY-MM-DD")
    ) {
      setModalAcao(true);
      setMensagemModal(
        "Data vigência inicio não pode ser menor e data vigência fim não pode ser maior!"
      );
      return;
    }

    let rangeExisteData = false;

    ranges.map((item) => {
      if (
        (window.moment(dadosEnviar?.dataInicioVigencia).format("YYYY-MM-DD") >=
          window.moment(item.dataInicioVigencia).format("YYYY-MM-DD") &&
          window.moment(dadosEnviar?.dataInicioVigencia).format("YYYY-MM-DD") <=
            window.moment(item.dataFimVigencia).format("YYYY-MM-DD")) ||
        (window.moment(dadosEnviar?.dataFimVigencia).format("YYYY-MM-DD") <=
          window.moment(item.dataFimVigencia).format("YYYY-MM-DD") &&
          window.moment(dadosEnviar?.dataFimVigencia).format("YYYY-MM-DD") >=
            window.moment(item.dataInicioVigencia).format("YYYY-MM-DD"))
      ) {
        rangeExisteData = true;
      } else if (
        window.moment(item.dataInicioVigencia).format("YYYY-MM-DD") >=
          window.moment(dadosEnviar?.dataInicioVigencia).format("YYYY-MM-DD") &&
        window.moment(item.dataFimVigencia).format("YYYY-MM-DD") <=
          window.moment(dadosEnviar?.dataFimVigencia).format("YYYY-MM-DD")
      ) {
        rangeExisteData = true;
      }
    });

    // eslint-disable-next-line consistent-return
    return rangeExisteData;
  };

  const salvarValorConta = () => {
    if (dadosEnviarFatura.id === null) return;
    const dadosPreenchidos = verificarCampos(dadosEnviarFatura);

    if (dadosPreenchidos) {
      setModalAcao(dadosPreenchidos);
      setMensagemModal("Todos os campos são obrigatórios!");
      return;
    }

    if (dadosEnviarFatura?.valorFaturaDe > dadosEnviarFatura?.valorFaturaAte) {
      setModalAcao(true);
      setMensagemModal("Valor A partir não pode ser maior que o Até!");
      return;
    }

    if (
      window
        .moment(dadosEnviarFatura?.dataInicioVigencia)
        .isAfter(dadosEnviarFatura?.dataFimVigencia)
    ) {
      setModalAcao(true);
      setMensagemModal(
        "Valor data inicio não pode ser maior que o da data fim!"
      );
      return;
    }

    let dadosSemAdicao = [];
    if (dadosEnviarFatura.id === 0) {
      dadosSemAdicao = dadosFatura.filter((item) => item?.id !== 0);
    } else {
      dadosSemAdicao = dadosFatura.filter(
        (item) => item?.id !== dadosEnviarFatura.id
      );
    }

    const existeVigencia = verificarVigencia(dadosEnviarFatura, dadosSemAdicao);

    if (existeVigencia) {
      setModalAcaoVigencia(existeVigencia);
      setMensagemModalVigencia("Valores e períodos já existentes!");
      return;
    }

    salvarBeneficioFatura(dadosEnviarFatura);
  };

  const handleMensagemCampos = (mensagem) => {
    return (
      <Box component="div" fontWeight="700" textAlign="center">
        {mensagem}
      </Box>
    );
  };

  return (
    <Grid container>
      <ModalMensagemErro
        item={modalAcao}
        titulo=""
        mensagem={handleMensagemCampos(mensagemModal)}
        onCancelar={() => {
          setModalAcao(false);
          setMensagemModal("");
        }}
        onConfirmar={() => {}}
        texto="Tentar novamente"
      />
      <ModalMensagemErro
        item={modalAcaoVigencia}
        mensagem={mensagemModalVigencia}
        onCancelar={() => {
          setModalAcaoVigencia(false);
          setMensagemModalVigencia("");
        }}
        onConfirmar={() => {}}
        texto="Tentar novamente"
      />
      <ModalLuz
        item={modalEditar}
        mensagem="Têm certeza que deseja, inativar?"
        onCancelar={() => {
          setModalEditar(false);
          setAtualizaStatusId(null);
        }}
        onConfirmar={() => handleInativar()}
      />

      <FiltrosMemberGetMember
        adicionarLinha={adicionarLinha}
        ultimaAtualizacao={
          ultimaAtualizacao
            ? window.moment(ultimaAtualizacao).format("DD/MM/yyyy HH:mm")
            : ""
        }
        inativarAdd={inativarAdd || !podeEditar}
        filtros="FaturaMemberGetMember"
      />
      <Grid item xs={12} className="mt-3">
        <DataTable
          columns={Colunas}
          data={dataFormatada}
          theme="DeltaEnergia"
          persistTableHead
          customStyles={customStyles}
          noDataComponent={<TabelaSemDados />}
          noHeader
          responsive
          progressPending={carregandoFatura}
          dense
        />
      </Grid>
      <Grid container className="mt-3 w-100">
        <Grid item md={3}>
          <Botao
            label="Salvar"
            onClick={() => salvarValorConta()}
            disabled={!inativarAdd && podeEditar}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FaturaMemberGetMember;
