import { makeStyles } from "@mui/styles";
import { Base } from "../cores";
import theme from "themes";

export const useStyles = makeStyles(() => ({
  formControl: {
    fontFamily: theme.font.family
  },
  select: {
    borderRadius: "4px",
    fontFamily: theme.font.family,
    marginBottom: (props) => props.marginBottom,
    maxHeight: (props) => props.maxHeight,
    maxWidth: (props) => props.maxWidth,
    "& .MuiOutlinedInput-root": {
      fontFamily: theme.font.family,
      "& .MuiSelect-outlined.MuiSelect-outlined": {
        fontFamily: theme.font.family
      },
      "& .MuiOutlinedInput-notchedOutline": {
        fontFamily: theme.font.family
      }
    },
    ".MuiAutocomplete-tag": {
      background: "white"
    }
  },
  selectIcon: {
    color: theme.color.primaryBackgroud,
    marginRight: "4px",
    position: "absolute",
    right: 0,
    top: "calc(50% - 12px)",
    "pointer-events": "none"
  },
  menuIcon: {
    marginRight: "10px"
  },
  adornment: {
    marginRight: "10px"
  },
  checked: {
    color: theme.color.checkButonColor
  },
  box: {
    color: Base.Preto
  }
}));

export const AutoCompleteCustom = {
  root: {
    color: Base.Preto,
    "&$checked": {
      color: theme.color.checkButonColor
    }
  },
  colorPrimary: {
    "&$checked": {
      color: theme.color.checkButonColor
    }
  }
};

export const customStyles = makeStyles(() => ({
  formControl: {
    fontFamily: theme.font.family
  },
  label: {
    fontFamily: theme.font.family,
    margin: 0,
    pointerEvents: "none",
    transform: "translate(15px, 6px) scale(1)",
    zIndex: 1,
    "&.MuiInputLabel-shrink": {
      display: "none"
    }
  },
  select: {
    fontFamily: theme.font.family,
    marginBottom: (props) => props.marginBottom,
    maxHeight: (props) => props.maxHeight,
    maxWidth: (props) => props.maxWidth,
    "& .MuiOutlinedInput-root": {
      borderColor: theme.color.primaryBackgroud,
      borderRadius: "50rem",
      fontFamily: theme.font.family,
      maxHeight: "30px",
      "& .MuiSelect-outlined.MuiSelect-outlined": {
        fontFamily: theme.font.family,
        padding: "10px",
        textAlign: "left"
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: `${theme.color.primaryBackgroud} !important`,
        fontFamily: theme.font.family
      }
    }
  },
  selectIcon: {
    color: theme.color.primaryBackgroud,
    marginRight: "4px",
    position: "absolute",
    right: 0,
    top: "calc(50% - 12px)",
    "pointer-events": "none"
  },
  menuIcon: {
    marginRight: "10px"
  }
}));
