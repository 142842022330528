import React, { useEffect } from "react";
import PropTypes from "prop-types";

// Redux
import { Route } from "react-router-dom";
import { useSelector } from "react-redux";
import { store } from "../../global/redux";
import { salvarRotaAtual } from "../../global/redux/modulos/rotas/actions";
import { alertaRemover } from "../../global/redux/modulos/alertas/actions";

// Componentes
import Alerta from "../alerta";

const Componente = ({ rota }) => {

  store.dispatch(salvarRotaAtual(rota));

  const alertas = useSelector((state) => state.alertas.alertas);

  return (
    <>
      {alertas && alertas?.length
        ? alertas.map((alerta, a) => {
          return (
            <Alerta
              key={a}
              onClose={() => store.dispatch(alertaRemover(alerta))}
              {...alerta}
            />
          );
        })
        : null}      
      <Route
        path={rota.path}
        render={(props) => <rota.component {...props} rotas={rota.rotas} />}
      />
    </>
  );
};

Componente.propTypes = {
  rota: PropTypes.oneOfType([PropTypes.any]).isRequired
};

export default Componente;
