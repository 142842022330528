import { makeStyles } from "@mui/styles";
import { Base } from "../cores";
import theme from "themes";
import { createTheme } from "@mui/material/styles";

const th = createTheme();

export const useStyles = makeStyles(() => ({
  "@global": {
    body: {
      fontFamily: theme.font.family,
      fontSize: "16px !important",
      height: "100% !important"
    },
    ".Mui-error": {
      color: Base.RedError,
      fontSize: "0.75rem"
    },
    ".MuiFormGroup-root": {
      "& label": {
        "& .MuiFormControlLabel-label:hover": {
          background: "transparent"
        },
        "& .Mui-checked": {
          color: `${theme.color.checkButonColor} !important`,
          "& .MuiIconButton-label": {
            color: `${theme.color.secondary} !important`
          },
          "& span": {
            "& div": {
              "& svg": {
                "&:nth-child(1)": {
                  fill: theme.color.radioButton
                },
                "&:nth-child(2)": {
                  fill: theme.color.radioButton
                }
              }
            }
          }
        },
        "& .MuiSwitch-switchBase": {
          color: Base.GreyWater
        },
        "& .MuiSwitch-track": {
          backgroundColor: `${Base.GreyDisabled} !important`
        }
      }
    },
    ".MuiDropzonePreviewList-root": {
      marginBottom: "5px",
      marginTop: "10px",
      "& .MuiChip-outlined": {
        backgroundColor: Base.BlackOpacity70,
        borderRadius: "5px",
        color: `${Base.OffWhite} !important`,
        fontSize: "14px",
        "& .MuiChip-deleteIcon": {
          color: `${Base.OffWhite} !important`
        }
      }
    },
    "#column-opcoes": {
      margin: "0 auto",
      textAlign: "center"
    }
  },
  root: {
    height: "100%",
    [th.breakpoints.up('md')]: {
      display: "flex",
    },
  },
  toolbar: {
    // height: "76px",
    // [theme.breakpoints.up('md')]: {
    //   padding: theme.spacing(2, 5),
    //   height: (props) => (props.hideHeader ? "76px" : "165px")
    // },
  },
  content: {
    backgroundColor: theme.color.bgTheme,
    clear: "both",
    flexGrow: 1,
    height: "auto",
    minHeight: "100vh",
    overflowX: "hidden",
    padding: th.spacing(2),
    [th.breakpoints.up('sm')]: {
      padding: th.spacing(3)
    },
    [th.breakpoints.up('xs')]: {
      padding: th.spacing(2),
    },
    [th.breakpoints.up('md')]: {
      padding: th.spacing(0, 3, 3)
    },
  }
}));
