import styled from "styled-components";
import { makeStyles } from '@mui/styles';
import { Base } from 'componentes/cores';
import { createTheme } from "@mui/material/styles";

const th = createTheme();

export const useStyles = makeStyles(() => ({
  boxLoader: {
    textAlign: 'center',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  overlay: {
    position: 'fixed',
    width: '100%',
    height: '100%',
    top: 0,
    backgroundColor: Base.BlackOpacity70,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [th.breakpoints.up('md')]: {
      width: '85%'
    }
  },
  containerLoader: {
    position: 'fixed'
  },
  imagemLoader: {
    borderRadius: 25,
    display: 'block'
  },
  msgLoading:{
    fontSize: 16,
    textAlign: 'center',
    width: '100%',
    color: `${Base.White} !important`,
    marginBottom: 10
  }
}))
