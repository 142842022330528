import axios from "axios";

// Redux
import { store } from "../global/redux";
import { deslogarUsuario } from "../global/redux/modulos/usuario/actions";
import { useSelector } from "react-redux";
import { responseFatura } from "../global/redux/modulos/log/actions";

// Env
import retornaApiUrl from "../global/configuracoes/env";

//Log
import { montaAtualizaLog, montaEnviaLog } from "./utilsLogs";
import lodash from "lodash";


const obterApi = (microServico, log) => {
 
  const apiUrl = retornaApiUrl(microServico);
  const { usuario } = store.getState();
  var logRequest;

  const Api = axios.create({ baseURL: `${apiUrl}/api/` });

  Api.interceptors.request.use(
    async (config) => {
      config.baseURL = `${apiUrl}/api/`;
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  Api.interceptors.request.use(async (config) => {
    return new Promise((resolve) => setTimeout(() => resolve(config), 200));
  });

  Api.interceptors.request.use(async (config) => {
    logRequest = config?.data;
    const token = usuario?.token ?? false;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  });

  Api.interceptors.response.use(
    (response) => {
      let cotacao_id =  sessionStorage.getItem( "cotacao_id");
      let idDopreLeadNoSalesforce = sessionStorage.getItem("idDoPreLeadNoSalesforce");
      if ( log?.tipo === "atualizaLog" && ((cotacao_id !== undefined && cotacao_id !== null) || (idDopreLeadNoSalesforce !== undefined && idDopreLeadNoSalesforce !== null))) 
      {
        var searchField = cotacao_id ? "cotacao_id" : "idDoPreLeadNoSalesforce"
        var searchContent = cotacao_id ? cotacao_id : idDopreLeadNoSalesforce
        
        if(log?.dadosFatura?.fatura?.statusCodes === 200 ){
        
          montaAtualizaLog({}, log?.dadosFatura, "upload_arquivo", searchField, searchContent,log.index );
        }
        montaAtualizaLog( logRequest, response?.data, log.nestedField, searchField, searchContent,log.index)
      } 
      else if ( log?.tipo === "enviaLog" )
      {
          var mainFields = {};
          mainFields[log.nameMainFields] = (lodash.get(response.data, log.mainFields))
          const res = montaEnviaLog( mainFields, logRequest, response.data.data, log.nestedField, log.messege, log.index, true ) 

      }
      return response;
    },
    (error) => {
      if (error?.response && error?.response?.status === 401) {
        store.dispatch(deslogarUsuario());
        return false;
      }
      return Promise.reject(error);
    }
  );

  return Api;
};

export default obterApi;
