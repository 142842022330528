import styled from "styled-components";
import theme from "../../../themes";

export const StyledSection = styled.section`
		.loader{
    		margin-top: 10px;
			color: black;
		}
		.posicione-figura{
			margin-bottom: 90px;
		}
		.img-captcha{
			margin-top: -110px;	
			transform: scaleY(-1);
			-moz-transform: scaleY(-1);
			-o-transform: scaleY(-1);
			-webkit-transform: scaleY(-1);
			transform: scaleY(-1);
		}

		.images {
			width: 28px !important;
			height: 48px !important;
			min-width: 0px !important;
		}
		.foto {
			cursor: pointer;
		}

		.resetCaptcha{
			cursor: pointer;
		}

		.ui-slider-handle {
			outline: none !important;
			height: 30px !important;
			width: 30px !important;
			border-radius: 50px;
			background: ${theme.color.btOrdBGColor} !important;
			cursor: pointer;
			-webkit-appearance: none;
			margin-top: -10px;
			border:none !important;
			&::before {
				position: absolute !important;
				content: ${(props) => props.url} !important;
				height: 90px !important;
				background-color: transparent !important;
				
				width: 20px !important;
				margin-top: -64px !important;
				
			}
		}

		.ui-slider-handle.sem-content{
			&&:before{
				content: none !import;
				
			}
		  }
		.ui-slider .ui-slider-range {
			background: ${theme.color.btOrdBGColor} !important;
		}

		
`;