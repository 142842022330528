import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import moment from "moment";

// Componentes
import DataTable from "react-data-table-component";
import Pagination from "@mui/material/Pagination";
import { Grid } from "@mui/material";
import PrecosCabecalho from "../PrecosCabecalho";
import TabelaSemDados from "../TabelaSemDados";
import { anos } from "../../anos";
import { meses } from "../../meses";

// Styles
import { useStyles, customStyles, customStylesHistorico } from "./style";

const TabelaHistorico = ({
  linhasAtuais,
  linhasHistorico,
  totalPaginas,
  onChangePagina,
  mes,
  submercado
}) => {
  const classes = useStyles();
  const [historico, setHistorico] = useState([]);
  const [atuais, setAtuais] = useState([]);
  const [colunasHistorico, setColunasHistorico] = useState([]);

  const colunasAtuais = [
    {
      name: <div className={classes.tabelaCelula}>Data</div>,
      selector: (row) => row.data
    },
    {
      name: <div className={classes.tabelaCelula}>Atual</div>,
      selector: (row) => row.precosAtuais
    }
  ];

  const salvarUltimaAtualizacao = (precos) => {
    if (precos?.length) {
      const max = new Date(
        Math.max(...precos.map((e) => new Date(e.dataFimVigencia)))
      );

      if (max) {
        return moment(max).format("DD/MM/yyyy HH:mm:ss");
      }
    }
    return "";
  };

  const agruparHistoricoPorData = (precos) => {
    const precosAgrupados = precos.reduce((r, a) => {
      r[`${a.nlote}`] = r[`${a.nlote}`] || [];
      r[`${a.nlote}`].push(a);
      return r;
    }, Object.create(null));

    const precosOrdenados = Object.entries(precosAgrupados).sort((a, b) =>
      salvarUltimaAtualizacao(a[1]) < salvarUltimaAtualizacao(b[1])
        ? 1
        : salvarUltimaAtualizacao(a[1]) > salvarUltimaAtualizacao(b[1])
        ? -1
        : 0
    );

    return precosOrdenados;
  };

  const buscarPrecoPorAnoMes = (precos, ano, precoMes) => {
    const precosLinha = precos.find(
      (preco) => preco.ano === ano && preco.mes === precoMes
    );

    if (precosLinha) {
      return {
        ...precosLinha,
        novo: false
      };
    }

    return {
      ano,
      base: null,
      inflacao: null,
      margem: null,
      mes,
      nlote: null,
      precoConv: null,
      precoI5: null,
      precoI1: null,
      swapI5: null,
      submercadoId: null
    };
  };

  const agruparPrecosPorAno = (precos) => {
    const precosAgrupados = anos.map((ano) => {
      return {
        ano: ano.valor,
        items: meses.map((item) =>
          buscarPrecoPorAnoMes(precos, ano.valor, item.valor)
        )
      };
    });

    return precosAgrupados;
  };

  const agruparPrecosPorAnoHistorico = (precos) => {
    const precosAgrupados = anos.map((ano) => {
      return {
        ano: ano.valor,
        items:
          precos.filter((preco) => preco.ano === ano.valor).length > 0
            ? precos.filter((preco) => preco.ano === ano.valor)
            : meses.map((item) =>
                buscarPrecoPorAnoMes(precos, ano.valor, item.valor)
              )
      };
    });

    return precosAgrupados;
  };

  const gerarColunasHistorico = (dados) => {
    const historicoPorLote = agruparHistoricoPorData(dados);
    const colunaHistorico = [];

    historicoPorLote.forEach((data, index) => {
      colunaHistorico.push({
        name: salvarUltimaAtualizacao(data[1]),
        selector: `preco${index}`
      });
    });

    setColunasHistorico(colunaHistorico);

    return historicoPorLote;
  };

  const gerarPrimeiraLinha = (colunas) => {
    const primeiraLinha = {};

    colunas.forEach((item, index) => {
      primeiraLinha[`preco${index}`] = <PrecosCabecalho />;
    });

    return primeiraLinha;
  };

  const formatarPreco = (media) => {
    if (typeof media === "number" && media > 0) {
      return (
        <strong>{`R$ ${String(media.toFixed(2)).replace(".", ",")}`}</strong>
      );
    }

    return "R$ 0,00";
  };

  const gerarMediaPrecos = (precosAtuais, precoAno) => {
    let mediaPrecoConv = 0;
    let mediaPrecoI5 = 0;
    let mediaPrecoI1 = 0;

    if (submercado && !mes) {
      const medias = precosAtuais.find(
        (atual) =>
          atual.mediaPrecoConv ||
          atual.mediaPrecoI5 ||
          (atual.mediaPrecoI1 && atual.ano === precoAno)
      );
      mediaPrecoConv = medias?.mediaPrecoConv || 0;
      mediaPrecoI5 = medias?.mediaPrecoI5 || 0;
      mediaPrecoI1 = medias?.mediaPrecoI1 || 0;
    } else if (submercado && mes) {
      const mediasMes = precosAtuais.find(
        (atual) =>
          (atual.precoConv || atual.precoI5 || atual.precoI1) &&
          atual.ano === precoAno &&
          atual.mes === Number(mes)
      );
      mediaPrecoConv = mediasMes?.precoConv || 0;
      mediaPrecoI5 = mediasMes?.precoI5 || 0;
      mediaPrecoI1 = mediasMes?.precoI1 || 0;
    } else if (!submercado && !mes) {
      const precosFiltradosPorAno = precosAtuais.filter(
        (item) =>
          item.ano === precoAno && item.mediaPrecoConv && item.mes === 12
      );
      precosFiltradosPorAno.forEach((item) => {
        mediaPrecoConv += item.mediaPrecoConv;
        mediaPrecoI5 += item.mediaPrecoI5;
        mediaPrecoI1 += item.mediaPrecoI1;
      });

      mediaPrecoConv /= precosFiltradosPorAno.length;
      mediaPrecoI5 /= precosFiltradosPorAno.length;
      mediaPrecoI1 /= precosFiltradosPorAno.length;
    } else {
      const precosFiltradosPorMes = precosAtuais.filter(
        (item) =>
          item.ano === precoAno && item.precoConv && item.mes === Number(mes)
      );
      precosFiltradosPorMes.forEach((item) => {
        mediaPrecoConv += item.precoConv;
        mediaPrecoI5 += item.precoI5;
        mediaPrecoI1 += item.precoI1;
      });

      mediaPrecoConv /= precosFiltradosPorMes.length;
      mediaPrecoI5 /= precosFiltradosPorMes.length;
      mediaPrecoI1 /= precosFiltradosPorMes.length;
    }

    return (
      <div className={classes.precoGrupo}>
        <div className={classes.precoItem}>
          {mediaPrecoConv ? (
            <strong>{formatarPreco(mediaPrecoConv)}</strong>
          ) : (
            "R$ 0,00"
          )}
        </div>
        <div className={classes.precoItem}>
          {mediaPrecoI5 > 0 ? (
            <strong>{formatarPreco(mediaPrecoI5)}</strong>
          ) : (
            "R$ 0,00"
          )}
        </div>
        <div className={classes.precoItem2}>
          {mediaPrecoI1 > 0 ? (
            <strong>{formatarPreco(mediaPrecoI1)}</strong>
          ) : (
            "R$ 0,00"
          )}
        </div>
      </div>
    );
  };

  const gerarLinhasAtuais = (precosAtuais) => {
    const precosAtuaisAgrupados = agruparPrecosPorAno(precosAtuais);
    const precosAtuaisFormatados = [];

    precosAtuaisFormatados.push({
      data: <div className={classes.colunaAno}>Ano</div>,
      precosAtuais: <PrecosCabecalho />
    });

    precosAtuaisAgrupados.forEach((dado) => {
      precosAtuaisFormatados.push({
        data:
          mes && Number(mes) !== 0
            ? `${meses.find((m) => m.valor === Number(mes)).nome}/${dado.ano}`
            : dado.ano,
        precosAtuais: gerarMediaPrecos(
          submercado ? dado.items || [] : precosAtuais || [],
          dado.ano
        )
      });
    });

    setAtuais(precosAtuaisFormatados);
  };

  const gerarLinhaParaPrecos = (precos, colunas) => {
    const novosPrecos = {};

    colunas.forEach((coluna, index) => {
      novosPrecos[`preco${index}`] = (
        <div className={classes.precoGrupo}>
          <div className={classes.precoItem}>R$ 0,00</div>
          <div className={classes.precoItem}>R$ 0,00</div>
          <div className={classes.precoItem2}>R$ 0,00</div>
        </div>
      );
    });

    let mediaPrecoConv = null;
    let mediaPrecoI5 = null;
    let mediaPrecoI1 = null;

    precos.every((item) => {
      const colunaPreco = colunas.findIndex(
        (coluna) => coluna[0] === `${item.nlote}`
      );

      mediaPrecoConv = item.mediaPrecoConv
        ? item.mediaPrecoConv
        : mediaPrecoConv;
      mediaPrecoI5 = item.mediaPrecoI5 ? item.mediaPrecoI5 : mediaPrecoI5;
      mediaPrecoI1 = item.mediaPrecoI1 ? item.mediaPrecoI1 : mediaPrecoI1;

      if (colunaPreco !== -1) {
        novosPrecos[`preco${colunaPreco}`] = (
          <div className={classes.precoGrupo}>
            <div className={classes.precoItem}>
              {formatarPreco(
                mes && Number(mes) !== 0 ? item.precoConv : mediaPrecoConv
              )}
            </div>
            <div className={classes.precoItem}>
              {formatarPreco(
                mes && Number(mes) !== 0 ? item.precoI5 : mediaPrecoI5
              )}
            </div>
            <div className={classes.precoItem2}>
              {formatarPreco(
                mes && Number(mes) !== 0 ? item.precoI1 : mediaPrecoI1
              )}
            </div>
          </div>
        );
        return true;
      }
      return false;
    });

    return novosPrecos;
  };

  const gerarLinhasHistorico = (precoHistorico) => {
    if (precoHistorico.length) {
      const precosAgrupadosPorAno = agruparPrecosPorAnoHistorico(
        precoHistorico
      );
      const historicoFormatado = [];

      const colunaHistorico = gerarColunasHistorico(precoHistorico);

      const primeiraLinha = gerarPrimeiraLinha(colunaHistorico);

      historicoFormatado.push(primeiraLinha);

      precosAgrupadosPorAno.forEach((preco) => {
        historicoFormatado.push({
          ...gerarLinhaParaPrecos(preco.items, colunaHistorico)
        });
      });

      setHistorico(historicoFormatado);
    } else {
      setHistorico([]);
    }
  };

  useEffect(() => {
    gerarLinhasHistorico(linhasHistorico);
    gerarLinhasAtuais(linhasAtuais, linhasHistorico);
  }, [linhasHistorico, linhasAtuais]);

  return (
    <>
      {historico.length || atuais.length ? (
        <>
          <Grid container>
            <Grid item lg={4} md={5} sm={6} xs={12}>
              <div className={classes.tabelaAtual}>
                <div className={classes.scroll}>
                  <DataTable
                    columns={colunasAtuais}
                    data={atuais}
                    customStyles={customStyles}
                    noDataComponent={<></>}
                    noHeader
                    responsive={false}
                  />
                </div>
              </div>
            </Grid>
            <Grid item lg={8} md={7} sm={6} xs={12}>
              <div className={classes.tabelaHistorico}>
                <div className={classes.scroll}>
                  <DataTable
                    columns={colunasHistorico}
                    data={historico}
                    customStyles={customStylesHistorico}
                    noDataComponent={<></>}
                    noHeader
                    responsive={false}
                  />
                </div>
              </div>
            </Grid>
          </Grid>
          <div className={classes.paginacao}>
            <Pagination
              count={totalPaginas}
              shape="rounded"
              variant="outlined"
              color="primary"
              onChange={(valor, page) => onChangePagina(valor, page)}
            />
          </div>
        </>
      ) : (
        <TabelaSemDados>Sem dados de histórico</TabelaSemDados>
      )}
    </>
  );
};

TabelaHistorico.propTypes = {
  linhasAtuais: PropTypes.oneOfType([PropTypes.any]).isRequired,
  linhasHistorico: PropTypes.oneOfType([PropTypes.any]).isRequired,
  totalPaginas: PropTypes.number,
  onChangePagina: PropTypes.func,
  mes: PropTypes.number,
  submercado: PropTypes.number
};

TabelaHistorico.defaultProps = {
  totalPaginas: 1,
  onChangePagina: () => {},
  mes: null,
  submercado: null
};

export default TabelaHistorico;
