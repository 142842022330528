import * as React from "react";
import PropTypes from "prop-types";
import {
  OutlinedInput,
  MenuItem,
  FormControl,
  FormControlLabel,
  Select,
  Radio,
  RadioGroup,
  Box,
  FormHelperText,
  Stack,
  Chip
} from "@mui/material";

const SelectRadio = React.forwardRef(
  ({ id, label, opcoes, onChange, valorSelecionado, erro }) => {
    const handleChange = (event) => {
      const {
        target: { value }
      } = event;
      onChange(value.tipoApplianceId);
    };

    return (
      <Box>
        <FormControl fullWidth error={Boolean(erro[id])}>
          <Select
            labelId={id}
            id={id}
            onChange={handleChange}
            displayEmpty
            input={<OutlinedInput />}
            renderValue={() => {
              const selecionado = opcoes?.length
                ? opcoes?.find(
                    (opcao) => opcao.tipoApplianceId === valorSelecionado
                  )
                : null;
              return (
                <>
                  {selecionado ? (
                    <Stack direction="row" spacing={1}>
                      <Chip label={selecionado.descricao} />
                    </Stack>
                  ) : (
                    label
                  )}
                </>
              );
            }}
          >
            {opcoes.map((item) => (
              <MenuItem key={item.tipoApplianceId} value={item}>
                <RadioGroup
                  aria-labelledby={id}
                  value={valorSelecionado}
                  name="radio-buttons-group"
                >
                  <FormControlLabel
                    value={item.tipoApplianceId}
                    control={<Radio />}
                    label={item.descricao}
                  />
                </RadioGroup>
              </MenuItem>
            ))}
          </Select>
          {Boolean(erro[id]) && (
            <FormHelperText>{erro[id]?.message}</FormHelperText>
          )}
        </FormControl>
      </Box>
    );
  }
);

SelectRadio.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  valorSelecionado: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  opcoes: PropTypes.arrayOf(
    PropTypes.shape({
      tipoApplianceId: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.elementType
      ]),
      descricao: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  ).isRequired,
  onChange: PropTypes.func,
  erro: PropTypes.oneOfType([PropTypes.any])
};

SelectRadio.defaultProps = {
  id: "",
  label: "",
  onChange: () => {},
  valorSelecionado: "",
  erro: {}
};

export default SelectRadio;
