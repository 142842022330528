import React, { useState } from "react";
import PropTypes from "prop-types";

// Icones
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

// Componentes
import { useTheme } from "@mui/material/styles";
import { Box, Collapse, Grid, useMediaQuery } from "@mui/material";
import ToogleIcon from "./componentes/toggleIcon";
import MaterialSwitch from "../switch";

// Styles
import { Label, useStyles } from "./style";
import theme from "../../themes";

const ItemListaExpansivel = ({
  label,
  color,
  edicao,
  exclusao,
  onClickEditar,
  onClickExcluir,
  ativo,
  aberto,
  children,
  lg,
  md,
  sm,
  xs,
  disabled
}) => {
  const themeMobile = useTheme();
  const mobile = useMediaQuery(themeMobile.breakpoints.down("sm"));
  const classes = useStyles({ ativo, mobile });
  const [itemAberto, setItemAberto] = useState(aberto);

  return (
    <Grid item xs={xs} sm={sm} md={md} lg={lg}>
      <Grid container spacing={0}>
        <Grid item className={`${classes.squareWidth} ${classes.gridItem}`}>
          <ToogleIcon
            aberto={itemAberto}
            ativo={ativo}
            color={color}
            onClick={() => setItemAberto(!itemAberto)}
          />
        </Grid>
        <Grid item xs>
          <Box component="div" className={classes.label}>
            {label}
          </Box>
        </Grid>
        {edicao && (
          <Grid item className={`${classes.squareWidth} ${classes.gridItem}`}>
            <div
              className={`${classes.treeItem} ${classes.wrapper} ${classes.center}`}
              onKeyDown={onClickEditar}
              onClick={onClickEditar}
              role="button"
              tabIndex={0}
            >
              <EditOutlinedIcon />
            </div>
          </Grid>
        )}
        {exclusao && (
          <Grid item className={`${classes.squareWidth} ${classes.gridItem}`}>
            <div
              className={`${classes.treeItem} ${classes.wrapper} ${classes.center}`}
              onKeyDown={onClickExcluir}
              onClick={onClickExcluir}
              role="button"
              tabIndex={0}
              style={{ opacity: disabled ? "0.5" : "" }}
            >
              <MaterialSwitch checked={ativo} />
            </div>
          </Grid>
        )}
      </Grid>
      <Collapse in={itemAberto}>
        <Grid container spacing={0}>
          <Grid
            item
            className={`${classes.gridItem} ${classes.treeItem} ${classes.expandItem} fade show`}
          >
            {children}
          </Grid>
        </Grid>
      </Collapse>
    </Grid>
  );
};

ItemListaExpansivel.propTypes = {
  label: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  color: PropTypes.string,
  edicao: PropTypes.bool,
  exclusao: PropTypes.bool,
  onClickEditar: PropTypes.oneOfType([PropTypes.func]),
  onClickExcluir: PropTypes.oneOfType([PropTypes.func]),
  ativo: PropTypes.bool,
  aberto: PropTypes.bool,
  lg: PropTypes.number,
  md: PropTypes.number,
  sm: PropTypes.number,
  xs: PropTypes.number,
  children: PropTypes.oneOfType([PropTypes.node]),
  disabled: PropTypes.bool
};

ItemListaExpansivel.defaultProps = {
  color: theme.color.primary,
  edicao: false,
  exclusao: false,
  onClickEditar: () => {},
  onClickExcluir: () => {},
  ativo: true,
  aberto: false,
  lg: 6,
  md: 12,
  sm: 12,
  xs: 12,
  children: <></>,
  disabled: false
};

export default ItemListaExpansivel;
