import styled from "styled-components";
import { Base } from "../cores";
import theme from "themes";
import { makeStyles } from '@mui/styles';

export const Button = styled.button`
  background-color: ${(props) =>
    !props.stateless
      ? props.selected
        ? props.bgColor
        : theme.color.secondaryBorderColor
      : props.bgColor};
  border-color: ${(props) =>
    !props.stateless
      ? props.selected
        ? props.bgColor
        : theme.color.primaryBackgroud
      : props.bgColor};
  color: ${(props) => (props.selected ? theme.color.secondaryBorderColor : theme.color.primaryBackgroud)};
  outline: none;
  width: 100%;
  padding: 5px;
  &:hover {
    background-color: ${theme.color.primaryBackgroud};
    border-color: ${(props) => props.bgColor};
    color: ${(props) => (props.disabled ? theme.color.primaryBackgroud : theme.color.secondaryBorderColor)};
  }
  &:focus {
    background-color: ${(props) =>
      !props.stateless && props.selected ? theme.color.secondaryBorderColor : props.bgColor};
    border-color: ${(props) =>
      !props.stateless && props.selected ? theme.color.primaryBackgroud : props.bgColor};
    color: ${(props) =>
      !props.stateless && props.selected ? theme.color.primaryBackgroud : theme.color.secondaryBorderColor};
  }
  &:active,
  &.active,
  &:not(:disabled):not(.disabled):active {
    background-color: ${(props) =>
      !props.stateless && props.selected ? theme.color.secondaryBorderColor : props.bgColor};
    border-color: ${(props) =>
      !props.stateless && props.selected ? theme.color.primaryBackgroud : props.bgColor};
    color: ${(props) =>
      !props.stateless && props.selected ? theme.color.primaryBackgroud : theme.color.secondaryBorderColor};
  }
`;

export const useStyles = makeStyles(() => ({
  checkbox: {
    "&:checked": {
      color: "black"
    }
  },
  iconeSelecionado: {
    fontWeight: "bold"
  },
  iconeSemSelecao: {
    color: `${Base.White} !important`,
    backgroundColor: Base.White,
    borderRadius: "2px"
  }
}));
