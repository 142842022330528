import React, { useMemo, useState } from "react";
import { Card, Grid } from "@mui/material";
import EsqueletoEtapa from "../../Componentes/EsqueletoEtapa";
import { useStyles } from "./style";
import Assistente from "componentes/assistente";
import { useRef } from "react";
import { ProtocolosEtapas } from "global/constantes";
import CartaDenuncia from "./componentes/cartaDenuncia";
import TermoPactuacao from "./componentes/termoPactuacao";
import ModelagemCCEE from "./componentes/modelagemCCEE";
import CadastroMedidor from "./componentes/cadastroMedidor";
import ImportacaoFaturas from "./componentes/importacaoFaturas";
import UnidadeNaoSelecionada from "componentes/unidadeNaoSelecionada";
import ValidacaoImportacaoFaturas from "./componentes/validacaoImportacaoFaturas";
import RetornoEndpointDto from "global/dto/retornoEndpointDto";
import { importarFaturasEnergiaTramitacao } from "servicos/workflowServico";
import { store } from '../../../../../global/redux'
import { alertaExibir } from "../../../../../global/redux/modulos/alertas/actions";

const Migracao = ({
  prazo,
  responsavel,
  onProximaEtapa,
  esconderBotaoProximo,
  onVoltarEtapa,
  etapa,
  protocoloDemanda
}) => {
  const classes = useStyles();
  const [titulo, setTitulo] = useState('');
  const [index, setIndex] = useState(-1);
  const [importarFaturas, setImportarFaturas] = useState(false);

  const botaoAtivo = etapa === ProtocolosEtapas.Fim || etapa === ProtocolosEtapas.Impossibilidade || importarFaturas;

  const avancarEtapa = () => {
    if (importarFaturas) {
      importarFaturasEnergia();
    } else {
      onProximaEtapa(ProtocolosEtapas.Fim);
    }
  };

  const onClickVoltar = () => {
    onVoltarEtapa(ProtocolosEtapas.ValidacaoAssinaturaConfirmacaoContrato)
  }
  

  const importarFaturasEnergia = async () => {
    try {
      const response = await importarFaturasEnergiaTramitacao(
        protocoloDemanda
      );

      if (response.status === 200) {
        onProximaEtapa();
        setImportarFaturas(false);

        exibirSucesso("Importação de Faturas realizadas!")
      }
    } catch (ex) {
      exibirErro(ex?.response?.data?.message ?? "Não foi possivel tramitar demanda faturas!")

    }
  }

  const exibirErro = (mensagem) => {
    store.dispatch(
      alertaExibir({
        tipo: "danger",
        mensagem
      })
    );
  }

  const exibirSucesso = (mensagem) => {
    store.dispatch(
      alertaExibir({
        tipo: "success",
        mensagem
      })
    );
  }

  const refAssistente = useRef(null);

  const steps = [
    "Carta denúncia",
    "Termo de pactuação",
    "Modelagem na CCEE",
    "Importação de Faturas",
    "Cadastro do Medidor"
  ];

  const TelaPassoAtual = useMemo(() => {
    switch (etapa) {
      case (ProtocolosEtapas.CartaDenuncia):
        setTitulo(steps[0]);
        setIndex(0);
        return (
            <CartaDenuncia
              prazo={prazo}
              responsavel="Luz"
              protocoloDemanda={protocoloDemanda}
              titulo={steps[0]}
            />
        );
      case (ProtocolosEtapas.TermoPactuacao):
        setTitulo(steps[1]);
        setIndex(1);
        return (
            <TermoPactuacao
              prazo={prazo}
              responsavel="Luz"
              protocoloDemanda={protocoloDemanda}
              titulo={steps[1]}
            />
        );
      case (ProtocolosEtapas.ModelagemCCEE):
        setTitulo(steps[2]);
        setIndex(2);
        return (
            <ModelagemCCEE
              prazo={prazo}
              responsavel="Luz"
              protocoloDemanda={protocoloDemanda}
              titulo={steps[2]}
            />
        );
      // case (ProtocolosEtapas.ImportacaoFaturas):
      //   setTitulo(steps[3]);
      //   setIndex(3);
      //   return (
      //       <ImportacaoFaturas
      //         prazo={prazo}
      //         responsavel="Cliente"
      //         protocoloDemanda={protocoloDemanda}
      //         titulo={steps[3]}
      //         ativarImportFaturas={setImportarFaturas}
      //       />
      //   );
      case (ProtocolosEtapas.ImportacaoFaturas):
      case (ProtocolosEtapas.ValidacaoImportacaoFaturas):
        setTitulo("Validação Importação de Faturas");
        setIndex(3);
        return (
            <ValidacaoImportacaoFaturas
              prazo={prazo}
              responsavel="Luz"
              protocoloDemanda={protocoloDemanda}
              titulo={"Validação Importação de Faturas"}
            />
        );
      case (ProtocolosEtapas.CadastroMedidor):
      default:
        setTitulo(steps[4]);
        setIndex(4);
        return (
            <CadastroMedidor
              prazo={prazo}
              responsavel="Luz"
              protocoloDemanda={protocoloDemanda}
              titulo={steps[4]}
            />
        );
    }
  }, [etapa]);

  return (
    <EsqueletoEtapa
      prazo={prazo}
      responsavel={responsavel}
      titulo={titulo}
      onClickProximo={() => avancarEtapa()}
      onClickVoltar={() => onClickVoltar()}
      esconderBotaoProximo={!botaoAtivo}
    >
      <Grid container justifyContent='space-between'>
        <Grid item lg={3}>
          <Assistente
              orientation='vertical'
              steps={steps}
              ref={refAssistente}
              onChangeStep={(passo) => passo}
              passoInicialFluxo={index}
            />
        </Grid>
        <Grid item lg={9}>
          {TelaPassoAtual}
        </Grid>
      </Grid>
    </EsqueletoEtapa>
  );
};

export default Migracao;
