import styled from "styled-components";
import { Base } from "componentes/cores";
import theme from 'themes';

export const Button = styled.button`
  color: ${Base.White};
  border-color: ${Base.White};
  width: 100%;
  &:hover {
    color: ${theme.color.secondaryBorderColor};
  }
`;

export const Icone = styled.img`
  max-height: 27px;
  object-fit: cover;
`;
