import React, { useCallback, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";

// Componentes
import TreeViewFuncionalidades from "../../../../../../componentes/treeView";

// Redux
import { store } from "../../../../../../global/redux";
import { alertaExibir } from "../../../../../../global/redux/modulos/alertas/actions";

// Serviços
import { listarFuncionalidades } from "../../../../../../servicos/funcionalidadesServico";
import { useParams } from "react-router";

const Funcionalidades = ({ selecionados, onSelecionar, onBuscar }) => {
  const [funcionalidades, setFuncionalidades] = useState([]);
  const { id } = useParams();

  const obterFuncionalidades = async () => {
    try {
      const lista = await listarFuncionalidades();
      if (lista?.status === 200 && lista?.data?.funcionalidadeColecao) {
        setFuncionalidades(lista?.data?.funcionalidadeColecao ?? []);
        if (!id) {
          selecionarFuncionalidadeDefault(lista?.data?.funcionalidadeColecao ?? []);
        }
      }
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
    }
  };

  useEffect(() => {
    obterFuncionalidades();
  }, []);

  const onClickSelecionar = (node) => {
    onSelecionar(node, funcionalidades);
  };

  const selecionarFuncionalidadeDefault = (listaFuncionalidades) => {
    if (listaFuncionalidades?.length > 0) {
      const colecaoFuncionalidades = listaFuncionalidades.map(a => a.funcionalidadeColecao).reduce((a,b) => a.concat(b));
      const funcionalidadeAlterarSenha = colecaoFuncionalidades.find(l => l.caminho === 'AlterarSenha');
      if (funcionalidadeAlterarSenha) onSelecionar(funcionalidadeAlterarSenha, listaFuncionalidades);
    }    
  }

  const filtrarDados = useCallback(() => {
    return funcionalidades.filter((x) => {
      if (onBuscar) {
        let filtro = x.nome.includes(onBuscar);
        if (filtro) return true;
        filtro = x.funcionalidadeColecao.filter((y) =>
          y.nome.includes(onBuscar)
        ).length;
        if (filtro) return true;
        return false;
      }
      return true;
    });
  }, [funcionalidades, onBuscar]);

  const nodesExpanded = useMemo(() => {
    if (onBuscar?.length) {
      const itens = [];
      filtrarDados().forEach((x) => {
        if (!itens.includes(x.id)) {
          itens.push(String(x.id));
        }
        if (x?.funcionalidadeColecao?.length) {
          x.funcionalidadeColecao.forEach((y) => {
            if (!itens.includes(y.id)) {
              itens.push(String(y.id));
            }
          });
        }
      });
      return itens;
    }
    return [];
  }, [onBuscar.length, filtrarDados]);

  return (
    <TreeViewFuncionalidades
      dataSource={filtrarDados()}
      selectable
      selecionados={selecionados}
      onSelecionar={(node) => onClickSelecionar(node)}
      nodesExpanded={onBuscar ? nodesExpanded : []}
    />
  );
};

Funcionalidades.propTypes = {
  selecionados: PropTypes.oneOfType([PropTypes.array]),
  onSelecionar: PropTypes.oneOfType([PropTypes.func]),
  onBuscar: PropTypes.string
};

Funcionalidades.defaultProps = {
  selecionados: [],
  onSelecionar: () => {},
  onBuscar: ""
};

export default Funcionalidades;
