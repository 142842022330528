import styled from "styled-components";
import { makeStyles } from '@mui/styles';
import theme from "themes";

export const BotaoFiltroOrdenacaoEstilo = styled.button`
  font-size: 18px;
  background-color: ${theme.color.btFOBgColor} !important;
  border-radius: ${theme.borda.input} !important;
  border-color: ${theme.color.btFOBorderColor} !important;
  color: ${theme.color.btFOTextColor} !important;
  &:hover {
    color: ${theme.color.btFOTextColor} !important;
    background-color: ${theme.color.btFOBgColo} !important;
  }
  &:focus,
  &.btn-primary:not(:disabled):not(.disabled):active:focus {
    box-shadow: none;
  }
  & > svg {
    width: 18px;
    margin-left: 5px;
  }
`;

export const useStyles = makeStyles({
  buttonFiltro: {
    fontSize: "18px",
    maxWidth: "220px"
  }
});
