import MICROSERVICO from "global/enumeradores/microServico";
import obterApi from "servicos/api";

const api = () => obterApi(MICROSERVICO.AREALOGADA);

export const Salvar = async (params) => {

  const url = `v1/metricasRelatorio/cria`;
  const method ="post";

  return api()[method](url, params);
};

export const ExportarPlanilha = async ( dataInicio, dataFim ) => {
  const { data } = await api().get(`v1/metricasRelatorio/exportarplanilha/contadordownloads?dataInicio=${dataInicio}&dataFim=${dataFim}`,
        {
            responseType: "blob",
        }
    );
  return data;
};

