import React, { useState } from "react";
import PropTypes from "prop-types";

import { EditOutlined } from "@mui/icons-material";

import { Box, Collapse, Grid, IconButton } from "@mui/material";
import ToogleIcon from "./componentes/toggleIcon";

import { useStyles } from "./style";
import theme from "../../../../../themes";

const ItemListaExpansivelResponsiva = ({
  nomeContato,
  color,
  aberto,
  email,
  telefone,
  cep,
  onClickEditar,
}) => {
  const classes = useStyles();
  const [itemAberto, setItemAberto] = useState(aberto);

  return (
    <Grid className={classes.boxItem} container>
      <Grid className={classes.gridItem} item xs={2}>
        <div className="w-100">
          <ToogleIcon
            aberto={itemAberto}
            color={color}
            onClick={() => setItemAberto(!itemAberto)}
          />
        </div>
      </Grid>
      <Grid item xs={10}>
        {nomeContato && (
          <Grid xs={12} className={classes.label}>
            {nomeContato}
          </Grid>
        )}
        {email && <Grid xs={12}>{email}</Grid>}
        {telefone && <Grid xs={12}>{telefone}</Grid>}
        {cep && <Grid xs={12}>{cep}</Grid>}
      </Grid>

      <Collapse className="w-100" in={itemAberto}>
        <Grid container spacing={0}>
          <Grid
            item
            className={`${classes.gridItem} ${classes.treeItem} ${classes.expandItem} fade show`}
          >
            <Grid container>
              <Grid item xs={4}>
                <IconButton onClick={onClickEditar}>
                  <Box display="flex" flexDirection="column">
                    <Box>
                      <EditOutlined cursor="pointer" htmlColor="#FA3EB1" />
                    </Box>
                    <Box
                      component="span"
                      fontSize="10px"
                      mt="7px"
                      color="#FA3EB1"
                      fontWeight="600"
                    >
                      Editar
                    </Box>
                  </Box>
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Collapse>
    </Grid>
  );
};

ItemListaExpansivelResponsiva.propTypes = {
  nomeContato: PropTypes.string,
  email: PropTypes.string.isRequired,
  color: PropTypes.string,
  teledone: PropTypes.string,
  cep: PropTypes.string,
  aberto: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.node]),
  onClickEditar: PropTypes.oneOfType([PropTypes.func]),
};

ItemListaExpansivelResponsiva.defaultProps = {
  nomeContato: "",
  color: theme.color.primary,
  aberto: false,
  teledone: "",
  cep: "",
  plano: "",
  children: <></>,
  onClickEditar: () => {},
};

export default ItemListaExpansivelResponsiva;
