import React, { useState, useEffect, useCallback } from "react";
import { Box, Card, Divider, Grid } from "@mui/material";
import { useHistory, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { cnpj } from "cpf-cnpj-validator";
import { RotasDTO } from "../../../../global/rotas/rotasUrlDto";
import {
  Botao,
  BotaoRetornarListagem,
  InputTextoCEP,
  Loader,
  SelectArredondado
} from "../../../../componentes";
import { useStyles } from "./style";
import MaterialInputMascara from "../../../../componentes/inputTextoMascara";
import MaterialInputTexto from "../../../../componentes/inputTexto/materialInput";
import MaterialInputTelefone from "../../../../componentes/inputTexto/materialInputTelefone";
import { unidadesFederativas } from "../../../../global/constantes";
import CadastroUsinasHelper from "./helper";
import {
  removeMaskCep,
  removeMaskCpfCnpj,
  removeMaskTelefone,
  validacaoTrimObject
} from "../../../../servicos/utils";
import ModalLuz from "../../../../componentes/modalLuz";

const UsinasLuzCadastro = () => {
  const { register, handleSubmit, errors } = useForm({
    reValidateMode: "onSubmit"
  });
  const classes = useStyles();
  const { id } = useParams();
  const history = useHistory();
  const [usinasId, setUsinasId] = useState(null);
  const [carregandoUsinas, setCarregandoUsinas] = useState(false);

  const [dadosEmpresa, setDadosEmpresa] = useState({
    id: null,
    cpfCnpj: null,
    razaoSocial: null,
    nomeFantasia: null,
    inscricaoEstadual: null,
    inscricaoMunicipal: null,
    telefone: null,
    situacao: null,
    dataReferencia: null,
    numeroInstalacao: null
  });

  const [dadosEndereco, setDadosEndereco] = useState({
    cep: null,
    endereco: null,
    numero: null,
    complemento: null,
    uf: "",
    cidade: null,
    bairro: null,
    associacaoLuzId: null,
    distribuidoraId: null,
    cri: ""
  });

  const [modalAcao, setModalAcao] = useState(false);
  const [mensagemModal, setMensagemModal] = useState("");
  const [carregandoCep, setCarregandoCep] = useState(false);
  const [carregandoNomeFantasia, setCarregandoNomeFantasia] = useState(false);
  const [dadosEdicao, setDadosEdicao] = useState({});
  const [carregandoDistribuidora, setCarregandoDistribuidora] = useState(false);
  const [listaDistribuidora, setListaDistribuidora] = useState([]);
  const [listaAssociacao, setListaAssociacao] = useState([]);
  const [cnpjAssociacao, setCnpjAssociacao] = useState(null);
  const [carregandoAssociacao, setCarregandoAssociacao] = useState([]);

  const listarCriAssociacao = [
    { nome: "Sim", id: "S" },
    { nome: "Não", id: "N" }
  ];

  useEffect(() => {
    if (!dadosEndereco.associacaoLuzId) return;

    const cnpjSelecionado = listaAssociacao.find(
      (item) => Number(item.id) === Number(dadosEndereco.associacaoLuzId)
    );
    setCnpjAssociacao(cnpjSelecionado?.cpfCnpj);
  }, [dadosEndereco?.associacaoLuzId, listaAssociacao]);

  const obterDistribuidora = useCallback(async () => {
    const {
      data,
      sucesso,
      mensagem
    } = await CadastroUsinasHelper.obterDistribuidora(
      setCarregandoDistribuidora
    );

    if (!sucesso) {
      CadastroUsinasHelper.exibirErro(mensagem);
      setCarregandoDistribuidora(false);
      return;
    }
    setListaDistribuidora(data);
  }, []);

  const obterAssociacao = useCallback(async () => {
    const {
      data,
      sucesso,
      mensagem
    } = await CadastroUsinasHelper.obterAssociacao(setCarregandoAssociacao);

    if (!sucesso) {
      CadastroUsinasHelper.exibirErro(mensagem);
      setCarregandoDistribuidora(false);
      return;
    }

    const listaAssociacaoAtivas =
      data?.filter((item) => item.situacao === "AT") || [];

    setListaAssociacao(listaAssociacaoAtivas);
  }, []);

  useEffect(() => {
    obterDistribuidora();
    obterAssociacao();
  }, []);

  const obterCepCobertura = async (cepCobertura, dataEdit) => {
    const {
      data,
      sucesso,
      mensagem
    } = await CadastroUsinasHelper.obterCepCobertura(
      cepCobertura,
      setCarregandoCep
    );

    if (!sucesso) {
      CadastroUsinasHelper.exibirErro(mensagem);
      setCarregandoCep(false);
    }

    setDadosEndereco({
      ...dadosEndereco,
      cep: data?.cep || dataEdit?.cep,
      endereco: data?.logradouro || dataEdit?.endereco,
      numero: data?.numero || dataEdit?.numero,
      complemento: data?.complemento || dataEdit?.complemento,
      uf: data?.uf || dataEdit?.uf,
      cidade: data?.cidade || dataEdit?.cidade,
      bairro: data?.bairro || dataEdit?.bairro,
      associacaoLuzId:
        dataEdit?.associacaoLuzId || dadosEndereco?.associacaoLuzId,
      distribuidoraId:
        dataEdit?.distribuidoraId || dadosEndereco?.distribuidoraId,
      cri: dataEdit?.cri || dadosEndereco?.cri
    });
  };

  const onBlurCep = async (valorCep) => {
    if (valorCep.length > 7) {
      await obterCepCobertura(removeMaskCep(valorCep), dadosEdicao);
    }
  };

  const onClickEditar = () => {
    history.push(`${RotasDTO.UsinasLuz}/cadastro/${usinasId}`);
    setModalAcao(false);
  };

  const obterUsinasLuzPorId = async (idSelecionado) => {
    const model = {
      id: idSelecionado
    };
    const {
      data,
      sucesso,
      mensagem
    } = await CadastroUsinasHelper.obterUsinasLuzPorId(
      model,
      setCarregandoUsinas
    );

    if (!sucesso) {
      CadastroUsinasHelper.exibirErro(mensagem);
      setCarregandoUsinas(false);
    }

    setDadosEdicao(data);
    setDadosEmpresa({
      id: Number(data.id),
      cpfCnpj: data?.cpfCnpj,
      razaoSocial: data?.razaoSocial,
      nomeFantasia: data?.nomeFantasia,
      inscricaoEstadual: data?.inscricaoEstadual,
      inscricaoMunicipal: data?.inscricaoMunicipal,
      telefone: data?.telefone,
      situacao: data?.situacao,
      numeroInstalacao: data?.numeroInstalacao
    });

    await obterCepCobertura(data?.cep, data);
  };

  useEffect(() => {
    if (!id) return;
    obterUsinasLuzPorId(id);
  }, [id]);

  const buscarDadosCadastraisPorCnpj = async (cnpjEmpresa) => {
    if (!cnpjEmpresa) return;
    const {
      data,
      sucesso,
      mensagem
    } = await CadastroUsinasHelper.buscarDadosCadastraisPorCnpj(
      cnpjEmpresa,
      setCarregandoNomeFantasia
    );

    if (!sucesso) {
      CadastroUsinasHelper.exibirErro(mensagem);
      setCarregandoNomeFantasia(false);
      return;
    }
    setDadosEmpresa({
      nomeFantasia: data.data.nomeFantasia,
      razaoSocial: data?.data?.razaoSocial
    });
  };

  const handleEmpresa = (cnpjEmpresa) => {
    setDadosEmpresa({
      cpfCnpj: cnpjEmpresa,
      ...dadosEmpresa
    });
    buscarDadosCadastraisPorCnpj(removeMaskCpfCnpj(cnpjEmpresa));
  };

  const handleEndereco = (e) => {
    setDadosEndereco({
      ...dadosEndereco,
      [e.target.name]: e.target.value
    });
  };

  const obterUsinasLuzCpfCnpj = async (cnpjEmpresa) => {
    const {
      data,
      sucesso,
      mensagem
    } = await CadastroUsinasHelper.obterUsinasLuzCpfCnpj(
      cnpjEmpresa,
      setCarregandoUsinas
    );

    if (!sucesso) {
      CadastroUsinasHelper.exibirErro(mensagem);
      setCarregandoUsinas(false);
      return;
    }

    setUsinasId(data?.id);
    setModalAcao(true);
    setMensagemModal("Nome fantasia já cadastrada! Deseja Editar?");
  };

  const salvarUsinasLuz = async (dados) => {
    const model = {
      uf: dados?.uf,
      cpfCnpj: removeMaskCpfCnpj(dados?.cpfCnpj),
      razaoSocial: dados?.razaoSocial,
      nomeFantasia: dados?.nomeFantasia,
      inscricaoEstadual: dados?.inscricaoEstadual,
      inscricaoMunicipal: dados?.inscricaoMunicipal,
      telefone: removeMaskTelefone(dados?.telefone),
      cep: removeMaskCep(dados?.cep),
      endereco: dados?.endereco,
      numero: dados?.numero,
      complemento: dados?.complemento,
      bairro: dados?.bairro,
      cidade: dados?.cidade,
      situacao: dados?.situacao,
      dataReferencia: window.moment().format(),
      distribuidoraId: dados?.distribuidoraId,
      associacaoLuzId: dados?.associacaoLuzId,
      numeroInstalacao: dados?.numeroInstalacao,
      cri: dados?.cri
    };

    const {
      data,
      sucesso,
      mensagem
    } = await CadastroUsinasHelper.salvarUsinasLuz(
      dados?.id,
      validacaoTrimObject(model),
      setCarregandoUsinas
    );

    if (!sucesso) {
      if (mensagem === "usinasExists") {
        await obterUsinasLuzCpfCnpj(removeMaskCpfCnpj(dados?.cpfCnpj));
        return;
      }
      CadastroUsinasHelper.exibirErro(mensagem);
      setCarregandoUsinas(false);
      return;
    }

    history.push(`${RotasDTO.UsinasLuz}`);
  };

  const enviarFormulario = async (dados) => {
    const dadosForm = {
      ...dados,
      id: dadosEmpresa?.id,
      uf: dadosEndereco?.uf,
      situacao: dadosEmpresa?.situacao || "AT",
      distribuidoraId: Number(dadosEndereco?.distribuidoraId),
      associacaoLuzId: Number(dadosEndereco?.associacaoLuzId),
      cri: dadosEndereco?.cri
    };
    salvarUsinasLuz(dadosForm);
  };

  const aoEnviarFormulario = (dados) => {
    enviarFormulario(dados);
  };

  const handleModalMensagem = (mensagem) => {
    return (
      <Box component="div" fontWeight="700" textAlign="center">
        {mensagem}
      </Box>
    );
  };

  return (
    <>
      <ModalLuz
        item={modalAcao}
        mensagem={handleModalMensagem(mensagemModal)}
        onCancelar={() => {
          setModalAcao(false);
          setMensagemModal("");
        }}
        onConfirmar={onClickEditar}
      />
      <Loader loading={carregandoUsinas}>
        <form
          className="needs-validation"
          onSubmit={handleSubmit(aoEnviarFormulario)}
        >
          <Card className={classes.cardCadastro}>
            <Box
              component={Grid}
              container
              spacing={2}
              p={2}
              className={classes.container}
            >
              <Grid item lg={6} xs={6} className="font-weight-bold">
                <Box component="div">{id ? "Editar usina" : "Nova usina"}</Box>
              </Grid>

              <BotaoRetornarListagem
                urlListagem={RotasDTO.UsinasLuz}
                zerarCombo
              />

              <Grid item lg={4} md={4} sm={4} xs={12}>
                <MaterialInputMascara
                  type="text"
                  id="cpfCnpj"
                  name="cpfCnpj"
                  label="CNPJ"
                  mask="##.###.###/####-##"
                  renderIconShowHide={false}
                  onBlur={(e) => handleEmpresa(e.target.value)}
                  defaultValue={dadosEmpresa?.cpfCnpj ?? ""}
                  ref={register({
                    required: "Campo CNPJ é obrigatório!",
                    maxLength: {
                      value: 18,
                      message: "Quantidade máxima de 18 caracteres!"
                    },
                    minLength: {
                      value: 11,
                      message: "Quantidade mínima de 11 caracteres!"
                    },
                    validate: (value) => cnpj.isValid(value) || "CNPJ inválido!"
                  })}
                  errors={errors}
                  className={id ? classes.desabledInput : ""}
                />
              </Grid>
              <Grid item lg={4} md={4} sm={4} xs={12}>
                <Loader loading={carregandoNomeFantasia}>
                  <MaterialInputTexto
                    type="text"
                    id="numeroInstalacao"
                    name="numeroInstalacao"
                    label="Número de instalação"
                    permiteValorBranco
                    renderIconShowHide={false}
                    defaultValue={dadosEmpresa?.numeroInstalacao ?? ""}
                    ref={register({
                      required: "Campo Número de instalação é obrigatório!"
                    })}
                    errors={errors}
                  />
                </Loader>
              </Grid>
              <Grid item lg={4} md={4} sm={4} xs={12}>
                <Loader loading={carregandoNomeFantasia}>
                  <MaterialInputTexto
                    type="text"
                    id="razaoSocial"
                    name="razaoSocial"
                    label="Razão Social"
                    permiteValorBranco
                    renderIconShowHide={false}
                    defaultValue={dadosEmpresa?.razaoSocial ?? ""}
                    ref={register({
                      required: "Campo Razão Social é obrigatório!"
                    })}
                    errors={errors}
                  />
                </Loader>
              </Grid>
              <Grid item lg={4} md={4} sm={4} xs={12}>
                <Loader loading={carregandoNomeFantasia}>
                  <MaterialInputTexto
                    type="text"
                    id="nomeFantasia"
                    name="nomeFantasia"
                    label="Nome Fantasia"
                    permiteValorBranco
                    renderIconShowHide={false}
                    defaultValue={dadosEmpresa?.nomeFantasia ?? ""}
                    ref={register({
                      required: "Campo Nome Fantasia é obrigatório!",
                      maxLength: {
                        value: 50,
                        message: "Quantidade máxima de 50 caracteres!"
                      },
                      validate: (value) =>
                        (!value.includes("'") && !value.includes('"')) ||
                        "Não é permitido adicionar aspas no Nome Fantasia"
                    })}
                    errors={errors}
                  />
                </Loader>
              </Grid>
              <Grid item lg={4} md={4} sm={4} xs={12}>
                <MaterialInputTexto
                  type="text"
                  id="inscricaoEstadual"
                  name="inscricaoEstadual"
                  label="Inscrição Estadual"
                  renderIconShowHide={false}
                  defaultValue={dadosEmpresa?.inscricaoEstadual ?? ""}
                  ref={register({
                    required: "Campo Inscrição Estadual é obrigatório!",
                    maxLength: {
                      value: 50,
                      message: "Quantidade máxima de 50 caracteres!"
                    }
                  })}
                  errors={errors}
                />
              </Grid>
              <Grid item lg={4} md={4} sm={4} xs={12}>
                <MaterialInputTexto
                  type="text"
                  id="inscricaoMunicipal"
                  name="inscricaoMunicipal"
                  label="Inscrição Municipal"
                  renderIconShowHide={false}
                  defaultValue={dadosEmpresa?.inscricaoMunicipal ?? ""}
                  ref={register({
                    required: "Campo Inscrição Municipal é obrigatório!",
                    maxLength: {
                      value: 50,
                      message: "Quantidade máxima de 50 caracteres!"
                    }
                  })}
                  errors={errors}
                />
              </Grid>
              <Grid item lg={4} md={4} sm={4} xs={12}>
                <MaterialInputTelefone
                  type="text"
                  id="telefone"
                  name="telefone"
                  label="Telefone"
                  renderIconShowHide={false}
                  defaultValue={dadosEmpresa?.telefone ?? ""}
                  ref={register({
                    required: "O campo Telefone é obrigatório!",
                    minLength: {
                      value: 18,
                      message: "Quantidade mínima de 12 caracteres!"
                    }
                  })}
                  errors={errors}
                />
              </Grid>
              <Box component={Grid} item xs={12} my={2}>
                <Divider />
              </Box>
              <Grid item lg={4} md={4} sm={4} xs={12}>
                <InputTextoCEP
                  id="cep"
                  name="cep"
                  label="CEP"
                  carregandoEndereco={carregandoCep}
                  onBlurCep={onBlurCep}
                  defaultValue={dadosEndereco?.cep ?? ""}
                  register={register}
                  errors={errors}
                />
              </Grid>
              <Grid item lg={4} md={4} sm={4} xs={12}>
                <MaterialInputTexto
                  type="text"
                  id="endereco"
                  name="endereco"
                  label="Endereço"
                  renderIconShowHide={false}
                  defaultValue={dadosEndereco?.endereco || ""}
                  ref={register({
                    required: "Campo Endereço é obrigatório!",
                    maxLength: {
                      value: 200,
                      message: "Quantidade máxima de 200 caracteres!"
                    }
                  })}
                  errors={errors}
                  allowClear
                />
              </Grid>
              <Grid item lg={4} md={4} sm={4} xs={12}>
                <MaterialInputTexto
                  type="text"
                  id="numero"
                  name="numero"
                  label="Número"
                  renderIconShowHide={false}
                  permiteValorBranco={!id}
                  defaultValue={dadosEndereco.numero || ""}
                  ref={register({
                    required: "Campo Número é obrigatório!",
                    maxLength: {
                      value: 10,
                      message: "Quantidade máxima de 10 caracteres!"
                    }
                  })}
                  errors={errors}
                  allowClear
                />
              </Grid>
              <Grid item lg={4} md={4} sm={4} xs={12}>
                <MaterialInputTexto
                  type="text"
                  id="complemento"
                  name="complemento"
                  label="Complemento"
                  renderIconShowHide={false}
                  permiteValorBranco={!id}
                  defaultValue={dadosEndereco?.complemento || ""}
                  ref={register({
                    maxLength: {
                      value: 100,
                      message: "Quantidade máxima de 100 caracteres!"
                    }
                  })}
                  errors={errors}
                  allowClear
                />
              </Grid>
              <Grid item lg={4} md={4} sm={4} xs={12}>
                <MaterialInputTexto
                  type="text"
                  id="bairro"
                  name="bairro"
                  label="Bairro"
                  renderIconShowHide={false}
                  permiteValorBranco={!id}
                  defaultValue={dadosEndereco?.bairro || ""}
                  ref={register({
                    required: "Campo Bairro é obrigatório!",
                    maxLength: {
                      value: 100,
                      message: "Quantidade máxima de 100 caracteres!"
                    }
                  })}
                  errors={errors}
                  allowClear
                />
              </Grid>
              <Grid item lg={4} md={4} sm={4} xs={12}>
                <MaterialInputTexto
                  type="text"
                  id="cidade"
                  name="cidade"
                  label="Cidade"
                  renderIconShowHide={false}
                  permiteValorBranco={!id}
                  defaultValue={dadosEndereco?.cidade || ""}
                  ref={register({
                    required: "Campo Cidade é obrigatório!",
                    maxLength: {
                      value: 100,
                      message: "Quantidade máxima de 100 caracteres!"
                    }
                  })}
                  errors={errors}
                  allowClear
                />
              </Grid>
              <Grid item lg={4} md={4} sm={4} xs={12}>
                <SelectArredondado
                  id="uf"
                  name="uf"
                  valueKey="UF"
                  valueName="UF"
                  dataSource={unidadesFederativas}
                  label="UF"
                  value={dadosEndereco?.uf ?? ""}
                  onChange={(e) => handleEndereco(e)}
                  placeholder="UF"
                  ref={register(
                    { name: "uf" },
                    {
                      required: !dadosEndereco?.uf
                        ? "Campo UF é obrigatório!"
                        : false
                    }
                  )}
                  errors={errors}
                />
              </Grid>

              <Grid item lg={4} md={4} sm={4} xs={12}>
                <Loader loading={carregandoDistribuidora}>
                  <SelectArredondado
                    id="distribuidoraId"
                    name="distribuidoraId"
                    valueKey="id"
                    valueName="nomeFantasia"
                    dataSource={listaDistribuidora}
                    label="Distribuidora"
                    value={dadosEndereco?.distribuidoraId ?? ""}
                    onChange={(e) => handleEndereco(e)}
                    placeholder="Distribuidora"
                    allowClear
                    ref={register(
                      { name: "distribuidoraId" },
                      {
                        required: !dadosEndereco?.distribuidoraId
                          ? "Campo Distribuidora é obrigatório!"
                          : false
                      }
                    )}
                    errors={errors}
                  />
                </Loader>
              </Grid>

              <Grid item lg={4} md={4} sm={4} xs={12}>
                <SelectArredondado
                  id="cri"
                  name="cri"
                  valueKey="id"
                  valueName="nome"
                  dataSource={listarCriAssociacao}
                  label="Usina Vinculada ao CRI?"
                  value={dadosEndereco?.cri ?? ""}
                  onChange={(e) => handleEndereco(e)}
                  placeholder="Usina Vinculada ao CRI?"
                  allowClear
                  ref={register(
                    { name: "cri" },
                    {
                      required: !dadosEndereco?.cri
                        ? "Campo Usina Vinculada ao CRI é obrigatório!"
                        : false
                    }
                  )}
                  errors={errors}
                />
              </Grid>

              <Grid item lg={4} md={4} sm={4} xs={12}>
                <Loader loading={carregandoAssociacao}>
                  <SelectArredondado
                    id="associacaoLuzId"
                    name="associacaoLuzId"
                    valueKey="id"
                    valueName="nomeFantasia"
                    dataSource={listaAssociacao}
                    label="Associação"
                    value={dadosEndereco?.associacaoLuzId ?? ""}
                    onChange={(e) => handleEndereco(e)}
                    placeholder="Associação"
                    allowClear
                    ref={register(
                      { name: "associacaoLuzId" },
                      {
                        required: !dadosEndereco?.associacaoLuzId
                          ? "Campo Associação é obrigatório!"
                          : false
                      }
                    )}
                    errors={errors}
                  />
                </Loader>
              </Grid>

              {dadosEndereco.associacaoLuzId && (
                <Grid item lg={4} md={4} sm={4} xs={12}>
                  <MaterialInputMascara
                    type="text"
                    id="cnpjAssociação"
                    name="cnpjAssociação"
                    label="CNPJ Associação"
                    mask="##.###.###/####-##"
                    renderIconShowHide={false}
                    defaultValue={cnpjAssociacao ?? ""}
                    disabled
                  />
                </Grid>
              )}
            </Box>

            <Grid container spacing={2} p={2} className={classes.container}>
              <Grid item lg={3} md={4} sm={4} xs={12}>
                <Botao
                  type="submit"
                  label="Salvar"
                  className={classes.button}
                />
              </Grid>
            </Grid>
          </Card>
        </form>
      </Loader>
    </>
  );
};

export default UsinasLuzCadastro;
