import React, { useEffect, useState } from "react";

// Redux
import { Grid } from "@mui/material";
import { store } from "../../../global/redux";
import { alertaExibir } from "../../../global/redux/modulos/alertas/actions";

// Componentes
import UploadDropArea from "../../../componentes/upload/index";

// Serviços
import {
  uploadMunicipio,
  getUploadProgress
} from "../../../servicos/uploadServico";
import { desabilitarFiltroLateral } from "../../../global/redux/modulos/usuario/actions";

const MunicipiosUpload = () => {
  const [progress, setProgress] = useState(0);
  const [showLoader, setShowLoader] = useState(false);

  const getProgress = (nomeArquivo) => {
    let auxProgress = 0;
    setShowLoader(true);
    const timer = setInterval(() => {
      const response = getUploadProgress(nomeArquivo);
      response.then((res) => {
        setProgress((prevProgress) => (res === 0 ? prevProgress : res));
        auxProgress = res;
      });

      if (auxProgress >= 100) {
        clearInterval(timer);
        store.dispatch(
          alertaExibir({
            tipo: "success",
            mensagem: `O arquivo ${nomeArquivo} foi processado com sucesso!`
          })
        );
        setShowLoader(false);
      }
    }, 10000);
  };

  const fazerUpload = async (arquivo) => {
    try {
      const upload = await uploadMunicipio(arquivo);

      if (upload?.status === 200 || upload?.status === 204) {
        store.dispatch(
          alertaExibir({
            tipo: "success",
            mensagem: `O arquivo ${arquivo.file.name} foi processado com sucesso!`
          })
        );
      }
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
    }

    setProgress(100);

    const ocultarBarra = setInterval(() => {
      setShowLoader(false);
      clearInterval(ocultarBarra);
    }, 3000);
  };

  const processarArquivo = async (valor) => {
    const arquivo = valor[0];

    if (arquivo) {
      store.dispatch(
        alertaExibir({
          tipo: "success",
          mensagem: `O arquivo ${arquivo.file.name} foi enviado para processamento!`
        })
      );

      fazerUpload(arquivo);
      setProgress(0);
      //getProgress(arquivo.file.name);
    }
  };

  const onAddUpload = (valor) => {
    processarArquivo(valor);
  };

  const onDropRejected = (valor) => {
    const arquivo = valor[0];
    store.dispatch(
      alertaExibir({
        tipo: "warning",
        mensagem: `Tipo do arquivo ${arquivo.name} não é permitido!`
      })
    );
  };

  useEffect(() => {
    store.dispatch(desabilitarFiltroLateral(true));

    return () => {
      store.dispatch(desabilitarFiltroLateral(false));
    };
  }, [desabilitarFiltroLateral]);

  return (
    <Grid container spacing={3} alignItems="center">
      <Grid item xs={12}>
        <UploadDropArea
          onAdd={onAddUpload}
          progressValue={progress}
          showLoader={showLoader}
          onDropRejected={onDropRejected}
        />
      </Grid>
    </Grid>
  );
};

export default MunicipiosUpload;
