import React from "react";
import PropTypes from "prop-types";

// Styles
import { Button } from "./style";

const Botao = React.forwardRef((props, ref) => {
  const {
    disabled,
    id,
    icon,
    label,
    type,
    onClick,
    style,
    className,
    value,
    loginLuz,
    stateless,
    selected,
  } = props;

  const classNameDesc = `btn btn-lg rounded-pill w-100 position-relative d-flex justify-content-center align-items-center ${className}`;

  return (
    <Button
      type={type}
      className={classNameDesc}
      id={id}
      disabled={disabled}
      ref={ref}
      loginLuz={loginLuz}
      stateless={stateless}
      selected={selected}
      onClick={onClick}
      style={{ ...style }}
      value={value}
    >
      {icon && icon}
      {label}
    </Button>
  );
});

Botao.propTypes = {
  disabled: PropTypes.bool,
  id: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.any]),
  label: PropTypes.string,
  bgColor: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.oneOfType([PropTypes.func]),
  style: PropTypes.oneOfType([PropTypes.any]),
  className: PropTypes.string,
  value: PropTypes.string,
  stateless: PropTypes.bool,
  loginLuz: PropTypes.bool,
  selected: PropTypes.bool,
};

Botao.defaultProps = {
  disabled: false,
  id: "",
  icon: false,
  label: "",
  bgColor: "",
  type: "button",
  onClick: () => {},
  style: {},
  className: "",
  value: "",
  stateless: true,
  loginLuz: false,
  selected: false,
};

export default Botao;
