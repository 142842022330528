import React, { useCallback, useRef } from "react";

import { Grid } from "@mui/material";

import { useHistory } from "react-router-dom";

import TabelaPaginada from "componentes/tabelaPaginada";
import Coluna from "componentes/tabelaPaginada/colunas/coluna";
import Botao from "componentes/botao";
import ResultadoPaginadoDto from "componentes/tabelaPaginada/resultadoPaginadoDto";
import ColunaComponentePersonalizado from "componentes/tabelaPaginada/colunas/colunaComponentePersonalizado";

import ListaDto from "./ListaDto";
import { useStyles } from "./style";
import Helper from "./helper";
import ColunaMultiplosBotoes from "./colunaMultiplosBotoes";

import { store } from "global/redux";
import { alertaExibir } from "global/redux/modulos/alertas/actions";
import { RotasDTO } from "global/rotas/rotasUrlDto";

import enumeradorBotao from "./enumeradores/enumeradorBotao";

const RelatoriosTipos = () => {
  const history = useHistory();
  const classes = useStyles();
  const refTabela = useRef(null);

  const onClickEditar = (id) => {
    history.push(`${RotasDTO.RelatoriosTipos}/cadastro/${id}`);
  };

  const onClickMudarStatus = useCallback(async (Id, Ativo) => {
    // console.log({id, status});
    try {
      const mudarStatus = await Helper.MudarStatus({Id, Ativo});

      // console.log('mudarStatus', mudarStatus);

      if (mudarStatus) {
        store.dispatch(
          alertaExibir({
            tipo: "success",
            mensagem: `A categoria foi ${
              Ativo === false ? "inativado" : "ativado"
            } com sucesso!`
          })
        );
      }
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
    }
    
    if (refTabela?.current) refTabela.current.obterDadosPaginados();
  }, []);

  const onClickBotao = (tipo, parametros) => {
    switch (tipo) {
      case enumeradorBotao.Edicao:
        onClickEditar(parametros.id);
        break;
      case enumeradorBotao.Status:
        onClickMudarStatus(parametros.id, !parametros.ativo);
        break;
      default:
        break;
    }
  };

  const colunas = [
    new Coluna("nome", "Tipo de relatórios"),
    new Coluna("categoriaRelatorio", "Categoria de relatórios"),
    new ColunaComponentePersonalizado(
        "opcoes",
        "",
        ColunaMultiplosBotoes,
        onClickBotao,
        false,
        true,
        "15%",
        "15%",
        "pl-2"
      )
  ];

  const obterCategoriasRelatorios = async (parametros) => {
    // console.log('obterCategoriasRelatorios', parametros);

    const resultado = await Helper.Listar(parametros);
    // console.log('resultado', resultado);

    if (!resultado.sucesso) {
      store.dispatch(
        alertaExibir({
          tipo: "danger",
          mensagem: resultado.mensagem
        })
      );
      return new ResultadoPaginadoDto([], 1, 0, 0);
    }

    if (resultado.data.lista?.length === 0 ?? true)
      return new ResultadoPaginadoDto([], 1, 0, 0);

    const resLinhas = resultado.data.lista?.map((res) => {
      
      return new ListaDto(
        res.id,
        res.nome,
        res.ativo,
        res.categoriaRelatorio.nome
      );
    });

    return new ResultadoPaginadoDto(
      resLinhas,
      resultado.data.paginaAtual,
      resultado.data.totalItens,
      resultado.data.totalPaginas
    );
  };

  const onChangeFiltrosTabela = async (parametros) => {
    return obterCategoriasRelatorios(parametros);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <TabelaPaginada
          className={classes.table}
          ref={refTabela}
          onChangeFiltrosTabela={onChangeFiltrosTabela}
          colunas={colunas}
          paginaAtual={1}
        />
      </Grid>
      <Grid container spacing={2} className={classes.container}>
        <Grid item xs={6}>
          <Botao
            type="submit"
            label="Novo Tipo de Relatório"
            className={classes.button}
            onClick={() => history.push(`${RotasDTO.RelatoriosTipos}/cadastro`)}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default RelatoriosTipos;