// Node mudules
import moment from "moment";
import { Grid, IconButton, Box } from "@mui/material";

// Hooks
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import { useStyles } from "./style";

// Componentes
import { useSelector } from "react-redux";
import CardPlano from "./componentes/cardPlano";
import SemPlanos from "./componentes/semPlanos";
import CardPlanoMobile from "./componentes/cardPlanoMobile";
import PlanosConstante from "./constantes/planosConstante";
import PlanosSimuladosHelper from "./helper";
import Loader from "../../../componentes/loader";
import BotaoBranco from "../../../componentes/botaoBranco";
import CustomLoader from "../../../componentes/customLoader";
import {
  desabilitarCliente,
  desabilitarEmpresa,
  desabilitarUnidade
} from "../../../global/redux/modulos/usuario/actions";
import { store } from "../../../global/redux";
import { useTheme } from '@mui/material/styles';
import { RotasDTO } from "global/rotas/rotasUrlDto";
import useMediaQuery from '@mui/material/useMediaQuery';
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import getDomain from "global/getDomain";
import ModalConfirmacao from "../../../componentes/modalConfirmacao";

import { handleLoading } from "../../../global/redux/modulos/loader/actions";



const PlanosSimulados = () => {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('lg'));
  const dispatch = useDispatch()

  const { state } = useLocation();

  const [semPlanos, setSemPlanos] = useState(false);

  // Estado para controle dados do calculo do plano simulado
  const [dadosPlanos, setDadosPlanos] = useState([]);

  // Estado para controle dos dados do endpoint de consulta (será utilizado apenas no post)
  const [dadosBanco, setDadosBanco] = useState(null);

  // Estado para contole das datas de cotação e validade
  const [dataCotacao, setDataCotacao] = useState(null);
  const [dataValidade, setDataValidade] = useState(null);

  // Estado para controle do loading da tela
  const [carregando, setCarregando] = useState(false);

  // Estado para controlar a tela em caso de cotação fechada
  const [cotacaoFechada, setCotacaoFechada] = useState(false);

  const planosDisponiveis = useMemo(() => {

    if (!dadosPlanos || dadosPlanos.length === 0) return [];
    return PlanosConstante.filter((plano) => {
      return dadosPlanos.some(
        (dado) => dado.tipoPlanoSimulado === plano.tipoPlano
      );
    });

  }, [dadosPlanos, PlanosConstante]);

  const dadosCotacaoInicial = useMemo(() => {
    return state?.dadosCotacaoInicial;
  }, [state]);

  const atribuirDadosState = (resultado) => {
    setDadosBanco(resultado.data);
    setDataCotacao(resultado.data.dataCotacao);
    setDataValidade(resultado.data.dataValidade);
    setCotacaoFechada(resultado.data.fechada);
    if (resultado.data.fechada) {
      setDadosPlanos(resultado.data.planos);
      setSemPlanos(false);
    } else {
      gerarPlanosSimulados();
    }
  };

  const gerarPlanosSimulados = async () => {

    dispatch(handleLoading(true))

    const resultPlanos = await PlanosSimuladosHelper.simularPlanos(dadosCotacaoInicial?.id);
    if (!resultPlanos.sucesso) {
      PlanosSimuladosHelper.exibirErro(resultPlanos.mensagem);
      setSemPlanos(true);
      setCarregando(false);
      return;
    }

    setDadosPlanos(resultPlanos.data.planos);
    setSemPlanos(resultPlanos.data?.planos?.length === 0);

    dispatch(handleLoading(false));
  }
  const dadosFatura = useSelector((state) => state.log);
  const onEscolherPlano = async (dadosCardSelecionado) => {
    if (!dadosCardSelecionado) return;

    const retorno = await PlanosSimuladosHelper.selecionarPlano(
      dadosCardSelecionado.id,
      dadosCotacaoInicial?.id,
      dadosFatura
    );

    if (!retorno.sucesso) {
      PlanosSimuladosHelper.exibirErro(retorno.mensagem);
      return;
    }

    mobile ?
      history.push(`/Cotacao/EmpresaMobile`, {
        dadosCotacaoInicial: state?.dadosCotacaoInicial
      })
      :
      history.push(`/Cotacao/Empresa`, {
        dadosCotacaoInicial: state?.dadosCotacaoInicial
      })
  };

  const prosseguirSemEscolha = async () => {

    mobile ?
      history.push(`/Cotacao/EmpresaMobile`, {
        dadosCotacaoInicial: state?.dadosCotacaoInicial
      })
      :
      history.push(`/Cotacao/Empresa`, {
        dadosCotacaoInicial: state?.dadosCotacaoInicial
      })
  };

  const onClickVoltar = () => {
    history.goBack();
  };

  const obterPlanosSimulados = useCallback(async () => {
    dispatch(handleLoading(true));

    const resultado = await PlanosSimuladosHelper.ObterPlanosSimulados(
      dadosCotacaoInicial?.id
    );

    if (!resultado.sucesso) {
      PlanosSimuladosHelper.exibirErro(resultado.mensagem);
      setSemPlanos(true);
      setCarregando(false);
      return;
    }

    atribuirDadosState(resultado);

    dispatch(handleLoading(false));
  }, [setDadosPlanos]);

  useEffect(() => {
    obterPlanosSimulados();
  }, [obterPlanosSimulados]);

  useEffect(() => {
    store.dispatch(desabilitarCliente(true));
    store.dispatch(desabilitarUnidade(true));

    return () => {
      store.dispatch(desabilitarEmpresa(false));
      store.dispatch(desabilitarCliente(false));
      store.dispatch(desabilitarUnidade(false));
    };
  }, []);

  const dominio = `${getDomain()}`;

  return <>
    {!mobile ?
      dataCotacao && planosDisponiveis?.length > 0 && (
        <Grid container>
          <Grid item lg={12} md={12} className={classes.divCabecalho}>
            <Grid
              container
              spacing={1}
              justifyContent="space-between"
              alignItems="center"
              className="mb-3"
            >
              <Grid item>O que nós recomendamos para você:</Grid>
              <Grid item lg={3} md={3} sm={6} className={classes.divEscolherDepois}>
                <BotaoBranco
                  className={classes.botaoEscolherDepois}
                  label={cotacaoFechada ? 'Próximo' : "Escolher depois"}
                  onClick={prosseguirSemEscolha}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )
      : <></>}

    {!mobile ?
      planosDisponiveis?.length > 0 ? (
        <Grid item lg={12} md={12}>
          <Grid container justifyContent="space-around">
            {planosDisponiveis.map((planoConfiguracao, index) => (
              <Grid
                item
                lg={4}
                md={4}
                sm={12}
                xs={12}
                className={classes.cards}
              >
                <CardPlano
                  planoConfiguracao={planoConfiguracao}
                  dadosCards={dadosPlanos.find(
                    (dado) =>
                      dado.tipoPlanoSimulado === planoConfiguracao.tipoPlano
                  )}
                  onEscolherPlano={onEscolherPlano}
                  ultimoItem={
                    String(planosDisponiveis?.length - 1) === String(index)
                  }
                  cotacaoFechada={cotacaoFechada}
                  dominio={`${dominio}`}
                />
              </Grid>
            ))}
          </Grid>
          <Grid
            container
            justifyContent="space-between"
            className={classes.divCabecalho}
          >
            <Grid item className={classes.informacoesAsterisco}>
              *Os valores são projeções que podem variar (Menos o percentual de redução de custos)
            </Grid>
            <Grid item className={classes.dataValidade}>
              Validade da cotação: {moment(dataValidade).format("DD/MM/YYYY")}
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <>
          {
            semPlanos ?
              <SemPlanos
                onClickVoltar={() => onClickVoltar()}
                onClickProsseguir={() => prosseguirSemEscolha()}
              /> : null
          }
        </>
      ) : <></>}





    {mobile ?
      <>
        <Box className={classes.barraSuperior}>
          <IconButton
            className={classes.arrowBack}
            onClick={() => onClickVoltar()}
            size="small"
          >
            <ArrowBackIosIcon fontSize="small" />
          </IconButton>
          Planos
        </Box>

        {
          planosDisponiveis?.length > 0 ? (
            <Grid item lg={12} md={12} className={classes.marginBarraSuperior}>
              <Grid container>
                {planosDisponiveis.map((planoConfiguracao, index) => (
                  <Grid
                    item
                    lg={4}
                    md={4}
                    sm={12}
                    xs={12}
                    className={classes.cards}
                  >
                    <CardPlanoMobile
                      planoConfiguracao={planoConfiguracao}
                      dadosCards={dadosPlanos.find(
                        (dado) =>
                          dado.tipoPlanoSimulado === planoConfiguracao.tipoPlano
                      )}
                      onEscolherPlano={onEscolherPlano}
                      ultimoItem={
                        String(planosDisponiveis?.length - 1) === String(index)
                      }
                      cotacaoFechada={cotacaoFechada}
                      dominio={`${dominio}`}
                    />
                  </Grid>
                ))}
              </Grid>
              <Grid
                container
                justifyContent="space-between"
                className={classes.divCabecalhoMobile}
              >
                <Grid item className={classes.informacoesAsteriscoMobile}>
                  *Os valores são projeções que podem variar (Menos o percentual de redução de custos)
                </Grid>
              </Grid>

              <Grid
                container
                justifyContent="space-between"
                className={classes.areaEscolherDepois}
              >
                <Grid item xs={6} className={classes.divEscolherDepois}>
                  <BotaoBranco
                    className={classes.botaoEscolherDepois}
                    label={cotacaoFechada ? 'Próximo' : "Escolher depois"}
                    onClick={prosseguirSemEscolha}
                  />
                </Grid>
                <Grid item xs={6} className={classes.dataValidadeMobile}>
                  Validade da cotação: {moment(dataValidade).format("DD/MM/YYYY")}
                </Grid>
              </Grid>



            </Grid>
          ) : (
            <>
              {
                semPlanos ?
                  <SemPlanos
                    onClickVoltar={() => onClickVoltar()}
                    onClickProsseguir={() => prosseguirSemEscolha()}
                  /> : null
              }
            </>
          )}

      </>
      :
      <></>
    }

  </>;
};

export default PlanosSimulados;
