import { Card, Divider, Grid } from "@mui/material";
import { Loader } from "componentes";
import { BotaoCadastro } from "componentes";
import { Base } from "componentes/cores";
import { ProtocolosEtapas, tipoArquivoWorkFlow } from "global/constantes";
import { b64toBlob } from "global/utils/blobUtil";
import React, { createRef, useEffect, useMemo, useRef, useState } from "react";
import shortid from "shortid";
import EsqueletoEtapa from "../../Componentes/EsqueletoEtapa";
import RepresentanteLegalCard from "../../Componentes/RepresentanteLegalCard";
import UploadCard from "../../Componentes/UploadCard";
import tipoArquivoDemandaUpload from "../../Constantes/tipoArquivoDemandaUpload";
import RepresentanteLegalDto from "../../Dto/RepresentanteLegalDto";
import RepresentateLegalWorkflowHelper from "./helper";
import { useStyles } from "./style";

function RepresentantesLegais({
  prazo,
  responsavel,
  onProximaEtapa,
  protocoloDemanda,
  onVoltarEtapa,
  somenteLeitura,
  onObterEtapaAtual
}) {
  const classes = useStyles();
  const [representantesLegais, setRepresentantesLegais] = useState([]);
  const [representantesRef, setRepresentantesRef] = useState([]);
  const [arquivosContratoSocial, setArquivosContratoSocial] = useState([]);
  const [faturaCER, setFaturaCER] = useState([]);
  const [demonstrativoDRE, setDemonstrativoDRE] = useState([]);
  const [renderizaComponente, setRenderizaComponente] = useState(false);
  const [loadingGeral, setLoadingGeral] = useState(false);

  const representantesTamanho = useMemo(() => {
    return representantesLegais?.length ?? 0;
  }, [representantesLegais]);

  const botaoNovoRepresentanteDisabled = useMemo(() => {
    return representantesLegais?.length >= 2;
  }, [representantesLegais]);

  const validarFormularios = async () => {
    const promisses = representantesRef.map((ref) =>
      ref?.current?.validarCampos()
    );

    const resultado = await Promise.all(promisses);

    return resultado.findIndex((x) => !x) === -1;
  };

  const obterValoresFormularios = () => {
    return representantesRef.map((ref) => ref?.current?.obterValores());
  };

  const mapearRepresentantesAntesExclusao = () => {
    const representantesComponente = obterValoresFormularios();

    setRepresentantesLegais(representantesComponente);
  };

  const onClickNovoRepresentante = async () => {
    if (!(await validarFormularios())) return;

    setRepresentantesLegais((oldState) => [
      ...oldState,
      new RepresentanteLegalDto()
    ]);
  };

  const addArquivos = (arquivos, tipo) => {
    switch (tipo) {
      case tipoArquivoDemandaUpload.ContratoSocialProcuracao:
        setArquivosContratoSocial(arquivos);
        break;
      case tipoArquivoDemandaUpload.FaturasCCER:
        setFaturaCER(arquivos);
        break;
      case tipoArquivoDemandaUpload.BalancoDre:
        setDemonstrativoDRE(arquivos);
        break;
      default:
        break;
    }
  };

  const onEnviarArquivos = async () => {
    if (!arquivosContratoSocial || arquivosContratoSocial.length <= 0) {
      RepresentateLegalWorkflowHelper.exibirErro(
        "Deve ser informado pelo menos um Contrato Social / Procuração"
      );
      return false;
    }

    if (!faturaCER || faturaCER.length <= 0) {
      RepresentateLegalWorkflowHelper.exibirErro(
        "Deve ser informado pelo menos uma Fatura de Energia / CCER"
      );
      return false;
    }

    if (!demonstrativoDRE || demonstrativoDRE.length <= 0) {
      RepresentateLegalWorkflowHelper.exibirErro(
        "Deve ser informado o Balanço Financeiro / DRE"
      );
      return false;
    }

    if (arquivosContratoSocial && arquivosContratoSocial.length > 0) {
      const resultContrato = await RepresentateLegalWorkflowHelper.salvarArquivosContratoSocialProcuracao(
        arquivosContratoSocial,
        protocoloDemanda
      );

      if (!resultContrato.sucesso) {
        RepresentateLegalWorkflowHelper.exibirErro(resultContrato.mensagem);
        return false;
      }
    }

    if (faturaCER && faturaCER.length > 0) {
      const resultadoFatura = await RepresentateLegalWorkflowHelper.salvarArquivosFaturasCCER(
        faturaCER,
        protocoloDemanda
      );

      if (!resultadoFatura.sucesso) {
        RepresentateLegalWorkflowHelper.exibirErro(resultadoFatura.mensagem);
        return false;
      }
    }


    if (demonstrativoDRE && demonstrativoDRE.length > 0) {
      const resultadoDemonstrativo = await RepresentateLegalWorkflowHelper.salvarArquivosDemonstrativoDRE(
        demonstrativoDRE,
        protocoloDemanda
      );

      if (!resultadoDemonstrativo.sucesso) {
        RepresentateLegalWorkflowHelper.exibirErro(resultadoDemonstrativo.mensagem);
        return false;
      }
    }

    return true;
  };

  const onClickProximo = async () => {
    if (!(await validarFormularios())) return;

    setLoadingGeral(true);

    //if (!somenteLeitura) {
    const resultadoArquivos = await onEnviarArquivos();
    if (!resultadoArquivos) {
      setLoadingGeral(false);
      return;
    }
    //}

    const representantes = obterValoresFormularios();

    const resultadoRepresentantes = await RepresentateLegalWorkflowHelper.indicarRepresentantesLegais(
      representantes,
      protocoloDemanda
    );

    if (!resultadoRepresentantes.sucesso) {
      RepresentateLegalWorkflowHelper.exibirErro(
        resultadoRepresentantes.mensagem
      );
      setLoadingGeral(false);
      return;
    }

    RepresentateLegalWorkflowHelper.exibirSucesso(
      "Representantes legais gravados com sucesso!"
    );

    onProximaEtapa(ProtocolosEtapas.AssinarContratoProcuracao, true);
    setLoadingGeral(false);
  };

  const listarRepresentates = async () => {
    const representates = await RepresentateLegalWorkflowHelper.ListarRepresentatesLegais(
      protocoloDemanda
    );

    if (!representates.sucesso) {
      RepresentateLegalWorkflowHelper.exibirErro(representates.mensagem);
      setRepresentantesLegais([new RepresentanteLegalDto()]);
      return;
    }

    setRepresentantesLegais(
      representates.data?.representantes ?? [new RepresentanteLegalDto()]
    );
  };

  const mapearArquviso = (arquivos) => {

    const arquivosContratoSocialProcuracao = arquivos.filter(
      (arquivo) => arquivo.tipo === tipoArquivoWorkFlow.ContratoSocialProcuracao
    );

    const arquivosFaturaCCER = arquivos.filter(
      (arquivo) => arquivo.tipo === tipoArquivoWorkFlow.FaturasCCER
    );

    const arquivosDemonstrativoDRE = arquivos.filter(
      (arquivo) => arquivo.tipo === tipoArquivoWorkFlow.BalancoDre
    );

    const arquivosContratoBlob = [];

    if (
      arquivosContratoSocialProcuracao &&
      arquivosContratoSocialProcuracao.length > 0
    ) {
      arquivosContratoSocialProcuracao.forEach((arquivo) => {
        arquivosContratoBlob.push(
          new File(
            [b64toBlob(arquivo.arquivoBase64), arquivo.mimeType],
            arquivo.nomeArquivo,
            {
              type: arquivo.mimeType
            }
          )
        );
      });
    }

    const arquivosFaturaCCERBlob = [];
    if (arquivosFaturaCCER && arquivosFaturaCCER.length > 0) {
      arquivosFaturaCCER.forEach((arquivo) => {
        arquivosFaturaCCERBlob.push(
          new File(
            [b64toBlob(arquivo.arquivoBase64), arquivo.mimeType],
            arquivo.nomeArquivo,
            {
              type: arquivo.mimeType
            }
          )
        );
      });
    }



    const arquivosDemonstrativoDREBlob = [];
    if (arquivosDemonstrativoDRE && arquivosDemonstrativoDRE.length > 0) {
      arquivosDemonstrativoDRE.forEach((arquivo) => {
        arquivosDemonstrativoDREBlob.push(
          new File(
            [b64toBlob(arquivo.arquivoBase64), arquivo.mimeType],
            arquivo.nomeArquivo,
            {
              type: arquivo.mimeType
            }
          )
        );
      });
    }

    setFaturaCER(arquivosFaturaCCERBlob);
    setDemonstrativoDRE(arquivosDemonstrativoDREBlob);
    setArquivosContratoSocial(arquivosContratoBlob);
    setRenderizaComponente(true);
  };

  const onClickRemove = (chaveUnica) => {
    setRepresentantesLegais((old) =>
      old.filter(
        (item, index) =>
          String(item.chaveUnica) !== String(chaveUnica) || index === 0
      )
    );
  };

  const ondDeleteFile = async (nomeArquivo, tipo) => {

    if (tipo === tipoArquivoWorkFlow.ContratoSocialProcuracao) {
      setArquivosContratoSocial((oldState) => {
        oldState.filter((item) => item.name !== nomeArquivo?.name)
      })
    }

    if (tipo === tipoArquivoWorkFlow.FaturasCCER) {
      setFaturaCER((oldState) => {
        oldState.filter((item) => item.name !== nomeArquivo?.name)
      })
    }

    if (tipo === tipoArquivoWorkFlow.BalancoDre) {
      setDemonstrativoDRE((oldState) => {
        oldState.filter((item) => item.name !== nomeArquivo?.name)
      })
    }

    const response = await RepresentateLegalWorkflowHelper.deleteArquivo(
      nomeArquivo?.name,
      protocoloDemanda,
      tipo,
    );

    
    if (response && !response.data.validationResult.isValid) {
      RepresentateLegalWorkflowHelper.exibirErro(response.errors[0]);
    }
    
  };

  const onDownloadArquivo = async (nomeArquivo) => {
    const response = await RepresentateLegalWorkflowHelper.baixarArquivosDemanda(
      nomeArquivo,
      protocoloDemanda
    );

    if (response && !response.sucesso) {
      RepresentateLegalWorkflowHelper.exibirErro(response.mensagem);
    }
  };

  const consultarArquivosDemanda = async () => {
    const resultado = await RepresentateLegalWorkflowHelper.consultarArquivosDemanda(
      protocoloDemanda
    );

    if (!resultado.sucesso) {
      RepresentateLegalWorkflowHelper.exibirErro(resultado.mensagem);
      setRenderizaComponente(true);
      return;
    }

    mapearArquviso(resultado.data?.arquivos);
  };

  useEffect(() => {
    setRepresentantesRef((ref) => {
      const refs = Array(representantesTamanho)
        .fill()
        .map((_, i) => (ref && ref[i]) || createRef());

      return refs;
    });
  }, [representantesTamanho]);

  useEffect(() => {
    listarRepresentates();
    consultarArquivosDemanda();
  }, []);

  return (
    <Loader loading={loadingGeral}>
      <EsqueletoEtapa
        prazo={prazo}
        responsavel={responsavel}
        titulo="Representante legal"
        onClickProximo={() => onClickProximo()}
        onClickVoltar={onVoltarEtapa}
        esconderBotaoVoltar
      >
        <Grid item xs={12} className={classes.conteudo}>
          {representantesRef &&
            representantesLegais &&
            representantesLegais?.map((representante, index) => {
              return (
                <div
                  className={
                    index < representantesLegais.length
                      ? classes.espacoEntreRepresentantes
                      : ""
                  }
                >
                  <RepresentanteLegalCard
                    ref={representantesRef[index]}
                    index={index}
                    key={representante.chaveUnica}
                    representanteLegal={representante}
                    onClickRemove={onClickRemove}
                  />
                </div>
              );
            })}
        </Grid>
        <Grid item xs={12} className={classes.addRepresentante}>
          <BotaoCadastro
            label="Adicionar representante"
            disabled={botaoNovoRepresentanteDisabled}
            className={classes.addRepresentante}
            onClick={() => onClickNovoRepresentante()}
            color={Base.Black}
          />
        </Grid>
        <Grid item xs={12}>
          <Divider className={classes.divider} />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item lg={4} md={4} sm={12}>
              <UploadCard
                limite={10}
                arquivos={arquivosContratoSocial}
                titulo="Procuração / Representante"
                tituloUpload="Solte o arquivo para anexá-lo ou procurar"
                subtitulo="Anexe a documentação societária. Lembre-se de incluir os documentos que dão poderes aos representantes informados acima."
                onAddArquivoUpload={(arquivos, tipo) =>
                  addArquivos(arquivos, tipo)
                }
                renderizaComponente={renderizaComponente}
                onDeleteArquivoUpload={(nomeArquivo) =>
                  ondDeleteFile(nomeArquivo, tipoArquivoDemandaUpload.ContratoSocialProcuracao)
                }
                //               somenteLeitura={somenteLeitura}
                tipo={tipoArquivoDemandaUpload.ContratoSocialProcuracao}
                onClickDownload={(nomeArquivo) =>
                  onDownloadArquivo(nomeArquivo)
                }
              />
            </Grid>
            <Grid item lg={4} md={4} sm={12}>
              <UploadCard
                limite={10}
                arquivos={faturaCER}
                titulo=" Fatura de Energia / CCER"
                tituloUpload="Solte o arquivo para anexá-lo ou procurar"
                subtitulo="Anexe as últimas 3 faturas de energia e Contrato de Compra de Energia Regulada (CCER). Caso não tenha o CCER, o mesmo poderá ser apresentado em etapa futura."
                onAddArquivoUpload={(arquivos, tipo) =>
                  addArquivos(arquivos, tipo)
                }
                onDeleteArquivoUpload={(nomeArquivo) =>
                  ondDeleteFile(nomeArquivo, tipoArquivoDemandaUpload.FaturasCCER)

                }
                //               somenteLeitura={somenteLeitura}
                renderizaComponente={renderizaComponente}
                tipo={tipoArquivoDemandaUpload.FaturasCCER}
                onClickDownload={(nomeArquivo) =>
                  onDownloadArquivo(nomeArquivo)
                }
              />
            </Grid>
            <Grid item lg={4} md={4} sm={12}>
              <UploadCard
                limite={10}
                arquivos={demonstrativoDRE}
                titulo=" Balanço Financeiro / DRE"
                tituloUpload="Solte o arquivo para anexá-lo ou procurar"
                subtitulo="Inclua os demonstrativos financeiros para prosseguirmos com a análise de crédito."
                onAddArquivoUpload={(arquivos, tipo) =>
                  addArquivos(arquivos, tipo)
                }
                onDeleteArquivoUpload={(nomeArquivo) =>
                  ondDeleteFile(nomeArquivo, tipoArquivoDemandaUpload.BalancoDre)

                }
                //              somenteLeitura={somenteLeitura}
                renderizaComponente={renderizaComponente}
                tipo={tipoArquivoDemandaUpload.BalancoDre}
                onClickDownload={(nomeArquivo) =>
                  onDownloadArquivo(nomeArquivo)
                }
              />
            </Grid>
          </Grid>
        </Grid>
      </EsqueletoEtapa>
    </Loader>
  );
}

export default RepresentantesLegais;
