import React, { useState, useEffect } from "react";
import shortid from "shortid";

// Redux
import { alertaExibir } from "../../../global/redux/modulos/alertas/actions";
import { store } from "../../../global/redux";
import { salvarNomeArquivoProinfa } from "../../../global/redux/modulos/usuario/actions";
import { useSelector } from "react-redux";

// Componentes
import { Grid } from "@mui/material";
import UploadDropArea from "../../../componentes/upload/index";

// Serviços
import {
  uploadProinfa,
  getUploadProgress
} from "../../../servicos/uploadServico";

const ProinfaUpload = () => {
  const usuarioGlobal = useSelector((state) => state.usuario);

  const [progress, setProgress] = useState(0);
  const [carregandoArquivo, setCarregandoArquivo] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  const getProgress = (nomeArquivo, auxProgress) => {
    setCarregandoArquivo(true);
    setShowLoader(true);

    if (auxProgress >= 100) {
      setShowLoader(false);
      setCarregandoArquivo(false);
    }

    const timer = setInterval(() => {
      const response = getUploadProgress(nomeArquivo);
      response.then((res) => {
        setProgress((prevProgress) =>
          res === 0 || res == null ? prevProgress : res
        );
        auxProgress = res;
      });

      if (auxProgress >= 100) {
        clearInterval(timer);
        setShowLoader(false);
        setCarregandoArquivo(false);
      }
    }, 5000);
  };

  const fazerUpload = async (arquivo, nomeArquivo) => {
    try {
      const upload = await uploadProinfa(arquivo);

      if (
        (upload?.status === 200 || upload?.status === 204) &&
        upload?.data?.validationResult?.isValid
      ) {
        store.dispatch(
          alertaExibir({
            tipo: "success",
            mensagem: `O arquivo ${nomeArquivo} foi processado com sucesso!`
          })
        );
      } else {
        store.dispatch(
          alertaExibir({
            tipo: "warning",
            mensagem: upload?.data?.validationResult?.errors[0]?.errorMessage
          })
        );
      }
    } catch (error) {
      setCarregandoArquivo(false);
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );

      store.dispatch(salvarNomeArquivoProinfa(""));
    }

    setProgress(100);

    const ocultarBarra = setInterval(() => {
      setShowLoader(false);
      setCarregandoArquivo(false);
      clearInterval(ocultarBarra);
    }, 3000);
  };

  const processarArquivo = (valor) => {
    const arquivo = valor[0];

    if (arquivo)
      store.dispatch(
        alertaExibir({
          tipo: "success",
          mensagem: `O arquivo ${arquivo.file.name} foi enviado para processamento!`
        })
      );

    var blob = arquivo.file.slice(0, arquivo.file.size, arquivo.file.type);
    var newFile = new File([blob], shortid.generate(), {
      type: arquivo.file.type
    });
    setProgress(0);
    fazerUpload(newFile, arquivo.file.name);
    getProgress(newFile.name, 0);

    store.dispatch(salvarNomeArquivoProinfa(newFile.name));
  };

  const verificarNivelUpload = async (nomeArquivoProinfa) => {
    const checkUpload = await getUploadProgress(nomeArquivoProinfa);
    if (checkUpload && checkUpload != 0) {
      getProgress(nomeArquivoProinfa, checkUpload);
      if (checkUpload != 0) setProgress(checkUpload);
    }
  };

  useEffect(() => {
    if (usuarioGlobal.nomeArquivoProinfa) {
      verificarNivelUpload(usuarioGlobal.nomeArquivoProinfa);
    }
  }, []);

  const onAddUpload = (valor) => {
    processarArquivo(valor);
  };

  const onDropRejected = (valor) => {
    const arquivo = valor[0];
    store.dispatch(
      alertaExibir({
        tipo: "warning",
        mensagem: `Tipo do arquivo ${arquivo.name} não é permitido!`
      })
    );
  };

  return (
    <Grid container spacing={3} alignItems="center">
      <Grid item xs={12}>
        <UploadDropArea
          loader={carregandoArquivo}
          onAdd={onAddUpload}
          progressValue={progress}
          showLoader={showLoader}
          onDropRejected={onDropRejected}
        />
      </Grid>
    </Grid>
  );
};

export default ProinfaUpload;
