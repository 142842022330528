import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";

// Componentes
import { Grid } from "@mui/material";
import TextField from "@mui/material/TextField";
import { mask as masker, unMask } from "remask";

// Styles
import { useStyles } from "./style";

const InputMask = ({ mask, onChange, value, ...props }) => {
  const handleChange = (ev) => {
    const originalValue = unMask(ev.target.value);
    onChange(originalValue);
  };

  const handleValue = useMemo(() => {
    return masker(value, mask);
  }, [value, mask]);

  return (
    <TextField
      variant="standard"
      {...props}
      onChange={handleChange}
      value={handleValue}
    />
  );
};

const MaterialInputCpf = React.forwardRef((props, ref) => {
  const {
    label,
    id,
    name,
    type,
    defaultValue,
    errors,
    style,
    className,
    onBlur,
    helperLabel,
    disabled,
    minlength
  } = props;

  const [values, setValues] = useState("");
  const patterns = ["999.999.999-99"];

  useEffect(() => {
    defaultValue && setValues(defaultValue);
  }, [defaultValue]);

  const classes = useStyles();

  return (
    <Grid container spacing={0} alignItems="flex-end">
      <Grid item lg={helperLabel ? 11 : 12} xs={helperLabel ? 11 : 12}>
        <InputMask
          type={type}
          id={id}
          name={name}
          mask={patterns}
          onChange={setValues}
          value={values}
          onBlur={onBlur}
          label={label}
          variant="outlined"
          classes={{ root: classes.root }}
          className={className}
          inputRef={ref}
          error={Boolean(errors[name]?.message)}
          helperText={errors[name]?.message ?? ""}
          fullWidth
          style={style}
          disabled={disabled}
          inputProps={{ minlength }}
        />
      </Grid>
    </Grid>
  );
});

MaterialInputCpf.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  defaultValue: PropTypes.string,
  renderIconShowHide: PropTypes.bool,
  errors: PropTypes.oneOfType([PropTypes.object]),
  style: PropTypes.oneOfType([PropTypes.object]),
  className: PropTypes.oneOfType([PropTypes.any]),
  searchAdornment: PropTypes.bool,
  onBlur: PropTypes.oneOfType([PropTypes.func]),
  helperLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  maxLength: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  permiteValorBranco: PropTypes.bool
};

MaterialInputCpf.defaultProps = {
  label: "",
  id: "",
  type: "text",
  defaultValue: "",
  renderIconShowHide: false,
  errors: {},
  style: {},
  className: undefined,
  searchAdornment: false,
  onBlur: () => {},
  helperLabel: "",
  maxLength: "",
  disabled: false,
  permiteValorBranco: false
};

export default MaterialInputCpf;
