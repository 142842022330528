import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";

// ApexChart
import ReactApexChart from "react-apexcharts";

// Styles
import { useStyles } from "./style";
import { Base } from "../../../../../componentes/cores";
import theme from 'themes';

const CustoCativo = ({ dadosCativo }) => {
	const classes = useStyles();

	const [custoCativo, setCustoCativo] = useState();
	const [labels, setLabels] = useState();

	const formatarTotal = (valor) => {
		return valor?.toLocaleString("pt-br", {
			style: "currency",
			currency: "BRL",
			maximumFractionDigits: 0,
			minimumFractionDigits: 0
		});
	};

	const legendaTooltip = (legenda) => {
		return legenda.charAt(0).toUpperCase() + legenda.slice(1);
	};

	const dados = useMemo(
		() => ({
			series: custoCativo,
			options: {
				chart: {
					type: "donut"
				},
				labels,
				colors: [theme.color.donutEnergiaCativo, theme.color.donutDistribuidoraCativo, theme.color.donutImpostoCativo, theme.color.donutOutrosCativo],
				stroke: {
					width: 0
				},
				legend: {
					fontWeight: "bold",
					fontSize: "16px",
					formatter(seriesName, opts) {
						return [
							legendaTooltip(seriesName),
							" ",
							opts.w.globals.series[opts.seriesIndex]?.toLocaleString("pt-br", {
								style: "currency",
								currency: "BRL",
								maximumFractionDigits: 0,
								minimumFractionDigits: 0
							})
						];
					},
					labels: {
						colors: theme.color.textoBarra,
						fontFamily: theme.font.family,
						fontSize: "18px"
					},
					markers: {
						radius: 0
					},
					// offsetY: "auto",
					position: "bottom"
				},
				plotOptions: {
					pie: {
						offsetX: -90,
						donut: {
							labels: {
								show: true,
								name: {
									show: true
								},
								value: {
									show: true,
									color: theme.color.textoBarra,
									fontSize: "22px",
									fontWeight: 800,
									formatter(value) {
										return formatarTotal(parseFloat(value));
									}
								},
								total: {
									show: true,
									showAlways: true,
									color: theme.color.textoBarra,
									label: "Custo Cativo",
									formatter(value) {
										return formatarTotal(
											value.globals.seriesTotals.reduce((a, b) => {
												return a + b;
											}, 0)
										);
									}
								}
							},
							size: "25%"
						},
						customScale: 0.9
					}
				},
				dataLabels: {
					enabled: false
				},
				yaxis: {
					labels: {
						formatter(val) {
							return val?.toLocaleString("pt-br", {
								style: "currency",
								currency: "BRL",
								maximumFractionDigits: 0,
								minimumFractionDigits: 0
							});
						}
					}
				},
				tooltip: {
					y: {
						title: {
							formatter: (seriesName) => {
								return legendaTooltip(seriesName);
							}
						}
					}
				}
			}
		}),
		[custoCativo, labels]
	);

	useEffect(() => {
		if (dadosCativo) {
			setLabels(Object.keys(dadosCativo));
			setCustoCativo([
				parseFloat(dadosCativo?.energia),
				parseFloat(dadosCativo?.distribuidora),
				parseFloat(dadosCativo?.impostos),
				parseFloat(dadosCativo?.outros)
			]);
		}
	}, [dadosCativo]);

	return (
		<div className={classes.container}>
			{dadosCativo && dados.options && dados.series ? (
				<ReactApexChart
					options={dados.options}
					series={dados.series}
					type="donut"
				/>
			) : null}
		</div>
	);
};

CustoCativo.propTypes = {
	dadosCativo: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
};

CustoCativo.defaultProps = {
	dadosCativo: {}
};

export default CustoCativo;
