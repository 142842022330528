import React, { useState, useMemo, useEffect } from "react";
import PropTypes from "prop-types";

// Componentes
import { FormControl, InputLabel, NativeSelect } from "@mui/material";

// Icones
import ExpandMore from "@mui/icons-material/ExpandMore";

// Styles
import { customStyles, useStyles } from "./style";

const CampoDropDownTabela = React.forwardRef((props, ref) => {
  const {
    id,
    name,
    value,
    onChange,
    dataSource,
    valueKey,
    valueName,
    label,
    maxHeight,
    maxWidth,
    marginBottom,
    placeholder,
    hideLabel,
    customSelect,
    className,
    labelClassName,
    disabled,
    parametros
  } = props;

  const mixedStyle = customSelect
    ? customStyles({ maxHeight, maxWidth, marginBottom, disabled })
    : useStyles({ maxHeight, maxWidth, marginBottom, disabled });

  const [aberto, setAberto] = useState(false);

  const selectProps = useMemo(() => {
    if (customSelect)
      return {
        IconComponent: () => (
          <ExpandMore
            className={mixedStyle.selectIcon}
            style={{ transform: `${aberto && "rotate(-180deg)"}` }}
          />
        ),
        onOpen: () => setAberto(true),
        onClose: () => setAberto(false)
      };
    return {};
  }, [customSelect, mixedStyle, aberto]);

  const [customValue, setCustomValue] = useState("");

  useEffect(() => {
    setCustomValue(value);
  }, [value]);

  const botaoPlanoDesabilitado =
    parametros?.status === "Expirada" ||
    parametros?.status === "Fechada" ||
    parametros?.status === "Cancelada" ||
    parametros?.status === "Rejeitada" ||
    parametros?.status === "Anulada";

  return (
    <FormControl
      variant={disabled ? "disabled" : "outlined"}
      fullWidth
      className={mixedStyle.formControl}
    >
      {!hideLabel && (
        <InputLabel
          className={`${mixedStyle.label} ${labelClassName}`}
          hidden={Boolean(value)}
          htmlFor={`${id}Label`}
          disabled={disabled}
        >
          {placeholder}
        </InputLabel>
      )}
      <NativeSelect
        variant={disabled ? "disabled" : "outlined"}
        value={customValue}
        onChange={onChange}
        id={String(id)}
        name={name}
        inputRef={ref}
        SelectProps={selectProps}
        label={!value ? "" : label}
        className={className}
        disabled={disabled || botaoPlanoDesabilitado}
      >
        {dataSource?.length
          ? dataSource.map((item, i) => {
              return (
                <option key={i} value={String(item[valueKey])}>
                  {item[valueName]}
                </option>
              );
            })
          : []}
      </NativeSelect>
    </FormControl>
  );
});

CampoDropDownTabela.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.any]),
  onChange: PropTypes.oneOfType([PropTypes.func]),
  dataSource: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  valueKey: PropTypes.string,
  valueName: PropTypes.string,
  label: PropTypes.string,
  maxHeight: PropTypes.string,
  maxWidth: PropTypes.string,
  marginBottom: PropTypes.string,
  placeholder: PropTypes.string,
  hideLabel: PropTypes.bool,
  customSelect: PropTypes.bool,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  parametros: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

CampoDropDownTabela.defaultProps = {
  id: "",
  name: "",
  value: "",
  onChange: () => {},
  dataSource: [],
  valueKey: "",
  valueName: "",
  label: "",
  maxHeight: "auto",
  maxWidth: "auto",
  marginBottom: "10px",
  placeholder: "",
  hideLabel: false,
  customSelect: false,
  className: "",
  labelClassName: "",
  parametros: {}
};

export default CampoDropDownTabela;
