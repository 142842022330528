import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { cnpj, cpf } from "cpf-cnpj-validator";
import { extendMoment } from "moment-range";

// Form Hooks
import { useForm } from "react-hook-form";

// Redux
import { useSelector } from "react-redux";

// Componentes
import { Box, Card, Divider, Grid } from "@mui/material";
import Loader from "../../../../componentes/loader";
import Botao from "../../../../componentes/botao";
import MaterialInputTexto from "../../../../componentes/inputTexto/materialInput";
import MaterialInputMascara from "../../../../componentes/inputTextoMascara";
import SelectArredondado from "../../../../componentes/selectArredondado";
import MaterialSwitch from "../../../../componentes/switch";
import SelectHierarquia from "../../../../componentes/selectHierarquia";
import BotaoRetornarListagem from "../../../../componentes/botaoRetornarListagem";
import ModalConfirmacao from "../../../../componentes/modalConfirmacao";
import InputRadioGroup from "../../../../componentes/inputRadioGroup";
import InputData from "../../../../componentes/inputTextoData";

// Serviços
import {
  buscarPorId,
  salvarCliente,
  buscarStatus,
  buscarPorCnpj,
  listarClientesCombo
} from "../../../../servicos/clientesServico";
import { listarArvoresGerenciais } from "../../../../servicos/arvoresGerenciaisServico";
import { listarColaboradores } from "../../../../servicos/colaboradoresServico";
import { obterCepCobertura } from "../../../../servicos/correiosServico";
import { usuarioPossuiFuncionalidade } from "../../../../servicos/funcionalidadesServico";
import ClienteHelper from "../helper";
import { estadoCivilList, sexoList } from "../../../../global/constantes";

// Rotas
import { RotasDTO } from "../../../../global/rotas/rotasUrlDto";

// Styles
import { useStyles } from "./style";
import "react-phone-input-2/lib/material.css";

// Enum
import { usuarioTipoEnum } from "../../../../global/constantes";

// Redux
import { alertaExibir } from "../../../../global/redux/modulos/alertas/actions";
import { store } from "../../../../global/redux";
import { unidadesFederativas } from "../../../../global/constantes";
import {
  desabilitarCliente,
  desabilitarEmpresa,
  desabilitarUnidade,
  salvarClientes,
  selecionarCliente,
  selecionarEmpresa,
  selecionarUnidade
} from "../../../../global/redux/modulos/usuario/actions";
import MaterialInputTelefone from "../../../../componentes/inputTexto/materialInputTelefone";
import { buscarDadosCadastraisPorCnpj } from "../../../../servicos/dadosCadastraisCnpjServico";

// DTO
import { InterfaceDTO } from "global/dto/interfacesDto";
import theme from "themes";
import { removeMaskCep, validarErrors } from "servicos/utils";
import { InputTextoCEP } from "componentes";
import ModalMensagemErro from "componentes/ModalMensagemErro";

const ClienteCadastro = () => {
  const { id } = useParams();
  const classes = useStyles();
  const history = useHistory();
  const { register, errors, handleSubmit, setValue } = useForm({
    reValidateMode: "onSubmit"
  });
  const global = useSelector((state) => state.usuario);
  const { empresaSelecionada, menu, usuario } = global;
  const rotas = useSelector((state) => state.rotas);
  const moment = extendMoment(window.moment);

  //#region useState
  const [arvoreGerencialId, setArvoreGerencialId] = useState();
  const [colaboradorId, setColaboradorId] = useState();
  const [situacao, setSituacao] = useState(false);
  const [consumidorLivre, setConsumidorLivre] = useState(false);
  const [ufId, setUFId] = useState(null);
  const [cep, setCep] = useState(null);
  const [statusId, setStatusId] = useState();
  const [carregandoCliente, setCarregandoCliente] = useState(false);
  const [dadosCliente, setDadosCliente] = useState();
  const [numero, setNumero] = useState(null);
  const [complemento, setComplemento] = useState(null);
  const [isUpdateArvoreGerencialId, setIsUpdateArvoreGerencialId] = useState(
    false
  );
  const [carregandoEndereco, setCarregandoEndereco] = useState(false);
  const [carregandoCnpj, setCarregandoCnpj] = useState(false);
  const [razaoSocial, setRazaoSocial] = useState("");
  const [nomeFantasia, setNomeFantasia] = useState("");
  const [telefone, setTelefone] = useState("");
  const [estadoCivil, setEstadoCivil] = useState("");
  const [sexo, setSexo] = useState("");
  const [profissao, setProfissao] = useState("");
  const [bairro, setBairro] = useState(null);
  const [cidade, setCidade] = useState(null);
  const [endereco, setEndereco] = useState(null);

  // Status
  const [listaStatus, setListaStatus] = useState([]);
  const [carregandoStatus, setCarregandoStatus] = useState(false);
  const [habilitarCampos, setHabilitarCampos] = useState(true);
  const [listaOfficer, setListaOfficer] = useState([]);
  const [carregandoOfficer, setCarregandoOfficer] = useState(false);
  // Arvore Gerencial
  const [listaArvoresGerenciais, setListaArvoresGerenciais] = useState([]);
  const [
    carregandoArvoresGerenciais,
    setCarregandoArvoresGerenciais
  ] = useState(false);
  // UF
  const [listaUF, setListaUF] = useState([]);
  const [carregandoUF, setCarregandoUF] = useState(false);
  const [clienteExistente, setClienteExistente] = useState(false);
  const [idClienteExistente, setIdClienteExistente] = useState();
  const [limparCnpj, setLimparCnpj] = useState(false);

  const [tipoClienteSelecionado, setTipoClienteSelecionado] = useState("PJ");
  const [labelNomeFantasia, setLabelNomeFantasia] = useState("Nome Fantasia");
  const [larguraGridNomeFantasia, setlarguraGridNomeFantasia] = useState(3);
  const [labelCpfCnpj, setLabelCpfCnpj] = useState("CNPJ");
  const [maskCpfCnpj, setMaskCpfCnpj] = useState("##.###.###/####-##");
  const [dataNascimento, setDataNascimento] = useState(null);
  const [listaEstadoCivil, setListaEstadoCivil] = useState([estadoCivilList]);
  const [listaSexo, setListaSexo] = useState([sexoList]);
  const [carregando, setCarregando] = useState(false);
  const [rgContatoInicial, setRgContatoInicial] = useState(null);
  const [inscricaoEstadual, setInscricaoEstadual] = useState(null);
  const [inscricaoMunicipal, setInscricaoMunicipal] = useState(null);
  const [nomeContatoInicial, setNomeContatoInicial] = useState(null);
  const [
    nacionalidadeContatoInicial,
    setNacionalidadeContatoInicial
  ] = useState(null);

  const [mensagemErrors, setMensagemErrors] = useState({});
  const [modalAcao, setModalAcao] = useState(false);

  const carregarCep = useCallback(async (valorCep) => {
    try {
      setCarregandoEndereco(true);
      const endereco = await obterCepCobertura(valorCep);

      if (endereco?.status === 200) {
        setUFId(endereco.data.uf);
        setCidade(endereco.data.cidade);
        setBairro(endereco.data.bairro);
        setEndereco(endereco.data.logradouro);
        setNumero(null);
        setComplemento(endereco?.data?.complemento);
      }

      setCarregandoEndereco(false);
    } catch (error) {
      setUFId(null);
      setNumero("");
      setComplemento("");
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Não foi possível localizar o CEP!"
        })
      );
      setCarregandoEndereco(false);
    }
  }, []);

  const onBlurCep = (cepValor) => {
    if (cepValor.length > 7) {
      setCep(cepValor);
      carregarCep(removeMaskCep(cepValor));
    }
  };

  const obterCliente = useCallback(
    async (idCliente) => {
      try {
        const cliente = await buscarPorId(idCliente);
        if (cliente?.status === 200 && cliente?.data) {
          //Dados comuns
          onChangeTipoCliente(cliente?.data?.pessoaFisicaJuridica);
          setDadosCliente(cliente?.data);
          setIsUpdateArvoreGerencialId(true);
          setNomeFantasia(cliente?.data?.nomeFantasia);
          setTelefone(cliente?.data?.telefone);
          setColaboradorId(cliente?.data?.colaboradorId);
          setSituacao(
            String(cliente?.data?.situacao).toLocaleUpperCase() === "AT"
          );
          setStatusId(cliente?.data?.status);

          //Pessoa Jurídica
          if (cliente?.data?.pessoaFisicaJuridica === "PJ") {
            setRazaoSocial(cliente?.data?.razaoSocial);
            setConsumidorLivre(cliente?.data?.consumidorLivre);
            setNomeContatoInicial(cliente?.data?.nomeContatoInicial);
            setInscricaoEstadual(cliente?.data?.inscricaoEstadual);
            setInscricaoMunicipal(cliente?.data?.inscricaoMunicipal);
          }

          //Pessoa física
          setRgContatoInicial(cliente?.data?.rgContatoInicial);
          setDataNascimento(
            cliente?.data?.dataNascimento
              ? new Date(cliente?.data?.dataNascimento)
              : null
          );
          setEstadoCivil(cliente?.data?.estadoCivil);
          setSexo(cliente?.data?.sexo);
          setProfissao(cliente?.data?.profissao);

          //Endereço
          setValue("cep", cliente?.data?.cep);

          setCep(removeMaskCep(cliente?.data?.cep));
          setNacionalidadeContatoInicial(cliente?.data?.nacionalidade);

          setUFId(cliente.data.uf);
          setCidade(cliente.data.cidade);
          setBairro(cliente.data.bairro);
          setEndereco(cliente.data.endereco);
          setNumero(cliente?.data?.numero);
          setComplemento(cliente?.data?.complemento);
        }
      } catch (error) {
        store.dispatch(
          alertaExibir({
            tipo: "warning",
            mensagem:
              error?.response?.data?.message ??
              "Erro interno, entre em contato com o suporte!"
          })
        );
      }
    },
    [setValue]
  );

  const onChangeArvoreGerencial = (arvoreGerencial) =>
    setArvoreGerencialId(arvoreGerencial);
  const onChangeOfficer = (officer) => setColaboradorId(officer.target.value);
  const onChangeSituacao = (valor) => setSituacao(valor);
  const onChangeConsumidorLivre = (valor) => setConsumidorLivre(valor);
  const onChangeUF = (uf) => setUFId(uf.target.value);
  const onChangeStatus = (status) => setStatusId(status.target.value);

  const onBlurNomeFantasia = (nomeFantasia) => {
    let nomeFantasiaFormatado = nomeFantasia
      .replaceAll("'", "")
      .replaceAll('"', "");
    setNomeFantasia(nomeFantasiaFormatado);
  };

  const checkPermissaoEditar = useMemo(() => {
    const rota = Object.keys(RotasDTO).find(
      (key) => RotasDTO[key] === RotasDTO[rotas.rotaAtual.caminho]
    );

    if (menu?.length && rota) {
      const funcionalidade = menu.find((item) =>
        item.functionality.funcionalidadeColecao.find(
          (funcionalidade) => funcionalidade.caminho === rota
        )
      );

      if (!funcionalidade) return false;

      if (funcionalidade?.functionality?.funcionalidadeColecao?.length) {
        const segmento = funcionalidade?.functionality?.funcionalidadeColecao.find(
          (item) => RotasDTO[item.caminho] === RotasDTO.Clientes
        );

        if (!segmento) return false;

        if (segmento?.funcionalidadeColecao?.length) {
          const filtro = segmento?.funcionalidadeColecao.find(
            (item) => InterfaceDTO[item.caminho] === InterfaceDTO.PodeEditar
          );

          return Boolean(filtro);
        } else {
          return false;
        }
      }
    }
  }, [menu, rotas]);

  const obterOfficer = async (colaboradorId) => {
    try {
      setCarregandoOfficer(true);
      const lista = await listarColaboradores(colaboradorId);
      if (lista?.status === 200 && lista?.data) {
        setListaOfficer(lista?.data);
      }
      setCarregandoOfficer(false);
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
      setCarregandoOfficer(false);
    }
  };

  const obterArvoresGerenciais = async (empresa) => {
    try {
      setListaArvoresGerenciais([]);
      setCarregandoArvoresGerenciais(true);
      const lista = await listarArvoresGerenciais("AT", empresa);
      if (lista?.status === 200 && lista?.data) {
        setListaArvoresGerenciais(lista?.data);
      }
      setCarregandoArvoresGerenciais(false);
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
      setCarregandoArvoresGerenciais(false);
    }
  };

  const obterStatus = async () => {
    try {
      setCarregandoStatus(true);
      const lista = await buscarStatus();
      if (lista?.status === 200 && lista?.data) {
        setListaStatus(lista?.data);
      }
      setCarregandoStatus(false);
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
      setCarregandoStatus(false);
    }
  };

  const obterUF = async () => {
    try {
      setCarregandoUF(true);
      const lista = {
        data: unidadesFederativas,
        status: 200
      };
      if (lista?.status === 200 && lista?.data) {
        setListaUF(lista?.data);
      }
      setCarregandoUF(false);
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
      setCarregandoUF(false);
    }
  };

  const recarregarClientesFiltro = async () => {
    if (global?.empresaSelecionada) {
      const lista = await listarClientesCombo(global?.empresaSelecionada);
      if (lista?.status === 200 && lista?.data?.clientes) {
        store.dispatch(salvarClientes(lista?.data?.clientes ?? []));
      }
    }
  };

  const enviarFormulario = async (dados) => {
    if (!global?.empresaSelecionada) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem: "É necessário selecionar uma Empresa"
        })
      );
      setCarregandoCliente(false);
    } else {
      setCarregandoCliente(true);
      let hoje = new Date();
      const dd = String(hoje.getDate()).padStart(2, "0");
      const mm = String(hoje.getMonth() + 1).padStart(2, "0");
      const yyyy = hoje.getFullYear();
      hoje = `${yyyy}-${mm}-${dd}`;

      const cnpjFormatado = dados?.cnpj?.replace(/[^a-zA-Z0-9]/g, "");
      const telefoneFormatado = dados.telefone?.replace(/[^a-zA-Z0-9]/g, "");
      const nomeContato = dados?.nomeContatoInicial;
      const emailContato = dados?.emailContatoInicial;
      delete dados?.nomeContatoInicial;
      delete dados?.emailContatoInicial;

      const parametros = {
        CnpjCpf: cnpjFormatado,
        RazaoSocial: razaoSocial,
        NomeFantasia: nomeFantasia,
        ArvoreGerencialId: Number(arvoreGerencialId),
        InscricaoEstadual: inscricaoEstadual,
        InscricaoMunicipal: inscricaoMunicipal,
        Status: statusId,
        Cep: removeMaskCep(cep),
        Endereco: endereco ?? "",
        Numero: numero ?? "",
        Complemento: complemento,
        UF: ufId ?? "",
        Bairro: bairro ?? "",
        Cidade: cidade ?? "",
        telefone: telefoneFormatado,
        PessoaFisicaJuridica: tipoClienteSelecionado,
        Situacao: id ? (situacao ? "AT" : "IN") : "AT",
        DataSituacao: hoje,
        ColaboradorId: Number(colaboradorId),
        AgenteCCEEId: "0",
        EmpresaGrupoDeltaId: global.empresaSelecionada,
        DataNascimento: dataNascimento,
        EstadoCivil: estadoCivil,
        Sexo: sexo,
        Profissao: profissao,
        ContatoInicial: {
          NomeCompleto: nomeContato,
          Email: emailContato,
          RG: rgContatoInicial
        },
        ConsumidorLivre: consumidorLivre
      };

      if (tipoClienteSelecionado === "PF") {
        parametros.nacionalidade = dados?.nacionalidadeContatoInicial;
      }

      try {
        const salvou = await salvarCliente(Number(id) ?? 0, parametros);

        if (salvou?.status === 200 || salvou?.status === 204) {
          store.dispatch(
            alertaExibir({
              tipo: "success",
              mensagem: "Cliente salvo com sucesso!"
            })
          );
          setTimeout(() => {
            recarregarClientesFiltro();
            history.push({
              pathname: RotasDTO.Clientes,
              state: {
                dadosCliente: salvou?.data
              }
            });
            store.dispatch(selecionarEmpresa(null));
            store.dispatch(selecionarCliente(null));
            store.dispatch(selecionarUnidade(null));
          }, 2000);
        }

        setCarregandoCliente(false);
      } catch (error) {
        if (error.response.status === 400) {
          setModalAcao(true);
          setMensagemErrors(JSON.parse(error?.response?.request?.response));
          return;
        }
        store.dispatch(
          alertaExibir({
            tipo: "warning",
            mensagem: error?.response?.data?.message
          })
        );
      } finally {
        setCarregandoCliente(false);
      }
    }
  };

  const aoEnviarFormulario = (dados) => enviarFormulario(dados);

  const onConfirmarTelaEdicao = () => {
    history.push(`${RotasDTO.Clientes}/cadastro/${idClienteExistente}`);
    setClienteExistente(false);
  };

  const verificarCnpj = useCallback(
    async (valor) => {
      setCarregandoCnpj(true);

      if (!dadosCliente) {
        const cnpjFormatado = valor?.target.value.replace(/[^a-zA-Z0-9]/g, "");
        if (cnpjFormatado.length === 14) {
          try {
            const cliente = await buscarPorCnpj(cnpjFormatado);

            if (cliente) {
              setIdClienteExistente(cliente?.data?.id);
              setCarregandoCnpj(false);
              return;
            }
          } catch {}

          try {
            const dadosCadastrais = await buscarDadosCadastraisPorCnpj(
              cnpjFormatado
            );

            if (dadosCadastrais?.status === 200 && dadosCadastrais?.data) {
              setRazaoSocial(dadosCadastrais?.data?.data?.razaoSocial);

              let nomeFantasiaFormatado = dadosCadastrais?.data?.data?.nomeFantasia
                .replaceAll("'", "")
                .replaceAll('"', "");
              setNomeFantasia(nomeFantasiaFormatado);

              if (
                dadosCadastrais?.data?.data?.situacaoCadastral !== "2" &&
                dadosCadastrais?.data?.data?.descricaoDaSituacaoCadastral !==
                  "Ativa"
              ) {
                store.dispatch(
                  alertaExibir({
                    tipo: "warning",
                    mensagem:
                      "CNPJ inserido encontra-se irregular na Receita Federal"
                  })
                );
              }
            }
          } catch (error) {
            store.dispatch(
              alertaExibir({
                tipo: "warning",
                mensagem:
                  error?.response?.data?.message ??
                  "CNPJ não encontrado na Receita Federal"
              })
            );
          } finally {
            setCarregandoCnpj(false);
          }
        }
      }
      setCarregandoCnpj(false);
    },
    [dadosCliente]
  );

  const nomeEmpresa = useMemo(() => {
    let empresa;

    if (dadosCliente?.empresaGrupoDeltaId) {
      empresa = global?.empresas.find(
        (item) => String(item?.id) === String(dadosCliente?.empresaGrupoDeltaId)
      );
    } else {
      empresa = global?.empresas.find(
        (item) => String(item?.id) === String(global?.empresaSelecionada)
      );
    }

    return empresa?.nomeFantasia ?? "";
  }, [global.empresas, global.empresaSelecionada, dadosCliente]);

  const onChangeTipoCliente = async (valor) => {
    setTipoClienteSelecionado(valor);
    setLabelNomeFantasia(valor == "PJ" ? "Nome Fantasia" : "Nome do Titular");
    setlarguraGridNomeFantasia(valor == "PJ" ? 3 : 6);
    setLabelCpfCnpj(valor == "PJ" ? "CNPJ" : "CPF");
    setMaskCpfCnpj(valor == "PJ" ? "##.###.###/####-##" : "###.###.###-##");

    if (valor == "PJ") {
      setDataNascimento(null);
      setEstadoCivil("");
      setSexo("");
    } else {
      setInscricaoEstadual(null);
    }
  };

  const obterListaEstadoCivil = async () => {
    try {
      setCarregando(true);
      const lista = {
        data: estadoCivilList,
        status: 200
      };
      if (lista?.status === 200 && lista?.data) {
        setListaEstadoCivil(lista?.data);
      }
      setCarregando(false);
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
      setCarregando(false);
    }
  };

  const obterListaSexo = async () => {
    try {
      setCarregando(true);
      const lista = {
        data: sexoList,
        status: 200
      };
      if (lista?.status === 200 && lista?.data) {
        setListaSexo(lista?.data);
      }
      setCarregando(false);
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
      setCarregando(false);
    }
  };

  function validarCpfCnpj(value) {
    let validacao;

    if (tipoClienteSelecionado === "PJ")
      validacao = cnpj.isValid(value) || "CNPJ inválido!";
    else validacao = cpf.isValid(value) || "CPF inválido!";

    return validacao;
  }

  useEffect(() => {
    store.dispatch(selecionarEmpresa());
  }, []);

  useEffect(() => {
    if (!id && !dadosCliente?.arvoreGerencialId) {
      setArvoreGerencialId();
    }

    if (id && dadosCliente?.arvoreGerencialId) {
      if (dadosCliente?.empresaGrupoDeltaId != empresaSelecionada) {
        setArvoreGerencialId();
      }
    }

    if (empresaSelecionada) obterArvoresGerenciais(empresaSelecionada);
  }, [empresaSelecionada]);

  useEffect(() => {
    if (
      typeof dadosCliente === "object" &&
      Object.entries(dadosCliente)?.length
    ) {
      try {
        if (dadosCliente?.empresaGrupoDeltaId) {
          store.dispatch(selecionarEmpresa(dadosCliente?.empresaGrupoDeltaId));
        }
      } catch (error) {
        store.dispatch(
          alertaExibir({
            tipo: "warning",
            mensagem:
              error?.response?.data?.message ??
              "Erro interno, entre em contato com o suporte!"
          })
        );
      } finally {
        setArvoreGerencialId(dadosCliente?.arvoreGerencialId);
      }
    }
  }, [dadosCliente]);

  useEffect(() => {
    if (id) {
      setHabilitarCampos(checkPermissaoEditar);
      obterCliente(id);
    } else {
      const possuiFuncionalidadeCadastrarCliente = usuarioPossuiFuncionalidade(
        menu,
        rotas.rotaAtual.caminho,
        RotasDTO.Clientes,
        InterfaceDTO.PermissaoCadastrarCliente
      );
      setHabilitarCampos(possuiFuncionalidadeCadastrarCliente);
    }
  }, [id, obterCliente, checkPermissaoEditar]);

  useEffect(() => {
    const buscarColaborador =
      usuario.tipoPerfil !== usuarioTipoEnum.colaborador && colaboradorId
        ? colaboradorId
        : null;
    if (
      usuario.tipoPerfil === usuarioTipoEnum.colaborador ||
      (id && colaboradorId && !habilitarCampos)
    )
      obterOfficer(buscarColaborador);
  }, [colaboradorId, habilitarCampos]);

  useEffect(() => {
    store.dispatch(desabilitarEmpresa(false));
    store.dispatch(desabilitarCliente(true));
    store.dispatch(desabilitarUnidade(true));

    return () => {
      store.dispatch(desabilitarCliente(false));
      store.dispatch(desabilitarUnidade(false));
    };
  }, [desabilitarEmpresa, desabilitarCliente, desabilitarUnidade]);

  useEffect(() => {
    obterStatus();
  }, []);

  useEffect(() => {
    obterUF();
  }, []);

  useEffect(() => {
    if (idClienteExistente) setClienteExistente(true);
  }, [idClienteExistente]);

  useEffect(() => {
    store.dispatch(selecionarCliente());
  }, [global.empresaSelecionada, global.clienteSelecionado]);

  useEffect(() => {
    obterListaEstadoCivil();
    obterListaSexo();
  }, []);

  const handleMensagemModal = (msgErrors) => {
    const msg = validarErrors(msgErrors);
    return (
      <>
        {msg &&
          msg.map((item) => (
            <Box
              component="div"
              fontWeight="700"
              fontSize="16px"
              textAlign="center"
            >
              <Box component="span" fontWeight="900" fontSize="20px">
                {item.nameError}
              </Box>
              <Box component="span">
                {item?.valueError
                  ?.map((value) => value)
                  ?.splice(",")[1]
                  ?.replaceAll("'", "")
                  ?.replace(`${item?.nameError}`, "")}
              </Box>
            </Box>
          ))}
      </>
    );
  };

  return (
    <>
      <ModalMensagemErro
        item={modalAcao}
        titulo=""
        mensagem={handleMensagemModal(mensagemErrors)}
        onCancelar={() => {
          setModalAcao(false);
          setMensagemErrors({});
        }}
        onConfirmar={() => {}}
        texto=""
        footer={false}
      />
      <ModalConfirmacao
        item={clienteExistente}
        onConfirmar={() => onConfirmarTelaEdicao()}
        mensagem="CNPJ já cadastrado. Deseja ir para a tela de edição?"
        onCancelar={() => {
          setClienteExistente(false);
          setLimparCnpj(true);
          setIdClienteExistente();
        }}
      />
      <form
        className="needs-validation"
        onSubmit={handleSubmit(aoEnviarFormulario)}
      >
        <Card className={classes.cardCadastro}>
          <Grid container p={2} spacing={4} className={classes.container}>
            <Grid item xs={6} className="font-weight-bold">
              {dadosCliente?.id ? "Editar cliente" : "Novo cliente"}
              {nomeEmpresa !== "" ? ` na empresa ${nomeEmpresa}` : ""}
            </Grid>
            <BotaoRetornarListagem
              urlListagem={{
                pathname: RotasDTO.Clientes,
                state: { dadosCliente }
              }}
              zerarCombo
            />
            <Grid item lg={12} md={12} sm={12} className={classes.inputRadio}>
              <InputRadioGroup
                type="text"
                id="tipoCliente"
                name="tipoCliente"
                label="Tipo de Cliente"
                renderIconShowHide={false}
                disabled={dadosCliente?.id ? true : false}
                customValue={tipoClienteSelecionado}
                onChange={(valor) => onChangeTipoCliente(valor.target.value)}
                defaultValue={tipoClienteSelecionado}
                ref={register({
                  required: "Campo Tipo de Cliente é obrigatório!"
                })}
                errors={errors}
                className={classes.inputRadio}
                classNameLabel={classes.inputRadioLabel}
                itens={ClienteHelper.obterTipoCliente()}
              />
            </Grid>
            <Grid item lg={3} md={3} sm={5}>
              <Loader loading={carregandoCnpj}>
                <MaterialInputMascara
                  readOnly={Boolean(dadosCliente?.id)}
                  type="text"
                  id="cnpj"
                  name="cnpj"
                  label={labelCpfCnpj}
                  mask={maskCpfCnpj}
                  disabled={!habilitarCampos}
                  onBlur={(valor) =>
                    tipoClienteSelecionado == "PJ" ? verificarCnpj(valor) : null
                  }
                  limparValor={limparCnpj}
                  redefineLimpar={(estado) => setLimparCnpj(estado)}
                  renderIconShowHide={false}
                  defaultValue={dadosCliente?.cnpjCpf}
                  ref={register({
                    required: "Campo " + labelCpfCnpj + " é obrigatório!",
                    maxLength: {
                      value: 18,
                      message: "Quantidade máxima de 18 caracteres!"
                    },
                    minLength: {
                      value: 11,
                      message: "Quantidade mínima de 11 caracteres!"
                    },
                    validate: (value) => validarCpfCnpj(value)
                  })}
                  errors={errors}
                />
              </Loader>
            </Grid>

            {tipoClienteSelecionado === "PJ" ? (
              <>
                <Grid item lg={6} md={6} sm={6}>
                  <Loader loading={carregandoCnpj}>
                    <MaterialInputTexto
                      type="text"
                      id="razaoSocial"
                      name="razaoSocial"
                      label="Razão Social"
                      permiteValorBranco
                      renderIconShowHide={false}
                      disabled={!habilitarCampos}
                      defaultValue={razaoSocial}
                      onBlur={(valor) => setRazaoSocial(valor.target.value)}
                      ref={register({
                        required: "Campo Razão Social é obrigatório!",
                        maxLength: {
                          value: 100,
                          message: "Quantidade máxima de 100 caracteres!"
                        }
                      })}
                      errors={errors}
                    />
                  </Loader>
                </Grid>
              </>
            ) : null}

            <Grid
              item
              lg={larguraGridNomeFantasia}
              md={larguraGridNomeFantasia}
              sm={6}
            >
              <Loader loading={carregandoCnpj}>
                <MaterialInputTexto
                  type="text"
                  id="nomeFantasia"
                  name="nomeFantasia"
                  label={labelNomeFantasia}
                  disabled={!habilitarCampos}
                  renderIconShowHide={false}
                  permiteValorBranco
                  defaultValue={nomeFantasia}
                  onBlur={(event) => onBlurNomeFantasia(event.target.value)}
                  onInput={(event) => onBlurNomeFantasia(event.target.value)}
                  ref={register({
                    required: "Campo " + labelNomeFantasia + " é obrigatório!",
                    maxLength: {
                      value: 50,
                      message: "Quantidade máxima de 50 caracteres!"
                    },
                    validate: (value) =>
                      (!value.includes("'") && !value.includes('"')) ||
                      "Não é permitido adicionar aspas no Nome Fantasia"
                  })}
                  errors={errors}
                />
              </Loader>
            </Grid>

            {tipoClienteSelecionado !== "PJ" ? (
              <>
                <Grid item lg={3} md={3} sm={6}>
                  <InputData
                    type="text"
                    id="dataNascimento"
                    name="dataNascimento"
                    label="Data de Nascimento"
                    minDate={window.moment().subtract(90, "year").toDate()}
                    maxDate={moment().startOf("day").toDate()}
                    customValue={dataNascimento}
                    onChange={(data) => setDataNascimento(data)}
                    ref={register()}
                    errors={errors}
                    className={classes}
                  />
                </Grid>
              </>
            ) : null}
          </Grid>

          <Grid container p={2} spacing={4} className={classes.container}>
            {tipoClienteSelecionado === "PJ" ? (
              <>
                <Grid
                  item
                  lg={tipoClienteSelecionado === "PF" ? 4 : 3}
                  md={tipoClienteSelecionado === "PF" ? 4 : 3}
                  sm={6}
                >
                  <MaterialInputTexto
                    type="text"
                    id="inscricaoEstadual"
                    name="inscricaoEstadual"
                    label="Inscrição Estadual"
                    disabled={!habilitarCampos}
                    renderIconShowHide={false}
                    defaultValue={dadosCliente?.inscricaoEstadual}
                    onBlur={(valor) => setInscricaoEstadual(valor.target.value)}
                    maxLength={30}
                    errors={errors}
                  />
                </Grid>
                <Grid
                  item
                  lg={tipoClienteSelecionado === "PF" ? 4 : 3}
                  md={tipoClienteSelecionado === "PF" ? 4 : 3}
                  sm={6}
                >
                  <MaterialInputTexto
                    type="text"
                    id="inscricaoMunicipal"
                    name="inscricaoMunicipal"
                    label="Inscrição Municipal"
                    disabled={!habilitarCampos}
                    renderIconShowHide={false}
                    defaultValue={dadosCliente?.inscricaoMunicipal}
                    onBlur={(valor) =>
                      setInscricaoMunicipal(valor.target.value)
                    }
                    maxLength={30}
                    errors={errors}
                  />
                </Grid>
                {tipoClienteSelecionado === "PJ" ? (
                  <>
                    <Grid item lg={3} md={3} sm={6}>
                      <MaterialInputTexto
                        type="text"
                        id="nomeContatoInicial"
                        name="nomeContatoInicial"
                        label="Nome do Contato"
                        disabled={!habilitarCampos}
                        renderIconShowHide={false}
                        defaultValue={dadosCliente?.nomeContatoInicial}
                        ref={register({
                          required: "Campo Nome do Contato é obrigatório!",
                          maxLength: {
                            value: 100,
                            message: "Quantidade máxima de 100 caracteres!"
                          }
                        })}
                        errors={errors}
                      />
                    </Grid>
                  </>
                ) : null}
              </>
            ) : (
              <>
                <Grid
                  item
                  lg={tipoClienteSelecionado === "PF" ? 4 : 3}
                  md={tipoClienteSelecionado === "PF" ? 4 : 3}
                  sm={6}
                >
                  <SelectArredondado
                    id="sexo"
                    name="sexo"
                    valueKey="value"
                    valueName="label"
                    dataSource={listaSexo}
                    label="Sexo"
                    value={sexo}
                    onChange={(value) => setSexo(value.target.value)}
                    placeholder="Sexo"
                    allowClear
                    ref={register({ name: "sexo" })}
                    errors={errors}
                  />
                </Grid>

                <Grid
                  item
                  lg={tipoClienteSelecionado === "PF" ? 4 : 3}
                  md={tipoClienteSelecionado === "PF" ? 4 : 3}
                  sm={6}
                >
                  <SelectArredondado
                    id="estadoCivil"
                    name="estadoCivil"
                    valueKey="value"
                    valueName="label"
                    dataSource={listaEstadoCivil}
                    label="Estado Civil"
                    value={estadoCivil}
                    onChange={(value) => setEstadoCivil(value.target.value)}
                    placeholder="Estado civil"
                    allowClear
                    ref={register({ name: "estadoCivil" })}
                    errors={errors}
                  />
                </Grid>

                <Grid
                  item
                  lg={tipoClienteSelecionado === "PF" ? 4 : 3}
                  md={tipoClienteSelecionado === "PF" ? 4 : 3}
                  sm={6}
                >
                  <MaterialInputTexto
                    type="text"
                    id="profissao"
                    name="profissao"
                    label="Profissão"
                    disabled={!habilitarCampos}
                    renderIconShowHide={false}
                    permiteValorBranco
                    onBlur={(event) => setProfissao(event.target.value)}
                    defaultValue={profissao}
                    ref={register()}
                    errors={errors}
                  />
                </Grid>
              </>
            )}

            <Grid
              item
              lg={tipoClienteSelecionado === "PF" ? 4 : 3}
              md={tipoClienteSelecionado === "PF" ? 4 : 3}
              sm={6}
              className={classes.containerTelefone}
            >
              <MaterialInputTelefone
                type="text"
                id="telefone"
                name="telefone"
                label="Telefone"
                renderIconShowHide={false}
                disabled={!habilitarCampos}
                defaultValue={telefone}
                value={telefone}
                ref={register({
                  required: "O campo Telefone é obrigatório!",
                  minLength: {
                    value: 18,
                    message: "Quantidade mínima de 12 caracteres!"
                  }
                })}
                errors={errors}
              />
            </Grid>

            <Grid
              item
              lg={tipoClienteSelecionado === "PF" ? 4 : 3}
              md={tipoClienteSelecionado === "PF" ? 4 : 3}
              sm={6}
            >
              <MaterialInputTexto
                type="text"
                id="emailContatoInicial"
                name="emailContatoInicial"
                label="Email do Contato"
                disabled={!habilitarCampos}
                renderIconShowHide={false}
                defaultValue={dadosCliente?.emailContatoInicial}
                ref={register({
                  required: "O campo E-mail do Contato é obrigatório!",
                  pattern: {
                    value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: "Campo E-mail do Contato inválido!"
                  },
                  maxLength: {
                    value: 100,
                    message: "Quantidade máxima de 100 caracteres!"
                  }
                })}
                errors={errors}
              />
            </Grid>

            {tipoClienteSelecionado === "PF" && (
              <Grid item lg={4} md={4} sm={6}>
                <MaterialInputTexto
                  type="text"
                  id="nacionalidadeContatoInicial"
                  name="nacionalidadeContatoInicial"
                  label="Nacionalidade do Contato"
                  renderIconShowHide={false}
                  disabled={!habilitarCampos}
                  defaultValue={dadosCliente?.nacionalidade ?? ""}
                  ref={register()}
                  errors={errors}
                />
              </Grid>
            )}

            <Grid
              item
              lg={tipoClienteSelecionado === "PF" ? 4 : 3}
              md={tipoClienteSelecionado === "PF" ? 4 : 3}
              sm={6}
            >
              <Loader loading={carregandoStatus}>
                <SelectArredondado
                  id="Status"
                  name="Status"
                  valueKey="codigo"
                  valueName="descricao"
                  dataSource={listaStatus}
                  disabled={!habilitarCampos}
                  label="Status"
                  value={statusId}
                  onChange={(status) => onChangeStatus(status)}
                  placeholder="Status"
                  allowClear
                  ref={register(
                    { name: "Status" },
                    {
                      required: !statusId
                        ? "Campo Status é obrigatório!"
                        : false
                    }
                  )}
                  errors={errors}
                />
              </Loader>
            </Grid>

            <Grid
              item
              lg={tipoClienteSelecionado === "PF" ? 4 : 3}
              md={tipoClienteSelecionado === "PF" ? 4 : 3}
              sm={6}
            >
              <Loader loading={carregandoArvoresGerenciais}>
                <SelectHierarquia
                  id="arvoreGerencialId"
                  name="arvoreGerencialId"
                  valueKey="id"
                  valueName="descricao"
                  dataSource={listaArvoresGerenciais.arvoreGerencialColecao}
                  label="Árvore gerencial"
                  value={arvoreGerencialId}
                  marginBottom="0"
                  isUpdate={isUpdateArvoreGerencialId}
                  onChange={(arvoreGerencial) =>
                    onChangeArvoreGerencial(arvoreGerencial)
                  }
                  disabled={
                    !empresaSelecionada ||
                    !listaArvoresGerenciais?.arvoreGerencialColecao?.length ||
                    !habilitarCampos
                  }
                  placeholder="Árvore gerencial"
                  allowClear
                  ref={register(
                    { name: "arvoreGerencialId" },
                    {
                      required: !arvoreGerencialId
                        ? "Campo Árvore Gerencial é obrigatório!"
                        : false
                    }
                  )}
                  errors={errors}
                />
              </Loader>
            </Grid>

            <Grid
              item
              lg={tipoClienteSelecionado === "PF" ? 4 : 3}
              md={tipoClienteSelecionado === "PF" ? 4 : 3}
              sm={6}
            >
              <Loader loading={carregandoOfficer}>
                <SelectArredondado
                  id="colaboradorId"
                  name="colaboradorId"
                  valueKey="id"
                  valueName="nomeColaborador"
                  disabled={!habilitarCampos}
                  dataSource={listaOfficer}
                  label="Officer"
                  value={colaboradorId}
                  onChange={(colaborador) => onChangeOfficer(colaborador)}
                  placeholder="Officer"
                  allowClear
                  ref={register(
                    { name: "colaboradorId" },
                    {
                      required: !colaboradorId
                        ? "Campo Officer é obrigatório!"
                        : false
                    }
                  )}
                  errors={errors}
                />
              </Loader>
            </Grid>
          </Grid>

          <Grid container p={2} spacing={4} className={classes.container}>
            {tipoClienteSelecionado === "PJ" ? (
              <>
                <Grid item lg={3} md={3} sm={6}>
                  <MaterialSwitch
                    label="É Consumidor Livre?"
                    labelPlacement="top"
                    id="consumidorLivre"
                    name="consumidorLivre"
                    checked={consumidorLivre}
                    disabled={!habilitarCampos}
                    onChange={(consumidor) =>
                      onChangeConsumidorLivre(consumidor)
                    }
                  />
                </Grid>
              </>
            ) : null}

            {dadosCliente?.id ? (
              <Grid item lg={3} md={3} sm={6}>
                <MaterialSwitch
                  label="Ativo"
                  labelPlacement="top"
                  id="Situacao"
                  disabled={!habilitarCampos}
                  name="Situacao"
                  checked={situacao}
                  onChange={(valor) => onChangeSituacao(valor)}
                />
              </Grid>
            ) : null}
          </Grid>
          <Divider className={classes.divider} />
          <Grid container p={2} spacing={4} className={classes.container}>
            <Grid item lg={3} md={3} sm={6}>
              <InputTextoCEP
                id="Cep"
                name="Cep"
                label="CEP"
                carregandoEndereco={carregandoEndereco}
                onBlurCep={onBlurCep}
                defaultValue={cep ?? ""}
                register={register}
                errors={errors}
                disabled={!habilitarCampos}
              />
            </Grid>
            <Grid item lg={6} md={6} sm={12}>
              <Loader loading={carregandoEndereco}>
                <MaterialInputTexto
                  type="text"
                  id="endereco"
                  name="endereco"
                  label="Endereço"
                  disabled={!habilitarCampos}
                  permiteValorBranco
                  renderIconShowHide={false}
                  defaultValue={endereco ?? ""}
                  onBlur={(valor) => setEndereco(valor.target.value)}
                  ref={register({
                    required: "Campo Endereço é obrigatório!",
                    maxLength: {
                      value: 200,
                      message: "Quantidade máxima de 200 caracteres!"
                    }
                  })}
                  errors={errors}
                />
              </Loader>
            </Grid>
            <Grid item lg={3} md={3} sm={6}>
              <Loader loading={carregandoEndereco}>
                <MaterialInputTexto
                  type="text"
                  id="numero"
                  name="numero"
                  label="Número"
                  disabled={!habilitarCampos}
                  permiteValorBranco
                  renderIconShowHide={false}
                  defaultValue={numero ?? ""}
                  onBlur={(valor) => setNumero(valor.target.value)}
                  ref={register({
                    required: "Campo Número é obrigatório!",
                    maxLength: {
                      value: 10,
                      message: "Quantidade máxima de 10 caracteres!"
                    }
                  })}
                  errors={errors}
                />
              </Loader>
            </Grid>
          </Grid>
          <Grid container p={2} spacing={4} className={classes.container}>
            <Grid item lg={3} md={3} sm={6}>
              <Loader loading={carregandoEndereco}>
                <MaterialInputTexto
                  type="text"
                  id="complemento"
                  name="complemento"
                  label="Complemento"
                  disabled={!habilitarCampos}
                  permiteValorBranco
                  renderIconShowHide={false}
                  defaultValue={complemento ?? ""}
                  onBlur={(valor) => setComplemento(valor.target.value)}
                  ref={register({
                    maxLength: {
                      value: 50,
                      message: "Quantidade máxima de 50 caracteres!"
                    }
                  })}
                  errors={errors}
                />
              </Loader>
            </Grid>
            <Grid item lg={3} md={3} sm={6}>
              <Loader loading={carregandoEndereco}>
                <MaterialInputTexto
                  type="text"
                  id="bairro"
                  name="bairro"
                  label="Bairro"
                  disabled={!habilitarCampos}
                  renderIconShowHide={false}
                  permiteValorBranco
                  defaultValue={bairro ?? ""}
                  onBlur={(valor) => setBairro(valor.target.value)}
                  ref={register({
                    required: "Campo Bairro é obrigatório!",
                    maxLength: {
                      value: 100,
                      message: "Quantidade máxima de 100 caracteres!"
                    }
                  })}
                  errors={errors}
                />
              </Loader>
            </Grid>
            <Grid item lg={3} md={3} sm={6}>
              <Loader loading={carregandoEndereco}>
                <MaterialInputTexto
                  type="text"
                  id="cidade"
                  name="cidade"
                  label="Cidade"
                  disabled={!habilitarCampos}
                  permiteValorBranco
                  renderIconShowHide={false}
                  defaultValue={cidade ?? ""}
                  onBlur={(valor) => setCidade(valor.target.value)}
                  ref={register({
                    required: "Campo Cidade é obrigatório!",
                    maxLength: {
                      value: 100,
                      message: "Quantidade máxima de 100 caracteres!"
                    }
                  })}
                  errors={errors}
                />
              </Loader>
            </Grid>
            <Grid item lg={3} md={3} sm={6}>
              <Loader loading={carregandoUF || carregandoEndereco}>
                <SelectArredondado
                  id="uf"
                  name="uf"
                  valueKey="UF"
                  valueName="UF"
                  dataSource={listaUF}
                  label="UF"
                  disabled={!habilitarCampos}
                  value={ufId ?? ""}
                  onChange={(estado) => onChangeUF(estado)}
                  placeholder="UF"
                  allowClear
                  ref={register(
                    { name: "uf" },
                    {
                      required: !ufId ? "Campo UF é obrigatório!" : false
                    }
                  )}
                  errors={errors}
                />
              </Loader>
            </Grid>
          </Grid>
          <Grid container p={2} spacing={4} className={classes.container}>
            <Grid
              item
              lg={3}
              md={4}
              sm={6}
              xs={12}
              className={classes.containerSalvar}
            >
              <Loader loading={carregandoCliente}>
                <Botao
                  type="submit"
                  label="Salvar"
                  textColor={theme.color.btTextColor}
                  disabled={!habilitarCampos}
                  className={classes.button}
                />
              </Loader>
            </Grid>
          </Grid>
        </Card>
      </form>
    </>
  );

  //#endregion return
};

export default ClienteCadastro;
