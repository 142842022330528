import MICROSERVICO from "../global/enumeradores/microServico";
import obterApi from "./api";

const api = () => obterApi(MICROSERVICO.CALCULOCUSTOS);

// Enviar para faturamento
export const EnviarParaFaturamento = async (id) => {

  return api().post(
    `v1/PreFaturamento/SalvarEnviarParaFaturamento?id=${id}`
  );
};

export const SalvarPreFaturamento = async (dados) => {
  return api().post(
    `v1/PreFaturamento/SalvarPreFaturamento`,
    dados
  );
};

// Obter por Id
export const ObterPreFaturamentoPorId = async (id) => {
  return api().get(`v1/PreFaturamento/${id}`);
};

// Recalcular
export const Recalcular = async (
  unidadeId,
  mesReferencia,
  anoReferencia,
  dados
) => {
  return api().post(
    `v1/PreFaturamento/ReCalcular/${unidadeId}/${mesReferencia}/${anoReferencia}`,
    dados
  );
};

export const listarPreFaturamentos = async (filtro) => {
  return api().get(
    `v1/PreFaturamento?${new URLSearchParams(filtro).toString()}`
  );
};

export const listarFaturados = async (filtro) => {
  return api().get(
    `v1/PreFaturamento/ObterListaFaturados?${new URLSearchParams(filtro).toString()}`
  );
};

export const listarPreFaturamentoRastreamento = async (id) => {
  return api().get(
    `v1/PreFaturamento/rastreamento/${id}`
  );
};
