import React, { useState, useEffect, useCallback,useMemo } from "react";
import { useHistory } from "react-router-dom";
import shortid from "shortid";

// Icones
import TuneIcon from "@mui/icons-material/Tune";
import { Edit, Delete } from "@mui/icons-material";

// Form Hooks
import { useForm, useWatch } from "react-hook-form";

// Redux
import { useSelector } from "react-redux";

// Componentes
import { Grid } from "@mui/material";
import { Base } from "../../../../componentes/cores";
import Botao from "../../../../componentes/botao";
import BotaoCadastro from "../../../../componentes/botaoCadastro";
import Loader from "../../../../componentes/loader";
import ModalConfirmacao from "../../../../componentes/modalConfirmacao";
import MaterialInputTexto from "../../../../componentes/inputTexto/materialInput";
import ItemListaExpansivel from "../../../../componentes/itemListaExpansivel";
import BotaoFiltroOrdenacao from "../../../../componentes/botaoFiltroOrdenar";
import TabelaPaginada from "../../../../componentes/tabelaPaginada";
import Coluna from "../../../../componentes/tabelaPaginada/colunas/coluna";
import ColunaBotao from "../../../../componentes/tabelaPaginada/colunas/colunaBotao";
import ColunaCampoSwitch from "../../../../componentes/tabelaPaginada/colunas/colunaCampoSwitch";
import ResultadoPaginadoDto from "../../../../componentes/tabelaPaginada/resultadoPaginadoDto";
import MaterialSwitch from "../../../../componentes/switch";
import ColunaComponentePersonalizado from "componentes/tabelaPaginada/colunas/colunaComponentePersonalizado";
import enumerador from './enumerador';
import ColunaMultiplosBotoes from "./colunaCustomizada";
import LeadUnidadeConsumidoraDto from "./leadUnidadeConsumidoraDto";

// Serviços
import {
  listarUnidadesConsumidoras,
  deletarUnidade,
  listarFiltrarPor,
  listarOrganizarPor,
  buscarStatus,
  efetivarUnidadeConsumidora
} from "../../../../servicos/leadUnidadesServico";

// Rotas
import { RotasDTO } from "../../../../global/rotas/rotasUrlDto";

// Redux
import { store } from "../../../../global/redux";
import { alertaExibir } from "../../../../global/redux/modulos/alertas/actions";
import { desabilitarUnidade } from "../../../../global/redux/modulos/usuario/actions";

// Styles
import { useStyles } from "./style";
import theme from 'themes';

// Útils
import { formataCnpj } from "../../../../servicos/utils";
import ColunaCampoCheckBox from "componentes/tabelaPaginada/colunas/colunaCampoCheckBox";
import MaterialCheckBox from "componentes/checkbox";
import { InterfaceDTO } from "global/dto/interfacesDto";
import { usuarioPossuiFuncionalidade } from "servicos/funcionalidadesServico";
import FiltroAdicionalTabela from "paginas/Cadastros/Agente/filtroAdicionalTabela";
import MaterialInputBusca from "componentes/inputBusca";
import { Alerta } from "componentes";

const UnidadesListar = () => {
  const classes = useStyles();
  const history = useHistory();

  const usuario = useSelector((state) => state.usuario);
  const rotas = useSelector((state) => state.rotas);
  const { menu } = usuario;
  const permissaoImportarLeads = usuarioPossuiFuncionalidade(menu, rotas.rotaAtual.caminho, RotasDTO.LeadUnidadesConsumidoras, InterfaceDTO.PermissaoImportarLeads);
  const { empresaSelecionada } = usuario;
  const { clienteSelecionado } = usuario;
  const { clientes } = usuario;

  useEffect(() => {
    store.dispatch(desabilitarUnidade(true));

    return () => store.dispatch(desabilitarUnidade(false));
  }, [desabilitarUnidade]);

  const [paginaAtual, setPaginaAtual] = useState();
  const [totalPaginas, setTotalPaginas] = useState();

  const [carregandoUnidades, setCarregandoUnidades] = useState(false);
  const [listaUnidades, setListaUnidades] = useState([]);
  const [linhasListaUnidades, setLista] = useState([]);
  
  const [changeCheck,setCheck] = useState('');
  const [checkAllList,setCheckAllList] = useState(false);
  const [importarLeadNaoPermitido, setImportarLeadNaoPermitido] = useState(false);
  const [carregamentoInicial, setCarregamentoInicial] = useState(false);

  useEffect(() => {
    setCarregamentoInicial(false);
  }, [])

  const onClickEditar = (id) => {
    history.push(`${RotasDTO.LeadUnidadesConsumidoras}/cadastro/${id.id}`);
  };

  const onClickBotao = (tipo, parametros) => {
    switch (tipo) {
      case enumerador.Edicao:
        onClickEditar(parametros);
        break;
      case enumerador.Deletar:
        onClickExcluir(parametros.id);
        break;
      default:
        break;
    }
  };

  // const colunas = [
  //   new ColunaCampoSwitch(
  //     "selecionado",
  //     <MaterialSwitch 
  //     checked={checkAllList}
  //       onChange={
  //         () => { 
  //           let checked = !checkAllList;
  //           for(var i = 0;i < linhasListaUnidades.length;++i){                
  //             linhasListaUnidades[i].checked = checked;                
  //           }        
  //           setLista(linhasListaUnidades);
  //           setCheck("all"+checked.toString());
  //           setCheckAllList(!checkAllList);
  //          }
  //       }
  //     />,
  //     (a,b)=> {
  //       for(var i = 0;i < linhasListaUnidades.length;++i){
          
  //         if(linhasListaUnidades[i].id === b){
  //           linhasListaUnidades[i].checked = a;
  //         }
  //       }        
  //       setLista(linhasListaUnidades);
  //       setCheck(a.toString() + b.toString());        
  //     },            
  //     "70px",
  //     "70px"      
  //   ),      
  //   new Coluna("nomeUnidade", "Nome Unidade"),
  //   new Coluna("cnpjCpf", "CNPJ"),
  //   new Coluna("dataCadastro", "Data pré-cadastro"),
  //   new Coluna("status", "Status"),
  //   new ColunaComponentePersonalizado(
  //     "opcoes",
  //     "Editar",
  //     ColunaMultiplosBotoes,
  //     onClickBotao
  //   )
  // ];

  const colunasCheckBox = [
    new ColunaCampoCheckBox(
      "selecionado",
      <MaterialCheckBox 
      checked={checkAllList}
        onChange={
          () => { 
            let checked = !checkAllList;
            for(var i = 0;i < linhasListaUnidades.length;++i){                
              linhasListaUnidades[i].checked = checked;                
            }        
            setLista(linhasListaUnidades);
            setCheck("all"+checked.toString());
            setCheckAllList(!checkAllList);
          }
        }
      />,
      (a,b)=> {
        for(var i = 0;i < linhasListaUnidades.length;++i){
          
          if(linhasListaUnidades[i].id === b){
            linhasListaUnidades[i].checked = a;
          }
        }        
        setLista(linhasListaUnidades);
        setCheck(a.toString() + b.toString());        
      },            
      "70px",
      "70px"      
    ),    
    new Coluna("nomeUnidade", "Nome Unidade"),
    new Coluna("cnpjCpf", "CNPJ"),
    new Coluna("dataCadastro", "Data pré-cadastro"),
    new Coluna("origem", "Origem"),
    new Coluna("assinado", "Assinado"),
    new Coluna("status", "Status"),
    new ColunaComponentePersonalizado(
      "opcoes",
      "Editar",
      ColunaMultiplosBotoes,
      onClickBotao
    )
  ];



  const [modalExclusao, setModalExclusao] = useState(false);

  const deletar = async (item) => {
    
    try {
      setModalExclusao(false);
      setCarregandoUnidades(true);
      const deletou = await deletarUnidade(
        item
      );
      if (deletou) {        
        setFiltroSelecionado([]);
        store.dispatch(
          alertaExibir({
            tipo: "success",
            mensagem: `O lead foi deletado com sucesso!`
          })
        );
      }
      setCarregandoUnidades(false);
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
      setCarregandoUnidades(false);
    }
  };

  // useEffect(() => {
  //   setTimeout(() => {
  //     handleListarUnidades(
  //       ordemSelecionada,
  //       filtroSelecionado,
  //       1,
  //       empresaSelecionada,
  //       textoParaBusca,
  //       false
  //     );
  //   }, 50);
  // }, [changeCheck]);

  useEffect(() => {
    handleClickAtivarAgora();
  }, [clienteSelecionado])


  const obterUnidadesConsumidoras = async (parametros) => {     
    // debugger
    const resultado =  await  listarUnidadesConsumidoras(
      ordemSelecionada,
      filtroSelecionado,
      parametros.pagina,
      parametros.pesquisar,
      [clienteSelecionado]
    );

    if (!resultado.status === 200) {
      store.dispatch(
        alertaExibir({
          tipo: "danger",
          mensagem: resultado.mensagem
        })
      );
      return new ResultadoPaginadoDto([], 1, 0, 0);
    }

    if (resultado?.length === 0 ?? true)
      return new ResultadoPaginadoDto([], 1, 0, 0);
    
    const allStatus = await buscarStatus();
    const resLinhas = await resultado.data.unidadesConsumidoras?.map((res)  =>  {   
      const cnpjFormatado = res?.cnpjCpf.replace(
        /(\d{2})?(\d{3})?(\d{3})?(\d{4})?(\d{2})/,
        "$1.$2.$3/$4-$5"
      );
      const dataCadastro = window
          .moment(res?.dataCadastro)
          .format("DD-MM-YYYY");
      
       let status = allStatus.data.filter(x => x.codigo === res.status);

      return new LeadUnidadeConsumidoraDto(
        res.id,
        res.nomeUnidade ===''?'-':res.nomeUnidade,
        cnpjFormatado === '' ?'-':cnpjFormatado,
        dataCadastro === '' ? '-':dataCadastro,
        res.origem === 'SF' ? 'Sales Force' : res.origem,
        res.assinado,
        status === undefined || status[0] === undefined ?'-': status[0].descricao,
        false
      );
    });
    setLista(resLinhas);
    // debugger
    return new ResultadoPaginadoDto(
      resLinhas,
      resultado.data.paginaAtual,
      resultado.data.totalItens,
      resultado.data.totalPaginas
    );
  };

  const onChangeFiltrosTabela = async (parametros) => { 
    setCarregamentoInicial(true); 
    if(carregamentoInicial) {
      return await  obterUnidadesConsumidoras({
        tamanhoPagina: parametros.totalPagina,
        pagina: parametros.pagina,
        pesquisar: parametros.pesquisar,
        filtroAdicionalTabela: new FiltroAdicionalTabela(
          parametros.filtrosAdicionais?.ordenacao,
          parametros.filtrosAdicionais?.filtro
        )
      });         

    }   
  };
 
  const onClickNovaUnidade = () => {
    history.push(`${RotasDTO.LeadUnidadesConsumidoras}/cadastro`);
  };
  const onClickExcluir = (item) => {
    setModalExclusao(item);
  };

  const onConfirmarExclusao = (item) => {
    try {
      deletar(item);
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
    }
  };

  const { register, control } = useForm({
    reValidateMode: "onSubmit"
  });

  const textoBusca = useWatch({
    control,
    name: "textoBusca",
    defaultValue: ""
  });

  const [ordemSelecionada, setOrdemSelecionada] = useState("");
  const [filtroSelecionado, setFiltroSelecionado] = useState("");

  const handleClickOrdemSelecionada = (event) => {
    const val = ordemSelecionada === event ? "" : event;
    setOrdemSelecionada(val);
  };

  const handleClickFiltroSelecionado = (event) => {
    const val = filtroSelecionado === event ? "" : event;
    setFiltroSelecionado(val);
  };

  const handleClickAtivarAgora = async () => {
    const filtro = [];
    if (filtroSelecionado) filtro.push(filtroSelecionado);
    setFiltroSelecionado(filtro);
  };

  const [carregandoUnidade, setCarregandoUnidade] = useState(false);

  const enviarFormulario = async () => {
      setCarregandoUnidade(true);

      let leadsSelecionados = linhasListaUnidades.filter(l => l.checked);

      if (leadsSelecionados.length === 0) {
        store.dispatch(
          alertaExibir({
            tipo: "warning",
            mensagem: 'Não há Leads Selecionados'
          })
        );
        setCarregandoUnidade(false);
        return;
      }
      
      if (possuiLeadsSiteNaoAssinados(leadsSelecionados)) {
        setImportarLeadNaoPermitido(true);
        setTimeout(() => {
          setImportarLeadNaoPermitido(false);
        }, 5000);
        setCarregandoUnidade(false);
        return;
      }

      const ids = leadsSelecionados.map(l => l.id);

      try {
        const salvou = await efetivarUnidadeConsumidora({ids: ids.toString()});

        if (salvou?.status === 200 || salvou?.status === 204) {          
          setFiltroSelecionado([]);
          handleClickAtivarAgora();
          store.dispatch(
            alertaExibir({
              tipo: "success",
              mensagem: "Leads importados com sucesso!"
            })
          );        
        }      
      } catch (error) {
        store.dispatch(
          alertaExibir({
            tipo: "warning",
            mensagem: error?.response?.data?.message
          })
        );
      }
      setCarregandoUnidade(false);
  }

  const possuiLeadsSiteNaoAssinados = (leads) => {
    const leadsSiteNaossinados = leads.filter(l => l.origem === "Site" && l.assinado === "Não");
    return leadsSiteNaossinados.length > 0;
  }

  return (
    <Loader loading={carregandoUnidades} className="w-auto">
      <ModalConfirmacao
        item={modalExclusao}
        onConfirmar={(item) => onConfirmarExclusao(item)}
        mensagem={`Tem certeza que deseja ${
          modalExclusao?.situacao?.toUpperCase() === "AT"
            ? "inativar"
            : "ativar"
        } essa Unidade?`}
        onCancelar={() => setModalExclusao(false)}
      />
      <Grid container spacing={3} alignItems="center" justifyContent={"space-between"}>
        <Grid item xs={5}>
          <MaterialInputBusca
            type="text"
            id="textoBusca"
            name="textoBusca"
            label="Buscar"
            renderIconShowHide
            searchAdornment
            defaultValue={textoBusca ?? ""}
            ref={register}
            permiteValorBranco
          />
        </Grid>
        <Grid item xs={3} className={classes.itemFiltro}>
          <BotaoFiltroOrdenacao
            type="button"
            color={theme.color.secondaryBorderColor}
            background="transparent"
            label="Filtrar / Ordernar"
            icon={<TuneIcon />}
            className={classes.buttonFiltro}
            ordenacao={listarOrganizarPor()}
            ordenadorUm="Ordenador"
            ordenadorDois="Filtrar"
            ordemSelecionada={ordemSelecionada}
            filtros={listarFiltrarPor()}
            filtrosSelecionados={filtroSelecionado}
            onClickOrdenacao={handleClickOrdemSelecionada}
            onClickFiltro={handleClickFiltroSelecionado}
            onClickAtivarAgora={handleClickAtivarAgora}
          />
        </Grid>
        <Grid item xs={12}>
          <BotaoCadastro
            label="Nova unidade consumidora"
            color={Base.White}
            onClick={onClickNovaUnidade}
          />
        </Grid>
        <Grid item xs={12} className="mt-4">
          <TabelaPaginada
            onChangeFiltrosTabela={onChangeFiltrosTabela}
            linhas={linhasListaUnidades}
            //colunas={colunas}
            colunas={colunasCheckBox}
            pesquisar={textoBusca}
            filtrosAdicionais={filtroSelecionado}
            paginationPerPage={10}
            paginaAtual={1}
            qtdLinhas={3}
          />
        </Grid>             
      </Grid>
      {importarLeadNaoPermitido &&
              <Grid item xs={12}>
                <Alerta
                  key={0}
                  onClose={() => {
                    setImportarLeadNaoPermitido(false);
                  }}
                  {...{
                    tipo: "danger",
                    mensagem: `Não é possível importar Leads com origem "Site - Não assinados"`
                  }}
                />
              </Grid>
            }
      {
        permissaoImportarLeads ?
        <Grid container spacing={4} className={classes.containerSalvar}>
          <Grid item lg={3} md={4} sm={6}>
            <Loader loading={carregandoUnidade}>
              <Botao
                type="submit"
                label="Importar"
                //disabled={!habilitarCampos}
                className={classes.button}
                onClick={enviarFormulario}
              />
            </Loader>
          </Grid>
        </Grid> : null
      }
      
        
    </Loader>
  );
};

export default UnidadesListar;
