import React from "react";
import PropTypes from "prop-types";
import { FormControlLabel, Checkbox } from "@mui/material";
import theme from "themes";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { CelulaCheckbox } from "./style";

const ColunaCheckbox = ({ parametros, onClick }) => {
  return (
    <CelulaCheckbox>
      <FormControlLabel
        control={
          <Checkbox
            checked={Boolean(
              parametros?.unidadesSelecionadas?.find(
                (unidade) =>
                  parametros[parametros?.campoSelecionar || "id"] === unidade
              )
            )}
            onChange={() =>
              onClick([parametros[parametros?.campoSelecionar || "id"]])
            }
            sx={{
              color: `${theme.color.secondary} !important`,
              "&.Mui-checked": {
                color: `${theme.color.secondary} !important`
              }
            }}
            icon={
              parametros?.disabled ? (
                <IndeterminateCheckBoxIcon />
              ) : (
                <CheckBoxOutlineBlankIcon />
              )
            }
            disabled={parametros?.disabled}
          />
        }
        label={parametros?.unidadeNome}
      />
    </CelulaCheckbox>
  );
};

ColunaCheckbox.propTypes = {
  parametros: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
    .isRequired,
  onClick: PropTypes.func
};

ColunaCheckbox.defaultProps = {
  onClick: () => {}
};

export default ColunaCheckbox;
