import styled from "styled-components";
import { Base } from "componentes/cores";
import { adaptV4Theme } from "@mui/material";
import { makeStyles } from '@mui/styles';
import { createTheme } from "react-data-table-component";
import theme  from 'themes'

export const useStyles = makeStyles({
  container: {
    margin: "0 !important",
    width: "100% !important",
    "& .MuiInputBase-root": {
      height: "46px"
    },
    "& .MuiFormLabel-root": {
      lineHeight: "1"
    },
    "& .MuiGrid-spacing-xs-4 > .MuiGrid-item": {
      padding: "12px"
    }
  },
  cardFormulario: {
    backgroundColor: `${Base.OffWhite} !important`,
    padding: "12px"
  },
  buttonHistorico: {
    borderColor: `${theme.color.primaryBackgroud} !important`,
    "&:hover": {
      color: `${Base.BlackOpacity40} !important`
    },
    "&:focus": {
      color: `${Base.BlackOpacity40} !important`
    }
  },
  gridEmpty: {
    minHeight: "38px"
  }
});

export const customStyles = {
  Container: {
    alignItems: "center",
    background: "#E3E5EF !important",
    color: "#fff",
    display: "flex",
    height: "300px",
    justifyContent: "center",
    marginBottom: "2em",
    marginTop: "0",
    opacity: "1",
    overflowX: "hidden"
  },
  table: {
    style: {
      color: theme.color.primaryBackgroud,
      opacity: "1",
      overflowX: "hidden"
    }
  },
  headRow: {
    style: {
      borderWidth: 0
    }
  },
  headCells: {
    style: {
      background: "#D9DBE4",
      color: theme.color.primaryBackgroud,
      fontSize: "16px",
      fontWeight: "bold",
      marginLeft: "3px",
      "&:first-child": {
        marginLeft: "0"
      }
    }
  },
  rows: {
    style: {
      borderBottomWidth: "0 !important",
      color: theme.color.primaryBackgroud,
      marginBottom: "1px",
      marginTop: "2px",
      opacity: "1"
    }
  },
  cells: {
    style: {
      background: "#E3E5EF",
      borderBottom: "0 none",
      color: theme.color.primaryBackgroud,
      fontSize: "14px",
      marginLeft: "3px",
      opacity: "1",
      "&:first-child": {
        marginLeft: "0"
      }
    }
  }
};

export const TabelaSemDados = styled.span`
  background-color: #e3e5ef;
  color: ${theme.color.primaryBackgroud};
  margin-top: 3px;
  padding: 8px;
  text-align: left;
`;

createTheme(adaptV4Theme("DeltaEnergiaDemo", {
  background: {
    default: "transparent"
  },
  text: {
    primary: theme.color.primaryBackgroud,
    secondary: theme.color.primaryBackgroud
  },
  action: {
    hover: theme.color.secondaryBorderColor
  }
}));
