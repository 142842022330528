import React from "react";
import { Box, Grid } from "@mui/material";
import { useHistory } from "react-router-dom";

import Alert from "../../assets/consumoEnergia/alert.png"
import { RotasDTO } from "../../global/rotas/rotasUrlDto";

const SemDados = () => {
	const history = useHistory();

	const handleHelp = () => {
	history.push(RotasDTO.Ajuda);
	}

	
	return (
		<Grid item xs={12} md={12}>
			<Box bgcolor="rgba(244, 163, 48, 0.16)" border="4px dashed #F4A330" borderRadius="8px" pt={4} pb={6} p={{ sm: 2, xs: 2 }}>
				<Box component="div" display="flex" justifyContent="center">
					<Box component="img" src={Alert} />
				</Box>
				<Box component="div" display="flex" justifyContent="center" fontSize="20px" fontWeight="700" my={2}>
					<Box component="span" color="#F4A330">Não há dados disponíveis para exibição</Box>
				</Box>
				<Box component="div" display="flex" justifyContent="center" fontSize="16px" fontWeight="400">
					<Box component="span" color="#4F4F4F">
						Por favor,
						<Box
							component="span"
							color="#FA3EB1"
							borderBottom="1px solid #FA3EB1"
							mx={0.5}
							sx={{ cursor: "pointer" }}
							onClick={handleHelp}

						>
							entre em contato com o nosso atendimento. 
						</Box>
					</Box>
				</Box>
			</Box>
		</Grid>
	)
}

export default SemDados;
