import RetornoEndpointDto from "global/dto/retornoEndpointDto";
import {
  ListarCustomizados,
  ListarSetoriais,
  MudarStatus,
  BaixarPorUUID,
  ObterVideosPorRelatorioId
} from "../../servicos/relatorioServico";
import { Salvar } from "submodules/AreaLogada/servicos/metricasRelatorioServico";


export default class Helper {
  static async ListarCustomizados(filtro) {
    try {
      const resultado = await ListarCustomizados(filtro);

      return RetornoEndpointDto.Sucesso(
        "Lista obtidas com sucesso",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro(
        (error?.response?.data?.errors && error?.response?.data?.errors[0]) ??
          "Ocorreu uma falha ao salvar o Relatório"
      );
    }
  }

  static async ListarSetoriais(filtro) {
    try {
      const resultado = await ListarSetoriais(filtro);

      return RetornoEndpointDto.Sucesso(
        "Lista obtidas com sucesso",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro(
        (error?.response?.data?.errors && error?.response?.data?.errors[0]) ??
          "Ocorreu uma falha ao salvar o Relatório"
      );
    }
  }

  static async BaixarPorUUID(filtro) {
    try {
      await BaixarPorUUID(filtro);

      return RetornoEndpointDto.Sucesso("arquivo obtido com sucesso", null);
    } catch (error) {
      return RetornoEndpointDto.Erro("Ocorreu um erro ao obter arquivo");
    }
  }

  static async MudarStatus(params) {
    try {
      if (!params) return RetornoEndpointDto.Erro("Erro ao tentar atualizar");

      const { data } = await MudarStatus(params);

      return RetornoEndpointDto.Sucesso("Atualizado com sucesso", data);
    } catch (error) {
      return RetornoEndpointDto.Erro(
        error?.response?.data?.message ?? "Ocorreu uma falha ao salvar"
      );
    }
  }

  static async ObterVideos(params) {
    try {
      const { data } = await ObterVideosPorRelatorioId(params);

      return RetornoEndpointDto.Sucesso(data, "videos obtido com sucesso");
    } catch (error) {
      return RetornoEndpointDto.Erro("Ocorreu um erro ao obter os vídeos");
    }
  }

  static async Salvar(params) {
    try {
      if (!params)
        return RetornoEndpointDto.Erro(
          "É necessário informar uma categoria para a persistencia"
        );

      const { data } = await Salvar(params);

      return RetornoEndpointDto.Sucesso(
        "Registro cadastrado com Sucesso",
        data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro(
        error?.response?.data?.message ?? "Ocorreu uma falha ao salvar o Métricas"
      );
    }
  }

  static async ExportarPlanilha(params) {
    try {
      const { data } = await ExportarPlanilha(params);

      return RetornoEndpointDto.Sucesso(data, "Planilha obtida com sucesso");
    } catch (error) {
      return RetornoEndpointDto.Erro("Ocorreu um erro ao obter a planilha");
    }
  }
}
