import React from "react";
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/material';
import { createTheme } from "@mui/material/styles";

const theme = createTheme();

// Imagens
import BgCardLogin from "../../assets/BgCardLogin.png";

const useStyles = makeStyles(() => ({
  paper: {
    backgroundImage: `url(${BgCardLogin})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    [theme.breakpoints.up('sm')]: {
      width: 468
    }
  }
}));

// height = { 533} width = { 468}

const CardLogin = ({ children }) => {
  const classes = useStyles();

  return (
    <Box
      className={`${classes.paper} mx-auto card shadow-lg border-0`}
    >
      <div className="card-body">{children}</div>
    </Box>
  );
};

export default CardLogin;
