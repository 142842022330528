import React from "react";
import PropTypes from "prop-types";

// Componentes
import Pagination from '@mui/material/Pagination';

// Styles
import { useStyles } from "./style";

const Paginacao = ({ totalPaginas, onChangePagina }) => {
  const classes = useStyles();

  const handleChangePagina = (event, page) => {
    onChangePagina(event.currentTarget, page);
  };

  return (
    <div className={classes.paginacaoContainer}>
      <Pagination
        count={totalPaginas}
        shape="rounded"
        variant="outlined"
        color="primary"
        onChange={(valor, page) => handleChangePagina(valor, page)}
      />
    </div>
  );
};

Paginacao.propTypes = {
  totalPaginas: PropTypes.oneOfType([PropTypes.any]),
  onChangePagina: PropTypes.oneOfType([PropTypes.any])
};

Paginacao.defaultProps = {
  totalPaginas: 1,
  onChangePagina: () => {}
};

export default Paginacao;
