import MICROSERVICO from "../global/enumeradores/microServico";
import obterApi from "./api";

const api = () => obterApi(MICROSERVICO.CALCULOCUSTOS);

export const AddProcessamentoFaturaML = async (parametros) => {
  return api().post('v1/ProcessamentoFaturaML', parametros).then((data) => {
    return data
  });
};

export const AtualizarProcessamentoFaturaML = async (unidadeId, mes, ano, parametros) => {
  return api().put(`v1/ProcessamentoFaturaML/${unidadeId}/${mes}/${ano}`, parametros).then((data) => {
    return data
  });
};

export const obterCalculoRealizadoEProcessado = async (unidadeId, mes, ano) => {
  return api().get(`v1/ProcessamentoFaturaML/ObterCalculoRealizadoEProcessado/${unidadeId}/${mes}/${ano}`);
};

export const calcularDiferencaFaturadoEProcessado = async (unidadeId, mes, ano, parametros) => {
  return api().post(`v1/ProcessamentoFaturaML/CalcularDiferencaFaturadoEProcessado/${unidadeId}/${mes}/${ano}`, parametros).then((data) => {
    return data
  });
};
