import { Box, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import $ from "jquery";
import "jquery-ui-bundle";
import "jquery-ui-touch-punch";

import PropTypes from "prop-types";
import LuzAcesa from "../../../assets/captch/images/luz-acesa.png";
import DeltaAcesa from "../../../assets/captch/images/delta-acesa.png";
import ThymosAcesa from "../../../assets/captch/images/thymos-acesa.png";
import Reset from "../../../assets/captch/reset.png";
import { StyledSection } from "./style";
import {
  retornoCaptcha
} from "../../../servicos/loginServico";

const Captcha = ({ handleCaptcha, captchaPosicao, sistema, chave }) => {
  const [captcha, setCaptcha] = useState(false);
  const [imagemCaptcha, setImagemCaptcha] = useState('');
  const [loading, setLoading] = useState('');

  const resetCaptcha = () => {
    setLoading(false)
    setCaptcha(false);
    handleCaptcha(true);
    captchaPosicao(0)
    chave('')
    $("#slider").slider({ disabled: false });
    $(".ui-slider-handle").show();
    $(".img-apagada").show();
    $("#slider").slider("value", 0);
    $(".ui-slider-handle").removeClass("sem-content");
    getCaptcha();
  }

  useEffect(() => {
    $(function () {
      $("#slider").slider({
        orientation: "horizontal",
        range: "min",
        min: 0,
        max: 100,
        value: 0,
        step: 1,
        change: function (event, ui) {
          if(ui.value > 0){
            setLoading(true)
            captchaPosicao(ui.value)
            handleCaptcha(false);
            $("#slider").slider({ disabled: true });  
          }
        }
      });
    });

    if (captcha === true) { $("#slider").slider({ disabled: true }) }

  }, []);

  const handleImage = (domain) => {
    switch (domain) {
      case "luz":
        return `url(${LuzAcesa})`;
      case "delta":
        return `url(${DeltaAcesa})`;
      case "thymos":
        return `url(${ThymosAcesa})`;
      default:
        return `url(${LuzAcesa})`;
    }
  }

  const getCaptcha = async () => {
    const captcha = await retornoCaptcha();
    try {
      if (captcha?.status === 200) {
        chave(captcha.data.chave);
        setImagemCaptcha(captcha.data.base64);

      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getCaptcha();
  }, []);

  return (
    <StyledSection url={handleImage(sistema)}>
      <head>
        <link rel="stylesheet" href="/css/jquery-ui.css" />
        <link rel="stylesheet" href="/css/captcha.css" />
      </head>
      <div className="col-xs-12 col-md-12 posicione-figura">
        <Box textAlign="center">
          Posicione a figura no local indicado
        </Box>
      </div>
      <div className="col-xs-12">
        <div className="area mx-auto">
          <Box className="img-apagada">
            <img className="img-captcha" src={`data:image/jpeg;base64,${imagemCaptcha}`} />
          </Box>
          <div id="slider" />
        </div>
      </div>
      <div className="col-xs-12 col-md-12 text-center mb-4 ">
        {loading ?
          <Box component="div" className="text-center mt-0">
            <CircularProgress size={24} className='loader' />
            <div className="text-center">Validando verificador ...</div>
          </Box>
          :
          <Box component="div" className="text-center mt-0 resetCaptcha ">
            <Box
              component="img"
              src={Reset}
              alt="Resetar verificador"
              onClick={() => resetCaptcha()}
              mt={4}
            />
            <div className="text-center mt-3">Resetar verificador</div>
          </Box>
        }

      </div>

    </StyledSection>
  )

}

Captcha.prototype = {
  captchaPosicao: PropTypes.oneOfType([PropTypes.func]),
  handleCaptcha: PropTypes.oneOfType([PropTypes.func]),
  chave: PropTypes.oneOfType([PropTypes.func]),
  sistema: PropTypes.string,
}

Captcha.defaultProps = {
  handleCaptcha: () => { },
  captchaPosicao: () => { },
  chave: () => { },
  sistema: "",
}

export default Captcha;



