import styled from "styled-components";
import { makeStyles } from '@mui/styles';
import { Base } from "../cores";
import theme from 'themes';

export const useStyles = makeStyles({
  borda: {
    backgroundColor: (props) => props.color,
    display: "block",
    height: "54px",
    marginLeft: "-2px",
    marginRight: "20px",
    opacity: (props) => (props.disabled ? "0.3" : "1"),
    width: "6px"
  },
  icone: {
    marginRight: "20px",
    color: `${theme.color.secondaryBorderColor} !important`,
    opacity: (props) => (props.disabled ? "0.3" : "1")
  },
  botao: {
    alignItems: "center",
    backgroundColor: "transparent",
    borderColor: theme.color.botaoCadastroBorderColor,
    borderWidth: "2px",
    borderStyle: "dashed",
    color: `${theme.color.secondaryBorderColor} !important`,
    opacity: (props) => (props.disabled ? "0.3" : "1"),
    display: "flex",
    fontSize: "18px",
    height: "54px",
    paddingLeft: "0px",
    width: "100%"
  }
});

export const Borda = styled.span``;
export const Botao = styled.button``;
