import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { cnpj } from "cpf-cnpj-validator";
import moment from "moment";

// Redux
import { useSelector } from "react-redux";

// Form Hooks
import { useForm } from "react-hook-form";

// Componentes
import { Card, Divider, Grid } from "@mui/material";
import Botao from "../../../../componentes/botao";
import Loader from "../../../../componentes/loader";
import BotaoRetornarListagem from "../../../../componentes/botaoRetornarListagem";
import ModalConfirmacao from "../../../../componentes/modalConfirmacao";
import MaterialInputMascara from "../../../../componentes/inputTextoMascara";
import InputRadioGroup from "../../../../componentes/inputRadioGroup";
import InputData from "../../../../componentes/inputTextoData";
import MaterialInputTexto from "../../../../componentes/inputTexto/materialInput";
import SelectArredondado from "../../../../componentes/selectArredondado";
import MaterialInputTelefone from "../../../../componentes/inputTexto/materialInputTelefone";

// Styles
import { useStyles } from "./style";

// Rotas
import { RotasDTO } from "../../../../global/rotas/rotasUrlDto";

// Redux
import { store } from "../../../../global/redux";
import { alertaExibir } from "../../../../global/redux/modulos/alertas/actions";
import {
  desabilitarUnidade,
  salvarUnidades,
  selecionarCliente,
  selecionarEmpresa,
  selecionarUnidade
} from "../../../../global/redux/modulos/usuario/actions";

// Serviços
import {
  buscarPorId,
  buscarPorCnpj,
  buscarStatus,
  buscarSubmercado,
  buscarNivelTensao,
  salvarUnidade,
  listarUnidadesConsumidorasCombo,
  buscaTipoRepresentacao
} from "../../../../servicos/leadUnidadesServico";
import { buscarTiposUnidadeConsumidora } from "servicos/unidadesServico";
import { buscarDistribuidoras } from "../../../../servicos/distribuidorasServico";
import { buscaCep } from "../../../../servicos/correiosServico";
import { buscarParcelaDeCarga } from "../../../../servicos/parcelaAtivoCCEEServico";
import {
  buscarPorId as buscarClientePorId,
  buscarPorCnpj as buscarClientePorCnpj
} from "../../../../servicos/leadClientesServico";
import { buscarPorId as buscarEmpresaPorId } from "../../../../servicos/empresaServico";
import { buscarDadosCadastraisPorCnpj } from "../../../../servicos/dadosCadastraisCnpjServico";

// DTO
import { InterfaceDTO } from "global/dto/interfacesDto";
import { removerCaracteres } from "servicos/utils";
import { SelectAutocomplete } from "componentes";
import { tipoUnidadeConsumidoraList } from "global/constantes";

const UnidadesCadastro = () => {
  const { id } = useParams();
  const classes = useStyles();
  const history = useHistory();
  const { register, errors, handleSubmit } = useForm({
    reValidateMode: "onSubmit"
  });
  const rotas = useSelector((state) => state.rotas);
  const usuario = useSelector((state) => state.usuario);
  const { menu } = usuario;

  useEffect(() => {
    store.dispatch(desabilitarUnidade(true));

    return () => {
      store.dispatch(desabilitarUnidade(false));
    };
    // eslint-disable-next-line
  }, [desabilitarUnidade]);

  const [unidadeExistente, setUnidadeExistente] = useState(false);
  const [idUnidadeExistente, setIdUnidadeExistente] = useState();
  const [unidadeExistenteDados, setUnidadeExistenteDados] = useState();

  const [numero, setNumero] = useState();
  const [complemento, setComplemento] = useState();

  const [carregandoCnpj, setCarregandoCnpj] = useState(false);
  const [razaoSocial, setRazaoSocial] = useState("");
  const [nomeFantasia, setNomeFantasia] = useState("");
  const [inscricaoEstadual, setInscricaoEstadual] = useState();
  const [inscricaoMunicipal, setInscricaoMunicipal] = useState();
  const [valorTelefone, setValorTelefone] = useState("");
  const [valorCnpj, setValorCnpj] = useState();
  const [valorCep, setValorCep] = useState();
  const [limparCep, setLimparCep] = useState(false);
  const [numeroInstalacao, setNumeroInstalacao] = useState();
  const [cnpjFormatado, setCnpjFormatado] = useState();

  const onBlurNomeFantasia = (nomeFantasia) => {
    let nomeFantasiaFormatado = nomeFantasia
      .replaceAll("'", "")
      .replaceAll('"', "");
    setNomeFantasia(nomeFantasiaFormatado);
  };

  const onConfirmarTelaEdicao = () => {
    setUnidadeExistente(false);
    history.push(
      `${RotasDTO.LeadUnidadesConsumidoras}/cadastro/${idUnidadeExistente}`
    );
  };

  // Situação
  const [situacao, setSituacao] = useState(false);
  const onChangeSituacao = (valor) => {
    setSituacao(valor);
  };

  // Data migração
  const [dataMigracao, setDataMigracao] = useState(null);
  const onChangeDataMigracao = (data) => {
    setDataMigracao(data);
  };

  // Distribuidoras
  const [distribuidoraId, setDistribuidoraId] = useState(null);
  const onChangeDistribuidora = (distribuidora) => {
    setDistribuidoraId(distribuidora);
  };

  const [carregandoUnidade, setCarregandoUnidade] = useState(false);
  const [dadosUnidade, setDadosUnidade] = useState();

  // Distribuidoras
  const [listaDistribuidora, setListaDistribuidora] = useState([]);
  const [carregandoDistribuidora, setCarregandoDistribuidora] = useState(false);

  const obterDistribuidora = async () => {
    try {
      setCarregandoDistribuidora(true);
      const lista = await buscarDistribuidoras();
      if (lista?.status === 200 && lista?.data) {
        setListaDistribuidora(lista?.data);
      }
      setCarregandoDistribuidora(false);
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
      setCarregandoDistribuidora(false);
    }
  };

  useEffect(() => {
    obterListaTipoUnidade();
    obterDistribuidora();
  }, []);

  const [ufId, setUFId] = useState();
  const onChangeUF = (uf) => {
    setUFId(uf.target.value);
  };

  // CEP
  const [carregandoEndereco, setCarregandoEndereco] = useState(false);
  const [dadosEndereco, setDadosEndereco] = useState();

  const carregarCep = useCallback(async (cep) => {
    try {
      setCarregandoEndereco(true);
      const endereco = await buscaCep(cep);

      if (endereco?.status === 200) {
        setUFId(endereco.data.uf);
        setDadosEndereco({
          endereco: endereco.data.logradouro,
          bairro: endereco.data.bairro,
          cidade: endereco.data.cidade
        });
        setNumero(" ");
        setComplemento(" ");
      }

      setCarregandoEndereco(false);
    } catch (error) {
      setUFId(null);
      setDadosEndereco({
        endereco: "",
        bairro: "",
        cidade: ""
      });
      setNumero("");
      setComplemento("");
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Não foi possível localizar o CEP!"
        })
      );
      setCarregandoEndereco(false);
    }
  }, []);

  const onBlurCep = (cepValor) => {
    if (cepValor.replace(/\s/g, "").length > 7) {
      carregarCep(cepValor);
    }
  };

  const [tipoFormulario, setTipoFormulario] = useState(1);

  const listaModalidade = [
    { nome: "AZUL", id: 1 },
    { nome: "VERDE", id: 2 }
  ];

  const [modalidadeId, setModalidadeId] = useState();
  const onChangeModalidade = (modalidade) => {
    setTipoFormulario(modalidade.target.value === "2" ? 2 : 1);
    setModalidadeId(modalidade.target.value);
  };

  const listaUF = [
    { nome: "Acre", UF: "AC" },
    { nome: "Alagoas", UF: "AL" },
    { nome: "Amapá", UF: "AP" },
    { nome: "Amazonas", UF: "AM" },
    { nome: "Bahia", UF: "BA" },
    { nome: "Ceará", UF: "CE" },
    { nome: "Distrito Federal", UF: "DF" },
    { nome: "Espírito Santo", UF: "ES" },
    { nome: "Goiás", UF: "GO" },
    { nome: "Maranhão", UF: "MA" },
    { nome: "Mato Grosso", UF: "MT" },
    { nome: "Mato Grosso do Sul", UF: "MS" },
    { nome: "Minas Gerais", UF: "MG" },
    { nome: "Pará", UF: "PA" },
    { nome: "Paraíba", UF: "PB" },
    { nome: "Paraná", UF: "PR" },
    { nome: "Pernambuco", UF: "PE" },
    { nome: "Piauí", UF: "PI" },
    { nome: "Rio de Janeiro", UF: "RJ" },
    { nome: "Rio Grande do Norte", UF: "RN" },
    { nome: "Rio Grande do Sul", UF: "RS" },
    { nome: "Rondônia", UF: "RO" },
    { nome: "Roraima", UF: "RR" },
    { nome: "Santa Catarina", UF: "SC" },
    { nome: "São Paulo", UF: "SP" },
    { nome: "Sergipe", UF: "SE" },
    { nome: "Tocantins", UF: "TO" }
  ];

  const [parcelaDeCargaExistente, setParcelaDeCargaExistente] = useState(false);
  const [parcelaDeCarga, setParcelaDeCarga] = useState("");

  const verificaCnpj = (valor) => {
    const cnpjFormatado = valor?.target.value.replace(/[^a-zA-Z0-9]/g, "");
    setCnpjFormatado(cnpjFormatado);
    verificarCnpjExistente(cnpjFormatado);
    consultarRF(cnpjFormatado);
  };

  const consultarRF = useCallback(async (valor) => {
    try {
      setCarregandoCnpj(true);
      const dadosCadastrais = await buscarDadosCadastraisPorCnpj(valor);
      let telefone1 =
        dadosCadastrais?.data?.data?.telefone1 &&
        `55${dadosCadastrais?.data?.data?.telefone1}`;
      let telefone2 =
        dadosCadastrais?.data?.data?.telefone2 &&
        `55${dadosCadastrais?.data?.data?.telefone2}`;

      if (dadosCadastrais?.data?.success) {
        setRazaoSocial(dadosCadastrais?.data?.data?.razaoSocial);
        let nomeFantasiaFormatado = dadosCadastrais?.data?.data?.nomeFantasia
          .replaceAll("'", "")
          .replaceAll('"', "");
        setNomeFantasia(nomeFantasiaFormatado);
        setValorTelefone(telefone1 || telefone2);
        setInscricaoMunicipal("");
        setInscricaoEstadual("");
        setStatusId(null);
        zerarCamposParcela();
        zerarCamposEndereco();
      } else {
        store.dispatch(
          alertaExibir({
            tipo: "warning",
            mensagem: "CNPJ inválido!"
          })
        );
        zerarCamposEmpresa();
        zerarCamposParcela();
        zerarCamposEndereco();
      }
      setCarregandoCnpj(false);
    } catch (error) {
      console.info(error);
      setCarregandoCnpj(false);
    }
  }, []);

  const verificarCnpjExistente = useCallback(
    async (valor) => {
      try {
        setCarregandoCnpj(true);
        if (!dadosUnidade) {
          if (valor.length === 14) {
            const unidade = await buscarPorCnpj(valor);
            if (unidade?.data && unidade?.data?.unidadesConsumidoras) {
              setUnidadeExistenteDados(unidade?.data);
              setIdUnidadeExistente(unidade?.data?.unidadesConsumidoras[0].id);
            }

            const dadosCliente = await buscarClientePorCnpj(valor);
            if (dadosCliente?.data) {
              setRazaoSocial(dadosCliente?.data?.razaoSocial);
              setNomeFantasia(dadosCliente?.data?.nomeFantasia);
              setValorTelefone(dadosCliente?.data?.telefone);
              setInscricaoEstadual(dadosCliente?.data?.inscricaoEstadual);
              setInscricaoMunicipal(dadosCliente?.data?.inscricaoMunicipal);
              setStatusId(dadosCliente?.data?.status);
              setDadosEndereco({
                endereco: dadosCliente?.data?.endereco,
                bairro: dadosCliente?.data?.bairro,
                cidade: dadosCliente?.data?.cidade
              });
              setValorCep(dadosCliente?.data?.cep);
              setNumero(dadosCliente?.data?.numero);
              setComplemento(dadosCliente?.data?.complemento);
              setUFId(dadosCliente?.data?.uf);
            } else {
              consultarRF(valor);
            }

            const verificarParcelaCarga = await buscarParcelaDeCarga(valor);
            if (
              verificarParcelaCarga?.status === 200 &&
              verificarParcelaCarga?.data?.parcelaDeCarga
            ) {
              setParcelaDeCargaExistente(true);
              setSubmercadoId(verificarParcelaCarga?.data?.submercado);
              setParcelaDeCarga(verificarParcelaCarga?.data.parcelaDeCarga);
            } else {
              zerarCamposParcela();
            }
          }
        }
        setCarregandoCnpj(false);
      } catch (error) {
        console.info(error);
      } finally {
        setCarregandoCnpj(false);
      }
    },
    [dadosUnidade]
  );

  useEffect(() => {
    if (numero === " ") setNumero("");
    if (complemento === " ") setComplemento("");
    if (parcelaDeCarga === " ") setParcelaDeCarga("");
  }, [numero, complemento, parcelaDeCarga]);

  const zerarCamposEmpresa = () => {
    setRazaoSocial("");
    setNomeFantasia("");
    setValorTelefone(" ");
    setInscricaoMunicipal("");
    setInscricaoEstadual("");
    setStatusId(null);
  };

  const zerarCamposParcela = () => {
    setParcelaDeCargaExistente(false);
    setSubmercadoId("");
    setParcelaDeCarga(" ");
  };

  const zerarCamposEndereco = () => {
    setLimparCep(true);
    setDadosEndereco(null);
    setNumero("");
    setComplemento("");
    setUFId(null);
  };

  // Status
  const [listaStatus, setListaStatus] = useState([]);
  const [carregandoStatus, setCarregandoStatus] = useState(false);
  const [statusId, setStatusId] = useState();

  const onChangeStatus = (status) => {
    setStatusId(status.target.value);
  };

  const obterStatus = async () => {
    try {
      setCarregandoStatus(true);
      const lista = await buscarStatus();
      if (lista?.status === 200 && lista?.data) {
        setListaStatus(lista?.data);
      }
      setCarregandoStatus(false);
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
      setCarregandoStatus(false);
    }
  };

  useEffect(() => {
    obterStatus();
  }, []);

  // Submercado
  const [listaSubmercado, setListaSubmercado] = useState([]);
  const [carregandoSubmercado, setCarregandoSubmercado] = useState(false);
  const [submercadoId, setSubmercadoId] = useState();

  const onChangeSubmercado = (submercado) => {
    setSubmercadoId(submercado.target.value);
  };

  const obterSubmercado = async () => {
    try {
      setCarregandoSubmercado(true);
      const lista = await buscarSubmercado();
      if (lista?.status === 200 && lista?.data) {
        setListaSubmercado(lista?.data);
      }
      setCarregandoSubmercado(false);
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
      setCarregandoSubmercado(false);
    }
  };

  useEffect(() => {
    obterSubmercado();
  }, []);

  // Nível Tensão
  const [listaNivelTensao, setListaNivelTensao] = useState([]);
  const [carregandoNivelTensao, setCarregandoNivelTensao] = useState(false);
  const [nivelTensaoId, setNivelTensaoId] = useState();

  const onChangeNivelTensao = (nivelTensao) => {
    setNivelTensaoId(nivelTensao.target.value);
  };

  const obterNivelTensao = async () => {
    try {
      setCarregandoNivelTensao(true);
      const lista = await buscarNivelTensao();
      if (lista?.status === 200 && lista?.data) {
        setListaNivelTensao(lista?.data);
      }
      setCarregandoNivelTensao(false);
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
      setCarregandoNivelTensao(false);
    }
  };

  useEffect(() => {
    obterNivelTensao();
  }, []);

  const [tipoRepresentacaoId, setTipoRepresentacaoId] = useState();

  const obterUnidade = useCallback(async (idUnidade) => {
    try {
      const unidade = await buscarPorId(idUnidade);
      if (unidade?.status === 200 && unidade?.data) {
        setDadosUnidade(unidade?.data);
        setRazaoSocial(unidade?.data?.nomeRazaoSocial);
        setNomeFantasia(unidade?.data?.nomeUnidade);
        setInscricaoEstadual(unidade?.data?.inscricaoEstadual);
        setInscricaoMunicipal(unidade?.data?.inscricaoMunicipal);
        setDadosEndereco({
          endereco: unidade?.data?.endereco,
          bairro: unidade?.data?.bairro,
          cidade: unidade?.data?.cidade
        });
        setParcelaDeCarga(unidade?.data?.parcelaCarga);
        setNumero(unidade?.data?.numero);
        setComplemento(unidade?.data?.complemento);
        setStatusId(unidade?.data?.status);
        setDistribuidoraId(unidade?.data?.distribuidoraId);
        setSubmercadoId(unidade?.data?.submercadoId);
        setNivelTensaoId(unidade?.data?.nivelTensaoId);
        setDataMigracao(unidade?.data?.dataPrevistaMigracao);
        setSituacao(
          String(unidade?.data?.situacao).toLocaleUpperCase() === "AT"
        );
        setTipoRepresentacaoId(unidade?.data?.tipoRepresentacaoId);
        setModalidadeId(unidade?.data?.classeTarifariaId);
        setTipoFormulario(unidade?.data?.classeTarifariaId === 2 ? 2 : 1);
        setValorCep(unidade?.data?.cep);
        setValorTelefone(unidade?.data?.telefone);
        setValorCnpj(unidade?.data?.cnpjCpf);
        setUFId(unidade?.data.uf);

        if (dadosUnidade?.icmsNaNotaFiscal != null)
          setIcmsNaNotaFiscal(unidade?.icmsNaNotaFiscal ? "1" : "0");
      }
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
    }
  }, []);

  const [habilitarCampos, setHabilitarCampos] = useState(true);

  const checarPermissaoEditar = useMemo(() => {
    return true;
  }, [menu, rotas]);

  useEffect(() => {
    if (id) {
      setHabilitarCampos(checarPermissaoEditar);
      obterUnidade(id);
    }
  }, [id, obterUnidade, checarPermissaoEditar]);

  // Converte a data para o formato americano
  const toDataFormat = (date) => {
    const dd = String(date?.getDate()).padStart(2, "0");
    const mm = String(date?.getMonth() + 1).padStart(2, "0");
    const yyyy = date?.getFullYear();

    return `${yyyy}-${mm}-${dd}`;
  };

  const recarregarUnidadesFiltro = useCallback(async () => {
    if (usuario?.clienteSelecionado) {
      const lista = await listarUnidadesConsumidorasCombo(
        usuario?.clienteSelecionado,
        usuario?.token
      );
      if (lista?.status === 200 && lista?.data.unidadeConsumidoraResponseList) {
        store.dispatch(
          salvarUnidades(lista?.data.unidadeConsumidoraResponseList ?? [])
        );
      }
    }
  }, []);

  const [icms, setIcms] = useState(0);

  const enviarFormulario = async (dados) => {
    let hoje = new Date();
    const dd = String(hoje.getDate()).padStart(2, "0");
    const mm = String(hoje.getMonth() + 1).padStart(2, "0");
    const yyyy = hoje.getFullYear();
    hoje = `${yyyy}-${mm}-${dd}`;

    const cnpjFormatado = dados?.cnpj
      ? dados?.cnpj?.replace(/[^a-zA-Z0-9]/g, "")
      : valorCnpj?.replace(/[^a-zA-Z0-9]/g, "");
    const telefoneFormatado = dados?.telefone.replace(/[^a-zA-Z0-9]/g, "");

    try {
      // if (!usuario.clienteSelecionado) {
      //   store.dispatch(
      //     alertaExibir({
      //       tipo: "warning",
      //       mensagem: "É necessário selecionar um cliente"
      //     })
      //   );
      // } else {
      const salvou = await salvarUnidade(id ?? 0, {
        ...dados,
        UF: ufId,
        Status: statusId,
        SubmercadoId: submercadoId,
        NivelTensaoId: nivelTensaoId ?? "",
        DistribuidoraId: distribuidoraId,
        ClasseTarifariaId: modalidadeId ?? "",
        ClienteId: 0,
        PessoaFisicaJuridica: "PJ",
        Situacao: id ? (situacao === true ? "AT" : "IN") : "AT",
        DataReferencia: hoje,
        cnpjCpf: cnpjFormatado,
        telefone: telefoneFormatado,
        demandaContratadaForaPonta: dados?.demandaContratadaForaPonta
          .trim()
          .replace(",", "")
          .replace(".", ""),
        demandaContratadaPonta: dados?.demandaContratadaPonta
          ? dados?.demandaContratadaPonta
              .trim()
              .replace(",", "")
              .replace(".", "")
          : 0,
        icms: icms,
        DataPrevistaMigracao: moment(dataMigracao).isValid()
          ? moment(dataMigracao).format("YYYY-MM-DD")
          : "",
        tipoRepresentacaoId: tipoRepresentacaoId,
        icmsNaNotaFiscal:
          icmsNaNotaFiscal == "1"
            ? true
            : icmsNaNotaFiscal == "0"
            ? false
            : null,
        tipoUnidadeConsumidoraId: tipoUc,
        Origem: "Sistema"
      });

      if (salvou?.status === 200 || salvou?.status === 204) {
        store.dispatch(
          alertaExibir({
            tipo: "success",
            mensagem: "Unidade Consumidora salva com sucesso!"
          })
        );
        setTimeout(() => {
          recarregarUnidadesFiltro();
          history.push(RotasDTO.LeadUnidadesConsumidoras);
          store.dispatch(selecionarEmpresa(null));
          store.dispatch(selecionarCliente(null));
          store.dispatch(selecionarUnidade(null));
        }, 3000);
      }
      //}
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
    }
    setCarregandoUnidade(false);
  };

  const aoEnviarFormulario = (dados) => {
    setCarregandoUnidade(true);
    enviarFormulario(dados);
  };

  const [limparCnpj, setLimparCnpj] = useState(false);

  const [empresaUnidade, setEmpresaUnidade] = useState();
  const [clienteUnidade, setClienteUnidade] = useState();

  // Nome da empresa selecionada
  const nomeEmpresa = useMemo(() => {
    if (empresaUnidade?.nomeFantasia) return empresaUnidade?.nomeFantasia;

    const empresa = usuario?.empresas.find(
      (item) => String(item?.id) === String(usuario?.empresaSelecionada)
    );

    return empresa?.nomeFantasia ?? "";
  }, [usuario, dadosUnidade, empresaUnidade]);

  const obterEmpresa = useCallback(async () => {
    if (clienteUnidade?.empresaGrupoDeltaId) {
      try {
        const empresa = await buscarEmpresaPorId(
          clienteUnidade?.empresaGrupoDeltaId
        );

        if (empresa?.data) {
          setEmpresaUnidade(empresa?.data);
        }
      } catch (error) {
        console.info(error);
      }
    }
  }, [clienteUnidade]);

  useEffect(() => {
    obterEmpresa();
  }, [obterEmpresa]);

  const obterCliente = useCallback(async () => {
    if (dadosUnidade?.clienteId) {
      try {
        const cliente = await buscarClientePorId(dadosUnidade?.clienteId);

        if (cliente?.data) {
          setClienteUnidade(cliente?.data);
        }
      } catch (error) {
        console.info(error);
      }
    }
  }, [dadosUnidade]);

  useEffect(() => {
    obterCliente();
  }, [obterCliente]);

  // Nome da cliente selecionado
  const nomeCliente = useMemo(() => {
    let cliente;

    if (dadosUnidade?.clienteId) {
      cliente = usuario?.clientes.find(
        (item) => String(item?.id) === String(dadosUnidade?.clienteId)
      );

      if (!cliente && clienteUnidade) return clienteUnidade?.nomeFantasia ?? "";
    } else {
      cliente = usuario?.clientes.find(
        (item) => String(item?.id) === String(usuario?.clienteSelecionado)
      );
    }

    return cliente?.nomeFantasia ?? "";
  }, [usuario, dadosUnidade, clienteUnidade]);

  const [disabledCpf, setDisabledCpf] = useState(false);

  const verificarPermissaoAlterarCpf = useCallback(async () => {
    setDisabledCpf(true);
    usuario.menu.forEach((permissao) => {
      if (permissao.functionality.nome === "Pode editar CPF") {
        setDisabledCpf(false);
      }
      if (permissao?.functionality?.funcionalidadeColecao) {
        permissao.functionality.funcionalidadeColecao.forEach(
          (funcionalidade) => {
            if (funcionalidade.nome === "Pode editar CPF") {
              setDisabledCpf(false);
            }
            if (funcionalidade?.funcionalidadeColecao) {
              funcionalidade.funcionalidadeColecao.forEach(
                (funcionalidadeNeta) => {
                  if (funcionalidadeNeta.nome === "Pode editar CPF") {
                    setDisabledCpf(false);
                  }
                }
              );
            }
          }
        );
      }
    });
  }, []);

  useEffect(() => {
    if (id) verificarPermissaoAlterarCpf(id);
  }, [id, verificarPermissaoAlterarCpf]);

  useEffect(() => {
    if (empresaUnidade) {
      store.dispatch(selecionarEmpresa(empresaUnidade?.id));
    }
  }, [empresaUnidade]);

  const { empresaSelecionada } = usuario;

  useEffect(() => {
    if (empresaSelecionada && dadosUnidade) {
      store.dispatch(selecionarCliente(dadosUnidade?.clienteId));
    }
  }, [empresaSelecionada, dadosUnidade]);

  useEffect(() => {
    if (
      unidadeExistenteDados &&
      !id &&
      cnpjFormatado === unidadeExistenteDados.unidadesConsumidoras[0].cnpjCpf &&
      numeroInstalacao ===
        unidadeExistenteDados.unidadesConsumidoras[0].numeroInstalacao &&
      Number(distribuidoraId) ===
        unidadeExistenteDados.unidadesConsumidoras[0].distribuidoraId
    ) {
      setUnidadeExistente(true);
    }
  }, [
    cnpjFormatado,
    numeroInstalacao,
    distribuidoraId,
    unidadeExistenteDados,
    id
  ]);

  const [tipoRepresentacaoLista, setTipoRepresentacaoLista] = useState([]);

  const obterTipoRepresentacao = useCallback(async () => {
    try {
      const lista = await buscaTipoRepresentacao();
      if (lista?.status === 200 && lista?.data)
        setTipoRepresentacaoLista(lista?.data);
    } catch (error) {
      console.info(error);
    }
  }, []);

  useEffect(() => {
    obterTipoRepresentacao();
  }, [obterTipoRepresentacao]);

  const onChangeTipoRepresentacao = (valor) => {
    setTipoRepresentacaoId(valor.target.value);
  };

  const listaIcmsInclusoNF = [
    { nome: "Não", id: "0" },
    { nome: "Sim", id: "1" }
  ];

  const [icmsNaNotaFiscal, setIcmsNaNotaFiscal] = useState();
  const onChangeIcmsNF = (valor) => {
    setIcmsNaNotaFiscal(valor.target.value);
  };

  const [
    listaTipoUnidadeConsumidora,
    setListaTipoUnidadeConsumidora
  ] = useState(tipoUnidadeConsumidoraList);
  const [tipoUc, setTipoUc] = useState();
  const onChangeTipoUc = (selected) => {
    setTipoUc(selected.target.value);
  };

  const obterListaTipoUnidade = async () => {
    try {
      setCarregandoStatus(true);
      const lista = await buscarTiposUnidadeConsumidora();
      if (lista?.status === 200 && lista?.data) {
        setListaTipoUnidadeConsumidora(lista?.data.tipos);
      }
      setCarregandoStatus(false);
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
      setCarregandoStatus(false);
    }
  };

  const listaProdutos = [
    // Item mockado para Lead
    { label: "Mercado Livre", valor: 1, id: 1 }
  ];

  return (
    <>
      {
        <ModalConfirmacao
          item={unidadeExistente}
          onConfirmar={() => onConfirmarTelaEdicao()}
          mensagem="Unidade já cadastrada. Deseja ir para a tela de edição?"
          onCancelar={() => {
            setUnidadeExistente(false);
            zerarCamposEmpresa();
            zerarCamposParcela();
            zerarCamposEndereco();
          }}
        />
      }
      <form
        className="needs-validation"
        onSubmit={handleSubmit(aoEnviarFormulario)}
      >
        <Card className={classes.container}>
          <Grid container p={2} spacing={4} className={classes.container}>
            <Grid item xs={6} className="font-weight-bold">
              {dadosUnidade?.id
                ? "Editar unidade consumidora"
                : "Nova unidade consumidora"}
              {nomeEmpresa !== "" ? ` na empresa ${nomeEmpresa}` : ""}
              {nomeCliente !== "" ? ` e cliente ${nomeCliente}` : ""}
            </Grid>
            <BotaoRetornarListagem
              urlListagem={RotasDTO.LeadUnidadesConsumidoras}
              zerarCombo
            />
            <Grid item xs={12} className="font-weight-bold">
              {"Produto"}
              <InputRadioGroup
                disabled
                type="text"
                checked
                id="produtoId"
                name="produtoId"
                renderIconShowHide={false}
                className={classes.inputRadio}
                defaultValue={1}
                customValue={1}
                classNameLabel={classes.inputRadioLabel}
                itens={listaProdutos}
              />
            </Grid>
            <Grid item lg={3} md={3} sm={6}>
              <MaterialInputMascara
                type="text"
                id="cnpj"
                name="cnpj"
                label="CNPJ"
                mask="##.###.###/####-##"
                limparValor={limparCnpj}
                disabled={disabledCpf || !habilitarCampos}
                redefineLimpar={(estado) => setLimparCnpj(estado)}
                onBlur={(valor) => verificaCnpj(valor)}
                renderIconShowHide={false}
                defaultValue={dadosUnidade?.cnpjCpf}
                ref={register({
                  required: "Campo CNPJ é obrigatório!",
                  maxLength: {
                    value: 18,
                    message: "Quantidade máxima de 18 caracteres!"
                  },
                  minLength: {
                    value: 11,
                    message: "Quantidade mínima de 11 caracteres!"
                  },
                  validate: (value) => cnpj.isValid(value) || "CNPJ inválido!"
                })}
                errors={errors}
              />
            </Grid>
            <Grid item lg={6} md={6} sm={6}>
              <Loader loading={carregandoCnpj}>
                <MaterialInputTexto
                  type="text"
                  id="nomeRazaoSocial"
                  name="nomeRazaoSocial"
                  label="Razão Social"
                  disabled={!habilitarCampos}
                  renderIconShowHide={false}
                  permiteValorBranco
                  defaultValue={razaoSocial}
                  ref={register({
                    required: "Campo Razão Social é obrigatório!",
                    maxLength: {
                      value: 200,
                      message: "Quantidade máxima de 200 caracteres!"
                    }
                  })}
                  errors={errors}
                />
              </Loader>
            </Grid>
            <Grid
              item
              lg={3}
              md={3}
              sm={6}
              className={classes.containerTelefone}
            >
              <Loader loading={carregandoCnpj}>
                <MaterialInputTelefone
                  type="text"
                  id="telefone"
                  name="telefone"
                  label="Telefone"
                  disabled={!habilitarCampos}
                  renderIconShowHide={false}
                  defaultValue={valorTelefone}
                  value={valorTelefone}
                  ref={register({
                    required: "O campo Telefone é obrigatório!",
                    minLength: {
                      value: 18,
                      message: "Quantidade mínima de 12 caracteres!"
                    }
                  })}
                  errors={errors}
                />
              </Loader>
            </Grid>
          </Grid>
          <Grid container p={2} spacing={4} className={classes.container}>
            <Grid item lg={6} md={6} sm={6}>
              <Loader loading={carregandoCnpj}>
                <MaterialInputTexto
                  type="text"
                  id="nomeUnidade"
                  name="nomeUnidade"
                  label="Nome Fantasia"
                  disabled={!habilitarCampos}
                  permiteValorBranco
                  renderIconShowHide={false}
                  defaultValue={nomeFantasia}
                  onBlur={(event) => onBlurNomeFantasia(event.target.value)}
                  onInput={(event) => onBlurNomeFantasia(event.target.value)}
                  ref={register({
                    required: "Campo Nome Fantasia é obrigatório!",
                    maxLength: {
                      value: 50,
                      message: "Quantidade máxima de 50 caracteres!"
                    },
                    validate: (value) =>
                      (!value.includes("'") && !value.includes('"')) ||
                      "Não é permitido adicionar aspas no Nome Fantasia"
                  })}
                  errors={errors}
                />
              </Loader>
            </Grid>
            <Grid item lg={3} md={3} sm={6}>
              <Loader loading={carregandoCnpj}>
                <MaterialInputTexto
                  type="text"
                  id="inscricaoEstadual"
                  name="inscricaoEstadual"
                  label="Inscrição Estadual"
                  disabled={!habilitarCampos}
                  renderIconShowHide={false}
                  permiteValorBranco
                  defaultValue={inscricaoEstadual}
                  ref={register({
                    maxLength: {
                      value: 30,
                      message: "Quantidade máxima de 30 caracteres!"
                    }
                  })}
                  maxLength={30}
                  errors={errors}
                />
              </Loader>
            </Grid>
            <Grid item lg={3} md={3} sm={6}>
              <Loader loading={carregandoCnpj}>
                <MaterialInputTexto
                  type="text"
                  id="inscricaoMunicipal"
                  name="inscricaoMunicipal"
                  label="Inscrição Municipal"
                  disabled={!habilitarCampos}
                  permiteValorBranco
                  renderIconShowHide={false}
                  defaultValue={inscricaoMunicipal}
                  ref={register({
                    maxLength: {
                      value: 30,
                      message: "Quantidade máxima de 30 caracteres!"
                    }
                  })}
                  maxLength={30}
                  errors={errors}
                />
              </Loader>
            </Grid>
          </Grid>
          <Grid container p={2} spacing={4} className={classes.container}>
            {/* <Grid item lg={3} md={3} sm={5}>
            <Loader loading={carregandoStatus || carregandoCnpj}>
              <SelectArredondado
                id="Status"
                name="Status"
                valueKey="codigo"
                valueName="descricao"
                dataSource={listaStatus}
                label="Status"
                disabled={!habilitarCampos}
                value={statusId}
                onChange={(status) => onChangeStatus(status)}
                placeholder="Status"
                marginBottom="0"
                allowClear
                ref={register(
                  { name: "Status" },
                  {
                    required: !statusId
                      ? "Campo Status é obrigatório!"
                      : false
                  }
                )}
                errors={errors}
              />
            </Loader>
          </Grid> */}
            {/* <Grid item lg={3} md={3} sm={5}>
            <Loader loading={carregandoDistribuidora}>
              <SelectArredondado
                id="Distribuidora"
                name="Distribuidora"
                valueKey="id"
                marginBottom="0"
                valueName="nomeFantasia"
                dataSource={listaDistribuidora}
                label="Distribuidora"
                disabled={!habilitarCampos}
                value={distribuidoraId}
                onChange={(distribuidora) =>
                  onChangeDistribuidora(distribuidora)
                }
                placeholder="Distribuidora"
                allowClear
                ref={register(
                  { name: "Distribuidora" },
                  {
                    required: !distribuidoraId
                      ? "Campo Distribuidora é obrigatório!"
                      : false
                  }
                )}
                errors={errors}
              />
            </Loader>
          </Grid> */}

            <Grid item lg={3} md={3} sm={5}>
              <Loader loading={carregandoDistribuidora}>
                <SelectAutocomplete
                  disableClearable
                  disableOpenOnFocus
                  id="Distribuidora"
                  name="Distribuidora"
                  valueKey="id"
                  marginBottom="0"
                  label="Distribuidora"
                  placeholder="Distribuidora"
                  defaultValue={distribuidoraId}
                  value={distribuidoraId}
                  options={listaDistribuidora}
                  getOptionLabel={(option) => option.nomeFantasia}
                  onChange={(e, item) => {
                    onChangeDistribuidora(item?.id ?? 0);
                  }}
                  ref={register(
                    { name: "Distribuidora" },
                    {
                      required: !distribuidoraId
                        ? "Campo Distribuidora é obrigatório!"
                        : false
                    }
                  )}
                  error={errors}
                  allowClear
                  valueName="nomeFantasia"
                />
              </Loader>
            </Grid>
            <Grid item lg={3} md={3} sm={5}>
              <Loader loading={carregandoStatus || carregandoCnpj}>
                <SelectArredondado
                  id="tipoRepresentacaoId"
                  name="tipoRepresentacaoId"
                  valueKey="id"
                  valueName="descricao"
                  dataSource={tipoRepresentacaoLista}
                  label="Tipo representação"
                  disabled={!habilitarCampos}
                  value={tipoRepresentacaoId}
                  onChange={(valor) => onChangeTipoRepresentacao(valor)}
                  placeholder="Tipo representação"
                  marginBottom="0"
                  allowClear
                  ref={register(
                    { name: "tipoRepresentacaoId" },
                    {
                      required: !tipoRepresentacaoId
                        ? "Campo Tipo representação é obrigatório!"
                        : false
                    }
                  )}
                  errors={errors}
                />
              </Loader>
            </Grid>
          </Grid>
          {/* <Grid container p={2} spacing={4} className={classes.containerAtivo}>
          {dadosUnidade?.id ? (
            <Grid item lg={3} md={3} sm={5}>
              <MaterialSwitch
                label="Ativo"
                disabled={!habilitarCampos}
                labelPlacement="top"
                id="Situacao"
                name="Situacao"
                checked={situacao}
                onChange={(valor) => onChangeSituacao(valor)}
              />
            </Grid>
          ) : null}
        </Grid> */}
          <Divider className={classes.divider} />
          <Grid container p={2} spacing={4} className={classes.container}>
            <Grid item xs={12} className="font-weight-bold">
              {"Endereço"}
            </Grid>
            <Grid item lg={3} md={3} sm={5}>
              <Loader loading={carregandoEndereco || carregandoCnpj}>
                <MaterialInputMascara
                  type="text"
                  id="cep"
                  name="cep"
                  label="CEP"
                  mask="#####-###"
                  disabled={!habilitarCampos}
                  limparValor={limparCep}
                  redefineLimpar={(estado) => setLimparCep(estado)}
                  renderIconShowHide={false}
                  defaultValue={valorCep?.replace("-", "")}
                  maxLength={9}
                  onBlur={(event) => onBlurCep(event.target.value)}
                  ref={register({
                    required: "O campo CEP é obrigatório!",
                    minLength: {
                      value: 9,
                      message: "Quantidade minima de 9 caracteres!"
                    },
                    maxLength: {
                      value: 9,
                      message: "Quantidade máxima de 9 caracteres!"
                    },
                    validate: (value) =>
                      value.replace(/\s/g, "").length === 9 ||
                      "Deve ser informado 8 digitos"
                  })}
                  errors={errors}
                />
              </Loader>
            </Grid>
            <Grid item lg={7} md={7} sm={9}>
              <Loader loading={carregandoEndereco || carregandoCnpj}>
                <MaterialInputTexto
                  type="text"
                  id="endereco"
                  name="endereco"
                  label="Endereço"
                  disabled={!habilitarCampos}
                  renderIconShowHide={false}
                  permiteValorBranco
                  defaultValue={dadosEndereco?.endereco}
                  ref={register({
                    required: "Campo Endereço é obrigatório!",
                    maxLength: {
                      value: 200,
                      message: "Quantidade máxima de 200 caracteres!"
                    }
                  })}
                  errors={errors}
                />
              </Loader>
            </Grid>
            <Grid item lg={2} md={4} sm={4}>
              <Loader loading={carregandoEndereco || carregandoCnpj}>
                <MaterialInputTexto
                  type="text"
                  id="numero"
                  name="numero"
                  label="Número"
                  disabled={!habilitarCampos}
                  permiteValorBranco
                  renderIconShowHide={false}
                  defaultValue={numero}
                  ref={register({
                    required: "Campo Número é obrigatório!",
                    maxLength: {
                      value: 10,
                      message: "Quantidade máxima de 10 caracteres!"
                    }
                  })}
                  errors={errors}
                />
              </Loader>
            </Grid>
          </Grid>
          <Grid container p={2} spacing={4} className={classes.container}>
            <Grid item lg={3} md={3} sm={5}>
              <Loader loading={carregandoEndereco || carregandoCnpj}>
                <MaterialInputTexto
                  type="text"
                  id="complemento"
                  name="complemento"
                  label="Complemento"
                  disabled={!habilitarCampos}
                  permiteValorBranco
                  renderIconShowHide={false}
                  defaultValue={complemento}
                  errors={errors}
                  ref={register}
                />
              </Loader>
            </Grid>
            <Grid item lg={3} md={3} sm={5}>
              <Loader loading={carregandoEndereco || carregandoCnpj}>
                <MaterialInputTexto
                  type="text"
                  id="bairro"
                  name="bairro"
                  label="Bairro"
                  disabled={!habilitarCampos}
                  permiteValorBranco
                  renderIconShowHide={false}
                  defaultValue={dadosEndereco?.bairro}
                  ref={register({
                    required: "Campo Bairro é obrigatório!",
                    maxLength: {
                      value: 100,
                      message: "Quantidade máxima de 100 caracteres!"
                    }
                  })}
                  errors={errors}
                />
              </Loader>
            </Grid>
            <Grid item lg={4} md={4} sm={6}>
              <Loader loading={carregandoEndereco || carregandoCnpj}>
                <MaterialInputTexto
                  type="text"
                  id="cidade"
                  name="cidade"
                  label="Cidade"
                  disabled={!habilitarCampos}
                  permiteValorBranco
                  renderIconShowHide={false}
                  defaultValue={dadosEndereco?.cidade}
                  ref={register({
                    required: "Campo Cidade é obrigatório!",
                    maxLength: {
                      value: 100,
                      message: "Quantidade máxima de 100 caracteres!"
                    }
                  })}
                  errors={errors}
                />
              </Loader>
            </Grid>
            <Grid item lg={2} md={2} sm={4}>
              <Loader loading={carregandoEndereco || carregandoCnpj}>
                <SelectArredondado
                  id="uf"
                  name="uf"
                  valueKey="UF"
                  marginBottom="0"
                  valueName="UF"
                  disabled={!habilitarCampos}
                  dataSource={listaUF}
                  label="UF"
                  value={ufId}
                  onChange={(estado) => onChangeUF(estado)}
                  placeholder="UF"
                  allowClear
                  ref={register(
                    { name: "uf" },
                    {
                      required: !ufId ? "Campo UF é obrigatório!" : false
                    }
                  )}
                  errors={errors}
                />
              </Loader>
            </Grid>
          </Grid>
          <Grid container p={2} spacing={4} className={classes.container}>
            <Grid item xs={12} className="font-weight-bold">
              {"Cadastro complementar"}
            </Grid>
            <Grid item lg={3} md={3} sm={5}>
              <InputData
                type="text"
                id="dataMigracao"
                name="dataMigracao"
                label="Data de Migração"
                disabled={!habilitarCampos}
                customValue={dataMigracao}
                onChange={(data) => onChangeDataMigracao(data)}
                renderIconShowHide={false}
                ref={register({
                  validate: (value) =>
                    (moment(value, "DD/MM/YYYY").isValid() &&
                      moment(value, "DD/MM/YYYY") >
                        moment("01/01/1900", "DD/MM/YYYY")) ||
                    value === "" ||
                    "Data de Migração inválida!"
                })}
                errors={errors}
                className={classes}
              />
            </Grid>
            <Grid item lg={3} md={3} sm={5}>
              <MaterialInputMascara
                type="text"
                id="numeroInstalacao"
                name="numeroInstalacao"
                label="N° da instalação"
                disabled={!habilitarCampos}
                mask={null}
                renderIconShowHide={false}
                onBlur={(event) => setNumeroInstalacao(event.target.value ?? 0)}
                defaultValue={dadosUnidade?.numeroInstalacao}
                ref={register({
                  maxLength: {
                    value: 50,
                    message: "Quantidade máxima de 50 caracteres!"
                  }
                })}
                maxLength={30}
                errors={errors}
              />
            </Grid>
            <Grid item lg={3} md={3} sm={5}>
              <Loader loading={carregandoSubmercado || carregandoCnpj}>
                <SelectArredondado
                  id="Submercado"
                  name="Submercado"
                  valueKey="id"
                  valueName="descricao"
                  marginBottom="0"
                  disabled={!habilitarCampos}
                  dataSource={listaSubmercado}
                  label="Submercado"
                  value={submercadoId}
                  onChange={(submercado) => onChangeSubmercado(submercado)}
                  placeholder="Submercado"
                  allowClear
                  errors={errors}
                />
              </Loader>
            </Grid>
            <Grid item lg={3} md={3} sm={5}>
              <Loader loading={carregandoCnpj}>
                <MaterialInputTexto
                  type="text"
                  id="parcelaCarga"
                  name="parcelaCarga"
                  label="Parcela de Carga"
                  permiteValorBranco
                  renderIconShowHide={false}
                  defaultValue={parcelaDeCarga}
                  ref={register({
                    maxLength: {
                      value: 50,
                      message: "Quantidade máxima de 50 caracteres!"
                    }
                  })}
                  maxLength={50}
                  errors={errors}
                  disabled={parcelaDeCargaExistente || !habilitarCampos}
                />
              </Loader>
            </Grid>
            <Grid item lg={3} md={3} sm={5}>
              <MaterialInputMascara
                type="text"
                id="icms"
                name="icms"
                label="ICMS"
                mask="##%"
                disabled={!habilitarCampos}
                renderIconShowHide={false}
                defaultValue={dadosUnidade?.icms}
                onBlur={(valor) => {
                  setIcms(removerCaracteres(valor?.target?.value));
                }}
                maxLength={30}
                errors={errors}
              />
            </Grid>
            <Grid item lg={3} md={3} sm={5}>
              <Loader loading={carregandoNivelTensao}>
                <SelectArredondado
                  id="NivelTensao"
                  name="NivelTensao"
                  valueKey="id"
                  marginBottom="0"
                  valueName="descricao"
                  dataSource={listaNivelTensao}
                  label="Nível de tensão"
                  disabled={!habilitarCampos}
                  value={nivelTensaoId}
                  onChange={(nivelTensao) => onChangeNivelTensao(nivelTensao)}
                  placeholder="Nível de tensão"
                  allowClear
                  errors={errors}
                />
              </Loader>
            </Grid>
            <Grid item lg={3} md={3} sm={5}>
              <SelectArredondado
                id="Modalidade"
                name="Modalidade"
                valueKey="id"
                valueName="nome"
                marginBottom="0"
                dataSource={listaModalidade}
                label="Modalidade"
                disabled={!habilitarCampos}
                value={modalidadeId}
                onChange={(modalidade) => onChangeModalidade(modalidade)}
                placeholder="Modalidade"
                allowClear
                errors={errors}
              />
            </Grid>
            <Grid item lg={3} md={3} sm={5}>
              <MaterialInputMascara
                type="text"
                id="demandaContratadaForaPonta"
                name="demandaContratadaForaPonta"
                label="Demanda C. fora ponta"
                disabled={!habilitarCampos}
                renderIconShowHide={false}
                defaultValue={dadosUnidade?.demandaContratadaForaPonta}
                ref={register({
                  maxLength: {
                    value: 32,
                    message: "Quantidade máxima de 32 caracteres!"
                  }
                })}
                maxLength={32}
                errors={errors}
              />
            </Grid>
            <Grid item lg={3} md={3} sm={5}>
              {tipoFormulario === 1 ? (
                <MaterialInputMascara
                  type="text"
                  id="demandaContratadaPonta"
                  name="demandaContratadaPonta"
                  label="Demanda C. ponta"
                  disabled={!habilitarCampos}
                  renderIconShowHide={false}
                  defaultValue={dadosUnidade?.demandaContratadaPonta}
                  ref={register({
                    maxLength: {
                      value: 32,
                      message: "Quantidade máxima de 32 caracteres!"
                    }
                  })}
                  maxLength={32}
                  errors={errors}
                />
              ) : null}
            </Grid>
            <Grid item lg={3} md={3} sm={5}>
              <SelectArredondado
                id="icmsNaNotaFiscal"
                name="icmsNaNotaFiscal"
                valueKey="id"
                marginBottom="0"
                valueName="nome"
                dataSource={listaIcmsInclusoNF}
                label="ICMS na NF de energia?"
                disabled={!habilitarCampos}
                value={icmsNaNotaFiscal}
                onChange={(valor) => onChangeIcmsNF(valor)}
                placeholder="ICMS na NF de energia?"
                allowClear
                errors={errors}
              />
            </Grid>

            <Grid item lg={3} md={3} sm={12}>
              <MaterialInputTexto
                type="text"
                id="cnae"
                name="cnae"
                label="CNAE"
                disabled={!habilitarCampos}
                renderIconShowHide={false}
                defaultValue={dadosUnidade?.cnae ?? ""}
                ref={register({
                  maxLength: {
                    value: 2,
                    message: "Quantidade máxima de 2 caracteres!"
                  }
                })}
                errors={errors}
              />
            </Grid>

            <Grid item lg={3} md={3} sm={12}>
              <SelectArredondado
                id="tipoUnidadeConsumidoraId"
                name="tipoUnidadeConsumidoraId"
                valueKey="id"
                valueName="descricao"
                dataSource={listaTipoUnidadeConsumidora}
                label="Tipo da UC"
                disabled={!habilitarCampos}
                value={dadosUnidade?.tipoUnidadeConsumidoraId ?? tipoUc}
                onChange={(value) => onChangeTipoUc(value)}
                placeholder="Tipo da UC"
                allowClear
                errors={errors}
              />
            </Grid>
          </Grid>
          <Grid container p={2} spacing={4} className={classes.containerSalvar}>
            <Grid item lg={3} md={4} sm={6}>
              <Loader loading={carregandoUnidade}>
                <Botao
                  type="submit"
                  label="Salvar"
                  disabled={!habilitarCampos}
                  className={classes.button}
                />
              </Loader>
            </Grid>
          </Grid>
        </Card>
      </form>
    </>
  );
};

export default UnidadesCadastro;
