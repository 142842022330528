import { makeStyles } from '@mui/styles';
import { Base } from "../../../../../componentes/cores";
import theme from 'themes';

export const useStyles = makeStyles({
  form: {
    backgroundColor: "transparent",
    "pointer-events": "all"
  },
  container: {
    height: "54px"
  },
  gridItem: {
    backgroundColor: Base.White
  },
  itemSelect: {
    flexBasis: "100%",
    maxWidth: "128px",
    width: "128px"
  },
  select: {
    border: "none",
    borderRadius: "0",
    color: theme.color.primaryBackgroud,
    marginBottom: "0",
    "&:hover": {
      border: "none"
    },
    "& .MuiOutlinedInput-root": {
      border: "none",
      borderRadius: "0",
      "&:hover": {
        border: "none",
        borderRadius: "0"
      }
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      border: "none",
      borderRadius: "0"
    },
    "& .MuiSelect-outlined.MuiSelect-outlined": {
      border: "none",
      borderRadius: "0"
    }
  },
  itemClose: {
    alignItems: "center",
    backgroundColor: Base.White,
    display: "flex",
    height: "100%",
    justifyContent: "center",
    maxWidth: "54px",
    textAlign: "center",
    width: "54px"
  },
  buttonClose: {
    cursor: "pointer",
    height: "24px",
    "pointer-events": "all",
    width: "24px"
  },
  itemCadastrar: {
    alignItems: "center",
    backgroundColor: Base.White,
    display: "flex",
    height: "100%",
    justifyContent: "center",
    maxWidth: "54px",
    textAlign: "center",
    width: "54px"
  },
  buttonCadastrar: {
    cursor: "pointer",
    height: "24px",
    "pointer-events": "all",
    width: "24px"
  },
  itemFull: {
    flexBasis: "0",
    flexGrow: 1,
    maxWidth: "100%"
  },
  itemLabel: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    paddingLeft: "5px"
  },
  label: {
    color: Base.GreyDim,
    fontSize: "16px",
    height: "auto",
    margin: "0",
    padding: "0"
  },
  input: {
    border: "0",
    borderRadius: "0",
    fontSize: "16px",
    outline: "none",
    padding: "0 0 0 6px",
    height: "100%",
    width: "100%"
  },
  descricao: {
    fontSize: "14px"
  },
  divider: {
    marginRight: "2px"
  },
  margin: {
    marginBottom: "2px"
  },
  customContainer: {
    height: "108px"
  },
  customLabel: {
    height: "54px !important",
    position: "absolute",
    top: "0"
  },
  customNome: {
    height: "54px !important",
    position: "absolute",
    marginRight: "0",
    right: "0",
    top: "0",
    width: "-webkit-fill-available"
  },
  customDescricao: {
    backgroundColor: Base.White,
    borderTop: "2px solid",
    bottom: "0",
    height: "54px !important",
    left: "0",
    marginLeft: "-130px",
    marginRight: "0",
    maxWidth: "-webkit-fill-available",
    paddingLeft: "0",
    position: "absolute",
    right: "0",
    width: "calc(100vw - 54px - 110px)"
  },
  errorLabel: {
    borderColor: Base.RedAmaranth,
    borderRightWidth: "0",
    borderStyle: "solid",
    borderWidth: "1px"
  },
  errorInput: {
    borderColor: Base.RedAmaranth,
    borderLeftWidth: "0",
    borderStyle: "solid",
    borderWidth: "1px"
  }
});
