import { makeStyles } from '@mui/styles';
import { Base } from "componentes/cores";
import getDomain from "global/getDomain";
import theme from 'themes';
import { createTheme } from "@mui/material/styles";

const th = createTheme();

export const useStyles = makeStyles(() => ({
  container: {
    margin: "0",
    width: "100%",
    "& .MuiGrid-root": {
      color: theme.color.fourthBackgroud
    },
    "& .MuiDropzoneArea-text": {
      color: theme.color.fourthBackgroud
    },
    "& .MuiInputBase-root": {
      height: "46px"
    },
    "& .MuiFormLabel-root": {
      lineHeight: "1"
    },
    "& .MuiTypography-root": {
      fontSize: "14px",
      [th.breakpoints.up("xl")]: {
        fontSize: "18px"
      }
    }
  },
  containerTelefone: {
    "& .MuiInputBase-input": {
      height: "7px"
    }
  },
  button: {
    fontSize: "18px",
    "&:disabled": {
      color: Base.Black,
      "&:hover": {
        color: Base.Black
      }
    }
  },
  inputTelefone: {
    "&.Mui-error": {
      borderColor: Base.RedError,
      color: Base.RedError
    }
  },
  addRepresentante: {
    "& .MuiSvgIcon-root, button": {
      color: Base.Black
    }
  },
  subTitulo: {
    marginTop: "10px",
    marginBottom: "-20px"
  },
  imagemFundo: {
    background: getDomain() !== 'luz' && `url(../../themes/${getDomain()}/assets/cotacao_dados_energia.png)`,
    backgroundSize: "cover"
  },
  faturaEnergia: {
    textAlign: "center",
    border: "1px solid #191c224D",
    padding: "24px 16px 16px",
    fontSize: "14px",

    "& span": {
      fontSize: "18px",
      letterSpacing: "-0.4px"
    },
    "& .MuiDropzoneArea-root": {
      borderWidth: "2px",
      borderColor: "#00000080"
    },
    "& .MuiDropzoneArea-textContainer": {
      flexDirection: "column-reverse",

      "& .MuiDropzoneArea-icon": {
        marginTop: "-15px",
        marginBottom: "-10px",
        width: "24px",
        marginRight: 0
      },
      "& .MuiDropzoneArea-text": {
        margin: 0,
        fontSize: "12px",
        padding: "0 50px"
      }
    }
  },
  cnpjCep : {
    "& label": {
      fontWeight: 'bold',
      fontSize: 'large'
    }
  },
  textoSpan:{
    color: `${Base.BlackRussian} !important`,
    fontWeight: 'bold'
  },
  excluirRepresentante:{
    color: `${Base.BlackRussian} !important`,
    cursor: 'pointer'
  }
}));
