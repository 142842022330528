import { Card, Grid } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import { Base } from "componentes/cores";

import {
  BotaoRetornarListagem,
  Botao,
  Switch,
  SelectArredondado,
  InputTextoData
} from "componentes";
import CustomLoader from "componentes/customLoader";

import { RotasDTO } from "global/rotas/rotasUrlDto";
import { store } from "global/redux";
import { alertaExibir } from "global/redux/modulos/alertas/actions";
import { handleLoading } from "global/redux/modulos/loader/actions";

import { useStyles } from "./style";
import Helper from "./helper";

import { useSelector } from "react-redux";
import UploadDropAreaMultiplo from "componentes/uploadMultiplo";
import SelectAutoComplete from "componentes/selectAutocomplete";
import UploadVideos from "./Componentes/UploadVideos";

function Cadastro() {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const usuario = useSelector((state) => state.usuario);
  const { empresaSelecionada } = usuario;

  const { id: idUrl } = useParams();

  const [id, setId] = useState();
  const [ativo, setAtivo] = useState();
  const [DataReferencia, setDataReferencia] = useState(new Date());
  const [listaTipos, setListaTipos] = useState();
  const [TipoRelatorioId, setTipoRelatorioId] = useState();
  const [listaClientes, setListaClientes] = useState();
  const [ClienteId, setClienteId] = useState();
  const [listaUnidades, setListaUnidades] = useState();
  const [UnidadeId, setUnidadeId] = useState();
  const [serveParaCliente, setServeParaCliente] = useState(false);
  const [progress, setProgress] = useState(0);
  const [arquivos, setArquivos] = useState([]);
  const [nome, setNome] = useState();
  const videoInicial = [
    { nome: "", descricao: "", ordem: 1, arquivo: "", extensao: "" }
  ];
  const [videos, setVideos] = useState(videoInicial);
  const [videosSalvar, setVideosSalvar] = useState([]);
  const [camposInvalidos, setCamposInvalidos] = useState(false);
  const [renderizaVideos, setRenderizaVideos] = useState(false);

  const { register, errors, handleSubmit } = useForm({
    reValidateMode: "onSubmit"
  });

  const verficarCamposVideos = () => {
    setCamposInvalidos(false);
    if (videosSalvar.length) {
      const videosVazios = videos.find(
        (video) =>
          !video?.nome ||
          video?.nome.length > 200 ||
          !video?.descricao ||
          video?.descricao?.length > 1000 ||
          !video?.ordem ||
          !video?.arquivo
      );
      const ordems = videos.map((item) => Number(item.ordem));
      const duplicados = ordems.filter(
        (item, idx) => ordems.indexOf(item) != idx
      );
      if (videosVazios || duplicados.length) {
        setCamposInvalidos(true);
        return true;
      }

      return false;
    }
    return false;
  };

  const dividirArquivo = (file) => {
    const partSize = 1048576 * 200;
    const numberOfParts =
      file.size % partSize == 0
        ? file.size / partSize
        : Math.floor(file.size / partSize) + 1;
    const newParts = [];
    let start = 0;

    for (let i = 0; i < numberOfParts; i++) {
      const end = start + partSize + 1;
      const parte = file.slice(start, end);
      newParts.push({ file: parte });
      start = end;
    }

    return newParts;
  };

  const enviarVideos = async (relatorio) => {
    if (videosSalvar.length) {
      let respostasApi = [];

      for (let video of videosSalvar) {

        let respostaUrl;
        let arquivos = [];
        let respostaMultpartUpload;
        let respostaMultpartUploadImagem;
        let respostaCompletar;

        if (
          video?.arquivo &&
          video?.arquivo?.size &&
          video?.arquivo?.size > 0 &&
          video?.id &&
          video?.identificador &&
          video?.extensao
        ) {
          const retornoDeletar = await Helper.DeletarVideoIdentificador(video);

          if (!retornoDeletar.sucesso) {
            store.dispatch(
              alertaExibir({
                tipo: "danger",
                mensagem: retornoDeletar.mensagem
              })
            );
            return;
          }
        }

        if (
          video?.arquivo &&
          video?.arquivo?.size &&
          video?.arquivo?.size > 0 &&
          video?.novaExtensao
        ) {
          respostaMultpartUpload = await Helper.IniciaMultpartUpload({
            extensao: video.novaExtensao
          });

          if (!respostaMultpartUpload.sucesso) {
            store.dispatch(
              alertaExibir({
                tipo: "danger",
                mensagem: respostaMultpartUpload.mensagem
              })
            );
            return;
          }

          arquivos = dividirArquivo(video.arquivo);

          respostaUrl = await Helper.GerarLinkVideo({
            key: respostaMultpartUpload.data.key,
            uploadId: respostaMultpartUpload.data.uploadId,
            partsNumber: arquivos.length,
            duracao: Number(process.env.REACT_APP_UPLOAD_VIDEO_DURACAO)
          });

          if (!respostaUrl.sucesso) {
            await Helper.CancelarEnvioVideo({
              key: respostaMultpartUpload.data.key,
              uploadId: respostaMultpartUpload.data.uploadId
            });
            store.dispatch(
              alertaExibir({
                tipo: "danger",
                mensagem: respostaUrl.mensagem
              })
            );
            return;
          }
        }

        if (arquivos.length && respostaUrl?.data?.length) {
          let videoParamsS3 = {
            urls: respostaUrl?.data,
            arquivos: arquivos
          };
          const retornoS3 = await Helper.PostarVideoS3(videoParamsS3);

          if (!retornoS3.sucesso) {
            await Helper.CancelarEnvioVideo({
              key: respostaMultpartUpload.data.key,
              uploadId: respostaMultpartUpload.data.uploadId
            });
            store.dispatch(
              alertaExibir({
                tipo: "danger",
                mensagem: retornoS3.mensagem
              })
            );
            return;
          }

          const paramsCompletar = {
            key: respostaMultpartUpload?.data?.key,
            uploadId: respostaMultpartUpload?.data?.uploadId,
            eTags: retornoS3.data.sort((a, b) => a.partNumber - b.partNumber)
          };

          respostaCompletar = await Helper.CompletarEnvioVideo(paramsCompletar);

          if (!respostaCompletar.sucesso) {
            await Helper.CancelarEnvioVideo({
              key: respostaMultpartUpload.data.key,
              uploadId: respostaMultpartUpload.data.uploadId
            });
            store.dispatch(
              alertaExibir({
                tipo: "danger",
                mensagem: retornoS3.mensagem
              })
            );
            return;
          }
        }

        //ENVIO DA IMAGEM
        if (
          video?.arquivoImagem &&
          video?.arquivoImagem?.size &&
          video?.arquivoImagem?.size > 0 &&
          video?.novaExtensaoImagem
        ) {
          respostaMultpartUploadImagem = await Helper.IniciaMultpartUpload({
            extensao: video.novaExtensaoImagem
          });

          if (!respostaMultpartUploadImagem.sucesso) {
            store.dispatch(
              alertaExibir({
                tipo: "danger",
                mensagem: respostaMultpartUploadImagem.mensagem
              })
            );
            return;
          }

          arquivos = dividirArquivo(video.arquivoImagem);

          respostaUrl = await Helper.GerarLinkVideo({
            key: respostaMultpartUploadImagem.data.key,
            uploadId: respostaMultpartUploadImagem.data.uploadId,
            partsNumber: arquivos.length,
            duracao: Number(process.env.REACT_APP_UPLOAD_VIDEO_DURACAO)
          });

          if (!respostaUrl.sucesso) {
            await Helper.CancelarEnvioVideo({
              key: respostaMultpartUploadImagem.data.key,
              uploadId: respostaMultpartUploadImagem.data.uploadId
            });
            store.dispatch(
              alertaExibir({
                tipo: "danger",
                mensagem: respostaUrl.mensagem
              })
            );
            return;
          }
        }

        
        if (arquivos.length && respostaUrl?.data?.length) {
          let videoParamsS3 = {
            urls: respostaUrl?.data,
            arquivos: arquivos
          };
          const retornoS3 = await Helper.PostarVideoS3(videoParamsS3);

          if (!retornoS3.sucesso) {
            await Helper.CancelarEnvioVideo({
              key: respostaMultpartUploadImagem.data.key,
              uploadId: respostaMultpartUploadImagem.data.uploadId
            });
            store.dispatch(
              alertaExibir({
                tipo: "danger",
                mensagem: retornoS3.mensagem
              })
            );
            return;
          }

          const paramsCompletar = {
            key: respostaMultpartUploadImagem?.data?.key,
            uploadId: respostaMultpartUploadImagem?.data?.uploadId,
            eTags: retornoS3.data.sort((a, b) => a.partNumber - b.partNumber)
          };

          respostaCompletar = await Helper.CompletarEnvioVideo(paramsCompletar);

          if (!respostaCompletar.sucesso) {
            await Helper.CancelarEnvioVideo({
              key: respostaMultpartUploadImagem.data.key,
              uploadId: respostaMultpartUploadImagem.data.uploadId
            });
            store.dispatch(
              alertaExibir({
                tipo: "danger",
                mensagem: retornoS3.mensagem
              })
            );
            return;
          }
        }

        //ENVIO IMAGENS

        let videoParams = {
          nome: video.nome,
          descricao: video.descricao,
          relatorioId: id || relatorio[0]?.id,
          ativo: true,
          dataUpload: respostaCompletar?.data?.dataUpload || video.dataUpload,
          extensao: video.novaExtensao,
          identificador: respostaMultpartUpload?.data?.identificador || video.identificador,
          extensaoImagem: video.novaExtensaoImagem,
          identificadorImagem: respostaMultpartUploadImagem?.data?.identificador || video.identificadorImagem
        };
        
        let respostaVideoPostado;

        if (video.id && video.identificador) {
          videoParams.id = video.id;
          respostaVideoPostado = await Helper.AtualizarVideo(videoParams);
        } else {
          respostaVideoPostado = await Helper.PostarVideo(videoParams);
        }

        if (!respostaVideoPostado.sucesso) {
          await Helper.CancelarEnvioVideo({
            key: respostaMultpartUpload.data.key,
            uploadId: respostaMultpartUpload.data.uploadId
          });
          setProgress(0);
          store.dispatch(
            alertaExibir({
              tipo: "danger",
              mensagem: respostaVideoPostado.mensagem
            })
          );
          return;
        }

        respostasApi.push({
          id: respostaVideoPostado?.data?.id || video?.id,
          ordem: video.ordem
        });
      }

      if (respostasApi?.filter((item) => item).length) {
        const respostaOrdenacao = await Helper.AtualizarOrdemsVideos(
          respostasApi
        );

        if (!respostaOrdenacao.sucesso) {
          store.dispatch(
            alertaExibir({
              tipo: "danger",
              mensagem: respostaOrdenacao.mensagem
            })
          );
          return;
        }
      }
    }
  };

  const verficarCampoArquivo = () => {
    if (!serveParaCliente && arquivos.length > 1) {
      store.dispatch(
        alertaExibir({
          tipo: "danger",
          mensagem: "Só é possível subir um arquivo de relatório por vez!"
        })
      );
      return true;
    }
    return false;
  };

  const onSubmit = async () => {
    const params = {};

    if (verficarCamposVideos()) return;
    if (verficarCampoArquivo()) return;

    if (id) {
      params.Id = id;
      params.DataReferencia = DataReferencia;
      params.Ativo = ativo;
    }

    params.tipoRelatorioId = TipoRelatorioId;
    params.clienteId = ClienteId;
    params.unidadeConsumidoraId = UnidadeId;
    params.arquivo = arquivos;

    dispatch(handleLoading(true));

    let retorno;

    setProgress(50);

    if (id) {
      retorno = await Helper.Atualiza(params);
    } else {
      retorno = await Helper.Upload(params);
    }

    if (retorno.sucesso) {
      await enviarVideos(retorno?.data);
    }

    dispatch(handleLoading(false));

    if (!retorno.sucesso) {
      setProgress(0);
      store.dispatch(
        alertaExibir({
          tipo: "danger",
          mensagem: retorno.mensagem
        })
      );
      return;
    }

    setProgress(100);
    store.dispatch(
      alertaExibir({
        tipo: "success",
        mensagem: retorno.mensagem
      })
    );

    history.push(RotasDTO.RelatoriosEnvios);
  };

  const obterListaVideos = async () => {
    const { mensagem } = await Helper.ObterVideos({ id: idUrl });

    if (mensagem?.lista?.length) {
      const videosSalvos = mensagem.lista
        .sort((a, b) => a.ordem - b.ordem)
        .map((item, index) => ({
          ...item,
          nome: item.nome,
          descricao: item.descricao,
          ordem: item.ordem || index,
          arquivo: new File([], item.identificador + item.extensao, {
            type: item.extensao
          })
        }));
      setVideos(videosSalvos);
    }
    setRenderizaVideos(true);
  };

  const ObterListaTipos = async () => {
    dispatch(handleLoading(true));
    const response = await Helper.ListarTipos();

    setListaTipos(response.data.lista);
    dispatch(handleLoading(false));
  };

  const ObterListaClientes = async () => {
    dispatch(handleLoading(true));

    const { sucesso, data, mensagem } = await Helper.ListarClientes({
      empresaSelecionada
    });

    if (!sucesso) {
      Helper.exibirErro(mensagem);
      dispatch(handleLoading(false));
      return;
    }

    setListaClientes(data.clientes);
    dispatch(handleLoading(false));
  };

  const ObterListaUnidades = async (clienteId) => {
    dispatch(handleLoading(true));

    const {
      sucesso,
      data,
      mensagem
    } = await Helper.ListarUnidadesConsumidorasCombo({
      clienteId
    });

    if (!sucesso) {
      Helper.exibirErro(mensagem);
      dispatch(handleLoading(false));
      return;
    }

    setListaUnidades(data.unidadeConsumidoraResponseList);
    dispatch(handleLoading(false));
  };

  const ServeParaCliente = async (tipoRelatorioId) => {
    if (listaTipos) {
      const tipoRelatorio = listaTipos.filter((item) => {
        return item.id === Number(tipoRelatorioId) ? item : null;
      });

      if (tipoRelatorioId) {
        setServeParaCliente(
          tipoRelatorio[0].categoriaRelatorio.serveParaCliente
        );
      }
    }
  };

  const onAddArquivoUpload = (lista) => {
    if (!serveParaCliente) {
      const arquivosFiltrados = lista.filter(
        (file) =>
          file.path !== arquivos[0]?.path && file.size !== arquivos[0]?.size
      );
      if (arquivosFiltrados?.length) {
        setArquivos([arquivosFiltrados[0]]);
        return;
      }
      setArquivos([lista[1]]);
      return;
    }

    setArquivos(lista);
  };

  const onDeleteArquivoUpload = (lista) => {
    setArquivos((oldState) =>
      oldState.filter((item) => item.name !== lista.name)
    );
  };

  const obterPorId = useCallback(async () => {
    if (!idUrl || idUrl <= 0) {
      setRenderizaVideos(true);
      return;
    }

    dispatch(handleLoading(true));

    const retorno = await Helper.ObterPorId({ id: idUrl });

    if (!retorno.sucesso) {
      store.dispatch(
        alertaExibir({
          tipo: "danger",
          mensagem: retorno.mensagem
        })
      );
      return;
    }

    const relatorio = retorno.data;

    if (!relatorio.tipoRelatorio.categoriaRelatorio.serveParaCliente) {
      await obterListaVideos(relatorio.id);
    }

    setId(relatorio.id);
    setClienteId(relatorio.clienteId);
    setUnidadeId(relatorio.unidadeConsumidoraId);
    setAtivo(relatorio.ativo);
    setDataReferencia(new Date(relatorio.dataReferencia));
    setTipoRelatorioId(relatorio.tipoRelatorioId);
    setNome(relatorio.nome);
    setArquivos([new File([], relatorio.nome, { type: relatorio.extensao })]);
    setServeParaCliente(
      relatorio.tipoRelatorio.categoriaRelatorio.serveParaCliente
    );

    setRenderizaVideos(true);

    if (relatorio.clienteId) ObterListaUnidades(relatorio.clienteId);

    dispatch(handleLoading(false));
  }, [idUrl]);

  useEffect(() => {
    ObterListaTipos();
    ObterListaClientes();
  }, []);

  useEffect(() => {
    obterPorId();
  }, [obterPorId]);

  return (
    <CustomLoader>
      <form
        className="needs-validation"
        onSubmit={handleSubmit(onSubmit)}
        enctype="multipart/form-data"
      >
        <Card>
          <Grid container p={2} spacing={3} className={classes.container}>
            <Grid item xs={6} className="font-weight-bold">
              {`${id ? "Editar" : "Enviar"} Relatório`}
            </Grid>
            <BotaoRetornarListagem urlListagem={RotasDTO.RelatoriosEnvios} />
            <Grid item lg={4} md={4} sm={12}>
              <SelectArredondado
                id="TipoRelatorioId"
                name="TipoRelatorioId"
                valueKey="id"
                marginBottom="0"
                valueName="nome"
                dataSource={listaTipos}
                label="Tipo de Relatório"
                defaultValue={TipoRelatorioId}
                value={TipoRelatorioId}
                onChange={(event) => {
                  setTipoRelatorioId(event.target.value);
                  setClienteId();
                  setUnidadeId();
                  ServeParaCliente(event.target.value);
                }}
                placeholder="Tipo de Relatório"
                allowClear
                ref={register(
                  {
                    name: "TipoRelatorioId"
                  },
                  {
                    required:
                      !TipoRelatorioId && "O Tipo de Relatórios é obrigátorio"
                  }
                )}
                errors={errors}
              />
            </Grid>
            <Grid item lg={4} md={4} sm={12} className={classes.contentCliente}>
              <SelectAutoComplete
                disabled={!serveParaCliente}
                id="ClienteId"
                name="ClienteId"
                options={listaClientes}
                label="Cliente"
                getOptionLabel={(option) => option.nomeFantasia}
                onChange={(event, item) => {
                  setClienteId(item?.id ?? 0);
                  ObterListaUnidades(item?.id ?? 0);
                }}
                value={ClienteId}
                ref={register(
                  {
                    name: "ClienteId"
                  },
                  {
                    required:
                      serveParaCliente &&
                      !ClienteId &&
                      "O Cliente é Obrigátorio"
                  }
                )}
                error={errors}
                allowClear
                valueName="nomeFantasia"
              />
            </Grid>
            <Grid item lg={4} md={4} sm={12} className={classes.contentCliente}>
              <SelectAutoComplete
                disabled={!serveParaCliente}
                id="UnidadeId"
                name="UnidadeId"
                options={listaUnidades}
                label="Unidade"
                getOptionLabel={(option) => option.nomeUnidade}
                onChange={(event, item) => {
                  setUnidadeId(item?.id ?? 0);
                }}
                value={UnidadeId}
                ref={register({
                  name: "UnidadeId"
                })}
                error={errors}
                allowClear
                valueName="nomeUnidade"
              />
            </Grid>
            {id && (
              <>
                <Grid item lg={4} md={4} sm={12}>
                  <InputTextoData
                    type="text"
                    id="DataReferencia"
                    name="DataReferencia"
                    maxDate={window.moment()}
                    customValue={
                      window.moment(DataReferencia).format() ||
                      window.moment().format()
                    }
                    onChange={(valor) => setDataReferencia(valor)}
                    renderIconShowHide={false}
                    ref={register(
                      {
                        required: "Campo Data é obrigatório!"
                      },
                      {
                        validate: (value) =>
                          (window.moment(value, "DD/MM/yyyy").isValid() &&
                            window.moment(value, "DD/MM/yyyy") <
                              window.moment()) ||
                          "Data da inválida!"
                      }
                    )}
                    className={classes}
                  />
                </Grid>
                <Grid item lg={3} md={3} sm={6} justifyContent="flex-start">
                  <Switch
                    ref={register()}
                    label="Ativo"
                    labelPlacement="top"
                    id="Ativo"
                    justify="flex-start"
                    name="Ativo"
                    checked={ativo}
                    onChange={(param) => setAtivo(param)}
                  />
                </Grid>
              </>
            )}
            <Grid item lg={12} md={12} sm={12}>
              <UploadDropAreaMultiplo
                imageOrPdfValidate={true}
                onAdd={onAddArquivoUpload}
                onDropRejected={onDeleteArquivoUpload}
                progressValue={progress}
                acceptedFiles={["application/pdf"]}
                dropAreaText="Solte os arquivos para anexá-los ou clique para procurar! Tamanho máximo 20MB."
                filesLimit={!serveParaCliente ? 1 : 10}
                renderizaComponente={renderizaVideos}
                initialFiles={arquivos}
                disabled={id >= 0}
                showPreviews
              />
            </Grid>

            {!serveParaCliente && videos.length > 0 && renderizaVideos && (
              <UploadVideos
                videos={videos}
                videosPostar={videosSalvar}
                salvarListaVideos={(lista) => setVideos(lista)}
                salvarListaVideosPostar={(lista) => setVideosSalvar(lista)}
                camposInvalidos={camposInvalidos}
              />
            )}

            <Grid item lg={3} md={3} sm={6}>
              <Botao
                type="submit"
                label="Salvar"
                color={Base.Mango}
                className={classes.button}
              />
            </Grid>
          </Grid>
        </Card>
      </form>
    </CustomLoader>
  );
}

export default Cadastro;
