import PlanoConfiguracaoDto from "../Dtos/planoConfiguracaoDto";
import tipoPlanoEnum from "../../../../global/enumeradores/tipoPlanoEnum";
import theme from 'themes';

const PlanosConstante = [
  new PlanoConfiguracaoDto(
    tipoPlanoEnum.PlanoBronze.valor,
    theme.color.backgroundPlanoBronze,
    tipoPlanoEnum.PlanoBronze.titulo,
    'bronze',
    theme.color.planoColor,
    theme.color.opacityPlanoBronze,
    theme.color.fundoBronze
  ),
  new PlanoConfiguracaoDto(
    tipoPlanoEnum.PlanoPrata.valor,
    theme.color.backgroundPlanoPrata,
    tipoPlanoEnum.PlanoPrata.titulo,
    'prata',
    theme.color.planoColor,
    theme.color.opacityPlanoPrata,
    theme.color.fundoPrata
  ),
  new PlanoConfiguracaoDto(
    tipoPlanoEnum.PlanoOuro.valor,
    theme.color.backgroundPlanoOuro,
    tipoPlanoEnum.PlanoOuro.titulo,
    'ouro',
    theme.color.planoColor,
    theme.color.opacityPlanoOuro,
    theme.color.fundoOuro
  ),
  new PlanoConfiguracaoDto(
    tipoPlanoEnum.PlanoBronzeLivre.valor,
    theme.color.backgroundPlanoBronze,
    tipoPlanoEnum.PlanoBronzeLivre.titulo,
    'bronze',
    theme.color.planoColor,
    theme.color.opacityPlanoBronze,
    theme.color.fundoBronze
  ),
  new PlanoConfiguracaoDto(
    tipoPlanoEnum.PlanoPrataLivre.valor,
    theme.color.backgroundPlanoPrata,
    tipoPlanoEnum.PlanoPrataLivre.titulo,
    'prata',
    theme.color.planoColor,
    theme.color.opacityPlanoPrata,
    theme.color.fundoPrata
  ),
  new PlanoConfiguracaoDto(
    tipoPlanoEnum.PlanoOuroLivre.valor,
    theme.color.backgroundPlanoOuro,
    tipoPlanoEnum.PlanoOuroLivre.titulo,
    'ouro',
    theme.color.planoColor,
    theme.color.opacityPlanoOuro,
    theme.color.fundoOuro
  )
];

export default PlanosConstante;
