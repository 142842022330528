import { makeStyles } from '@mui/styles';
import { Base } from "componentes/cores";
import theme from 'themes';

export const useStyles = makeStyles(() => ({
  containerContent: {
    padding: "30px 10px 30px 0px",
    maxHeight: "300px",
    overflowX: "auto",
    fontFamily: theme.font.family,
    fontSize: "18px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "-0.4px",
    textAlign: "center",
    color: Base.Preto
  },
  content: {
    padding: "0px 40px"
  },
  conteudo: {
    padding: "10px 0px"
  },
  addRepresentante: {
    "& .MuiSvgIcon-root, button": {
      color: Base.Black
    }
  },
  espacoEntreRepresentantes: {
    marginBottom: "30px"
  },
  divider: {
    margin: "20px 0px",
    color: theme.color.primaryBackgroud,
    height: "2px"
  },
  cardUpload: {
    //border: `1px solid ${theme.color.primaryBackgroud}`,
    padding: "16px",
    "-webkit-backdrop-filter": "blur(2px)",
    backdropFilter: "blur(2px)",
    color: Base.Preto
    
  },
  cardUploadDireita: {
    marginLeft: "12px"
  },
  cardUploadEsquerda: {
    marginRight: "12px"
  },
  tituloCard: {
    fontFamily: theme.font.family,
    fontSize: "18px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "-0.4px",
    color: theme.color.primaryBackgroud
  }
}));
