import MICROSERVICO from "../global/enumeradores/microServico";
import obterApi from "./api";

const api = () => obterApi(MICROSERVICO.DELTASDK);

export async function enviaLog(logLevel, message, logElastic, index, date) {
    return api().post(`v1/log/envialog?logLevel=${logLevel}&message=${message}&index=${index}&date=${date}`,{
        ...logElastic
    });
};

export async function atualizaLog(queryIndexId, doc, index, nestedField) {
    let metodo = "post";
    let url =  `v1/log/atualizalog`;
    let body = {
        queryIndexId : queryIndexId,
        doc : doc,
        index : index,
        nestedField : nestedField
    }
    return api()[metodo](url, body);
}