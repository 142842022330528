import React from "react";
import PropTypes from "prop-types";
import { generate } from "shortid";
import { Grid, Button } from "@mui/material";
import { saveAs } from "file-saver";
import { GetApp } from "@mui/icons-material";
import { useStyles } from "./style";
import { HtmlTooltip } from "../../../Graficos/ConsumoAtual/style";
import FaturasGDHelper from "../helper";
import { useSelector } from "react-redux";

const ColunaRelatorio = ({ parametros }) => {

  const global = useSelector((state) => state.usuario);
  const { unidadeSelecionada, unidades } = global;
  const nomeUnidade = unidades?.find(x => x.id === unidadeSelecionada)?.nomeUnidade?.replace(/\s/g, '-');
  const classes = useStyles();
 
  const onClickGerarPDF = async (params) => {
    const {
      sucesso,
      mensagem
    } = await FaturasGDHelper.downloadFatura(params, nomeUnidade);

    if (!sucesso) return FaturasGDHelper.exibirErro(mensagem);
  };

  return (
    <Grid container spacing={0} className={classes.fundoCelula}>
      <Grid item xs={12}>
        <Button
          key={generate()}
          onClick={() => onClickGerarPDF(parametros)}
          className={`text-white ${classes.botao}`}
        >
          <HtmlTooltip title="Baixar">
            <GetApp />
          </HtmlTooltip>
        </Button>
      </Grid>
    </Grid>
  );
};

ColunaRelatorio.propTypes = {
  parametros: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
    .isRequired
};

export default ColunaRelatorio;
