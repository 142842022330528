import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";

// Componentes
import { Grid, Card } from "@mui/material";
import {
  unidadeConsumidoraGD,
  unidadeConsumidoraGDColaborador
} from "servicos/unidadeConsumidoraGDServico";
import { getDadosFaturas } from "servicos/dgIntegracaoServico";
import { obterPermissaoPorCliente } from "servicos/memberGetMemberServico";
import { obterProtocoloDemandaGDPorFiltroLateral } from "servicos/demandaAtendServico";
import { obterPorIdUnidade } from "servicos/homeServico";
import { getStatusMedidor, verificarMac } from "servicos/temetriaGDServico";
import { store } from "global/redux";
import { alertaExibir } from "global/redux/modulos/alertas/actions";
import Categorias from "../Categorias";
import ConsumoEconomia from "../ConsumoEconomia";
import SuaFatura from "../SuaFatura";
import MeusPlanos from "../MeusPlanos";
import Medidor from "../Medidor";
import IndiqueGanhe from "../IndiqueGanhe";
import FluxoMigracao from "../FluxoMigracao";

// Styles
import { useStyles } from "./style";

const HomeCliente = () => {
  const classes = useStyles();
  const usuarioLogado = useSelector((state) => state.usuario);
  const {
    empresaSelecionada,
    clienteSelecionado,
    unidadeSelecionada,
    usuario
  } = usuarioLogado;

  const { tipo } = usuario;
  const [carregandoUnidade, setCarregandoUnidade] = useState(false);
  const [unidade, setUnidade] = useState(null);
  const [carregandoFatura, setCarregandoFatura] = useState(false);
  const [carregandoMedidor, setCarregandoMedidor] = useState(false);
  const [dadosFatura, setDadosFatura] = useState([]);
  const [dadosMedidores, setDadosMedidores] = useState([]);
  const [statusMedidor, setStatusMedidor] = useState(false);
  const [permissaoIndique, setPermissaoIndique] = useState(false);
  const [passoOnboardingId, setPassoOnboardingId] = useState(null);
  const [dadosCarregados, setDadosCarregados] = useState(false);
  const [statusMac, setStatusMac] = useState(false);

  const handleUnidadeConsumidora = async (unidadeId) => {
    setCarregandoUnidade(true);
    try {
      const { data, status } =
        tipo === "Cliente"
          ? await unidadeConsumidoraGD()
          : await unidadeConsumidoraGDColaborador(unidadeId);
      if (data && status === 200) {
        const unidadeFiltrada =
          data?.unidadeConsumidoraGDList?.filter(
            (item) => item.unidadeConsumidoraId === unidadeId
          ) || [];

        if (unidadeFiltrada?.length > 0) {
          setUnidade(unidadeFiltrada[0]);
          const dataInicial = moment()
            .subtract(2, "months")
            .endOf("month")
            .format("YYYY-MM-DD");
          const dataHoje = moment().format("YYYY-MM-DD");
          if (dataInicial) {
            await handleDadosFaturas(
              unidadeFiltrada[0]?.unidadeConsumidoraId,
              dataInicial,
              dataHoje
            );
            await handleMedidor(unidadeFiltrada[0]?.unidadeConsumidoraId);
          } else {
            setDadosFatura([]);
          }
        } else {
          setUnidade(null);
          setDadosFatura([]);
        }
      }
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ||
            "Erro interno, entre em contato com o suporte!"
        })
      );
      setUnidade(null);
      setDadosFatura([]);
    } finally {
      setCarregandoUnidade(false);
      setDadosCarregados(true);
    }
  };

  const handleDadosFaturas = async (unidadeId, dataInicial, dataHoje) => {
    setCarregandoFatura(true);
    try {
      const { data, status } = await getDadosFaturas(
        unidadeId,
        dataInicial,
        dataHoje
      );
      if (data.length > 0 && status === 200) {
        setDadosFatura(data);
      } else {
        setDadosFatura([]);
      }
    } catch (error) {
      setDadosFatura([]);
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ||
            "Erro interno, entre em contato com o suporte!"
        })
      );
    } finally {
      setCarregandoFatura(false);
    }
  };

  const handleMedidor = async (unidadeId) => {
    setCarregandoMedidor(true);
    try {
      const { data, status } = await obterPorIdUnidade(unidadeId);
      if (data.length > 0 && status === 200) {
        setDadosMedidores(data);
      } else {
        setDadosMedidores([]);
      }
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ||
            "Erro interno, entre em contato com o suporte!"
        })
      );
    } finally {
      setCarregandoMedidor(false);
    }
  };

  const handleStatusMedidor = async () => {
    setCarregandoMedidor(true);
    try {
      if (dadosMedidores[0]?.codigoMedidor) {
        const statusResposta = await getStatusMedidor(
          dadosMedidores[0]?.codigoMedidor
        );

        if (statusResposta?.data?.connected === true) {
          setStatusMedidor(true);
        } else {
          setStatusMedidor(false);
        }
      }
    } catch (error) {
      setStatusMedidor(false);
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ||
            "Erro interno, entre em contato com o suporte!"
        })
      );
    } finally {
      setCarregandoMedidor(false);
    }
  };

  const handleVerificarMac = async () => {
    setCarregandoMedidor(true);
    try {
      if (dadosMedidores[0]?.codigoMedidor) {
        const statusResposta = await verificarMac(
          dadosMedidores[0]?.codigoMedidor
        );

        if (statusResposta?.data) {
          setStatusMac(true);
        } else {
          setStatusMac(false);
        }
      }
    } catch (error) {
      setStatusMac(false);
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ||
            "Erro interno, entre em contato com o suporte!"
        })
      );
    } finally {
      setCarregandoMedidor(false);
    }
  };

  const handlePermissaoIndique = async (cliente) => {
    try {
      const { data } = await obterPermissaoPorCliente(cliente);
      if (data?.aceite === "S") setPermissaoIndique(true);
      else setPermissaoIndique(false);
    } catch (error) {
      setPermissaoIndique(false);
    }
  };

  const obterDemandaPorFiltroLateral = async () => {
    try {
      const { data } = await obterProtocoloDemandaGDPorFiltroLateral(
        unidadeSelecionada
      );

      setPassoOnboardingId(data?.operacaoPassoOnboardingId || null);
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ||
            "Erro interno, entre em contato com o suporte!"
        })
      );
    }
  };

  useEffect(() => {
    if (unidadeSelecionada) {
      setDadosFatura([]);
      handleUnidadeConsumidora(unidadeSelecionada);
      obterDemandaPorFiltroLateral();
    } else {
      setUnidade(null);
      setDadosFatura([]);
      setDadosCarregados(true);
    }
    if (clienteSelecionado) {
      handlePermissaoIndique(clienteSelecionado);
    }
  }, [empresaSelecionada, clienteSelecionado, unidadeSelecionada]);

  useEffect(() => {
    if (dadosMedidores?.length) {
      handleStatusMedidor();
      handleVerificarMac();
    } else {
      setStatusMac(false);
      setStatusMedidor(false);
    }
  }, [dadosMedidores]);

  return (
    <Grid container>
      {dadosCarregados &&
        unidade?.medidores?.length > 0 &&
        passoOnboardingId === 13 && (
          <Grid xs={12} sm={6} lg={4} item sx={{ px: { sm: 1 }, py: 2 }}>
            <Card className={classes.card}>
              <ConsumoEconomia
                unidadeConsumidora={unidade}
                dadosFatura={dadosFatura}
                carregandoUnidade={carregandoUnidade}
              />
            </Card>
          </Grid>
        )}
      {dadosCarregados &&
        unidade?.medidores?.length > 0 &&
        passoOnboardingId === 13 && (
          <Grid xs={12} sm={6} lg={4} item sx={{ px: { sm: 1 }, py: 2 }}>
            <Card className={classes.card}>
              <Medidor
                dadosFatura={dadosFatura}
                medidores={dadosMedidores}
                status={statusMedidor}
                carregandoMedidor={carregandoMedidor}
                unidadeConsumidora={unidade}
              />
            </Card>
          </Grid>
        )}
      {dadosCarregados &&
        unidade?.medidores?.length > 0 &&
        passoOnboardingId === 13 &&
        statusMac && (
          <Grid xs={12} sm={6} lg={4} item sx={{ px: { sm: 1 }, py: 2 }}>
            <Card className={classes.card}>
              <Categorias unidadeConsumidora={unidade} />
            </Card>
          </Grid>
        )}
      {dadosCarregados && passoOnboardingId === 13 && (
        <Grid xs={12} sm={6} lg={4} item sx={{ px: { sm: 1 }, py: 2 }}>
          <Card className={classes.card}>
            <SuaFatura
              carregandoFatura={carregandoFatura}
              dadosFatura={dadosFatura}
            />
          </Card>
        </Grid>
      )}
      {dadosCarregados && (
        <Grid
          xs={12}
          sm={6}
          lg={passoOnboardingId === 13 ? 4 : 6}
          item
          sx={{ px: { sm: 1 }, py: 2 }}
        >
          <Card className={classes.card}>
            <MeusPlanos unidadeConsumidora={unidade} />
          </Card>
        </Grid>
      )}
      {dadosCarregados && passoOnboardingId !== 13 && (
        <Grid xs={12} sm={6} lg={6} item sx={{ px: { sm: 1 }, py: 2 }}>
          <Card className={classes.card}>
            <FluxoMigracao passo={passoOnboardingId} />
          </Card>
        </Grid>
      )}
      {dadosCarregados && passoOnboardingId === 13 && (
        <Grid xs={12} sm={6} lg={4} item sx={{ px: { sm: 1 }, py: 2 }}>
          <Card className={classes.card}>
            <IndiqueGanhe
              cliente={clienteSelecionado}
              permissao={permissaoIndique}
            />
          </Card>
        </Grid>
      )}
    </Grid>
  );
};

export default HomeCliente;
