import React, { useCallback, useState, useEffect, useMemo } from "react";

// ApexChart
import ReactApexChart from "react-apexcharts";

// Redux
import { useSelector } from "react-redux";

// Componentes
import { Grid } from "@mui/material";
import InputData from "../../../componentes/inputTextoData";
import Loader from "../../../componentes/loader";
import { Base } from "../../../componentes/cores";
import _theme from "themes";

// Styles
import { Container } from "../Dashboard/style";
import { useStyles } from "./style";

// Serviços
import { consumoMensal } from "../../../servicos/graficosServico";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import moment from "moment";
import themes from "themes";

const ConsumoMensal = (props) => {
  const classes = useStyles();
  const usuario = useSelector((state) => state.usuario);
  const { unidadeSelecionada } = usuario;
  const [mesInicio, setMesInicio] = useState();
  const [mesFim, setMesFim] = useState();
  const [dadosContratoMax, setDadosContratoMax] = useState([]);
  const [dadosContrato, setDadosContrato] = useState([]);
  const [dadosContratoMin, setDadosContratoMin] = useState([]);
  const [dadosConsumoMes, setDadosConsumoMes] = useState([]);
  const [labelsMes, setLabelsMes] = useState([]);
  const [grafico, setGrafico] = useState(<div />);
  const [checarDados, setChecarDados] = useState();
  const [pdf, setPDF] = React.useState(props);
  const [dadosJan, setDadosJan] = useState([]);
  const [dadosFev, setDadosFev] = useState([]);
  const [dadosMar, setDadosMar] = useState([]);
  const [dadosAbr, setDadosAbr] = useState([]);
  const [dadosMai, setDadosMai] = useState([]);
  const [dadosJun, setDadosJun] = useState([]);
  const [dadosJul, setDadosJul] = useState([]);
  const [dadosAgo, setDadosAgo] = useState([]);
  const [dadosSet, setDadosSet] = useState([]);
  const [dadosOut, setDadosOut] = useState([]);
  const [dadosNov, setDadosNov] = useState([]);
  const [dadosDez, setDadosDez] = useState([]);

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("lg"));

  useEffect(() => {
    setPDF(props);
    if (!mobile) {
      setMesInicio(window.moment().startOf("year"));
    } else {
      setMesInicio(window.moment().subtract(3, "month"));
    }
  }, [mobile]);

  const dados = useMemo(
    () => ({
      series: [
        {
          color: themes.color.serieMensalUm,
          data: dadosConsumoMes,
          name: "Consumo + Perdas - Proinfa",
          type: "column"
        },
        {
          color: themes.color.serieMensalDois,
          data: dadosContrato,
          name: "Volume Contratado",
          type: "line"
        },
        {
          color: themes.color.serieMensalTres,
          data: dadosContratoMax,
          name: "Volume Máx",
          type: "line"
        },
        {
          color: themes.color.serieMensalQuatro,
          data: dadosContratoMin,
          name: "Volume Mín",
          type: "line"
        }
      ],
      options: {
        plotOptions: {
          bar: {
            columnWidth: "60%"
          }
        },
        chart: {
          fontFamily: themes.font.family,
          toolbar: {
            show: false
          },
          type: "line",
          zoom: {
            enabled: false
          },
          background: pdf.status ? "white" : themes.color.cardColorGraficos,
          width: "100%"
        },
        dataLabels: {
          background: {
            enabled: false
          },
          dropShadow: {
            enabled: true,
            blur: 0,
            opacity: 0.2
          },
          offsetY: -5,
          style: {
            color: Base.OrangeEcstasy,
            fontSize: "16px"
          },
          textAnchor: "start",
          width: "100%"
        },
        legend: {
          show: false
        },
        stroke: {
          curve: "straight",
          width: 3
        },
        fill: {
          type: ["solid"],
          gradient: {
            type: "horizontal",
            shadeIntensity: 0,
            inverseColors: false,
            opacityFrom: 0.8,
            opacityTo: 0.8,
            stops: [20, 100, 100, 100]
          }
        },
        labels: labelsMes,
        markers: {
          size: 0
        },
        grid: {
          borderColor: Base.GrayShuttle,
          width: "100%",
          xaxis: {
            lines: {
              show: false
            },
            labels: {
              show: false
            }
          },
          yaxis: {
            lines: {
              show: true
            },
            labels: {
              show: false
            }
          }
        },
        xaxis: {
          categories: pdf.status
            ? [
                ["Jan", dadosJan[0], dadosJan[1], dadosJan[2], dadosJan[3]],
                ["Fev", dadosFev[0], dadosFev[1], dadosFev[2], dadosFev[3]],
                ["Mar", dadosMar[0], dadosMar[1], dadosMar[2], dadosMar[3]],
                ["Abr", dadosAbr[0], dadosAbr[1], dadosAbr[2], dadosAbr[3]],
                ["Mai", dadosMai[0], dadosMai[1], dadosMai[2], dadosMai[3]],
                ["Jun", dadosJun[0], dadosJun[1], dadosJun[2], dadosJun[3]],
                ["Jul", dadosJul[0], dadosJul[1], dadosJul[2], dadosJul[3]],
                ["Ago", dadosAgo[0], dadosAgo[1], dadosAgo[2], dadosAgo[3]],
                ["Set", dadosSet[0], dadosSet[1], dadosSet[2], dadosSet[3]],
                ["Out", dadosOut[0], dadosOut[1], dadosOut[2], dadosOut[3]],
                ["Nov", dadosNov[0], dadosNov[1], dadosNov[2], dadosNov[3]],
                ["Dez", dadosDez[0], dadosDez[1], dadosDez[2], dadosDez[3]]
              ]
            : "",
          width: "100%",
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          crosshairs: {
            show: false
          },
          labels: {
            minHeight: 100,
            maxHeight: 180,
            show: true,
            rotateAlways: mobile,
            style: {
              fontSize: pdf.status ? "14px" : "12px",
              lineHeight: 20,
              colors: pdf.status ? "black" : themes.color.labelColorGrafico
            }
          },
          position: pdf.status ? "bottom" : "top",
          tooltip: {
            enabled: false
          }
        },
        yaxis: {
          min: 0,
          max: (value) => value + 100,
          forceNiceScale: true,
          // tickAmount: 4,
          width: "100%",
          labels: {
            show: true,
            style: {
              fontSize: "12px",
              colors: pdf.status ? "black" : themes.color.labelColorGrafico
            },
            formatter(value) {
              return `${value} MWh`;
            }
          }
        },
        tooltip: {
          theme: "dark",
          y: [
            {
              title: {
                formatter(val) {
                  return val;
                }
              }
            },
            {
              title: {
                formatter(val) {
                  return val;
                }
              }
            },
            {
              title: {
                formatter(val) {
                  return val;
                }
              }
            }
          ]
        },
        noData: {
          text: "",
          style: {
            color: `${Base.OffWhite} !important`,
            fontSize: "18px",
            maxWidth: "80%"
          }
        }
      }
    }),
    [
      dadosContratoMax,
      dadosContrato,
      dadosContratoMin,
      dadosConsumoMes,
      labelsMes
    ]
  );
  const RenderizarGrafico = useCallback(() => {
    const graficoRender = (
      <ReactApexChart
        options={dados?.options}
        series={dados?.series}
        type="line"
        height={350}
        width={pdf.status ? "108%" : "100%"}
      />
    );

    setGrafico(graficoRender);
  }, [
    setGrafico,
    dadosContratoMax,
    dadosContrato,
    dadosContratoMin,
    dadosConsumoMes,
    labelsMes
  ]);

  useEffect(() => RenderizarGrafico(), [RenderizarGrafico]);

  const [carregandoDados, setCarregandoDados] = useState(false);
  const obterDados = useCallback(async () => {
    try {
      setCarregandoDados(true);
      if (mesInicio && mesFim && unidadeSelecionada) {
        const retorno = await consumoMensal(
          window.moment(mesInicio).format("YYYY-MM"),
          window.moment(mesFim).format("YYYY-MM"),
          unidadeSelecionada
        );
        if (retorno?.data) {
          setDadosContratoMax(retorno?.data?.resultados?.contratoMax ?? []);
          setDadosContrato(retorno?.data?.resultados?.contrato ?? []);
          setDadosContratoMin(retorno?.data?.resultados?.contratoMin ?? []);
          setDadosConsumoMes(retorno?.data?.resultados?.consumoMes ?? []);
          setLabelsMes(retorno?.data?.labelsMes ?? []);
        }
      }
      setCarregandoDados(false);
    } catch (error) {
      setCarregandoDados(false);
    }
  }, [mesFim, unidadeSelecionada]);

  useEffect(() => {
    obterDados();
  }, [obterDados]);

  useEffect(() => {
    if (mesInicio) {
      if (!mobile) {
        setMesFim(window.moment(mesInicio).add(11, "month"));
      } else {
        setMesFim(window.moment(mesInicio).add(3, "month"));
      }
    }
  }, [mesInicio, mobile]);

  const formatData = (dado) => {
    const value = dado?.toLocaleString("pt-br", {
      style: "decimal",
      maximumFractionDigits: 0,
      minimumFractionDigits: 0
    });
    const parts = value.split(",");
    if (String(dado).length > 7) {
      return parts[0];
    } else {
      return value;
    }
  };

  useEffect(() => {
    const valorInicial = 0;
    const soma = dadosConsumoMes.reduce((acumulador, valorAtual) => {
      return acumulador + valorAtual;
    }, valorInicial);
    setChecarDados(soma);

    var Jan = [];
    Jan.push(formatData(dados?.series[0]?.data[0] ?? 0));
    Jan.push(formatData(dados?.series[1]?.data[0] ?? 0));
    Jan.push(formatData(dados?.series[2]?.data[0] ?? 0));
    Jan.push(formatData(dados?.series[3]?.data[0] ?? 0));
    setDadosJan(Jan);

    var Fev = [];
    Fev.push(formatData(dados?.series[0]?.data[1] ?? 0));
    Fev.push(formatData(dados?.series[1]?.data[1] ?? 0));
    Fev.push(formatData(dados?.series[2]?.data[1] ?? 0));
    Fev.push(formatData(dados?.series[3]?.data[1] ?? 0));
    setDadosFev(Fev);

    var Mar = [];
    Mar.push(formatData(dados?.series[0]?.data[2] ?? 0));
    Mar.push(formatData(dados?.series[1]?.data[2] ?? 0));
    Mar.push(formatData(dados?.series[2]?.data[2] ?? 0));
    Mar.push(formatData(dados?.series[3]?.data[2] ?? 0));
    setDadosMar(Mar);

    var Abr = [];
    Abr.push(formatData(dados?.series[0]?.data[3] ?? 0));
    Abr.push(formatData(dados?.series[1]?.data[3] ?? 0));
    Abr.push(formatData(dados?.series[2]?.data[3] ?? 0));
    Abr.push(formatData(dados?.series[3]?.data[3] ?? 0));
    setDadosAbr(Abr);

    var Mai = [];
    Mai.push(formatData(dados?.series[0]?.data[4] ?? 0));
    Mai.push(formatData(dados?.series[1]?.data[4] ?? 0));
    Mai.push(formatData(dados?.series[2]?.data[4] ?? 0));
    Mai.push(formatData(dados?.series[3]?.data[4] ?? 0));
    setDadosMai(Mai);

    var Jun = [];
    Jun.push(formatData(dados?.series[0]?.data[5] ?? 0));
    Jun.push(formatData(dados?.series[1]?.data[5] ?? 0));
    Jun.push(formatData(dados?.series[2]?.data[5] ?? 0));
    Jun.push(formatData(dados?.series[3]?.data[5] ?? 0));
    setDadosJun(Jun);

    var Jul = [];
    Jul.push(formatData(dados?.series[0]?.data[6] ?? 0));
    Jul.push(formatData(dados?.series[1]?.data[6] ?? 0));
    Jul.push(formatData(dados?.series[2]?.data[6] ?? 0));
    Jul.push(formatData(dados?.series[3]?.data[6] ?? 0));
    setDadosJul(Jul);

    var Ago = [];
    Ago.push(formatData(dados?.series[0]?.data[7] ?? 0));
    Ago.push(formatData(dados?.series[1]?.data[7] ?? 0));
    Ago.push(formatData(dados?.series[2]?.data[7] ?? 0));
    Ago.push(formatData(dados?.series[3]?.data[7] ?? 0));
    setDadosAgo(Ago);

    var Setembro = [];
    Setembro.push(formatData(dados?.series[0]?.data[8] ?? 0));
    Setembro.push(formatData(dados?.series[1]?.data[8] ?? 0));
    Setembro.push(formatData(dados?.series[2]?.data[8] ?? 0));
    Setembro.push(formatData(dados?.series[3]?.data[8] ?? 0));
    setDadosSet(Setembro);

    var Out = [];
    Out.push(formatData(dados?.series[0]?.data[9] ?? 0));
    Out.push(formatData(dados?.series[1]?.data[9] ?? 0));
    Out.push(formatData(dados?.series[2]?.data[9] ?? 0));
    Out.push(formatData(dados?.series[3]?.data[9] ?? 0));
    setDadosOut(Out);

    var Nov = [];
    Nov.push(formatData(dados?.series[0]?.data[10] ?? 0));
    Nov.push(formatData(dados?.series[1]?.data[10] ?? 0));
    Nov.push(formatData(dados?.series[2]?.data[10] ?? 0));
    Nov.push(formatData(dados?.series[3]?.data[10] ?? 0));
    setDadosNov(Nov);

    var Dez = [];
    Dez.push(formatData(dados?.series[0]?.data[11] ?? 0));
    Dez.push(formatData(dados?.series[1]?.data[11] ?? 0));
    Dez.push(formatData(dados?.series[2]?.data[11] ?? 0));
    Dez.push(formatData(dados?.series[3]?.data[11] ?? 0));
    setDadosDez(Dez);
  }, [dadosConsumoMes]);

  return (
    <div>
      <Container
        className={classes.containerGrafico}
        style={{ backgroundColor: pdf.status === true ? "white" : "" }}
      >
        <Grid
          container
          justifyContent="space-between"
          className="m-0 w-100"
          spacing={2}
        >
          <Grid
            item
            className={classes.titulo}
            style={{ color: pdf.status === true ? "black" : "" }}
          >
            <div style={{ fontSize: pdf.status === true ? "18px" : "" }}>
              Consumo mensal
            </div>
          </Grid>
          <Grid item>
            <Grid container spacing={2}>
              <Grid item>
                {!pdf.status ? (
                  <InputData
                    type="text"
                    id="mesInicio"
                    name="mesInicio"
                    minDate={window.moment().subtract(10, "year").toDate()}
                    maxDate={window.moment().endOf("month").toDate()}
                    customValue={mesInicio}
                    onChange={(valor) => setMesInicio(valor)}
                    renderIconShowHide={false}
                    className={classes}
                    views={["month", "year"]}
                    variant="inline"
                    autoOk={true}
                    format="MMM/yyyy"
                  />
                ) : (
                  <div style={{ color: "black" }}>
                    {moment(mesInicio).format("MMM/yyyy")}
                  </div>
                )}
              </Grid>
              <Grid item>
                {!pdf.status ? (
                  <InputData
                    type="text"
                    id="mesFim"
                    name="mesFim"
                    minDate={new Date(mesInicio)}
                    maxDate={
                      !mobile
                        ? window.moment(mesInicio).add(11, "month").toDate()
                        : window.moment(mesInicio).add(4, "month").toDate()
                    }
                    customValue={mesFim}
                    onChange={(valor) => setMesFim(valor)}
                    renderIconShowHide={false}
                    className={classes}
                    views={["month", "year"]}
                    variant="inline"
                    autoOk={true}
                    format="MMM/yyyy"
                  />
                ) : (
                  <div style={{ color: "black", marginLeft: -13 }}>
                    {" "}
                    - {moment(mesFim).format("MMM/yyyy")}
                  </div>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {pdf.status ? (
          <div class={classes.boxTotal}>
            {dados?.series.map((item, i) => {
              return (
                <Grid container>
                  <div
                    className={classes.boxLegendaMenor}
                    style={{ backgroundColor: dados?.series[i].color }}
                  ></div>
                </Grid>
              );
            })}
          </div>
        ) : (
          <></>
        )}

        <Grid container className="m-0 w-100" spacing={4}>
          <Grid
            item
            xs={12}
            style={{ backgroundColor: pdf.status === true ? "white" : "" }}
          >
            <Loader loading={carregandoDados}>
              {dados?.options && dados?.series && checarDados > 0 ? (
                grafico
              ) : (
                <div className={classes.noData}>
                  <div>
                    Não foi possível carregar as informações de consumo, tente
                    novamente em algumas horas!
                  </div>
                </div>
              )}
            </Loader>
          </Grid>
        </Grid>
      </Container>
      <div className={classes.info}>*Consumo sujeito à alterações</div>
    </div>
  );
};
export default ConsumoMensal;
