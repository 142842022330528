import { createTheme, adaptV4Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Base } from "../../../../componentes/cores";
import theme from "../../../../themes";

export const useStyles = makeStyles(() => ({
  root: {
    "& label.Mui-focused": {
      color: `${Base.GreyDim}`
    }
  },
  container: {
    "& .MuiFormControl-marginNormal": {
      margin: "0",
      width: "100%"
    },
    "& .Mui-error fieldset": {
      borderColor: `${Base.Lava} !important`
    }
  }
}));

export const materialTheme = createTheme(
  adaptV4Theme({
    overrides: {
      MuiPickersToolbar: {
        toolbar: {
          backgroundColor: theme.color.primary
        }
      },
      MuiPickersDay: {
        daySelected: {
          backgroundColor: theme.color.primary
        },
        current: {
          color: theme.color.primary
        }
      }
    }
  })
);
