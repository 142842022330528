import { makeStyles } from '@mui/styles';
import { Base } from "../cores";
import { createTheme } from "@mui/material/styles";

const theme = createTheme();

export const useStyles = makeStyles(() => ({
  root: {
    "& label.Mui-focused": {
      color: `${Base.GreyDim}`
    },
    "& .MuiFormLabel-root": {
      [theme.breakpoints.down("md")]: {
        fontSize: ".9rem"
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: ".8rem"
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "1rem"
      }
    }
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200
  }
}));
