import { makeStyles } from '@mui/styles';
import { Base } from "componentes/cores";
import theme from "themes";

export const useStyles = makeStyles({
  inputFile: {
    display: "none"
  },
  customFile: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    flexDirection: "row-reverse",
    backgroundColor: "transparent",
    borderRadius: theme.borda.input,
    borderColor: Base.GreyDim,
    borderWidth: "2px",
    borderStyle: "dashed",
    cursor: "default",
    color: Base.Preto,
    height: "54px",
    paddingLeft: "10px",
    width: "100%",
    textAlign: "center",
    minHeight: "117px",
    marginBottom: "5px",
    cursor: "pointer"
  },
  textContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: Base.Black,
    fontSize: "18px"
  },
  icon: {
    padding: 0,
    "& svg": {
      color: Base.Black,
      marginRight: "20px",
      width: "51px",
      height: "51px",
      marginRight: "20px"
    },
    "& :hover": {
      backgroundColor: "transparent"
    }
  },
  text: {
    color: Base.Black,
    marginBottom: 0
  },
  chip: {
    margin: "4px",
    color: `${theme.color.secondaryBorderColor} !important`,
    fontSize: "14px",
    borderRadius: "5px",
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    "& span": {
      overflow: "hidden",
      textOverflow: "ellipsis",
      width: "170px"
    },
    "& .MuiChip-deleteIcon": {
      color: `${theme.color.secondaryBorderColor} !important`
    }
  },
  error: {
    borderColor: Base.RedError,
    marginBottom: "5px",
    "& p, & svg": {
      color: `${Base.RedError} !important`
    }
  }
});
