import styled from "styled-components";
import { adaptV4Theme } from "@mui/material";
import { makeStyles } from '@mui/styles';
import { createTheme } from "react-data-table-component";
import theme from 'themes';
import { Base } from "componentes/cores";

export const useStyles = makeStyles({
  container: {
    margin: "0",
    width: "100%",
    "& .MuiInputBase-root": {
      height: "46px"
    },
    "& .MuiFormLabel-root": {
      lineHeight: "1"
    }
  },
  cardCadastro: {
    backgroundColor: theme.color.bgColorCardCadastro
  },
  button: {
    fontSize: "18px"
  },
  removerItem:{
    color: theme.color.textoBarra
  }
});

export const customStyles = {
  Container: {
    alignItems: "center",
    background: theme.color.thirdBackgroud,
    color: theme.color.textoBarra,
    display: "flex",
    height: "300px",
    justifyContent: "center",
    marginBottom: "2em",
    marginTop: "0",
    opacity: "1"
  },
  table: {
    style: {
      color: theme.color.textoBarra,
      opacity: "1"
    }
  },
  headCells: {
    style: {
      background: theme.color.thirdBackgroud,
      color: theme.color.textoBarra,
      fontSize: "16px",
      marginLeft: "3px",
      "& div": {
        "&:hover": {
          color: theme.color.textoBarra,
          opacity: "1"
        }
      }
    }
  },
  rows: {
    style: {
      color: theme.color.textoBarra,
      marginBottom: "1px",
      marginTop: "1px",
      opacity: "1"
    }
  },
  Pagination: {
    styles: {
      color: `${theme.color.secondaryBorderColor} !important`
    }
  },
  cells: {
    style: {
      background: theme.color.thirdBackgroud,
      color: theme.color.textoBarra,
      fontSize: "14px",
      marginLeft: "3px",
      opacity: "1"
    }
  }
};

export const TabelaSemDados = styled.span`
  text-align: left;
  width: 100%;
`;

createTheme(adaptV4Theme("DeltaEnergia", {
  background: {
    default: "transparent"
  },
  text: {
    primary: theme.color.secondaryText,
    secondary: theme.color.secondaryText
  },
  action: {
    hover: theme.color.secondaryBorderColor
  }
}));
