import RetornoEndpointDto from "global/dto/retornoEndpointDto";
import { 
  ListaTipoRelatorioPorCliente, 
  AlterarParametrizacaoNotificacao 
} from "../../servicos/notificacaoServico";

export default class Helper {
  static async ListaTipoRelatorioPorCliente(filtro) {
    try {
      const resultado = await ListaTipoRelatorioPorCliente(filtro);

      return RetornoEndpointDto.Sucesso(
        "Lista obtidas com sucesso",
        resultado.data
      );
    } catch (error) {
      
      if (error.response.status == 403) {

        return RetornoEndpointDto.Erro(
          error.response.data.errors[0]
        );
      }

      return RetornoEndpointDto.Erro(
        "Ocorreu um erro ao obter a lista"
      );
    }
  }

  static async AlterarParametrizacaoNotificacao(params) {
    try {
      if (!params)
        return RetornoEndpointDto.Erro(
          "Erro ao tentar atualizar"
        );

      const { data } = await AlterarParametrizacaoNotificacao(params);

      return RetornoEndpointDto.Sucesso(
        "Atualizado com sucesso",
        data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro(
        error?.response?.data?.message ?? "Ocorreu uma falha ao salvar"
      );
    }
  }
}
