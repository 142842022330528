import React from "react";
import { RelatoriosDashboard } from "submodules/AreaLogada/paginas";
import { useSelector } from "react-redux";

const Home = () => {
  const usuario = useSelector((state) => state.usuario);

  return <RelatoriosDashboard />;
};

export default Home;
