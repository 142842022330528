import React from "react";
import { Box } from "@mui/material";
import { Edit, Check } from "@mui/icons-material";
import PropTypes from "prop-types";

const ColunaMultiplosBotoes = ({ parametros, onClick, className, enabled }) => {
  const onClickColuna = (disabled) => {
    onClick(disabled, parametros);
  };

  return (
    <Box mr="auto" ml="auto">
      {enabled ? (
        <Check cursor="pointer" onClick={() => onClickColuna(false)} />
      ) : (
        <Edit cursor="pointer" onClick={() => onClickColuna(true)} />
      )}
    </Box>
  );
};

ColunaMultiplosBotoes.propTypes = {
  parametros: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  onClick: PropTypes.oneOfType([PropTypes.func]),
  className: PropTypes.string,
  enabled: PropTypes.bool
};

ColunaMultiplosBotoes.defaultProps = {
  parametros: {},
  onClick: () => {},
  className: "",
  enabled: false
};

export default ColunaMultiplosBotoes;
