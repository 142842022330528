import React, { useState, useCallback, useEffect, useMemo } from "react";
import { useParams, useHistory } from "react-router-dom";

// Form Hooks
import { useForm } from "react-hook-form";

// Utils
import { cnpj } from "cpf-cnpj-validator";

// Redux
import { useSelector } from "react-redux";

// Componentes
import { Card, Divider, Grid } from "@mui/material";
import Loader from "../../../../componentes/loader";
import Botao from "../../../../componentes/botao";
import MaterialInputTexto from "../../../../componentes/inputTexto/materialInput";
import MaterialSwitch from "../../../../componentes/switch";
import BotaoRetornarListagem from "../../../../componentes/botaoRetornarListagem";
import MaterialInputMascara from "../../../../componentes/inputTextoMascara";
import ModalConfirmacao from "../../../../componentes/modalConfirmacao";
import SelectHierarquia from "../../../../componentes/selectHierarquia";
import SelectArredondado from "../../../../componentes/selectArredondado";
import InputTextoCEP from "../../../../componentes/inputTextoCEP";

// Serviços
import {
  buscarPorId,
  salvarEmpresaGrupoDelta,
  BuscarPorCnpjOuNomeFantasia,
  listarEmpresasGrupoDeltaCombo
} from "../../../../servicos/empresaServico";
import { listarArvoresGerenciais } from "../../../../servicos/arvoresGerenciaisServico";
import { buscaCep } from "../../../../servicos/correiosServico";

// Rotas
import { RotasDTO } from "../../../../global/rotas/rotasUrlDto";

// Styles
import { useStyles } from "./style";

// Redux
import { store } from "../../../../global/redux";
import { alertaExibir } from "../../../../global/redux/modulos/alertas/actions";
import { unidadesFederativas } from "../../../../global/constantes";
import {
  salvarEmpresas,
  selecionarCliente,
  selecionarEmpresa,
  selecionarUnidade
} from "../../../../global/redux/modulos/usuario/actions";
import MaterialInputTelefone from "../../../../componentes/inputTexto/materialInputTelefone";
import { buscarDadosCadastraisPorCnpj } from "../../../../servicos/dadosCadastraisCnpjServico";

const EmpresaGrupoDeltaCadastro = () => {
  const { id } = useParams();
  const classes = useStyles();
  const history = useHistory();
  const { register, errors, handleSubmit, setValue } = useForm({
    reValidateMode: "onSubmit"
  });

  const [
    carregandoEmpresaGrupoDelta,
    setCarregandoEmpresaGrupoDelta
  ] = useState();
  const [erroRazaoSocial, setErroRazaoSocial] = useState();
  const [dadosEmpresaGrupoDelta, setDadosEmpresaGrupoDelta] = useState();
  const [
    idEmpresaGrupoDeltaExistente,
    setidEmpresaGrupoDeltaExistente
  ] = useState(0);
  const [dadosEndereco, setDadosEndereco] = useState();
  const [numero, setNumero] = useState();
  const [complemento, setComplemento] = useState();

  const [carregandoCnpj, setCarregandoCnpj] = useState(false);
  const [razaoSocial, setRazaoSocial] = useState("");
  const [nomeFantasia, setNomeFantasia] = useState("");
  const [telefone, setTelefone] = useState("");

  const onBlurNomeFantasia = (nomeFantasia) => {
    let nomeFantasiaFormatado = nomeFantasia
      .replaceAll("'", "")
      .replaceAll('"', "");
    setNomeFantasia(nomeFantasiaFormatado);
  };

  // UF
  const [ufId, setUFId] = useState();
  const onChangeUF = (uf) => {
    setUFId(uf.target.value);
  };

  // CEP
  const [carregandoEndereco, setCarregandoEndereco] = useState(false);
  const carregarCep = useCallback(async (cep) => {
    try {
      setCarregandoEndereco(true);
      const endereco = await buscaCep(cep);

      if (endereco?.status === 200 && endereco?.data) {
        setUFId(endereco.data.uf);
        setDadosEndereco({
          endereco: endereco.data.logradouro,
          bairro: endereco.data.bairro,
          cidade: endereco.data.cidade
        });
        setNumero(" ");
        setComplemento(" ");
      }

      setCarregandoEndereco(false);
    } catch (error) {
      setUFId(null);
      setDadosEndereco({
        endereco: "",
        bairro: "",
        cidade: ""
      });
      setNumero("");
      setComplemento("");
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Não foi possível localizar o CEP!"
        })
      );
      setCarregandoEndereco(false);
    }
  }, []);

  const onBlurCep = (valorCep) => {
    if (valorCep.length > 7) {
      carregarCep(valorCep);
    }
  };

  useEffect(() => {
    if (numero === " ") setNumero("");
    if (complemento === " ") setComplemento("");
  }, [numero, complemento, telefone]);

  const [modalCnpjExistente, setModalCnpjExistente] = useState(false);
  const [modalRazaoSocialExistente, setModalRazaoSocialExistente] = useState(
    false
  );
  const onConfirmarTelaEdicaoCNPJ = () => {
    history.push(
      `${RotasDTO.EmpresaGrupoDelta}/cadastro/${idEmpresaGrupoDeltaExistente}`
    );
    setModalCnpjExistente(false);
  };

  const [limparCpf, setLimparCpf] = useState(false);

  const onCancelarTelaEdicaoCNPJ = () => {
    setDadosEmpresaGrupoDelta({
      ...(dadosEmpresaGrupoDelta && { dadosEmpresaGrupoDelta }),
      cnpj: " "
    });
    setLimparCpf(true);
    setModalCnpjExistente(false);
    setidEmpresaGrupoDeltaExistente(0);
  };

  const onConfirmarTelaEdicaoRazaoSocial = () => {
    history.push(
      `${RotasDTO.EmpresaGrupoDelta}/cadastro/${idEmpresaGrupoDeltaExistente}`
    );
    setModalRazaoSocialExistente(false);
  };

  const onCancelarTelaEdicaoRazaoSocial = () => {
    setDadosEmpresaGrupoDelta({
      ...(dadosEmpresaGrupoDelta && { dadosEmpresaGrupoDelta }),
      RazaoSocial: " "
    });
    setModalRazaoSocialExistente(false);
  };

  const verificaCnpj = (valor) => {
    verificarCnpjExistente(valor);
    buscarCnpj(valor);
  };

  const verificarCnpjExistente = useCallback(async (valor) => {
    const cnpjFormatado = valor?.target.value.replace(/[^a-zA-Z0-9]/g, "");
    const empresaGrupoDelta = await BuscarPorCnpjOuNomeFantasia(cnpjFormatado);
    if (idEmpresaGrupoDeltaExistente === 0 && cnpjFormatado.length === 14) {
      if (
        empresaGrupoDelta?.status === 200 &&
        empresaGrupoDelta?.data &&
        empresaGrupoDelta?.data.id
      ) {
        setModalCnpjExistente(true);
        setidEmpresaGrupoDeltaExistente(empresaGrupoDelta?.data?.id);
      }
    }
  }, []);

  const buscarCnpj = useCallback(async (valor) => {
    const cnpjFormatado = valor?.target.value.replace(/[^a-zA-Z0-9]/g, "");

    try {
      setCarregandoCnpj(true);
      if (idEmpresaGrupoDeltaExistente === 0 && cnpjFormatado.length === 14) {
        const dadosCadastrais = await buscarDadosCadastraisPorCnpj(
          cnpjFormatado
        );
        let telefone1 =
          dadosCadastrais?.data?.data?.telefone1 &&
          `55${dadosCadastrais?.data?.data?.telefone1}`;
        let telefone2 =
          dadosCadastrais?.data?.data?.telefone2 &&
          `55${dadosCadastrais?.data?.data?.telefone2}`;

        if (dadosCadastrais?.data?.success) {
          setRazaoSocial(dadosCadastrais?.data?.data?.razaoSocial);
          let nomeFantasiaFormatado = dadosCadastrais?.data?.data?.nomeFantasia
            .replaceAll("'", "")
            .replaceAll('"', "");
          setNomeFantasia(nomeFantasiaFormatado);
          setTelefone(telefone1 || telefone2);
        } else {
          store.dispatch(
            alertaExibir({
              tipo: "warning",
              mensagem: "CNPJ inválido!"
            })
          );
          setRazaoSocial("");
          setNomeFantasia("");
          setTelefone(" ");
        }
      }
      setCarregandoCnpj(false);
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem: "Erro interno, entre em contato com o suporte!"
        })
      );
      setCarregandoCnpj(false);
    }
  }, []);

  // Situacao
  const [situacao, setSituacao] = useState(true);
  const onChangeSituacao = (valor) => {
    setSituacao(valor);
  };

  // UF
  const [listaUF, setListaUF] = useState([]);
  const [carregandoUF, setCarregandoUF] = useState(false);

  const obterUF = async () => {
    try {
      setCarregandoUF(true);
      const lista = {
        data: unidadesFederativas,
        status: 200
      };
      if (lista?.status === 200 && lista?.data) {
        setListaUF(lista?.data);
      }
      setCarregandoUF(false);
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
      setCarregandoUF(false);
    }
  };

  useEffect(() => {
    obterUF();
  }, []);

  const usuario = useSelector((state) => state.usuario);

  // Arvore Gerencial
  const [arvoreGerencialId, setArvoreGerencialId] = useState();

  const onChangeArvoreGerencial = (arvoreGerencial) => {
    setArvoreGerencialId(arvoreGerencial);
  };

  const recarregarEmpresasFiltro = async () => {
    const lista = await listarEmpresasGrupoDeltaCombo(usuario?.token);
    if (lista?.status === 200 && lista?.data.empresasGrupoDelta) {
      store.dispatch(salvarEmpresas(lista?.data.empresasGrupoDelta ?? []));
    }
  };

  const enviarFormulario = async (dados) => {
    let hoje = new Date();
    const dd = String(hoje.getDate()).padStart(2, "0");
    const mm = String(hoje.getMonth() + 1).padStart(2, "0");
    const yyyy = hoje.getFullYear();
    hoje = `${yyyy}-${mm}-${dd}`;

    const cnpjFormatado = dados.cnpj?.replace(/[^a-zA-Z0-9]/g, "");
    const telefoneFormatado = dados.telefone?.replace(/[^a-zA-Z0-9]/g, "");

    try {
      const salvou = await salvarEmpresaGrupoDelta(id ?? 0, {
        ...dados,
        CpfCnpj: cnpjFormatado,
        UF: ufId,
        Telefone: telefoneFormatado,
        Situacao: id ? (situacao ? "AT" : "IN") : "AT",
        DataReferencial: hoje,
        ArvoreGerencialId: arvoreGerencialId
      });

      if (salvou?.status === 200 || salvou?.status === 204) {
        store.dispatch(
          alertaExibir({
            tipo: "success",
            mensagem: "Empresa salva com sucesso!"
          })
        );
        setTimeout(() => {
          recarregarEmpresasFiltro();
          history.push(RotasDTO.EmpresaGrupoDelta);
          store.dispatch(selecionarEmpresa(null));
          store.dispatch(selecionarCliente(null));
          store.dispatch(selecionarUnidade(null));
        }, 2000);
      }
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
    }
    setCarregandoEmpresaGrupoDelta(false);
  };

  const [, setCarregandoEmpresasGrupoDelta] = useState(false);

  const aoEnviarFormulario = (dados) => {
    if (!erroRazaoSocial) {
      setCarregandoEmpresasGrupoDelta(true);
      enviarFormulario(dados);
      setErroRazaoSocial(false);
    }
  };

  // Arvore Gerencial
  const [listaArvoresGerenciais, setListaArvoresGerenciais] = useState([]);
  const [
    carregandoArvoresGerenciais,
    setCarregandoArvoresGerenciais
  ] = useState(false);

  const obterArvoresGerenciais = async () => {
    try {
      setCarregandoArvoresGerenciais(true);
      const lista = await listarArvoresGerenciais("AT");
      if (lista?.status === 200 && lista?.data) {
        setListaArvoresGerenciais(lista?.data);
      }
      setCarregandoArvoresGerenciais(false);
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
      setCarregandoArvoresGerenciais(false);
    }
  };

  useEffect(() => {
    obterArvoresGerenciais();
  }, []);

  const obterEmpresaGrupoDelta = useCallback(
    async (idEmrpresaGrupoDelta) => {
      try {
        const empresaGrupoDelta = await buscarPorId(idEmrpresaGrupoDelta);
        if (empresaGrupoDelta?.status === 200 && empresaGrupoDelta?.data) {
          setDadosEmpresaGrupoDelta(empresaGrupoDelta?.data);
          setDadosEndereco({
            endereco: empresaGrupoDelta?.data?.endereco,
            bairro: empresaGrupoDelta?.data?.bairro,
            cidade: empresaGrupoDelta?.data?.cidade
          });
          setRazaoSocial(empresaGrupoDelta?.data?.razaoSocial);
          setNomeFantasia(empresaGrupoDelta?.data?.nomeFantasia);
          setTelefone(empresaGrupoDelta?.data?.telefone);
          setNumero(empresaGrupoDelta?.data?.numero);
          setComplemento(empresaGrupoDelta?.data?.complemento);
          setArvoreGerencialId(empresaGrupoDelta?.data?.arvoreGerencialId);
          setSituacao(
            String(empresaGrupoDelta?.data?.situacao).toLocaleUpperCase() ===
              "AT"
          );
          setValue("cep", empresaGrupoDelta?.data?.cep);
          setUFId(empresaGrupoDelta?.data?.uf);
        }
      } catch (error) {
        store.dispatch(
          alertaExibir({
            tipo: "warning",
            mensagem:
              error?.response?.data?.message ??
              "Erro interno, entre em contato com o suporte!"
          })
        );
      }
    },
    [setValue]
  );

  useEffect(() => {
    if (id) obterEmpresaGrupoDelta(id);
  }, [id, obterEmpresaGrupoDelta]);

  return (
    <>
      <ModalConfirmacao
        item={modalCnpjExistente}
        onConfirmar={() => onConfirmarTelaEdicaoCNPJ()}
        mensagem="CNPJ já cadastrado. Deseja ir para a tela de edição?"
        onCancelar={() => onCancelarTelaEdicaoCNPJ()}
      />
      <ModalConfirmacao
        item={modalRazaoSocialExistente}
        onConfirmar={() => onConfirmarTelaEdicaoRazaoSocial()}
        mensagem="Razão Social já cadastrada. Deseja ir para a tela de edição?"
        onCancelar={() => onCancelarTelaEdicaoRazaoSocial(false)}
      />
      <form
        className="needs-validation"
        onSubmit={handleSubmit(aoEnviarFormulario)}
      >
        <Card className={classes.cardCadastro}>
          <Grid container p={2} spacing={4} className={classes.container}>
            <Grid item xs={6} className="font-weight-bold">
              {dadosEmpresaGrupoDelta?.id ? "Editar empresa" : "Nova empresa"}
            </Grid>
            <BotaoRetornarListagem
              urlListagem={RotasDTO.EmpresaGrupoDelta}
              zerarCombo
            />
            <Grid item lg={3} md={3} sm={5}>
              <Loader loading={carregandoCnpj}>
                <MaterialInputMascara
                  type="text"
                  id="cnpj"
                  name="cnpj"
                  label="CNPJ"
                  mask="##.###.###/####-##"
                  renderIconShowHide={false}
                  onBlur={(valor) => verificaCnpj(valor)}
                  limparValor={limparCpf}
                  redefineLimpar={(estado) => setLimparCpf(estado)}
                  defaultValue={dadosEmpresaGrupoDelta?.cpfCnpj}
                  ref={register({
                    required: "Campo CNPJ é obrigatório!",
                    maxLength: {
                      value: 18,
                      message: "Quantidade máxima de 18 caracteres!"
                    },
                    minLength: {
                      value: 11,
                      message: "Quantidade mínima de 11 caracteres!"
                    },
                    validate: (value) => cnpj.isValid(value) || "CNPJ inválido!"
                  })}
                  errors={errors}
                />
              </Loader>
            </Grid>
            <Grid item lg={6} md={6} sm={8}>
              <Loader loading={carregandoCnpj}>
                <MaterialInputTexto
                  type="text"
                  id="RazaoSocial"
                  name="RazaoSocial"
                  label="Razão Social"
                  permiteValorBranco
                  renderIconShowHide={false}
                  defaultValue={razaoSocial}
                  ref={register({
                    required: "Campo Razão Social é obrigatório!"
                  })}
                  errors={errors}
                />
              </Loader>
            </Grid>
            <Grid item lg={3} md={3} sm={5}>
              <Loader loading={carregandoCnpj}>
                <MaterialInputTexto
                  type="text"
                  id="NomeFantasia"
                  name="NomeFantasia"
                  label="Nome Fantasia"
                  permiteValorBranco
                  renderIconShowHide={false}
                  defaultValue={nomeFantasia}
                  onBlur={(event) => onBlurNomeFantasia(event.target.value)}
                  onInput={(event) => onBlurNomeFantasia(event.target.value)}
                  ref={register({
                    required: "Campo Nome Fantasia é obrigatório!",
                    maxLength: {
                      value: 50,
                      message: "Quantidade máxima de 50 caracteres!"
                    },
                    validate: (value) =>
                      (!value.includes("'") && !value.includes('"')) ||
                      "Não é permitido adicionar aspas no Nome Fantasia"
                  })}
                  errors={errors}
                />
              </Loader>
            </Grid>
          </Grid>
          <Grid container p={2} spacing={4} className={classes.container}>
            <Grid item lg={3} md={3} sm={5}>
              <MaterialInputTexto
                type="text"
                id="InscricaoEstadual"
                name="InscricaoEstadual"
                label="Inscrição Estadual"
                renderIconShowHide={false}
                defaultValue={dadosEmpresaGrupoDelta?.inscricaoEstadual}
                ref={register({
                  required: "Campo Inscrição Estadual é obrigatório!",
                  maxLength: {
                    value: 50,
                    message: "Quantidade máxima de 50 caracteres!"
                  }
                })}
                errors={errors}
              />
            </Grid>
            <Grid item lg={3} md={3} sm={5}>
              <MaterialInputTexto
                type="text"
                id="InscricaoMunicipal"
                name="InscricaoMunicipal"
                label="Inscrição Municipal"
                renderIconShowHide={false}
                defaultValue={dadosEmpresaGrupoDelta?.inscricaoMunicipal}
                ref={register({
                  required: "Campo Inscrição Municipal é obrigatório!",
                  maxLength: {
                    value: 50,
                    message: "Quantidade máxima de 50 caracteres!"
                  }
                })}
                errors={errors}
              />
            </Grid>
            <Grid
              item
              lg={3}
              md={3}
              sm={5}
              className={classes.containerTelefone}
            >
              <Loader loading={carregandoCnpj}>
                <MaterialInputTelefone
                  type="text"
                  id="telefone"
                  name="telefone"
                  label="Telefone"
                  renderIconShowHide={false}
                  defaultValue={telefone}
                  value={telefone}
                  ref={register({
                    required: "O campo Telefone é obrigatório!",
                    minLength: {
                      value: 18,
                      message: "Quantidade mínima de 12 caracteres!"
                    }
                  })}
                  errors={errors}
                />
              </Loader>
            </Grid>
            <Grid item lg={3} md={3} sm={5}>
              <Loader loading={carregandoArvoresGerenciais}>
                <SelectHierarquia
                  id="arvoreGerencialId"
                  name="arvoreGerencialId"
                  valueKey="id"
                  valueName="descricao"
                  dataSource={listaArvoresGerenciais.arvoreGerencialColecao}
                  label="Árvore gerencial"
                  value={arvoreGerencialId}
                  isUpdate={dadosEmpresaGrupoDelta?.arvoreGerencialId ?? false}
                  onChange={(arvoreGerencial) =>
                    onChangeArvoreGerencial(arvoreGerencial)
                  }
                  placeholder="Árvore gerencial"
                  allowClear
                  ref={register(
                    { name: "arvoreGerencialId" },
                    {
                      required: !arvoreGerencialId
                        ? "Campo Árvore Gerencial é obrigatório!"
                        : false
                    }
                  )}
                  errors={errors}
                />
              </Loader>
            </Grid>
          </Grid>
          {dadosEmpresaGrupoDelta?.id ? (
            <Grid container p={2} spacing={4} className={classes.container}>
              <Grid
                item
                lg={3}
                md={3}
                sm={5}
                className={classes.containerAtivo}
              >
                <MaterialSwitch
                  label="Ativo"
                  labelPlacement="top"
                  id="Situacao"
                  name="Situacao"
                  checked={situacao}
                  onChange={(valor) => onChangeSituacao(valor)}
                />
              </Grid>
            </Grid>
          ) : null}
          <Divider className={classes.divider} />
          <Grid container p={2} spacing={4} className={classes.container}>
            <Grid item lg={3} md={3} sm={5}>
              <InputTextoCEP
                id="Cep"
                name="Cep"
                label="CEP"
                carregando={carregandoEndereco}
                onBlurCep={onBlurCep}
                defaultValue={dadosEmpresaGrupoDelta?.cep ?? ""}
                register={register}
                errors={errors}
              />
            </Grid>
            <Grid item lg={3} md={3} sm={5}>
              <Loader loading={carregandoEndereco}>
                <MaterialInputTexto
                  type="text"
                  id="endereco"
                  name="endereco"
                  label="Endereço"
                  permiteValorBranco
                  renderIconShowHide={false}
                  defaultValue={dadosEndereco?.endereco}
                  ref={register({
                    required: "Campo Endereço é obrigatório!",
                    maxLength: {
                      value: 200,
                      message: "Quantidade máxima de 200 caracteres!"
                    }
                  })}
                  errors={errors}
                />
              </Loader>
            </Grid>
            <Grid item lg={2} md={2} sm={4}>
              <Loader loading={carregandoEndereco}>
                <MaterialInputTexto
                  type="text"
                  id="numero"
                  name="numero"
                  label="Número"
                  permiteValorBranco
                  renderIconShowHide={false}
                  defaultValue={numero}
                  maxLength={30}
                  ref={register({
                    required: "Campo Número é obrigatório!",
                    maxLength: {
                      value: 5,
                      message: "Quantidade máxima de 30 caracteres!"
                    }
                  })}
                  errors={errors}
                />
              </Loader>
            </Grid>
            <Grid item lg={3} md={3} sm={5}>
              <Loader loading={carregandoEndereco}>
                <MaterialInputTexto
                  type="text"
                  id="complemento"
                  name="complemento"
                  label="Complemento"
                  permiteValorBranco
                  renderIconShowHide={false}
                  defaultValue={complemento}
                  ref={register({
                    maxLength: {
                      value: 50,
                      message: "Quantidade máxima de 50 caracteres!"
                    }
                  })}
                  errors={errors}
                />
              </Loader>
            </Grid>
          </Grid>

          <Grid container p={2} spacing={4} className={classes.container}>
            <Grid item lg={3} md={3} sm={5}>
              <Loader loading={carregandoEndereco}>
                <MaterialInputTexto
                  type="text"
                  id="bairro"
                  name="bairro"
                  label="Bairro"
                  permiteValorBranco
                  renderIconShowHide={false}
                  defaultValue={dadosEndereco?.bairro}
                  ref={register({
                    required: "Campo Bairro é obrigatório!",
                    maxLength: {
                      value: 100,
                      message: "Quantidade máxima de 100 caracteres!"
                    }
                  })}
                  errors={errors}
                />
              </Loader>
            </Grid>
            <Grid item lg={3} md={3} sm={5}>
              <Loader loading={carregandoEndereco}>
                <MaterialInputTexto
                  type="text"
                  id="cidade"
                  name="cidade"
                  label="Cidade"
                  permiteValorBranco
                  renderIconShowHide={false}
                  defaultValue={dadosEndereco?.cidade}
                  ref={register({
                    required: "Campo Cidade é obrigatório!",
                    maxLength: {
                      value: 100,
                      message: "Quantidade máxima de 100 caracteres!"
                    }
                  })}
                  errors={errors}
                />
              </Loader>
            </Grid>
            <Grid item lg={2} md={2} sm={4}>
              <Loader loading={carregandoUF || carregandoEndereco}>
                <SelectArredondado
                  id="uf"
                  name="uf"
                  valueKey="UF"
                  valueName="UF"
                  dataSource={listaUF}
                  label="UF"
                  value={ufId}
                  onChange={(estado) => onChangeUF(estado)}
                  placeholder="UF"
                  allowClear
                  ref={register(
                    { name: "uf" },
                    {
                      required: !ufId ? "Campo UF é obrigatório!" : false
                    }
                  )}
                  errors={errors}
                />
              </Loader>
            </Grid>
          </Grid>
          <Grid container p={2} spacing={4} className={classes.containerSalvar}>
            <Grid item lg={3} md={4} sm={6}>
              <Loader loading={carregandoEmpresaGrupoDelta}>
                <Botao
                  type="submit"
                  label="Salvar"
                  className={classes.button}
                />
              </Loader>
            </Grid>
          </Grid>
        </Card>
      </form>
    </>
  );
};

export default EmpresaGrupoDeltaCadastro;
