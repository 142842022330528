import React from "react";
import "date-fns";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import prbrLocale from "date-fns/locale/pt-BR";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { ptBR } from "@mui/x-date-pickers/locales";

// style
import { ThemeProvider } from "@mui/material/styles";
import StyledEngineProvider from "@mui/material/StyledEngineProvider";
import { materialTheme, useStyles } from "./style";

const InputCalendario = React.forwardRef((props, ref) => {
  const {
    label,
    id,
    name,
    format,
    defaultValue,
    errors,
    onChange,
    customValue,
    className,
    minDate,
    maxDate,
    views,
    disabled,
    openTo
  } = props;

  const classes = useStyles();

  return (
    <Grid container className={`${className.container} ${classes.container}`}>
      <LocalizationProvider
        adapterLocale={prbrLocale}
        dateAdapter={AdapterDateFns}
        localeText={
          ptBR.components.MuiLocalizationProvider.defaultProps.localeText
        }
      >
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={materialTheme}>
            <DatePicker
              minDate={minDate}
              maxDate={maxDate}
              id={id}
              format={format}
              label={label}
              defaultValue={defaultValue}
              value={customValue}
              inputRef={ref}
              onChange={onChange}
              disabled={disabled}
              views={views}
              openTo={openTo}
              slotProps={{
                actionBar: { actions: ["cancel", "accept", "clear"] },
                textField: {
                  name: name,
                  error: Boolean(errors[name]?.message),
                  helperText: errors[name]?.message ?? ""
                }
              }}
              className={classes.datepicker}
            />
          </ThemeProvider>
        </StyledEngineProvider>
      </LocalizationProvider>
    </Grid>
  );
});

InputCalendario.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  format: PropTypes.string,
  defaultValue: PropTypes.string,
  errors: PropTypes.oneOfType([PropTypes.object]),
  className: PropTypes.oneOfType([PropTypes.object]),
  onChange: PropTypes.oneOfType([PropTypes.func]),
  customValue: PropTypes.oneOfType([PropTypes.any]),
  minDate: PropTypes.oneOfType([PropTypes.any]),
  maxDate: PropTypes.oneOfType([PropTypes.any]),
  views: PropTypes.oneOfType([PropTypes.any]),
  disabled: PropTypes.bool,
  InputProps: PropTypes.object,
  variant: PropTypes.string,
  autoOk: PropTypes.bool,
  openTo: PropTypes.string,
};

InputCalendario.defaultProps = {
  label: "",
  id: "",
  format: "dd/MM/yyyy",
  defaultValue: "",
  errors: {},
  className: {},
  onChange: () => {},
  customValue: "",
  minDate: new Date(new Date().setFullYear(new Date().getFullYear() - 2)),
  maxDate: new Date("2090-12-31"),
  views: ["year", "month", "day"],
  disabled: false,
  variant: "dialog",
  autoOk: false,
  InputProps: { readOnly: false },
  openTo: "day",
};

export default InputCalendario;
