import { makeStyles } from '@mui/styles';
import theme from "../../../../../themes";

export const useStyles = makeStyles(() => ({
  root: {
    color: `${theme.color.secondaryBorderColor} !important`,
    fontSize: '28px',
    margin: '200px auto 0',
    maxWidth: '500px',
    textAlign: 'center',
  }
}));