import PropTypes from "prop-types";
import React, { useState } from "react";

// Componentes
import { GetApp } from "@mui/icons-material";
import { Box } from "@mui/material";
import IconeAprovar from "assets/icones/operacoes/aprovar.png";
import IconeRejeitar from "assets/icones/operacoes/rejeitar.png";
import { Loader, SelectArredondado } from "componentes";
import { HtmlTooltip } from "paginas/Graficos/ConsumoAtual/style";
import operacaoControleHelper from "../../helper";
import { Icone, TabelaModal, useStyles, Scroll } from "../style";

const ModalDocumentos = ({
  listaClienteDocumento,
  listaTipoProblema,
  listaControleDocumento,
  setListaControleDocumento,
  setDocumentoComProblema,
  btnSalvarValidacaoDocumentoDisabled,
  documentosSelecionados,
  setDocumentosSelecionados
}) => {
  const classes = useStyles();
  const [carregandoModalDocumentos, setCarregandoModalDocumentos] = useState(
    false
  );
  const [itensSelecionado, setItensSelecionados] = useState([]);

  const onClickDownloadDocumentoCliente = async (documento) => {
    setCarregandoModalDocumentos(true);

    await operacaoControleHelper.downloadDocumentoCliente(
      documento.id,
      documento?.nomeArquivo + documento?.extensao
    );
    setCarregandoModalDocumentos(false);
  };

  const onClickAdicionaDocumentoProblema = (documento, item) => {
    if (
      listaControleDocumento?.find(
        (item) => item.tipoDocumentoId === documento?.tipoDocumento?.id
      )
    ) {
      return;
    }
    const novoItem = {
      id: 0,
      operacaoTipoProblemaId: 0,
      tipoDocumentoId: documento?.tipoDocumento?.id
    };
    const listaControleDocumentoAtualizada = [
      ...listaControleDocumento,
      novoItem
    ];
    const novosItensSelecionados = [...documentosSelecionados, documento];

    setListaControleDocumento(listaControleDocumentoAtualizada);
    setDocumentosSelecionados(novosItensSelecionados);
    setItensSelecionados([...itensSelecionado, item]);
  };

  const onClickRemoverDocumentoProblema = (documento, item) => {
    const listaDocumentosRemovidos = listaControleDocumento.filter(
      (item) => item?.tipoDocumentoId === documento?.tipoDocumento?.id
    );
    const listaControleDocumentoAtualizada = listaControleDocumento.filter(
      (item) => item?.tipoDocumentoId !== documento?.tipoDocumento?.id
    );
    if (listaDocumentosRemovidos.length > 1) {
      listaDocumentosRemovidos.splice(-1);
      setListaControleDocumento([
        ...listaControleDocumentoAtualizada,
        ...listaDocumentosRemovidos
      ]);
    } else {
      setListaControleDocumento(listaControleDocumentoAtualizada);
    }
    if (listaControleDocumentoAtualizada.length === 0) {
      setDocumentoComProblema("NÃO");
    }

    const novosItensSelecionados = documentosSelecionados.filter(
      (item) => item?.id !== documento?.id
    );

    setDocumentosSelecionados(novosItensSelecionados);
    setItensSelecionados(itensSelecionado.filter((i) => i !== item));
  };

  const onChangeDocumentoProblema = (documento, idProblema) => {
    const listaControleDocumentoAtualizada = listaControleDocumento.map(
      (item) => {
        if (item?.tipoDocumentoId === documento?.tipoDocumento?.id) {
          return {
            ...item,
            operacaoTipoProblemaId: idProblema
          };
        }
        return item;
      }
    );

    setListaControleDocumento(listaControleDocumentoAtualizada);
  };

  return (
    <Loader loading={carregandoModalDocumentos} className="w-auto">
      <Scroll>
        <TabelaModal>
          <thead>
            <TabelaModal.Tr container borderBottom>
              <TabelaModal.ThLeft borderBottom width="200px">
                <div>
                  <b>Tipo de Documento</b>
                </div>
              </TabelaModal.ThLeft>
              <TabelaModal.Th width="150px">
                <div>
                  <b>Data de Upload</b>
                </div>
              </TabelaModal.Th>
              <TabelaModal.Th width="250px">
                <div>
                  <b>Nome do arquivo</b>
                </div>
              </TabelaModal.Th>
              <TabelaModal.Th width="120px">
                <div>
                  <b>Documentos</b>
                </div>
              </TabelaModal.Th>
              <TabelaModal.Th>
                <div>
                  <b>Aprovar ou rejeitar</b>
                </div>
              </TabelaModal.Th>
            </TabelaModal.Tr>
          </thead>
          <tbody>
            {listaClienteDocumento?.map((item, a) => (
              <TabelaModal.Tr container key={a}>
                <TabelaModal.TdLeft width="200px">
                  <div>{item?.tipoDocumento?.documento}</div>
                </TabelaModal.TdLeft>
                <TabelaModal.Td width="150px">
                  <div>{item?.dataCriacaoFormatada}</div>
                </TabelaModal.Td>
                <TabelaModal.Td width="250px">
                  <div>{item?.nomeArquivo + item?.extensao}</div>
                </TabelaModal.Td>
                <TabelaModal.Td width="120px">
                  <button
                    type="button"
                    onClick={() => onClickDownloadDocumentoCliente(item)}
                    className={classes.button}
                  >
                    <HtmlTooltip title="Baixar">
                      <GetApp />
                    </HtmlTooltip>
                  </button>
                </TabelaModal.Td>
                <TabelaModal.Td>
                  <button
                    type="button"
                    className={classes.button}
                    onClick={() => {
                      onClickRemoverDocumentoProblema(item, a + 1);
                    }}
                    disabled={
                      btnSalvarValidacaoDocumentoDisabled ||
                      !listaControleDocumento?.find(
                        (selecionado) =>
                          selecionado?.tipoDocumentoId ===
                          item?.tipoDocumento?.id
                      )
                    }
                  >
                    <HtmlTooltip title="Aprovar">
                      <Icone src={IconeAprovar} />
                    </HtmlTooltip>
                  </button>
                  <button
                    type="button"
                    className={classes.button}
                    onClick={() => {
                      onClickAdicionaDocumentoProblema(item, a + 1);
                    }}
                    disabled={
                      !!listaControleDocumento?.find(
                        (selecionado) =>
                          selecionado?.tipoDocumentoId ===
                          item?.tipoDocumento?.id
                      ) || btnSalvarValidacaoDocumentoDisabled
                    }
                  >
                    <HtmlTooltip title="Rejeitar">
                      <Icone src={IconeRejeitar} />
                    </HtmlTooltip>
                  </button>
                  <Box pt={1}>
                    {itensSelecionado.find((item) => item === a + 1) ? (
                      <SelectArredondado
                        select
                        id={`tipoProblema-${item?.id}`}
                        name={`tipoProblema-${item?.id}`}
                        label="Motivo"
                        placeholder="Motivo"
                        valueKey="id"
                        valueName="descricao"
                        dataSource={listaTipoProblema}
                        value={
                          listaControleDocumento.find(
                            (documento) =>
                              documento.tipoDocumentoId ===
                              item?.tipoDocumento?.id
                          )?.operacaoTipoProblemaId
                        }
                        onChange={(e) => {
                          onChangeDocumentoProblema(item, e?.target?.value);
                          setDocumentoComProblema("SIM");
                        }}
                        permiteValorBranco
                        styleForm={false}
                        disabled={btnSalvarValidacaoDocumentoDisabled}
                      />
                    ) : (
                      ""
                    )}
                  </Box>
                </TabelaModal.Td>
              </TabelaModal.Tr>
            ))}
          </tbody>
        </TabelaModal>
      </Scroll>
    </Loader>
  );
};

ModalDocumentos.propTypes = {
  listaClienteDocumento: PropTypes.oneOfType([PropTypes.any]),
  listaControleDocumento: PropTypes.oneOfType([PropTypes.any]),
  listaTipoProblema: PropTypes.oneOfType([PropTypes.any]),
  setListaControleDocumento: PropTypes.func,
  setDocumentoComProblema: PropTypes.func,
  btnSalvarValidacaoDocumentoDisabled: PropTypes.bool,
  documentosSelecionados: PropTypes.arrayOf(PropTypes.number),
  setDocumentosSelecionados: PropTypes.func
};

ModalDocumentos.defaultProps = {
  listaClienteDocumento: {},
  listaControleDocumento: {},
  listaTipoProblema: {},
  setListaControleDocumento: () => {},
  setDocumentoComProblema: () => {},
  btnSalvarValidacaoDocumentoDisabled: false,
  documentosSelecionados: false,
  setDocumentosSelecionados: () => {}
};

export default ModalDocumentos;
