import { Grid, Icon } from "@mui/material";
import { useStyles } from "./style";
import MaterialInputTexto from "componentes/inputTexto/materialInput";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState
} from "react";
import { Loader } from "componentes";
import { useForm } from "react-hook-form";
import MaterialInputMascara from "componentes/inputTextoMascara";
import { cpf } from "cpf-cnpj-validator";
import MaterialInputTelefone from "componentes/inputTexto/materialInputTelefone";
import { removerCaracteres } from "servicos/utils";
import { mdiTrashCanOutline } from "@mdi/js";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { Botao } from "componentes";
import BotaoOutLine from "componentes/botaoOutline";
import { SelectArredondado } from "componentes";
import { estadoCivilList } from '../../../../../global/constantes';
import { store } from "../../../../../global/redux";
import { alertaExibir } from "../../../../../global/redux/modulos/alertas/actions";
// import { Container } from './styles';

const RepresentanteLegalCard = forwardRef((props, ref) => {
  const {
    index,
    representanteLegal,
    somenteLeitura,
    onClickRemove,
    key
  } = props;
  const classes = useStyles();
  const [carregando, setCarregando] = useState(false);
  const [listaEstadoCivil, setListaEstadoCivil] = useState([estadoCivilList]);
  const [estadoCivil, setEstadoCivil] = useState(representanteLegal?.estadoCivil);

  const { register, errors, handleSubmit, control, setError} = useForm({
    reValidateMode: "onSubmit"
  });

  // console.log(key);

  const representanteNumero = useMemo(() => {
    return index + 1;
  }, [index]);

  const validarNomeUnico = (nome) => {
    if (!nome || nome.length === 0) return true;

    const nomes = nome.split(" ");

    return nomes.length > 1;
  };

  const handleObterValores = () => {
    return {
      ...control.getValues(),
      id: representanteLegal.id,
      chaveUnica: representanteLegal.chaveUnica
    };
  };

  const handleSetarErro = (campo, mensagem) => {
    setError(campo, {
      type: "manual",
      message: mensagem
    });
  };

  const handleValidarCampos = async () => {
    return control?.trigger();
  };

  const obterListaEstadoCivil = async () => {
    try {
      setCarregando(true);
      const lista = {
        data: estadoCivilList,
        status: 200
      };
      if (lista?.status === 200 && lista?.data) {
        setListaEstadoCivil(lista?.data);
      }
      setCarregando(false);
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
      setCarregando(false);
    }
  };

  const onChangeEstadoCivil = (selected) => {
    setEstadoCivil(selected.target.value);
  };


  useEffect(() => {
    obterListaEstadoCivil();
  }, []);

  useImperativeHandle(ref, () => ({
    validarCampos() {
      return handleValidarCampos();
    },
    obterValores() {
      const valores = handleObterValores();
      return {
        ...valores,
        rg: removerCaracteres(valores.rg),
        cpf: removerCaracteres(valores.cpf),
        telefone: removerCaracteres(valores.telefone),
        estadoCivil
      };
    },
    setarErro(campo, mensagem) {
      handleSetarErro(campo, mensagem);
    }
  }));

  return (
    <Loader loading={carregando}>
      <form className="needs-validation">
        <Grid container>
          <Grid item xs={12} className={classes.conteudo}>
            <label className={classes.representanteTitulo}>
              Representante {representanteNumero}
              {index > 0 && (
                <DeleteOutlinedIcon
                  className={classes.icone}
                  onClick={() =>
                    onClickRemove &&
                    onClickRemove(representanteLegal.chaveUnica)
                  }
                />
              )}
            </label>
          </Grid>
          <Grid container spacing={2}>
            <Grid item md={6} sm={12} xs={12}>
              <MaterialInputTexto
                type="text"
                id="nomeCompleto"
                name="nomeCompleto"
                label="Nome completo"
                renderIconShowHide={false}
                disabled={somenteLeitura}
                defaultValue={representanteLegal?.nomeCompleto}
                ref={register({
                  required: "Campo Nome completo é obrigatório!",
                  maxLength: {
                    value: 100,
                    message: "Quantidade máxima de 100 caracteres!"
                  },
                  pattern: {
                    value: /^[a-zA-Z\u00C0-\u00FF\s]*$/,
                    message:
                      "Campo preenchido incorretamente, deve ser informado apenas letras e espaços!"
                  },
                  validate: (value) =>
                    validarNomeUnico(value) ||
                    "Deve ser informado Nome e Sobrenome"
                })}
                errors={errors}
              />
            </Grid>
            <Grid item md={3} sm={6} xs={12}>
              <MaterialInputTexto
               type="text"
                id="rg"
                name="rg"
                label="RG"
                disabled={somenteLeitura}
                renderIconShowHide={false}
                defaultValue={representanteLegal?.rg}
                ref={register({
                  required: "Campo RG é obrigatório!",
                  maxLength: {
                    value: 15,
                    message: "Quantidade máxima de 15 caracteres!"
                  }
                })}
                errors={errors}
              />
            </Grid>
            <Grid item md={3} sm={6} xs={12}>
              <MaterialInputMascara
                type="text"
                id="cpf"
                name="cpf"
                label="CPF"
                disabled={somenteLeitura}
                mask="###.###.###-##"
                renderIconShowHide={false}
                defaultValue={representanteLegal?.cpf}
                ref={register({
                  required: "Campo CPF é obrigatório!",
                  maxLength: {
                    value: 14,
                    message: "Quantidade máxima de 14 caracteres!"
                  },
                  minLength: {
                    value: 14,
                    message: "Quantidade mínima de 14 caracteres!"
                  },
                  validate: (value) => cpf.isValid(value) || "CPF inválido!"
                })}
                errors={errors}
              />
            </Grid>
            <Grid item md={3} sm={12} xs={12}>
              <MaterialInputTexto
                type="text"
                id="email"
                name="email"
                label="Email"
                disabled={somenteLeitura}
                renderIconShowHide={false}
                defaultValue={representanteLegal?.email}
                ref={register({
                  required: "O campo E-mail é obrigatório!",
                  pattern: {
                    value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: "E-mail é inválido!"
                  },
                  maxLength: {
                    value: 100,
                    message: "Quantidade máxima de 100 caracteres!"
                  }
                })}
                errors={errors}
              />
            </Grid>
            <Grid item md={3} sm={6} xs={12}>
              <MaterialInputTelefone
                type="text"
                id="telefone"
                name="telefone"
                label="Telefone"
                disabled={somenteLeitura}
                renderIconShowHide={false}
                defaultValue={representanteLegal.telefone}
                ref={register({
                  required: "O campo Telefone é obrigatório!",
                  minLength: {
                    value: 18,
                    message: "Quantidade mínima de 12 caracteres!"
                  }
                })}
                errors={errors}
              />
            </Grid>
            <Grid item md={3} sm={12} xs={6}>
              <MaterialInputTexto
                type="text"
                id="nacionalidade"
                name="nacionalidade"
                label="Nacionalidade"
                disabled={somenteLeitura}
                renderIconShowHide={false}
                defaultValue={representanteLegal?.nacionalidade ?? 'Brasileira'}
                ref={register({
                  required: "Campo Nacionalidade é obrigatório!",
                  pattern: {
                    value: /([^\d$&+,:;=?@#|'<>.^*()%!-]*$)/g,
                    message:
                      "Deve ser informado apenas letras e espaços no nome!"
                  },
                })}
                errors={errors}
              />
            </Grid>
            <Grid item md={3} sm={12} xs={6}>
              <SelectArredondado
                    id="estadoCivil"
                    name="estadoCivil"
                    valueKey="value"
                    valueName="label"
                    dataSource={listaEstadoCivil}
                    label="Estado Civil"
                    disabled={somenteLeitura}
                    value={estadoCivil}
                    onChange={(value) => onChangeEstadoCivil(value)}
                    placeholder="Estado civil"
                    allowClear
                    ref={register(
                      { name: "estadoCivil" },
                      {
                        required: !estadoCivil ? "Campo Estado civil é obrigatório!" : false
                      }
                    )}
                    errors={errors}
                  />
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Loader>
  );
});

export default RepresentanteLegalCard;
