import React, { useState, useEffect } from "react";
import moment from "moment";
import * as XLSX from "xlsx";

// Componentes
import { Grid } from "@mui/material";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { handleLoading } from "global/redux/modulos/loader/actions";
import CustomLoader from "componentes/customLoader";
import {
  Botao,
  ModalConfirmacao,
  SelectArredondado
} from "../../../componentes";
import InputBlock from "../Componentes/InputBlock";
import MaterialInputMascara from "../Componentes/InputTextoMascara";
import TabelaPrecoAtual from "../Componentes/TabelaPrecoAtual";
import SelectSubmercado from "../Componentes/SelectSubmercado";
import { HtmlTooltip } from "../../Graficos/ConsumoAtual/style";
import { anos } from "../anos";
import InputFile from "../Componentes/InputFile";
import DownloadButton from "../Componentes/DownloadButton";
import { meses } from "../meses";

// Redux
import { store } from "../../../global/redux";
import { alertaExibir } from "../../../global/redux/modulos/alertas/actions";

// Serviços
import {
  listarPrecos,
  postarPrecos,
  downloadPlanilhaPrecos
} from "../../../servicos/precoServico";

// Form Hooks

// Styles
import { BotaoFiltro, useStyles } from "./style";
import { usuarioPossuiFuncionalidade } from "../../../servicos/funcionalidadesServico";
import { RotasDTO } from "../../../global/rotas/rotasUrlDto";
import { InterfaceDTO } from "../../../global/dto/interfacesDto";

const Atual = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { register, setError, errors, handleSubmit, clearErrors } = useForm({
    reValidateMode: "onChange"
  });
  const [filtro, setFiltro] = useState({
    ano: null,
    submercadoId: 1,
    margem: "",
    margemTradicional: "",
    comissao: "",
    ganhoMin3: "",
    ganhoMin4: "",
    ganhoMin5: "",
    mensalidadeMedidor: "",
    adicionalMedidor: "",
    swapI5: "",
    swapI1: ""
  });
  const [ultimaAtualizacao, setUltimaAtualizacao] = useState("");
  const [submercadoEspelho, setSubmercadoEspelho] = useState("");
  const [initialState, setInitialState] = useState(false);
  const [precosNaoAgrupados, setPrecosNaoAgrupados] = useState([]);
  const [precosAgrupados, setPrecosAgrupados] = useState([]);
  const [precosSalvar, setPrecosSalvar] = useState([]);
  const [botaoDesabilitado, setBotaoDesabilitado] = useState(true);
  const [temErro, setTemErro] = useState(false);
  const [limparCampo, setLimparCampo] = useState(false);
  const [campoEditar, setCampoEditar] = useState(false);
  const [modalInformacao, setModalInformacao] = useState(false);
  const [mensagemModalInformacao, setMensagemModalConfirmacao] = useState("");
  const usuario = useSelector((state) => state.usuario);
  const rotas = useSelector((state) => state.rotas);
  const { menu } = usuario;
  const [itemAberto, setItemAberto] = useState(-1);

  const editarInputs = usuarioPossuiFuncionalidade(
    menu,
    rotas.rotaAtual.caminho,
    RotasDTO.PrecosAtual,
    InterfaceDTO.PermissaoEditarPrecoMensal
  );

  const verificarMesAtual = (ano, mes) => {
    const dataHoje = new Date();
    const m = dataHoje.getMonth();
    const y = dataHoje.getFullYear();

    if (ano < y || (ano === y && mes < m + 1)) return true;
    return false;
  };

  const buscarPrecoPorAnoMes = (precosApi, ano, mes, submercadoId) => {
    const precosLinha = precosApi.find(
      (preco) => preco.ano === ano && preco.mes === mes
    );

    if (precosLinha) {
      return {
        ...precosLinha,
        ganhoMin3: precosLinha.ganhoMin3 ? precosLinha.ganhoMin3 : 0.0,
        ganhoMin4: precosLinha.ganhoMin4 ? precosLinha.ganhoMin4 : 0.0,
        ganhoMin5: precosLinha.ganhoMin5 ? precosLinha.ganhoMin5 : 0.0,
        mensalidadeMedidor: precosLinha.mensalidadeMedidor
          ? precosLinha.mensalidadeMedidor
          : 0.0,
        adicionalMedidor: precosLinha.adicionalMedidor
          ? precosLinha.adicionalMedidor
          : 0.0,
        margem: precosLinha.margem ? precosLinha.margem : 0.0,
        margemTradicional: precosLinha.margemTradicional
          ? precosLinha.margemTradicional
          : 0.0,
        comissao: precosLinha.comissao ? precosLinha.comissao : 0.0,
        bloqueado: verificarMesAtual(ano, mes)
      };
    }

    return {
      ano,
      ganhoMin3: 0.0,
      ganhoMin4: 0.0,
      ganhoMin5: 0.0,
      mensalidadeMedidor: 0.0,
      adicionalMedidor: 0.0,
      margem: 0.0,
      margemTradicional: 0.0,
      comissao: 0.0,
      mes,
      nlote: 0,
      precoConv: 0,
      precoI5: 0,
      precoI1: 0,
      swapI5: 0,
      swapI1: 0,
      submercadoId: submercadoId ? Number(submercadoId) : null,
      bloqueado: verificarMesAtual(ano, mes)
    };
  };

  const agruparPrecosPorAno = (submercadoId, items) => {
    const itemsAgrupados = anos.map((ano) => {
      return {
        ano: ano.valor,
        items: meses.map((mes) =>
          buscarPrecoPorAnoMes(items, ano.valor, mes.valor, submercadoId)
        )
      };
    });

    return itemsAgrupados;
  };

  const salvarUltimaAtualizacao = (items) => {
    if (items?.length) {
      const max = new Date(
        Math.max(...items.map((e) => new Date(e.dataInicioVigencia)))
      );

      if (max) {
        setUltimaAtualizacao(moment(max).format("DD/MM/yyyy HH:mm"));
      }
    } else {
      setUltimaAtualizacao("");
    }
  };

  const salvarUltimaMargemGanhoMin = (precos) => {
    if (precos?.length) {
      const maxMargem = precos[precos.length - 1].margem;
      const maxGanhoMin3 = precos[precos.length - 1].ganhoMin3;
      const maxGanhoMin4 = precos[precos.length - 1].ganhoMin4;
      const maxGanhoMin5 = precos[precos.length - 1].ganhoMin5;
      const maxMensalidadeMedidor =
        precos[precos.length - 1].mensalidadeMedidor;
      const maxAdicionalMedidor = precos[precos.length - 1].adicionalMedidor;
      const { comissao } = precos[precos.length - 1];
      const { margemTradicional } = precos[precos.length - 1];
      setFiltro((prevState) => ({
        ...prevState,
        margem: maxMargem || 0,
        ganhoMin3: maxGanhoMin3 || 0,
        ganhoMin4: maxGanhoMin4 || 0,
        ganhoMin5: maxGanhoMin5 || 0,
        mensalidadeMedidor: maxMensalidadeMedidor || 0,
        adicionalMedidor: maxAdicionalMedidor || 0,
        comissao: comissao || 0,
        margemTradicional: margemTradicional || 0
      }));
    } else {
      setFiltro((prevState) => ({
        ...prevState,
        margem: 0,
        ganhoMin3: 0,
        ganhoMin4: 0,
        ganhoMin5: 0,
        mensalidadeMedidor: 0,
        adicionalMedidor: 0,
        comissao: 0,
        margemTradicional: 0
      }));
    }
  };

  const onChangePrecos = (precosSubmercado) => {
    const precosParaAgrupar = precosSubmercado;

    const precosSubmercados = [
      { submercadoId: 1, precos: [] },
      { submercadoId: 2, precos: [] },
      { submercadoId: 3, precos: [] },
      { submercadoId: 4, precos: [] }
    ];
    const precosNovosAgrupados = precosSubmercados.map((submercado) => {
      const precosFiltrados = precosParaAgrupar.filter(
        (preco) => preco.submercadoId === Number(submercado.submercadoId)
      );
      const dadosFormatados = agruparPrecosPorAno(
        submercado.submercadoId,
        precosFiltrados
      );

      return { submercadoId: submercado.submercadoId, precos: dadosFormatados };
    });
    setPrecosAgrupados(precosNovosAgrupados);

    setPrecosNaoAgrupados(precosParaAgrupar);

    salvarUltimaAtualizacao(precosParaAgrupar || []);
    salvarUltimaMargemGanhoMin(precosParaAgrupar || []);
    setPrecosSalvar([]);
    setTemErro(false);
    setBotaoDesabilitado(true);
  };

  const buscarPrecos = async () => {
    try {
      dispatch(handleLoading(true));
      if (filtro.submercadoId) {
        const resposta = await listarPrecos({
          tamanhoPaginacao: 800
        });
        const { precos } = resposta.data;

        onChangePrecos(precos || []);
      }
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
    } finally {
      dispatch(handleLoading(false));
      setLimparCampo(false);
    }
  };

  const validarEnter = (e) => {
    if (filtro.ano) clearErrors([e.target.name]);

    setError([e.target.name], {
      message: "Aperte o Enter!",
      type: "required"
    });
  };

  const alterarValorCampo = (valor, nome) => {
    setLimparCampo(false);
    setFiltro((prevState) => ({
      ...prevState,
      [nome]: valor
    }));
  };

  const verificarCampos = (ano = true) => {
    let campoVazio = false;

    if (filtro.ano) clearErrors();

    if (!filtro.ano && ano) {
      setError("ano", {
        message: "Campo obrigatório!",
        type: "required"
      });
      campoVazio = true;
    }

    if (!filtro.submercadoId) {
      setError("submercadoId", {
        message: "Campo obrigatório!",
        type: "required"
      });
      campoVazio = true;
    }

    if (campoVazio) {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }

    return campoVazio;
  };

  const alterarValores = (e) => {
    validarEnter(e);
    const valor = e.target.value;

    if (e.keyCode === 13) {
      if (verificarCampos()) return;

      const somarCampos = (item) => {
        if (e.target.name === "swapI1" && valor) {
          return {
            precoI1:
              parseFloat(String(valor)?.replace(".", "").replace(",", ".")) +
              item.precoConv,
            [e.target.name]: valor
              ? parseFloat(String(valor)?.replace(".", "").replace(",", "."))
              : null
          };
        }
        return {
          precoI5:
            parseFloat(String(valor)?.replace(".", "").replace(",", ".")) +
            item.precoConv,
          [e.target.name]: valor
            ? parseFloat(String(valor)?.replace(".", "").replace(",", "."))
            : null
        };
      };

      const precosAtualizados = precosAgrupados.map((submercado) => {
        if (submercado.submercadoId === Number(filtro.submercadoId)) {
          return {
            ...submercado,
            precos: submercado.precos.map((preco) => {
              if (Number(filtro.ano) === preco.ano)
                return {
                  ano: Number(filtro.ano),
                  items: preco.items.map((item) => {
                    if (item.bloqueado) return item;
                    return {
                      ...item,
                      ...somarCampos(item)
                    };
                  })
                };

              return preco;
            })
          };
        }
        return submercado;
      });

      setPrecosSalvar(precosAtualizados);
      setPrecosAgrupados(precosAtualizados);
    }
  };

  const alterarValoresMargemGanhoMin = (e) => {
    validarEnter(e);
    const valor = e.target.value;

    if (e.keyCode === 13) {
      if (verificarCampos(false)) return;

      const precosAtualizados = precosAgrupados.map((submercado) => {
        return {
          ...submercado,
          precos: submercado.precos.map((preco) => {
            const novosPrecos = preco.items.map((item) => {
              if (item.bloqueado) return item;
              return {
                ...item,
                [e.target.name]: valor
                  ? parseFloat(
                      String(valor)?.replace(".", "").replace(",", ".")
                    )
                  : null
              };
            });

            return { ...preco, items: novosPrecos };
          })
        };
      });

      clearErrors([e.target.name]);

      setPrecosSalvar(precosAtualizados);
      setPrecosAgrupados(precosAtualizados);
    }
  };

  const atualizarPreco = (linhas) => {
    const precosAtualizados = precosAgrupados.map((submercado) => {
      if (submercado.submercadoId === Number(filtro.submercadoId))
        return {
          ...submercado,
          precos: submercado.precos.map((preco) => {
            if (linhas[0].ano === preco.ano)
              return {
                ano: linhas[0].ano,
                items: linhas
              };

            return preco;
          })
        };
      return submercado;
    });

    setPrecosAgrupados(precosAtualizados);
    setPrecosSalvar(precosAtualizados);
  };

  const habilitarBotaoSalvar = () => {
    if (precosSalvar.length) {
      setBotaoDesabilitado(false);
    }
  };

  const replicarValores = () => {
    if (verificarCampos()) return;

    const precosPorAno = precosAgrupados
      .find(
        (submercado) => submercado.submercadoId === Number(filtro.submercadoId)
      )
      .precos.find((preco) => Number(filtro.ano) === preco.ano);

    const precoNaoBloqueado = precosPorAno.items.find(
      (preco) => !preco?.bloqueado
    );

    if (
      !precoNaoBloqueado ||
      (precoNaoBloqueado?.precoConv === null &&
        precoNaoBloqueado?.precoI5 === null &&
        precoNaoBloqueado?.precoI1 === null &&
        precoNaoBloqueado?.swapI5 === null &&
        precoNaoBloqueado?.swapI1 === null)
    ) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem: "Não existem valores a replicar!"
        })
      );
      return;
    }

    const novosPrecos = precosPorAno.items.map((item) => {
      if (item.bloqueado) return item;
      const itemNovo = precoNaoBloqueado;
      if (!Number.isNaN(itemNovo.id) && Number.isNaN(item.id))
        delete itemNovo.id;
      return {
        ...itemNovo,
        mes: item.mes,
        ano: item.ano,
        submercadoId: Number(filtro.submercadoId)
      };
    });

    const precosAtualizados = precosAgrupados.map((submercado) => {
      if (submercado.submercadoId === Number(filtro.submercadoId)) {
        return {
          ...submercado,
          precos: submercado.precos.map((preco) => {
            if (Number(filtro.ano) === preco.ano)
              return { ano: Number(filtro.ano), items: novosPrecos };

            return preco;
          })
        };
      }
      return submercado;
    });

    setPrecosSalvar(precosAtualizados);
    setPrecosAgrupados(precosAtualizados);

    alertaExibir({
      tipo: "success",
      mensagem: "Dados replicados com sucesso!"
    });
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const espelharSubmercado = async (id) => {
    dispatch(handleLoading(true));
    if (Number(id) === Number(filtro.submercadoId)) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem: "Não é possível espelhar do mesmo submercado!"
        })
      );
      dispatch(handleLoading(false));
      return;
    }

    if (!id || verificarCampos()) {
      dispatch(handleLoading(false));
      return;
    }
    if (precosNaoAgrupados) {
      const precosParaEspelhar = precosAgrupados
        .find((item) => item.submercadoId === Number(id))
        .precos.find((preco) => preco.ano === Number(filtro.ano));
      const precosPorAno = precosAgrupados
        .find((item) => item.submercadoId === Number(filtro.submercadoId))
        .precos.find((preco) => Number(filtro.ano) === preco.ano);
      const precosAntigos = precosPorAno.items.filter(
        (preco) => !preco.bloqueado
      );
      let temCampoVazio = false;

      if (precosParaEspelhar?.items) {
        precosParaEspelhar.items.forEach((item) => {
          if (
            (item.precoConv === null ||
              item.precoConv === "" ||
              item.precoConv === 0 ||
              item.precoI5 === null ||
              item.precoI5 === "" ||
              item.precoI5 === 0 ||
              item.precoI1 === null ||
              item.precoI1 === "" ||
              item.precoI1 === 0 ||
              item.swapI5 === null ||
              item.swapI5 === "" ||
              item.swapI1 === null ||
              item.swapI1 === "" ||
              item.swapI1 === 0) &&
            !item.bloqueado
          ) {
            temCampoVazio = true;
          }
        });
      }

      if (temCampoVazio) {
        store.dispatch(
          alertaExibir({
            tipo: "warning",
            mensagem:
              "Só é possível espelhar quando todos os dados estiverem preenchidos!"
          })
        );
        dispatch(handleLoading(false));
        return;
      }

      if (!precosParaEspelhar || !precosAntigos) {
        store.dispatch(
          alertaExibir({
            tipo: "warning",
            mensagem: "Não existem valores para espelhar!"
          })
        );
        dispatch(handleLoading(false));
        return;
      }

      const precosEspelhados = precosPorAno.items.map((item) => {
        const preco = precosParaEspelhar.items.find(
          (precoItem) => precoItem.mes === item.mes
        );

        if (item.bloqueado) return item;

        return {
          ...preco,
          submercadoId: item.submercadoId,
          bloqueado: item.bloqueado
        };
      });

      const precosAtualizadosPorAno = precosAgrupados.map((item) => {
        if (item.submercadoId === Number(filtro.submercadoId))
          return {
            ...item,
            precos: item.precos.map((preco) => {
              if (Number(filtro.ano) === preco.ano)
                return { ano: Number(filtro.ano), items: precosEspelhados };

              return preco;
            })
          };

        return item;
      });

      setPrecosSalvar(precosAtualizadosPorAno);
      setPrecosAgrupados(precosAtualizadosPorAno);

      store.dispatch(
        alertaExibir({
          tipo: "success",
          mensagem: "Dados replicados com sucesso!"
        })
      );
      dispatch(handleLoading(false));
    } else {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem: "Não existem valores a espelhar!"
        })
      );
      dispatch(handleLoading(false));
    }
  };

  const verificarCamposTabela = () => {
    let temCampoVazio = false;

    precosSalvar.forEach((submercado) => {
      submercado.precos.forEach((preco) => {
        preco.items.forEach((item) => {
          if (
            (item.precoConv === null ||
              item.precoConv === "" ||
              item.precoI5 === null ||
              item.precoI5 === "" ||
              item.precoI1 === null ||
              item.precoI1 === "" ||
              item.swapI5 === null ||
              item.swapI5 === "" ||
              item.swapI1 === null ||
              item.swapI1 === "") &&
            !item.bloqueado
          ) {
            temCampoVazio = true;
          }
        });
      });
    });

    if (campoEditar || temCampoVazio) setTemErro(true);

    return campoEditar || temCampoVazio;
  };

  const verificarTabela = () => {
    let temCampoVazio = false;

    precosSalvar.forEach((submercado) => {
      submercado.precos.forEach((preco) => {
        const precosFiltrados = preco.items.filter((item) => !item.bloqueado);
        const precosZerados = preco.items.filter(
          (item) =>
            (item.precoConv === 0 ||
              item.precoI5 === 0 ||
              item.precoI1 === 0 ||
              item.swapI5 === 0 ||
              item.swapI1 === 0) &&
            !item.bloqueado
        );
        if (precosZerados.length === 0) {
          return;
        }
        if (precosFiltrados.length > precosZerados.length) {
          temCampoVazio = true;
          return;
        }
        preco.items.every((item) => {
          if (item.bloqueado) {
            return false;
          }
          if (
            (item.precoConv > 0 &&
              (item.precoI5 === 0 ||
                item.precoI1 === 0 ||
                item.swapI5 === 0 ||
                item.swapI1 === 0)) ||
            (item.precoI5 > 0 &&
              (item.precoConv === 0 ||
                item.precoI1 === 0 ||
                item.swapI5 === 0 ||
                item.swapI1 === 0)) ||
            (item.precoI1 > 0 &&
              (item.precoConv === 0 ||
                item.precoI5 === 0 ||
                item.swapI5 === 0 ||
                item.swapI1 === 0)) ||
            (item.swapI5 > 0 &&
              (item.precoConv === 0 ||
                item.precoI1 === 0 ||
                item.precoI5 === 0 ||
                item.swapI1 === 0)) ||
            (item.swapI1 > 0 &&
              (item.precoConv === 0 ||
                item.precoI1 === 0 ||
                item.swapI5 === 0 ||
                item.precoI5 === 0))
          ) {
            temCampoVazio = true;
            return false;
          }
          return true;
        });
      });
    });

    return temCampoVazio;
  };

  const verificarMargemGanhoMinimo = () => {
    let temCampoVazio = false;

    if (
      filtro.ganhoMin3 > 0 ||
      filtro.ganhoMin4 > 0 ||
      filtro.ganhoMin5 > 0 ||
      filtro.mensalidadeMedidor > 0 ||
      filtro.adicionalMedidor > 0 ||
      filtro.margem > 0 ||
      filtro.margemTradicional > 0
    ) {
      return temCampoVazio;
    }

    precosSalvar.forEach((submercado) => {
      submercado.precos.forEach((preco) => {
        const precosZerados = preco.items.filter((i) => {
          return (
            ((i.precoConv > 0 ||
              i.precoI5 > 0 ||
              i.precoI1 > 0 ||
              i.swapI5 > 0 ||
              i.swapI1 > 0) &&
              !i.margem &&
              !i.margemTradicional) ||
            !i.ganhoMin3 ||
            !i.ganhoMin4 ||
            !i.ganhoMin5 ||
            !i.adicionalMedidor ||
            !i.maxAdicionalMedidor
          );
        });
        if (precosZerados.length) {
          setModalInformacao(true);
          temCampoVazio = true;
        }
      });
    });

    const mensagemFormatada =
      "Preencher valor das Margens ou Ganho Minímo para os anos.";

    setMensagemModalConfirmacao(mensagemFormatada);

    return temCampoVazio;
  };

  const handleSalvar = async () => {
    if (verificarCampos(false)) return;

    try {
      dispatch(handleLoading(true));

      if (verificarCamposTabela()) {
        store.dispatch(
          alertaExibir({
            tipo: "warning",
            mensagem: "preencha e salve todos os campos."
          })
        );
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        return;
      }

      if (verificarTabela()) {
        store.dispatch(
          alertaExibir({
            tipo: "warning",
            mensagem: "preencha e salve todos os campos."
          })
        );
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        return;
      }

      if (verificarMargemGanhoMinimo()) return;

      const precosParaSalvar = [];
      const dataAtual = new Date();

      precosSalvar.forEach((submercado) => {
        submercado.precos.forEach((preco) => {
          preco.items.forEach((item, index) => {
            dataAtual.setSeconds(dataAtual.getSeconds() + index);
            precosParaSalvar.push({
              ...item,
              ganhoMin3: item.ganhoMin3
                ? item.ganhoMin3
                : parseFloat(String(filtro.ganhoMin3 || 0)),
              ganhoMin4: item.ganhoMin4
                ? item.ganhoMin4
                : parseFloat(String(filtro.ganhoMin4 || 0)),
              ganhoMin5: item.ganhoMin5
                ? item.ganhoMin5
                : parseFloat(String(filtro.ganhoMin5 || 0)),
              mensalidadeMedidor: item.mensalidadeMedidor
                ? item.mensalidadeMedidor
                : parseFloat(String(filtro.mensalidadeMedidor || 0)),
              adicionalMedidor: item.adicionalMedidor
                ? item.adicionalMedidor
                : parseFloat(String(filtro.adicionalMedidor || 0)),
              margem: item.margem
                ? item.margem
                : parseFloat(String(filtro.margem || 0)),
              margemTradicional: item.margemTradicional
                ? item.margemTradicional
                : parseFloat(String(filtro.margemTradicional || 0)),
              comissao: item.comissao
                ? item.comissao
                : parseFloat(String(filtro.comissao || 0)),
              dataInicioVigencia: moment(dataAtual).format(
                "yyyy-MM-DDTHH:mm:ss"
              ),
              dataFimVigencia: "2090-12-31T00:00"
            });
          });
        });
      });
      let dadosSalvos = {};

      if (precosParaSalvar?.length) {
        dadosSalvos = await postarPrecos(precosParaSalvar);
      }

      if (dadosSalvos?.status === 200 || dadosSalvos?.status === 204) {
        store.dispatch(
          alertaExibir({
            tipo: "success",
            mensagem: "Dados salvos com sucesso!"
          })
        );
        buscarPrecos();
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      }
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    } finally {
      dispatch(handleLoading(false));
    }
  };

  const calcularPreco = (preco1, preco2) => {
    return parseFloat(String(preco1)) + parseFloat(String(preco2));
  };

  const alterarValoresPorPlanilha = (valores) => {
    const dadosPreenchidos = valores.filter((item) => item.length);
    const dadosCorretos = dadosPreenchidos.find(
      (item) =>
        item[0]?.toLowerCase() === "submercado" ||
        item[0]?.toLowerCase() === "data"
    );

    const dadosPorData = dadosPreenchidos.filter((dado) =>
      moment(dado[0]?.replaceAll("/", "-"), "MM-DD-YYYY").isValid()
    );

    const dadosColunasIncompletas = dadosPorData.find(
      (dado) => dado.length > 1 && dado.length < 5
    );

    if (dadosColunasIncompletas) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem: "Planilha contém colunas não preenchidas"
        })
      );
      return;
    }

    if (dadosPreenchidos[0][1])
      alterarValorCampo(dadosPreenchidos[0][1], "margem");
    if (dadosPreenchidos[0][2])
      alterarValorCampo(dadosPreenchidos[0][3], "ganhoMin3");
    if (dadosPreenchidos[0][5])
      alterarValorCampo(dadosPreenchidos[0][5], "mensalidadeMedidor");
    if (dadosPreenchidos[1][1])
      alterarValorCampo(dadosPreenchidos[1][1], "margemTradicional");
    if (dadosPreenchidos[1][3])
      alterarValorCampo(dadosPreenchidos[1][3], "ganhoMin4");
    if (dadosPreenchidos[1][5])
      alterarValorCampo(dadosPreenchidos[1][5], "adicionalMedidor");
    if (dadosPreenchidos[2][1])
      alterarValorCampo(dadosPreenchidos[2][1], "comissao");
    if (dadosPreenchidos[2][3])
      alterarValorCampo(dadosPreenchidos[2][3], "ganhoMin5");

    let itensDuplicados = [];

    if (valores?.length && dadosCorretos) {
      const precosAtualizados = precosAgrupados.map((submercado) => {
        return {
          ...submercado,
          precos: submercado.precos.map((preco) => {
            const novosPrecos = preco.items.map((item) => {
              if (item.bloqueado) return item;

              const dadosPorAno = dadosPorData.filter(
                (dado) =>
                  moment(dado[0]?.replaceAll("/", "-"), "MM-DD-YYYY").format(
                    "YYYY"
                  ) === String(item.ano) &&
                  moment(dado[0]?.replaceAll("/", "-"), "MM-DD-YYYY").format(
                    "M"
                  ) === String(item.mes)
              );

              if (dadosPorAno.length > 1)
                itensDuplicados = [...itensDuplicados, ...dadosPorAno];

              return {
                ...item,
                precoConv:
                  dadosPorAno.length === 1 &&
                  dadosPorAno[0][submercado?.submercadoId]
                    ? dadosPorAno[0][submercado?.submercadoId]
                      ? Number(dadosPorAno[0][submercado?.submercadoId])
                      : 0
                    : 0,
                margem: dadosPreenchidos[0][1]
                  ? Number(dadosPreenchidos[1])
                  : item.margem,
                margemTradicional: dadosPreenchidos[1][1]
                  ? Number(dadosPreenchidos[1][1])
                  : item.margemTradicional,
                ganhoMin3: dadosPreenchidos[0][2]
                  ? Number(dadosPreenchidos[0][3])
                  : item.ganhoMin3,
                mensalidadeMedidor: dadosPreenchidos[0][5]
                  ? Number(dadosPreenchidos[0][5])
                  : item.mensalidadeMedidor,
                ganhoMin4: dadosPreenchidos[1][3]
                  ? Number(dadosPreenchidos[1][3])
                  : item.ganhoMin4,
                adicionalMedidor: dadosPreenchidos[1][5]
                  ? Number(dadosPreenchidos[1][5])
                  : item.adicionalMedidor,
                comissao: dadosPreenchidos[2][1]
                  ? Number(dadosPreenchidos[2][1])
                  : item.comissao,
                ganhoMin5: dadosPreenchidos[2][3]
                  ? Number(dadosPreenchidos[2][3])
                  : item.ganhoMin5,
                swapI5:
                  dadosPorAno.length === 1 && dadosPorAno[0][5]
                    ? dadosPorAno[0][5]
                      ? Number(dadosPorAno[0][5])
                      : 0
                    : 0,
                swapI1:
                  dadosPorAno.length === 1 && dadosPorAno[0][6]
                    ? dadosPorAno[0][6]
                      ? Number(dadosPorAno[0][6])
                      : 0
                    : 0,
                precoI5:
                  dadosPorAno.length === 1
                    ? calcularPreco(
                        dadosPorAno[0][submercado?.submercadoId] || 0,
                        dadosPorAno[0][5] || 0
                      )
                    : 0,
                precoI1:
                  dadosPorAno.length === 1
                    ? calcularPreco(
                        dadosPorAno[0][submercado?.submercadoId] || 0,
                        dadosPorAno[0][6] || 0
                      )
                    : 0
              };
            });

            return { ...preco, items: novosPrecos };
          })
        };
      });

      setPrecosAgrupados(precosAtualizados);
      setPrecosSalvar(precosAtualizados);
      habilitarBotaoSalvar();

      if (itensDuplicados.length) {
        const itensDuplicadosFormatados = [];
        itensDuplicados.map((item) => {
          if (!itensDuplicadosFormatados.includes(item[0])) {
            itensDuplicadosFormatados.push(item[0]);
          }

          return item;
        });
        store.dispatch(
          alertaExibir({
            tipo: "warning",
            mensagem: `Planilha contém os seguintes items duplicados: ${itensDuplicadosFormatados.join(
              ", "
            )}`
          })
        );
      }

      store.dispatch(
        alertaExibir({
          tipo: "success",
          mensagem: "Planilha importada com sucesso!"
        })
      );
      dispatch(handleLoading(false));
    } else {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem: "Planilha incorreta"
        })
      );
      dispatch(handleLoading(false));
    }
  };

  const onUploadPlanilha = (arquivo) => {
    dispatch(handleLoading(true));
    try {
      const reader = new FileReader();
      reader.onload = async (evt) => {
        const bstr = evt.target.result;
        const wb = XLSX.read(bstr, { type: "binary" });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(ws, { header: 1, raw: false });
        await alterarValoresPorPlanilha(data);
      };
      reader.readAsBinaryString(arquivo);
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
      dispatch(handleLoading(false));
    }
  };

  const onDownloadPlanilha = async () => {
    dispatch(handleLoading(true));
    try {
      const resposta = await downloadPlanilhaPrecos();

      if (resposta === "Sucesso") {
        store.dispatch(
          alertaExibir({
            tipo: "success",
            mensagem: "Planilha baixada com sucesso!"
          })
        );
      }
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );

      dispatch(handleLoading(false));
    }
  };

  useEffect(() => {
    buscarPrecos();
    setInitialState(true);
  }, []);

  useEffect(() => {
    habilitarBotaoSalvar();
  }, [precosSalvar]);

  return (
    <CustomLoader>
      <ModalConfirmacao
        item={modalInformacao}
        mensagem={mensagemModalInformacao}
        onCancelar={() => setModalInformacao(false)}
        esconderBotaoConfirmar
      />
      <form className="needs-validation" onSubmit={handleSubmit(() => {})}>
        <Grid container>
          <Grid container item xs={12}>
            <Grid container item xs={12} sm={12} md={12} lg={5}>
              <HtmlTooltip
                placement="top"
                title={
                  !editarInputs ? "" : "Selecione o ano para preenchimento"
                }
              >
                <Grid item lg={3} md={3} sm={3} xs={12}>
                  <SelectArredondado
                    select
                    id="ano"
                    name="ano"
                    label="ano"
                    placeholder="ano"
                    valueKey="valor"
                    valueName="nome"
                    className={classes.select}
                    labelClassName={classes.label}
                    dataSource={anos}
                    value={filtro.ano}
                    onChange={(e) => {
                      setFiltro((prevState) => ({
                        ...prevState,
                        ano: e.target.value
                      }));

                      setLimparCampo(true);
                      setSubmercadoEspelho("");
                      clearErrors();
                    }}
                    styleForm={false}
                    ref={register(
                      { name: "ano" },
                      {
                        required: !filtro.ano ? "Campo obrigatório!" : false
                      }
                    )}
                    errors={errors}
                    allowClear
                    disabled={!editarInputs}
                  />
                </Grid>
              </HtmlTooltip>
              <HtmlTooltip
                placement="top"
                title="Selecione o submercado para inserção dos dados"
              >
                <Grid item lg={5} md={5} sm={5} xs={12}>
                  <SelectArredondado
                    select
                    id="submercadoId"
                    name="submercadoId"
                    label="submercado"
                    placeholder="submercado"
                    valueKey="valor"
                    valueName="nome"
                    className={classes.select}
                    labelClassName={classes.label}
                    dataSource={[
                      { nome: "Sudeste", valor: 1 },
                      { nome: "Sul", valor: 2 },
                      { nome: "Nordeste", valor: 3 },
                      { nome: "Norte", valor: 4 }
                    ]}
                    value={filtro.submercadoId}
                    onChange={(e) => {
                      setFiltro((prevState) => ({
                        ...prevState,
                        submercadoId: e.target.value,
                        ano: "",
                        swapI5: "",
                        swapI1: ""
                      }));
                      setSubmercadoEspelho("");
                      setLimparCampo(true);
                      clearErrors();
                    }}
                    styleForm={false}
                    ref={register(
                      { name: "submercado" },
                      {
                        required: !filtro.submercadoId
                          ? "Campo obrigatório!"
                          : false
                      }
                    )}
                    errors={errors}
                  />
                </Grid>
              </HtmlTooltip>
              <HtmlTooltip
                placement="top"
                title={
                  !editarInputs ? "" : "Indique o valor do Swap para replicação"
                }
              >
                <Grid item lg={4} md={4} sm={4} xs={12}>
                  <MaterialInputMascara
                    type="text"
                    id="swapI5"
                    name="swapI5"
                    defaultValue={filtro.swapI5}
                    label="Swap I5 R$"
                    className={classes.input}
                    renderIconShowHide={false}
                    onInputChange={(valor) =>
                      alterarValorCampo(valor, "swapI5")
                    }
                    onKeyDown={(e) => alterarValores(e)}
                    errors={errors}
                    limparValor={limparCampo}
                    disabled={!editarInputs}
                  />
                </Grid>
              </HtmlTooltip>
            </Grid>
            <Grid container item lg={7} md={12} sm={12} xs={12}>
              <HtmlTooltip
                placement="top"
                title={
                  !editarInputs ? "" : "Indique o valor do Swap para replicação"
                }
              >
                <Grid item lg={3} md={3} sm={3} xs={12}>
                  <MaterialInputMascara
                    type="text"
                    id="swapI1"
                    name="swapI1"
                    defaultValue={filtro.swapI1}
                    label="Swap I1 R$"
                    className={classes.input}
                    renderIconShowHide={false}
                    onInputChange={(valor) =>
                      alterarValorCampo(valor, "swapI1")
                    }
                    onKeyDown={(e) => alterarValores(e)}
                    errors={errors}
                    limparValor={limparCampo}
                    disabled={!editarInputs}
                  />
                </Grid>
              </HtmlTooltip>
              <HtmlTooltip
                placement="top"
                title={
                  !editarInputs ? "" : "Indique o valor da mensalidade medidor"
                }
              >
                <Grid item lg={3} md={3} sm={3} xs={12}>
                  <MaterialInputMascara
                    type="text"
                    id="mensalidadeMedidor"
                    name="mensalidadeMedidor"
                    defaultValue={filtro.mensalidadeMedidor}
                    label="Mensalidade Medidor R$"
                    className={classes.input}
                    renderIconShowHide={false}
                    onInputChange={(valor) =>
                      alterarValorCampo(valor, "mensalidadeMedidor")
                    }
                    onKeyDown={(e) => alterarValoresMargemGanhoMin(e)}
                    errors={errors}
                    disabled={!editarInputs}
                  />
                </Grid>
              </HtmlTooltip>
              <HtmlTooltip
                placement="top"
                title={
                  !editarInputs ? "" : "Indique o valor do adicional medidor"
                }
              >
                <Grid item lg={3} md={3} sm={3} xs={12}>
                  <MaterialInputMascara
                    type="text"
                    id="adicionalMedidor"
                    name="adicionalMedidor"
                    defaultValue={filtro.adicionalMedidor}
                    label="Adicional Medidor R$"
                    className={classes.input}
                    renderIconShowHide={false}
                    onInputChange={(valor) =>
                      alterarValorCampo(valor, "adicionalMedidor")
                    }
                    onKeyDown={(e) => alterarValoresMargemGanhoMin(e)}
                    errors={errors}
                    disabled={!editarInputs}
                  />
                </Grid>
              </HtmlTooltip>
              <Grid item lg={3} md={3} sm={3} xs={12}>
                <InputBlock
                  type="text"
                  id="ultimaAtualizacao"
                  name="ultimaAtualizacao"
                  label="Última atualização"
                  value={ultimaAtualizacao}
                  className="w-100 pr-2 pb-2"
                  readOnly
                  disabled
                />
              </Grid>
            </Grid>
            <Grid container item lg={12} sm={12} xs={12}>
              <HtmlTooltip
                placement="top"
                title={
                  !editarInputs
                    ? ""
                    : "Indique o valor da margem atacadista para replicação"
                }
              >
                <Grid item lg={2} md={4} sm={4} xs={12}>
                  <MaterialInputMascara
                    type="text"
                    id="margemTradicional"
                    name="margemTradicional"
                    defaultValue={filtro.margemTradicional}
                    label="Margem Atacadista R$"
                    className={classes.input}
                    renderIconShowHide={false}
                    onInputChange={(valor) =>
                      alterarValorCampo(valor, "margemTradicional")
                    }
                    onKeyDown={(e) => alterarValoresMargemGanhoMin(e)}
                    errors={errors}
                    decimalScale={2}
                    disabled={!editarInputs}
                  />
                </Grid>
              </HtmlTooltip>
              <HtmlTooltip
                placement="top"
                title={
                  !editarInputs
                    ? ""
                    : "Indique o valor da margem para replicação"
                }
              >
                <Grid item lg={2} md={4} sm={4} xs={12}>
                  <MaterialInputMascara
                    type="text"
                    id="margem"
                    name="margem"
                    defaultValue={filtro.margem}
                    label="Margem Varejista R$"
                    className={classes.input}
                    renderIconShowHide={false}
                    onInputChange={(valor) =>
                      alterarValorCampo(valor, "margem")
                    }
                    onKeyDown={(e) => alterarValoresMargemGanhoMin(e)}
                    errors={errors}
                    decimalScale={2}
                    disabled={!editarInputs}
                  />
                </Grid>
              </HtmlTooltip>
              <HtmlTooltip
                placement="top"
                title={
                  !editarInputs
                    ? ""
                    : "Indique o valor da comissão para replicação"
                }
              >
                <Grid item lg={2} md={4} sm={4} xs={12}>
                  <MaterialInputMascara
                    type="text"
                    id="comissao"
                    name="comissao"
                    defaultValue={filtro.comissao}
                    label="Comissão R$"
                    className={classes.input}
                    renderIconShowHide={false}
                    onInputChange={(valor) =>
                      alterarValorCampo(valor, "comissao")
                    }
                    onKeyDown={(e) => alterarValoresMargemGanhoMin(e)}
                    errors={errors}
                    decimalScale={2}
                    disabled={!editarInputs}
                  />
                </Grid>
              </HtmlTooltip>
              <HtmlTooltip
                placement="top"
                title={
                  !editarInputs
                    ? ""
                    : "Indique o valor da ganho minímo para replicação"
                }
              >
                <Grid item lg={2} md={4} sm={4} xs={12}>
                  <MaterialInputMascara
                    type="text"
                    id="ganhoMin3"
                    name="ganhoMin3"
                    defaultValue={filtro.ganhoMin3}
                    label="Ganho Min 3 R$"
                    className={classes.input}
                    renderIconShowHide={false}
                    onInputChange={(valor) =>
                      alterarValorCampo(valor, "ganhoMin3")
                    }
                    onKeyDown={(e) => alterarValoresMargemGanhoMin(e)}
                    errors={errors}
                    disabled={!editarInputs}
                  />
                </Grid>
              </HtmlTooltip>
              <HtmlTooltip
                placement="top"
                title={
                  !editarInputs
                    ? ""
                    : "Indique o valor da ganho minímo para replicação"
                }
              >
                <Grid item lg={2} md={4} sm={4} xs={12}>
                  <MaterialInputMascara
                    type="text"
                    id="ganhoMin4"
                    name="ganhoMin4"
                    defaultValue={filtro.ganhoMin4}
                    label="Ganho Min 4 R$"
                    className={classes.input}
                    renderIconShowHide={false}
                    onInputChange={(valor) =>
                      alterarValorCampo(valor, "ganhoMin4")
                    }
                    onKeyDown={(e) => alterarValoresMargemGanhoMin(e)}
                    errors={errors}
                    disabled={!editarInputs}
                  />
                </Grid>
              </HtmlTooltip>
              <HtmlTooltip
                placement="top"
                title={
                  !editarInputs
                    ? ""
                    : "Indique o valor da ganho minímo para replicação"
                }
              >
                <Grid item lg={2} md={4} sm={4} xs={12}>
                  <MaterialInputMascara
                    type="text"
                    id="ganhoMin5"
                    name="ganhoMin5"
                    defaultValue={filtro.ganhoMin5}
                    label="Ganho Min 5 R$"
                    className={classes.input}
                    renderIconShowHide={false}
                    onInputChange={(valor) =>
                      alterarValorCampo(valor, "ganhoMin5")
                    }
                    onKeyDown={(e) => alterarValoresMargemGanhoMin(e)}
                    errors={errors}
                    disabled={!editarInputs}
                  />
                </Grid>
              </HtmlTooltip>
            </Grid>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <p className={classes.info}>
              Selecione 1° o ano e submercado desejado para replicar ou
              espelhar!
            </p>
          </Grid>
          <Grid item lg={3} md={3} sm={6} xs={12} className={classes.botaoGrid}>
            <HtmlTooltip
              title={
                !editarInputs
                  ? ""
                  : "Replique os valores do primeiro mês/ano desbloqueado preenchido."
              }
            >
              <BotaoFiltro
                type="button"
                disabled={!editarInputs}
                onClick={() => replicarValores()}
              >
                Replicar Valores (12 meses)
              </BotaoFiltro>
            </HtmlTooltip>
          </Grid>
          <Grid item lg={3} md={3} sm={6} xs={12} className={classes.botaoGrid}>
            <InputFile
              type="file"
              id="uploadPlanilha"
              name="uploadPlanilha"
              label="Importar Planilha"
              className={classes.inputFile}
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              onUpload={(e) => onUploadPlanilha(e?.target?.files[0])}
            />
          </Grid>
          <Grid item lg={3} md={3} sm={6} xs={12} className={classes.botaoGrid}>
            <DownloadButton
              type="file"
              id="uploadPlanilha"
              label="Baixar Planilha"
              onDownload={() => onDownloadPlanilha()}
            />
          </Grid>
          <HtmlTooltip
            title={
              !editarInputs ? "" : "Escolha o Submercado que deseja replicar"
            }
          >
            <Grid item lg={3} md={3} sm={6} xs={12} alignItems="flex-end">
              <SelectSubmercado
                select
                id="espelharSubmercado"
                name="espelharSubmercado"
                label="Espelhar Submercado"
                placeholder="Espelhar Submercado"
                valueKey="valor"
                valueName="nome"
                className={classes.select}
                dataSource={[
                  { nome: "Sudeste", valor: 1 },
                  { nome: "Sul", valor: 2 },
                  { nome: "Nordeste", valor: 3 },
                  { nome: "Norte", valor: 4 }
                ]}
                value={submercadoEspelho}
                onChange={(e) => {
                  setSubmercadoEspelho(e.target.value);
                  espelharSubmercado(e.target.value);
                }}
                customSelect
                allowClear
                disabled={!editarInputs}
              />
            </Grid>
          </HtmlTooltip>
        </Grid>
      </form>
      <Grid className="my-3">
        {precosAgrupados.length > 0 && initialState
          ? precosAgrupados
              .find(
                (submercado) =>
                  submercado.submercadoId === Number(filtro.submercadoId)
              )
              .precos?.map((preco, index) => (
                <TabelaPrecoAtual
                  key={preco.ano}
                  linhas={preco.items}
                  salvarPrecos={(linha) => atualizarPreco(linha)}
                  erro={temErro}
                  index={index}
                  onEditar={(campo) => setCampoEditar(campo)}
                  editarInputs={editarInputs}
                  itemAberto={itemAberto}
                  setItemAberto={setItemAberto}
                />
              ))
          : null}

        <Grid container className="m-3 w-100">
          <Grid item md={3}>
            <Botao
              label="Salvar"
              disabled={botaoDesabilitado}
              onClick={() => handleSalvar()}
            />
          </Grid>
        </Grid>
      </Grid>
    </CustomLoader>
  );
};

export default Atual;
