import RetornoEndpointDto from "../../../global/dto/retornoEndpointDto";
import { listarPaginado } from "../../../servicos/listagemCotacoesServico";
import { contratarCotacao } from "../../../servicos/demandaAtendServico";
import { obterTodos } from "../../../servicos/tipoPlanoCotacaoServico";
import { alertaExibir } from "../../../global/redux/modulos/alertas/actions";
import { atualizarDesconto } from "../../../servicos/listagemCotacoesServico";
import { validandoDesconto } from "../../../servicos/validaDescontoServico";
import { store } from "../../../global/redux";
export default class ListagemCotacaoHelper {

  static async validandoDesconto(id, desconto, consumidorLivre, valorMedioConta, cotacaoSelecionada, dataVigencia, dataMigracao) {
    try {
      const validando = await validandoDesconto(id, desconto, consumidorLivre, valorMedioConta, cotacaoSelecionada, dataVigencia,dataMigracao);  
      return RetornoEndpointDto.Sucesso(
        "Calculo de desconto efetuado com sucesso",
        validando.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro(
        "Ocorreu um erro ao retornar o desconto"
      );
    }
  }

  static async atualizarDesconto(desconto, id, economiaAnual, economiaMensal, economiaTotal,dataMigracao) {
    try {
      const atualizando = await atualizarDesconto(desconto, id, economiaAnual, economiaMensal, economiaTotal,dataMigracao);
      
      return RetornoEndpointDto.Sucesso(
        "Desconto atualizado com sucesso",
        atualizando.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro(
        "Ocorreu um erro ao atualizar o desconto"
      );
    }
  }

  static async listarCotacoes(filtro) {
    try {
      const resultado = await listarPaginado(filtro);

      return RetornoEndpointDto.Sucesso(
        "Listagem de cotações obtidas com sucesso",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro(
        "Ocorreu um erro ao obter a lista de cotações"
      );
    }
  }

  static async contratarCotacao(id) {
    try {
      const resultado = await contratarCotacao(id);

      return RetornoEndpointDto.Sucesso(
        "Cotação contratada com sucesso",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro("Ocorreu um erro ao contratar a cotação");
    }
  }

  static async listarTodosTipos() {
    try {
      const resultado = await obterTodos();

      return RetornoEndpointDto.Sucesso(
        "Listagem de tipo de cotações obtidas com sucesso",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro(
        "Ocorreu um erro ao obter a lista de tipos de cotações"
      );
    }
  }

  static exibirErro(mensagem) {
    store.dispatch(
      alertaExibir({
        tipo: "danger",
        mensagem
      })
    );
  }

  static exibirSucesso(mensagem) {
    store.dispatch(
      alertaExibir({
        tipo: "success",
        mensagem
      })
    );
  }
}
