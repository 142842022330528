import { makeStyles } from '@mui/styles';
import theme from "themes";

export const useStyles = makeStyles(() => ({
  formControl: {
    fontFamily: theme.font.family,
    "& .MuiInputBase-root.Mui-disabled": {
      color: theme.color.primaryBackgroud
    }
  },
  select: {
    "& .MuiOutlinedInput-root": {
      borderColor: theme.color.borderColor,
      borderRadius: "50rem",

      maxHeight: "30px",
      "& .MuiSelect-outlined.MuiSelect-outlined": {
        padding: "10px",
        textAlign: "left"
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: `${theme.color.borderColor} !important`
      }
    },
    "& .MuiInputLabel-root": {
      color: theme.color.borderColor,
      transform: "translate(15px, 6px) scale(1)",
      "&.Mui-focused": {
        transform: "translate(14px, -6px) scale(0.75)"
      }
    },
    "& .MuiSelect-icon": {
      backgroundColor: theme.color.borderColor,
      borderRadius: "50%",
      color: theme.color.primary,
      right: "4px"
    },
    "& .Mui-disabled": {
      opacity: "50%"
    }
  },
  clearButton: {
    marginRight: "15px"
  }
}));
