import React from "react";
import TextField from "@mui/material/TextField";
import PropTypes from "prop-types";
import { useStyles } from "./style";

const InputTexto = React.forwardRef((props, ref) => {
  const classes = useStyles();

  const { type, id, name, defaultValue, disabled, label, onBlur } = props;

  return (
    <TextField
      type={type}
      name={name}
      id={id}
      label={label}
      defaultValue={defaultValue}
      onBlur={onBlur}
      disabled={disabled}
      inputRef={ref}
      classes={{ root: classes.root }}
      focused
    />
  );
});

InputTexto.propTypes = {
  type: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  onBlur: PropTypes.oneOfType([PropTypes.func])
};

InputTexto.defaultProps = {
  type: "",
  id: "",
  name: "",
  defaultValue: "",
  disabled: false,
  label: "",
  onBlur: () => {}
};

export default InputTexto;
