import styled from "styled-components";
import { Base } from "../../../../componentes/cores";
import theme from 'themes';

// Imagens
import imgEconomia from "../../../../assets/home/economia.png";
import bgEconomia from "../../../../assets/home/bgEconomia.png";

import imgConsumo from "../../../../assets/home/consumo.png";
import bgConsumo from "../../../../assets/home/bgConsumo.png";

import bgMedicao from "../../../../assets/home/bgMedicao.png";
import imgMedicao from "../../../../assets/home/medicao.png";

import bgInfoCadastro from "../../../../assets/home/bgInfoCadastro.png";

import iconLinkedin from "../../../../assets/home/iconLinkedin.png";
import iconDelta from "../../../../assets/home/iconDelta.png";

// Imagens mobile
import bgEconomiaMobile from "../../../../assets/home/bg-economia.png";
import bgFinanceiroMobile from "../../../../assets/home/bg-financeiro.png";
import bgConsumoMobile from "../../../../assets/home/bg-consumo.png";
import bgRelatoriosMobile from "../../../../assets/home/bg-relatorios.png";


import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  bordaAmarela: {
    boxShadow: "2px 2px 9px #FFCC00",
    borderRight: "2px solid #FFCC00",
    borderBottom: "2px solid #FFCC00"
  },
  cursorPointer: {
    cursor: "pointer"
  },
  dadosUsinas: {
    width: 240,
    height: 190,
    color: "white",
    fontSize: 11
  },
  boxUsina: {
    borderWidth: 1,
    borderColor: 'white',
    borderStyle: 'solid',
    borderRadius: 5,
    textAlign: 'left',
    padding: 12,
    paddingLeft: 10,
    width: 250,
    zIndex: 200,
    position: "relative",
    backgroundColor: "#2d2d2c",
    "& img": {
      zIndex: 200
    }
  },
  nomeUsina:{
    fontSize: 14,
    color: 'white'
  },
  iconesDashboard: {
    float: 'right',
    marginRight: '20%'
  },
  usinaFechada:{
    height: 50
  },
  usinaAberta:{
    height: 250
  },
  linhaTresLagoas:{
    position: 'absolute',
    marginTop: '-120px',
    marginLeft: 245,
    zIndex: 99,
  },
  linhaLagoaBonita:{
    position: 'absolute',
    marginTop: '-167px',
    marginLeft: 221,
    zIndex: 99,
  },
  linhaSantoInacio:{
    position: 'absolute',
    marginTop: '-91px',
    marginLeft: 241,
    zIndex: 99,
  },
  linhaItapolis:{
    position: 'absolute',
    marginTop: '-59px',
    marginLeft: 244,
    zIndex: 99,
  },
  linhaBarretos:{
    position: 'absolute',
    marginTop: '-226px',
    marginLeft: 214,
    zIndex: 99,
  },
  linhaAcacia:{
    position: 'absolute',
    marginTop: '-291px',
    marginLeft: 236,
    zIndex: 99,
  },
  linhaPindamonhangaba:{
    position: 'absolute',
    marginTop: '-170px',
    marginLeft: '-196px',
    zIndex: 99,
  },
  linhaAdamantina:{
    position: 'absolute',
    marginTop: '-88px',
    marginLeft: '-264px',
    zIndex: 99,
  },
  linhaCarmo:{
    position: 'absolute',
    marginTop: '-81px',
    marginLeft: '-141px',
    zIndex: 99,
  },
  linhaCampoGrande:{
    position: 'absolute',
    marginTop: '-99px',
    marginLeft: '-328px',
    zIndex: 99,
  },
  linhaBrauna:{
    position: 'absolute',
    marginTop: '-147px',
    marginLeft: '-252px',
    zIndex: 99,
  },
  mapaHome:{
    marginTop: 0,
    marginLeft: 170,
  },
  bgMapa:{
    width: 1005,
    height: 630,
    textAlign: 'center',
    marginLeft: '5%',
  },
  btnLagoaBonita:{
    position: 'absolute',
    marginTop: 485,
    marginLeft: 64,
  },
  btnSantoInacio:{
    position: 'absolute',
    marginTop: 485,
    marginLeft: 997,
  },
  btnTresLagoas:{
    position: 'absolute',
    marginTop: 185,
    marginLeft: 64,
  },
  btnItapolis:{
    position: 'absolute',
    marginTop: 180,
    marginLeft: 997,
  },
  margin50Menos:{
    marginLeft: '-40px'
  }
}))

export const Economia = styled.div`
  align-items: center;
  background: url(${bgEconomia}) center center no-repeat;
  background-size: cover;
  cursor: pointer;
  display: flex;
  height: 445px;
  justify-items: center;
  margin-bottom: 30px;
  text-decoration: none;
  width: 97%;
  &:hover {
    text-decoration: none;
  }
  @media (max-width: 1366px) {
    height: 290px;
    width: 97%;
  }
  @media (max-width: 1024px) {
    height: 290px;
    width: 97%;
  }
  @media (max-width: 600px) {
    height: 120px;
    width: 97%;
    margin-bottom: 10px;
  }
`;

export const TextoEconomia = styled.div`
  background: url(${imgEconomia}) left center no-repeat;
  color: ${theme.color.fourthBackgroud};
  font-size: 34px;
  height: 80px;
  margin: 0 auto;
  padding-left: 96px;
  padding-top: 10px;
  width: 288px;
`;

export const Consumo = styled.div`
  align-items: center;
  background: url(${bgConsumo}) center center no-repeat;
  background-size: cover;
  cursor: pointer;
  display: flex;
  height: 445px;
  justify-items: center;
  text-decoration: none;
  max-width: 97%;
  &:hover {
    text-decoration: none;
  }
  @media (max-width: 1366px) {
    height: 290px;
    width: 97%;
  }
  @media (max-width: 1024px) {
    height: 290px;
    width: 97%;
  }
  @media (max-width: 600px) {
    height: 120px;
    width: 97%;
    margin-bottom: 10px;
  }
`;

export const TextoConsumo = styled.div`
  background: url(${imgConsumo}) left center no-repeat;
  color: ${theme.color.fourthBackgroud};
  font-size: 34px;
  height: 76px;
  margin: 0 auto;
  padding-left: 96px;
  padding-top: 10px;
  width: 258px;
`;

export const MedicaoConsolidada = styled.div`
  align-items: center;
  background: url(${bgMedicao}) center center no-repeat;
  background-size: cover;
  cursor: pointer;
  display: flex;
  height: 637px;
  justify-items: center;
  margin-bottom: 30px;
  text-decoration: none;
  width: 95%;
  &:hover {
    text-decoration: none;
  }
  @media (max-width: 1366px) {
    height: 410px;
    width: 97%;
  }
  @media (max-width: 1024px) {
    height: 385px;
    width: 97%;
  }
  @media (max-width: 600px) {
    height: 120px;
    width: 97%;
    margin-bottom: 15px;
  }
`;

export const TextoMedicao = styled.div`
  background: url(${imgMedicao}) left center no-repeat;
  color: ${theme.color.fourthBackgroud};
  font-size: 28px;
  height: 96px;
  margin: 0 auto;
  padding-left: 80px;
  padding-top: 10px;
  width: 228px;
  @media (max-width: 1366px) {
    font-size: 26px;
  }
  @media (max-width: 1024px) {
    font-size: 24px;
  }
`;

export const InformacoesCadastro = styled.div`
  align-items: center;
  background: url(${bgInfoCadastro}) center center no-repeat;
  background-size: cover;
  display: flex;
  height: 252px;
  justify-items: center;
  text-decoration: none;
  width: 98%;
  &:hover {
    text-decoration: none;
  }
  @media (max-width: 1366px) {
    height: 172px;
    width: 99%;
  }
  @media (max-width: 1024px) {
    width: 95%;
    height: 195px;
  }
  @media (max-width: 600px) {
    width: 100%;
    height: 145px;
  }
`;

export const TextoInformacoes = styled.div`
  color: ${theme.color.fourthBackgroud};
  font-size: 24px;
  height: 96px;
  margin: 0 auto;
  width: 150px;
`;

export const LinkedIn = styled.a`
  align-items: center;
  background: ${Base.OffWhite} url(${iconLinkedin}) center center no-repeat;
  background-size: cover;
  display: flex;
  height: 111px;
  justify-items: center;
  margin-bottom: 30px;
  width: 91px;
  @media (max-width: 1366px) {
    height: 75px !important;
    width: 61px !important;
    margin-bottom: 22px;
    margin-right: 12% !important;
  }
  @media (max-width: 1024px) {
    height: 81px !important;
    width: 51px !important;
    margin-bottom: 33px;
    margin-right: 12% !important;
  }
  @media (max-width: 600px) {
    height: 61px !important;
    width: 51px !important;
    margin-bottom: 22px;
    margin-right: 12% !important;
  }
`;

export const Site = styled.a`
  align-items: center;
  background: ${Base.OffWhite} url(${iconDelta}) center center no-repeat;
  background-size: cover;
  display: flex;
  height: 111px;
  justify-items: center;
  width: 91px;
  @media (max-width: 1366px) {
    height: 75px !important;
    width: 61px !important;
    margin-right: 12% !important;
  }
  @media (max-width: 1024px) {
    height: 81px !important;
    width: 51px !important;
    margin-right: 12% !important;;
  }
  @media (max-width: 600px) {
    height: 61px !important;
    width: 51px !important;
  }
`;



export const EconomiaMobile = styled.div`
  align-items: center;
  background: url(${bgEconomiaMobile}) center center no-repeat;
  background-size: cover;
  cursor: pointer;
  display: flex;
  height: 445px;
  justify-items: center;
  margin-bottom: 30px;
  text-decoration: none;
  width: 100%;
  &:hover {
    text-decoration: none;
  }
  @media (max-width: 1366px) {
    height: 290px;
    width: 100%;
  }
  @media (max-width: 1024px) {
    height: 290px;
    width: 100%;
  }
  @media (max-width: 600px) {
    height: 140px;
    width: 97%;
    width: 100%;
  }
`;

export const FinanceiroMobile = styled.div`
  align-items: center;
  background: url(${bgFinanceiroMobile}) center center no-repeat;
  background-size: cover;
  cursor: pointer;
  display: flex;
  height: 445px;
  justify-items: center;
  margin-bottom: 30px;
  text-decoration: none;
  width: 100%;
  &:hover {
    text-decoration: none;
  }
  @media (max-width: 1366px) {
    height: 290px;
    width: 100%;
  }
  @media (max-width: 1024px) {
    height: 290px;
    width: 100%;
  }
  @media (max-width: 600px) {
    height: 140px;
    width: 97%;
    width: 100%;
  }
`;

export const ConsumoMobile = styled.div`
  align-items: center;
  background: url(${bgConsumoMobile}) center center no-repeat;
  background-size: cover;
  cursor: pointer;
  display: flex;
  height: 445px;
  justify-items: center;
  margin-bottom: 30px;
  text-decoration: none;
  width: 100%;
  &:hover {
    text-decoration: none;
  }
  @media (max-width: 1366px) {
    height: 290px;
    width: 100%;
  }
  @media (max-width: 1024px) {
    height: 290px;
    width: 100%;
  }
  @media (max-width: 600px) {
    height: 140px;
    width: 97%;
    width: 100%;
  }
`;

export const RelatoriosMobile = styled.div`
  align-items: center;
  background: url(${bgRelatoriosMobile}) center center no-repeat;
  background-size: cover;
  cursor: pointer;
  display: flex;
  height: 445px;
  justify-items: center;
  margin-bottom: 30px;
  text-decoration: none;
  width: 100%;
  &:hover {
    text-decoration: none;
  }
  @media (max-width: 1366px) {
    height: 290px;
    width: 100%;
  }
  @media (max-width: 1024px) {
    height: 290px;
    width: 100%;
  }
  @media (max-width: 600px) {
    height: 140px;
    width: 97%;
    width: 100%;
  }
`;


export const TextoMobile = styled.div`
  text-align: center;
  color: ${theme.color.textoBarra};
  font-size: 28px;
  height: 80px;
  margin: 0 auto;
  padding-top: 15px;
  width: 288px;
`;