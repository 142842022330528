import styled from "styled-components";
import { Base } from "../cores";
import theme from 'themes';

export const Box = styled.div`
  display: inline-flex;
  max-height: 15px;
  line-height: 10px;
`;

export const StatusColor = styled.div`
  width: 20px;
  height: 10px;
  background-color: ${(props) => props.bgColor};
  border-radius: 3px;
  margin: 0 5px;
`;

export const Legenda = styled.p`
  color: ${theme.color.labelColorGrafico};
  font-size: ${(props) => props.size};
`;
