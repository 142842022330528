import { makeStyles } from "@mui/styles";
import themes from "themes";
import { createTheme } from "@mui/material/styles";

const theme = createTheme();

export const useStyles = makeStyles(() => ({
  container: {
    backgroundColor: "#FFFFFF",
    border: "0 none",
    borderRadius: "10px",
    boxShadow: theme.shadows[5],
    left: "50%",
    outline: "none",
    position: "absolute",
    transform: "translate(-50%, -50%)",
    top: "50%",
    width: "70% !important",
    color: "#FFFFFF",
    [theme.breakpoints.down("sm")]: {
      width: "98% !important",
    },
  },
  button: {
    color: `${themes.color.secondaryText} !important`,
    width: "100%",
    justifyContent: "center",
    justifyItems: "center"
  },

  header: {
    color: themes.color.secondary,
    padding: "20px 0px 0px 20px",
    fontWeight: "bold",
    fontSize: "18px !important"
  }
}));
