import React from "react";
import { Link } from "react-router-dom";

// Componentes
import { MenuItem } from "@mui/material";
import CardItemNotificacao from "./cardItemNotificacao";

// Styles
import { useStyles, Mensagens } from "./style";

const ListaNotificacoes = (props) => {
  const classes = useStyles();

  return (
    <Mensagens>
      {props.lista?.length
        ? props.lista.map((mensagem) => {
          return (
            <MenuItem
              key={mensagem.id}
              className={classes.notificacaoItem}
            >
              <Link className={classes.footerLink} to={mensagem.rota + "?" + mensagem.parametrosUrl} >
                <CardItemNotificacao titulo={mensagem.titulo}>
                  {mensagem.textoCorpo} - {mensagem.dataCriacaoFormatada}
                </CardItemNotificacao>
              </Link>
            </MenuItem>
          );
        })
        : null}
    </Mensagens>
  );
};

export default ListaNotificacoes;
