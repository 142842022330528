import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import PropTypes from "prop-types";
import {
  formataMoedaReais,
  formatarBrasileira
} from "../../../../servicos/utils";

const InputTexto = React.forwardRef((props, ref) => {
  const {
    type,
    id,
    name,
    defaultValue,
    disabled,
    label,
    onBlur,
    className
  } = props;

  const [values, setValues] = useState(null);

  useEffect(() => {
    setValues(formataMoedaReais(defaultValue));
  }, [defaultValue]);

  const handleBlur = (event) => {
    onBlur(event);
  };

  const handleChange = (event) => {
    setValues(formatarBrasileira(event.target.value));
  };

  return (
    <TextField
      type={type}
      name={name}
      id={id}
      label={label}
      onBlur={handleBlur}
      onChange={handleChange}
      disabled={disabled}
      inputRef={ref}
      classes={{ root: className }}
      focused={false}
      value={values || ""}
    />
  );
});

InputTexto.propTypes = {
  type: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  onBlur: PropTypes.oneOfType([PropTypes.func]),
  className: PropTypes.oneOfType([PropTypes.any])
};

InputTexto.defaultProps = {
  type: "",
  id: "",
  name: "",
  defaultValue: "",
  disabled: false,
  label: "",
  onBlur: () => {},
  className: ""
};

export default InputTexto;
