import shortid from "shortid";

export default class RepresentanteLegalDto {
  constructor(nomeCompleto, RG, CPF, email, telefone, id, nacionalidade, estadoCivil) {
    this.nomeCompleto = nomeCompleto;
    this.RG = RG;
    this.CPF = CPF;
    this.email = email;
    this.telefone = telefone;
    this.id = id;
    this.chaveUnica = shortid.generate();
    this.nacionalidade = nacionalidade ?? 'Brasileira';
    this.estadoCivil = estadoCivil;
  }
}
