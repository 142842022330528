import { makeStyles } from '@mui/styles';
import theme from "../../../../../themes";

export const useStyles = makeStyles({
  container: {
    margin: "0",
    width: "100%",
    "& .MuiInputBase-root": {
      height: "46px"
    },
    "& .MuiFormLabel-root": {
      lineHeight: "1"
    }
  },
  cardCadastro: {
    backgroundColor: theme.color.bgColorCardCadastro
  },
  inputRadio: {
    marginBottom: "-16px"
  },
  inputRadioLabel: {
    color: `${theme.color.radioButton} !important`
  },
  botaoCamera: {
    display: "flex",
    textAlign: "center",
    width: "100%",
    height: "44px",
    textTransform: "inherit !important",
    color: `${theme.color.buttonColor} !important`,
    "& svg": {
      marginRight: "20px",
      color: `${theme.color.secondary} !important`
    }
  },
  tituloModal: {
    backgroundcolor: "primary",
    backgroundImage: "linear-gradient(90deg,#F722B5 0%,#FD9507 100%)",
    backgroundSize: "100%",
    backgroundRepeat: "repeat",
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent"
  }
});
