import React, { useState, useEffect } from "react";
import moment from "moment";

// Componentes
import { Grid } from "@mui/material";
import { SelectArredondado, Loader } from "componentes";
import InputBlock from "../Componentes/InputBlock";
import TabelaPrecoAnual from "../Componentes/TabelaPrecoAnual";
import { HtmlTooltip } from "../../Graficos/ConsumoAtual/style";
import { anos } from "../anos";

// Redux
import { store } from "../../../global/redux";
import { alertaExibir } from "../../../global/redux/modulos/alertas/actions";

// Serviços
import {
  listarPrecos,
  listarPrecosAnuais
} from "../../../servicos/precoServico";

// Form Hooks
import { useForm } from "react-hook-form";

// Styles
import { useStyles } from "./style";

const Anual = () => {
  const classes = useStyles();
  const date = new Date();
  const { handleSubmit } = useForm({
    reValidateMode: "onChange"
  });
  const [filtro, setFiltro] = useState({
    ano: "",
    submercadoId: 1,
    indice: "IPCA",
    dataBase: new Date(date.getFullYear(), date.getMonth(), 1)
  });
  const [ultimaAtualizacao, setUltimaAtualizacao] = useState("");
  const [carregando, setCarregando] = useState(false);
  const [initialState, setInitialState] = useState(false);
  const [precosAgrupados, setPrecosAgrupados] = useState([]);

  const salvarUltimaAtualizacao = (precos) => {
    if (precos?.length) {
      const max = new Date(
        Math.max(...precos.map((e) => new Date(e.dataInicioVigencia)))
      );

      if (max) {
        setUltimaAtualizacao(moment(max).format("DD/MM/yyyy HH:mm"));
      }
    } else {
      setUltimaAtualizacao("");
    }
  };

  const buscarPrecosMensais = async (filtro) => {
    try {
      setCarregando(true);
      if (filtro.submercadoId) {
        const precosMensais = await listarPrecos({
          submercadoId: filtro.submercadoId
        });
        const { precos } = precosMensais.data;

        salvarUltimaAtualizacao(precos || []);
      } else {
        salvarUltimaAtualizacao([]);
      }
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );

      salvarUltimaAtualizacao([]);
    } finally {
      setCarregando(false);
    }
  };

  const buscarPrecosAnuais = async (filtro) => {
    try {
      setCarregando(true);
      if (filtro.submercadoId && filtro.dataBase && filtro.indice) {
        const precosAnuais = await listarPrecosAnuais({
          ...filtro,
          dataBase: moment(filtro.dataBase).format("yyyy-MM-DD")
        });
        const { data } = precosAnuais;

        setPrecosAgrupados(data || []);
      } else {
        setPrecosAgrupados([]);
      }
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );

      setPrecosAgrupados([]);
    } finally {
      setCarregando(false);
    }
  };

  useEffect(() => {
    buscarPrecosAnuais(filtro);
    buscarPrecosMensais(filtro);
    setInitialState(true);
  }, []);

  return (
    <>
      <form className="needs-validation" onSubmit={handleSubmit(() => {})}>
        <Grid container>
          <Grid container item lg={7} md={7} sm={12} xs={12}>
            <HtmlTooltip
              placement="top"
              title="Selecione o ano para preenchimento"
            >
              <Grid item lg={4} md={4} sm={4} xs={12}>
                <SelectArredondado
                  select
                  id="ano"
                  name="ano"
                  label="ano"
                  placeholder="ano"
                  valueKey="valor"
                  valueName="nome"
                  className={classes.select}
                  labelClassName={classes.label}
                  dataSource={anos}
                  value={filtro.ano}
                  onChange={(e) => {
                    setFiltro((prevState) => ({
                      ...prevState,
                      ano: e.target.value
                    }));
                    buscarPrecosAnuais({ ...filtro, ano: e.target.value });
                  }}
                  styleForm={false}
                  allowClear
                />
              </Grid>
            </HtmlTooltip>
            <HtmlTooltip
              placement="top"
              title="Selecione o submercado para inserção dos dados"
            >
              <Grid item lg={4} md={4} sm={4} xs={12}>
                <SelectArredondado
                  select
                  id="submercadoId"
                  name="submercadoId"
                  label="submercado"
                  placeholder="submercado"
                  valueKey="valor"
                  valueName="nome"
                  className={classes.select}
                  labelClassName={classes.label}
                  dataSource={[
                    { nome: "Sudeste", valor: 1 },
                    { nome: "Sul", valor: 2 },
                    { nome: "Nordeste", valor: 3 },
                    { nome: "Norte", valor: 4 }
                  ]}
                  value={filtro.submercadoId}
                  onChange={(e) => {
                    setFiltro((prevState) => ({
                      ...prevState,
                      submercadoId: e.target.value
                    }));
                    buscarPrecosAnuais({
                      ...filtro,
                      submercadoId: e.target.value
                    });
                    buscarPrecosMensais({
                        ...filtro,
                        submercadoId: e.target.value
                      });
                  }}
                  styleForm={false}
                />
              </Grid>
            </HtmlTooltip>
            <HtmlTooltip
              placement="top"
              title="Selecione o índice para busca dos dados"
            >
              <Grid item lg={4} md={4} sm={4} xs={12}>
                <SelectArredondado
                  select
                  id="indice"
                  name="indice"
                  label="Índice"
                  placeholder="Índice"
                  valueKey="valor"
                  valueName="nome"
                  className={classes.select}
                  labelClassName={classes.label}
                  dataSource={[
                    { nome: "IPCA", valor: "IPCA" },
                    { nome: "IGPM", valor: "IGPM" }
                  ]}
                  value={filtro.indice}
                  onChange={(e) => {
                    setFiltro((prevState) => ({
                      ...prevState,
                      indice: e.target.value
                    }));
                    buscarPrecosAnuais({ ...filtro, indice: e.target.value });
                  }}
                  styleForm={false}
                />
              </Grid>
            </HtmlTooltip>
          </Grid>

          <Grid container item lg={5} md={5} sm={12} xs={12}>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <InputBlock
                type="text"
                id="dataBase"
                name="dataBase"
                label="Data Base"
                value={moment(filtro.dataBase).format("DD/MM/yyyy")}
                className="w-100 pr-2 pb-2"
                readOnly
                disabled
              />
            </Grid>

            <Grid item lg={6} md={6} sm={6} xs={12}>
              <InputBlock
                type="text"
                id="ultimaAtualizacao"
                name="ultimaAtualizacao"
                label="Última atualização"
                value={ultimaAtualizacao}
                className="w-100 pr-2 pb-2"
                readOnly
                disabled
              />
            </Grid>
          </Grid>
        </Grid>
      </form>
      <Grid className="my-3">
        <Loader loading={carregando}>
          {precosAgrupados.length > 0 && initialState ? (
            <TabelaPrecoAnual linhas={precosAgrupados} />
          ) : null}
        </Loader>
      </Grid>
    </>
  );
};

export default Anual;
