import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { useHistory } from "react-router-dom";
import { useForm, useWatch } from "react-hook-form";
import { useSelector } from "react-redux";

// Componentes
import { Grid } from "@mui/material";
import { EditOutlined } from "@mui/icons-material";
import TuneIcon from "@mui/icons-material/Tune";
import TabelaPaginada from "../../../componentes/tabelaPaginada";
import Coluna from "../../../componentes/tabelaPaginada/colunas/coluna";
import MaterialInputTexto from "../../../componentes/inputTexto/materialInput";
import BotaoFiltroOrdenacao from "../../../componentes/botaoFiltroOrdenar";
import ListagemValidarCotacaoHelper from "./helper";
import SelectArredondado from "../../../componentes/selectArredondado";
import Loader from "../../../componentes/loader";
import { Base } from "../../../componentes/cores";
import ResultadoPaginadoDto from "../../../componentes/tabelaPaginada/resultadoPaginadoDto";
import ColunaBotao from "componentes/tabelaPaginada/colunas/colunaBotao";

// Redux
import { store } from "../../../global/redux";
import { alertaExibir } from "../../../global/redux/modulos/alertas/actions";
import {
  desabilitarCliente,
  desabilitarEmpresa,
  desabilitarUnidade
} from "../../../global/redux/modulos/usuario/actions";

// DTO
import FiltroDto from "./dto/filtroDto";
import ListagemValidarCotacaoDto from "./dto/listagemValidarCotacaoDto";
import FiltroAdicionalTabela from "./dto/filtroAdicionalTabela";
import { RotasDTO } from "../../../global/rotas/rotasUrlDto";
import { InterfaceDTO } from "global/dto/interfacesDto";

// Constantes
import statusCotacao from "../constantes/constanteStatusValidarCotacao";

// Styles
import { useStyles } from "./style";
import theme from 'themes';

// Serviços
import {
  listarOrganizarPor,
  ordenacaoPorColuna
} from "../../../servicos/listagemValidarCotacoesServico";
import { listarColaboradoresPorArvoreGerencial } from "../../../servicos/colaboradoresServico";
import { buscarPorId } from "../../../servicos/usuariosServico";
import MaterialInputBusca from "componentes/inputBusca";

const ListagemValidarCotacoes = () => {
  const [ordemSelecionada, setOrdemSelecionada] = useState();
  const [filtroSelecionado, setFiltroSelecionado] = useState();
  const [ordemColunaSelecionada, setOrdemColunaSelecionada] = useState();
  const [filtroAdicionalTabela, setFiltroAdicionalTabela] = useState({});
  const [filtroPersonalizado, setFiltroPersonalizado] = useState({});
  const [valorOrdenacao, setValorOrdenacao] = useState();

  const history = useHistory();
  const classes = useStyles();

  const refTabela = useRef(null);

  const { register, control } = useForm({
    reValidateMode: "onSubmit"
  });

  const textoBusca = useWatch({
    control,
    name: "textoBusca",
    defaultValue: ""
  });

  useEffect(() => {
    store.dispatch(desabilitarCliente(true));
    store.dispatch(desabilitarUnidade(true));

    return () => {
      store.dispatch(desabilitarEmpresa(false));
      store.dispatch(desabilitarCliente(false));
      store.dispatch(desabilitarUnidade(false));
    };
  }, []);

  const [listaOfficer, setListaOfficer] = useState([]);
  const [carregandoOfficer, setCarregandoOfficer] = useState(false);

  const obterOfficer = async (empresa) => {
    try {
      setCarregandoOfficer(true);
      const lista = await listarColaboradoresPorArvoreGerencial(empresa ?? 0);
      if (lista?.status === 200 && lista?.data) {
        setListaOfficer(
          lista?.data
            ?.concat({
              id: -1,
              nomeColaborador: "Todos"
            })
            .sort((x, y) => x.id - y.id)
        );
        setColaboradorId(-1);
      }
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
    } finally {
      setCarregandoOfficer(false);
    }
  };

  const obterDadosColaborador = async (idUsuario) => {
    try {
      setCarregandoOfficer(true);
      const dados = await buscarPorId(idUsuario);
      if (dados?.data) {
        setListaOfficer([
          {
            id: dados?.data?.usuario?.colaboradorId,
            nomeColaborador: dados?.data?.usuario?.nome
          }
        ]);
      }
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
    } finally {
      setCarregandoOfficer(false);
    }
  };

  const [colaboradorId, setColaboradorId] = useState();

  const onChangeSelecionarOfficer = (event) => {
    setColaboradorId(event);
  };

  useEffect(() => {
    setFiltroPersonalizado({
      ...(colaboradorId > -1 && { colaboradorId: colaboradorId })
    });
  }, [colaboradorId]);

  const rotas = useSelector((state) => state.rotas);
  const usuario = useSelector((state) => state.usuario);
  const { menu } = usuario;

  const filtroOfficer = useMemo(() => {
    const rota = Object.keys(RotasDTO).find(
      (key) => RotasDTO[key] === history?.location?.pathname
    );

    if (menu?.length && rota) {
      const funcionalidade = menu.find((item) =>
        item.functionality.funcionalidadeColecao.find(
          (funcionalidade) => funcionalidade.caminho === rota
        )
      );

      if (!funcionalidade) return false;

      if (funcionalidade?.functionality?.funcionalidadeColecao?.length) {
        const segmento = funcionalidade?.functionality?.funcionalidadeColecao.find(
          (item) => RotasDTO[item.caminho] === RotasDTO.Cotacoes
        );

        if (!segmento) return false;

        if (segmento?.funcionalidadeColecao?.length) {
          const filtro = segmento?.funcionalidadeColecao.find(
            (item) =>
              InterfaceDTO[item.caminho] ===
              InterfaceDTO.PodeAlterarFiltrarOfficer
          );

          return Boolean(filtro);
        }
      }
    }
  }, [menu, history]);

  const { empresaSelecionada } = usuario;

  useEffect(() => {
    if (filtroOfficer) obterOfficer(empresaSelecionada);
    else if (usuario?.usuario?.id) obterDadosColaborador(usuario?.usuario?.id);
  }, [filtroOfficer, empresaSelecionada]);

  useEffect(() => {
    if (listaOfficer?.length && listaOfficer?.length === 1)
      setColaboradorId(usuario?.usuario?.colaboradorId);
  }, [listaOfficer]);

  const onClickEditar = (id) => {
    history.push(`${RotasDTO.ValidarCotacoes}/comparar/${id}`);
  };

  const colunas = [
    new Coluna("nomeFantasia", "Nome Fantasia", false, "250px", "250px"),
    new Coluna("cnpj", "CNPJ", false, "200px", "200px"),
    new Coluna("plano", "Plano", false, "100px", "100px"),
    new Coluna("fechamento", "Fechamento", false),
    new Coluna("prazo", "Prazo", false),
    new Coluna("status", "Status", false, "90px", "90px"),
    new ColunaBotao(
      "editar",
      "Editar",
      onClickEditar,
      <EditOutlined />,
      false,
      "90px",
      "90px",
      false,
      false
    )
  ];

  const obterStatusDescricao = (status) => {
    switch (status) {
      case statusCotacao.Aberto:
        return "Aberto";
      case statusCotacao.Validado:
        return "Validado";
      case statusCotacao.Ajustado:
        return "Ajustado";
      case statusCotacao.Rejeitada:
        return "Rejeitada";
      case statusCotacao.Cancelada:
        return "Cancelada";
      case statusCotacao.Atrasado:
        return "Atrasado";
      default:
        return "";
    }
  };

  const obterListagem = useCallback(async (parametros) => {
    try {
      const filtro = new FiltroDto(
        parametros.tamanhoPagina,
        parametros.pagina,
        parametros.pesquisar,
        parametros.filtroAdicional.ordenacao ?? "",
        [parametros.filtroAdicional.filtro] ?? "",
        {
          nome: "colaboradorId",
          valor: parametros?.filtroPersonalizado?.colaboradorId ?? ""
        },
        parametros.filtroAdicional.sortOrder,
        parametros?.filtroAdicional?.empresaId ?? ""
      );

      const resultado = await ListagemValidarCotacaoHelper.listarCotacoes(
        filtro
      );

      if (!resultado.sucesso) {
        store.dispatch(
          alertaExibir({
            tipo: "danger",
            mensagem: resultado.mensagem
          })
        );
        return new ResultadoPaginadoDto([], 1, 0, 0);
      }

      if (resultado.data.cotacoesIniciais?.length === 0 ?? true)
        return new ResultadoPaginadoDto([], 1, 0, 0);

      if (resultado.data === "") return new ResultadoPaginadoDto([], 1, 0, 0);

      const resLinhas = resultado?.data?.cotacoesInicias?.map((res) => {
        const fechamento = window.moment(res?.fechamento).format("DD-MM-YYYY");
        const prazo = window.moment(res?.prazo).format("DD-MM-YYYY");
        const cotacaoStatus = obterStatusDescricao(res.status);

        const descricaoPlanoSelecionado =
          res?.plano == "Sem Selecao" ? "-" : res?.plano;

        const cnpjFormatado = res?.cnpj.replace(
          /(\d{2})?(\d{3})?(\d{3})?(\d{4})?(\d{2})/,
          "$1.$2.$3/$4-$5"
        );

        return new ListagemValidarCotacaoDto(
          res?.id,
          res?.nomeFantasia,
          cnpjFormatado,
          descricaoPlanoSelecionado,
          fechamento,
          prazo,
          cotacaoStatus,
          true,
          null,
          res.status
        );
      });

      if (!resLinhas) return false;

      return new ResultadoPaginadoDto(
        resLinhas,
        resultado.data.paginaAtual,
        resultado.data.totalItens,
        resultado.data.totalPaginas
      );
    } catch (error) {
      console.info(error);
    }
  }, []);

  const onChangeFiltrosTabela = useCallback(
    async (parametros) => {
      try {
        if (!filtroOfficer && colaboradorId === -1) return null;

        return obterListagem({
          tamanhoPagina: parametros.totalPagina,
          pagina: parametros.pagina,
          pesquisar: parametros.pesquisar,
          filtroAdicional: new FiltroAdicionalTabela(
            parametros.filtrosAdicionais?.ordenacao,
            parametros.filtrosAdicionais?.filtro,
            parametros.filtrosAdicionais?.sortOrder,
            parametros.filtrosAdicionais?.empresaId
          ),
          filtroPersonalizado: parametros?.filtroPersonalizado
        });
      } catch (error) {
        return null;
      }
    },
    [filtroOfficer, colaboradorId]
  );

  const handleAlterarFiltroLateral = () => {
    setFiltroAdicionalTabela(
      new FiltroAdicionalTabela(
        ordemSelecionada,
        filtroSelecionado,
        valorOrdenacao,
        empresaSelecionada
      )
    );
  };

  useEffect(() => {
    handleAlterarFiltroLateral();
  }, [empresaSelecionada]);

  const handleClickAtivarFiltro = () => {
    setFiltroAdicionalTabela(
      new FiltroAdicionalTabela(
        ordemSelecionada,
        filtroSelecionado,
        valorOrdenacao,
        empresaSelecionada
      )
    );
  };

  const handleClickOrdemSelecionada = (event) => {
    const val = ordemSelecionada === event ? "" : event;
    setOrdemSelecionada(val);
  };

  const handleClickFiltroSelecionado = (event) => {
    const val = filtroSelecionado === event ? "" : event;
    setFiltroSelecionado(val);
  };

  const handleClickSortOrderSelecionado = (valor, idCampoSelecionado) => {
    const val =
      ordemColunaSelecionada === idCampoSelecionado && valor === ""
        ? ""
        : idCampoSelecionado;
    setOrdemColunaSelecionada(val);
    setValorOrdenacao(valor);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container alignItems="center" justifyContent="space-between" spacing={2}>
          <Grid item md={5} sm={9}>
            <MaterialInputBusca
              type="text"
              id="textoBusca"
              name="textoBusca"
              label="Buscar"
              renderIconShowHide
              searchAdornment
              permiteValorBranco
              defaultValue={textoBusca ?? ""}
              ref={register}
            />
          </Grid>
          <Grid item md={4}>
            <Loader loading={carregandoOfficer}>
              <SelectArredondado
                select
                id="officer"
                name="officer"
                label="Officer"
                placeholder="Officer"
                valueKey="id"
                valueName="nomeColaborador"
                dataSource={listaOfficer}
                value={colaboradorId}
                onChange={(event) => {
                  onChangeSelecionarOfficer(event.target.value);
                }}
                permiteValorBranco
                ref={register}
                className={classes.select}
                marginBottom="0"
              />
            </Loader>
          </Grid>
          <Grid item xs={3} className={classes.itemFiltro}>
            <BotaoFiltroOrdenacao
              type="button"
              color={theme.color.secondaryBorderColor}
              background="transparent"
              label="Filtrar / Ordernar"
              icon={<TuneIcon />}
              className={classes.buttonFiltro}
              ordenacao={listarOrganizarPor()}
              ordenacaoColuna={ordenacaoPorColuna()}
              ordenador="Ordenação"
              ordenadorUm="Status"
              ordenadorDois="Planos"
              ordemSelecionada={ordemSelecionada}
              filtrosSelecionados={filtroSelecionado}
              ordemColunaSelecionada={ordemColunaSelecionada}
              onClickOrdenacao={handleClickOrdemSelecionada}
              onClickFiltro={handleClickFiltroSelecionado}
              onClickSortOrder={handleClickSortOrderSelecionado}
              onClickAtivarAgora={handleClickAtivarFiltro}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} mt={4}>
        <TabelaPaginada
          ref={refTabela}
          onChangeFiltrosTabela={onChangeFiltrosTabela}
          colunas={colunas}
          pesquisar={textoBusca}
          filtrosAdicionais={filtroAdicionalTabela}
          filtroPersonalizado={filtroPersonalizado}
          paginaAtual={1}
          paginationPerPage={20}
          noHeader
        />
      </Grid>
    </Grid>
  );
};

export default ListagemValidarCotacoes;
