import React from "react";
import withStyles from '@mui/styles/withStyles';
import Tooltip from "@mui/material/Tooltip";
import { Base } from "../cores";

const CustomTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: Base.GreyPaynes,
    color: `${Base.OffWhite} !important`,
    fontSize: "15px",
    padding: "15px",
    "& ul li": {
      listStyle: "none",
      marginLeft: "-40px"
    }
  }
}))(Tooltip);

export default function CustomizedTooltipForArvoreGerencialTreeview(props) {
  const { raizArvore, nestedComponents } = props;
  if (raizArvore?.empresasGrupoDelta?.length > 0) {
    return (
      <CustomTooltip
        title={
          <ul>
            {raizArvore?.empresasGrupoDelta.map((empresa, e) => {
              return <li key={e}>{empresa.nomeFantasia}</li>;
            })}
          </ul>
        }
        placement="right-start"
      >
        {nestedComponents[0]}
      </CustomTooltip>
    );
  }
  return "";
}
