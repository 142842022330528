import {
  obterOperacaoTrocaTitularidadePaginado,
  obterDistribuidoras,
  obterProblemaMigracaoMotivos,
  atualizarTrocaTitularidadeLote
} from "servicos/atividadesServico";
import RetornoEndpointDto from "global/dto/retornoEndpointDto";

export default class AtualizarStatusMigracaoHelper {
  static async listarDistribuidoras(setNotificacao) {
    try {
      const resultado = await obterDistribuidoras();

      return RetornoEndpointDto.Sucesso(
        "Listagem de distribuidoras obtidas com sucesso!",
        resultado.data
      );
    } catch (error) {
      setNotificacao({
        aberto: true,
        tipo: "error",
        texto:
          error?.response?.data?.message ||
          "Ocorreu um erro ao obter distribuidoras!"
      });
    }
  }

  static async listarOperacaoTrocaTitularidadePaginado(
    filtro,
    parametrosAdicionais
  ) {
    try {
      const resultado = await obterOperacaoTrocaTitularidadePaginado(
        filtro,
        parametrosAdicionais
      );

      return RetornoEndpointDto.Sucesso(
        "Listagem de Operação Controle obtidas com sucesso!",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro(
        error?.response?.data?.message ??
          "Ocorreu um erro ao obter a lista de Operação Controle!"
      );
    }
  }

  static async listarProblemaMigracaoMotivos(setNotificacao) {
    try {
      const resultado = await obterProblemaMigracaoMotivos();

      return RetornoEndpointDto.Sucesso(
        "Listagem de motivos obtidos com sucesso!",
        resultado.data
      );
    } catch (error) {
      setNotificacao({
        aberto: true,
        tipo: "error",
        texto:
          error?.response?.data?.message ||
          "Ocorreu um erro ao obter distribuidoras!"
      });
      return RetornoEndpointDto.Erro(
        error?.response?.data?.message ??
          "Ocorreu um erro ao obter distribuidoras!"
      );
    }
  }

  static async atualizarTrocaTitularidade(dados, setNotificacao) {
    try {
      const resultado = await atualizarTrocaTitularidadeLote(dados);

      return RetornoEndpointDto.Sucesso(
        "Operação Controle atualizada com sucesso!",
        resultado.data
      );
    } catch (error) {
      setNotificacao({
        aberto: true,
        tipo: "error",
        texto: error?.response?.data?.message || "Ocorreu um erro ao atualizar!"
      });
      return RetornoEndpointDto.Erro(
        error?.response?.data?.message ?? "Ocorreu um erro ao atualizar!"
      );
    }
  }
}
