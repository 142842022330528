import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import prbrLocale from "date-fns/locale/pt-BR";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { ptBR } from "@mui/x-date-pickers/locales";
import {
  Grid,
  Popover,
  InputAdornment,
  TextField,
  IconButton
} from "@mui/material";
import DateRangeIcon from "@mui/icons-material/DateRange";
import { ThemeProvider } from "@mui/material/styles";

import StyledEngineProvider from "@mui/material/StyledEngineProvider";
import Botao from "../../../../componentes/botao";
import { useStyles, materialTheme } from "./style";

const InputData = ({ onChangeData, defaultValue }) => {
  const classes = useStyles();
  const calendarioRef = useRef(0);
  const [data, setData] = useState();
  const [motrarCalendario, setMotrarCalendario] = useState(false);
  const [valor, setValor] = useState();
  const [ancora, setAncora] = useState(null);

  const formatarValor = (dataFormatar) => {
    let inputValor = "";
    if (dataFormatar)
      inputValor += `${moment(
        dataFormatar
      ).format("MMMM")}, ${moment(dataFormatar).format("yyyy")}`;
    setValor(inputValor);
  };

  const fecharCalendario = () => {
    setMotrarCalendario(false);
    setAncora(null);
  };

  const selecionarData = (dataValor) => {
    let dataVerificar = moment(dataValor);
    dataVerificar = dataVerificar.isValid() ? dataVerificar.toDate() : undefined;

    formatarValor(dataVerificar);
    onChangeData(dataValor);
    setMotrarCalendario(false);
  };

  useEffect(() => {
    formatarValor(defaultValue || new Date());
  }, [defaultValue]);

  return (
    <Grid container>
      <Grid xs={7} sm={8}>
        <TextField
          label=""
          fullWidth
          value={valor}
          ref={calendarioRef}
          disabled
          className={classes.container}
          InputProps={{
            startAdornment: (
              <InputAdornment
                position="end"
                onClick={() => {
                  setAncora(calendarioRef.current);
                  setMotrarCalendario(true);
                }}
              >
                <IconButton>
                  <DateRangeIcon />
                </IconButton>
              </InputAdornment>
            )
          }}
        />
        <Popover
          open={motrarCalendario}
          anchorEl={ancora}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left"
          }}
          onClose={fecharCalendario}
        >
          <LocalizationProvider
            adapterLocale={prbrLocale}
            dateAdapter={AdapterDateFns}
            localeText={
              ptBR.components.MuiLocalizationProvider.defaultProps.localeText
            }
          >
            <StyledEngineProvider injectFirst>
              <ThemeProvider theme={materialTheme}>
                <DateCalendar
                  value={data}
                  onChange={(date, selectionState) => {
                    setData(date)
                    if (selectionState === "finish") selecionarData(date);
                  }}
                  views={["month", "year"]}
                  openTo="month"
                />
              </ThemeProvider>
            </StyledEngineProvider>
          </LocalizationProvider>
        </Popover>
      </Grid>
      <Grid xs={5} sm={4}>
        <Botao
          type="button"
          label="Alterar período"
          className={classes.button}
          onClick={() => {
            setAncora(calendarioRef.current);
            setMotrarCalendario(true);
          }}
        />
      </Grid>
    </Grid>
  );
};

InputData.propTypes = {
  onChangeData: PropTypes.func,
  defaultValue: PropTypes.string
};

InputData.defaultProps = {
  onChangeData: () => {},
  defaultValue: new Date()
};

export default InputData;
