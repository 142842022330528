import React from "react";
import SlickSlider from "react-slick";

import { Wrapper } from "./styles";

const Carousel = ({ settings, children, dotsPosition = "center" }) => {
  return (
    <Wrapper>
      <SlickSlider
        {...settings}
        arrows={true}
        dots={false}
        swipe={false}
        slidesToScroll={1}
        className={`dots-position-${dotsPosition}`}
      >
        {children}
      </SlickSlider>
    </Wrapper>
  );
};

export default Carousel;
