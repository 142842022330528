import { makeStyles } from "@mui/styles";
import { Base } from "../cores";
import theme from "themes";
import getDomain from "global/getDomain";

export const useStyles = makeStyles(() => ({
  formControl: {
    display: "block !important",
    marginBottom: "10px !important",
    fontFamily: theme.font.family,
    "& .MuiOutlinedInput-root": {
      backgroundColor:
        getDomain() === "luz"
          ? (props) => (props.value ? "#d2d2d7" : Base.White)
          : (props) => (props.value ? Base.BlackOpacity20 : "transparent"),
      color: `${Base.Black} !important`,
      padding: "0 !important",
      paddingRight: "30px !important",
      paddingLeft: "10px !important",
      "& .Mui-disabled": {
        "-webkit-text-fill-color": `${Base.Black} !important`
      }
    },
    "& .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']": {
      padding: "0 !important",
      paddingLeft: "10px !important",
      "& input": {
        maxWidth: "120px !important"
      }
    },
    "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] .MuiAutocomplete-input": {
      padding: "10px !important",
    },
    "& .MuiInputBase-root.Mui-disabled": {
      padding: 0,
      paddingLeft: "10px !important",
      flexWrap: "nowrap",
      color: `${theme.color.secondaryBackgroud} !important`
    },
    "& .MuiAutocomplete-popupIndicator": {
      opacity: 0
    },
    "& .makeStyles-formControl-26": {
      borderRadius: 6,
      backgroundColor: Base.OffWhite,
      color: theme.color.secondaryBackgroud,
      cursor: "pointer"
    },
    "& .MuiAutocomplete-clearIndicator": {
      visibility: (props) => (props.value ? "visible" : "hidden")
    }
  },
  select: {
    "& .MuiOutlinedInput-root": {
      borderColor: theme.color.borderColor,
      color: theme.color.secondaryBackgroud,
      borderRadius: 6,
      maxHeight: "42px",
      "& .MuiSelect-outlined.MuiSelect-outlined": {
        padding: "50px",
        textAlign: "left"
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: `${theme.color.borderColor} !important`
      }
    },
    "& .MuiInputLabel-root": {
      color: theme.color.secondaryBackgroud,
      transform: "translate(15px, 6px) scale(1)",
      "&.Mui-focused": {
        transform: "translate(14px, -6px) scale(0.75)"
      }
    },
    "& .MuiSelect-icon": {
      backgroundColor: theme.color.secondaryBackgroud,
      borderRadius: 6,
      color: theme.color.secondaryBackgroud,
      right: "4px"
    }
  },
  selectIcon: {
    color: Base.Black,
    marginRight: "6px",
    position: "absolute",
    right: 0,
    top: "calc(50% - 10px)",
    "pointer-events": "none",
    borderRadius: 6
    //backgroundColor: theme.color.secondaryBackgroud
  },
  menuIcon: {
    marginRight: "10px !important"
  },
  adornment: {
    marginRight: "10px !important"
  },
  clearButton: {
    marginRight: "15px !important"
  }
}));

export const customStyles = makeStyles(() => ({
  select: {
    "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] .MuiAutocomplete-input": {
      padding: "0 4px"
    }
  },
  selectIcon: {
    display: "none"
  }
}));
