import MICROSERVICO from "../global/enumeradores/microServico";
import obterApi from "./api";

const apiMeansurement = () => obterApi(MICROSERVICO.MEASUREMENT);

const apiManagement = (log) => obterApi(MICROSERVICO.MANAGEMENT, log);

export const obterPlanosSimulados = (cotacaoId) => {
  return apiManagement().get(
    `v1/CotacaoInicialPlanosGerados/cotacao/${cotacaoId}`
  );
};

export const gerarPlanosSimulados = (idCotacao) => {
  const dados = {
    IdCotacaoInicial: idCotacao
  };

  return apiMeansurement().post("v1/planossimulados", dados);
};

export const selecionarPlanoSimulado = (idPlano, cotacaoId,dadosFatura) => {
  const log = {
    dadosFatura : dadosFatura,
    nestedField : "plano_selecionado",
    tipo : "atualizaLog"
  };
  return apiManagement(log).put(
    "v1/CotacaoInicialPlanosGerados/SelecionarCotacao",
    {
      CotacaoInicialId: cotacaoId,
      CotacaoInicialPlanoGeradoId: idPlano
    }
  );
};

export const criarPLanosCotacaoInicial = (body) => {
  return apiManagement().post(
    "v1/CotacaoInicialPlanosGerados/InserirCotacaoInicialPlanosGeradosViaCalculo",
    body
  );
};
