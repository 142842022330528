import MICROSERVICO from "../global/enumeradores/microServico";
import obterApi from "./api";

const apiManagement = () => obterApi(MICROSERVICO.MANAGEMENT);
const apiMeasurement = () => obterApi(MICROSERVICO.MEASUREMENT);

export const obterOperacaoTrocaTitularidadePaginado = async (
  params,
  parametrosAdicionais
) => {
  const filtroQueryString = new URLSearchParams(params).toString();
  return apiManagement().get(
    parametrosAdicionais
      ? `v1/OperacaoTrocaTitularidade?${filtroQueryString}${parametrosAdicionais}`
      : `v1/OperacaoTrocaTitularidade?${filtroQueryString}`
  );
};

export const obterDistribuidoras = async () => {
  return apiManagement().get("v1/OperacaoTrocaTitularidade/distribuidoras");
};

export const obterProblemaMigracaoMotivos = async () => {
  return apiManagement().get(
    "v1/OperacaoTrocaTitularidade/MotivoProblemaMigracao"
  );
};

export const atualizarTrocaTitularidadeLote = async (dados) => {
  return apiManagement().put(
    "v1/OperacaoTrocaTitularidade/AtualizarTrocaTitularidadeLote",
    dados
  );
};

export const obterListaAssociacoes = async (distribuidoraId) => {
  return apiMeasurement().get(
    `v1/associacaoluz/obterlistapordistribuidoraid/${distribuidoraId}`
  );
};

export const obterListaUsinas = async (id) => {
  return apiMeasurement().get(
    `v1/usinasluz/obterusinaassociacaoporassociacaoid?associacaoId=${id}`
  );
};

export const obterUnidadesAssociacaoPaginado = async (
  params,
  parametrosAdicionais
) => {
  const filtroQueryString = new URLSearchParams(params).toString();
  return apiManagement().get(
    parametrosAdicionais
      ? `v1/OperacaoControle/unidades-consumidoras/associacao?${filtroQueryString}${parametrosAdicionais}`
      : `v1/OperacaoControle/unidades-consumidoras/associacao?${filtroQueryString}`
  );
};

export const obterDistribuidorasAssociacao = async () => {
  return apiManagement().get("v1/OperacaoControle/distribuidoras");
};
