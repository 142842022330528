import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((themes) => ({
    dataLuz: {
       backgroundColor:" #F4F4F4",
       width: "24px",
       height: "24px",
       cursor: "pointer",
       fontFamily: "Plau",
       fontStyle: "normal",
       fontWeight: "700",
       fontSize: "18px",
       color: "#FA3EB1",
       border: 0,
       outline:0,
    }
    
}));   