import styled from "styled-components";
import { makeStyles } from '@mui/styles';
import { Base } from "componentes/cores";
import theme from 'themes';
import { createTheme } from "@mui/material/styles";

const th = createTheme();

export const Container = styled.div`
  background-color: ${Base.BlueZodiac};
  border-radius: 4px;
  color: ${Base.OffWhite};
  height: 100%;
  min-height: ${(props) => props.height};
`;

export const UnidadeNaoSelecionada = styled.div`
  color: ${Base.OffWhite};
  font-size: 28px;
  margin: 200px auto 0;
  max-width: 500px;
  text-align: center;
`;

export const useStyles = makeStyles(() => ({
  container: {
    width: "100%",
    padding: th.spacing(2, 0),
    "& .MuiInputBase-root": {
      height: "46px",
      "& input": {
        pointerEvents: "none"
      }
    },
    "& .MuiFormLabel-root": {
      lineHeight: "1",
      color: `${theme.color.secondaryText} !important`
    },
    "& .MuiSvgIcon-root": {
      color: `${theme.color.secondaryText} !important`
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: `1px solid ${theme.color.secondaryText}`
    }
  },
  filter: {
    color: `${theme.color.secondaryText} !important`,
    display: "inline",
    fontSize: "18px",
    margin: 0,
    padding: th.spacing(2, 0, 0),
    "& li": {
      display: "inline-block",
      marginLeft: "45px",
      position: "relative",
      "&:nth-child(1) .icon": {
        backgroundColor: theme.color.legendaMedicao1
      },
      "&:nth-child(2) .icon": {
        backgroundColor: theme.color.legendaMedicao2
      },
      "&:nth-child(3) .icon": {
        backgroundColor: theme.color.legendaMedicao3
      }
    },
    "& .icon": {
      display: "inline-block",
      height: "20px",
      left: "-30px",
      position: "absolute",
      width: "20px"
    },
    "& .texto": { marginTop: "-2px" },
    [th.breakpoints.up("md")]: {
      padding: 0
    },
  },
  noData: {
    height: "100%",
    minHeight: "200px",
    width: "100%",
    paddingTop: "80px",
    textAlign: "center"
  },
  content: {
    background: theme.color.bgColorCard
  },
  containerCalendario: {
    // height: "54px",
    borderBottom: `2px solid ${theme.color.borderCard}`
  },
  calendario: {
    // marginTop: "-10px",
    "& > div": {
      padding: 0,
      // marginLeft: "-60px",
      "& > div": {
        color: '#fff',
        margin: 0,
        width: '100%',
        [th.breakpoints.up("md")]: {
          margin: 0,
        },
        "& > div": {
          color: '#fff',
          alignSelf: 'center',
          "& svg": {
            color: '#fff',
          }
        }
      }
    },
    "& .MuiIconButton-root:hover": {
      backgroundColor: "rgba(0, 0, 0, 0)"
    },
    "& fieldset": {
      borderColor: 'transparent'
    }
  },
  containerArrow: {
    // margin: "6px 0 0 14px"
  },
  containerArrowLeft: {
    // maxWidth: "54px",
    borderRight: `2px solid ${theme.color.borderCard}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  containerArrowRight: {
    // maxWidth: "54px",
    borderLeft: `2px solid ${theme.color.borderCard}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  arrow: {
    color: `${theme.color.secondaryText} !important`,
    cursor: "pointer",
    // marginTop: "10px"
  },
  disabled: {
    opacity: 0.5,
    pointerEvents: "none"
  },
  tooltip: {
    width: "100%",
    textAlign: "center",
    alignItems: "center",
    padding: "8px",
    borderImageSlice: 1,
    backgroundImage: `linear-gradient(to bottom, ${Base.GreyPaleSky}, ${Base.GreyPaleSky}), linear-gradient(to bottom, ${Base.BlueLimed}, ${Base.GreyPaleSky} 87%)`,
    borderImageSource: `linear-gradient(to bottom, ${Base.BlueLimed}, ${Base.GreyPaleSky} 87%)`
  },
  tooltipDescricao: {
    fontWeight: "bold",
    fontSize: "15px"
  },
  tooltipData: {
    fontSize: "13px",
    color: Base.GreyCasper
  },
  inputRoot: {
    color: `${theme.color.secondaryText} !important`,
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.color.secondaryText
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.color.secondaryText
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.color.secondaryText
    }
  }
}));
