import React, { useState, useEffect } from "react";
import moment from "moment";

// Componentes
import DataTable from "react-data-table-component";
import InputEditavel from "../InputEditavel";
import colunas from "../../Constantes/Colunas";
import InputData from "../InputData";

// Styles
import { useStyles, customStyles } from "./style";

const TabelaConstantesAdicionar = ({ linhas, salvar, erros }) => {
  const classes = useStyles();
  const [linhasFormatadas, setLinhasFormatadas] = useState([]);

  const formatarLinhas = (dados) => {
    let dadosFormatados = [];

    if (dados?.length > 0) {
      dadosFormatados = dados.map((item, index) => {
        return {
          dataInicioVigencia: (
            <InputData
              type="text"
              id={`dataInicioVigencia${index}`}
              name={`dataInicioVigencia${index}`}
              label=""
              customValue={item.dataInicioVigencia ? new Date(item.dataInicioVigencia) : null}
              onChange={(data) => salvar(data, index, "dataInicioVigencia")}
              className={classes}
              errors={!item.dataInicioVigencia && erros}
              minDate={new Date()}
            />
          ),

          dataFimVigencia: (
            <InputData
              type="text"
              id={`dataFimVigencia${index}`}
              name={`dataFimVigencia${index}`}
              label=""
              customValue={item.dataFimVigencia ? new Date(item.dataFimVigencia) : null}
              onChange={(data) => salvar(data, index, "dataFimVigencia")}
              className={classes}
              errors={!item.dataFimVigencia && erros}
              minDate={moment()
                .add(+1, "days")
                .toDate()}
            />
          ),
          contribuicaoAssociativa: (
            <InputEditavel
              id="contribuicaoAssociativa"
              name="contribuicaoAssociativa"
              valorInput={item.contribuicaoAssociativa}
              onClick={(valor) =>
                salvar(valor, index, "contribuicaoAssociativa")
              }
              editar={() => {
                salvar("", index, "contribuicaoAssociativa");
              }}
              erro={!item.contribuicaoAssociativa && erros}
            />
          ),
          cosip: (
            <InputEditavel
              id="cosip"
              name="cosip"
              valorInput={item.cosip}
              onClick={(valor) => {
                console.log(valor);
                salvar(valor, index, "cosip");
              }}
              editar={() => {
                salvar("", index, "cosip");
              }}
              erro={!item.cosip && erros}
            />
          ),
          custoConexao: (
            <InputEditavel
              id="conexao"
              name="conexao"
              valorInput={item.custoConexao}
              onClick={(valor) => salvar(valor, index, "custoConexao")}
              editar={() => {
                salvar("", index, "conexao");
              }}
              erro={!item.custoConexao && erros}
            />
          ),
          encargos: (
            <InputEditavel
              id="encargos"
              name="encargos"
              valorInput={item.encargos}
              onClick={(valor) => salvar(valor, index, "encargos")}
              editar={() => {
                salvar("", index, "encargos");
              }}
              erro={!item.encargos && erros}
            />
          ),
          custosAdicionais: (
            <InputEditavel
              id="custosAdicionais"
              name="custosAdicionais"
              valorInput={item.custosAdicionais}
              onClick={(valor) => salvar(valor, index, "custosAdicionais")}
              editar={() => {
                salvar("", index, "custosAdicionais");
              }}
              erro={!item.custosAdicionais && erros}
            />
          ),
          margemLuz: (
            <InputEditavel
              id="margem"
              name="margem"
              valorInput={item.margemLuz}
              onClick={(valor) => salvar(valor, index, "margemLuz")}
              editar={() => {
                salvar("", index, "margem");
              }}
              erro={!item.margemLuz && erros}
            />
          )
        };
      });
    }
    setLinhasFormatadas(dadosFormatados);
  };

  useEffect(() => {
    formatarLinhas(linhas);
  }, [linhas, erros]);

  return (
    <DataTable
      columns={colunas}
      data={linhasFormatadas}
      theme="DeltaEnergia"
      persistTableHead
      customStyles={customStyles}
      noDataComponent={<></>}
      noHeader
      responsive={false}
    />
  );
};

export default TabelaConstantesAdicionar;
