import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import { Botao, SelectArredondado } from "componentes";
import BotaoOutLine from "componentes/botaoOutline";
import VincularAssociacaoUCHelper from "../../VincularAssociacaoUC/helper";

import { useStyles } from "./style";

const FormularioAtualizarStatus = (props) => {
  const {
    onCancelar,
    setCarregando,
    setNotificacao,
    obterDados,
    unidadesSelecionadas,
    carregando,
    distribuidoraId
  } = props;
  const classes = useStyles();
  const [desabilitarSalvar, setDesabilitarSalvar] = useState();
  const [listaAssociacoes, setListaAssociacoes] = useState();
  const [listaUsinas, setListaUsinas] = useState();
  const [associacaoId, setAssociacaoId] = useState();
  const [usinaId, setUsinaId] = useState();

  const obterAssociacoes = async () => {
    setCarregando(true);
    const resultado = await VincularAssociacaoUCHelper.listarAssociacoes(
      distribuidoraId,
      setNotificacao
    );

    setCarregando(false);
    setListaAssociacoes(resultado?.data || []);
  };

  const obterUsinas = async (id) => {
    setCarregando(true);
    const resultado = await VincularAssociacaoUCHelper.listarUsinas(
      id,
      setNotificacao
    );

    setCarregando(false);
    setListaUsinas(resultado?.data || []);
  };

  const onChangeAssociacao = async (valor) => {
    setAssociacaoId(valor);
    setUsinaId();
    if (valor) await obterUsinas(valor);
  };

  const onChangeUsina = (valor) => {
    setUsinaId(valor);
  };

  const validarBotao = () => {
    if (associacaoId && usinaId) {
      setDesabilitarSalvar(false);
      return;
    }

    setDesabilitarSalvar(true);
  };

  const onSalvar = async () => {
    setCarregando(true);
    setDesabilitarSalvar(true);

    const resultadoAtualizar = await VincularAssociacaoUCHelper.atualizarTrocaTitularidade(
      {
        operacoesControleId: unidadesSelecionadas,
        ...(associacaoId && {
          associacaoId: Number(associacaoId)
        })
      },
      setNotificacao
    );

    setCarregando(false);
    setDesabilitarSalvar(false);

    if (resultadoAtualizar?.data) {
      setNotificacao({
        aberto: true,
        tipo: "warning",
        texto:
          unidadesSelecionadas?.length > 1
            ? `${unidadesSelecionadas?.length} UCs estão em processamento!`
            : `${unidadesSelecionadas?.length} UC esta em processamento!`
      });

      onCancelar();
      obterDados({ quantidade: unidadesSelecionadas?.length });
    }
  };

  useEffect(() => {
    validarBotao();
  }, [associacaoId, usinaId]);

  useEffect(() => {
    obterAssociacoes();
  }, []);

  return (
    <Grid container spacing={1}>
      {unidadesSelecionadas && (
        <>
          <Grid item xs={12} className="font-weight-bold">
            <label htmlFor="associacao" className={classes.label}>
              Associação
            </label>
            <SelectArredondado
              select
              id="associacao"
              name="associacao"
              label="Selecione uma opção"
              placeholder="Selecione uma opção"
              valueKey="id"
              valueName="nomeFantasia"
              dataSource={listaAssociacoes}
              value={associacaoId}
              onChange={(e) => {
                onChangeAssociacao(e.target.value);
              }}
              permiteValorBranco
            />
          </Grid>
          <Grid item xs={12} className="font-weight-bold">
            <label htmlFor="usina" className={classes.label}>
              Usina
            </label>
            <SelectArredondado
              select
              id="usina"
              name="usina"
              label="Selecione uma opção"
              placeholder="Selecione uma opção"
              valueKey="id"
              valueName="nomeUsina"
              dataSource={listaUsinas}
              value={usinaId}
              onChange={(e) => {
                onChangeUsina(e.target.value);
              }}
              permiteValorBranco
              disabled={!associacaoId}
            />
          </Grid>
        </>
      )}

      <Grid item xs={6} mt={6}>
        <BotaoOutLine
          onClick={() => onCancelar()}
          label="Cancelar"
          className={classes.botaoTransparente}
          disabled={carregando}
        />
      </Grid>
      <Grid item xs={6} mt={6}>
        <Botao
          onClick={() => onSalvar()}
          label="Salvar"
          className={classes.botao}
          disabled={desabilitarSalvar || carregando}
        />
      </Grid>
    </Grid>
  );
};

FormularioAtualizarStatus.propTypes = {
  onCancelar: PropTypes.func,
  setCarregando: PropTypes.func,
  setNotificacao: PropTypes.func,
  obterDados: PropTypes.func,
  unidadesSelecionadas: PropTypes.arrayOf([PropTypes.number]),
  carregando: PropTypes.bool,
  distribuidoraId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

FormularioAtualizarStatus.defaultProps = {
  onCancelar: () => {},
  setCarregando: () => {},
  setNotificacao: () => {},
  obterDados: () => {},
  unidadesSelecionadas: [],
  carregando: false,
  distribuidoraId: ""
};

export default FormularioAtualizarStatus;
