import React, { useCallback, useRef, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useHistory } from "react-router-dom";

// Componentes
import TuneIcon from "@mui/icons-material/Tune";
import { Grid } from "@mui/material";
import { Base } from "../../../componentes/cores";
import Botao from "../../../componentes/botao";
import TabelaPaginada from "../../../componentes/tabelaPaginada";
import Coluna from "../../../componentes/tabelaPaginada/colunas/coluna";
import MaterialInputTexto from "../../../componentes/inputTexto/materialInput";
import BotaoFiltroOrdenacao from "../../../componentes/botaoFiltroOrdenar";
import GrupoAssociativoHelper from "./helper";
import FiltroAdicionalTabela from "./dto/filtroAdicionalTabela";
import ColunaComponentePersonalizado from "../../../componentes/tabelaPaginada/colunas/colunaComponentePersonalizado";
import ColunaMultiplosBotoes from "./colunaMultiplosBotoes";

// DTO
import ListagemGruposAssociativosDto from "./dto/listagemGruposAssociativosDto";
import FiltroDto from "./dto/filtroDto";
import ResultadoPaginadoDto from "../../../componentes/tabelaPaginada/resultadoPaginadoDto";
import enumeradorBotao from "./enumeradores/enumeradorBotao";

// Redux
import { store } from "../../../global/redux";
import { alertaExibir } from "../../../global/redux/modulos/alertas/actions";

// Styles
import { useStyles } from "./style";
import theme from 'themes';

// Serviços
import {
  listarFiltrarPor,
  listarOrganizarPor,
  MudarStatus,
  ordenacaoPorColuna
} from "../../../servicos/grupoAssociativoServico";
import { RotasDTO } from "global/rotas/rotasUrlDto";
import MaterialInputBusca from "componentes/inputBusca";

const ListagemGrupoAssociativo = () => {
  const history = useHistory();
  const [ordemSelecionada, setOrdemSelecionada] = useState("");
  const [ordemColunaSelecionada, setOrdemColunaSelecionada] = useState();
  const [filtroSelecionado, setFiltroSelecionado] = useState("");
  const [filtroAdicionalTabela, setFiltroAdicionalTabela] = useState(
    new FiltroAdicionalTabela()
  );
  const [valorOrdenacao, setValorOrdenacao] = useState();

  const classes = useStyles();
  const refTabela = useRef(null);

  const { register, control } = useForm({
    reValidateMode: "onSubmit"
  });

  const textoBusca = useWatch({
    control,
    name: "textoBusca",
    defaultValue: ""
  });

  const onClickEditar = (id) => {
    history.push(`${RotasDTO.GrupoAssociativo}/cadastro/${id}`);
  };

  const onClickMudarStatus = useCallback(async (id, status) => {
    try {
      const mudarStatus = await MudarStatus(id, status);
      if (mudarStatus) {
        store.dispatch(
          alertaExibir({
            tipo: "success",
            mensagem: `O grupo foi ${
              status === false ? "inativado" : "ativado"
            } com sucesso!`
          })
        );
      }
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
    }
    if (refTabela?.current) refTabela.current.obterDadosPaginados();
  }, []);

  const onClickBotao = (tipo, parametros) => {
    switch (tipo) {
      case enumeradorBotao.Edicao:
        onClickEditar(parametros.id);
        break;
      case enumeradorBotao.Status:
        onClickMudarStatus(parametros.id, !parametros.ativo);
        break;
      default:
        break;
    }
  };

  const colunas = [
    new Coluna("grupoAssociativo", "Grupo Associativo", false, "38%", "38%"),
    new Coluna("clientes", "Clientes", false, "400px", "49%"),
    new ColunaComponentePersonalizado(
      "opcoes",
      "",
      ColunaMultiplosBotoes,
      onClickBotao,
      false,
      true,
      "12%",
      "12%",
      "pl-2"
    )
  ];

  const obterGrupos = async (parametros) => {
    const filtro = new FiltroDto(
      parametros.tamanhoPagina,
      parametros.pagina,
      parametros.pesquisar,
      parametros.filtroAdicional.ordenacao ?? "",
      [parametros.filtroAdicional.filtro] ?? "",
      parametros.filtroAdicional.sortOrder ?? ""
    );

    const resultado = await GrupoAssociativoHelper.listarGrupos(filtro);

    if (!resultado.sucesso) {
      store.dispatch(
        alertaExibir({
          tipo: "danger",
          mensagem: resultado.mensagem
        })
      );
      return new ResultadoPaginadoDto([], 1, 0, 0);
    }

    if (resultado?.data?.grupoAssociativos?.length === 0 ?? true)
      return new ResultadoPaginadoDto([], 1, 0, 0);

    const resLinhas = resultado?.data?.grupoAssociativos?.map((res) => {
      const clientes = res.clientes.map((item) => {
        if (res.clientes.length > 1) {
          if (item === res.clientes[res.clientes.length - 1]) {
            return item.nomeFantasia ?? item.razaoSocial;
          } else {
            return `${item.nomeFantasia ?? item.razaoSocial} | `;
          }
        } else {
          return item.nomeFantasia ?? item.razaoSocial;
        }
      });

      return new ListagemGruposAssociativosDto(
        res.id,
        res.descricao,
        clientes,
        res.ativo
      );
    });

    return new ResultadoPaginadoDto(
      resLinhas,
      resultado?.data?.paginaAtual,
      resultado?.data?.totalItens,
      resultado?.data?.totalPaginas
    );
  };

  const onChangeFiltrosTabela = async (parametros) => {
    return obterGrupos({
      tamanhoPagina: parametros.totalPagina,
      pagina: parametros.pagina,
      pesquisar: parametros.pesquisar,
      filtroAdicional: new FiltroAdicionalTabela(
        parametros.filtrosAdicionais?.ordenacao,
        parametros.filtrosAdicionais?.filtro,
        parametros.filtrosAdicionais?.sortOrder
      )
    });
  };

  const handleClickAtivarFiltro = () => {
    setFiltroAdicionalTabela(
      new FiltroAdicionalTabela(
        ordemSelecionada,
        filtroSelecionado,
        valorOrdenacao
      )
    );
  };

  const handleClickOrdemSelecionada = (event) => {
    const val = ordemSelecionada === event ? "" : event;
    setOrdemSelecionada(val);
  };

  const handleClickFiltroSelecionado = (event) => {
    const val = filtroSelecionado === event ? "" : event;
    setFiltroSelecionado(val);
  };

  const handleClickSortOrderSelecionado = (valor, idCampoSelecionado) => {
    const val =
      ordemColunaSelecionada === idCampoSelecionado && valor === ""
        ? ""
        : idCampoSelecionado;
    setOrdemColunaSelecionada(val);
    setValorOrdenacao(valor);
  };
  return (
    <Grid container>
      <Grid item xs={12} className="ml-1 mr-1">
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item md={5} sm={9}>
            <MaterialInputBusca
              type="text"
              id="textoBusca"
              name="textoBusca"
              label="Buscar"
              renderIconShowHide
              searchAdornment
              permiteValorBranco
              defaultValue={textoBusca ?? ""}
              ref={register}
            />
          </Grid>
          <Grid item xs={3} className={classes.itemFiltro}>
            <BotaoFiltroOrdenacao
              type="button"
              color={theme.color.secondaryBorderColor}
              background="transparent"
              label="Filtrar / Ordernar"
              icon={<TuneIcon />}
              className={classes.buttonFiltro}
              ordenacao={listarOrganizarPor()}
              ordenadorUm="Ordenador"
              ordenadorDois="Filtrar"
              ordemSelecionada={ordemSelecionada}
              ordemColunaSelecionada={ordemColunaSelecionada}
              filtros={listarFiltrarPor()}
              filtrosSelecionados={filtroSelecionado}
              onClickOrdenacao={handleClickOrdemSelecionada}
              onClickFiltro={handleClickFiltroSelecionado}
              onClickAtivarAgora={handleClickAtivarFiltro}
              onClickSortOrder={handleClickSortOrderSelecionado}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className={`${classes.tabela} mt-4`}>
        <TabelaPaginada
          ref={refTabela}
          onChangeFiltrosTabela={onChangeFiltrosTabela}
          colunas={colunas}
          pesquisar={textoBusca}
          filtrosAdicionais={filtroAdicionalTabela}
          paginaAtual={1}
          paginationPerPage={20}
          noHeader
        />
      </Grid>
      <Grid container spacing={2} className={classes.container}>
        <Grid item xs={3}>
          <Botao
            type="button"
            label="Novo Grupo"
            className={classes.button}
            onClick={() => {
              history.push(`${RotasDTO.GrupoAssociativo}/cadastro`);
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ListagemGrupoAssociativo;
