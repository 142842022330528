import styled from "styled-components";
import { makeStyles } from '@mui/styles';
import { Base } from 'componentes/cores';
import theme from 'themes';

export const useStyles = makeStyles(() => ({
  boxLoader: {
    textAlign: 'center',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  containerLoader: {
    padding: 20,
    backgroundColor: Base.White,
    borderRadius: 25,
    marginTop: 70
  },
  imagemLoader: {
    margin: '0 auto',
    marginTop: 20,
    marginBottom: 5,
    display: 'block'
  },
  msgLoading:{
    fontSize: 14,
    textAlign: 'center',
    width: '100%',
    color: theme.color.primaryBackgroud,
    marginBottom: 10
  }
}))

export const Wrapper = styled.div`
  width: 100%;
  display: grid;
  justify-content: center;
  justify-items: center;
  margin: 0 auto
`;
