const getDomain = () => {
  let domain;
  //return 'delta'

  const getLocal = () => {

    if(window.location.hostname.indexOf("k8s") > -1){
      return "delta";
    }

    let domain = window.location.hostname.split(".")[0];

    domain = domain !== "localhost" && isNaN(domain) ? domain : "delta";

    return domain;
  };

  const getDevHml = () => {
    let domain;

    switch (window.location.port) {
      case "32590":
      case "32593":
        domain = "thymos";
        break;
      case "32591":
      case "30003":
        domain = "delta";
        break;
      case "":
        const [sistema, _ambiente] = window.location.hostname.split(".");
        domain = sistema;
        break;
    }

    return domain;
  };

  if (window.location.hostname.indexOf("localhost") > -1 || window.location.hostname.indexOf("192.168") > -1 || window.location.hostname.indexOf("k8s") > -1) {
    domain = getLocal();
  } else {
    domain = getDevHml();
  }

  return domain;
};

export default getDomain;
