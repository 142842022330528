import { makeStyles } from '@mui/styles';
import { Base } from "../../../../componentes/cores";
import styled from "styled-components";
import theme from 'themes';

export const useStyles = makeStyles({
  container: {
    margin: "0 0 24px",
    width: "100%",
    "& .MuiOutlinedInput-notchedOutline": {
      border: `1px solid ${theme.color.secondaryBorderColor} !important`,
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: `${theme.color.secondaryBorderColor} !important`
    },
    "& .MuiFormLabel-root": {
      color: `${theme.color.secondaryBorderColor} !important`
    },
    "& .MuiInputBase-root": {
      color: `${theme.color.secondaryBorderColor} !important`
    },
    "& .MuiSvgIcon-root": {
      color: `${theme.color.secondaryBorderColor} !important`
    },
    "& .MuiGrid-root": {
      paddingTop: 5
    }
  }, 
  itemFiltro: {
    display: "flex",
    justifyContent: "flex-end"
  },
  buttonExcel: {
    fontSize: "18px",
    padding: "9.5px 5px",
    "& img": {
      width: "18px",
      marginLeft: "16px"
    }
  },
  buttonFiltro: {
    padding: "9.5px 5px"
  },
  tabela: {
    "& .MuiButton-root": {
      minWidth: "0px",
      "& .MuiSvgIcon-root": {
        fontSize: "20px",
        marginLeft: "2px"
      }
    }
  }
});

export const Icone = styled.img`
  margin-left: 2px;
  max-width: 18px;
  object-fit: cover;
`;
