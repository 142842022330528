import RotasDTOAreaLogada from "../../submodules/AreaLogada/rotas/rotasUrlDto";
import RotasDTODeltaTarifa from "../../submodules/DeltaTarifa/rotas/rotasUrlDto";

export const RotasDTO = {
  Agentes: "/cadastros/agentes",
  AgentesCadastro: "/cadastros/agentes/cadastro",
  AlteracaoMedicoes: "/operacaobackoffice/alteracao-medicoes",
  AlteracaoMedicoesConsolidadas:
    "/operacaobackoffice/alteracao-medicoes-consolidadas",
  AlterarSenha: "/conta/alterar-senha",
  ArvoreGerencial: "/gestaoplataforma/arvore-gerencial",
  Boletas: "/cadastros/boletas",
  BoletasCadastro: "/cadastros/boletas/cadastro",
  Clientes: "/cadastros/clientes",
  Colaborador: "/cadastros/colaboradores",
  Colaboradores: "/cadastros/colaboradores",
  CotacaoIncial: "/cotacao/inicial",
  Cotacoes: "/cotacao",
  DashboardConsumo: "/dashboard/consumo",
  Demo: "/demo",
  RotaUsinas: "/investimentos/rota-usinas",
  DistribuidoraPisCofins: "/cadastros/distribuidora-pis-cofins",
  DistribuidoraICMS: "/cadastros/distribuidora-ICMS",
  Distribuidoras: "/cadastros/distribuidoras",
  Economia: "/dashboard/economia",
  EmpresaGrupoDelta: "/cadastros/empresas-grupo-delta",
  FaturasCliente: "/financeiro/faturas",
  FaturasGD: "/financeiro/faturas-gd",
  FaturasColaborador: "/financeiro/faturas-colaborador",
  FaturaDistribuidoraCliente: "/fatura-distribuidora/cliente",
  FaturaDistribuidoraPreLead: "/fatura-distribuidora/pre-lead",
  Funcionalidades: "/gestaoplataforma/funcionalidades",
  GrupoAssociativo: "/gestaoplataforma/grupo-associativo",
  Home: "/",
  LeadClientes: "/lead/leadClientes",
  LeadUnidadesConsumidoras: "/lead/Leadunidades-consumidoras",
  ListagemMedicao: "/dashboard/listagem-medicao",
  Login: "/login",
  LoginSemCaptcha: "/login/sem-captcha",
  MedicaoConsolidada: "/dashboard/medicao-consolidada",
  Medidores: "/cadastros/medidores",
  CentralNotificacoesRelatorio: "/central-notificacoes/relatorios",
  Serasa: "/serasa/lista",
  OnBoarding: "/fluxo-migracao/migracao-ml",
  OnBoardingGD: "/fluxo-migracao/migracao-gd",
  PerfilAgenteCadastro: "/cadastros/agentes/perfis/cadastro",
  PerfisAcesso: "/gestaoplataforma/perfis-acesso",
  PreFaturamento: "/financeiro/pre-faturamento",
  ProcessamentoFatura: "/financeiro/processamento-fatura",
  ProcessamentoFaturaML: "/financeiro/processamento-fatura-ml",
  UnidadesConsumidoras: "/cadastros/unidades-consumidoras",
  UploadMatrizDesconto: "/operacaobackoffice/matriz-desconto",
  UploadMedicao: "/upload/medicao",
  UploadPatamares: "/upload/patamares",
  UploadProinfa: "/operacaobackoffice/proinfa",
  UploadTarifas: "/upload/tarifas",
  UploadMunicipios: "/operacaobackoffice/municipio",
  UploadCorreios: "/operacaobackoffice/correios",
  Usuarios: "/cadastros/usuarios",
  ValidarCotacoes: "/cotacao/validar",
  CotacaoEditarDesconto: "/cotacao/CotacaoEditarDesconto",
  Termos: "/cadastro/termos",
  BoletasGD: "/cadastros/boletas-gd",
  ConsumoEconomia: "/consumoeconomia/economia/resumo",
  Porhora: "/consumoeconomia/economia/por-hora",
  Diario: "/consumoeconomia/economia/diario",
  Mensal: "/consumoeconomia/economia/mensal",
  Geral: "/consumoeconomia/economia/geral",
  Ajuda: "/ajuda",
  MedidorGD: "/cadastros/medidor-gd",
  Atual: "/consumoeconomia/economia/atual",
  PrecosAnual: "/precos/anual",
  PrecosAtual: "/precos/atual",
  PrecosHistorico: "/precos/historico",
  PrecosConstantes: "/precos/Constantes",
  CotacoesGD: "/cotacaogd/inicial",
  ContaDocumentos: "/meus-documentos/documentos",
  ContaBaixarContratos: "/meus-documentos/baixar-contratos",
  EditarCupom: "/cadastros/editar-cupom",
  CotacoesGDListagem: "/cotacaogd",
  UploadIndices: "/operacaobackoffice/indices",
  CotacoesGDListagemSemFatura: "/cotacaogd-sem-fatura",
  CotacaoACL: "/cotacaoacl/inicial",
  AcompanhamentoCliente: "/acompanhamento-cliente",
  AnaliseDocumento: "/analise-documento",
  ConstantesIndicesGerais: "/constantes/indices-gerais",
  Categorias: "/categorias",
  Questionario: "/questionario",
  ListarOperacoes: "/listar-operacoes",
  MemberGetMemberDistribuidora: "/member-get-member/distribuidora",
  MemberGetMemberFatura: "/member-get-member/fatura",
  VisaoGeral: "/constantes/visao-geral",
  AssociacaoLuz: "/cadastros/associacao",
  MemberGetMemberPrecoFixo: "/member-get-member/preco-fixo",
  ScoreSerasaACL: "/serasa/score-acl",
  ScoreSerasaGD: "/serasa/score-gd",
  UsinasLuz: "/cadastros/usinas",
  ValidacaoJuridico: "/validacao-juridico",
  ConvideGanhe: "/convide-e-ganhe",
  Simulacoes: "/simulacoes",
  DownloadCupons: "/member-get-member/download-cupons",
  DownloadProcuracao: "/meus-documentos/procuracao",
  PowerBI: "/power-bi",
  UploadUsinasUnidade: "/operacaobackoffice/usinas-unidade",
  SuspensaoCancelamento: "/suspensao-cancelamento",
  AtualizarStatusMigracao: "/atualizar-status-migracao",
  VincularAssociacaoUC: "/vincular-associacao-na-uc",
  ...RotasDTOAreaLogada,
  ...RotasDTODeltaTarifa
};
