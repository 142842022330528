import MICROSERVICO from "../global/enumeradores/microServico";
import obterApi from "./api";

const api = () => obterApi(MICROSERVICO.MANAGEMENT);

export const listarOrganizarPor = () => {
  const ordernacoes = [
    {
      key: "AT",
      label: "Aberta"
    },
    {
      key: "FE",
      label: "Fechada"
    },
    {
      key: "IN",
      label: "Expirada"
    },
    {
      key: "CA",
      label: "Cancelada"
    },
    {
      key: "RE",
      label: "Rejeitada"
    },
    {
      key: "AN",
      label: "Anulada"
    }
  ];

  return ordernacoes;
};

export const listarFiltrarPor = () => {
  const filtros = [
    {
      key: "BRONZE",
      label: "Bronze"
    },
    {
      key: "PRATA",
      label: "Prata"
    },
    {
      key: "OURO",
      label: "Ouro"
    }
  ];

  return filtros;
};

export const ordenacaoPorColuna = () => {
  const sortOrder = [
    {
      key: "0",
      asc: "0",
      desc: "1",
      label: "Nome"
    },
    {
      key: "4",
      asc: "4",
      desc: "5",
      label: "Data"
    },
    {
      key: "6",
      asc: "6",
      desc: "7",
      label: "Validade"
    }
  ];

  return sortOrder;
};

// Listar planos
export const listarPlanos = async () => {
  return api().get("v1/tipoplanocotacao");
};

// Update Plano
export const updatePlano = async (planoGeradoId, cotacaoId) => {
  const metodo = "put";
  const url = "v1/CotacaoInicialPlanosGerados/SelecionarCotacao";
  const dados = {
    CotacaoInicialId: cotacaoId.id,
    CotacaoInicialPlanoGeradoId: planoGeradoId ?? 0
  };

  return api()[metodo](url, dados);
};

// Listar Cotações
export const listarCotacoes = async () => {
  return api().get("v1/CotacaoInicial");
};

// Listar Cotações Paginadas
export const listarPaginado = async (filtro) => {
  const filtroQueryString = new URLSearchParams(filtro).toString();

  return api().get(`v1/cotacaoInicial?${filtroQueryString}`);
};

// Contratar uma contação
export const contratarCotacao = async (id) => {
  return api().post(`v1/cotacaoInicial/contratar/${id}`);
};

// Atualizar cotações
export const atualizarDesconto = async (desconto, cotacaoId, economiaAnual, economiaMensal, economiaTotal,dataMigracao) => {
  const metodo = "post";
  const url = "v1/cotacaoInicial/alterar-desconto";
  console.log("datamigra" , dataMigracao);
  const dados = {
    Id: cotacaoId,
    PercentualDesconto: desconto,
    economiaAnual: economiaAnual,
    economiaMensal: economiaMensal,
    economiaTotal: economiaTotal,
    dataMigracao :dataMigracao
  };
  return api()[metodo](url, dados);
};

// Obter lista de precos
export const listarPrecosDetalhe = async (id) => {
  return api().get(
    `v1/CotacaoInicial/ObterPrecoCotacaoInicial?cotacaoId=${id}`
  );
};

export const liberaPropostaContrato = async (id, body) => {
  return api().patch(`v1/CotacaoInicial/liberapropostacontrato/${id}`, body);
};
