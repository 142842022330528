import React, { useState, useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";

// Icones
import TuneIcon from "@mui/icons-material/Tune";

// Form Hooks
import { useForm, useWatch } from "react-hook-form";

// Redux
import { useSelector } from "react-redux";

// Componentes
import { Grid } from "@mui/material";
import { Base } from "../../../../componentes/cores";
import BotaoCadastro from "../../../../componentes/botaoCadastro";
import Botao from "../../../../componentes/botao";
import Loader from "../../../../componentes/loader";
import ModalConfirmacao from "../../../../componentes/modalConfirmacao";
import BotaoFiltroOrdenacao from "../../../../componentes/botaoFiltroOrdenar";
import TabelaPaginada from "../../../../componentes/tabelaPaginada";
import Coluna from "../../../../componentes/tabelaPaginada/colunas/coluna";
import ResultadoPaginadoDto from "../../../../componentes/tabelaPaginada/resultadoPaginadoDto";
import ColunaComponentePersonalizado from "componentes/tabelaPaginada/colunas/colunaComponentePersonalizado";
import enumerador from './enumerador';
import ColunaMultiplosBotoes from "./colunaCustomizada";

// Serviços
import {
  listarClientes,
  deletarCliente,
  listarOrganizarPor,
  listarFiltrarPor,
  buscarStatus,
  efetivarCliente
} from "../../../../servicos/leadClientesServico";

//dtos
import LeadClienteListaDto from './leadClienteListaDto';
import FiltroAdicionalTabela from "./filtroAdicionalTabela";

// Rotas
import { RotasDTO } from "../../../../global/rotas/rotasUrlDto";

// Redux
import { store } from "../../../../global/redux";
import { alertaExibir } from "../../../../global/redux/modulos/alertas/actions";

// Styles
import { useStyles } from "./style";
import theme from 'themes';

// Útils
import {
  desabilitarCliente,
  desabilitarEmpresa,
  desabilitarUnidade
} from "../../../../global/redux/modulos/usuario/actions";
import ColunaCampoCheckBox from "componentes/tabelaPaginada/colunas/colunaCampoCheckBox";
import MaterialCheckBox from "componentes/checkbox";
import { InterfaceDTO } from "global/dto/interfacesDto";
import { usuarioPossuiFuncionalidade } from "servicos/funcionalidadesServico";
import MaterialInputBusca from "componentes/inputBusca";

const LeadClientesListar = () => {
  
  const classes = useStyles();
  const history = useHistory();
  const [carregandoClientes, setCarregandoClientes] = useState(false);
  const [listaCli, setLista] = useState([]);
  const usuario = useSelector((state) => state.usuario);
  const rotas = useSelector((state) => state.rotas);
  const { menu } = usuario;
  const permissaoImportarLeads = usuarioPossuiFuncionalidade(menu, rotas.rotaAtual.caminho, RotasDTO.LeadClientes, InterfaceDTO.PermissaoImportarLeads);
  const { empresaSelecionada } = usuario;

  const onClickEditar = (id) => {
    history.push(`${RotasDTO.LeadClientes}/cadastro/${id.id}`);
  };

  const [changeCheck, setCheck] = useState("");
  const [checkAllList, setCheckAllList] = useState(false);
  const [carregamentoInicial, setCarregamentoInicial] = useState(false);

  useEffect(() => {
    setCarregamentoInicial(false);
  }, [])


  const onClickExcluir = (item) => {
    setModalExclusao(item);
  };

  const onConfirmarExclusao = (item) => {
    try {
      deletar(item);
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
    }
  };
  const onClickBotao = (tipo, parametros) => {
    switch (tipo) {
      case enumerador.Edicao:
        onClickEditar(parametros);
        break;
      case enumerador.Deletar:
        onClickExcluir(parametros.id);
        break;
      default:
        break;
    }
  };

  const colunasCheckBox = [
    new ColunaCampoCheckBox(
      "selecionado",
      <MaterialCheckBox
        checked={checkAllList}
        onChange={
          () => {
            let checked = !checkAllList;
            for (var i = 0; i < listaCli.length; ++i) {
              if (listaCli[i].status === 'Não Importado') {
                listaCli[i].checked = checked;
              }
            }
            setLista(listaCli);
            setCheck("all" + checked.toString());
            setCheckAllList(!checkAllList);
          }

        }
      />,
      (a, b) => {
        for (var i = 0; i < listaCli.length; ++i) {

          if (listaCli[i].id === b && listaCli[i].status === 'Não Importado') {
            listaCli[i].checked = a;
          }
        }
        setLista(listaCli);
        setCheck(a.toString() + b.toString());
      },
      "70px",
      "70px"
    ),
    new Coluna("nomeFantasia", "Nome"),
    new Coluna("cnpjCpf", "CNPJ/CPF"),
    new Coluna("dataCadastro", "Data pré-cadastro"),
    new Coluna("origem", "Origem"),
    new Coluna("status", "Status"),
    new ColunaComponentePersonalizado(
      "opcoes",
      "Editar",
      ColunaMultiplosBotoes,
      onClickBotao
    )
  ];

  useEffect(() => {
    setCarregamentoInicial(true);
    if(carregamentoInicial) {
      handleClickAtivarAgora();
    }
  }, [empresaSelecionada])

  const onChangeFiltrosTabela = async (parametros) => {

    return await obterLeadClientes({
      tamanhoPagina: parametros.totalPagina,
      pagina: parametros.pagina,
      pesquisar: parametros.pesquisar,
      filtroAdicionalTabela: new FiltroAdicionalTabela(
        parametros.filtrosAdicionais?.ordenacao,
        parametros.filtrosAdicionais?.filtro
      )
    });
  };

  const obterLeadClientes = async (parametros) => {

    const resultado = await listarClientes(
      ordemSelecionada,
      parametros.filtroAdicionalTabela.filtro,
      parametros.pagina,
      empresaSelecionada,
      parametros.pesquisar
    );

    if (!resultado.status === 200) {
      store.dispatch(
        alertaExibir({
          tipo: "danger",
          mensagem: resultado.mensagem
        })
      );
      return new ResultadoPaginadoDto([], 1, 0, 0);
    }

    if (resultado?.length === 0 ?? true)
      return new ResultadoPaginadoDto([], 1, 0, 0);

    const allStatus = await buscarStatus();
    const resLinhas = await resultado.data.clientes?.map((res) => {

      const dataCadastro = window
        .moment(res?.dataCadastro)
        .format("DD-MM-YYYY");
      let status = allStatus.data.filter(x => x.codigo === res.status);
      return new LeadClienteListaDto(
        res.id,
        res.nomeFantasia === '' ? '-' : res.nomeFantasia,
        res.cnpjCpfFormatado,
        dataCadastro === '' ? '-' : dataCadastro,
        res.origem === 'SF' ? 'Sales Force' : res.origem,
        status === undefined || status[0] === undefined ? '-' : status[0].descricao,
        false
      );
    });
    // setPaginaAtual(resultado.data.paginaAtual);
    // setTotalPaginas(resultado.data.totalPaginas);  
    setLista(resLinhas);
    // debugger
    return new ResultadoPaginadoDto(
      resLinhas,
      resultado.data.paginaAtual,
      resultado.data.totalItens,
      resultado.data.totalPaginas
    );
  };

  const onClickNovoCliente = () => {
    history.push(`${RotasDTO.LeadClientes}/cadastro`);
  };

  const [modalExclusao, setModalExclusao] = useState(false);

  const deletar = async (item) => {

    try {
      setModalExclusao(false);
      setCarregandoClientes(true);
      const deletou = await deletarCliente(
        item
      );
      if (deletou) {

        setFiltroAdicionalTabela([]);
        setFiltroSelecionado([]);
        //history.go(0);
        store.dispatch(
          alertaExibir({
            tipo: "success",
            mensagem: `O lead foi deletado com sucesso!`
          })
        );
      }
      setCarregandoClientes(false);
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
      setCarregandoClientes(false);
    }
  };

  const { register, control } = useForm({
    reValidateMode: "onSubmit"
  });

  const textoBusca = useWatch({
    control,
    name: "textoBusca",
    defaultValue: ""
  });

  const textoParaBusca = useMemo(() => {
    return textoBusca?.length >= 3 ? textoBusca : "";
  }, [textoBusca]);

  const [ordemSelecionada, setOrdemSelecionada] = useState("");
  const [filtroSelecionado, setFiltroSelecionado] = useState("");
  const [filtroAdicionalTabela, setFiltroAdicionalTabela] = useState(
    new FiltroAdicionalTabela());

  useEffect(() => {
    store.dispatch(desabilitarCliente(true));
    store.dispatch(desabilitarUnidade(true));

    return () => {
      store.dispatch(desabilitarEmpresa(false));
      store.dispatch(desabilitarCliente(false));
      store.dispatch(desabilitarUnidade(false));
    };
  }, [desabilitarCliente, desabilitarUnidade]);

  const handleClickOrdemSelecionada = (event) => {
    const val = ordemSelecionada === event ? "" : event;
    setOrdemSelecionada(val);
  };

  const handleClickFiltroSelecionado = (event) => {

    const val = filtroSelecionado === event ? "" : event;
    setFiltroSelecionado(val);
    setFiltroAdicionalTabela(
      new FiltroAdicionalTabela(
        ordemSelecionada,
        val
      )
    );
  };

  const handleClickAtivarAgora = async () => {
    const filtro = [];
    if (filtroSelecionado) filtro.push(filtroSelecionado);
    setFiltroSelecionado(filtro);
    setFiltroAdicionalTabela(filtro);
  };
  const enviarFormulario = async () => {
    setCarregandoClientes(true);
    let ids = [];
    for (let i = 0; i < listaCli.length; i++) {
      let item = listaCli[i];
      if (item.checked) {
        ids.push(item.id);
      }
    }
    try {
      const salvou = await efetivarCliente({ ids: ids.toString() });

      if (salvou?.status === 200 || salvou?.status === 204) {
        setFiltroAdicionalTabela([]);
        setFiltroSelecionado([]);
        handleClickAtivarAgora();
        store.dispatch(
          alertaExibir({
            tipo: "success",
            mensagem: "Leads importados com sucesso!"
          })
        );
      }
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem: error?.response?.data?.message
        })
      );
    }

    setCarregandoClientes(false);
  }
  return (
    <Loader loading={carregandoClientes} className="w-auto">
      <ModalConfirmacao
        item={modalExclusao}
        onConfirmar={(item) => onConfirmarExclusao(item)}
        mensagem={`Tem certeza que deseja ${modalExclusao?.situacao?.toUpperCase() === "AT"
            ? "inativar"
            : "ativar"
          } esse Cliente?`}
        onCancelar={() => setModalExclusao(false)}
      />
      <Grid container spacing={3} alignItems="center" justifyContent="space-between">
        <Grid item xs={5}>
          <MaterialInputBusca
            type="text"
            id="textoBusca"
            name="textoBusca"
            label="Buscar"
            renderIconShowHide
            searchAdornment
            defaultValue={textoBusca ?? ""}
            ref={register}
            permiteValorBranco
          />
        </Grid>
        <Grid item xs={3} className={classes.itemFiltro}>
          <BotaoFiltroOrdenacao
            type="button"
            color={theme.color.secondaryBorderColor}
            background="transparent"
            label="Filtrar / Ordernar"
            icon={<TuneIcon />}
            className={classes.buttonFiltro}
            ordenacao={listarOrganizarPor()}
            ordenadorUm="Ordenador"
            ordenadorDois="Filtrar"
            ordemSelecionada={ordemSelecionada}
            filtros={listarFiltrarPor()}
            filtrosSelecionados={filtroSelecionado}
            onClickOrdenacao={handleClickOrdemSelecionada}
            onClickFiltro={handleClickFiltroSelecionado}
            onClickAtivarAgora={handleClickAtivarAgora}
          />
        </Grid>
        <Grid item xs={12}>
          <BotaoCadastro
            label="Novo lead cliente"
            color={Base.White}
            onClick={onClickNovoCliente}
          />
        </Grid>
        <Grid item xs={12} className="mt-1">
          <TabelaPaginada
            onChangeFiltrosTabela={onChangeFiltrosTabela}
            linhas={listaCli}
            colunas={colunasCheckBox}
            //colunas={colunas}
            pesquisar={textoBusca}
            filtrosAdicionais={filtroAdicionalTabela}
            paginaAtual={1}
            paginationPerPage={2}
            noHeader
          />
        </Grid>
        {
          permissaoImportarLeads ?
            <Grid container p={3} spacing={4} className={classes.containerSalvar}>
              <Grid item lg={3} md={4} sm={6}>
                <Loader loading={carregandoClientes}>
                  <Botao
                    type="submit"
                    label="Importar"
                    disabled={listaCli === undefined || listaCli.length == 0}
                    className={classes.button}
                    onClick={enviarFormulario}
                  />
                </Loader>
              </Grid>
            </Grid> : null
        }
      </Grid>
    </Loader>
  );
};

export default LeadClientesListar;
