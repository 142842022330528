import { makeStyles } from '@mui/styles';
import { green } from "@mui/material/colors";
import { Base } from "../cores";

export const useStyles = makeStyles(() => ({
  root: {
    "& label.Mui-focused": {
      color: `${Base.GreyDim}`
    },
    "& .MuiFormControlLabel-root": {
      marginLeft: 0
    }
  }
}));

export const radioClass = makeStyles(() => ({
  alinhamento: {
    color: green
  }
}));
