import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import { useForm } from "react-hook-form";
import { RestoreFromTrash } from "@mui/icons-material";
import { HtmlTooltip } from "paginas/Graficos/ConsumoAtual/style";
import MaterialInputTexto from "componentes/inputTexto/materialInput";
import { store } from "global/redux";
import { alertaExibir } from "global/redux/modulos/alertas/actions";
import { useStyles } from "./style";
import Helper from "../../helper";
import InputFile from "../InputFile";

const UploadVideos = ({
  videos,
  videosPostar,
  salvarListaVideos,
  camposInvalidos,
  salvarListaVideosPostar,
  imagens
}) => {
  const classes = useStyles();
  const { setError, errors, clearErrors } = useForm({
    reValidateMode: "onChange"
  });
  const [listaVideos, setListaVideos] = useState(videos);
  const [listaVideosPostar, setListaVideosPostar] = useState(videosPostar);

  const verificarCampos = (videos) => {
    let campoVazio = false;

    clearErrors();

    videos.forEach((item) => {
      if (!item.nome) {
        setError(`nome${item.ordem}`, {
          message: "Campo obrigatório!",
          type: "required"
        });
        campoVazio = true;
      }

      if (item?.nome?.length > 200) {
        setError(`nome${item.ordem}`, {
          message: "Quantidade máxima de 200 caracteres!",
          type: "invalid"
        });
        campoVazio = true;
      }

      if (!item.descricao) {
        setError(`descricao${item.ordem}`, {
          message: "Campo obrigatório!",
          type: "required"
        });
        campoVazio = true;
      }

      if (item?.descricao?.length > 1000) {
        setError(`descricao${item.ordem}`, {
          message: "Quantidade máxima de 1000 caracteres!",
          type: "invalid"
        });
        campoVazio = true;
      }

      if (item.arquivo?.length === 0) {
        setError(`arquivo${item.ordem}`, {
          message: "Campo obrigatório!",
          type: "required"
        });
        campoVazio = true;
      }

      if (item.ordem <= 0) {
        setError(`ordem${item.ordem}`, {
          message: "Campo inválido!",
          type: "invalid"
        });
        campoVazio = true;
      }
    });

    return campoVazio;
  };

  const verificarDuplicados = (videos) => {
    var ordems = videos.map((item) => Number(item.ordem));
    var duplicados = ordems.filter((item, idx) => ordems.indexOf(item) != idx);

    if (duplicados.length) {
      videos.forEach((item) => {
        if (duplicados.includes(Number(item.ordem))) {
          setError(`ordem${item.ordem}`, {
            message: "Campo duplicado!",
            type: "invalid"
          });
        }
      });
      return true;
    }
    return false;
  };

  const verificarExtensao = (file, index) => {
    clearErrors(`arquivo${videos[index].ordem}`);

    if (file?.type !== "video/mp4" && file?.type !== "video/quicktime") {
      setError(`arquivo${videos[index].ordem}`, {
        message: "Extensão inválida!",
        type: "invalid"
      });
      return true;
    }
    if (file?.size > Number(process.env.REACT_APP_UPLOADVIDEOTAMANHOMAXIMO)) {
      setError(`arquivo${videos[index].ordem}`, {
        message: "Vídeo acima do tamanho máximo permitido!",
        type: "invalid"
      });
      return true;
    }
    return false;
  };

  const verificarExtensaoImagem = (file, index) => {
    clearErrors(`arquivo${videos[index].ordem}`);
    if (file?.type !== "image/png" && file?.type !== "image/jpeg" && file?.type !== "image/JPG") {
      setError(`arquivo${videos[index].ordem}`, {
        message: "Extensão inválida!",
        type: "invalid"
      });
      return true;
    }
    if (file?.size > Number(process.env.REACT_APP_UPLOADVIDEOTAMANHOMAXIMO)) {
      setError(`arquivo${videos[index].ordem}`, {
        message: "Imagem acima do tamanho máximo permitido!",
        type: "invalid"
      });
      return true;
    }
    return false;
  };

  const removerLinhaDuplicada = (array) => {
    let a = array.concat();
    for (let i = 0; i < a.length; ++i) {
      for (let j = i + 1; j < a.length; ++j) {
        if (a[i] === a[j]) a.splice(j--, 1);
      }
    }

    return a;
  };

  const adicionarCamposVideo = () => {
    if (verificarCampos(listaVideos)) return;
    if (verificarDuplicados(listaVideos)) return;

    const listaSalva = listaVideos;
    const max = Math.max(...listaVideos.map((video) => video.ordem));
    const linhaVideo = {
      nome: "",
      descricao: "",
      ordem: max + 1,
      arquivo: "",
      extensao: "",
      arquivoImagem: "",
      extensaoImagem: "" 
    };
    listaSalva.push(linhaVideo);
    listaVideosPostar.push(linhaVideo);
    const removeDuplicados = removerLinhaDuplicada(listaVideosPostar);

    setListaVideos(listaSalva);
    salvarListaVideos(listaSalva);
    setListaVideosPostar(removeDuplicados);
    salvarListaVideosPostar(removeDuplicados);
  };

  const alterarListaVideos = (lista, index) => {
    listaVideosPostar.push(lista[index]);
    const removeDuplicados = removerLinhaDuplicada(listaVideosPostar);
    setListaVideos(lista);
    salvarListaVideos(lista);
    setListaVideosPostar(removeDuplicados);
    salvarListaVideosPostar(removeDuplicados);
  };

  const alterarListaImagens = (lista, index) => {
    
    listaVideosPostar.push(lista[index]);
    const removeDuplicados = removerLinhaDuplicada(listaVideosPostar);
    setListaVideos(lista);
    salvarListaVideos(lista);
    setListaVideosPostar(removeDuplicados);
    salvarListaVideosPostar(removeDuplicados);

  };

  const aterarCampo = (evento, index, campo) => {
    const listaVideosSalva = listaVideos;
    listaVideosSalva[index][campo] = evento.target.value;

    alterarListaVideos(listaVideosSalva, index);
  };

  const onAddArquivoUpload = (file, index) => {
    if (verificarExtensao(file, index)) return;
    const listaVideosSalva = listaVideos;
    listaVideosSalva[index].arquivo = file;
    listaVideosSalva[index].novaExtensao = `.${file?.name?.split(".").pop()}`;
    alterarListaVideos(listaVideosSalva, index);
  };

  const onAddArquivoUploadImagem = (file, index) => {
    
    if (verificarExtensaoImagem(file, index)) return;
    const listaVideosSalva = listaVideos;
    listaVideosSalva[index].arquivoImagem = file;
    listaVideosSalva[index].novaExtensaoImagem = `.${file?.name?.split(".").pop()}`;
    console.log("teste aqui", listaVideosSalva)
    alterarListaImagens(listaVideosSalva, index);
  };

  const onDeleteArquivoUpload = (lista, index) => {
    const listaVideosSalva = listaVideos;
    listaVideosSalva[index].arquivo = "";

    alterarListaVideos(listaVideosSalva, index);
  };

  const onDeleteArquivoUploadImagem = (lista, index) => {
    const listaVideosSalva = listaVideos;
    listaVideosSalva[index].arquivoImagem = "";

    alterarListaVideos(listaVideosSalva, index);
  };

  const excluirVideo = async (index) => {
    if (verificarDuplicados(listaVideos)) return;

    const listaVideosSalva = listaVideos;

    if (listaVideosSalva[index].id) {
      const { mensagem, sucesso } = await Helper.DeletarVideo(
        listaVideosSalva[index].id
      );

      if (!sucesso) {
        store.dispatch(
          alertaExibir({
            tipo: "danger",
            mensagem
          })
        );
        return;
      }
    }

    if (listaVideosSalva.length === 1) {
      const listaVideosVazia = [
        { nome: "", descricao: "", ordem: 1, arquivo: "", extensao: "" }
      ];
      setListaVideos(listaVideosVazia);
      salvarListaVideos(listaVideosVazia);
      setListaVideosPostar([]);
      salvarListaVideosPostar([]);
      return;
    }

    const removeitemPostar = listaVideosPostar.filter(
      (item) => item.ordem !== listaVideosSalva[index].ordem
    );
    listaVideosSalva.splice(index, 1);
    setListaVideos(listaVideosSalva);
    salvarListaVideos(listaVideosSalva);
    setListaVideosPostar(removeitemPostar);
    salvarListaVideosPostar(removeitemPostar);
  };

  useEffect(() => {
    if (camposInvalidos) {
      verificarCampos(listaVideos);
      verificarDuplicados(listaVideos);
    }
  }, [camposInvalidos]);

  return (
    <>
      <Grid item xs={12} className="font-weight-bold">
        Vídeo
      </Grid>
      {listaVideos.map((item, index) => (
        <>
          <Grid item lg={4} md={4} sm={12} xs={12}>
            <MaterialInputTexto
              type="text"
              id={`nome${item.ordem}`}
              name={`nome${item.ordem}`}
              label="Título"
              permiteValorBranco
              renderIconShowHide={false}
              defaultValue={item.nome}
              onBlur={(evento) => aterarCampo(evento, index, "nome")}
              errors={errors}
            />
          </Grid>
          <Grid item lg={5} md={5} sm={12} xs={12}>
            <MaterialInputTexto
              type="text"
              id={`descricao${item.ordem}`}
              name={`descricao${item.ordem}`}
              label="Descricão"
              permiteValorBranco
              renderIconShowHide={false}
              defaultValue={item.descricao}
              onBlur={(evento) => aterarCampo(evento, index, "descricao")}
              errors={errors}
            />
          </Grid>
          <Grid item lg={2} md={2} sm={10} xs={10}>
            <MaterialInputTexto
              type="number"
              id={`ordem${item.ordem}`}
              name={`ordem${item.ordem}`}
              label="Ordem"
              renderIconShowHide={false}
              defaultValue={item.ordem}
              onBlur={(evento) => aterarCampo(evento, index, "ordem")}
              errors={errors}
            />
          </Grid>
          <Grid item lg={1} md={1} sm={2} xs={2}>
            <button
              type="button"
              onClick={() => excluirVideo(index)}
              className={classes.botaoExcluir}
            >
              <HtmlTooltip title="Vídeo">
                <RestoreFromTrash />
              </HtmlTooltip>
            </button>
          </Grid>
          <Grid item xs={6}>
            <InputFile
              type="file"
              id={`arquivo${item.ordem}`}
              name={`arquivo${item.ordem}`}
              file={item.arquivo}
              dropAreaText="Solte o vídeo para anexá-lo ou clique para procurar!"
              onUpload={(file) => onAddArquivoUpload(file, index)}
              onDelete={(file) => onDeleteArquivoUpload(file, index)}
              errors={errors}
            />
          </Grid>
          <Grid item xs={6}>
            <InputFile
              type="file"
              id={`arquivoImagem${item.ordem}`}
              name={`arquivoImagem${item.ordem}`}
              file={item.arquivoImagem}
              dropAreaText="Solte a imagem para anexá-la ou clique para procurar!"
              onUpload={(file) => onAddArquivoUploadImagem(file, index)}
              onDelete={(file) => onDeleteArquivoUploadImagem(file, index)}
              errors={errors}
            />
          </Grid>
        </>
      ))}
      <Grid item xs={12} className="text-center">
        <button
          type="button"
          onClick={() => adicionarCamposVideo()}
          className={classes.botaoAdicionar}
        >
          + Adicionar mais vídeos
        </button>
      </Grid>
    </>
  );
};

UploadVideos.propTypes = {
  videos: PropTypes.array,
  videosPostar: PropTypes.array,
  salvarListaVideos: PropTypes.func,
  renderizaComponente: PropTypes.bool,
  camposInvalidos: PropTypes.bool,
  salvarListaVideosPostar: PropTypes.func
};

UploadVideos.defaultProps = {
  videos: [{ nome: "", descricao: "", ordem: 1, arquivo: "", extensao: "", arquivoImagem: "", extensaoImagem: "" }],
  videosPostar: [],
  salvarListaVideos: () => {},
  camposInvalidos: false,
  salvarListaVideosPostar: () => {}
};

export default UploadVideos;
