import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  container: {
    margin: "0",
    width: "100%",
    "& .MuiInputBase-root": {
      height: "46px"
    },
    "& .MuiFormLabel-root": {
      lineHeight: "1"
    }
  },
  desabledInput: {
    pointerEvents: "none",
    opacity: 0.5
  }
});
