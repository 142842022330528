import EnumDto from "global/dto/enumDto";
import { store } from "global/redux";
import { alertaExibir } from "global/redux/modulos/alertas/actions";
import { obterTipoPerfil } from "servicos/tipoPerfilServico";
import RetornoEndpointDto from "../../../../global/dto/retornoEndpointDto";
import APIUtil from "../../../../global/utils/apiUtil";
import { buscarPerfilPorTipo } from "../../../../servicos/perfisServico";
import { enviarEmailAcesso } from "../../../../servicos/usuariosServico";

export default class UsuarioHelper {
  static async obterPerfis(tipo) {
    try {
      const lista = await buscarPerfilPorTipo(tipo);

      return RetornoEndpointDto.SucessoSomenteDados(lista.data);
    } catch (error) {
      return APIUtil.tratarFalhaApi(
        error,
        "Ocorreu uma falha ao buscar os perfis"
      );
    }
  }

  static async obterTipoPerfis() {
    try {
      var tipos = await obterTipoPerfil();

      if (tipos.status === "204" || !tipos.data)
        return RetornoEndpointDto.Erro(
          "Não foi possivel obter os tipos de usuário"
        );

      var tiposLista = tipos.data.map(
        (tipo) => new EnumDto(tipo.tipo, tipo.nome, tipo.nome, tipo.nome)
      );

      return RetornoEndpointDto.SucessoSomenteDados(tiposLista);
    } catch (error) {
      return RetornoEndpointDto.Erro(
        error?.response?.data?.message ??
          "Não foi possivel obter os tipos de usuário"
      );
    }
  }

  static async aoEnviarEmailAcesso(id, empresaId, email) {
    try {
      await enviarEmailAcesso(id, empresaId, email);
      return RetornoEndpointDto.Sucesso("E-mail enviado com sucesso");
    } catch (error) {
      return RetornoEndpointDto.Erro(
        error?.response?.data?.message ??
          "Não foi possivel enviar o e-mail de acesso"
      );
    }
  }

  static exibirErro(mensagem) {
    store.dispatch(
      alertaExibir({
        tipo: "danger",
        mensagem: mensagem ?? "Erro interno, entre em contato com o suporte!"
      })
    );
  }

  static exibirSucesso(mensagem) {
    store.dispatch(
      alertaExibir({
        tipo: "success",
        mensagem
      })
    );
  }
}
