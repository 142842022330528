import React, { useCallback, useRef, useState, useEffect } from "react";

import { Grid } from "@mui/material";

import { useHistory } from "react-router-dom";

import Coluna from "componentes/tabelaPaginada/colunas/coluna";
import ResultadoPaginadoDto from "componentes/tabelaPaginada/resultadoPaginadoDto";
import ColunaComponentePersonalizado from "componentes/tabelaPaginada/colunas/colunaComponentePersonalizado";

import { Loader, TabelaPaginada, Botao, SelectArredondado } from 'componentes';

import ListaDto from "./ListaDto";
import { useStyles } from "./style";
import Helper from "./helper";
import ColunaMultiplosBotoes from "./colunaCustomizada";
import ColunaBaixar from "./colunaCustomizada/colunaBaixar";
import HelperTipos from '../Tipos/helper';
import HelperCategorias from '../Categorias/helper';

import { store } from "global/redux";
import { alertaExibir } from "global/redux/modulos/alertas/actions";
import { RotasDTO } from "global/rotas/rotasUrlDto";

import enumeradorBotao from "./enumeradores/enumeradorBotao";
import anos from "../Baixar/anos";
import moment from 'moment';

const RelatoriosEnvios = () => {

  const history = useHistory();
  const classes = useStyles();
  const refTabela = useRef(null);
  const [mes, setMes] = useState("0");
  const [ano, setAno] = useState("0");
  const [tipoRelatorioId, setTipoRelatorioId] = useState("0");
  const [tiposRelatorios, setTiposRelatorios] = useState();
  const [categoriaRelatorioId, setCategoriaRelatorioId] = useState("0");
  const [categoriasRelatorios, setCategoriasRelatorios] = useState();

  const [filtroAdicionalTabela, setFiltroAdicionalTabela] = useState({ tipoRelatorioId, ano, mes });

  const onClickEditar = (params) => {
    history.push(`${RotasDTO.RelatoriosEnvios}/cadastro/${params.id}`);
  };

  const onClickMudarStatus = useCallback(async (Id, Ativo) => {

    try {
      const mudarStatus = await Helper.MudarStatus({ Id, Ativo });

      if (mudarStatus) {
        store.dispatch(
          alertaExibir({
            tipo: "success",
            mensagem: `A categoria foi ${Ativo === false ? "inativado" : "ativado"
              } com sucesso!`
          })
        );
      }
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
    }

    if (refTabela?.current) refTabela.current.obterDadosPaginados();
  }, []);

  const onClickBotao = (tipo, parametros) => {

    switch (tipo) {
      case enumeradorBotao.Edicao:
        onClickEditar(parametros);
        break;
      case enumeradorBotao.Status:
        onClickMudarStatus(parametros.id, !parametros.ativo);
        break;
      default:
        break;
    }
  };

  const colunas = [
    new Coluna("nome", "Relatórios"),
    new Coluna("tipoRelatorio.nome", "Tipo de relatório"),
    new Coluna("clienteNome", "Cliente"),
    new Coluna("dataUpload", "Data de upload", false, "12%"),
    new ColunaComponentePersonalizado(
      "colunaBaixar",
      "Baixar",
      ColunaBaixar,
      onClickBotao,
      false,
      false,
      "10%"
    ),
    new ColunaComponentePersonalizado(
      "opcoes",
      "Editar",
      ColunaMultiplosBotoes,
      onClickBotao
    )
  ];

  const obterEnviados = async (params) => {

    if (Number(categoriaRelatorioId) !== 0) params.CategoriaRelatorioId = categoriaRelatorioId;
    if (Number(tipoRelatorioId) !== 0) params.TipoRelatorioId = tipoRelatorioId;
    if (Number(ano) !== 0) params.Ano = ano;
    if (Number(mes) !== 0) params.Mes = mes;

    const resultado = await Helper.Listar(params);

    const resLinhas = resultado.data.lista?.map((item) => {
      item.dataUpload = moment(item.dataUpload).format("L");

      return item;
    });

    return new ResultadoPaginadoDto(
      resLinhas,
      resultado.data.paginaAtual,
      resultado.data.totalItens,
      resultado.data.totalPaginas
    );
  };

  const onChangeFiltrosTabela = async (params) => {
    return obterEnviados(params);
  };

  useEffect(() => {
    setFiltroAdicionalTabela({
      categoriaRelatorioId,
      tipoRelatorioId,
      ano,
      mes
    })
  }, [categoriaRelatorioId, tipoRelatorioId, ano, mes]);

  const obterCategoriasRelatorios = async (parametros) => {

    const resultado = await HelperCategorias.Listar(parametros);

    if (!resultado.sucesso) {
      Helper.exibirErro(resultado.mensagem);
      return;
    }

    setCategoriasRelatorios([{
      id: "0",
      nome: 'Todos'
    }, ...resultado.data.lista]);
  };

  const obterTiposRelatorios = async (parametros) => {

    const resultado = await HelperTipos.Listar(parametros);

    if (!resultado.sucesso) {
      Helper.exibirErro(resultado.mensagem);
      return;
    }

    setTiposRelatorios([{
      id: "0",
      nome: 'Todos'
    }, ...resultado.data.lista]);
  };

  useEffect(() => {
    obterCategoriasRelatorios();
    obterTiposRelatorios();
  }, []);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container alignItems="center" justifyContent="space-between" spacing={2}>
          <Grid item md={3}>
            <Loader loading={false}>
              <SelectArredondado
                select
                id="CategoriaRelatorioId"
                name="CategoriaRelatorioId"
                label="Categoria de relatório"
                placeholder="Categoria de relatório"
                valueKey="id"
                valueName="nome"
                dataSource={categoriasRelatorios}
                value={categoriaRelatorioId}
                onChange={(e) => {
                  setCategoriaRelatorioId(e.target.value);
                }}
                permiteValorBranco
                marginBottom="0"
                styleForm={false}
              />
            </Loader>
          </Grid>
          <Grid item md={3}>
            <Loader loading={false}>
              <SelectArredondado
                select
                id="TipoRelatorioId"
                name="TipoRelatorioId"
                label="Tipo de relatório"
                placeholder="Tipo de relatório"
                valueKey="id"
                valueName="nome"
                dataSource={tiposRelatorios}
                value={tipoRelatorioId}
                onChange={(e) => {
                  setTipoRelatorioId(e.target.value);
                }}
                permiteValorBranco
                marginBottom="0"
                styleForm={false}
              />
            </Loader>
          </Grid>
          <Grid item md={3}>
            <SelectArredondado
              select
              id="Ano"
              name="Ano"
              label="Ano"
              placeholder="Ano"
              valueKey="valor"
              valueName="nome"
              dataSource={anos}
              value={ano}
              onChange={(e) => {
                setAno(e.target.value);
              }}
              permiteValorBranco
              marginBottom="0"
              styleForm={false}
            />
          </Grid>
          <Grid item md={3}>
            <SelectArredondado
              select
              id="Mes"
              name="Mes"
              label="Mês"
              placeholder="Mês"
              valueKey="valor"
              valueName="nome"
              dataSource={[{
                nome: 'Todos',
                valor: "0"
              }, {
                nome: 'Janeiro',
                valor: 1
              }, {
                nome: 'Fevereiro',
                valor: 2
              }, {
                nome: 'Março',
                valor: 3
              }, {
                nome: 'Abril',
                valor: 4
              }, {
                nome: 'Maio',
                valor: 5
              }, {
                nome: 'Junho',
                valor: 6
              }, {
                nome: 'Julho',
                valor: 7
              }, {
                nome: 'Agosto',
                valor: 8
              }, {
                nome: 'Setembro',
                valor: 9
              }, {
                nome: 'Outubro',
                valor: 10
              }, {
                nome: 'Novembro',
                valor: 11
              }, {
                nome: 'Dezembro',
                valor: 12
              }]}
              value={mes}
              onChange={(event) => {
                setMes(event.target.value);
              }}
              permiteValorBranco
              marginBottom="0"
              styleForm={false}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className="mt-5">
        <TabelaPaginada
          className={classes.table}
          ref={refTabela}
          onChangeFiltrosTabela={onChangeFiltrosTabela}
          filtrosAdicionais={filtroAdicionalTabela}
          colunas={colunas}
          paginaAtual={1}
        />
      </Grid>
      <Grid container spacing={2} className={classes.container}>
        <Grid item xs={6}>
          <Botao
            type="submit"
            label="Enviar relatório"
            className={classes.button}
            onClick={() => history.push(`${RotasDTO.RelatoriosEnvios}/cadastro`)}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default RelatoriosEnvios;