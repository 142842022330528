import { makeStyles } from '@mui/styles';
import theme from 'themes';

export const useStyles = makeStyles(() => ({
  cardUpload: {
    border: `1px solid ${theme.color.borderColorCardrepresetante}`,
    padding: "16px",
    "-webkit-backdrop-filter": "blur(2px)",
    backdropFilter: "blur(2px)",
    background: "transparent",
    minHeight: "350px"
  },
  tituloCard: {
    fontFamily: theme.font.family,
    fontSize: "18px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "-0.4px",
    color: theme.color.tituloColorCardRepresetante
  },
  subTituloCard: {
    paddingTop: "10px",
    paddingBottom: "10px",
    fontFamily: theme.font.family,
    fontSize: "16px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "-0.31px",
    minHeight:130,
  }
}));
