import React, { useEffect, useMemo, memo, useState } from "react";

// Redux
import { useSelector } from "react-redux";

// Componentes

// Serviços
import { usuarioTipoEnum } from "../../../../global/constantes";
import { listarEmpresasGrupoDeltaCombo } from "../../../../servicos/empresaServico";
import { listarClientesCombo } from "../../../../servicos/clientesServico";
import { listarUnidadesConsumidorasCombo } from "../../../../servicos/unidadesServico";

// Redux
import { store } from "../../../../global/redux";
import {
  salvarClientes,
  salvarEmpresas,
  salvarUnidades,
  selecionarEmpresa,
  selecionarCliente,
  selecionarUnidade,
  salvarUnidadeDefault,
  salvarEmpresaDefault,
  salvarClienteDefault
} from "../../../../global/redux/modulos/usuario/actions";
import { alertaExibir } from "../../../../global/redux/modulos/alertas/actions";
import SelectCustomizadoAutoComplete from "../../../selectCustomizadoAutoComplete";

const SelecaoClienteUnidade = () => {
  const usuario = useSelector((state) => state.usuario);
  const rotas = useSelector((state) => state.rotas);
  const [url, setUrl] = useState();
  const [params, setparams] = useState();
  const [bloquearEmpresa, setbloquearEmpresa] = useState(false);
  const [bloquearCliente, setbloquearCliente] = useState(false);
  const [bloquearUnidade, setbloquearUnidade] = useState(false);

  const {
    empresas,
    empresaSelecionada: empresaSelecionadaStore,
    empresaDesabilitada,
    clientes,
    clienteSelecionado: clienteSelecionadoStore,
    clienteDesabilitado,
    unidades,
    unidadeSelecionada: unidadeSelecionadaStore,
    unidadeDesabilitada,
    empresaDefault,
    clienteDefault,
    unidadeDefault,
    contas
  } = usuario;

  useEffect(() => {
    if (rotas?.rotaAtual) {
      setUrl(rotas.rotaAtual.path);
      setparams(rotas.rotaAtual.computedMatch.params.id);
    }
  }, [rotas]);

  const getOptionLabel = (empresa) => {
    if (
      usuario?.usuario?.tipoPerfil !== usuarioTipoEnum.colaborador &&
      empresa?.nomeFantasia?.startsWith("Luz ")
    ) {
      return empresa?.nomeFantasia?.substr(
        0,
        empresa?.nomeFantasia?.indexOf(" ")
      );
    }
    return empresa?.nomeFantasia;
  };

  const definirUnidadeDefaultClientMobile = () => {
    const urlWorkflow = "/onboarding";
    const urlDashBoard = "/dashboard";
    const urlFinanceiro = "/financeiro/faturas";
    const urlBaixarRelatorios = "relatorios/baixar/customizados";
    const urls = [
      urlWorkflow,
      urlDashBoard,
      urlFinanceiro,
      urlBaixarRelatorios
    ];

    urls.forEach((urlPage) => {
      if (url.includes(urlPage)) {
        store.dispatch(selecionarEmpresa(empresaDefault));
        store.dispatch(selecionarCliente(clienteDefault));
        store.dispatch(selecionarUnidade(unidadeDefault));
      }
    });
  };

  useEffect(() => {
    const urlUnidadeCadastro = "/cadastros/unidades-consumidoras/cadastro/:id?";
    const urlMedicorCadastro = "/cadastros/medidores/cadastro/:id?";

    if (url && contas?.length > 0) {
      definirUnidadeDefaultClientMobile();
    }

    if (params) {
      if (url === urlMedicorCadastro || url === urlUnidadeCadastro) {
        setbloquearEmpresa(true);
        setbloquearCliente(true);
        setbloquearUnidade(true);
      }
    } else if (url === urlUnidadeCadastro) {
      setbloquearUnidade(true);
    }
  }, [url, params]);

  const empresasLista = useMemo(() => empresas, [empresas]);

  const empresaSelecionada = useMemo(() => empresaSelecionadaStore, [
    empresaSelecionadaStore
  ]);

  const clienteSelecionado = useMemo(() => clienteSelecionadoStore, [
    clienteSelecionadoStore
  ]);

  const unidadeSelecionada = useMemo(() => unidadeSelecionadaStore, [
    unidadeSelecionadaStore
  ]);

  const bloquearUnidadeUnico = useMemo(() => {
    return unidades.length === 1 && !!unidadeSelecionada;
  }, [unidades, unidadeSelecionada]);

  const bloquearClienteUnico = useMemo(() => {
    return clientes.length === 1 && !!clienteSelecionado;
  }, [clientes, clienteSelecionado]);

  const bloquearEmpresaUnico = useMemo(() => {
    return empresas.length === 1 && !!empresaSelecionada;
  }, [empresas, empresaSelecionada]);

  const clientesLista = useMemo(() => clientes, [clientes]);

  const unidadesLista = useMemo(() => unidades, [unidades]);

  useEffect(() => {
    if (
      unidadeDesabilitada &&
      usuario?.usuario?.tipoPerfil !== usuarioTipoEnum.cliente
    )
      store.dispatch(selecionarUnidade());
  }, [empresaDesabilitada]);

  useEffect(() => {
    if (
      clienteDesabilitado &&
      usuario?.usuario?.tipoPerfil !== usuarioTipoEnum.cliente
    )
      store.dispatch(selecionarCliente());
  }, [empresaDesabilitada]);

  useEffect(() => {
    if (
      empresaDesabilitada &&
      usuario?.usuario?.tipoPerfil !== usuarioTipoEnum.cliente
    )
      store.dispatch(selecionarEmpresa());
  }, [empresaDesabilitada]);

  const obterUnidadesConsumidoras = async (cliente) => {
    try {
      if (cliente) {
        const lista = await listarUnidadesConsumidorasCombo(cliente);
        if (
          lista?.status === 200 &&
          lista?.data?.unidadeConsumidoraResponseList
        ) {
          const unidades =
            lista?.data?.unidadeConsumidoraResponseList?.filter(
              (item) => item?.status?.toLowerCase() !== "sp"
            ) ?? [];

          if (lista?.data?.unidadeConsumidoraResponseList?.length === 1) {
            store.dispatch(
              salvarUnidades(lista?.data?.unidadeConsumidoraResponseList ?? [])
            );
            store.dispatch(
              selecionarUnidade(
                lista?.data.unidadeConsumidoraResponseList[0]?.id
              )
            );
          } else if (lista?.data.unidadeConsumidoraResponseList.length === 0) {
            store.dispatch(salvarUnidades([]));
            store.dispatch(selecionarUnidade(null));
          } else if (unidades?.length === 1) {
            store.dispatch(salvarUnidades(unidades ?? []));
            store.dispatch(selecionarUnidade(unidades[0]?.id));
          } else if (lista?.data?.unidadeConsumidoraResponseList?.length > 1) {
            store.dispatch(salvarUnidades(unidades ?? []));
          }
        }
      }
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
    }
  };

  const handleUnidadesConsumidoras = (clienteId) => {
    localStorage.setItem("clienteId", clienteId);
    obterUnidadesConsumidoras(clienteId);
  };

  const onChangeCliente = (event) => {
    localStorage.setItem("clienteId", "");
    if (event?.id) {
      store.dispatch(selecionarCliente(event.id));
      store.dispatch(selecionarUnidade());
    } else {
      store.dispatch(selecionarCliente());
      store.dispatch(salvarUnidades());
      store.dispatch(selecionarUnidade());
    }
  };

  const obterClientes = async (empresa) => {
    try {
      if (empresa) {
        const lista = await listarClientesCombo(empresa, usuario?.token);
        if (lista?.status === 200 && lista?.data?.clientes) {
          store.dispatch(salvarClientes(lista?.data?.clientes ?? []));
          if (lista?.data?.clientes?.length === 1) {
            store.dispatch(selecionarCliente(lista?.data?.clientes[0]?.id));
            store.dispatch(
              salvarClienteDefault(lista?.data?.empresasGrupoDelta[0]?.id)
            );
            if (unidadesLista?.length === 1) {
              store.dispatch(selecionarUnidade(unidadesLista[0]?.id));
              store.dispatch(salvarUnidadeDefault(unidadesLista[0]?.id));
            }
          }
        }
      }
    } catch (error) {
      console.info(
        error?.response?.data?.message ??
          "Erro interno, entre em contato com o suporte!"
      );
    }
  };

  const handleListarClientes = (empresaId) => {
    localStorage.setItem("empresaId", empresaId);
    obterClientes(empresaId);
  };

  const onChangeEmpresa = (empresa) => {
    store.dispatch(selecionarCliente());
    store.dispatch(salvarClientes([]));
    store.dispatch(selecionarUnidade());
    store.dispatch(salvarUnidades([]));
    localStorage.setItem("empresaId", "");

    if (empresa?.id) {
      store.dispatch(selecionarEmpresa(empresa?.id));
    } else {
      store.dispatch(selecionarEmpresa());
    }
  };

  const obterEmpresasGrupoDelta = async () => {
    try {
      const lista = await listarEmpresasGrupoDeltaCombo(usuario?.token);
      if (lista?.status === 200 && lista?.data.empresasGrupoDelta) {
        store.dispatch(salvarEmpresas(lista?.data.empresasGrupoDelta ?? []));
        if (lista?.data.empresasGrupoDelta?.length === 1) {
          store.dispatch(
            selecionarEmpresa(lista?.data?.empresasGrupoDelta[0]?.id)
          );
          store.dispatch(
            salvarEmpresaDefault(lista?.data?.empresasGrupoDelta[0]?.id)
          );
        }
      }
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
    }
  };

  const onChangeUnidadeConsumidora = (event) => {
    store.dispatch(selecionarUnidade(event?.id));
    if (event?.id && contas?.length > 0) {
      store.dispatch(salvarUnidadeDefault(event?.id));
    }
  };

  const handleListarEmpresas = () => {
    obterEmpresasGrupoDelta();
  };

  useEffect(() => {
    if (!empresas.length) handleListarEmpresas();
  }, [empresas.length]);

  useEffect(() => {
    const empresaSalva = localStorage.getItem("empresaId");
    if (
      (empresaSelecionada && Number(empresaSalva) !== empresaSelecionada) ||
      (empresaSelecionada && !clientesLista?.length)
    )
      handleListarClientes(empresaSelecionada);
  }, [empresaSelecionada]);


  useEffect(() => {
    const clienteSalvo = localStorage.getItem("clienteId");
    if (clienteSelecionado && Number(clienteSalvo) !== clienteSelecionado)
      handleUnidadesConsumidoras(clienteSelecionado);
  }, [clienteSelecionado]);

  useEffect(() => {
    if (clienteSelecionado) {
      if (unidadeSelecionada === "") {
        if (unidadesLista.length === 1) {
          if (clienteSelecionado !== "") {
            // store.dispatch(selecionarUnidade(unidadesLista[0]?.id));
            store.dispatch(salvarUnidadeDefault(unidadesLista[0]?.id));
          } else {
            store.dispatch(selecionarUnidade());
            store.dispatch(salvarUnidadeDefault());
          }
        }
      }
    } else {
      store.dispatch(selecionarUnidade());
      store.dispatch(salvarUnidadeDefault());
    }
  }, [unidadesLista, clienteSelecionado, clientesLista]);

  return (
    <>
      <SelectCustomizadoAutoComplete
        disableClearable
        disableOpenOnFocus
        id="empresaSelecionada"
        name="empresaSelecionada"
        label="Empresa"
        value={empresaSelecionada}
        options={empresasLista}
        getOptionLabel={(option) => getOptionLabel(option)}
        onChange={(e, valor) => onChangeEmpresa(valor)}
        disabled={
          empresaDesabilitada || bloquearEmpresa || bloquearEmpresaUnico
        }
        valueName="nomeFantasia"
      />
      <SelectCustomizadoAutoComplete
        disableClearable
        disableOpenOnFocus
        id="ClienteSelecionado"
        name="ClienteSelecionado"
        label="Cliente"
        value={clienteSelecionado}
        options={clientesLista}
        getOptionLabel={(option) => option?.nomeFantasia}
        onChange={(e, valor) => onChangeCliente(valor)}
        disabled={
          Boolean(clienteDesabilitado) ||
          bloquearCliente ||
          bloquearClienteUnico
        }
        valueName="nomeFantasia"
      />
      <SelectCustomizadoAutoComplete
        id="unidadeSelecionada"
        name="unidadeSelecionada"
        label="Unidade"
        value={unidadeSelecionada}
        options={unidadesLista}
        getOptionLabel={(option) => option?.nomeUnidade}
        disabled={
          unidadeDesabilitada || bloquearUnidade || bloquearUnidadeUnico
        }
        onChange={(e, valor) => onChangeUnidadeConsumidora(valor)}
        valueName="nomeUnidade"
      />
    </>
  );
};

export default memo(SelecaoClienteUnidade);
