import React, { useState, useCallback, useEffect } from "react";

// Componentes
import { Grid } from "@mui/material";

// Redux
import { useSelector } from "react-redux";
import { store } from "../../../../global/redux";
import { alertaExibir } from "../../../../global/redux/modulos/alertas/actions";

// Serviços
import { buscarPorUnidade } from "../../../../servicos/consolidacaoMedidorServico";

// Componentes
import Loader from "../../../../componentes/loader";

// Ícones
import IconClock from "../../../../assets/icones/medidores/completos.png";
import IconClockLuz from "../../../../assets/icones/medidores/completos-luz.png";

import getDomain from "global/getDomain";

// Styles
import { Container } from "../../Dashboard/style";
import { useStyles, Icone, BoxOption, Option, Status } from "./style";

const MedidoresCompletos = (props) => {
  const classes = useStyles();
  const [activeMenuId, setActiveMenuId] = useState();
  const usuario = useSelector((state) => state.usuario);
  const { unidadeSelecionada } = usuario;
  const [listaMedidores, setListaMedidores] = useState([]);
  const [medidoresCompletos, setMedidoresCompletos] = useState();
  const [totalMedidores, setMedidoresincompletos] = useState();
  const [carregandoMedidores, setCarregandoMedidores] = useState(false);
  const values = props;

  const onClickCard = async (codigoMedidorCCEE) => {
    try {
      setCarregandoMedidores(true);
      const medidor = listaMedidores.find(
        (x) => x.codigoMedidorCCEE === codigoMedidorCCEE
      );
      values.medidorSelecionado(medidor);
      setCarregandoMedidores(false);
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
      setCarregandoMedidores(false);
    }
  };

  useEffect(() => {
    values.medidorSelecionado(null);
  }, [unidadeSelecionada, values?.dataSelecionada]);

  useEffect(() => {
    onClickCard(listaMedidores[0]?.codigoMedidorCCEE);
    setActiveMenuId(listaMedidores[0]?.id);
  }, [listaMedidores]);

  const handleListarMedidores = async (data) => {
    try {
      setCarregandoMedidores(true);
      const dataSelecionada = data ?? new Date();
      const lista = await buscarPorUnidade(
        unidadeSelecionada,
        dataSelecionada.getMonth() + 1,
        dataSelecionada.getFullYear()
      );
      if (
        lista?.status === 200 &&
        lista?.data &&
        lista?.data?.listaMedicaoConsolidacao
      ) {
        const listaEditada = lista?.data?.listaMedicaoConsolidacao;
        if (lista?.data?.listaMedicaoConsolidacao.length > 1) {
          listaEditada.unshift({
            codigoMedidorCCEE: "Ver todos",
            consumoForaPonta: lista?.data?.consumoForaPonta,
            consumoPonta: lista?.data?.consumoPonta,
            consumoTotal: lista?.data?.consumoTotal,
            contagemHorasCompletas: lista?.data?.contagemHorasCompletas,
            contagemHorasIncompletas: lista?.data?.contagemHorasIncompletas,
            totalHorasMes: lista?.data?.totalHorasMes
          });
        }
        setListaMedidores(listaEditada);
        setMedidoresCompletos(lista?.data?.medidoresCompletos);
        setMedidoresincompletos(lista?.data?.totalMedidores);
      }
      setCarregandoMedidores(false);
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
      setCarregandoMedidores(false);
    }
  };

  const obterMedidores = useCallback(async (data) => {
    handleListarMedidores(data, "", "");
  }, []);

  useEffect(() => {
    obterMedidores(values?.dataSelecionada);
  }, [values?.dataSelecionada]);

  const medidores = useCallback(() => {
    return listaMedidores;
  });

  return <>
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Container className={classes.trace}>
          <Grid
            container
            justifyContent="center"
            alignContent="center"
            alignItems="center"
            className={classes.topo}
          >
            <Grid item xs={3}>
              <Icone src={getDomain() === 'luz' ? IconClockLuz : IconClock} />
            </Grid>
            <Grid item xs={9}>
              <Grid item className={classes.dados}>
                {`${medidoresCompletos ?? 0}/${totalMedidores ?? 0}`}
              </Grid>
              <Grid item className={classes.info}>
                Medidores completos
              </Grid>
            </Grid>
          </Grid>
          <Loader loading={carregandoMedidores} className="w-auto">
            <Grid item justifyContent="center" className={classes.subCard}>
              <nav>
                {medidores() && medidores()?.length
                  ? medidores().map((medidor, i) => {
                      return (
                        <Option
                          key={i}
                          className={`${
                            medidor?.id === activeMenuId ? "active" : ""
                          }`}
                          onClick={() => {
                            setActiveMenuId(medidor.id);
                            onClickCard(medidor.codigoMedidorCCEE);
                          }}
                        >
                          <BoxOption>
                            {medidor?.codigoMedidorCCEE}
                            {medidor?.codigoMedidorCCEE !== "Ver todos" ? (
                              <Status
                                className={
                                  medidor.statusFechamento === "Apto"
                                    ? "green"
                                    : "red"
                                }
                              />
                            ) : null}
                          </BoxOption>
                        </Option>
                      );
                    })
                  : null}
              </nav>
            </Grid>
          </Loader>
        </Container>
      </Grid>
    </Grid>
  </>;
};

export default MedidoresCompletos;
