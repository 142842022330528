import { makeStyles } from "@mui/styles";
import theme from "../../../themes";
import { Base } from "../../../componentes/cores";

// formControl: {
//   "& .MuiInputBase-root": {
//     backgroundColor: Base.BlueTuna,
//     borderRadius: 4,
//     height: "36px"
//   },
//   "& .MuiSelect-icon": {
//     color: theme.color.labelColorSelect
//   },
//   "& option": {
//     color: theme.color.labelColorSelect
//   },
//   "& .MuiSelect-select.MuiSelect-select": {
//     overflow: "hidden",
//     whiteSpace: "nowrap",
//     textOverflow: "ellipsis",
//     color: theme.color.labelColorSelect
//   },
//   "& .MuiSelect-outlined.MuiSelect-outlined": {
//     padding: "0 40px 0 20px "
//   }
// },

export const useStyles = makeStyles({
  container: {
    width: "100%",
    margin: 0,
    paddingBottom: 0,
    paddingRight: 0,
    "& fieldset": {
      borderColor: "rgba(0, 0, 0, 0.26) !important"
    },
    "& input": {
      padding: "7px 5px !important",
      fontSize: "14px"
    },
    "& button": {
      padding: "0"
    },
    "& .MuiInputAdornment-positionEnd": {
      marginLeft: 0
    },
    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: 0
    }
  },
  formControl: {
    "& .MuiSelect-select.MuiSelect-select": {
      padding: "7px 5px !important",
      fontSize: "14px"
    },

    "& .MuiInputBase-input": {
      padding: "7px 5px !important",
      fontSize: "14px"
    }
  },

  headCellsActive: {
    paddingTop: "15px",
    paddingBottom: "15px",
    paddingLeft: "30px",
    paddingRight: "30px"
  },
  headCellsInactive: {
    backgroundColor: "rgb(233, 236, 239)",
    paddingTop: "15px",
    paddingBottom: "15px",
    paddingLeft: "30px",
    paddingRight: "30px"
  },
  headCellsInactiveData: {
    backgroundColor: "rgb(233, 236, 239)"
  }
});

export const customStyles = {
  table: {
    style: {
      background: "transparent"
    }
  },
  headRow: {
    style: {
      background: "transparent",
      marginBottom: "3px",
      borderBottom: "none"
    }
  },
  headCells: {
    style: {
      background: Base.GreyDisabled,
      marginLeft: "6px",
      fontSize: "16px",
      textAlign: "center",
      justifyContent: "center",
      color: theme.color.primary
    }
  },
  rows: {
    style: {
      background: "transparent",
      marginBottom: "3px",
      marginTop: "3px",
      minHeight: "30px",
      ":not(:last-of-type)": {
        borderBottom: "none"
      }
    }
  },
  cells: {
    style: {
      marginLeft: "6px",
      background: Base.White,
      fontSize: "14px",
      padding: "4px",
      textAlign: "center !important",
      width: "100%",
      "&.rdt_TableCell div:first-child": {
        width: "100%"
      }
    }
  }
};
