import { makeStyles } from '@mui/styles';
import { Base } from "componentes/cores";
import theme from 'themes';

export const useStyles = makeStyles(() => ({
  "MuiGrid-container": {
    color: `${Base.OffWhite} !important`
  },
  container: {
    margin: "0",
    width: "100%",
    "& .MuiInputBase-root": {
      borderColor: `${theme.color.secondaryBorderColor} !important`,
      color: `${theme.color.secondaryBorderColor} !important`,
      "&:hover": {
        borderColor: `${theme.color.secondaryBorderColor} !important`,
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: `${theme.color.secondaryBorderColor} !important`
        }
      }
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: `${theme.color.secondaryBorderColor} !important`,
      "&:hover": {
        borderColor: `${theme.color.secondaryBorderColor} !important`
      }
    },
    "& .MuiButtonBase-root": {
      color: `${theme.color.secondaryBorderColor} !important`
    }
  },
  search: {
    "& .MuiOutlinedInput-input": {
      padding: "18.5px ​14p"
    },
    "& label.Mui-focused": {
      color: `${theme.color.secondaryBorderColor} !important`
    },
    "& .MuiInput-underline:after": {
      borderColor: `${theme.color.secondaryBorderColor} !important`
    },
    "& .MuiFormLabel-root": {
      color: `${theme.color.secondaryBorderColor} !important`
    },
    "& .MuiOutlinedInput-root": {
      "& .MuiOutlinedInput-input": {
        color: `${theme.color.secondaryBorderColor} !important`
      },
      "& .MuiInputAdornment-root": {
        color: `${theme.color.secondaryBorderColor} !important`
      },
      "& fieldset": {
        borderColor: `${theme.color.secondaryBorderColor} !important`
      },
      "&:hover fieldset": {
        borderColor: `${theme.color.secondaryBorderColor} !important`
      },
      "&.Mui-focused fieldset": {
        borderColor: `${theme.color.secondaryBorderColor} !important`
      }
    }
  },
  itemFiltro: {}
}));
