import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";

import {
  DashboardView,
  CategoriesContainer,
  CategorieCard,
  CategorieTitle,
  InfoContainer,
  InfoCard,
  InfoTitle,
  InfoText,
  SocialList,
  CategorieTitleText,
  SocialLink
} from "./dashboard.styled";

import AssignmentIndOutlinedIcon from "@mui/icons-material/AssignmentIndOutlined";
import EventNoteOutlinedIcon from "@mui/icons-material/EventNoteOutlined";
import RecordVoiceOverOutlinedIcon from "@mui/icons-material/RecordVoiceOverOutlined";
import PublicOutlinedIcon from "@mui/icons-material/PublicOutlined";
import AnnouncementOutlinedIcon from "@mui/icons-material/AnnouncementOutlined";
import NotInterestedOutlinedIcon from "@mui/icons-material/NotInterestedOutlined";

import bgCustomizados from "./assets/images/bg-customizados.png";
import bgSetoriais from "./assets/images/bg-setoriais.png";
import linkedinIcon from "./assets/images/linkedin-icon.svg";
import facebookIcon from "./assets/images/facebook-icon.svg";
import instagramIcon from "./assets/images/instagram-icon.svg";

import { alertaExibir } from "global/redux/modulos/alertas/actions";
import { store } from "global/redux";

import DashboardHelper from "./helper";
import { salvarMenuSelecionadoColecao } from "global/redux/modulos/rotas/actions";
import { useSelector } from "react-redux";

const RelatoriosDashboard = () => {
  const usuario = useSelector((state) => state.usuario);
  const history = useHistory();
  const { menu } = usuario;
  const [nomeGestor, setGestor] = useState();
  const [telefoneGestor, setTelefoneGestor] = useState();
  const [emailGestor, setEmailGestor] = useState();

  const ObterGestor = async () => {
    const retorno = await DashboardHelper.obterGestor();

    if (!retorno.sucesso) {
      store.dispatch(
        alertaExibir({
          tipo: "danger",
          mensagem: retorno.mensagem
        })
      );
      return;
    }

    //console.log('retorno', retorno.data.usuario);

    setGestor(retorno?.data?.usuario?.nome);
    setTelefoneGestor(retorno?.data?.usuario?.telefone);
    setEmailGestor(retorno?.data?.usuario?.email);
  };

  useEffect(() => {
    ObterGestor();
  }, []);

  const salvarMenuColecao = (rota, colecao) => {
    if (rota && colecao?.length) {
      const segmento = colecao.find((itemColecao) =>
        itemColecao.functionality.funcionalidadeColecao.find(
          (funcionalidade) => funcionalidade.caminho === rota
        )
      );

      if (segmento?.functionality?.funcionalidadeColecao?.length) {
        store.dispatch(
          salvarMenuSelecionadoColecao([
            ...segmento?.functionality?.funcionalidadeColecao
          ])
        );
      }
    }
  };

  const SocialIcon = (props) => {
    return (
      <>
        {props.icon ? <img src={props.icon} /> : <NotInterestedOutlinedIcon />}
      </>
    );
  };

  const GoToThymosResearch = () => {
    salvarMenuColecao("RelatoriosSetoriaisBaixar", menu);
    window.scrollTo(0, 0);
    history.push("/relatorios/baixar/setoriais", { filtroCategoriaId: 12 });
  };

  const GoToCustomizados = () => {
    salvarMenuColecao("RelatoriosCustomizadosBaixar", menu);
    window.scrollTo(0, 0);
    history.push("/relatorios/baixar/customizados");
  };

  const GoToSetoriais = () => {
    salvarMenuColecao("RelatoriosSetoriaisBaixar", menu);
    window.scrollTo(0, 0);
    history.push("/relatorios/baixar/setoriais");
  };

  return (
    <DashboardView>
      <CategoriesContainer>
        <CategorieCard
          bg={bgCustomizados}
          type="customizados"
          onClick={() => GoToCustomizados()}
        >
          {/* <Link onClick={() => salvarMenuColecao("RelatoriosCustomizadosBaixar", menu)} to="/relatorios/baixar/customizados" style={{ textDecoration: 'none', color: '#fff' }}> */}
          <CategorieTitle>
            <AssignmentIndOutlinedIcon style={{ fontSize: "80px" }} />
            <CategorieTitleText>Relatórios Customizados</CategorieTitleText>
          </CategorieTitle>
          {/* </Link> */}
        </CategorieCard>

        <CategorieCard
          bg={bgSetoriais}
          type="setoriais"
          onClick={() => GoToSetoriais()}
        >
          {/* <Link onClick={() => salvarMenuColecao("RelatoriosSetoriaisBaixar", menu)} to="/relatorios/baixar/setoriais" style={{ textDecoration: 'none', color: '#fff' }}> */}
          <CategorieTitle>
            <EventNoteOutlinedIcon style={{ fontSize: "80px" }} />
            <CategorieTitleText>Relatórios Setoriais</CategorieTitleText>
          </CategorieTitle>
          {/* </Link> */}
        </CategorieCard>

      </CategoriesContainer>

      <InfoContainer>
        <InfoCard className="gestor">
          <InfoTitle>
            <RecordVoiceOverOutlinedIcon /> Meu gestor
          </InfoTitle>

          <InfoText>{nomeGestor} </InfoText>
          <InfoText>{telefoneGestor}</InfoText>
          <InfoText>{emailGestor}</InfoText>
        </InfoCard>

        <InfoCard>
          <InfoTitle>
            <PublicOutlinedIcon /> Redes sociais
          </InfoTitle>

          <InfoText>
            Acompanhe a maior consultoria em energia do Brasil nas redes sociais
            e fique por dentro das análises de nossos especialistas.
          </InfoText>

          <SocialList>
            <SocialLink
              href="https://www.linkedin.com/company/thymos-energia/"
              target="_blank"
            >
              <SocialIcon icon={linkedinIcon} />
            </SocialLink>

            <SocialLink
              href="https://www.instagram.com/thymosenergia/"
              target="_blank"
            >
              <SocialIcon icon={instagramIcon} />
            </SocialLink>

            <SocialLink
              href="https://pt-br.facebook.com/thymosenergia/"
              target="_blank"
            >
              <SocialIcon icon={facebookIcon} />
            </SocialLink>
          </SocialList>
        </InfoCard>

        <InfoCard
          onClick={() => GoToThymosResearch()}
          style={{ cursor: "pointer" }}
        >
          <InfoTitle>
            <AnnouncementOutlinedIcon />
            Thymos research
          </InfoTitle>

          <InfoText>
            Se você não possui acesso ao Thymos research, entre em contato com
            nosso time comercial e assine o relatório mais completo sobre o
            setor de energia, com dados, análises e novidades
          </InfoText>
        </InfoCard>
      </InfoContainer>
    </DashboardView>
  );
};

export default RelatoriosDashboard;
