import MICROSERVICO from "../global/enumeradores/microServico";
import obterApi from "./api";
const api = () => obterApi(MICROSERVICO.MEASUREMENT);
export const validandoDesconto = async (id, desconto, consumidorLivre, valorMedioConta, cotacaoSelecionada, dataVigencia, dataMigracao) => {
  console.log("Data migracao no post ", dataMigracao);
  const metodo = "post";
  const url = "v1/planossimulados/retornodescontofixo";
  const dados = {
    idCotacao: id,
    desconto: desconto,
    consumidorLivre: consumidorLivre,
    valorMedioConta: valorMedioConta,
    idPlanoCotacaoColl: cotacaoSelecionada,
    dataVigencia: dataVigencia,
    dataMigracao: dataMigracao
  };

  return api()[metodo](url, dados);
};