import { useColorlibStepIconStyles } from "../style";
import clsx from "clsx";
import React from "react";

export default function StepIcone(props) {
  const { active, completed, icons, vertical } = props;
  const classes = useColorlibStepIconStyles({vertical});

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed
      })}
    >
      {icons[String(props.icon - 1)]}
    </div>
  );
}
