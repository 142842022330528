import React, { useState, useRef, useEffect, useCallback } from "react";

import { Grid } from "@mui/material";
import { useHistory, useParams } from "react-router-dom";
import { useForm, useWatch } from "react-hook-form";
import Coluna from "componentes/tabelaPaginada/colunas/coluna";
import ColunaComponentePersonalizado from "componentes/tabelaPaginada/colunas/colunaComponentePersonalizado";

import MaterialInputTexto from "componentes/inputTexto/materialInput";
import ResultadoPaginadoDto from "componentes/tabelaPaginada/resultadoPaginadoDto";

import { SelectArredondado, TabelaPaginada, Switch, Loader } from "componentes";
import { store } from "global/redux";
import { alertaExibir } from "global/redux/modulos/alertas/actions";

import { useStyles } from "./style";
import Helper from "./helper";

const ColunaAssociado = ({ onClick, parametros }) => {

  const [ativo, setAtivo] = useState(parametros.ativo);

  return (
    <Grid container justifyContent="center" alignItems="center">
      <Switch
        checked={ativo}
        onChange={(value) => {
          setAtivo(value);
          parametros.ativo = value;
          onClick(parametros);
        }}
      />
    </Grid>
  );
};

const ColunaVingencia = ({ onClick, parametros }) => {

  const [possuiVigencia, setPossuiVigencia] = useState(
    parametros.possuiVigencia
  );

  return (
    <Grid container justifyContent="center" alignItems="center">
      <Switch
        disabled={!parametros.ativo}
        checked={possuiVigencia}
        onChange={(value) => {
          setPossuiVigencia(value);
          parametros.possuiVigencia = value;
          onClick(parametros);
        }}
      />
    </Grid>
  );
};

const onChangeVingencia = (params) => {
  console.log("onChangeVingencia", params);
};

const RelatoriosAssociar = () => {
  const history = useHistory();

  const classes = useStyles();
  const refTabela = useRef(null);

  const { id: idUrl } = useParams();

  const [listaClientes, setListaClientes] = useState();
  const [clienteId, setClienteId] = useState("0");
  const [carregando, setCarregando] = useState();

  const [filtroAdicionalTabela, setFiltroAdicionalTabela] = useState();

  const onClickBotao = (tipo, parametros) => {};

  const { register, errors, handleSubmit, setValue, control } = useForm({
    reValidateMode: "onSubmit"
  });

  const NomeTipoRelatorio = useWatch({
    control,
    name: "NomeTipoRelatorio",
    defaultValue: ""
  });

  const onChangeFiltrosTabela = async (parametros) => {

    if ((Number(clienteId) == 0)) return;

    const params = {};
    params.clienteId = clienteId;
    params.NomeTipoRelatorio = NomeTipoRelatorio;
    params.totalPagina = parametros.totalPagina;
    params.pagina = parametros.pagina;

    const retorno = await Helper.ListaTipoRelatorioParaAssociacao(params);

    return new ResultadoPaginadoDto(
      retorno.data.lista,
      1,
      retorno.data.totalItens,
      retorno.data.totalPaginas
    );
  };

  const ObterListaClientes = async () => {
    setCarregando(true);

    const { data } = await Helper.ListarClientes();

    setListaClientes([{
      id: "0",
      nomeFantasia: ' -- Selecione -- '
    },...data.clientes]);

    setCarregando(false);
  };

  const onChangeAtivo = async (params) => {
    
    let response;

    setCarregando(true);

    if (params.ativo) {
      response = await Helper.Associa(params);
    } else {
      response = await Helper.Desassocia(params);
    }

    setCarregando(false);
  };

  useEffect(() => {
    ObterListaClientes();
  }, []);

  const obterPorId = useCallback(async () => {
    if (!idUrl || idUrl <= 0) return;

    setClienteId(idUrl);
    setFiltroAdicionalTabela({
      clienteId: idUrl
    });
  }, [idUrl]);

  useEffect(() => {
    obterPorId();
  }, [obterPorId]);

  const colunas = [
    new ColunaComponentePersonalizado(
      "ativo",
      "Associado",
      ColunaAssociado,
      onChangeAtivo,
      false,
      false,
      "110px",
      "110px",
      ""
    ),
    new Coluna("nomeCategoriaRelatorio", "Categoria Relatório"),
    new Coluna("nomeTipoRelatorio", "Tipo de Relatório"),
    new ColunaComponentePersonalizado(
      "possuiVigencia",
      "Possui Vigencia?",
      ColunaVingencia,
      onChangeAtivo,
      false,
      false,
      "160px",
      "160px",
      ""
    )
  ];

  return <>
    <Loader loading={carregando}>
      <Grid container>
        <Grid item xs={12}>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            spacing={2}
          >
            <Grid item lg={6} md={6} sm={12}>
              <SelectArredondado
                id="ClienteId"
                name="ClienteId"
                valueKey="id"
                marginBottom="0"
                valueName="nomeFantasia"
                dataSource={listaClientes}
                label="Cliente"
                defaultValue={clienteId}
                value={clienteId}
                onChange={(event) => {
                  setClienteId(event.target.value);
                  setFiltroAdicionalTabela({
                    ClienteId: event.target.value
                  });
                }}
                className={classes.search}
                placeholder="Cliente"                  
                ref={register({
                  name: "ClienteId",
                  required: true
                })}
                errors={errors}
              />
            </Grid>

            <Grid item lg={6} md={6} sm={12}>
              <MaterialInputTexto
                type="text"
                id="NomeTipoRelatorio"
                name="NomeTipoRelatorio"
                label="Buscar Tipo de Relatório"
                renderIconShowHide
                searchAdornment
                permiteValorBranco
                defaultValue={NomeTipoRelatorio ?? ""}
                ref={register}
                className={classes.search}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} mt={6}>
          <TabelaPaginada
            ref={refTabela}
            className={classes.table}
            onChangeFiltrosTabela={onChangeFiltrosTabela}
            colunas={colunas}
            paginaAtual={1}
            pesquisar={NomeTipoRelatorio}
            filtrosAdicionais={filtroAdicionalTabela}
            noHeader
          />
        </Grid>
      </Grid>
    </Loader>
  </>;
};

export default RelatoriosAssociar;
