import React from "react";
import PropTypes from "prop-types";
import { createTheme, ThemeProvider } from "@mui/material";
import StyledEngineProvider from '@mui/material/StyledEngineProvider';

import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Base } from "../cores";

const theme = createTheme();

theme.overrides = {
  MuiLinearProgress: {
    barColorPrimary: {
      backgroundColor: Base.GreenFern
    }
  }
};

function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2">{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired
};

export default function LinearWithValueLabel({ progressValue }) {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <LinearProgressWithLabel value={progressValue} />
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
