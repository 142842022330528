import {
  obterDadosEmpresa,
  salvarDadosEmpresa,
  verificarSemPlanoSelecionado,
  atualizarExternoIdCotacao
} from "servicos/cotacaoInicialServico";
import { contratarCotacao } from "servicos/demandaAtendServico";
import { digitalizacaoFaturaAntigo } from "servicos/digitalizacaoServico";
import { convertBase64 } from "servicos/utils";
import RetornoEndpointDto from "../../../global/dto/retornoEndpointDto";
import { store } from "../../../global/redux";
import { alertaExibir } from "../../../global/redux/modulos/alertas/actions";
import { buscarDadosCadastraisPorCnpj } from "../../../servicos/dadosCadastraisCnpjServico";

export default class DadosEmpresaHelper {
  static async contratarCotacao(cotacaoId) {
    try {
      const resultado = await contratarCotacao(cotacaoId);

      return RetornoEndpointDto.Sucesso(
        "Cotação contratada com sucesso!",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro(
        "Ocorreu uma falha ao contratar a cotação!"
      );
    }
  }

  static async salvarDadosEmpresaCadastro(dadosEmpresa) {
    try {
      const resultado = await salvarDadosEmpresa(dadosEmpresa);

      return RetornoEndpointDto.Sucesso(
        "Dados da Empresa salvos com sucesso",
        resultado.data
      );
    } catch (e) {
      return RetornoEndpointDto.Erro(
        "Ocorreu uma falha ao salvar os dados da empresa!"
      );
    }
  }

  static async obterDadosEmpresaCadastro(cnpj, id) {
    try {
      const resultado = await obterDadosEmpresa(cnpj, id);

      if (
        !resultado.data ||
        resultado.data === null ||
        resultado.status === 204
      )
        return RetornoEndpointDto.Erro(
          "Ocorreu uma falha ao obter os dados da empresa!"
        );

      return RetornoEndpointDto.Sucesso(
        "Dados obtidos com sucesso!",
        resultado.data
      );
    } catch (ex) {
      return RetornoEndpointDto.Erro(
        "Ocorreu uma falha ao obter os dados da empresa!"
      );
    }
  }

  static async verificarSemPlanoSelecionado(id) {
    try {
      const resultado = await verificarSemPlanoSelecionado(id);

      if (resultado.data === null || resultado.status === 204)
        return RetornoEndpointDto.Erro(
          "Ocorreu uma falha ao verificar se o usuario tem algum plano selecionado!"
        );

      return RetornoEndpointDto.Sucesso(
        "Dados obtidos com sucesso!",
        resultado.data
      );
    } catch (ex) {
      return RetornoEndpointDto.Erro(
        "Ocorreu uma falha ao verificar se o usuario tem algum plano selecionado!"
      );
    }
  }

  static async obterDadosEmpresaReceitaCNPJ(CNPJ) {
    try {
      const resultado = await buscarDadosCadastraisPorCnpj(CNPJ);

      if (!resultado.data || !resultado.data.data)
        return RetornoEndpointDto.Erro(
          "Ocorreu uma falha ao obter os dados da empresa!"
        );

      return RetornoEndpointDto.Sucesso(
        "Dados obtidos com sucesso!",
        resultado.data.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro(
        "Ocorreu uma falha ao obter os dados da empresa!"
      );
    }
  }


  static async uploadFaturaFourvision(arquivo, externoId, cotacaoId) {

    try {

      var base64 = await convertBase64(arquivo[0]);
      var base64SemMimeType = base64.split(',')[1];
      var extensao = base64.split(';')[0];
      var endpoint = '';

      const retornoDigitalizacao = await digitalizacaoFaturaAntigo(
        base64SemMimeType,
        externoId,
        endpoint
      );
      // eslint-disable-next-line no-unused-vars
      const atualizacao = await atualizarExternoIdCotacao(cotacaoId, externoId);

      if (!retornoDigitalizacao.data || !retornoDigitalizacao.data.data)
        return RetornoEndpointDto.Erro(
          "Ocorreu uma falha ao realizar digitalização da fatura!"
        );

      return RetornoEndpointDto.Sucesso(
        "Upload realizado com sucesso!",
        retornoDigitalizacao.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro(
        "Ocorreu uma falha ao realizar digitalização da fatura!!"
      );
    };
  }

  static exibirErro(mensagem) {
    store.dispatch(
      alertaExibir({
        tipo: "danger",
        mensagem
      })
    );
  }

  static exibirSucesso(mensagem) {
    store.dispatch(
      alertaExibir({
        tipo: "success",
        mensagem
      })
    );
  }
}
