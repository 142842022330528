import { makeStyles } from '@mui/styles';
import styled from "styled-components";

import sucesso from "../../Assets/sucesso.png";

export const useStyles = makeStyles({
  card: {
    backgroundImage: `url(${sucesso})`,
    backgroundSize: "cover",
    minHeight: 800,
    position: "relative"
  },
  container: {
    paddingBottom: 0,
    margin: "0",
    width: "100%",
    "& .MuiGrid-item": {
      paddingBottom: 0
    },
    "& .MuiInputBase-root": {
      height: "46px",
      paddingBottom: 0
    },
    "& .MuiFormLabel-root": {
      lineHeight: "1"
    },
    "& .MuiGrid-spacing-xs-4 > .MuiGrid-item": {
      padding: "12px"
    }
  },
  titulo: {
    fontSize: "32px"
  },
  containerAvancar: {
    bottom: 0,
    margin: "0 0 20px",
    position: "absolute"
  },
  button: {
    fontSize: "18px"
  }
});

export const Conteudo = styled.div`
  font-size: 18px;
`;

export const ConteudoAbaixo = styled.div`
  font-size: 28px;
`;
