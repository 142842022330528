import styled from "styled-components";
import { makeStyles } from '@mui/styles';
import { Base } from "../../cores";
import theme from "themes";
const BorderColor = (level, childs) => {

  if (level === 5) return Base.Aurora;
  return theme.color.treeViewBackground2;
};

const BorderWidth = (level, childs) => {
  if (level === 0 && !childs) return "6px";
  if (level === 1 && !childs) return "6px";
  if (level === 2 && !childs) return "6px";
  if (level === 3 && !childs) return "6px";
  if (level === 4 && !childs) return "6px";
  if (level === 5 && !childs) return "6px";
  return "0";
};

const BackgroundColor = (level) => {
  const cores = [
      theme.color.treeViewBackground1, 
      theme.color.treeViewBackground2, 
      theme.color.treeViewBackground2, 
      theme.color.treeViewBackground2,
      theme.color.treeViewBackground2,
      theme.color.treeViewBackground2,
    ];
  return cores[level];
};

export const useStyles = makeStyles({
  container: {
    height: "54px !important",
    margin: "0 !important",
    opacity: (props) => (props.ativo ? 1 : 0.5),
    width: "100% !important"
  },
  gridItem: {
    marginRight: "2px !important",
    padding: "0 !important",
    "&:last-child": {
      marginRight: "0 !important"
    }
  },
  labelItem: {
    borderLeftColor: (props) => BorderColor(props.level, props.childs),
    borderLeftWidth: (props) => BorderWidth(props.level, props.childs),
    borderLeftStyle: "solid !important",
    width: "100% !important"
  },
  treeItem: {
    backgroundColor: (props) => BackgroundColor(props.level),
    color: `${theme.color.secondaryText} !important`,
    height: "54px !important"
  },
  wrapper: {
    alignItems: "center !important",
    display: "flex !important",
    height: "100% !important"
  },
  start: {
    alignItems: "flex-start !important"
  },
  center: {
    justifyContent: "center !important"
  },
  left: {
    justifyContent: "start !important",
    paddingLeft: "20px !important"
  },
  right: {
    justifyContent: "flex-end !important"
  },
  spaceBetween: {
    justifyContent: "space-between !important",
    paddingLeft: "20px !important",
    paddingRight: "20px !important"
  },
  squareWidth: {
    flexBasis: "100% !important",
    maxWidth: "54px !important",
    width: "54px !important",
    "& svg": {
      "pointer-events": "all !important"
    }
  },
  checkbox: {
    "pointer-events": "all !important",
    "&.MuiCheckbox-root": {
      color: theme.color.textoBarra
    },
    "&.Mui-checked": {
      color: theme.color.checkButonColor
    }
  },
  tooltipIcon: {
    marginLeft: "10px !important",
    pointerEvents: "all !important"
  },
  enabledSwitch: {
    pointerEvents: "all !important"
  },
  cardSelecionado: {
    backgroundColor: theme.color.checkButonColor,
    width: "20px !important",
    height: "20px !important"

  }
});

export const Item = styled.div``;
