import styled from "styled-components";
import { Base } from "../../../componentes/cores";
import { makeStyles } from '@mui/styles';
import theme from 'themes';

export const Container = styled.div`
  width: 100%;
  background-color: ${theme.color.cardColorGraficos};
  border-radius: 4px;
  color: ${theme.color.labelColorGrafico};
  min-height: ${(props) => props.height};
`;

export const UnidadeNaoSelecionada = styled.div`
  color: ${theme.color.textoBarra};
  font-size: 28px;
  margin: 200px auto 0;
  max-width: 500px;
  text-align: center;
`;
export const Logo = styled.img`
    max-height: 62px;
    max-width: 87px;

    height: auto;
    position: absolute;
  
`;

export const CarregandoPDF = styled.div`
  color: ${theme.color.textoBarra};
  font-size: 22px;
  margin: 200px auto 0;
  max-width: 500px;
  text-align: center;
`;

export const useStyles = makeStyles((themes) => ({
  estiloBotaoPDF: {
    color: theme.color.textoBarra,
    fontSize: 32
  },
  bordaPDF: {
    borderColor: 'transparent',
    padding: 5,
    borderStyle: 'solid',
    borderWidth: 1,
    borderRadius: 5,
    backgroundColor: 'transparent'
  },
  boxPDF:{
    position: 'fixed',
    right: 0,
    marginTop: '-70px',
    zIndex: 9999,
    marginRight: 10
  },
  loaderBranco:{
    color: `${Base.White} !important`,
    position: 'absolute',
    marginLeft: '-38px',
    marginTop: 16,
    backgroundColor: theme.color.primaryBackgroud
  },
  planos: {
    flexDirection: "row",
    flexGrow: 0,
    justifyContent: "space-around",
    marginBottom: "20px",
    marginTop: "20px"
  },
  rodapePagina: {
    bottom: 0,
    fontSize: "8px",
    height: "124px",
    position: "absolute",
    width: "100%"
  }
}));
