import MICROSERVICO from "../global/enumeradores/microServico";
import obterApi from "./api";

const api = (log) => obterApi(MICROSERVICO.MANAGEMENT, log);

// Salvar Cotacao Inicial
export const salvarCotacaoInicial = async (dados, isEdicao, id) => {
  const metodo = isEdicao ? "put" : "post";
  const url = `v1/CotacaoInicial/${isEdicao ? id : ""}`;
  const log = {
    tipo: isEdicao ? "atualizaLog" : "enviaLog",
    nestedField: isEdicao ? "edicao_pre_cadastro" : "pre_cadastro",
    index: `Cotacao-${process.env.REACT_APP_ENVIRONMENT}`,
    mainFields: "id",
    messege: "Cotação Area Logada",
    nameMainFields: "cotacao_id"
  };
  const res = api(log)[metodo](url, dados);
  return res;
};

export const obterPorId = (id) => {
  return api().get(`v1/CotacaoInicial/${id}`);
};

export const dadosPlanoEscolhido = (id) => {
  return api().get(`v1/CotacaoInicial/${id}/plano-selecionado/`);
};

// Upload Fatura
export const uploadFatura = async (idCliente, cotacaoId, listaArquivos) => {
  const formdata = new FormData();
  formdata.append("clienteId", idCliente);
  formdata.append("cotacaoId", cotacaoId);

  listaArquivos.forEach((arquivo) => {
    formdata.append("file", arquivo, arquivo.path);
  });

  return api().post("v1/UploadFatura", formdata);
};

// Arquivos Cotação
export const listaArquivosCotacao = async (cotacaoId) => {
  return api().get(`v1/ClienteArquivoFatura/${cotacaoId}`);
};

// Resumo Cotação PDF
export const resumoCotacaoPdf = async (id) => {
  return api().get(`v1/CotacaoInicial/ResumoCotacao/${id}`);
};

// Busca Por Id
export const buscarPorId = async (id) => {
  return api().get(`v1/cotacaoInicial/${id}`);
};

// Busca Por CNPJ
export const buscarPorCnpj = async (cnpj) => {
  return api().get(`v1/cliente/GetByCnpj/${cnpj}`);
};

// Busca Por CNPJ
export const buscarPorCnpjCotacao = async (cnpj) => {
  return api().get(`v1/cliente/GetByCnpj/${cnpj}?ehCotacao=true`);
};

// Salvar Cliente
export const salvarClienteCotacao = async (id, dados) => {
  let metodo = "post";
  const url = "v1/Cliente?ehClienteCotacao=true";
  if (id > 0) {
    metodo = "put";
    dados.Id = id;
  }
  return api()[metodo](url, dados);
};

// Obter Dados Empresa
export const obterDadosEmpresa = (cnpj, id) => {
  return api().get(`v1/CotacaoInicial/Empresa/${cnpj}/Dados/${id}`);
};

// Verificar se não tem nenhum plano selecionado
export const verificarSemPlanoSelecionado = (id) => {
  return api().get(`v1/CotacaoInicial/sem-plano-selecionado/${id}`);
};

// Salvar Dados Empresa
export const salvarDadosEmpresa = (dadosEmpresa) => {
  const log = {
    nestedField : "dados_empresa",
    tipo : "atualizaLog"
  }
  return api(log).post(`v1/CotacaoInicial/Empresa/Dados`, dadosEmpresa);
};

// Atualizar Id externo na cotação
export const atualizarExternoIdCotacao = (cotacaoId, externoId) => {
  return api().put(`v1/CotacaoInicial/${cotacaoId}/fatura/${externoId}`);
};

export const obterPropostaACL = (body) => {
  return api().post("v1/CotacaoInicial/proposta", body);
};

export const obterCotacao = (params) => {
  const { numeroInstalacao, cpfCnpj, distribuidoraId } = params;
  return api().get(
    `/v1/CotacaoInicial/${numeroInstalacao}/${cpfCnpj}/${distribuidoraId}`
  );
};

export const salvarCotacaoInicialAcl = async (dados, id) => {
  const metodo = id ? "put" : "post";
  const url = id ? `v1/CotacaoInicial/${id}` : "v1/CotacaoInicial";
  return api()[metodo](url, dados);
};

export const obterLeadClientePorId = async (cotacaoId) => {
  const metodo = "get";
  const url = `v1/LeadCliente/GetByCotacaoMLId/${cotacaoId}`;
  return api()[metodo](url);
};

export const atualizarCotacao = async (id, body) => {
  return api().patch(`/v1/CotacaoInicial/${id}`, body);
};

export const atualizarCotacaoSituacaoVigencia = async (id) => {
  return api().patch(`/v1/CotacaoInicial/CotacaoInicialSituacaoVigencia/${id}`);
};

export const obterCotacaoInicialPlanosGerados = (id) => {
  return api().get(`/v1/CotacaoInicialPlanosGerados/PlanosAtivos/${id}`);
};

export const salvarPrecosDetalhe = async (dados, id) => {
  return api().post(
    `v1/CotacaoInicial/PrecoCotacaoInicial/?cotacaoId=${id}`,
    dados
  );
};
