import MICROSERVICO from "../global/enumeradores/microServico";
import obterApi from "./api";
import { saveAs } from "file-saver";

const api = () => obterApi(MICROSERVICO.FATURAMENTO);

export const listarOrganizarPor = () => {
  const ordenar = [
    {
      key: "1",
      asc: "1",
      desc: "2",
      label: "Mês referência"
    },
    {
      key: "3",
      asc: "3",
      desc: "4",
      label: "Valor"
    }
  ];

  return ordenar;
};

export const listarColaboradorOrdenarPor = () => {
  const ordenar = [
    {
      key: "5",
      asc: "5",
      desc: "6",
      label: "Atraso"
    },
    {
      key: "3",
      asc: "3",
      desc: "4",
      label: "Valor"
    }
  ];

  return ordenar;
};

export const filtroStatusPor = () => {
  const filtroStatus = [
    {
      key: "1",
      label: "Pendente"
    },
    {
      key: "2",
      label: "Atrasado"
    },
    {
      key: "3",
      label: "Pago"
    },
    {
      key: "4",
      label: "Pago parcial"
    }
  ];

  return filtroStatus;
};

// Download do boleto
export const downloadBoleto = async (params) => {
  const { data } = await api().get(
    `v1/fatura/downloadboleto/${params.ultimoIdentificadorBoleto}`,
    {
      responseType: "blob"
    }
  );
  const blob = new Blob([data]);
  saveAs(blob, params.ultimoNomeBoleto);
};

// Download da nota fiscal XML
export const downloadNotaFiscalXML = async (params) => {
  const { data } = await api().get(
    `v1/fatura/downloadxmlnf/${params.ultimoIdentificadorNotaFiscal}`,
    {
      responseType: "blob"
    }
  );
  const blob = new Blob([data]);
  saveAs(blob, params.ultimoNomeNotaFiscal + ".xml");
};

// Download da nota fiscal
export const downloadNotaFiscal = async (params) => {
  const { data } = await api().get(
    `v1/fatura/downloadnf/${params.ultimoIdentificadorNotaFiscal}`,
    {
      responseType: "blob"
    }
  );
  const blob = new Blob([data]);
  saveAs(blob, params.ultimoNomeNotaFiscal);

  downloadNotaFiscalXML(params);
};

// Listar
export const listarFaturas = async (filtro) => {
  const filtroQueryString = new URLSearchParams(filtro).toString();
  return api().get(`v1/fatura/lista?${filtroQueryString}`);
};

// Listar
export const listarFaturasColaborador = async (filtro) => {
  const filtroQueryString = new URLSearchParams(filtro).toString();
  return api().get(`v1/fatura/lista-colaborador?${filtroQueryString}`);
};
