import { saveAs } from "file-saver";
import MICROSERVICO from "../global/enumeradores/microServico";
import obterApi from "./api";

const api = () => obterApi(MICROSERVICO.MEASUREMENT);

export const listarPrecos = async (filtro) => {
  return api().get(
    `v1/preco/obter-precos-vigentes?${new URLSearchParams(filtro).toString()}`
  );
};

export const listarConstantes = async (filtro) => {
  return api().get(
    `v1/precoConstantes?${new URLSearchParams(filtro).toString()}`
  );
};

export const postarConstantes = async (constantes) => {
  return api().post("v1/precoConstantes", constantes);
};

export const postarPrecos = async (precos) => {
  return api().post(`v1/preco/inserir-precos`, precos);
};

export const listarHistoricoPaginado = async (filtro) => {
  return api().get(
    `v1/preco/historico?${new URLSearchParams(filtro).toString()}`
  );
};

export const listarPrecosAnuais = async (filtro) => {
  return api().get(
    `v1/preco/obter-precos-anuais?${new URLSearchParams(filtro).toString()}`
  );
};

export const downloadPlanilhaPrecos = async () => {
  let resposta;
  await api()
    .get("v1/preco/donwload-planilha-precos", {
      headers: {
        "Content-Disposition": "attachment;",
        "Content-Type":
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      },
      responseType: "arraybuffer"
    })
    .then((response) => {
      if (response.status === 200) {
        const blob = new Blob([response.data], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        });
        saveAs(blob, "planilha-precos.xlsx");
        resposta = "Sucesso";
      } else if (response.status === 202) {
        resposta = new TextDecoder().decode(response.data);
      }
    });
  return resposta;
};
