import { makeStyles } from "@mui/styles";
import { createTheme, styled } from "@mui/material/styles";
import { Tab, Tabs } from "@mui/material";
import { Base } from "componentes/cores";
import theme from "themes";

const th = createTheme();

export const useStyles = makeStyles(() => ({
  container: {
    margin: "0 0 24px",
    width: "100%",
    "& .MuiFormLabel-root": {
      marginTop: "-3px"
    }
  },
  titulo: {
    color: theme.color.primary,
    fontSize: "20px",
    lineHeight: "24px",
    fontWeight: 700
  },
  box: {
    width: "auto",

    "& .Mui-disabled": {
      color: "#a6a6a6 !important"
    }
  },
  boxAtualizarStatus: {
    backgroundColor: Base.TextLuzOpacity,
    padding: "16px",
    width: "100%",
    display: "flex",
    color: Base.BoldLuzText,
    fontFamily: theme.fontTahoma.family
  },
  search: {
    "& label.Mui-focused": {
      color: Base.BlackBastille
    },
    "& .MuiInput-underline:after": {
      borderColor: Base.BlackBastille
    },
    "& .MuiFormLabel-root": {
      color: Base.BlackBastille
    },
    "& .MuiOutlinedInput-root": {
      "& .MuiOutlinedInput-input": {
        color: Base.BlackBastille
      },
      "& .MuiInputAdornment-root": {
        color: Base.BlackBastille
      },
      "& fieldset": {
        borderColor: Base.BlackBastille
      },
      "&:hover fieldset": {
        borderColor: Base.BlackBastille
      },
      "&.Mui-focused fieldset": {
        borderColor: Base.BlackBastille
      },
      "& .MuiSvgIcon-root": {
        color: "#646464 !important"
      }
    },
    "& .MuiOutlinedInput-input": {
      padding: "13.5px 0"
    }
  },
  select: {
    "& .MuiOutlinedInput-input": {
      padding: "13.5px 14px"
    },
    "& label.Mui-focused": {
      color: Base.OffWhite
    },
    "& .MuiInput-underline:after": {
      borderColor: Base.OffWhite
    },
    "& .MuiFormLabel-root": {
      color: Base.OffWhite
    },
    "& .MuiOutlinedInput-root": {
      "& .MuiOutlinedInput-input": {
        color: Base.OffWhite
      },
      "& .MuiInputAdornment-root": {
        color: Base.OffWhite
      },
      "& fieldset": {
        borderColor: Base.OffWhite
      },
      "&:hover fieldset": {
        borderColor: Base.OffWhite
      },
      "&.Mui-focused fieldset": {
        borderColor: Base.OffWhite
      }
    },
    "& .MuiSelect-icon": {
      color: Base.White
    }
  },
  itemFiltro: {
    display: "flex",
    justifyContent: "flex-end",
    [th.breakpoints.down("md")]: {
      justifyCcontent: "normal",
      width: "100% !important",
      display: "inline"
    }
  },
  buttonFiltro: {
    fontSize: "16px",
    fontWeight: "700",
    marginTop: "5px",
    width: "auto !important",
    padding: "8px 32px",
    "&:disabled": {
      background: "#9F9F9F !important",
      color: "#9F9F9F !important",
      borderColor: "#9F9F9F !important",
      opacity: "1 !important"
    }
  },
  botaoFiltrarUc: {
    background: "transparent !important",
    color: `${theme.color.secondary} !important`,
    fontSize: "16px",
    width: "auto !important",
    fontWeight: "700",
    marginTop: "5px",
    lineHeight: "19.2px",
    padding: "8px 32px",
    "&:disabled": {
      color: "#9F9F9F !important",
      borderColor: "#9F9F9F !important",
      opacity: "1 !important"
    }
  },
  botaoLimpar: {
    background: "transparent !important",
    color: `${theme.color.secondary} !important`,
    textDecoration: "underline",
    border: "none",
    fontSize: "16px",
    maxWidth: "100%",
    fontWeight: "700",
    marginTop: "5px",
    lineHeight: "19.2px",
    padding: "8px 16px",
    "&:focus": {
      boxShadow: "none",
      border: "none"
    },
    "&:disabled": {
      color: "#9F9F9F !important",
      opacity: "1 !important"
    }
  },
  tabela: {
    "& .MuiButton-root": {
      minWidth: "0px",
      "& .MuiSvgIcon-root": {
        fontSize: "20px",
        marginLeft: "2px"
      }
    },
    "& .rdt_TableCol_Sortable": {
      fontWeight: 700,
      width: "100%",
      "& div": {
        width: "100%"
      }
    },
    "& .rdt_TableCell": {
      padding: "14px !important"
    }
  },
  linhaTooltip: {
    cursor: "default",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  fundoCelulaMobile: {
    backgroundColor: "#282E3D",
    marginBottom: "23px"
  },
  botao: {
    color: `${theme.color.secondaryText} !important`,
    fontSize: "42px"
  },
  empresaNaoSelecionada: {
    color: `${theme.color.secondaryBorderColor} !important`,
    fontSize: "28px",
    margin: "200px auto 0",
    maxWidth: "500px",
    textAlign: "center"
  },
  naoEncontrado: {
    backgroundColor: "#f4f4f4",
    height: "500px",

    "& div": {
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      justifyContent: "center",
      "& h4": {
        color: "#4F4F4F",
        fontFamily: theme.fontTahoma.family,
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "16px",
        display: "flex",
        alignItems: "center",
        marginBottom: "16px"
      }
    },
    "& h4": {
      color: "#4F4F4F",
      fontFamily: theme.fontTahoma.family,
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "16px",
      display: "flex",
      alignItems: "center"
    }
  }
}));

export const StyledTabs = styled(Tabs)({
  backgroundColor: Base.White,
  "& .MuiTabs-indicator": {
    backgroundColor: theme.color.primary,
    height: "4px"
  }
});

export const StyledTab = styled(Tab)(() => ({
  textTransform: "none",
  minWidth: 0,
  backgroundColor: Base.White,
  color: theme.color.primary,
  fontFamily: theme.fontTahoma.family,
  fontSize: "14px",
  lineHeight: "16.9px",

  borderBottom: "4px solid #E8E8E8",
  "&:hover": {
    fontWeight: 700,
    opacity: 1
  },
  "&.Mui-selected": {
    color: theme.color.primary,
    fontWeight: 700
  },
  "&.Mui-focusVisible": {
    backgroundColor: Base.White
  }
}));
