import { Base } from "../../componentes/cores";
import CenturyGothic from "../../assets/fontes/century-gothic/century-gothic.TTF";
import CenturyGothicBold from "../../assets/fontes/century-gothic-bold/century-gothic-bold.ttf";
import Tahoma from "../../assets/fontes/Tahoma.ttf"
import FundoPdf from "./assets/FundoPdf.png";
import LogoPdf from "./assets/Delta_Preto.png";
import { FontCenturyGothicFileContent } from "../../assets/fontes/FontCenturyGothicFileContent";
import { FontCenturyGothicBoldFileContent } from "../../assets/fontes/FontCenturyGothicBoldFileContent";

const theme = {
  appBar: {
    backgroundColor: Base.BlackRussian,
    TextColor: Base.White,
    menuitemcolor: Base.PrimaryThymosColor,
    TextColorLuz: Base.White,
  },
  selectArredondado: {
    borderColor: Base.BlackRussian
  },
  borda:{
    input: ".3rem"
  },
  texto: {
    loginBemVindo: "",
    site: "www.thymosenergia.com.br",
    empresa: "Thymos Comercializadora de Energia Ltda",
    enderecoRua: "Av. Brigadeiro Faria Lima",
    enderecoNumero: "4.100",
    enderecoComplemento: "7º andar",
    enderecoBairro: "Itaim Bibi",
    enderecoCep: "04538-132",
    enderecoCidade: "São Paulo",
    enderecoEstado: "SP"
  },
  font: {
    family: "\"Century Gothic\", CenturyGothic, AppleSDGothicNeo, sans-serif",
    srcFont: CenturyGothic,
    srcFontBold: CenturyGothicBold
  },
  fontTahoma: {
    family: "Tahoma !important",
    srcFont: Tahoma
  },
  pdf: {
    footer: FundoPdf,
    logo: LogoPdf,
    drawColorR: 254,
    drawColorG: 187,
    drawColorB: 47,
    fontFooterColor: Base.BlackRussian,
    fontJsPDF: FontCenturyGothicFileContent,
    fontStyleJsPDF: "century-gothic",
    fontNameJsPDF: "century-gothic-normal.ttf",
    fontJsPDFBold: FontCenturyGothicBoldFileContent,
    fontStyleJsPDFBold: "century-gothic-bold",
    fontNameJsPDFBold: "century-gothic-bold-normal.ttf"
  },
  color: {
    primary: Base.PrimaryThymosColor,
    primaryOpacity: Base.PrimaryOpacityThymosColor,
    BtLoginBG: Base.PrimaryThymosColor,
    BtLoginBorder: Base.BtLoginBGThymosColor,
    BtLoginText: Base.White,
    InLoginText: Base.BlackRussian,
    borderColor: Base.BlackRussian,
    buttonColor: Base.White,
    invert: "invert(1)",

    menuItemtextEnable: Base.White,
    menuItemTextOpacity: Base.MenuItemTextOpacityDelta,

    // Botão Ordenador
    btOrdBGColor: Base.PrimaryThymosColor,
    btSelBGColor: Base.OffWhite,
    btTextColor: Base.White,
    btSelTextColor: Base.BlackRussian,
    boBackgroudSelColor: Base.OffWhite,
    boBorderSelColor: Base.GrPurpleishpinkeyPaynes,
    boTexSelColor: Base.GreyTextLuz,
    botaoTextColor: Base.OffWhite,

    // botaoFiltroOrdenar
    btFOBorderColor: Base.White,
    btFOBgColor: Base.BlackRussian,

    checkButonColor: Base.PrimaryThymosColor,
    footerColor: Base.BlackCurrant,
    primaryText: Base.BlackRussian,
    primaryBackgroud: Base.BlackRussian,
    secondaryBackgroud: Base.BlackRussian,
    secondary: Base.CharcoalGrey,
    secondaryText: Base.White,
    secondaryBorderColor: Base.OffWhite,
    thirdBackgroud: Base.BlackRock,
    thirdText: Base.BlackBastille,
    fourthBackgroud: Base.BlackBastille,
    fundoBotoes: Base.BlackRock,
    gridText: Base.OffWhite,

    // Listagem
    treeItemArrowColor: Base.OffWhite,

    // Menu
    listItemBackgroundHover: Base.BlackOpacity20,
    btMenuTextColor: Base.BlackRussian,
    anchorMenuColor: Base.BlackRussian,
    menuAvatarColor: Base.OffWhite,
    menuItemAvatarBackground: Base.WhiteGhost,
    menuItemAvatarFont: Base.BlackRussian,
    menuItemAvatarFontHover: Base.BlackRussian,
    menuAvatarFooterBackground: Base.OffWhite,
    
    novoItemBaseColor: Base.BlackOpacity20,
    novoItemTextColor: Base.BlackBastille,
    novoBtMenuTextColor:  Base.BlackBastille,
    novoListItemBackgroundHover: Base.NovoBGMenuHuver,

    // Paginação
    bgPaginacao: Base.BlackRussian,
    numeroPagina: Base.OffWhite,

    // Paginação:hover
    bgPaginacaoHover: Base.primary,
    numeroPaginaHover: Base.OffWhite,

    // Paginacao:active
    bgPaginacaoAtiva: Base.PrimaryOpacityThymosColor,

    // TreeView
    treeViewBackground1: Base.BlackBastille,
    treeViewBackground2: Base.BlackRock,
    treeViewBackground3: Base.BlackBastille,
    treeViewBackground4: Base.BlackBastille,

    // BarraSuperior
    textoBarra: Base.OffWhite,

    // Cotações
    bgCardInstrucoes: Base.OffWhite,
    bgCardCotacoes: Base.BlackRussian,

    // InputBuscar
    inputBuscar: Base.OffWhite,

    // Workflow
    rootBgColor: Base.White,
    rootVerticalBgColor: Base.White,
    rootColor: Base.Preto,
    breadcrumbBgColor: Base.White,
    breadcrumbVerticalBgColor:  Base.White,
    activeLabelColor: Base.Preto,
    completeLabelColor: Base.Preto,
    breadcrumbActiveBgColor: Base.MetallicGold,
    breadcrumbCompleteBgColor: Base.MetallicGold,
    breadcrumbVerticalActiveBgColor: Base.MetallicGold,
    breadcrumbVerticalCompleteBgColor: Base.MetallicGold,
    label: Base.White,
    labelVertical: Base.Preto,
    tituloColorCardRepresetante: Base.BlackRussian,
    borderColorCardrepresetante: Base.BlackRussian,

    // BotaoOutlined
    labelColor: Base.Preto,
    borderColor: Base.Preto,
    hoverBorderColor: Base.PrimaryThymosColor,
    hoverBgColor: Base.PrimaryThymosColor,
    hoverLabelColor: Base.White,

    // Gráficos
    consumoAtualInferior: Base.BlueZodiac,
    cardColorGraficos: Base.BlueZodiac,
    labelColorGrafico: Base.OffWhite,
    labelColorSelect: Base.OffWhite,
    gradientInicial: Base.OrangeEcstasy,
    gradientFinal: Base.YellowLight,
    gradientGraficoHoraInicial: Base.BlueCerulean,
    gradientGraficoHoraFinal: Base.BlueCerulean,
    gridColorGrafico: Base.OffWhite,
    serieMensalUm: Base.SerieDeltaUm,
    serieMensalDois: Base.SerieDeltaDois,
    serieMensalTres: Base.SerieDeltaTres,
    serieMensalQuatro: Base.SerieDeltaQuatro,
    serieInferiorAtual: Base.GreenWater,
    montanteSuperior: Base.DeltaMontSup,
    montanteInferior: Base.DeltaMontInf,
    montante: Base.DeltaMont,

    // Select Arredondado
    selectArredondadoColor: Base.OffWhite,
    switchBgColorDisabled: Base.PrimaryDeltaColor,

    // Switch
    switchBgColor: Base.PrimaryThymosColor,

    // InputDate
    inputDateColor: Base.OffWhite,

    radioButton: Base.BlackOpacity60,
    bgColorCard: Base.BlueTuna,
    borderCard: Base.BlackRussian,
    bgColorCadastro: Base.White,
    bgColorSecondCard: Base.BlueZodiac,
    bgColorThirdCard: Base.BlueTuna,

    radioButtonDefault: Base.GreyDim,

    // Listar Medição Legendas
    legendaMedicao1: Base.GreenFrag,
    legendaMedicao2: Base.RedGeranium,
    legendaMedicao3: Base.GreyPaynes,

    // BG Medidor
    bgMedidor: Base.BlueTuna,

    // Led Medidor
    ledVerde: Base.LedVerde,
    ledVermelho: Base.LedVermelho,

    // Trace Medidor
    traceMedidorConsumo: Base.MetallicGold,
    traceMedidorHoras: Base.TraceMedidorConsumo,

    // Grafico EconomiaPdf
    Cativo: Base.CativoDelta,
    Livre: Base.LivreDelta,


    // PlanosCotações
    backgroundPlanoBronze: Base.PlanoBronze,
    backgroundPlanoPrata: Base.PlanoPrata,
    backgroundPlanoOuro: Base.PlanoOuro,
    opacityPlanoBronze: 1,
    opacityPlanoPrata: 1,
    opacityPlanoOuro: 1,
    opacityGrafico: 1,
    planoColor: Base.Preto,
    fundoBronze: Base.FundoBronze,
    fundoPrata: Base.FundoPrata,
    fundoOuro: Base.FundoOuro,

    
    // Botão Filtrar Ordenador
    bgBotaoInicial: Base.OffWhite,
    bgBotaoSelecionado: Base.PrimaryThymosColor,
    labelBotaoInicial: Base.BlackRussian,
    labelBotaoSelecionado: Base.White,

    // Hover Esqueci Senha
    hoverEsqueciSenha: Base.GreyPaynes,

    // Background content
    bgTheme: Base.BlackRussian,

    // Background tabelas
    bgTabela: Base.BlackRock,
    
    // Background item expansivel
    bgItemExpansivel: Base.BlackBastille,
    
    // Background item expansivel
    bgCardEconomia: Base.BlueZodiac,

    // Background faixa medidores
    bgFaixaMedidores: Base.GrayMedidores,

    // Background checkbox
    fundoCheckbox: Base.White,

    // Borda Checkbox
    bordaCheckbox: Base.White,

    // Setas menu superior
    setaMenu: Base.PrimaryThymosColor,

    // Upload
    dropZoneLabel: Base.Preto,
    
    // Background Tooltip
    bgToolTip: Base.PrimaryThymosColor,
    textoTooltip: Base.BlackRussian,

    // Financeiro status
    bgPendente: Base.PrimaryThymosColor,
    bgPago: Base.DeltaMont,
    bgPagoParcial: Base.GreenFrag,
    bgAtrasado: Base.DeltaMontInf,
    bgVencido: Base.RedAmaranth,

    donutEnergiaCativo: Base.GreyHit,
    donutDistribuidoraCativo: Base.CativoDistribuidoraDelta,
    donutImpostoCativo: Base.CativoImpostoDelta,
    donutOutrosCativo: Base.CativoOutrosDelta,

    donutEnergiaLivre: Base.GreenMeadow,
    donutDistribuidoraLivre: Base.LivreDistribuidoraDelta,
    donutImpostoLivre: Base.LivreImpostoDelta,
    donutOutrosLivre: Base.LivreOutrosDelta,

    custoCativo: Base.GreyHit,
    custoLivre: Base.GreenMeadow,

    // Notificação
    notificacaoSino: Base.OffWhite,
    notificacaoLink: Base.BlackCurrant,
    qtdNotificacao: Base.PrimaryThymosColor,

    // Faturas
    valorCorFatura:Base.PrimaryThymosColor,
    botaoDownloadFatura: Base.PrimaryThymosColor,

    // mudar senha
    IconSenha: Base.PrimaryLuzColor,

    // Uploader
    backgroundUpload: Base.PrimaryThymosColor,
    bordaUpload: Base.GreyDim,
     
  }
}

export default theme;
