import { makeStyles } from '@mui/styles';
import { Base } from "../cores";
import theme from 'themes';

export const useStyles = makeStyles(() => ({
  formControl: {
    fontFamily: theme.font.family,
    "& .MuiNativeSelect-select": {
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      color: `${theme.color.secondaryBorderColor} !important`,
      "-webkit-text-fill-color": `${theme.color.secondaryBorderColor} !important`,
      fontSize: "14px",
      padding: "0 5px"
    },
    "& option": {
      color: Base.BlackRussian,
      margin: "5px",
      padding: "5px"
    },
    "& label + .MuiInput-formControl": {
      marginTop: 0
    },
    "& .MuiSvgIcon-root": {
      color: `${theme.color.secondaryText} !important`
    },
    "& .MuiInput-underline:before": {
      display: "none"
    },
    "& .MuiNativeSelect-select:focus": {
      background: "none",
      color: "none"
    },
    "& .MuiInput-underline:after": {
      borderBottom: 0
    },
    "& .MuiNativeSelect-icon": {
      marginRight: "-10px"
    },
    "& .MuiNativeSelect-iconOutlined": {
      right: "0!important"
    }
  }
}));

export const customStyles = makeStyles(() => ({
  formControl: {
    fontFamily: theme.font.family,
    "& .MuiNativeSelect-select": {
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      color: `${theme.color.secondaryBorderColor} !important`,
      fontSize: "18px",
      padding: "0 5px"
    },
    "& option": {
      color: Base.BlackRussian,
      margin: "5px",
      padding: "5px"
    },
    "& label + .MuiInput-formControl": {
      marginTop: 0
    },
    "& .MuiSvgIcon-root": {
      color: `${theme.color.secondaryText} !important`
    },
    "& .MuiInput-underline:before": {
      display: "none"
    },
    "& .MuiNativeSelect-select:focus": {
      background: "none",
      color: "none"
    },
    "& .MuiInput-underline:after": {
      borderBottom: 0
    },
    "& .MuiNativeSelect-iconOutlined": {
      right: "0!important"
    }
  }
}));
