import React, { useState, useEffect } from "react";

// Componentes
import { Grid } from "@mui/material";
import Loader from "../../../../componentes/loader";

// Ícones
import IconeForaPonta from "../../../../assets/icones/medidores/fora-ponta.png";
import IconeConsumoPonta from "../../../../assets/icones/medidores/consumo-ponta.png";
import IconeConsumoTotal from "../../../../assets/icones/medidores/consumo-total.png";

import IconeForaPontaLuz from "../../../../assets/icones/medidores/fora-ponta-luz.png";
import IconeConsumoPontaLuz from "../../../../assets/icones/medidores/consumo-ponta-luz.png";
import IconeConsumoTotalLuz from "../../../../assets/icones/medidores/consumo-total-luz.png";

import getDomain from "global/getDomain";

// Styles
import { Container } from "../../Dashboard/style";
import { useStyles, Icone } from "./style";

const MedidorConsumo = (props) => {
  const classes = useStyles();
  const [consumoForaPonta, setConsumoForaPonta] = useState();
  const [consumoPonta, setConsumoPonta] = useState();
  const [consumoTotal, setConsumoTotal] = useState();
  const [carregandoMedidores, setCarregandoMedidores] = useState(false);
  const values = props;

  useEffect(() => {
    setCarregandoMedidores(true);
    setConsumoForaPonta(values?.medidorSelecionado?.consumoForaPonta);
    setConsumoPonta(values?.medidorSelecionado?.consumoPonta);
    setConsumoTotal(values?.medidorSelecionado?.consumoTotal);
    setCarregandoMedidores(false);
  }, [values?.medidorSelecionado]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Container className={classes.card}>
          <Loader loading={carregandoMedidores} className="w-auto">
            <Grid
              container
              justifyContent="center"
              alignContent="center"
              alignItems="center"
              className={classes.topo}
            >
              <Grid item xs={3} className={classes.iconeWrapper}>
                <Icone src={getDomain() === 'luz' ? IconeForaPontaLuz : IconeForaPonta} />
              </Grid>
              <Grid item xs={9} alignContent="center" alignItems="center">
                <Grid item className={classes.dados}>
                  {consumoForaPonta !== undefined ? consumoForaPonta : 0}
                  <span> MWh</span>
                </Grid>
                <Grid item className={classes.info}>
                  Consumo fora ponta
                </Grid>
              </Grid>
            </Grid>
          </Loader>
        </Container>
      </Grid>

      <Grid item xs={12}>
        <Container className={classes.card}>
          <Loader loading={carregandoMedidores} className="w-auto">
            <Grid
              container
              justifyContent="center"
              alignContent="center"
              alignItems="center"
              className={classes.topo}
            >
              <Grid item xs={3} className={classes.iconeWrapper}>
                <Icone src={getDomain() === 'luz' ? IconeConsumoPontaLuz : IconeConsumoPonta } />
              </Grid>
              <Grid item xs={9} alignContent="center" alignItems="center">
                <Grid item className={classes.dados}>
                  {consumoPonta !== undefined ? consumoPonta : 0}
                  <span> MWh</span>
                </Grid>
                <Grid item className={classes.info}>
                  Consumo ponta
                </Grid>
              </Grid>
            </Grid>
          </Loader>
        </Container>
      </Grid>

      <Grid item xs={12}>
        <Container className={`${classes.card} ${classes.trace}`}>
          <Loader loading={carregandoMedidores} className="w-auto">
            <Grid
              container
              justifyContent="center"
              alignContent="center"
              alignItems="center"
              className={classes.topo}
            >
              <Grid item xs={3} className={classes.iconeWrapper}>
                <Icone src={getDomain() === 'luz' ? IconeConsumoTotalLuz : IconeConsumoTotal} />
              </Grid>
              <Grid item xs={9} alignContent="center" alignItems="center">
                <Grid item className={classes.dados}>
                  {consumoTotal !== undefined ? consumoTotal : 0}
                  <span> MWh</span>
                </Grid>
                <Grid item className={classes.info}>
                  Consumo total
                </Grid>
              </Grid>
            </Grid>
          </Loader>
        </Container>
      </Grid>
    </Grid>
  );
};

export default MedidorConsumo;
