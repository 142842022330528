import { makeStyles } from '@mui/styles';
import { Base } from "../../../../../componentes/cores";
import theme from 'themes';

export const useStyles = makeStyles({
  fundoCelula: {
    backgroundColor: "transparent",
    width: "100%",
    height: "100%",
    justifyContent: 'center'
  },
  fundoBotoes: {
    backgroundColor: theme.color.bgTabela
  },
  botaoCentral: {
    borderLeft: `3px ${theme.color.secondaryBackgroud} solid`,
    borderRight: `3px ${theme.color.secondaryBackgroud} solid`
  },
  botao: {
    color: `${theme.color.secondaryText} !important`,
    width: "100%",
    height: "100%",
    justifyContent: "center",
    justifyItems: "center",
    alignContent: "center",
    alignItems: "center"
  },
  inativo: {
    opacity: 0.5
  },
  ativo: {
    opacity: 1
  },
  grafico: {
    position: 'absolute',
    marginTop: '-3500px',
    right: '50%'
  },
  legendaMarker: {
  background: '#febb2f',
  color: '#febb2f',
  height: '12px',
  width: '12px',
  left: '0px',
  top: '0px',
  borderWidth: '0px',
  borderRadius: '12px',
  "& ::marker": {
    color: '#c4c4c4',
    }
  }
});
