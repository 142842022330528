import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";

// Form Hooks
import { useForm, useWatch } from "react-hook-form";

// Componentes
import { Grid } from "@mui/material";
import ItemLista from "../../../../componentes/itemLista";
import BotaoCadastro from "../../../../componentes/botaoCadastro";
import Loader from "../../../../componentes/loader";
import ModalConfirmacao from "../../../../componentes/modalConfirmacao";

// Serviços
import {
  listarPerfis,
  deletarPerfil
} from "../../../../servicos/perfisServico";

// Rotas
import { RotasDTO } from "../../../../global/rotas/rotasUrlDto";

// Redux
import { store } from "../../../../global/redux";
import { alertaExibir } from "../../../../global/redux/modulos/alertas/actions";

// Styles
import { useStyles } from "./style";
import { desabilitarFiltroLateral } from "../../../../global/redux/modulos/usuario/actions";
import theme from "themes";
import MaterialInputBusca from "componentes/inputBusca";

const PerfilAcessoListar = () => {
  const classes = useStyles();
  const history = useHistory();

  const [carregandoPerfis, setCarregandoPerfis] = useState(false);
  const [listaPerfis, setListaPerfis] = useState([]);

  const obterPerfis = async () => {
    try {
      setCarregandoPerfis(true);
      const lista = await listarPerfis();

      if (lista?.status === 200 && lista?.data) {
        setListaPerfis(lista?.data);
      }
      setCarregandoPerfis(false);
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
      setCarregandoPerfis(false);
    }
  };

  useEffect(() => {
    if (!listaPerfis?.length) obterPerfis();
  }, [listaPerfis.length]);

  const onClickNovoPerfil = () => {
    history.push(`${RotasDTO.PerfisAcesso}/cadastro`);
  };

  const onClickEditar = (id) => {
    history.push(`${RotasDTO.PerfisAcesso}/cadastro/${id}`);
  };

  const [modalExclusao, setModalExclusao] = useState(false);

  const deletar = async (perfil) => {
    try {
      setModalExclusao(false);
      setCarregandoPerfis(true);
      const deletou = await deletarPerfil(perfil.id, !perfil.ativo);
      if (deletou?.status === 204) {
        obterPerfis();
        store.dispatch(
          alertaExibir({
            tipo: "success",
            mensagem: `O perfil foi ${
              perfil?.ativo ? "inativado" : "ativado"
            } com sucesso!`
          })
        );
      }
      setCarregandoPerfis(false);
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
      setCarregandoPerfis(false);
    }
  };

  const onClickExcluir = (perfil) => {
    setModalExclusao(perfil);
  };

  const onConfirmarExclusao = (perfil) => {
    try {
      deletar(perfil);
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
    }
  };

  const { register, control } = useForm({
    reValidateMode: "onSubmit"
  });
  const textoBusca = useWatch({
    control,
    name: "textoBusca",
    defaultValue: ""
  });

  const filtrarPerfis = useCallback(() => {
    return listaPerfis.filter((item) => {
      if (textoBusca && textoBusca.length > 2) {
        const regex = RegExp(`^${textoBusca.toLowerCase()}.*$`);
        const filtroPerfis = item.nome.toLowerCase().match(regex);
        // const filtroPerfis = item.nome.toLowerCase().search(textoBusca.toLowerCase())
        console.log("filtroPerfis.length", filtroPerfis)

        if (filtroPerfis) return true;
        return false;
      }
      return true;
    });
  }, [listaPerfis, textoBusca]);

  useEffect(() => {
    store.dispatch(desabilitarFiltroLateral(true));

    return () => {
      store.dispatch(desabilitarFiltroLateral(false));
    };
  }, [desabilitarFiltroLateral]);

  return (
    <Loader loading={carregandoPerfis} className="w-auto">
      <Grid container spacing={0} className={classes.container}>
        <ModalConfirmacao
          item={modalExclusao}
          onConfirmar={(item) => onConfirmarExclusao(item)}
          mensagem={`Tem certeza que deseja ${
            modalExclusao?.ativo ? "inativar" : "ativar"
          } esse Perfil?`}
          onCancelar={() => setModalExclusao(false)}
        />
        <Grid item lg={5} className="mb-4">
          <MaterialInputBusca
            type="text"
            id="textoBusca"
            name="textoBusca"
            label="Buscar"
            renderIconShowHide
            searchAdornment
            defaultValue={textoBusca ?? ""}
            ref={register}
            permiteValorBranco
          />
        </Grid>
        <Grid item xs={12}>
          <BotaoCadastro
            label="Novo perfil de acesso"
            color={theme.color.buttonColor}
            onClick={onClickNovoPerfil}
          />
        </Grid>
        {filtrarPerfis() && filtrarPerfis()?.length
          ? filtrarPerfis().map((item) => {
              return (
                <ItemLista
                  key={item?.id}
                  label={item?.nome ?? "Perfil"}
                  color={theme.color.buttonColor}
                  edicao={!!item?.id}
                  exclusao={!!item?.id}
                  onClickEditar={() => onClickEditar(item.id)}
                  onClickExcluir={() => onClickExcluir(item ?? false)}
                  ativo={item?.ativo}
                />
              );
            })
          : null}
      </Grid>
    </Loader>
  );
};

export default PerfilAcessoListar;
