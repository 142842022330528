import React from "react";
import PropTypes from "prop-types";
import { Grid, Box } from "@mui/material";
import { Botao } from "../../../../../componentes";
import EsqueletoEtapa from "../../Componentes/EsqueletoEtapa";

function InstalacaoMedidor({ prazo, agendar, showButton }) {
  const onClickAgendar = () => {
    agendar();
  };

  const subtituloInstalacaoAgendada = `Agora você já pode agendar a instalação do seu medidor inteligente de energia e começar a acompanhar seu consumo e economia. Basta clicar no botão abaixo e agendar uma data com nossos especialistas de atendimento. Nossa equipe está pronta para te explicar os detalhes da instalação e solucionar todas as suas dúvidas.`;

  return (
    <>
      <EsqueletoEtapa
        prazo={prazo}
        titulo="Envio do medidor"
        subtitulo={subtituloInstalacaoAgendada}
        esconderBotaoVoltar
        esconderBotaoProximo={false}
        showButton={showButton}
      />

      <Box component={Grid} container spacing={2} pt={2}>
        <Grid item xs={12} lg={4} />
        <Grid item xs={12} lg={4} />
        <Grid item xs={12} lg={4}>
          <Botao
            type="button"
            label="Fale com o atendimento"
            onClick={() => onClickAgendar()}
          />
        </Grid>
      </Box>
    </>
  );
}

InstalacaoMedidor.propTypes = {
  agendar: PropTypes.oneOfType([PropTypes.func]),
  showButton: PropTypes.bool,
  prazo: PropTypes.string
};

InstalacaoMedidor.defaultProps = {
  agendar: () => {},
  showButton: false,
  prazo: ""
};

export default InstalacaoMedidor;
