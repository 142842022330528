import MICROSERVICO from "../global/enumeradores/microServico";
import obterApi from "./api";

const api = () => obterApi(MICROSERVICO.MEASUREMENT);

// Busca Por Id
export const buscarPorId = async (id) => {
  return api().get(`v1/unidadeconsumidora/${id}`);
};

// Listar Unidades
export const listarUnidadesConsumidoras = async (
  ordemSelecionada,
  filtroSelecionado,
  paginaAtual = 0,
  textoPesquisa,
  cliente,
  filtroTipoClienteSelecionado,
  empresa,
  campoPesquisa
) => {
  const parametros = {
    sortOrder: ordemSelecionada,
    filter: filtroSelecionado,
    pagina: paginaAtual,
    searchString: textoPesquisa,
    tamanhoPaginacao: 20,
    tipoCliente: filtroTipoClienteSelecionado,
    empresaId: empresa,
    clientesId: cliente,
    campoTextoSelecionado: campoPesquisa
  };

  return api().get(
    `v1/unidadeconsumidora?${new URLSearchParams(parametros).toString()}`
  );
};

// Deletar Unidade
export const deletarUnidade = async (id, ativo) => {
  let hoje = new Date();
  const dd = String(hoje.getDate()).padStart(2, "0");
  const mm = String(hoje.getMonth() + 1).padStart(2, "0");
  const yyyy = hoje.getFullYear();
  hoje = `${yyyy}-${mm}-${dd}`;
  const situacao = ativo ? "AT" : "IN";

  return api().patch(
    `v1/unidadeconsumidora/${id}/${situacao}?dataReferencial=${hoje}`
  );
};

export const listarOrganizarPor = () => {
  const ordernacoes = [
    {
      key: "0",
      label: "Alfabética"
    },
    {
      key: "3",
      label: "Recentes"
    }
  ];

  return ordernacoes;
};

export const listarFiltrarClientePor = () => {
  const filtros = [
    {
      key: "PF",
      label: "PF"
    },
    {
      key: "PJ",
      label: "PJ"
    }
  ];

  return filtros;
};

// Salvar Cliente
export const salvarUnidade = async (id, dados) => {
  let metodo = "post";
  let url = "v1/unidadeconsumidora";
  if (id > 0) {
    metodo = "put";
    url = `${url}/${id}`;
  }

  return api()[metodo](url, dados);
};

export const listarFiltrarPor = () => {
  const filtros = [
    {
      key: "AT",
      label: "Ativo"
    },
    {
      key: "IN",
      label: "Inativo"
    }
  ];

  return filtros;
};

// Listar Unidades
export const listarUnidadesConsumidorasCombo = async (clienteId) => {
  const IdCliente = parseInt(clienteId, 0);
  return api().get(
    `v1/unidadeconsumidora/ListarUnidadeConsumidoraPorCliente/${IdCliente}`
  );
};

// Buscar Unidade Consumidora por CNPJ
export const buscarPorCnpj = async (cnpj) => {
  const urlParams = `?searchString=${cnpj}`;
  return api().get(`v1/UnidadeConsumidora${urlParams}`);
};

// Busca Status
export const buscarStatus = async () => {
  return api().get("v1/UnidadeConsumidora/Status");
};

// Tipos de Unidade
export const buscarTiposUnidadeConsumidora = async () => {
  return api().get("v1/UnidadeConsumidora/tipos");
};

// Numeros de fases1
export const buscarNumeroFases = async () => {
  return api().get("v1/UnidadeConsumidora/numerofases");
};

// Busca Submercado
export const buscarSubmercado = async () => {
  return api().get("v1/Submercado");
};

// Busca Nível de Tensão
export const buscarNivelTensao = async () => {
  return api().get("v1/NivelTensao");
};

// Todas Unidades
export const listarUnidadesSemPaginacao = async (empresa, cliente) => {
  const params = {
    empresaId: empresa,
    clienteId: cliente
  };

  return api().get(
    `v1/unidadeconsumidora/sem-paginacao?${new URLSearchParams(
      params
    ).toString()}`
  );
};

// Busca Tipo Representação
export const buscaTipoRepresentacao = async () => {
  return api().get("v1/tiporepresentacao");
};

export const salvarUnidadeUsina = async (body) => {
  return api().post("v1/unidadeconsumidora/unidadeusina", body);
};

export const obterUnidadeUsinaPorUnidadeId = async (params) => {
  const filtroQueryString = new URLSearchParams(params).toString();
  return api().get(
    `v1/unidadeconsumidora/unidadeusinaporunidadeid?${filtroQueryString}`
  );
};
