import React from "react";
import PropTypes from "prop-types";

const Alerta = ({ tipo, mensagem, onClose }) => {
  const tratarMensagem = (entrada) => {
    try {
      const [, texto] = entrada.split("::");
      return texto?.trim() ?? mensagem;
    } catch (error) {
      return "Erro interno, entre em contato com o suporte!";
    }
  };

  return (
    <div
      className={`alert alert-${tipo} alert-dismissible fade show w-100`}
      role="alert"
    >
      {tratarMensagem(mensagem)}     
      <button
        type="button"
        className="close"
        data-dismiss="alert"
        aria-label="Close"
        onClick={onClose}
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  );
};

Alerta.propTypes = {
  tipo: PropTypes.string,
  mensagem: PropTypes.string.isRequired,
  onClose: PropTypes.oneOfType([PropTypes.func])
};

Alerta.defaultProps = {
  tipo: "warning",
  onClose: () => {}
};

export default Alerta;
//Alerta