import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

// Componentes
import Modal from "@mui/material/Modal";
import { Grid } from "@mui/material";
import Botao from "../botao";
import SelectArredondado from "../selectArredondado";
import CloseIcon from "@mui/icons-material/Close";

// Form Hooks
import { useForm } from "react-hook-form";

// Styles
import { useStyles } from "./style";
import theme from 'themes';

const ModalContrato = React.forwardRef((props, ref) => {
  const {
    item,
    onConfirmar,
    onCancelar,
    mensagem,
    dataSourcePlataforma,
    valuePlataforma,
    onChange
  } = props;

  const classes = useStyles();
  const [aberto, setAberto] = useState(false);
  const { register, errors, handleSubmit } = useForm({
    reValidateMode: "onSubmit"
  });

  const aoEnviar = (item) => {
    onConfirmar(item);
  };

  useEffect(() => {
    setAberto(Boolean(item));
  }, [item]);

  const onClose = () => {
    setAberto(false);
    onCancelar();
  };

  return (
    <Modal open={aberto} onClose={onClose}>
      <form className="needs-validation" onSubmit={handleSubmit(aoEnviar)}>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          className={classes.container}
          spacing={1}
        >
          <Grid item xs={11} className={classes.labelTitulo}>
            Enviar contrato
          </Grid>
          <Grid item xs={1}>
            <CloseIcon
              className={classes.icon}
              onClick={() => {
                onClose();
              }}
            />
          </Grid>
          <Grid item xs={12}>
            {mensagem}
          </Grid>
          <Grid item className="my-2 px-1" xs={12}>
            Deseja enviar o acordo por qual plataforma?
          </Grid>
          <Grid item xs={12}>
            <SelectArredondado
              id="plataforma"
              name="plataforma"
              valueKey="id"
              valueName="descricao"
              marginBottom="0"
              dataSource={dataSourcePlataforma}
              label="Enviar por..."
              value={valuePlataforma}
              onChange={onChange}
              placeholder="Enviar por..."
              ref={register(
                { name: "plataforma" },
                {
                  required: !valuePlataforma
                    ? "Enviar por é obrigatório!"
                    : false
                }
              )}
              errors={errors}
            />
          </Grid>
          <Grid item xs={2}>
            <Botao
              type="submit"
              label="Enviar"
            />
          </Grid>
        </Grid>
      </form>
    </Modal>
  );
});

ModalContrato.propTypes = {
  item: PropTypes.oneOfType([PropTypes.any]).isRequired,
  onConfirmar: PropTypes.oneOfType([PropTypes.func]),
  onCancelar: PropTypes.oneOfType([PropTypes.func]),
  mensagem: PropTypes.oneOfType([PropTypes.node, PropTypes.any]),
  dataSourcePlataforma: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object
  ]),
  valuePlataforma: PropTypes.oneOfType([PropTypes.any]),
  onChange: PropTypes.oneOfType([PropTypes.func])
};

ModalContrato.defaultProps = {
  onConfirmar: () => {},
  onCancelar: () => {},
  mensagem: {},
  dataSourcePlataforma: [],
  valuePlataforma: "",
  onChange: () => {}
};

export default ModalContrato;
