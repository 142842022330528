import React, { useState, useEffect, useCallback } from "react";
import { useHistory, useParams } from "react-router-dom";
import { extendMoment } from "moment-range";

// Redux
import { useSelector } from "react-redux";

// Form Hooks
import { useForm } from "react-hook-form";

// Componentes
import { Card, Divider, Grid } from "@mui/material";
import Loader from "../../../../componentes/loader";
import Botao from "../../../../componentes/botao";
import BotaoRetornarListagem from "../../../../componentes/botaoRetornarListagem";
import MaterialInputMascara from "../../../../componentes/inputTextoMascara";
import InputData from "../../../../componentes/inputTextoData";
import MaterialInputTexto from "componentes/inputTexto/materialInput";

// Rotas
import { RotasDTO } from "../../../../global/rotas/rotasUrlDto";

// Styles
import { useStyles } from "./style";

// Helper
import preFaturamentoHelper from "./helper";

// DTO
import { StatusPreFaturamentoDto } from "./statusPrefaturamentoDto";
import { InterfaceDTO } from "global/dto/interfacesDto";

import { usuarioPossuiFuncionalidade } from "../../../../servicos/funcionalidadesServico";
import { buscarPorUnidade } from '../../../../servicos/consolidacaoMedidorServico';

const PreFaturamentoCadastro = () => {
  const { id } = useParams();
  const history = useHistory();
  const classes = useStyles();
  const { register, errors, handleSubmit } = useForm({
    reValidateMode: "onSubmit"
  });

  const usuarioGlobal = useSelector((state) => state.usuario);
  const { menu } = usuarioGlobal;
  const rotas = useSelector((state) => state.rotas);

  const moment = extendMoment(window.moment);

  const [consumoFP, setConsumoFP] = useState();
  const [consumoFPAnterior, setConsumoFPAnterior] = useState();

  const [consumoP, setConsumoP] = useState();
  const [consumoPAnterior, setConsumoPAnterior] = useState();

  const [demandaRegFP, setDemandaRegFP] = useState();
  const [demandaRegFPAnterior, setDemandaRegFPAnterior] = useState();

  const [demandaRegP, setDemandaRegP] = useState();
  const [demandaRegPAnterior, setDemandaRegPAnterior] = useState();

  const [ajustesDesconto, setAjustesDesconto] = useState();
  const [ajustesDescontoAnterior, setAjustesDescontoAnterior] = useState();

  const [descontoIncentivada, setDescontoIncentivada] = useState();
  const [
    descontoIncentivadaAnterior,
    setDescontoIncentivadaAnterior
  ] = useState();

  const [outrosTUSD, setOutrosTUSD] = useState();
  const [outrosTUSDAnterior, setOutrosTUSDAnterior] = useState();
  const [valorTotalCativo, setValorTotalCativo] = useState();
  const [desconto, setDesconto] = useState();
  const [valorTotalLivre, setValorTotalLivre] = useState();
  const [volumeEnergia, setVolumeEnergia] = useState();
  const [valor, setValor] = useState();
  const [valorFio, setValorFio] = useState();
  const [unidadeId, setUnidadeId] = useState();
  const [mesReferencia, setMesReferencia] = useState();
  const [anoReferencia, setAnoReferencia] = useState();
  const [carregandoRecalcular, setCarregandoRecalcular] = useState();
  const [
    carregandoSalvarPreFaturamento,
    setCarregandoSalvarPreFaturamento
  ] = useState();
  const [carregandoBtnCalculoCusto, setCarregandoBtnCalculoCusto] = useState(
    false
  );
  const [
    carregandoEnviarFaturamento,
    setCarregandoEnviarFaturamento
  ] = useState();
  const [desabilitarRecalcular, setDesabilitarRecalcular] = useState(true);
  const [statusPreFaturamento, setStatusPreFaturamento] = useState();
  const [desabilitarBotaoSalvar, setDesabilitarBotaoSalvar] = useState(false);
  const [
    desabilitarBotaoEnviarParaFaturamento,
    setDesabilitarBotaoEnviarParaFaturamento
  ] = useState(false);
  const [desabilitarBotoes, setDesabilitarBotoes] = useState(false);
  const [clienteNome, setClienteNome] = useState(false);
  const [unidadeNome, setUnidadeNome] = useState(false);
  const [dataVencimento, setDataVencimento] = useState(null);
  const [icmsNaNotaFiscal, setIcmsNaNotaFiscal] = useState();
  const [mostrarBtnCalculoCustos, setMostrarBtnCalculoCustos] = useState(false);
  const [tipoEnergia, setTipoEnergia] = useState();
  const [consolidado, setConsolidado] = useState(false);
  const [valorIcms, setValorIcms] = useState(null);
  const [valorFioIcms, setValorFioIcms] = useState(null);
  const [valorFioSemIcms, setValorFioSemIcms] = useState(null);
  const [valorSemIcms, setValorSemIcms] = useState(null);
  const [dadosPreFaturamento, setDadosPreFaturamento] = useState({});

  useEffect(() => {
    if (
      statusPreFaturamento === StatusPreFaturamentoDto.Faturado ||
      statusPreFaturamento === StatusPreFaturamentoDto.EmProcessamento
    ) {
      setDesabilitarBotoes(true);
    }
  }, [statusPreFaturamento]);

  const setBotoesCarregando = (status) => {
    setCarregandoRecalcular(status);
    setCarregandoSalvarPreFaturamento(status);
    setCarregandoEnviarFaturamento(status);
    setCarregandoBtnCalculoCusto(status);
  };

  const salvarFormulario = async () => {
    setCarregandoSalvarPreFaturamento(true);

    const resultado = await preFaturamentoHelper.salvarPreFaturamento({
      ConsumoForaPonta: consumoFP,
      ConsumoPonta: consumoP,
      DemandaRegistradaForaPonta: demandaRegFP,
      DemandaRegistradaPonta: demandaRegP,
      AjustesDesconto: ajustesDesconto,
      DescontoIncentivada: descontoIncentivada,
      OutrosTusd: outrosTUSD,
      UnidadeId: unidadeId,
      MesReferencia: mesReferencia,
      AnoReferencia: anoReferencia,
      DataVencimento: dataVencimento,
      ValorTotalCativo: valorTotalCativo,
      ValorTotalLivre: valorTotalLivre,
      VolumeEnergia: volumeEnergia,
      Valor: valor,
      ValorIcms: valorIcms,
      ValorSemIcms: valorSemIcms,
      ValorFio: valorFio,
      ValorFioIcms: valorFioIcms,
      ValorFioSemIcms : valorFioSemIcms,
      Desconto: desconto,
      IcmsNaNotaFiscal:
        icmsNaNotaFiscal == "Sim"
          ? true
          : icmsNaNotaFiscal == "Não"
          ? false
          : null,
      TipoEnergia: tipoEnergia
    });

    if (!resultado.sucesso) {
      setCarregandoSalvarPreFaturamento(false);
      preFaturamentoHelper.exibirErro(resultado.mensagem);
      return;
    }

    preFaturamentoHelper.exibirSucesso("Pré faturamento salvo com sucesso!");
    setCarregandoSalvarPreFaturamento(false);

    history.push({
      pathName: RotasDTO.PreFaturamento,
      state: { preFaturamento: resultado?.data }
    });
  };

  const executarCalculoCusto = async () => {
    setCarregandoBtnCalculoCusto(true);

    const resultado = await preFaturamentoHelper.executarCalculoCustoPorUnidade(
      unidadeId,
      mesReferencia,
      anoReferencia
    );

    if (!resultado.sucesso) {
      setCarregandoBtnCalculoCusto(false);
      preFaturamentoHelper.exibirErro(resultado.mensagem);
      return;
    }

    preFaturamentoHelper.exibirSucesso("Calculo custo executado com sucesso!");

    setCampos(resultado?.data);

    setCarregandoBtnCalculoCusto(false);
  };

  const enviarParaFaturamento = async () => {
    setCarregandoEnviarFaturamento(true);

    const resultado = await preFaturamentoHelper.enviarParaFaturamento(id);

    if (!resultado.sucesso) {
      setCarregandoEnviarFaturamento(false);
      preFaturamentoHelper.exibirErro(resultado.mensagem);
      return;
    }

    preFaturamentoHelper.exibirSucesso("Enviado para faturamento com sucesso!");
    setCarregandoEnviarFaturamento(false);

    history.push({
      pathName: RotasDTO.PreFaturamento,
      state: { preFaturamento: resultado?.data }
    });
  };

  function formatarValorSalvar(dado) {
    const formatado = dado
      ? dado
          .replace(" MWh", "")
          ?.replace(" kW", "")
          ?.replace("R$ ", "")
          ?.replace("%", "")
          ?.replaceAll(".", "")
          ?.replace(",", ".")
          ?.trim()
      : dado;

    return formatado;
  }

  const obterPreFaturamento = useCallback(async (preFaturamentoId) => {
    setBotoesCarregando(true);
    const resultado = await preFaturamentoHelper.obterPreFaturamento(
      preFaturamentoId
    );

    if (!resultado.sucesso) {
      setBotoesCarregando(false);
      preFaturamentoHelper.exibirErro(resultado.mensagem);
      return;
    }

    setCampos(resultado?.data);

    obterConsolidacaoMedidor(resultado?.data?.unidadeId, resultado?.data?.mesReferencia, resultado?.data?.anoReferencia);

    const possuiPermissaoCalcularCustos = usuarioPossuiFuncionalidade(
      menu,
      rotas.rotaAtual.caminho,
      RotasDTO.PreFaturamento,
      InterfaceDTO.PermissaoCalcularCustos
    );
    setMostrarBtnCalculoCustos(possuiPermissaoCalcularCustos);

    setBotoesCarregando(false);
  }, []);

  const obterConsolidacaoMedidor = async (ucId, mes, ano) => {
    const lista = await buscarPorUnidade(
      ucId,
      mes,
      ano
      );
	
	    if (
	      lista?.status === 200 &&
	      lista?.data &&
	      lista?.data?.listaMedicaoConsolidacao
	    ) {
        setConsolidado(lista?.data?.listaMedicaoConsolidacao?.map(x => x.statusFechamento)?.includes("Apto"));
      }
  }

  function setCampos(preFaturamento) {
    setConsumoFP(String(preFaturamento?.consumoForaPonta));
    setConsumoFPAnterior(String(preFaturamento?.consumoForaPonta));
    setConsumoP(String(preFaturamento?.consumoPonta));
    setConsumoPAnterior(String(preFaturamento?.consumoPonta));
    setDemandaRegFP(String(preFaturamento?.demandaRegistradaForaPonta));
    setDemandaRegFPAnterior(String(preFaturamento?.demandaRegistradaForaPonta));
    setDemandaRegP(String(preFaturamento?.demandaRegistradaPonta));
    setDemandaRegPAnterior(String(preFaturamento?.demandaRegistradaPonta));
    setAjustesDesconto(String(preFaturamento?.ajustesDesconto));
    setAjustesDescontoAnterior(String(preFaturamento?.ajustesDesconto));
    setDescontoIncentivada(String(preFaturamento?.descontoIncentivada));
    setDescontoIncentivadaAnterior(String(preFaturamento?.descontoIncentivada));
    setOutrosTUSDAnterior(String(preFaturamento?.outrosTusd));
    setOutrosTUSD(String(preFaturamento?.outrosTusd));
    setValorTotalCativo(String(preFaturamento?.valorTotalCativo));
    setDesconto(String(preFaturamento?.desconto));
    setValorTotalLivre(String(preFaturamento?.valorTotalLivre));
    setVolumeEnergia(String(preFaturamento?.volumeEnergia));
    setValor(String(preFaturamento?.valor));
    setValorSemIcms(String(preFaturamento?.valorSemIcms));
    setValorIcms(String(preFaturamento?.valorIcms));
    setValorFioIcms(String(preFaturamento?.valorFioIcms));
    setValorFioSemIcms(String(preFaturamento?.valorFioSemIcms));
    setValorFio(String(preFaturamento?.valorFio));
    setDataVencimento(String(preFaturamento?.dataVencimento));
    setIcmsNaNotaFiscal(
      preFaturamento?.icmsNaNotaFiscal == null
        ? " -- "
        : preFaturamento?.icmsNaNotaFiscal
        ? "Sim"
        : "Não"
    );
    setMesReferencia(preFaturamento?.mesReferencia);
    setAnoReferencia(preFaturamento?.anoReferencia);
    setUnidadeId(preFaturamento?.unidadeId);
    setStatusPreFaturamento(preFaturamento?.statusId);
    setUnidadeNome(preFaturamento?.unidadeNome);
    setClienteNome(preFaturamento?.clienteNome);
    setTipoEnergia(preFaturamento?.tipoEnergia);
    setDadosPreFaturamento(preFaturamento);
  }

  useEffect(() => {
    if (id) {
      obterPreFaturamento(id);
    } 

  }, [id, obterPreFaturamento]);

  useEffect(() => {
    if (
      consumoFP !== consumoFPAnterior ||
      consumoP !== consumoPAnterior ||
      demandaRegFP !== demandaRegFPAnterior ||
      demandaRegP !== demandaRegPAnterior ||
      ajustesDesconto !== ajustesDescontoAnterior ||
      descontoIncentivada !== descontoIncentivadaAnterior ||
      outrosTUSD !== outrosTUSDAnterior
    ) {
      setDesabilitarRecalcular(false);
      setDesabilitarBotaoSalvar(true);
      setDesabilitarBotaoEnviarParaFaturamento(true);
    } else {
      setDesabilitarRecalcular(true);
      setDesabilitarBotaoSalvar(false);
      setDesabilitarBotaoEnviarParaFaturamento(false);
    }

    if (consumoFP !== consumoFPAnterior) setConsumoFPAnterior(consumoFP);

    if (consumoP !== consumoPAnterior) setConsumoPAnterior(consumoP);

    if (demandaRegFP !== demandaRegFPAnterior)
      setDemandaRegFPAnterior(demandaRegFP);

    if (demandaRegP !== demandaRegPAnterior)
      setDemandaRegPAnterior(demandaRegP);

    if (ajustesDesconto !== ajustesDescontoAnterior)
      setAjustesDescontoAnterior(ajustesDesconto);

    if (descontoIncentivada !== descontoIncentivadaAnterior)
      setDescontoIncentivadaAnterior(descontoIncentivada);

    if (outrosTUSD !== outrosTUSDAnterior) setOutrosTUSDAnterior(outrosTUSD);
  }, [
    consumoFP,
    consumoP,
    demandaRegFP,
    demandaRegP,
    ajustesDesconto,
    descontoIncentivada,
    outrosTUSD
  ]);

  const recalcular = useCallback(async () => {
    setCarregandoRecalcular(true);

    const resultado = await preFaturamentoHelper.recalcularPreFaturamento(
      unidadeId,
      mesReferencia,
      anoReferencia,
      {
        ConsumoForaPonta: consumoFP,
        ConsumoPonta: consumoP,
        DemandaRegistradaForaPonta: demandaRegFP,
        DemandaRegistradaPonta: demandaRegP,
        AjustesDesconto: ajustesDesconto,
        DescontoIncentivada: descontoIncentivada,
        OutrosTusd: outrosTUSD
      }
    );

    if (!resultado.sucesso || resultado?.data?.message) {
      setCarregandoRecalcular(false);
      preFaturamentoHelper.exibirErro(
        resultado?.data?.message ?? resultado.mensagem
      );
      return;
    }

    setDesabilitarRecalcular(true);
    setDesabilitarBotaoSalvar(false);

    setValorTotalCativo(String(resultado?.data?.valorTotalCativo));
    setDesconto(String(resultado?.data?.desconto));
    setValorTotalLivre(String(resultado?.data?.valorTotalLivre));
    setVolumeEnergia(String(resultado?.data?.volumeEnergia));
    setValor(String(resultado?.data?.valor));
    setIcmsNaNotaFiscal(
      resultado?.data?.icmsNaNotaFiscal == null
        ? " -- "
        : resultado?.data?.icmsNaNotaFiscal
        ? "Sim"
        : "Não"
    );

    setCarregandoRecalcular(false);
  }, [
    mesReferencia,
    unidadeId,
    anoReferencia,
    consumoFP,
    consumoP,
    demandaRegFP,
    demandaRegP,
    ajustesDesconto,
    descontoIncentivada,
    outrosTUSD
  ]);

  const [titulo, setTitulo] = useState();
  useEffect(() => {
    if (clienteNome && unidadeNome)
      setTitulo(
        `Editar Pré-faturamento do cliente ${clienteNome} e Unidade ${unidadeNome}`
      );
  }, [clienteNome, unidadeNome]);

  return (
    <>
      <form
        className="needs-validation"
        onSubmit={handleSubmit(salvarFormulario)}
      >
        <Card className={classes.cardCadastro}>
          <Grid container p={2} spacing={2} className={classes.container}>
            <Grid item xs={6} className="font-weight-bold">
              {titulo}
            </Grid>
            <BotaoRetornarListagem
              urlListagem={{
                pathname: RotasDTO.PreFaturamento,
                state: { preFaturamento: dadosPreFaturamento }
              }}
              zerarCombo
            />
            <Grid item xs={12} className="font-weight-bold">
              Energia
            </Grid>
            <Grid item lg={3} md={3} sm={6}>
              <MaterialInputMascara
                type="text"
                id="consumoForaPonta"
                name="consumoForaPonta"
                sufixo=" MWh"
                label="Consumo FP (MWh)"
                renderIconShowHide={false}
                defaultValue={consumoFP}
                onBlur={(e) =>
                  setConsumoFP(formatarValorSalvar(e.target.value))
                }
                ref={register}
                errors={errors}
              />
            </Grid>
            <Grid item lg={3} md={3} sm={6}>
              <MaterialInputMascara
                type="text"
                id="consumoPonta"
                name="consumoPonta"
                defaultValue={consumoP}
                sufixo=" MWh"
                label="Consumo P (MWh)"
                renderIconShowHide={false}
                onBlur={(e) => setConsumoP(formatarValorSalvar(e.target.value))}
                ref={register}
                errors={errors}
              />
            </Grid>
            <Grid item lg={3} md={3} sm={6}>
              <MaterialInputMascara
                type="text"
                id="demandaRegistradaForaPonta"
                name="demandaRegistradaForaPonta"
                defaultValue={demandaRegFP}
                sufixo=" kW"
                label="Demanda reg. FP (kW)"
                renderIconShowHide={false}
                onBlur={(e) =>
                  setDemandaRegFP(formatarValorSalvar(e.target.value))
                }
                ref={register}
                errors={errors}
              />
            </Grid>
            <Grid item lg={3} md={3} sm={6}>
              <MaterialInputMascara
                type="text"
                id="demandaRegistradaPonta"
                name="demandaRegistradaPonta"
                defaultValue={demandaRegP}
                sufixo=" kW"
                label="Demanda reg. P (kW)"
                renderIconShowHide={false}
                onBlur={(e) =>
                  setDemandaRegP(formatarValorSalvar(e.target.value))
                }
                ref={register}
                errors={errors}
              />
            </Grid>
          </Grid>
          <Divider className={classes.divider} />
          <Grid container p={2} spacing={2} className={classes.container}>
            <Grid item xs={12} className="font-weight-bold">
              Ajustes
            </Grid>
            <Grid item lg={3} md={3} sm={6}>
              <MaterialInputMascara
                type="text"
                id="ajustesDesconto"
                name="ajustesDesconto"
                defaultValue={ajustesDesconto}
                prefixo="R$ "
                label="Ajustes de desconto (R$)"
                renderIconShowHide={false}
                onBlur={(e) =>
                  setAjustesDesconto(formatarValorSalvar(e.target.value))
                }
                ref={register}
                errors={errors}
              />
            </Grid>
            <Grid item lg={3} md={3} sm={6}>
              <MaterialInputMascara
                type="text"
                id="descontoIncentivada"
                name="descontoIncentivada"
                defaultValue={descontoIncentivada}
                prefixo="R$ "
                label="Descontos incentivada (R$)"
                renderIconShowHide={false}
                onBlur={(e) =>
                  setDescontoIncentivada(formatarValorSalvar(e.target.value))
                }
                ref={register}
                errors={errors}
              />
            </Grid>
            <Grid item lg={3} md={3} sm={6}>
              <MaterialInputMascara
                type="text"
                id="outrosTusd"
                name="outrosTusd"
                prefixo="R$ "
                label="Outros TUSD (R$)"
                defaultValue={outrosTUSD}
                renderIconShowHide={false}
                onBlur={(e) =>
                  setOutrosTUSD(formatarValorSalvar(e.target.value))
                }
                ref={register}
                errors={errors}
              />
            </Grid>
            <Grid item lg={3} md={3} sm={6}>
              <Loader loading={carregandoRecalcular}>
                <Botao
                  disabled={desabilitarRecalcular || desabilitarBotoes}
                  onClick={() => recalcular()}
                  label="Recalcular"
                  className={classes.button}
                />
              </Loader>
            </Grid>
          </Grid>
          <Divider className={classes.divider} />
          <Grid container p={2} spacing={2} className={classes.container}>
            <Grid item xs={12} className="font-weight-bold">
              Resumo
            </Grid>
            <Grid item lg={3} md={3} sm={6}>
              <MaterialInputMascara
                type="text"
                id="valorTotalCativo"
                name="valorTotalCativo"
                defaultValue={valorTotalCativo}
                prefixo="R$ "
                label="Valor total cativo (R$)"
                renderIconShowHide={false}
                onBlur={(e) =>
                  setValorTotalCativo(formatarValorSalvar(e.target.value))
                }
                ref={register}
                errors={errors}
                disabled
              />
            </Grid>
            <Grid item lg={3} md={3} sm={6}>
              <MaterialInputMascara
                type="text"
                id="desconto"
                name="desconto"
                defaultValue={desconto}
                sufixo="%"
                label="Desconto (%)"
                renderIconShowHide={false}
                onBlur={(e) => setDesconto(formatarValorSalvar(e.target.value))}
                ref={register}
                errors={errors}
                disabled
              />
            </Grid>
            <Grid item lg={3} md={3} sm={6}>
              <MaterialInputMascara
                type="text"
                id="valorTotalLivre"
                name="valorTotalLivre"
                defaultValue={valorTotalLivre}
                prefixo="R$ "
                label="Valor total livre (R$)"
                renderIconShowHide={false}
                onBlur={(e) =>
                  setValorTotalLivre(formatarValorSalvar(e.target.value))
                }
                ref={register}
                errors={errors}
                disabled
              />
            </Grid>
            <Grid item lg={3} md={3} sm={6}>
              <MaterialInputMascara
                type="text"
                id="volumeEnergia"
                name="volumeEnergia"
                defaultValue={volumeEnergia}
                sufixo=" MWh"
                label="Volume de energia (MWh)"
                renderIconShowHide={false}
                onBlur={(e) =>
                  setVolumeEnergia(formatarValorSalvar(e.target.value))
                }
                ref={register}
                errors={errors}
                disabled
              />
            </Grid>
            <Grid item lg={3} md={3} sm={6}>
              <MaterialInputMascara
                type="text"
                id="valor"
                name="valor"
                defaultValue={valor}
                prefixo="R$ "
                label="Valor (R$/MWh)"
                renderIconShowHide={false}
                onBlur={(e) => setValor(formatarValorSalvar(e.target.value))}
                ref={register}
                errors={errors}
                disabled
              />
            </Grid>
            <Grid item lg={3} md={3} sm={6}>
              <MaterialInputMascara
                type="text"
                id="valorFio"
                name="valorFio"
                defaultValue={valorFio}
                prefixo="R$ "
                label="Valor Fio (R$/MWh)"
                renderIconShowHide={false}
                onBlur={(e) => setValor(formatarValorSalvar(e.target.value))}
                ref={register}
                errors={errors}
                disabled
              />
            </Grid>
            <Grid item lg={3} md={3} sm={6}>
              <MaterialInputTexto
                type="text"
                disabled
                id="icmsNaNotaFiscal"
                name="icmsNaNotaFiscal"
                label="ICMS na NF de energia?"
                placeholder="ICMS na NF de energia?"
                defaultValue={icmsNaNotaFiscal}
                errors={errors}
              />
            </Grid>
            <Grid item lg={3} md={3} sm={6}>
              <InputData
                type="text"
                id="dataVencimento"
                name="dataVencimento"
                label="Data do vencimento"
                customValue={dataVencimento}
                minDate={moment().startOf("day")}
                onChange={(data) => setDataVencimento(data)}
                renderIconShowHide={false}
                ref={register({
                  required: "Campo Data do vencimento é obrigatório!",
                  validate: (data) =>
                    moment(data, "DD/MM/YYYY").isValid() ||
                    "Data do vencimento inválida!"
                })}
                errors={errors}
                className={classes}
              />
            </Grid>
            <Grid item lg={3} md={3} sm={6}>
              <MaterialInputTexto
                type="text"
                disabled
                id="tipoEnergia"
                name="tipoEnergia"
                label="Tipo de energia"
                placeholder="Tipo de energia"
                defaultValue={tipoEnergia}
                errors={errors}
              />
            </Grid>
          </Grid>
          <Grid container p={2} spacing={2} className={classes.container}>
            <Grid item lg={3} md={4} sm={6} className={classes.containerSalvar}>
              <Loader loading={carregandoEnviarFaturamento}>
                <Botao
                  label="Enviar para faturamento"
                  className={classes.button}
                  onClick={() => enviarParaFaturamento()}
                  disabled={!consolidado}
                />
              </Loader>
            </Grid>
            <Grid item lg={3} md={4} sm={6} className={classes.containerSalvar}>
              <Loader loading={carregandoSalvarPreFaturamento}>
                <Botao
                  type="submit"
                  label="Salvar"
                  className={classes.button}
                  disabled={desabilitarBotoes || desabilitarBotaoSalvar}
                />
              </Loader>
            </Grid>
            <Grid
              item
              lg={3}
              md={4}
              sm={6}
              spacing={3}
              className={`${
                mostrarBtnCalculoCustos
                  ? `${classes.containerSalvar}`
                  : "d-none"
              }`}
            >
              <Loader loading={carregandoBtnCalculoCusto}>
                <Botao
                  label="Executar calculo custos"
                  className={classes.button}
                  onClick={() => executarCalculoCusto()}
                  disabled={desabilitarBotoes}
                />
              </Loader>
            </Grid>
          </Grid>
        </Card>
      </form>
    </>
  );
};

export default PreFaturamentoCadastro;
