import React, { useEffect, useMemo, useRef, useState } from "react";
import moment from "moment";

// Icones
import TuneIcon from "@mui/icons-material/Tune";

// Componentes
import { Grid } from "@mui/material";
import { Botao, Loader, SelectArredondado } from "componentes";
import BotaoFiltroOrdenacao from "componentes/botaoFiltroOrdenar";
import TabelaPaginada from "componentes/tabelaPaginada";
import Coluna from "componentes/tabelaPaginada/colunas/coluna";
import ColunaComponentePersonalizado from "componentes/tabelaPaginada/colunas/colunaComponentePersonalizado";
import ResultadoPaginadoDto from "componentes/tabelaPaginada/resultadoPaginadoDto";

// Redux
import { store } from "global/redux";
import { desabilitarUnidade } from "global/redux/modulos/usuario/actions";

// Útils
import { ordenacaoPorStatus } from "servicos/listagemOperacaoControleServico";
import { useForm, useWatch } from "react-hook-form";
import ColunaDocumentos from "./colunaCustomizada/colunaDocumentos";
import ColunaOpcoes from "./colunaCustomizada/colunaOpcoes";
import FiltroAdicionalTabela from "./dto/filtroAdicionalTabela";
import ListagemOperacoesHelper from "./helper";
import OperacaoDto from "./operacaoDto";

// Styles
import theme from "../../../themes";
import { useStyles } from "./style";
import MaterialInputBusca from "../../../componentes/inputBusca";
import FiltroDto from "./dto/filtroDto";
import { cpfCnpjValido } from "../../../global/utils/formatCpfCnpj";

const ValidacaoJuridico = () => {
  const classes = useStyles();
  const refTabela = useRef(null);

  const [carregandoOperacoes, setCarregandoOperacoes] = useState();
  const [filtroAdicionalTabela, setFiltroAdicionalTabela] = useState(
    new FiltroAdicionalTabela()
  );

  const [filtroSelecionado, setFiltroSelecionado] = useState("");

  const { register, control, setValue } = useForm({
    reValidateMode: "onSubmit"
  });

  const [campoPesquisa, setCampoPesquisa] = useState("");
  const [carregamentoInicial, setCarregamentoInicial] = useState(false);

  const textoBusca = useWatch({
    control,
    name: "textoBusca",
    defaultValue: ""
  });

  const textoPesquisa = useMemo(() => {
    return textoBusca?.trim() || "";
  }, [textoBusca]);

  useEffect(() => {
    if (campoPesquisa === "Todos" && carregamentoInicial) {
      setValue("textoBusca", null);
      const debounce = setTimeout(() => {
        setFiltroAdicionalTabela(
          new FiltroAdicionalTabela(
            filtroSelecionado,
            textoPesquisa,
            campoPesquisa
          )
        );
      }, 1000);
      return () => clearTimeout(debounce);
    }
  }, [campoPesquisa]);

  const obterDistribuidoras = async () => {};

  const colunas = [
    new Coluna("unidadeNome", "Nome do Cliente"),
    new Coluna("cnpj", "CNPJ"),
    new ColunaDocumentos("Aprovado ou reprovado", "status"),
    new Coluna("data", "Data"),
    new ColunaComponentePersonalizado(
      "operacaoPassoOnboardingId",
      "Editar",
      ColunaOpcoes
    )
  ];

  const mascaraCnpj = (valor) => {
    return valor.replace(
      /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
      "$1.$2.$3/$4-$5"
    );
  };

  const obterOperacoes = async (parametros) => {
    setCarregandoOperacoes(true);

    const filtro = new FiltroDto(
      parametros?.pagina,
      parametros?.tamanhoPaginacao,
      parametros?.filtroAdicional?.status ?? "",
      cpfCnpjValido(parametros?.filtroAdicional?.searchString) ?? "",
      parametros?.filtroAdicional.campoTextoSelecionado || campoPesquisa
    );

    const {
      data,
      mensagem,
      sucesso
    } = await ListagemOperacoesHelper.listarOperacaoControlePaginado(filtro);

    setCarregandoOperacoes(false);

    if (!sucesso) {
      ListagemOperacoesHelper.exibirErro(mensagem);
      return new ResultadoPaginadoDto([], 1, 0, 0);
    }

    if (data?.listOperacaoControle?.length === 0 ?? true) {
      return new ResultadoPaginadoDto([], 1, 0, 0);
    }

    const resLinhas = data?.listOperacaoControle?.map((res) => {
      return new OperacaoDto(
        res.operacaoControleId,
        res.clienteId,
        res.unidadeNome,
        mascaraCnpj(res.unidadeCnpjCpf),
        res.aprovado,
        res?.dataEnvioJuridico
          ? moment(res.dataEnvioJuridico).format("DD/MM/YYYY")
          : res.dataEnvioJuridico,
        res.statusFinalizado
      );
    });

    return new ResultadoPaginadoDto(
      resLinhas,
      data?.paginaAtual,
      data?.totalItens,
      data?.totalPaginas
    );
  };

  const onChangeFiltrosTabela = async (parametros) => {
    return obterOperacoes({
      tamanhoPaginacao: parametros.totalPagina,
      pagina: parametros.pagina,
      pesquisar: parametros?.pesquisar,
      filtroAdicional: new FiltroAdicionalTabela(
        parametros?.filtrosAdicionais?.status,
        parametros?.filtrosAdicionais?.searchString,
        parametros?.filtrosAdicionais?.campoTextoSelecionado
      )
    });
  };

  const handleClickFiltroSelecionado = (event) => {
    const val = filtroSelecionado === event ? "" : event;
    setFiltroSelecionado(val);
  };

  const handleClickAtivarFiltro = () => {
    setFiltroAdicionalTabela(
      new FiltroAdicionalTabela(filtroSelecionado, textoPesquisa, campoPesquisa)
    );
  };

  useEffect(() => {
    store.dispatch(desabilitarUnidade(true));

    return () => store.dispatch(desabilitarUnidade(false));
  }, [desabilitarUnidade]);

  useEffect(() => {
    obterDistribuidoras();
    setCarregamentoInicial(true);
  }, []);

  const onClickSearch = () => {
    setFiltroAdicionalTabela(
      new FiltroAdicionalTabela(filtroSelecionado, textoPesquisa, campoPesquisa)
    );
  };

  return (
    <Loader loading={carregandoOperacoes} className="w-auto">
      <Grid
        container
        spacing={3}
        alignItems="center"
        justifyContent="space-between"
        className={classes.container}
        p={2}
      >
        <Grid item xs={12} sm={6} md={6} lg={3}>
          <SelectArredondado
            select
            id="CampoPesquisa"
            name="CampoPesquisa"
            label="Filtrar por campo"
            placeholder="Filtrar por campo"
            valueKey="valor"
            valueName="nome"
            className={classes.select}
            dataSource={[
              { nome: "Todos", valor: "Todos" },
              { nome: "CNPJ", valor: "CNPJ" }
            ]}
            value={campoPesquisa}
            onChange={(e) => {
              setCampoPesquisa(e.target.value);
            }}
            permiteValorBranco
            styleForm={false}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={4}>
          <MaterialInputBusca
            type="text"
            id="textoBusca"
            name="textoBusca"
            label="Buscar"
            renderIconShowHide
            searchAdornment
            permiteValorBranco
            defaultValue={textoBusca ?? ""}
            ref={register}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={6} lg={2}>
          <Botao
            type="submit"
            onClick={onClickSearch}
            label="Buscar"
            className={classes.button}
            disabled={!textoPesquisa}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          lg={3}
          display="flex"
          justifyContent={{ sm: "flex-end" }}
        >
          <BotaoFiltroOrdenacao
            type="button"
            color={theme.color.secondaryBorderColor}
            background="transparent"
            label="Filtrar / Ordernar"
            icon={<TuneIcon />}
            className={classes.buttonFiltro}
            filtros={ordenacaoPorStatus()}
            filtrosSelecionados={filtroSelecionado}
            onClickFiltro={handleClickFiltroSelecionado}
            onClickAtivarAgora={handleClickAtivarFiltro}
          />
        </Grid>
        <Grid item xs={12} className="mt-4">
          <TabelaPaginada
            ref={refTabela}
            onChangeFiltrosTabela={onChangeFiltrosTabela}
            colunas={colunas}
            pesquisar=""
            filtrosAdicionais={filtroAdicionalTabela}
            paginationPerPage={10}
            paginaAtual={1}
          />
        </Grid>
      </Grid>
    </Loader>
  );
};

export default ValidacaoJuridico;
