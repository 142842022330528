import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
    text: {
        backgroundcolor: "primary",
        backgroundImage: `linear-gradient(90deg,#F722B5 0%,#FD9507 100%)`,
        backgroundSize: "100%",
        backgroundRepeat: "repeat",
        backgroundClip: "text",
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent"
    }
})