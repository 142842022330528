import React, { useState } from "react";
import { Grid } from "@mui/material";
import EsqueletoEtapa from "../../Componentes/EsqueletoEtapa";
import { useStyles } from "./style";
import { ProtocolosEtapas } from "global/constantes";

const ValidacaoAssinaturaDocumentos = ({
  prazo,
  responsavel,
  onProximaEtapa,
  esconderBotaoProximo,
  onVoltarEtapa
}) => {
  const classes = useStyles();

  const onClickProximo = () => {
    onProximaEtapa(ProtocolosEtapas.CadastroMedidor, true);
  };

  const onclickVoltar = () => {
    onVoltarEtapa(ProtocolosEtapas.AssinaturaConfirmacaoContrato)
  }

  return (
    <EsqueletoEtapa
      prazo={prazo}
      responsavel={responsavel}
      titulo="Validação Assinatura Documentos"
      onClickProximo={() => onClickProximo()}
      onClickVoltar={() => onclickVoltar()}
      esconderBotaoProximo={esconderBotaoProximo}
    >
      <Grid className={classes.containerContent}>
        <h4>
          <p>Aguarde enquanto validamos a assinatura dos documentos.</p>
          {/* <p>
            Caso não receba retorno das análises até o prazo acima, a Adesão
            estará automaticamente cancelada.
          </p> */}
        </h4>
      </Grid>
    </EsqueletoEtapa>
  );
};

export default ValidacaoAssinaturaDocumentos;
