import getDomain from "./getDomain";

let clientData = {
  default: {
    document: {
      title: "document.title"
    },
    meta: {
      description: "meta.description"
    },
    footer: {
      copyright: "footer.copyright"
    }
  },
  delta: {
    document: {
      title: "Delta Energia"
    },
    meta: {
      description: ""
    },
    footer: {
      copyright: `Delta Energia ® ${window.moment().year()}`
    }
  },
  thymos: {
    document: {
      title: "Thymos Energia"
    },
    meta: {
      description: ""
    },
    footer: {
      copyright: `Thymos Energia ® ${window.moment().year()}`
    }
  },
  luz: {
    document: {
      title: "LUZ"
    },
    meta: {
      description: ""
    },
    footer: {
      copyright: `LUZ ® ${window.moment().year()}`
    }
  }
};

const data = clientData[getDomain()];

export default data;
