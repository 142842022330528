import { makeStyles } from '@mui/styles';
import { Base } from "componentes/cores";
import styled from "styled-components";
import theme from "themes";

export const UnidadeNaoSelecionada = styled.div`
  color: ${Base.OffWhite};
  font-size: 28px;
  margin: 200px auto 0;
  max-width: 500px;
  text-align: center;
`;

export const useStyles = makeStyles(() => ({
  header: {
    padding: "24px 40px",
    boxShadow: `0 3px ${Base.OffWhite}`
  },
  footer: {
    padding: "24px 40px",
    boxShadow: `0 -3px ${Base.OffWhite}`
  },
  content: {
    padding: "0px 40px"
  },
  containerBotaoAssinar: {
    justifyContent: "flex-end",
    justifySelf: "flex-end",
    justifyItems: "flex-end"
  },
  containerContent: {
    padding: "30px 10px 30px 0px",
    maxHeight: "300px",
    overflowX: "auto",
    fontFamily: theme.font.family,
    fontSize: "18px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "-0.4px",
    textAlign: "justify",
    color: Base.Preto
  },
  containerTitulo: {
    alignItems: "center",
    alignContent: "center",
    alignSelf: "center"
  },
  labelMenor: {
    fontFamily: theme.font.family,
    fontSize: "12px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "-0.27px"
  },
  labelMaior: {
    fontSize: "16px",
    letterSpacing: "-0.36px",
    fontFamily: theme.font.family,
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "-0.27px"
  },
  icone: {
    marginRight: 10
  },
  containerIcone: {
    alignItems: "center",
    alignContent: "center",
    alignSelf: "center",
    justifyContent: "center",
    justifySelf: "center",
    justifyItems: "center"
  },
  titulo: {
    fontFamily: theme.font.family,
    fontSize: "18px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "-0.4px"
  },
  linkDownload: {
    fontFamily: theme.font.family,
    fontSize: "18px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "-0.4px",
    textAlign: "justify",
    color: Base.Preto,
    textDecoration: "underline",
    "&:hover": {
      textDecoration: "underline",
      color: Base.Preto
    }
  }
}));
