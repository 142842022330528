import React from "react";
import moment from "moment";

import { Box } from "@mui/material";
import { Wrapper, useStyles, Mes } from "./style";
import IconData from "../../../../assets/consumoEnergia/icons-data.png"

const Data = (props) => {
  const classes = useStyles();

  return (
    <Wrapper className={classes.wrapper}>
      <Mes>
        {`${moment().format("D MMM")}`}
        &nbsp;
      </Mes>
      {moment().format("YYYY, dddd")}
      <Box component="img" src={IconData} alt="icons-data" ml={2} />
    </Wrapper>
  );
};

export default Data;
