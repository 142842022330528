import React, { useEffect } from "react";
import PropTypes from "prop-types";

// Componentes
import { Grid } from "@mui/material";
import TextField from "@mui/material/TextField";

// Form Hooks
import { Controller, useForm } from "react-hook-form";

// Styles
import { useStyles } from "./style";

const InputBlock = React.forwardRef((props, ref) => {
  const {
    label,
    id,
    name,
    type,
    value,
    defaultValue,
    className,
    disabled
  } = props;

  const { control, setValue } = useForm();

  useEffect(() => {
    if (defaultValue) setValue(`${name}`, defaultValue);
  }, [defaultValue, setValue, name]);

  const classes = useStyles();

  return (
    <Grid container spacing={0} alignItems="flex-end">
      <Grid item lg={12} xs={12}>
        <Controller
          as={TextField}
          control={control}
          id={id}
          name={name}
          variant="outlined"
          label={label}
          type={type}
          InputProps={{
            value
          }}
          defaultValue={defaultValue}
          classes={{ root: classes.root }}
          className={className}
          inputRef={ref}
          fullWidth
          autoComplete="off"
          disabled={disabled}
        />
      </Grid>
    </Grid>
  );
});

InputBlock.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  defaultValue: PropTypes.string,
  style: PropTypes.oneOfType([PropTypes.object]),
  className: PropTypes.oneOfType([PropTypes.any]),
  disabled: PropTypes.bool
};

InputBlock.defaultProps = {
  label: "",
  id: "",
  type: "text",
  defaultValue: "",
  className: undefined,
  disabled: false
};

export default InputBlock;
