import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";

// Icones
import { AccountCircle } from "@mui/icons-material";

// Componentes
import { Grid, Box } from "@mui/material";
import iconePerfil from "../../../../assets/home/icone-perfil.png";
import iconeDocumentos from "../../../../assets/home/documentos-icone-dashboad-luz.png";
import iconeAlterarSenha from "../../../../assets/home/icone-alterar-senha.png";
import iconeCotacao from "../../../../assets/home/icone-cotacao.png";
import iconeValidarCotacao from "../../../../assets/home/icone-validar-cotacao.png";
import iconeListaCotacao from "../../../../assets/home/icone-lista-de-cotacao.png";
import iconeWorkflow from "../../../../assets/home/icone-workflow.png";
import iconeBoleta from "../../../../assets/home/icone-boleta.png";
import iconeFinanceiroColaborador from "../../../../assets/home/icone-financeiro-colaborador.png";
import iconeSerasa from "../../../../assets/home/icone-serasa.png";
import iconeUnidadeConsumidora from "../../../../assets/home/icone-unidade-consumidora.png";
import iconeLeads from "../../../../assets/home/icone-leads.png";
import iconeDistribuidoraPisCofins from "../../../../assets/home/icone-distribuidora-piscofins.png";
import iconeDistribuidoraICMS from "../../../../assets/home/icone-distribuidora-icms.png";
import iconeDistribuidora from "../../../../assets/home/icone-distribuidora.png";
import iconeMedidor from "../../../../assets/home/icone-medidor.png";
import iconeUpload from "../../../../assets/home/icone-upload.png";
import iconeAlteracao from "../../../../assets/home/icone-alteracao.png";
import iconeFuncionalidades from "../../../../assets/home/icone-funcionalidades.png";
import iconeArvoreGerencial from "../../../../assets/home/icone-arvore-gerencial.png";
import iconeUsuarios from "../../../../assets/home/icone-usuarios.png";
import iconeColaboradores from "../../../../assets/home/icone-colaboradores.png";
import iconeGrupoDeltaEnergia from "../../../../assets/home/icone-grupo-delta.png";
import iconeEconomia from "../../../../assets/home/icone-economia.png";
import iconeConsumo from "../../../../assets/home/icone-consumo.png";
import iconeMedicao from "../../../../assets/home/icone-medicao.png";
import iconeFaturas from "../../../../assets/home/icone-fatura.png";
import iconeConsumoEconomia from "../../../../assets/home/icone-consumo-economia.png";
import HomeCliente from "../../Componentes/HomeCliente";

// Redux
import { useSelector } from "react-redux";
import { store } from "../../../../global/redux";
import { salvarMenuSelecionadoColecao } from "../../../../global/redux/modulos/rotas/actions";

// DTO
import { RotasDTO } from "../../../../global/rotas/rotasUrlDto";

//Responsivo
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

// Styles
import {
  Economia,
  TextoEconomia,
  Consumo,
  TextoConsumo,
  MedicaoConsolidada,
  TextoMedicao,
  InformacoesCadastro,
  TextoInformacoes,
  LinkedIn,
  Site,
  EconomiaMobile,
  FinanceiroMobile,
  ConsumoMobile,
  RelatoriosMobile,
  TextoMobile,
  useStyles
} from "./style";

const Home = () => {
  const usuario = useSelector((state) => state.usuario);
  const { menu } = usuario;
  const history = useHistory();
  const classes = useStyles();

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("lg"));

  const salvarMenuColecao = (rota, colecao) => {
    if (rota && colecao?.length) {
      const segmento = colecao.find((itemColecao) =>
        itemColecao.functionality.funcionalidadeColecao.find(
          (funcionalidade) => funcionalidade.caminho === rota
        )
      );

      if (segmento?.functionality?.funcionalidadeColecao?.length) {
        store.dispatch(
          salvarMenuSelecionadoColecao([
            ...segmento?.functionality?.funcionalidadeColecao
          ])
        );
      }
    }
  };

  const [menuCentral, setMenuCentral] = useState([]);
  const montarMenu = (role) => {
    switch (role) {
      case "Vendedor":
        setMenuCentral([
          {
            label: "Cotação",
            icone: iconeCotacao,
            link: "CotacaoIncial"
          },
          {
            label: "Meus Documentos",
            icone: iconeDocumentos,
            link: "RelatoriosCustomizadosBaixar"
          },
          {
            label: "Lista de Cotações",
            icone: iconeListaCotacao,
            link: "Cotacoes"
          },
          {
            label: "Alterar Senha",
            icone: iconeAlterarSenha,
            link: "AlterarSenha"
          }
        ]);
        break;
      case "Geral":
        setMenuCentral([
          {
            label: "Medição",
            icone: iconeMedicao,
            link: "MedicaoConsolidada"
          },
          {
            label: "Perfil",
            icone: iconePerfil,
            link: "Usuarios"
          },
          {
            label: "Meus Documentos",
            icone: iconeDocumentos,
            link: "RelatoriosCustomizadosBaixar"
          },
          {
            label: "AlterarSenha",
            icone: iconeAlterarSenha,
            link: "AlterarSenha"
          }
        ]);
        break;

      case "Cliente":
        setMenuCentral([
          {
            label: "Economia",
            icone: iconeEconomia,
            link: "Economia"
          },
          {
            label: "Consumo",
            icone: iconeConsumo,
            link: "DashboardConsumo"
          },
          {
            label: "Medição",
            icone: iconeMedicao,
            link: "MedicaoConsolidada"
          },
          {
            label: "Fatura",
            icone: iconeFaturas,
            link: "Faturas"
          },
          {
            label: "Meus Documentos",
            icone: iconeDocumentos,
            link: "RelatoriosCustomizadosBaixar"
          },
          {
            label: "Perfil",
            icone: iconePerfil,
            link: "Usuarios"
          }
        ]);
        break;

      case "ClienteemMigração":
        setMenuCentral([
          {
            label: "Workflow",
            icone: iconeWorkflow,
            link: "OnBoarding"
          },
          {
            label: "Perfil",
            icone: iconePerfil,
            link: "Usuarios"
          },
          {
            label: "Meus Documentos",
            icone: iconeDocumentos,
            link: "RelatoriosCustomizadosBaixar"
          },
          {
            label: "AlterarSenha",
            icone: iconeAlterarSenha,
            link: "AlterarSenha"
          }
        ]);
        break;

      case "CustumerSucess":
        setMenuCentral([
          {
            label: "Validar Cotação",
            icone: iconeValidarCotacao,
            link: "ValidarCotacoes"
          },
          {
            label: "Boleta",
            icone: iconeBoleta,
            link: "Boletas"
          },
          {
            label: "Financeiro Colaborador",
            icone: iconeFinanceiroColaborador,
            link: "FaturasColaborador"
          },
          {
            label: "Serasa",
            icone: iconeSerasa,
            link: "Serasa"
          },
          {
            label: "Unidade Consumidora",
            icone: iconeUnidadeConsumidora,
            link: "UnidadesConsumidoras"
          },
          {
            label: "Leads",
            icone: iconeLeads,
            link: "LeadClientes"
          }
        ]);
        break;

      case "OperaçãoDistribuidora":
        setMenuCentral([
          {
            label: "Distribuidora",
            icone: iconeDistribuidora,
            link: "Distribuidoras"
          },
          {
            label: "Unidade Consumidora",
            icone: iconeUnidadeConsumidora,
            link: "UnidadesConsumidoras"
          },
          {
            label: "Distribuidora PisCofins",
            icone: iconeDistribuidoraPisCofins,
            link: "DistribuidoraPisCofins"
          },
          {
            label: "Distribuidora ICMS",
            icone: iconeDistribuidoraICMS,
            link: "DistribuidoraICMS"
          },
          {
            label: "Medidor",
            icone: iconeMedidor,
            link: "Medidores"
          }
        ]);
        break;

      case "Crédito":
        setMenuCentral([
          {
            label: "Serasa",
            icone: iconeSerasa,
            link: "Serasa"
          },
          {
            label: "Alterar Senha",
            icone: iconeAlterarSenha,
            link: "AlterarSenha"
          }
        ]);
        break;

      case "Backoffice":
        setMenuCentral([
          {
            label: "Upload Municípios",
            icone: iconeUpload,
            link: "UploadMunicipios"
          },
          {
            label: "Alteração Medição",
            icone: iconeAlteracao,
            link: "AlteracaoMedicoes"
          },
          {
            label: "Upload Proinfa",
            icone: iconeUpload,
            link: "UploadProinfa"
          },
          {
            label: "Alteração de Medição Consolidada",
            icone: iconeAlteracao,
            link: "AlteracaoMedicoesConsolidadas"
          }
        ]);
        break;

      case "Administrativo":
        setMenuCentral([
          {
            label: "Funcionalidades",
            icone: iconeFuncionalidades,
            link: "Funcionalidades"
          },
          {
            label: "Perfil",
            icone: iconePerfil,
            link: "PerfisAcesso"
          },
          {
            label: "Árvore Gerencial",
            icone: iconeArvoreGerencial,
            link: "ArvoreGerencial"
          },
          {
            label: "Usuários",
            icone: iconeUsuarios,
            link: "Usuarios"
          },
          {
            label: "Colaboradores",
            icone: iconeColaboradores,
            link: "Colaboradores"
          },
          {
            label: "Grupo Delta Energia",
            icone: iconeGrupoDeltaEnergia,
            link: "EmpresaGrupoDelta"
          }
        ]);
        break;

      case "ClienteGDMigração":
        setMenuCentral([
          {
            label: "Fluxo Migração",
            icone: iconeWorkflow,
            link: "OnBoardingGD"
          },
          {
            label: "Perfil",
            icone: iconePerfil,
            link: "Usuarios"
          },
          {
            label: "Consumo e Economia",
            icone: iconeConsumoEconomia,
            link: "ConsumoEconomia"
          },
          {
            label: "AlterarSenha",
            icone: iconeAlterarSenha,
            link: "AlterarSenha"
          }
        ]);
        break;

      default:
        setMenuCentral([
          {
            label: "Medição",
            icone: iconeMedicao,
            link: "MedicaoConsolidada"
          },
          {
            label: "Perfil",
            icone: iconePerfil,
            link: "Usuarios"
          },
          {
            label: "Meus Documentos",
            icone: iconeDocumentos,
            link: "RelatoriosCustomizadosBaixar"
          },
          {
            label: "AlterarSenha",
            icone: iconeAlterarSenha,
            link: "AlterarSenha"
          }
        ]);
    }
  };

  useEffect(() => {
    montarMenu(usuario.usuario.role.replace(/\s+/g, ""));
  }, []);

  const homeLuz = () =>
    !mobile ? null : (
      <Grid container>
        <Grid xs={12} item>
          <EconomiaMobile
            onClick={() => salvarMenuColecao("Economia", menu)}
            to={`${RotasDTO.Economia}/mobile`}
          >
            <TextoMobile>Economia</TextoMobile>
          </EconomiaMobile>

          <FinanceiroMobile
            onClick={() => salvarMenuColecao("Faturas", menu)}
            to={`${RotasDTO.Faturas}/mobile`}
          >
            <TextoMobile>Financeiro</TextoMobile>
          </FinanceiroMobile>

          <ConsumoMobile
            onClick={() => salvarMenuColecao("DashboardConsumo", menu)}
            to={`${RotasDTO.DashboardConsumo}/mobile`}
          >
            <TextoMobile>Consumo</TextoMobile>
          </ConsumoMobile>

          <RelatoriosMobile
            onClick={() => salvarMenuColecao("RelatoriosDashboard", menu)}
            to={`${RotasDTO.RelatoriosDashboard}/mobile`}
          >
            z<TextoMobile>Relatórios</TextoMobile>
          </RelatoriosMobile>
        </Grid>
      </Grid>
    );

  return (
    <>
      {usuario?.usuario?.tipo === "Cliente" &&
      !usuario?.usuario?.role?.toLowerCase()?.includes("cliente ml") ? (
        <HomeCliente />
      ) : !mobile ? (
        <Grid container>
          {menuCentral.map((item, i) => {
            return (
              <Grid
                md={menuCentral?.length <= 4 ? 6 : 4}
                xs={12}
                item
                className={classes.paddingCardDashboardLuz}
                key={i}
              >
                <Box className={classes.card50DashboardLuz}>
                  <Link
                    className={classes.linkDashboard}
                    onClick={() => salvarMenuColecao(item.link, menu)}
                    to={`${RotasDTO[item.link]}`}
                  >
                    <img
                      src={item.icone}
                      className={classes.iconeDashboardLuz}
                      alt="ícone luz"
                    />
                    {item.label}
                  </Link>
                </Box>
              </Grid>
            );
          })}
        </Grid>
      ) : (
        <Grid container>
          {menuCentral.map((item, i) => {
            return (
              <Box component={Grid} item xs={12} key={i}>
                <Box className={classes.card50DashboardLuz} mt={2}>
                  <Link
                    className={classes.linkDashboard}
                    onClick={() => salvarMenuColecao(item.link, menu)}
                    to={`${RotasDTO[item.link]}`}
                  >
                    <Box>
                      <Box display="flex" justifyContent="center">
                        <Box
                          component="img"
                          src={item.icone}
                          className={classes.iconeDashboardLuz}
                        />
                      </Box>
                      <Box display="flex" justifyContent="center" fontSize="10">
                        {item.label}
                      </Box>
                    </Box>
                  </Link>
                </Box>
              </Box>
            );
          })}
        </Grid>
      )}
    </>
  );
};

export default Home;
