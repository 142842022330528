import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  container: {
    margin: "0 0 24px",
    width: "100%",
    "& .MuiInputBase-root": {
      height: "46px"
    },
    "& .MuiFormLabel-root": {
      lineHeight: "1"
    }
  },
  button: {
    fontSize: "18px"
  },
  savewrapper: {
    width: "30%",
    padding: '0 0 16px 16px'
  }
});
