import { makeStyles } from '@mui/styles';
import { Base } from "componentes/cores";
import styled from "styled-components";
import theme from 'themes';

export const useStyles = makeStyles(() => ({
  containerContent: {
    padding: "30px 10px 30px 0px",
    maxHeight: "300px",
    overflowX: "auto",
    fontFamily: theme.font.family,
    fontSize: "18px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "-0.4px",
    textAlign: "justify",
    color: Base.Preto
  }
}));

export const ModeloDocumento = styled.img`
  display: flex;
  margin: 0 auto;
  max-width: 450px;
`;
