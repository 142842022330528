import MICROSERVICO from "../global/enumeradores/microServico";
import obterApiLuz from "./apiLuz";
import obterApi from "./api";
import { tipoProduto, tipoUrl } from "../global/utils/tipoProdutoUtil";

const apiLuz = (log) => obterApiLuz(MICROSERVICO.SITE_LUZ_LEAD, log);
const api = (log) => obterApi(MICROSERVICO.MANAGEMENT, log);

export const criarLeadClienteLuz = async (body, id) => {
  const metodo = id ? "put" : "post";

  let url = "";
  if (id) {
    url = "/v1/LeadCliente/UpdateByCotacaoGdId";
  } else {
    url = "/v1/LeadCliente";
  }
  const log = {
    nestedField: "post_LeadCliente",
    tipo: "atualizaLog",
    index: `cotacao-gd-${process.env.REACT_APP_ENVIRONMENT}`
  };
  return apiLuz(log)[metodo](url, body);
};

export const criarLeadClientePFLuz = async (body, id) => {
  const metodo = id ? "put" : "post";

  let url = "";

  if (id) {
    url = "/v1/LeadCliente/UpdateByCotacaoGdId";
  } else {
    url = "/v1/LeadCliente/pf";
  }
  const log = {
    nestedField: "post_LeadCliente_pf",
    tipo: "atualizaLog",
    index: `cotacao-gd-${process.env.REACT_APP_ENVIRONMENT}`
  };
  return apiLuz(log)[metodo](url, body);
};

// pf e pj
export const criarLeadUnidade = async (body, id) => {
  const metodo = id ? "put" : "post";

  let url = "";
  if (id) {
    url = "/v1/LeadUnidade/UpdateClientId";
  } else {
    url = "/v1/LeadUnidade";
  }
  const log = {
    nestedField: "post_LeadUnidade",
    tipo: "atualizaLog",
    index: `cotacao-gd-${process.env.REACT_APP_ENVIRONMENT}`
  };
  return apiLuz(log)[metodo](url, body);
};

// pf e pj
export const criarLeadPlanoSelecionado = async (body, id) => {
  const metodo = id ? "put" : "post";

  let url = "";

  if (id) {
    url = "/v1/LeadUnidade/UpdateLeadPlanoLeadUnidadeId";
  } else {
    url = "/v1/LeadUnidade/leadPLanoSelecionado";
  }
  const log = {
    nestedField: "post_LeadUnidade_leadPLanoSelecionado",
    tipo: "atualizaLog",
    index: `cotacao-gd-${process.env.REACT_APP_ENVIRONMENT}`
  };
  return apiLuz(log)[metodo](url, body);
};

export const criarTermoAdesao = async (body) => {
  const log = {
    nestedField: "post_SimuladorSite_docusign_termo-adesao-gd",
    tipo: "atualizaLog",
    index: `cotacao-gd-${process.env.REACT_APP_ENVIRONMENT}`
  };

  const distribuidoraId = tipoProduto(body.distribuidoraId);
  const url = tipoUrl(body.distribuidoraId);
  delete body.distribuidoraId;

  return distribuidoraId
    ? api(log).post(url, body)
    : apiLuz(log).post(url, body);
};

// pf e pj
export const atualizarSalesForce = async (body) => {
  const log = {
    nestedField: "put_SFIntegracaoEnergia_PreLead",
    tipo: "atualizaLog",
    index: `cotacao-gd-${process.env.REACT_APP_ENVIRONMENT}`
  };
  return apiLuz(log).put("/v1/SFIntegracaoEnergia/PreLead", body);
};

export const criarTermoAdesaoPJ = async (body) => {
  const log = {
    nestedField: "post_SimuladorSite_docusign_termo-adesao-gd_pj",
    tipo: "atualizaLog",
    index: `cotacao-gd-${process.env.REACT_APP_ENVIRONMENT}`
  };

  const distribuidoraId = tipoProduto(body.distribuidoraId);
  const url = tipoUrl(body.distribuidoraId, "/pj");
  delete body.distribuidoraId;

  return distribuidoraId
    ? api(log).post(url, body)
    : apiLuz(log).post(url, body);
};

export const obterUnidadeConsumidoraSimulador = async (params) => {
  const filtroQueryString = new URLSearchParams(params).toString();
  const log = {
    nestedField: "get_MatrizDescontoGDBaixa_descontoSemFidelidade",
    tipo: "atualizaLog",
    index: `cotacao-gd-${process.env.REACT_APP_ENVIRONMENT}`
  };
  return apiLuz(log).get(
    `/v1/SimuladorSite/unidadeConsumidora?${filtroQueryString}`
  );
};

export const obterMatrizDescontoSimulacao = async (params, paramsOpcional) => {
  const { idDistribuidora, valorSemGD, pessoaFisicaJuridica } = params;
  const queryString = new URLSearchParams(paramsOpcional).toString();
  return apiLuz().get(
    `/v1/MatrizDescontoGDBaixa/ObterPorDistribuidoraFasePessoa/${idDistribuidora}/${valorSemGD}/Trifasico/${pessoaFisicaJuridica}?${queryString}`
  );
};

export const criarCotacaoGD = async (body, id) => {
  const metodo = id ? "patch" : "post";
  let url = "";
  if (id) {
    url = `/v1/CotacaoGD/Update/${id}`;
  } else {
    url = "/v1/CotacaoGD";
  }
  const log = {
    nestedField: id ? "patch_CotacaoGD" : "post_CotacaoGD",
    tipo: "atualizaLog",
    index: `cotacao-gd-${process.env.REACT_APP_ENVIRONMENT}`
  };
  return api(log)[metodo](url, body);
};

export const criarCotacaoGDPlanosGerado = async (body) => {
  const metodo = "post";
  const log = {
    nestedField: "post_CotacaoGDPlanosGerados",
    tipo: "atualizaLog",
    index: `cotacao-gd-${process.env.REACT_APP_ENVIRONMENT}`
  };
  return api(log)[metodo]("/v1/CotacaoGDPlanosGerados", body);
};

export const atualizarCotacaoGD = async (id, body) => {
  const log = {
    nestedField: "put_CotacaoGD_Update",
    tipo: "atualizaLog",
    index: `cotacao-gd-${process.env.REACT_APP_ENVIRONMENT}`
  };
  return api(log).patch(`/v1/CotacaoGD/Update/${id}`, body);
};

export const obterCotacaoGdId = async (id) => {
  return api().get(`/v1/CotacaoGD/${id}`);
};

export const atualizarCotacaoGDPlanosGerado = async (body) => {
  const log = {
    nestedField: "post_CotacaoGDPlanosGerados",
    tipo: "atualizaLog",
    index: `cotacao-gd-${process.env.REACT_APP_ENVIRONMENT}`
  };
  return api().patch("/v1/CotacaoGDPlanosGerados/Update", body);
};

export const obterCotacaoGd = async (model) => {
  const { numeroInstalacao, cpfCnpj, distribuidoraId } = model;
  const log = {
    nestedField: "get_CotacaoGD",
    tipo: "atualizaLog",
    index: `cotacao-gd-${process.env.REACT_APP_ENVIRONMENT}`
  };
  return api(log).get(
    `/v1/CotacaoGD/${numeroInstalacao}/${cpfCnpj}/${distribuidoraId}`
  );
};

export const criarCotacaoGDSemFatura = async (body) => {
  const metodo = "post";
  return api()[metodo]("/v1/CotacaoGDSemFatura", body);
};

export const obterCotacaoGdIdSemFatura = async (id) => {
  return api().get(`/v1/CotacaoGDSemFatura/${id}`);
};

export const obterPorCupom = async (cupom) => {
  return apiLuz().get(
    `/v1/SimuladorSite/ColaboradorCupom/ObterPorCupom/${cupom}`
  );
};

export const cotacaoProposta = async (body) => {
  return api().post("/v1/CotacaoGD/proposta", body);
};

export const edicaoDesconto = async (body) => {
  const metodo = "post";
  const url = "/v1/MatrizDescontoGDBaixa/EdicaoDesconto";

  return apiLuz()[metodo](url, body);
};
