import { store } from "global/redux";
import { alertaExibir } from "global/redux/modulos/alertas/actions";
import { ImportarFaturaFourvision } from "servicos/digitalizacaoServico";
import { 
  AddProcessamentoFaturaML, 
  AtualizarProcessamentoFaturaML,
  obterCalculoRealizadoEProcessado, 
  calcularDiferencaFaturadoEProcessado } from "servicos/processamentoFaturaMLServico";
import { listarFaturados } from "../../../../servicos/preFaturamentoServico";

export default class ProcessamentoFaturaMLHelper {

  static async ImportarFaturaFourvision(arquivo) {
    try {
      return await ImportarFaturaFourvision(arquivo);
    } catch (ex) {
      store.dispatch(
        alertaExibir({ tipo: "warning", mensagem: ex?.response?.data.responseText ?? "Erro ao importar arquivoXX!" })
      )
    }
  }

  static async AddProcessamentoFaturaML(parametros) {
    try {
      const response = await AddProcessamentoFaturaML(parametros);

      if(response?.status === 200){
        store.dispatch(
          alertaExibir({ tipo: "success", mensagem: `Processado com sucesso!` })
        );
      }
    } catch (ex) {
      store.dispatch(
        alertaExibir({ tipo: "danger", mensagem: ex?.response?.data?.message ?? "Erro interno, entre em contato com o suporte!" })
      );
    }
  }

  static async AtualizarProcessamentoFaturaML(unidadeId, mes, ano, parametros) {
    try {
      const response = await AtualizarProcessamentoFaturaML(unidadeId, mes, ano, parametros);

      if(response?.status === 200){
        store.dispatch(
          alertaExibir({ tipo: "success", mensagem: `Processado com sucesso!` })
        );
      }
    } catch (ex) {
      store.dispatch(
        alertaExibir({ tipo: "danger", mensagem: ex?.response?.data?.message ?? "Erro interno, entre em contato com o suporte!" })
      );
    }
  }

  static async ListarFaturados(filtro) {
    try {
      return await listarFaturados(filtro);
    } catch (ex) {
      store.dispatch(
        alertaExibir({ tipo: "danger", mensagem: ex?.response?.data?.message ?? "Erro interno, entre em contato com o suporte!" })
      );
    }
  }

  static async ObterCalculoRealizadoEProcessado(unidadeId, mes, ano) {
    try {
      return await obterCalculoRealizadoEProcessado(unidadeId, mes, ano);
    } catch (ex) {
      store.dispatch(
        alertaExibir({ tipo: "danger", mensagem: ex?.response?.data?.message ?? "Erro interno, entre em contato com o suporte!" })
      );
    }
  }

  static async CalcularDiferencaFaturadoEProcessado(unidadeId, mes, ano, parametros) {
    try {
      return await calcularDiferencaFaturadoEProcessado(unidadeId, mes, ano, parametros);
    } catch (ex) {
      store.dispatch(
        alertaExibir({ tipo: "danger", mensagem: ex?.response?.data?.message ?? "Erro interno, entre em contato com o suporte!" })
      );
    }
  }

  static exibirErro(mensagem) {
    store.dispatch(
      alertaExibir({
        tipo: "danger",
        mensagem
      })
    );
  }

  static exibirSucesso(mensagem) {
    store.dispatch(
      alertaExibir({
        tipo: "success",
        mensagem
      })
    );
  }
}
