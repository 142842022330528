import { makeStyles } from '@mui/styles';
import { Base } from "../../../../componentes/cores";
import theme from 'themes';

export const useStyles = makeStyles({
  container: {
    margin: "0 0 24px",
    width: "100%",
    "& .MuiGrid-grid-xs-4": {
      padding: "12px"
    }
  },
  filter: {
    color: `${theme.color.secondaryBorderColor} !important`,
    display: "inline",
    fontSize: "18px",
    margin: 0,
    padding: 0,
    "& li": {
      display: "inline-block",
      marginLeft: "45px",
      position: "relative",
      "&:nth-child(1) .icon": {
        backgroundColor: Base.GreenFern
      },
      "&:nth-child(2) .icon": {
        backgroundColor: Base.BlueViolet
      },
      "&:nth-child(3) .icon": {
        backgroundColor: Base.BlueDodger
      }
    },
    "& .icon": {
      display: "inline-block",
      height: "20px",
      left: "-30px",
      position: "absolute",
      width: "20px"
    },
    "& .texto": { marginTop: "-2px" }
  },
  button: {
    fontSize: "18px"
  }
});
