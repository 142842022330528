import React, { useMemo, useState, useEffect, useCallback } from "react";
import { Box, Button, Grid } from "@mui/material";
import DataTable from "react-data-table-component";
import { Cancel, Delete, Edit } from "@mui/icons-material";
import FiltroDto from "./dto/filtroDto";
import FiltrosMemberGetMember from "../Componentes/Filtros";
import MemberGetMemberDistribuidora from "./helper";
import Colunas from "./Colunas";
import { customStyles, useStyles } from "./style";
import TabelaSemDados from "../Componentes/TabelaSemDados";
import {
  Botao,
  SelectArredondado,
  SelectAutocomplete
} from "../../../componentes";
import InputData from "../Componentes/InputData";
import InputTexto from "../Componentes/inputTexto";
import ModalMensagemErro from "../../../componentes/ModalMensagemErro";
import ModalLuz from "../../../componentes/modalLuz";

const DistribuidoraMemberGetMember = () => {
  const classes = useStyles();
  const [dadosDistribuidora, setDadosDistribuidora] = useState([]);
  const [inativarAdd, setInativarAdd] = useState(false);
  const [ultimaAtualizacao, setUltimaAtualizacao] = useState(null);
  const [distribuidoraId, setDistribuidoraId] = useState(null);
  const [listaDistribuidora, setListaDistribuidora] = useState([]);
  const [
    carregandoBenificioDistribuidora,
    setCarregandoBenificioDistribuidora
  ] = useState(false);
  const [idSelecionado, setIdSelecionado] = useState(null);
  const [podeEditar, setPodeEditar] = useState(true);
  const [mensagemModal, setMensagemModal] = useState("");
  const [modalAcao, setModalAcao] = useState(false);
  const [mensagemModalVigencia, setMensagemModalVigencia] = useState("");
  const [modalAcaoVigencia, setModalAcaoVigencia] = useState(false);
  const [modalEditar, setModalEditar] = useState(false);
  const [atualizaStatusId, setAtualizaStatusId] = useState(null);

  const [
    dadosEnviarBeneficiosDistribuidora,
    setDadosEnviarBeneficiosDistribuidora
  ] = useState({
    id: null,
    elasticidadeBeneficioId: 0,
    distribuidoraId: 0,
    valorIndicador: 0,
    valorAmigoIndicado: 0,
    dataInicioVigencia: null,
    dataFimVigencia: null,
    status: "S",
    ordemPrioritaria: 0,
    enabled: true
  });

  const [carregandoDistribuidora, setCarregandoDistribuidora] = useState(false);

  const obterDistribuidora = useCallback(async () => {
    const {
      data,
      sucesso,
      mensagem
    } = await MemberGetMemberDistribuidora.obterDistribuidora(
      setCarregandoDistribuidora
    );

    if (!sucesso) {
      MemberGetMemberDistribuidora.exibirErro(mensagem);
      setCarregandoDistribuidora(false);
      return;
    }
    setListaDistribuidora(data);
  }, []);

  const handleDadosDistribuidora = (dados) => {
    return dados?.map((item) => ({
      descricao: item?.descricao,
      ordemPrioritaria: item?.ordemPrioritaria,
      elasticidadeBeneficioId: item?.elasticidadeBeneficioId,
      distribuidoraId: item.distribuidoraId,
      valorIndicador: item?.valorIndicador,
      valorAmigoIndicado: item?.valorAmigoIndicado,
      dataInicioVigencia: item?.dataInicioVigencia,
      dataFimVigencia: item?.dataFimVigencia,
      status: item?.status,
      enabled: item?.enabled,
      id: item.id
    }));
  };

  const obterBeneficioDistribuidora = async (distribuidoraSelecionada) => {
    const filtro = new FiltroDto(distribuidoraSelecionada || "", "S");

    const {
      data,
      sucesso,
      mensagem
    } = await MemberGetMemberDistribuidora.obterBeneficioDistribuidora(
      filtro,
      setCarregandoBenificioDistribuidora
    );

    if (!sucesso) {
      MemberGetMemberDistribuidora.exibirErro(mensagem);
      setCarregandoBenificioDistribuidora(false);
      return;
    }

    const dadosDistribuidoraFormatado = handleDadosDistribuidora(data || []);

    setIdSelecionado(null);
    setUltimaAtualizacao(data?.[0]?.dataAtualizacao);
    setDadosDistribuidora(dadosDistribuidoraFormatado);
    setInativarAdd(false);
  };

  useEffect(() => {
    obterBeneficioDistribuidora();
    obterDistribuidora();
  }, []);

  useEffect(() => {
    obterBeneficioDistribuidora(distribuidoraId);
  }, [distribuidoraId]);

  const handleChangeData = (valor, id, nameInput) => {
    setIdSelecionado(id);
    setDadosEnviarBeneficiosDistribuidora({
      ...dadosEnviarBeneficiosDistribuidora,
      [nameInput]: window.moment(valor).isValid()
        ? window.moment(valor).format()
        : null
    });
  };

  const formataValor = (valor) => {
    if (typeof valor === "number") return valor;
    if (!valor) return "";
    return valor
      ? parseFloat(
          String(valor)
            .trim()
            .replace(",00", "")
            .replace("R$", "")
            .replace(" ", "")
            .replaceAll(".", "")
            .replace(",", ".")
        )
      : valor;
  };

  const handleChange = (e, id) => {
    setIdSelecionado(id);
    setDadosEnviarBeneficiosDistribuidora({
      ...dadosEnviarBeneficiosDistribuidora,
      [e?.target?.name]: formataValor(e?.target?.value)
    });
  };

  const handleChangeComplete = (e, id) => {
    setIdSelecionado(id);
    setDadosEnviarBeneficiosDistribuidora({
      ...dadosEnviarBeneficiosDistribuidora,
      [e?.name]: formataValor(e?.value)
    });
  };

  const removerLinha = (dados) => {
    if (dados.id !== 0) {
      setModalEditar(true);
      setAtualizaStatusId(dados.id);
      return;
    }

    const linha = dadosDistribuidora?.filter((item) => item.id !== 0);

    setDadosDistribuidora(linha);

    setInativarAdd(false);
    setPodeEditar(true);
    setDadosEnviarBeneficiosDistribuidora({
      id: 0,
      distribuidoraId: null,
      ordemPrioritaria: null,
      valorIndicador: null,
      valorAmigoIndicado: null,
      dataInicioVigencia: null,
      dataFimVigencia: null,
      status: "S",
      enabled: false
    });
  };

  const cancelarEdicao = () => {
    setInativarAdd(false);
    setPodeEditar(true);
    setIdSelecionado(null);
    obterBeneficioDistribuidora(distribuidoraId);
  };

  const editarLinha = (item) => {
    setIdSelecionado(item.id);
    setPodeEditar(false);

    setDadosEnviarBeneficiosDistribuidora({
      id: item.id,
      descricao: item.descricao,
      ordemPrioritaria: item.ordemPrioritaria,
      distribuidoraId: item.distribuidoraId,
      elasticidadeBeneficioId: item.elasticidadeBeneficioId,
      valorIndicador: item.valorIndicador,
      valorAmigoIndicado: item.valorAmigoIndicado,
      dataInicioVigencia: window.moment(item.dataInicioVigencia).format(),
      dataFimVigencia: window.moment(item.dataFimVigencia).format(),
      status: item.status,
      enabled: false
    });
  };

  const dataFormatada = useMemo(() => {
    const dadosFormatados =
      dadosDistribuidora &&
      dadosDistribuidora?.map((item) => ({
        nomeDistribuidora: (
          <SelectAutocomplete
            disableClearable
            disableOpenOnFocus
            id="distribuidoraId"
            name="distribuidoraId"
            options={listaDistribuidora}
            label=""
            getOptionLabel={(option) => option?.nomeFantasia ?? distribuidoraId}
            onChange={(e, distribuidora) => {
              handleChangeComplete(
                { name: "distribuidoraId", value: distribuidora.id },
                item?.id
              );
            }}
            value={
              item?.id === idSelecionado
                ? dadosEnviarBeneficiosDistribuidora?.distribuidoraId
                : item?.distribuidoraId
            }
            allowClear
            valueName="nomeFantasia"
            disabled={!(item.id === idSelecionado && !podeEditar)}
            inputProps={{ "aria-label": "controlled" }}
            className={classes.formControl}
          />
        ),
        dataInicioVigencia: (
          <InputData
            type="text"
            id={item?.id}
            name="dataInicioVigencia"
            label=""
            customValue={
              item?.dataInicioVigencia
                ? new Date(item?.dataInicioVigencia)
                : null
            }
            onChange={(data) =>
              handleChangeData(data, item?.id, "dataInicioVigencia")
            }
            errors={!item.dataInicioVigencia}
            disabled={!(item.id === idSelecionado && !podeEditar)}
            className={classes}
          />
        ),
        dataFimVigencia: (
          <InputData
            type="text"
            id={item?.id}
            name="dataFimVigencia"
            label=""
            customValue={
              item.dataFimVigencia ? new Date(item.dataFimVigencia) : null
            }
            onChange={(data) =>
              handleChangeData(data, item?.id, "dataFimVigencia")
            }
            errors={!item.dataFimVigencia}
            minDate={window
              .moment(dadosEnviarBeneficiosDistribuidora?.dataInicioVigencia)
              .toDate()}
            disabled={!(item.id === idSelecionado && !podeEditar)}
            className={classes}
          />
        ),
        valorAmigoIndicado: (
          <InputTexto
            name="valorAmigoIndicado"
            id="valorAmigoIndicado"
            defaultValue={
              item?.valorAmigoIndicado === null ? "" : item?.valorAmigoIndicado
            }
            disabled={!(item.id === idSelecionado && !podeEditar)}
            onBlur={(e) => handleChange(e, item.id)}
            className={classes.formControl}
          />
        ),
        valorIndicador: (
          <InputTexto
            name="valorIndicador"
            id="valorIndicador"
            defaultValue={
              item?.valorIndicador === null ? "" : item?.valorIndicador
            }
            disabled={!(item.id === idSelecionado && !podeEditar)}
            onBlur={(e) => handleChange(e, item.id)}
            className={classes.formControl}
          />
        ),
        ordemPrioritaria: (
          <SelectArredondado
            id={item.id}
            name="ordemPrioritaria"
            valueKey="id"
            valueName="value"
            dataSource={[
              { id: 1, value: "1" },
              { id: 2, value: "2" },
              { id: 3, value: "3" }
            ]}
            label=""
            value={
              item?.id === idSelecionado
                ? dadosEnviarBeneficiosDistribuidora?.ordemPrioritaria
                : item?.ordemPrioritaria
            }
            onChange={(event) => handleChange(event, item?.id)}
            disabled={!(item.id === idSelecionado && !podeEditar)}
            inputProps={{ "aria-label": "controlled" }}
            className={classes.formControl}
          />
        ),
        acoes: (
          <Box textAlign="center" key={item.id}>
            <Button
              name={item?.id}
              id={item?.id}
              className={`${classes.botao} ${classes.ativo}`}
              disabled={item.id === 0 ? false : inativarAdd}
            >
              {item.id === 0 ? (
                <Delete
                  onClick={() => removerLinha(item)}
                  htmlColor="#332053"
                />
              ) : (
                <Delete
                  onClick={() => removerLinha(item)}
                  htmlColor={inativarAdd ? "" : "#332053"}
                />
              )}
            </Button>
            <Button
              name={item?.id}
              id={item?.id}
              className={`${classes.botao} ${classes.ativo}`}
              disabled={inativarAdd}
            >
              {idSelecionado === item.id ? (
                <Cancel
                  htmlColor={inativarAdd ? "" : "#332053"}
                  onClick={() => {
                    cancelarEdicao(item);
                  }}
                />
              ) : (
                <Edit
                  onClick={() => editarLinha(item)}
                  htmlColor={inativarAdd ? "" : "#332053"}
                />
              )}
            </Button>
          </Box>
        )
      }));

    return dadosFormatados;
  }, [
    dadosDistribuidora,
    dadosEnviarBeneficiosDistribuidora,
    listaDistribuidora
  ]);

  const adicionarLinha = () => {
    setInativarAdd(true);
    setPodeEditar(false);
    setIdSelecionado(0);
    const linha = {
      id: 0,
      elasticidadeBeneficioId: 0,
      distribuidoraId: 0,
      valorIndicador: 0,
      valorAmigoIndicado: 0,
      dataInicioVigencia: null,
      dataFimVigencia: null,
      status: "S",
      enabled: true,
      ordemPrioritaria: 0
    };

    setDadosEnviarBeneficiosDistribuidora({
      id: 0,
      elasticidadeBeneficioId: 0,
      distribuidoraId: 0,
      valorIndicador: 0,
      valorAmigoIndicado: 0,
      dataInicioVigencia: null,
      dataFimVigencia: null,
      status: "S",
      ordemPrioritaria: 0,
      enabled: false
    });

    setDadosDistribuidora((prevState) => [linha, ...prevState]);
  };

  const atualizaStatusBeneficioFatura = async () => {
    const model = {
      idBeneficioDistribuidora: atualizaStatusId,
      Status: "N"
    };

    const {
      data,
      sucesso,
      mensagem
    } = await MemberGetMemberDistribuidora.atualizaStatusDistribuidoraFatura(
      model,
      setCarregandoBenificioDistribuidora
    );

    if (!sucesso) {
      MemberGetMemberDistribuidora.exibirErro(mensagem);
      setCarregandoBenificioDistribuidora(false);
      setModalEditar(false);
      return;
    }

    setModalEditar(false);
    obterBeneficioDistribuidora(distribuidoraId);
  };

  const handleInativar = () => {
    atualizaStatusBeneficioFatura(dadosEnviarBeneficiosDistribuidora);
  };

  const verificarCampos = (dados) => {
    if (
      dados?.ordemPrioritaria &&
      dados?.valorIndicador &&
      dados?.valorAmigoIndicado &&
      dados?.distribuidoraId &&
      window.moment(dados?.dataInicioVigencia).isValid() &&
      window.moment(dados?.dataFimVigencia).isValid()
    ) {
      return false;
    }

    return true;
  };

  const salvarDistribuidoraFatura = async (dadosPreenchidos) => {
    const model = {
      elasticidadeBeneficioId: 3,
      distribuidoraId: dadosPreenchidos?.distribuidoraId,
      valorIndicador: dadosPreenchidos?.valorIndicador,
      valorAmigoIndicado: dadosPreenchidos?.valorAmigoIndicado,
      dataInicioVigencia: window
        .moment(dadosPreenchidos?.dataInicioVigencia)
        .format("YYYY-MM-DD"),
      dataFimVigencia: window
        .moment(dadosPreenchidos?.dataFimVigencia)
        .format("YYYY-MM-DD"),
      status: dadosPreenchidos?.status,
      ordemPrioritaria: dadosPreenchidos?.ordemPrioritaria
    };

    const {
      data,
      sucesso,
      mensagem
    } = await MemberGetMemberDistribuidora.salvarDistribuidoraFatura(
      dadosPreenchidos?.id,
      model,
      setCarregandoBenificioDistribuidora
    );

    if (!sucesso) {
      MemberGetMemberDistribuidora.exibirErro(mensagem);
      setCarregandoBenificioDistribuidora(false);
      return;
    }

    obterBeneficioDistribuidora(distribuidoraId);
    setInativarAdd(false);
    setPodeEditar(true);
    setModalEditar(false);
  };

  const verificarVigencia = (dadosEnviar, dadosSemAdicao) => {
    const ranges = dadosSemAdicao.filter(
      (item) => dadosEnviar.distribuidoraId === item?.distribuidoraId
    );

    const dataInicio = ranges.map((item) => new Date(item.dataInicioVigencia));
    const dataFIm = ranges.map((item) => new Date(item.dataFimVigencia));

    const datas = [...dataInicio, ...dataFIm];
    const dataMenor = new Date(Math.min(...datas));
    const dataMaior = new Date(Math.max(...datas));

    const dataInicioVigencia = window
      .moment(dadosEnviar?.dataInicioVigencia)
      // .subtract(1, "day")
      .format("YYYY-MM-DD");
    const dataFimVigencia = window
      .moment(dadosEnviar?.dataFimVigencia)
      // .add(1, "day")
      .format("YYYY-MM-DD");

    if (
      window.moment(dataInicioVigencia).format("YYYY-MM-DD") <=
        window.moment(dataMenor).format("YYYY-MM-DD") &&
      window.moment(dataFimVigencia).format("YYYY-MM-DD") >=
        window.moment(dataMaior).format("YYYY-MM-DD")
    ) {
      return true;
    }

    let rangeExisteData = false;

    ranges.map((item) => {
      const dataInicioRange = window
        .moment(item.dataInicioVigencia)
        .format("YYYY-MM-DD");
      const dataFimRange = window
        .moment(item.dataFimVigencia)
        .format("YYYY-MM-DD");

      if (
        window
          .moment(dataInicioVigencia)
          .isSame(dataFimRange, dataInicioRange) ||
        window.moment(dataFimVigencia).isSame(dataInicioRange, dataFimRange)
      ) {
        rangeExisteData = true;
        // eslint-disable-next-line no-useless-return
        return;
      }

      if (
        window
          .moment(dataInicioVigencia)
          .isBetween(dataInicioRange, dataFimRange) ||
        window.moment(dataFimVigencia).isBetween(dataInicioRange, dataFimRange)
      ) {
        rangeExisteData = true;
        // eslint-disable-next-line no-useless-return
        return;
      }
    });

    // eslint-disable-next-line consistent-return
    return rangeExisteData;
  };

  const salvarValorConta = () => {
    if (dadosEnviarBeneficiosDistribuidora.id === null) return;
    const dadosPreenchidos = verificarCampos(
      dadosEnviarBeneficiosDistribuidora
    );

    if (dadosPreenchidos) {
      setModalAcao(dadosPreenchidos);
      setMensagemModal("Todos os campos são obrigatórios!");
      return;
    }

    if (
      window
        .moment(dadosEnviarBeneficiosDistribuidora?.dataInicioVigencia)
        .isAfter(dadosEnviarBeneficiosDistribuidora?.dataFimVigencia)
    ) {
      setModalAcao(true);
      setMensagemModal(
        "Valor data inicio não pode ser maior que o da data fim!"
      );
      return;
    }

    let dadosSemAdicao = [];
    if (dadosEnviarBeneficiosDistribuidora.id === 0) {
      dadosSemAdicao = dadosDistribuidora.filter((item) => item?.id !== 0);
    } else {
      dadosSemAdicao = dadosDistribuidora.filter(
        (item) => item?.id !== dadosEnviarBeneficiosDistribuidora.id
      );
    }

    const existeVigencia = verificarVigencia(
      dadosEnviarBeneficiosDistribuidora,
      dadosSemAdicao
    );

    if (existeVigencia) {
      setModalAcaoVigencia(existeVigencia);
      setMensagemModalVigencia("Valores e períodos já existentes!");
      return;
    }

    salvarDistribuidoraFatura(dadosEnviarBeneficiosDistribuidora);
  };

  const handleModal = (mensagem) => {
    return (
      <Box component="div" fontWeight="700" textAlign="center">
        {mensagem}
      </Box>
    );
  };

  return (
    <Grid container>
      <ModalMensagemErro
        item={modalAcao}
        titulo=""
        mensagem={handleModal(mensagemModal)}
        onCancelar={() => {
          setModalAcao(false);
          setMensagemModal("");
        }}
        onConfirmar={() => {}}
        texto="Tentar novamente"
      />
      <ModalMensagemErro
        item={modalAcaoVigencia}
        mensagem={handleModal(mensagemModalVigencia)}
        onCancelar={() => {
          setModalAcaoVigencia(false);
          setMensagemModalVigencia("");
        }}
        onConfirmar={() => {}}
        texto="Tentar novamente"
      />
      <ModalLuz
        item={modalEditar}
        mensagem={handleModal("Têm certeza que deseja inativar?")}
        onCancelar={() => {
          setModalEditar(false);
          setAtualizaStatusId(null);
        }}
        onConfirmar={() => handleInativar()}
      />
      <FiltrosMemberGetMember
        adicionarLinha={adicionarLinha}
        ultimaAtualizacao={
          ultimaAtualizacao
            ? window.moment(ultimaAtualizacao).format("DD/MM/yyyy HH:mm")
            : ""
        }
        inativarAdd={inativarAdd}
        setDistribuidoraId={setDistribuidoraId}
        distribuidoraId={distribuidoraId}
        listaDistribuidora={listaDistribuidora}
        filtros="DistribuidoraMemberGetMember"
        carregandoDistribuidora={carregandoDistribuidora}
      />
      <Grid item xs={12} className="mt-3">
        <DataTable
          columns={Colunas}
          data={dataFormatada}
          theme="DeltaEnergia"
          persistTableHead
          customStyles={customStyles}
          noDataComponent={<TabelaSemDados />}
          noHeader
          responsive
          progressPending={carregandoBenificioDistribuidora}
          dense
        />
      </Grid>
      <Grid container className="mt-3 w-100">
        <Grid item md={3}>
          <Botao
            label="Salvar"
            onClick={() => salvarValorConta()}
            disabled={!inativarAdd && podeEditar}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DistribuidoraMemberGetMember;
