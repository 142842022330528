import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

// Componentes
import Modal from "@mui/material/Modal";
import { Grid } from "@mui/material";
import Botao from "../botao";
import SelectArredondado from "../selectArredondado";
import CloseIcon from "@mui/icons-material/Close";

// Form Hooks
import { useForm } from "react-hook-form";

// Styles
import { useStyles } from "./style";
import theme from 'themes';

const ModalMaior = React.forwardRef((props, ref) => {
  const {
    item,
    onConfirmar,
    onCancelar,
    mensagem,
    dataSourcePlataforma,
    valuePlataforma,
    onChange
  } = props;

  const classes = useStyles();
  const [aberto, setAberto] = useState(false);
  const { register, errors, handleSubmit } = useForm({
    reValidateMode: "onSubmit"
  });

  const aoEnviar = (item) => {
    onConfirmar(item);
  };

  useEffect(() => {
    setAberto(Boolean(item));
  }, [item]);

  const onClose = () => {
    setAberto(false);
    onCancelar();
  };

  return (
    <Modal open={aberto} onClose={onClose}>
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        className={classes.container}
        p={1}
        spacing={1}
      >

        <Grid item xs={1}>
          <CloseIcon
            className={classes.icon}
            onClick={() => {
              onClose();
            }}
          />
        </Grid>
        <Grid item xs={12}>
          {mensagem}
        </Grid>


      </Grid>
    </Modal>
  );
});

ModalMaior.propTypes = {
  item: PropTypes.oneOfType([PropTypes.any]).isRequired,
  onConfirmar: PropTypes.oneOfType([PropTypes.func]),
  onCancelar: PropTypes.oneOfType([PropTypes.func]),
  mensagem: PropTypes.oneOfType([PropTypes.node, PropTypes.any]),
  dataSourcePlataforma: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object
  ]),
  valuePlataforma: PropTypes.oneOfType([PropTypes.any]),
  onChange: PropTypes.oneOfType([PropTypes.func])
};

ModalMaior.defaultProps = {
  onConfirmar: () => { },
  onCancelar: () => { },
  mensagem: {},
  dataSourcePlataforma: [],
  valuePlataforma: "",
  onChange: () => { }
};

export default ModalMaior;
