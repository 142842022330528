import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { extendMoment } from "moment-range";
import shortid from "shortid";

// Form Hooks
import { useForm } from "react-hook-form";

// Componentes
import { Card, Grid } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DataTable from "react-data-table-component";
import Loader from "../../../../componentes/loader";
import Botao from "../../../../componentes/botao";
import BotaoRetornarListagem from "../../../../componentes/botaoRetornarListagem";
import SelectAutoComplete from "../../../../componentes/selectAutocomplete";
import InputData from "../../../../componentes/inputTextoData";
import SelectArredondado from "../../../../componentes/selectArredondado";
import MaterialSwitch from "componentes/switch";
import BotaoCadastro from "componentes/botaoCadastro";
import { Base } from "componentes/cores";
import Coluna from "componentes/tabelaPaginada/colunas/coluna";
import ColunaMultiplosBotoes from "../listagem/colunaMultiplosBotoes";
import ColunaComponentePersonalizado from "componentes/tabelaPaginada/colunas/colunaComponentePersonalizado";
import MaterialInputMascara from "componentes/inputTextoMascara";
import { ModalConfirmacao } from "componentes";
import MaterialInputTexto from "componentes/inputTexto/materialInput";

// Serviços
import { listarEmpresasGrupoDeltaCombo } from "../../../../servicos/empresaServico";
import { listarClientesCombo } from "../../../../servicos/clientesServico";
import { listarUnidadesMedicao } from "../../../../servicos/unidadesMedidaServico";
import { listarSubmercados } from "../../../../servicos/submercadoServico";
import { listarTipoEnergia } from "../../../../servicos/tiposEnergiaServico";
import {
  salvarBoleta,
  buscarPorId,
  listarFlexibilidadeInferior,
  listarFlexibilidadeSuperior
} from "../../../../servicos/boletasServico";
import { listarProdutos } from "../../../../servicos/produtosServico";
import { listarStatus } from "../../../../servicos/statusServico";
import { listarUnidadesSemPaginacao } from "servicos/unidadesServico";

// Styles
import { customStyles, TabelaSemDados, useStyles } from "./style";

// Redux
import { alertaExibir } from "../../../../global/redux/modulos/alertas/actions";
import { store } from "../../../../global/redux";
import { useSelector } from "react-redux";
import {
  selecionarCliente,
  selecionarEmpresa,
  desabilitarUnidade
} from "../../../../global/redux/modulos/usuario/actions";

// DTOs
import { RotasDTO } from "../../../../global/rotas/rotasUrlDto";
import enumeradorBotao from "../../Boleta/listagem/enumeradores/enumeradorBotao.js";

const BoletaCadastro = () => {
  const moment = extendMoment(window.moment);
  const usuario = useSelector((state) => state.usuario);
  const { id } = useParams();
  const classes = useStyles();
  const history = useHistory();
  const { register, handleSubmit, setError, clearErrors, errors } = useForm({
    reValidateMode: "onSubmit"
  });

  useEffect(() => {
    store.dispatch(desabilitarUnidade(true));
    return () => store.dispatch(desabilitarUnidade(false));
  }, [desabilitarUnidade]);

  // Status
  const [statusLista, setStatusLista] = useState([]);
  const [statusId, setStatusId] = useState();
  const [carregandoStatus, setCarregandoStatus] = useState(false);

  const obterStatus = async () => {
    try {
      setCarregandoStatus(true);
      const lista = await listarStatus();
      if (lista?.status === 200 && lista?.data?.status) {
        setStatusLista(lista?.data.status ?? []);
        setStatusId(
          lista?.data?.status.find((item) => item.sigla === "LA")?.id
        );
      }
      setCarregandoStatus(false);
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
      setCarregandoStatus(false);
    }
  };

  // Tipo de energia
  const [tipoEnergiaId, setTipoEnergiaId] = useState();
  const [tipoEnergiaLista, setTipoEnergiaLista] = useState([]);
  const [carregandoTipoEnergia, setCarregandoTipoEnergia] = useState(false);

  const obterTipoEnergia = async () => {
    try {
      setCarregandoTipoEnergia(true);
      const lista = await listarTipoEnergia();
      if (lista?.status === 200 && lista?.data) {
        setTipoEnergiaLista(lista?.data ?? []);
      }
      setCarregandoTipoEnergia(false);
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
      setCarregandoTipoEnergia(false);
    }
  };

  // Submercado
  const [submercadoId, setSubmercadoId] = useState();
  const [submercadoLista, setSubmercadoLista] = useState([]);
  const [carregandoSubmercado, setCarregandoSubmercado] = useState(false);

  const obterSubmercado = async () => {
    try {
      setCarregandoSubmercado(true);
      const lista = await listarSubmercados(usuario?.token);
      if (lista?.status === 200 && lista?.data) {
        setSubmercadoLista(lista?.data ?? []);
      }
      setCarregandoSubmercado(false);
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
      setCarregandoSubmercado(false);
    }
  };

  // Unidades de medida
  const [unidadeMedidaId, setUnidadeMedidaId] = useState();
  const [unidadesMedidaLista, setUnidadesMedidaLista] = useState([]);
  const [carregandoUnidadesMedida, setCarregandoUnidadesMedida] = useState(
    false
  );

  const obterUnidadesMedida = async () => {
    try {
      setCarregandoUnidadesMedida(true);
      const lista = await listarUnidadesMedicao();
      if (lista?.status === 200 && lista?.data) {
        setUnidadesMedidaLista(lista?.data ?? []);
      }
      setCarregandoUnidadesMedida(false);
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
      setCarregandoUnidadesMedida(false);
    }
  };

  // Produto
  const [produtoLista, setProdutoLista] = useState([]);
  const [produtoId, setProdutoId] = useState();
  const [carregandoProduto, setCarregandoProduto] = useState(false);

  const onChangeProdutoId = (valor) => {
    setProdutoId(valor.target.value);
  };

  const desabilitarCampoDesconto = useMemo(() => {
    return String(produtoId) === "2";
  }, [produtoId]);

  const [desconto, setDesconto] = useState();

  useEffect(() => {
    if (desabilitarCampoDesconto) setDesconto(new String());
  }, [desabilitarCampoDesconto]);

  const obterProduto = async () => {
    try {
      setCarregandoProduto(true);
      const lista = await listarProdutos();
      if (lista?.status === 200 && lista?.data?.produtos) {
        setProdutoLista(lista?.data.produtos ?? []);
        setProdutoId(lista?.data?.produtos.find((x) => x.sigla === "DG")?.id);
      }
      setCarregandoProduto(false);
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
      setCarregandoProduto(false);
    }
  };

  // Data boleta
  const [dataBoleta, setDataBoleta] = useState(null);

  // Empresa
  const [empresaId, setEmpresaId] = useState();
  const [listaEmpresa, setListaEmpresa] = useState([]);
  const [carregandoEmpresa, setCarregandoEmpresa] = useState(false);

  const obterEmpresa = async () => {
    try {
      setCarregandoEmpresa(true);
      const lista = await listarEmpresasGrupoDeltaCombo(usuario?.token);
      if (lista?.status === 200 && lista?.data.empresasGrupoDelta) {
        setListaEmpresa(lista?.data.empresasGrupoDelta ?? []);
      }
      setCarregandoEmpresa(false);
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
      setCarregandoEmpresa(false);
    }
  };

  useEffect(() => {
    if (!id) setDataBoleta(new Date());
    obterEmpresa();
    obterProduto();
    obterUnidadesMedida(empresaId);
    obterSubmercado();
    obterTipoEnergia();
    obterStatus();
  }, []);

  // Cliente
  const [clienteId, setClienteId] = useState();
  const [listaCliente, setListaCliente] = useState([]);
  const [carregandoCliente, setCarregandoCliente] = useState(false);

  const obterCliente = async (empresa) => {
    try {
      setCarregandoCliente(true);
      if (empresa) {
        const lista = await listarClientesCombo(empresa, usuario?.token);
        if (lista?.status === 200 && lista?.data?.clientes) {
          setListaCliente(lista?.data?.clientes);
        } else {
          setListaCliente([]);
        }
      } else {
        setListaCliente([]);
      }
      setCarregandoCliente(false);
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
      setCarregandoCliente(false);
    }
  };

  useEffect(() => {
    obterCliente(empresaId);
  }, [empresaId]);

  const [
    objetoBoletaUnidadeVinculadasSalvar,
    setObjetoBoletaUnidadeVinculadasSalvar
  ] = useState([]);

  const [
    objetoBoletaUnidadeVinculadasTabela,
    setObjetoBoletaUnidadeVinculadasTabela
  ] = useState([]);

  // 15.000,00 -> 150000.00
  // 0,750 -> 0.75
  function formatarValorSalvar(valor) {
    return valor
      ? parseFloat(
          String(valor)
            ?.replace(/[^0-9,.]/g, "")
            ?.replace(".", "")
            ?.replace(",", ".")
            ?.trim()
        )
      : valor;
  }

  function formatarValorTabela(valor) {
    return valor
      ? valor.toLocaleString("pt-BR", {
          style: "decimal",
          currency: "BRL",
          minimumFractionDigits: 2
        })
      : valor;
  }

  // Contrato
  const [contrato, setContrato] = useState();
  // const gerarContrato = () => {
  //   setContrato(
  //     `${moment().year()}_${id}${
  //       objetoBoletaUnidadeVinculadasTabela[0]
  //         ? `_${objetoBoletaUnidadeVinculadasTabela[0]?.unidadeConsumidoraId}`
  //         : ""
  //     }`
  //   );
  // };

  // useEffect(() => {
  //   if (id) gerarContrato();
  // }, [id, objetoBoletaUnidadeVinculadasTabela]);

  const [carregandoSalvar, setCarregandoSalvar] = useState(false);

  const enviarFormulario = async (dados) => {
    try {
      setCarregandoSalvar(true);

      if (!objetoBoletaUnidadeVinculadasSalvar?.length > 0) {
        store.dispatch(
          alertaExibir({
            tipo: "warning",
            mensagem: "É necessário vincular ao menos uma Unidade Consumidora!"
          })
        );
        return;
      }

      const model = {
        DataBoleta: moment(dataBoleta).format("yyyy-MM-DD"),
        CotacaoId: 0,
        UnidadeMedidaId: Number(unidadeMedidaId),
        Retusd: "",
        Status: "",
        StatusId: Number(statusId),
        ClientId: clienteId,
        TipoEnergiaId: Number(tipoEnergiaId),
        SubmercadoId: Number(submercadoId),
        EmpresaGrupoDeltaId: empresaId,
        ProdutoId: Number(produtoId),
        BoletaUnidadeVinculadas: objetoBoletaUnidadeVinculadasSalvar
      };

      const salvou = await salvarBoleta(id ?? 0, model);

      if (salvou?.status === 200 || salvou?.status === 204) {
        store.dispatch(
          alertaExibir({
            tipo: "success",
            mensagem: "Boleta salva com sucesso!"
          })
        );

        setTimeout(() => {
          history.push({
            pathname: RotasDTO.Boletas,
            state: { dadosBoleta: model }
          });
        }, 1000);
      }
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem: error?.response?.data?.message
        })
      );
    } finally {
      setCarregandoSalvar(false);
    }
  };

  const aoEnviarFormulario = (dados) => {
    enviarFormulario(dados);
  };

  const [
    listaUnidadesConsumidorasCombo,
    setListaUnidadesConsumidorasCombo
  ] = useState([]);

  const [listaFlexibilidadeSuperior, setListaFlexibilidadeSuperior] = useState(
    []
  );
  const [listaFlexibilidadeInferior, setListaFlexibilidadeInferior] = useState(
    []
  );

  const nomeUnidadeMedida = useMemo(() => {
    const unidade = unidadesMedidaLista.find(
      (item) => String(item.id) === String(unidadeMedidaId)
    );

    return unidade?.descricao ?? "";
  }, [unidadesMedidaLista, unidadeMedidaId]);

  const [listaTabelaSemFormatacao, setListaTabelaSemFormatacao] = useState();

  const formatarListaUnidadesVinculadas = useCallback(() => {
    if (!listaTabelaSemFormatacao) return;

    const listaObjetosUnidadeTabela = listaTabelaSemFormatacao.map(
      (unidade) => ({
        ...unidade
      })
    );

    listaObjetosUnidadeTabela.forEach((unidade) => {
      const nomeFantasiaUnidade = listaUnidadesConsumidorasCombo.find(
        (item) => String(item.id) === String(unidade.unidadeConsumidoraId)
      );

      const flexibilidadeSuperiorDescricao = listaFlexibilidadeSuperior.find(
        (item) => String(item.id) === String(unidade.flexibilidadeSuperiorId)
      );
      const flexibilidadeInferiorDescricao = listaFlexibilidadeInferior.find(
        (item) => String(item.id) === String(unidade.flexibilidadeInferiorId)
      );

      if (!unidade?.idLinhaTabela) unidade.idLinhaTabela = shortid.generate();

      unidade.id = unidade.id;

      unidade.unidadeConsumidoraNomeUnidade =
        nomeFantasiaUnidade?.nomeUnidade ?? unidade.unidadeConsumidoraId;

      unidade.inicioFornecimento = moment(unidade.inicioFornecimento).format(
        "DD/MM/YYYY"
      );

      unidade.fimFornecimento = moment(unidade.fimFornecimento).format(
        "DD/MM/YYYY"
      );

      unidade.montanteContratado = `${formatarValorTabela(
        unidade.montanteContratado
      )} ${nomeUnidadeMedida}`;

      unidade.precoContratadoDesconto = `${
        typeof unidade.precoContratadoDesconto === "number" &&
        unidade.precoContratadoDesconto > 0
          ? `${unidade.precoContratadoDesconto}%`
          : "-"
      }`;

      unidade.flexibilidadeSuperior = flexibilidadeSuperiorDescricao?.descricao
        ? `${flexibilidadeSuperiorDescricao?.descricao}`
        : `${unidade.flexibilidadeSuperior}%`;

      unidade.flexibilidadeInferior = flexibilidadeInferiorDescricao?.descricao
        ? `${flexibilidadeInferiorDescricao?.descricao}`
        : `${unidade.flexibilidadeInferior}%`;

      unidade.ativo = unidade.situacao === "AT";
    });

    setObjetoBoletaUnidadeVinculadasTabela(listaObjetosUnidadeTabela);
  }, [
    listaTabelaSemFormatacao,
    listaUnidadesConsumidorasCombo,
    nomeUnidadeMedida,
    listaFlexibilidadeSuperior,
    listaFlexibilidadeInferior
  ]);

  useEffect(() => {
    formatarListaUnidadesVinculadas();
  }, [formatarListaUnidadesVinculadas]);

  const [carregandoUnidades, setCarregandoUnidades] = useState(false);
  const [unidadeConsumidoraId, setUnidadeConsumidoraId] = useState(null);

  // Obter Boleta Por Id
  const obterBoleta = useCallback(async (boletaId) => {
    try {
      const boleta = await buscarPorId(boletaId);
      if (boleta?.status === 200 && boleta?.data) {
        if (!boleta.data) return;

        setUnidadeMedidaId(boleta?.data?.unidadeMedidaId);
        setProdutoId(boleta?.data?.produtoId);
        setStatusId(boleta?.data?.statusId);
        setDataBoleta(new Date(boleta?.data?.dataBoleta));
        setClienteId(boleta?.data?.clientId);
        setTipoEnergiaId(boleta?.data?.tipoEnergiaId);
        setSubmercadoId(boleta?.data?.submercadoId);
        setEmpresaId(boleta?.data?.empresaGrupoDeltaId);
        setContrato(boleta?.data?.contrato);

        setObjetoBoletaUnidadeVinculadasSalvar(
          boleta?.data?.boletaUnidadeVinculada.map((item) => {
            item.flexibilidadeInferior = item.flexibilidadeInferiorId;
            item.flexibilidadeSuperior = item.flexibilidadeSuperiorId;
            return item;
          })
        );
        setListaTabelaSemFormatacao(boleta?.data?.boletaUnidadeVinculada);
      }
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
    }
  }, []);

  useEffect(() => {
    if (id) obterBoleta(id);
    else setAdicaoUnidade(true);
  }, [id]);

  // Seleção automatica de empresa e cliente
  useEffect(() => {
    if (!id && usuario?.empresaSelecionada)
      setEmpresaId(Number(usuario?.empresaSelecionada));
    if (!id && usuario?.clienteSelecionado)
      setClienteId(Number(usuario?.clienteSelecionado));
  }, [usuario]);

  // Vincular Unidade Consumidora
  const [adicaoUnidade, setAdicaoUnidade] = useState(false);
  const [edicaoUnidade, setEdicaoUnidade] = useState(false);

  const onClickNovaUnidade = () => {
    setAdicaoUnidade(!adicaoUnidade);
  };

  const obterUnidadesConsumidoras = useCallback(async (empresa, cliente) => {
    try {
      setCarregandoUnidades(true);
      const lista = await listarUnidadesSemPaginacao(empresa, cliente);

      if (lista?.data?.unidadeConsumidoraResponseList)
        setListaUnidadesConsumidorasCombo(
          lista?.data?.unidadeConsumidoraResponseList
        );
    } catch (error) {
      console.info(error);
    } finally {
      setCarregandoUnidades(false);
    }
  }, []);

  useEffect(() => {
    if (empresaId && clienteId) obterUnidadesConsumidoras(empresaId, clienteId);
  }, [empresaId, clienteId]);

  const [dataInicioFornecimento, setDataInicioFornecimento] = useState(null);
  const [dataFimFornecimento, setDataFimFornecimento] = useState(null);

  const [volumeContratado, setVolumeContratado] = useState();

  const [flexibilidadeSuperior, setFlexibilidadeSuperior] = useState(4);

  const obterFlexibilidadeSuperior = useCallback(async () => {
    try {
      const lista = await listarFlexibilidadeSuperior();

      if (lista?.data?.flexibilidadeSuperior)
        setListaFlexibilidadeSuperior(lista?.data?.flexibilidadeSuperior);
    } catch (error) {
      console.info(error);
    }
  }, []);

  useEffect(() => {
    obterFlexibilidadeSuperior();
  }, [obterFlexibilidadeSuperior]);

  const [flexibilidadeInferior, setFlexibilidadeInferior] = useState(4);

  const obterFlexibilidadeInferior = useCallback(async () => {
    try {
      const lista = await listarFlexibilidadeInferior();

      if (lista?.data?.flexibilidadeInferior)
        setListaFlexibilidadeInferior(lista?.data?.flexibilidadeInferior);
    } catch (error) {
      console.info(error);
    }
  }, []);

  useEffect(() => {
    obterFlexibilidadeInferior();
  }, [obterFlexibilidadeInferior]);

  const [situacao, setSituacao] = useState(false);

  const existemErrosValidacao = () => {
    let errosEncontrados = false;

    if (!unidadeConsumidoraId) {
      setError("unidadeConsumidoraId", {
        type: "required",
        message: "Campo Unidade Consumidora é obrigatório!"
      });

      errosEncontrados = true;
    }

    if (!dataInicioFornecimento) {
      setError("dataInicioFornecimento", {
        type: "required",
        message: "Campo Início fornecimento é obrigatório!"
      });

      errosEncontrados = true;
    }

    if (
      dataInicioFornecimento &&
      (!(
        moment(dataInicioFornecimento, "DD/MM/YYYY") >
        moment("01/01/1900", "DD/MM/YYYY")
      ) ??
        !moment(dataInicioFornecimento, "DD/MM/YYYY").isValid())
    ) {
      setError("dataInicioFornecimento", {
        type: "manual",
        message: "Data do Início fornecimento inválida!"
      });

      errosEncontrados = true;
    }

    if (!dataFimFornecimento) {
      setError("dataFimFornecimento", {
        type: "required",
        message: "Campo Fim fornecimento é obrigatório!"
      });

      errosEncontrados = true;
    }

    if (
      dataFimFornecimento &&
      (!(
        moment(dataFimFornecimento, "DD/MM/YYYY") >
        moment("01/01/1900", "DD/MM/YYYY")
      ) ??
        !moment(dataFimFornecimento, "DD/MM/YYYY").isValid())
    ) {
      setError("dataFimFornecimento", {
        type: "manual",
        message: "Data do Fim fornecimento inválida!"
      });

      errosEncontrados = true;
    }

    if (moment(dataFimFornecimento).isBefore(moment(dataInicioFornecimento))) {
      setError("dataFimFornecimento", {
        type: "manual",
        message: "Data do Fim fornecimento inferior ao Início fornecimento!"
      });

      errosEncontrados = true;
    }

    if (!volumeContratado) {
      setError("volumeContratado", {
        type: "required",
        message: "Campo Volume contratado é obrigatório!"
      });

      errosEncontrados = true;
    }

    if (volumeContratado && volumeContratado < 0) {
      setError("volumeContratado", {
        type: "manual",
        message: "Volume contratado é inválido!"
      });

      errosEncontrados = true;
    }

    if (!desabilitarCampoDesconto && typeof desconto !== "number") {
      setError("desconto", {
        type: "required",
        message: "Campo Desconto é obrigatório!"
      });

      errosEncontrados = true;
    }

    if (!flexibilidadeSuperior) {
      setError("flexibilidadeSuperior", {
        type: "required",
        message: "Campo Flexibilidade superior é obrigatório!"
      });

      errosEncontrados = true;
    }

    if (!flexibilidadeInferior) {
      setError("flexibilidadeInferior", {
        type: "required",
        message: "Campo Flexibilidade inferior é obrigatório!"
      });

      errosEncontrados = true;
    }

    if (!errosEncontrados) clearErrors();

    return errosEncontrados;
  };

  const existemUnidadesNoMesmoPeriodo = (lista, unidade) => {
    if (!lista?.length) return false;

    const range = moment().range(
      moment(unidade?.inicioFornecimento),
      moment(unidade?.fimFornecimento)
    );

    const unidadesNoPeriodo = lista?.find((item) => {
      return (
        item?.unidadeConsumidoraId === unidade?.unidadeConsumidoraId &&
        (range.contains(moment(item?.inicioFornecimento)) ||
          range.contains(moment(item?.fimFornecimento)))
      );
    });

    return Boolean(unidadesNoPeriodo);
  };

  const resetarFormulario = () => {
    setUnidadeConsumidoraId(null);
    setDataInicioFornecimento(null);
    setDataFimFornecimento(null);
    setVolumeContratado();
    setDesconto();
    setFlexibilidadeSuperior(4);
    setFlexibilidadeInferior(4);
    setSituacao();
    setAdicaoUnidade(false);
    setEdicaoUnidade(false);
  };

  const onClickAdicionarUnidade = () => {
    if (!existemErrosValidacao()) {
      if (!unidadeMedidaId) {
        store.dispatch(
          alertaExibir({
            tipo: "warning",
            mensagem: "É necessário selecionar uma Unidade de medida!"
          })
        );
        setError("unidadesMedidaId", {
          type: "required",
          message: "Campo Unidade de medida é obrigatório!"
        });
        return;
      }

      const listaObjetosUnidadeSalvar = objetoBoletaUnidadeVinculadasSalvar.map(
        (unidade) => ({
          ...unidade
        })
      );
      const listaObjetosUnidadeTabela = objetoBoletaUnidadeVinculadasTabela.map(
        (unidade) => ({
          ...unidade
        })
      );

      const nomeFantasiaUnidade = listaUnidadesConsumidorasCombo.find(
        (item) => String(item.id) === String(unidadeConsumidoraId)
      );
      const flexibilidadeSuperiorDescricao = listaFlexibilidadeSuperior.find(
        (item) => String(item.id) === String(flexibilidadeSuperior)
      );
      const flexibilidadeInferiorDescricao = listaFlexibilidadeInferior.find(
        (item) => String(item.id) === String(flexibilidadeInferior)
      );

      if (!edicaoUnidade) {
        // É adição
        // Salvar

        const objetoUnidadeSalvar = {
          montanteContratado: volumeContratado,
          precoContratadoDesconto: typeof desconto === "number" ? desconto : 0,
          flexibilidadeSuperior: flexibilidadeSuperior,
          flexibilidadeInferior: flexibilidadeInferior,
          flexibilidadeSuperiorId: flexibilidadeSuperior,
          flexibilidadeInferiorId: flexibilidadeInferior,
          inicioFornecimento: moment(dataInicioFornecimento).format(
            "YYYY-MM-DD"
          ),
          fimFornecimento: moment(dataFimFornecimento).format("YYYY-MM-DD"),
          unidadeConsumidoraId: unidadeConsumidoraId,
          situacao: "AT"
        };

        // Tabela

        const objetoUnidadeTabela = {
          montanteContratado: `${formatarValorTabela(
            volumeContratado
          )} ${nomeUnidadeMedida}`,
          precoContratadoDesconto: `${
            typeof desconto === "number" ? `${desconto}%` : "-"
          }`,
          flexibilidadeSuperior:
            flexibilidadeSuperiorDescricao?.descricao ?? flexibilidadeSuperior,
          flexibilidadeInferior:
            flexibilidadeInferiorDescricao?.descricao ?? flexibilidadeInferior,
          inicioFornecimento: moment(dataInicioFornecimento).format(
            "DD/MM/YYYY"
          ),
          fimFornecimento: moment(dataFimFornecimento).format("DD/MM/YYYY"),
          unidadeConsumidoraId: unidadeConsumidoraId,
          unidadeConsumidoraNomeUnidade:
            nomeFantasiaUnidade?.nomeUnidade ?? unidadeConsumidoraId,
          ativo: true
        };

        if (!objetoUnidadeTabela?.idLinhaTabela)
          objetoUnidadeTabela.idLinhaTabela = shortid.generate();

        if (
          !existemUnidadesNoMesmoPeriodo(objetoBoletaUnidadeVinculadasSalvar, {
            unidadeConsumidoraId: unidadeConsumidoraId,
            inicioFornecimento: moment(dataInicioFornecimento).format(
              "YYYY-MM-DD"
            ),
            fimFornecimento: moment(dataFimFornecimento).format("YYYY-MM-DD")
          })
        ) {
          listaObjetosUnidadeSalvar.push(objetoUnidadeSalvar);
          listaObjetosUnidadeTabela.push(objetoUnidadeTabela);

          resetarFormulario();
        } else {
          store.dispatch(
            alertaExibir({
              tipo: "warning",
              mensagem:
                "Unidade Consumidora já vinculada à esta boleta neste período!"
            })
          );
        }
      } else {
        // É edição
        // Salvar

        listaObjetosUnidadeSalvar
          .filter(
            (item) =>
              item?.unidadeConsumidoraId ===
                edicaoUnidade?.unidadeConsumidoraId &&
              moment(item?.inicioFornecimento).format("YYYY-MM-DD") ===
                moment(edicaoUnidade.inicioFornecimento).format("YYYY-MM-DD") &&
              moment(item?.fimFornecimento).format("YYYY-MM-DD") ===
                moment(edicaoUnidade.fimFornecimento).format("YYYY-MM-DD")
          )
          .forEach((unidade) => {
            unidade.montanteContratado = volumeContratado;
            unidade.precoContratadoDesconto =
              typeof desconto === "number" ? desconto : 0;
            unidade.flexibilidadeSuperior = flexibilidadeSuperior;
            unidade.flexibilidadeInferior = flexibilidadeInferior;
            unidade.inicioFornecimento = moment(dataInicioFornecimento).format(
              "YYYY-MM-DD"
            );
            unidade.fimFornecimento = moment(dataFimFornecimento).format(
              "YYYY-MM-DD"
            );
            unidade.unidadeConsumidoraId = unidadeConsumidoraId;
            unidade.situacao = situacao;
          });

        // Tabela

        listaObjetosUnidadeTabela
          .filter(
            (item) =>
              item?.unidadeConsumidoraId ===
                edicaoUnidade?.unidadeConsumidoraId &&
              moment(item?.inicioFornecimento, "DD/MM/YYYY").format(
                "YYYY-MM-DD"
              ) ===
                moment(edicaoUnidade.inicioFornecimento).format("YYYY-MM-DD") &&
              moment(item?.fimFornecimento, "DD/MM/YYYY").format(
                "YYYY-MM-DD"
              ) === moment(edicaoUnidade.fimFornecimento).format("YYYY-MM-DD")
          )
          .forEach((unidade) => {
            if (!unidade?.idLinhaTabela)
              unidade.idLinhaTabela = shortid.generate();

            unidade.montanteContratado = `${formatarValorTabela(
              volumeContratado
            )} ${nomeUnidadeMedida}`;
            unidade.precoContratadoDesconto = `${
              typeof desconto === "number" ? `${desconto}%` : "-"
            }`;
            unidade.flexibilidadeSuperior =
              flexibilidadeSuperiorDescricao?.descricao ??
              flexibilidadeSuperior;
            unidade.flexibilidadeInferior =
              flexibilidadeInferiorDescricao?.descricao ??
              flexibilidadeInferior;
            unidade.inicioFornecimento = moment(dataInicioFornecimento).format(
              "DD/MM/YYYY"
            );
            unidade.fimFornecimento = moment(dataFimFornecimento).format(
              "DD/MM/YYYY"
            );
            unidade.unidadeConsumidoraId = unidadeConsumidoraId;
            unidade.unidadeConsumidoraNomeUnidade =
              nomeFantasiaUnidade?.nomeUnidade ?? unidadeConsumidoraId;
            unidade.ativo = situacao === "IN" ? false : true;
          });

        resetarFormulario();
      }

      setObjetoBoletaUnidadeVinculadasSalvar(listaObjetosUnidadeSalvar);
      setObjetoBoletaUnidadeVinculadasTabela(listaObjetosUnidadeTabela);
    }
  };

  const [modalExclusao, setModaoExclusao] = useState(false);

  const onClickExcluir = (item) => {
    setModaoExclusao(item);
  };

  const onClickMudarStatus = (params) => {
    try {
      const listaObjetosUnidadeSalvar = objetoBoletaUnidadeVinculadasSalvar.map(
        (unidade) => ({
          ...unidade
        })
      );
      const listaObjetosUnidadeTabela = objetoBoletaUnidadeVinculadasTabela.map(
        (unidade) => ({
          ...unidade
        })
      );

      listaObjetosUnidadeSalvar
        .filter(
          (item) =>
            item?.unidadeConsumidoraId === params?.unidadeConsumidoraId &&
            moment(item?.inicioFornecimento, "YYYY-DD-MM").format(
              "YYYY-MM-DD"
            ) === moment(params.inicioFornecimento).format("YYYY-MM-DD") &&
            moment(item?.fimFornecimento, "YYYY-DD-MM").format("YYYY-MM-DD") ===
              moment(params.fimFornecimento).format("YYYY-MM-DD")
        )
        .forEach((item) => {
          if (typeof params.situacao != "undefined")
            item.situacao = params.situacao === "AT" ? "IN" : "AT";
          else item.situacao = params.ativo ? "IN" : "AT";
        });

      listaObjetosUnidadeTabela
        .filter(
          (item) =>
            item?.unidadeConsumidoraId === params?.unidadeConsumidoraId &&
            moment(item?.inicioFornecimento, "MM/DD/YYYY").format(
              "YYYY-MM-DD"
            ) === moment(params.inicioFornecimento).format("YYYY-MM-DD") &&
            moment(item?.fimFornecimento, "MM/DD/YYYY").format("YYYY-MM-DD") ===
              moment(params.fimFornecimento).format("YYYY-MM-DD")
        )
        .forEach((item) => (item.ativo = params.ativo === true ? false : true));

      setObjetoBoletaUnidadeVinculadasTabela(listaObjetosUnidadeTabela);
      setObjetoBoletaUnidadeVinculadasSalvar(listaObjetosUnidadeSalvar);
    } catch (error) {
      console.info(error);
    } finally {
      setModaoExclusao(false);
    }
  };

  const onClickBotaoTabelaUnidade = (botao, params) => {
    switch (botao) {
      case enumeradorBotao.Edicao:
        const listaObjetosUnidadeSalvar = objetoBoletaUnidadeVinculadasSalvar.map(
          (unidade) => ({
            ...unidade
          })
        );

        const unidade = listaObjetosUnidadeSalvar.find(
          (item) =>
            item.unidadeConsumidoraId === params.unidadeConsumidoraId &&
            moment(item.inicioFornecimento).format("YYYY-MM-DD") ===
              moment(params.inicioFornecimento, "DD/MM/YYYY").format(
                "YYYY-MM-DD"
              ) &&
            moment(item.fimFornecimento).format("YYYY-MM-DD") ===
              moment(params.fimFornecimento, "DD/MM/YYYY").format("YYYY-MM-DD")
        );

        setUnidadeConsumidoraId(unidade?.unidadeConsumidoraId);
        setDataInicioFornecimento(moment(unidade?.inicioFornecimento).toDate());
        setDataFimFornecimento(moment(unidade?.fimFornecimento).toDate());
        setVolumeContratado(unidade?.montanteContratado);

        setDesconto(unidade?.precoContratadoDesconto);
        setFlexibilidadeSuperior(String(unidade?.flexibilidadeSuperiorId));
        setFlexibilidadeInferior(String(unidade?.flexibilidadeInferiorId));
        setSituacao(unidade?.situacao);

        setAdicaoUnidade(true);
        setEdicaoUnidade(unidade);
        break;
      case enumeradorBotao.Status:
        onClickExcluir(params);
        break;
    }
  };

  const colunas = [
    new Coluna("id", "", true, "3%", "auto"),
    new Coluna(
      "unidadeConsumidoraNomeUnidade",
      "Unidade consumidora",
      true,
      "13%",
      "auto"
    ),
    new Coluna(
      "inicioFornecimento",
      "Início fornecimento",
      true,
      "13%",
      "auto"
    ),
    new Coluna("fimFornecimento", "Fim fornecimento", true, "13%", "auto"),
    new Coluna("montanteContratado", "Volume contratado", true, "12%", "auto"),
    new Coluna("precoContratadoDesconto", "Desc.", true, "7%", "auto"),
    new Coluna(
      "flexibilidadeInferior",
      "Flexibilidade inferior",
      true,
      "12%",
      "auto"
    ),
    new Coluna(
      "flexibilidadeSuperior",
      "Flexibilidade superior",
      true,
      "12%",
      "auto"
    ),
    new ColunaComponentePersonalizado(
      "opcoes",
      "Editar",
      ColunaMultiplosBotoes,
      onClickBotaoTabelaUnidade,
      false,
      true,
      "16%",
      "auto",
      "text-center"
    )
  ];

  return (
    <Loader loading={carregandoSalvar}>
      <form
        className="needs-validation"
        onSubmit={handleSubmit(aoEnviarFormulario)}
      >
        <Card className={classes.cardCadastro}>
          <Grid container p={2} spacing={4} className={classes.container}>
            <ModalConfirmacao
              item={modalExclusao}
              onConfirmar={(item) => onClickMudarStatus(item)}
              mensagem={`Tem certeza que deseja ${
                modalExclusao?.ativo ? "inativar" : "ativar"
              } essa Unidade?`}
              onCancelar={() => setModaoExclusao(false)}
            />
            <Grid item lg={6} xs={6} className="font-weight-bold">
              {id ? "Editar" : "Nova"} boleta
            </Grid>
            <BotaoRetornarListagem urlListagem={RotasDTO.Boletas} zerarCombo />
            <Grid item lg={3} md={3} sm={6}>
              <Loader loading={carregandoEmpresa}>
                <SelectAutoComplete
                  disableOpenOnFocus
                  id="empresaId"
                  name="empresaId"
                  label="Empresa"
                  value={empresaId}
                  options={listaEmpresa}
                  getOptionLabel={(option) => option?.nomeFantasia ?? ""}
                  onChange={(e, item) => {
                    setEmpresaId(item?.id ?? 0);
                    store.dispatch(selecionarEmpresa(item?.id ?? 0));
                  }}
                  allowClear
                  ref={register(
                    { name: "empresaId" },
                    {
                      required: !empresaId
                    }
                  )}
                  error={errors}
                  valueName="nomeFantasia"
                />
              </Loader>
            </Grid>
            <Grid item lg={3} md={3} sm={6}>
              <Loader loading={carregandoCliente}>
                <SelectAutoComplete
                  disableClearable
                  disableOpenOnFocus
                  id="clienteId"
                  name="clienteId"
                  label="Cliente"
                  value={clienteId}
                  options={listaCliente.filter(cliente => cliente.nomeFantasia)}
                  getOptionLabel={(option) => option?.nomeFantasia ?? ""}
                  onChange={(e, item) => {
                    setClienteId(item?.id ?? 0);
                    store.dispatch(selecionarCliente(item?.id ?? 0));
                  }}
                  allowClear
                  ref={register(
                    { name: "clienteId" },
                    {
                      required: !clienteId
                        ? "Campo Cliente é obrigatório!"
                        : false
                    }
                  )}
                  error={errors}
                  valueName="nomeFantasia"
                />
              </Loader>
            </Grid>
            <Grid item lg={3} md={3} sm={6}>
              <InputData
                type="text"
                id="dataBoleta"
                name="dataBoleta"
                label="Data da boleta"
                customValue={dataBoleta}
                maxDate={new Date()}
                onChange={(data) => setDataBoleta(data)}
                renderIconShowHide={false}
                ref={register({
                  required: "Campo Data da boleta é obrigatório!",
                  validate: (data) =>
                    (moment(data, "DD/MM/YYYY").isValid() &&
                      moment(data, "DD/MM/YYYY") <=
                        moment(moment().startOf("day"), "DD/MM/YYYY")) ||
                    "Data da boleta inválida!"
                })}
                errors={errors}
                className={classes}
              />
            </Grid>
          </Grid>
          <Grid container p={2} spacing={4} className={classes.container}>
            <Grid item lg={3} md={3} sm={6}>
              <MaterialInputTexto
                type="text"
                disabled
                id="numeroContrato"
                name="numeroContrato"
                label="Contrato"
                placeholder="Contrato"
                defaultValue={contrato}
                errors={errors}
              />
            </Grid>
            <Grid item lg={3} md={3} sm={6}>
              <Loader loading={carregandoProduto}>
                <SelectArredondado
                  id="produtoId"
                  name="produtoId"
                  valueKey="id"
                  valueName="descricao"
                  dataSource={produtoLista}
                  label="Produto"
                  value={produtoId}
                  onChange={(valor) => {
                    onChangeProdutoId(valor);
                  }}
                  placeholder="Produto"
                  allowClear
                  ref={register(
                    { name: "produtoId" },
                    {
                      required: !produtoId
                        ? "Campo Produto é obrigatório!"
                        : false
                    }
                  )}
                  errors={errors}
                />
              </Loader>
            </Grid>
            <Grid item lg={3} md={3} sm={6}>
              <Loader loading={carregandoUnidadesMedida}>
                <SelectArredondado
                  id="unidadesMedidaId"
                  name="unidadesMedidaId"
                  valueKey="id"
                  valueName="descricao"
                  dataSource={unidadesMedidaLista}
                  label="Unidade de medida"
                  value={unidadeMedidaId}
                  onChange={(unidadeMedida) => {
                    setUnidadeMedidaId(unidadeMedida.target.value);
                  }}
                  placeholder="Unidade de medida"
                  allowClear
                  ref={register(
                    { name: "unidadesMedidaId" },
                    {
                      required: !unidadeMedidaId
                        ? "Campo Unidade de medida é obrigatório!"
                        : false
                    }
                  )}
                  readOnly={
                    objetoBoletaUnidadeVinculadasTabela?.length
                      ? "readonly"
                      : false
                  }
                  errors={errors}
                />
              </Loader>
            </Grid>
          </Grid>
          <Grid container p={2} spacing={4} className={classes.container}>
            <Grid item lg={3} md={3} sm={6}>
              <Loader loading={carregandoSubmercado}>
                <SelectArredondado
                  id="submercadoId"
                  name="submercadoId"
                  valueKey="id"
                  valueName="descricao"
                  dataSource={submercadoLista}
                  label="Submercado"
                  value={submercadoId}
                  onChange={(submercado) => {
                    setSubmercadoId(submercado.target.value);
                  }}
                  placeholder="Submercado"
                  allowClear
                  ref={register(
                    { name: "submercadoId" },
                    {
                      required: !submercadoId
                        ? "Campo Submercado é obrigatório!"
                        : false
                    }
                  )}
                  errors={errors}
                />
              </Loader>
            </Grid>
            <Grid item lg={3} md={3} sm={6}>
              <Loader loading={carregandoTipoEnergia}>
                <SelectArredondado
                  id="tipoEnergiaId"
                  name="tipoEnergiaId"
                  valueKey="id"
                  valueName="descricao"
                  dataSource={tipoEnergiaLista}
                  label="Tipo de energia"
                  value={tipoEnergiaId}
                  onChange={(tipoEnergia) => {
                    setTipoEnergiaId(tipoEnergia.target.value);
                  }}
                  placeholder="Tipo de energia"
                  allowClear
                  ref={register(
                    { name: "tipoEnergiaId" },
                    {
                      required: !tipoEnergiaId
                        ? "Campo Tipo de energia é obrigatório!"
                        : false
                    }
                  )}
                  errors={errors}
                />
              </Loader>
            </Grid>
            <Grid item lg={3} md={3} sm={6}>
              <Loader loading={carregandoStatus}>
                <SelectArredondado
                  id="statusId"
                  name="statusId"
                  valueKey="id"
                  valueName="descricao"
                  dataSource={statusLista}
                  label="Status"
                  value={statusId}
                  onChange={(status) => {
                    setStatusId(status.target.value);
                  }}
                  placeholder="Status"
                  allowClear
                  ref={register(
                    { name: "statusId" },
                    {
                      required: !statusId
                        ? "Campo Status é obrigatório!"
                        : false
                    }
                  )}
                  errors={errors}
                />
              </Loader>
            </Grid>
          </Grid>
        </Card>
        {adicaoUnidade ? (
          <Card className={`my-4 ${classes.cardCadastro}`}>
            <Grid container p={2} spacing={4} className={classes.container}>
              <Grid item lg={6} xs={6} className="font-weight-bold">
                {edicaoUnidade ? "Editar" : "Vincular"} Unidade Consumidora
              </Grid>
              <Grid item xs={6} className={classes.item}>
                <CloseIcon
                  className={classes.icon}
                  onClick={() => {
                    onClickNovaUnidade();
                    resetarFormulario();
                  }}
                />
              </Grid>
              <Grid item lg={3} md={3} sm={6}>
                <Loader loading={carregandoUnidades}>
                  <SelectAutoComplete
                    disableClearable
                    disableOpenOnFocus
                    id="unidadeConsumidoraId"
                    name="unidadeConsumidoraId"
                    label="Unidade Consumidora"
                    value={unidadeConsumidoraId}
                    options={listaUnidadesConsumidorasCombo}
                    getOptionLabel={(option) =>
                      option?.nomeUnidade ?? option?.razaoSocial
                    }
                    onChange={(e, item) => {
                      console.log(item)
                      setUnidadeConsumidoraId(item?.id ?? 0);
                    }}
                    allowClear
                    disabled={!clienteId}
                    ref={register}
                    error={errors}
                    valueName="nomeUnidade"
                  />
                </Loader>
              </Grid>
              <Grid item lg={3} md={3} sm={6}>
                <InputData
                  type="text"
                  id="dataInicioFornecimento"
                  name="dataInicioFornecimento"
                  customValue={dataInicioFornecimento}
                  onChange={(valor) => setDataInicioFornecimento(valor)}
                  label="Início fornecimento"
                  renderIconShowHide={false}
                  ref={register}
                  errors={errors}
                  className={classes}
                  InputProps={{ readOnly: false }}
                  variant={"inline"}
                  autoOk={true}
                />
              </Grid>
              <Grid item lg={3} md={3} sm={6}>
                <InputData
                  type="text"
                  id="dataFimFornecimento"
                  name="dataFimFornecimento"
                  minDate={dataInicioFornecimento}
                  customValue={dataFimFornecimento}
                  onChange={(valor) => setDataFimFornecimento(valor)}
                  label="Fim fornecimento"
                  renderIconShowHide={false}
                  ref={register}
                  errors={errors}
                  className={classes}
                  InputProps={{ readOnly: false }}
                  variant={"inline"}
                  autoOk={true}
                />
              </Grid>
              <Grid item lg={3} md={3} sm={6}>
                <MaterialInputMascara
                  type="text"
                  id="volumeContratado"
                  name="volumeContratado"
                  sufixo={` ${nomeUnidadeMedida}`}
                  label="Volume contratado"
                  {...(edicaoUnidade && {
                    defaultValue: volumeContratado
                  })}
                  renderIconShowHide={false}
                  onBlur={(e) =>
                    setVolumeContratado(formatarValorSalvar(e.target.value))
                  }
                  ref={register}
                  errors={errors}
                  disabled={!nomeUnidadeMedida}
                />
              </Grid>
              <Grid item lg={3} md={3} sm={6}>
                <MaterialInputMascara
                  type="text"
                  id="desconto"
                  name="desconto"
                  sufixo="%"
                  label="Desconto (%)"
                  defaultValue={desconto}
                  renderIconShowHide={false}
                  onBlur={(e) =>
                    setDesconto(formatarValorSalvar(e.target.value))
                  }
                  ref={register}
                  errors={errors}
                  disabled={desabilitarCampoDesconto}
                />
              </Grid>
              <Grid item lg={3} md={3} sm={6}>
                <SelectArredondado
                  id="flexibilidadeInferior"
                  name="flexibilidadeInferior"
                  valueKey="id"
                  valueName="descricao"
                  dataSource={listaFlexibilidadeInferior}
                  label="Flexibilidade inferior"
                  value={flexibilidadeInferior}
                  onChange={(valor) => {
                    setFlexibilidadeInferior(valor.target.value);
                  }}
                  placeholder="Flexibilidade inferior"
                  allowClear
                  ref={register}
                  errors={errors}
                />
              </Grid>
              <Grid item lg={3} md={3} sm={6}>
                <SelectArredondado
                  id="flexibilidadeSuperior"
                  name="flexibilidadeSuperior"
                  valueKey="id"
                  valueName="descricao"
                  dataSource={listaFlexibilidadeSuperior}
                  label="Flexibilidade superior"
                  value={flexibilidadeSuperior}
                  onChange={(valor) => {
                    setFlexibilidadeSuperior(valor.target.value);
                  }}
                  placeholder="Flexibilidade superior"
                  allowClear
                  ref={register}
                  errors={errors}
                />
              </Grid>
              <Grid item lg={3} md={3} sm={5}>
                {edicaoUnidade ? (
                  <MaterialSwitch
                    label="Ativo"
                    labelPlacement="top"
                    id="situacao"
                    name="situacao"
                    justify="flex-start"
                    marginTop="mt-n2"
                    checked={situacao === "AT"}
                    onChange={(valor) => setSituacao(!valor ? "IN" : "AT")}
                  />
                ) : null}
              </Grid>
              <Grid item lg={2} md={2} sm={6} className="pt-4">
                <Botao
                  type="button"
                  label={!edicaoUnidade ? "Adicionar" : "Atualizar"}
                  onClick={onClickAdicionarUnidade}
                  className={classes.button}
                />
              </Grid>
            </Grid>
          </Card>
        ) : (
          <Grid container spacing={0} className={`my-4 ${classes.container}`}>
            <Grid item lg={12} xs={6}>
              <BotaoCadastro
                label="Adicionar Unidade Consumidora"
                color={Base.White}
                onClick={onClickNovaUnidade}
              />
            </Grid>
          </Grid>
        )}
        <Grid item xs={12} className="mt-0">
          <DataTable
            columns={colunas}
            noHeader
            persistTableHead
            keyField="idLinhaTabela"
            data={objetoBoletaUnidadeVinculadasTabela}
            theme="DeltaEnergia"
            customStyles={customStyles}
            noDataComponent={
              <TabelaSemDados>Nenhuma unidade vinculada</TabelaSemDados>
            }
          />
        </Grid>
        <Grid container spacing={0} className={`my-4 ${classes.container}`}>
          <Grid
            item
            lg={3}
            md={3}
            sm={6}
            className={`mt-4 ml-0 ${classes.containerSalvar}`}
          >
            <Botao type="submit" label="Salvar" className={classes.button} />
          </Grid>
        </Grid>
      </form>
    </Loader>
  );
};

export default BoletaCadastro;
