import React from "react";
import PropTypes from "prop-types";

// Styles
import { Button } from "./style";
import { Base } from "../cores";
import theme from "themes";

const BotaoOrdenador = React.forwardRef((props, ref) => {
  const {
    disabled,
    id,
    icon,
    label,
    color,
    type,
    onClick,
    style,
    className,
    value,
    stateless,
    selected
  } = props;

  const classNameDesc = `btn btn-lg btn-primary rounded-pill w-100 position-relative d-flex justify-content-center align-items-center ${className}`;

  return (
    <Button
      type={type}
      className={classNameDesc}
      id={id}
      disabled={disabled}
      ref={ref}
      color={color}
      stateless={stateless}
      selected={selected}
      onClick={onClick}
      style={{ ...style }}
      value={value}
    >
      {label}
      {icon && icon}
    </Button>
  );
});

BotaoOrdenador.propTypes = {
  disabled: PropTypes.bool,
  id: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.any]),
  label: PropTypes.string,
  color: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.oneOfType([PropTypes.func]),
  style: PropTypes.oneOfType([PropTypes.any]),
  className: PropTypes.string,
  value: PropTypes.string,
  stateless: PropTypes.bool,
  selected: PropTypes.bool
};

BotaoOrdenador.defaultProps = {
  disabled: false,
  id: "",
  icon: false,
  label: "",
  color: theme.color.btSelBGColor,
  type: "button",
  onClick: () => {},
  style: {},
  className: "",
  value: "",
  stateless: true,
  selected: false
};

export default BotaoOrdenador;
