import React, { useEffect, useMemo, useState } from "react";
import { Grid } from "@mui/material";
import EsqueletoEtapa from "../../../../Componentes/EsqueletoEtapa";
import { useStyles } from "./style";
import { store } from "global/redux";
import { SetModoEnvioAssinatura } from "global/redux/modulos/workflow/actions";
import { useSelector } from "react-redux";
import { ProtocolosEtapas, tipoEnvioContrato } from "global/constantes";

const CartaDenuncia = ({
  titulo,
  prazo,
  responsavel,
  protocoloDemanda
}) => {
  const classes = useStyles();

  const onClickProximo = () => {

  };

  const onClickVoltar = () => {
    
  };

  return (
      <Grid className={classes.containerContent}>
        <h5>
          <p>Enviamos suas carta denúncia para a distribuidora, estamos aguardando o retorno do documento.</p>
        </h5>
      </Grid>
  );
};

export default CartaDenuncia;
