import styled from "styled-components";
import { makeStyles } from '@mui/styles';
import { Base } from "../../../cores";
import theme from 'themes';
import { createTheme } from "@mui/material/styles";

const themes = createTheme();

export const useStyles = makeStyles(() => ({
  wrapper: {
    position: "relative",
    marginTop: 8
  },
  avatar: {
    backgroundColor: `${Base.OffWhite} !important`,
    color: `${theme.color.primary} !important`,
    height: `${themes.spacing(4.5)} !important`,
    width: `${themes.spacing(4.5)} !important`
  },
  anchorMenu: {
    bottom: "-32px",
    color: theme.color.anchorMenuColor,
    fontSize: "26px",
    position: "absolute",
    right: "18px",
    transform: "rotate(-90deg)"
  },
  menu: {
    backgroundColor: theme.color.menuAvatarColor,
    paddingBottom: 0,
    "&:hover": {
      color: `${theme.color.menuItemAvatarFontHover} !important`,
      textDecoration: "none !important"
    }
  },
  menuHeader: {
    backgroundColor: Base.OffWhite
  },
  menuItem: {
    backgroundColor: `${theme.color.menuItemAvatarBackground} !important`,
    color: `${theme.color.menuItemAvatarFont} !important`,
    fontFamily: theme.font.family,
    fontSize: "14px",
    width: "227px",
    display: "block !important",
    fontFamily: `${theme.font.family}  !important`,
    fontSize: "14px",
    textDecoration: "none !important",
    width: "100%",
    "&:hover": {
      color: `${theme.color.menuItemAvatarFontHover} !important`,
      textDecoration: "none !important"
    }
  },
  menuIcon: {
    marginRight: "10px"
  },
  menuLink: {
    textDecoration: "none !important",
    color: `${theme.color.menuItemAvatarFont} !important`,
    "&:hover": {
      color: `${theme.color.menuItemAvatarFontHover} !important`
    }
  },
  menuFooter: {
    backgroundColor: `${theme.color.menuAvatarFooterBackground} !important`,
    color: `${theme.color.footerColor} !important`,
    fontFamily: theme.font.family,
    fontSize: "9px !important",
    lineHeight: "10px !important",
    minHeight: "32px !important",
    "&:hover": {
      backgroundColor: `${theme.color.menuAvatarFooterBackground} !important`
    }
  },
  menuHeader: {
    lineHeight: "1px",
    minHeight: "1px"
  },
  footerLink: {
    color: theme.color.footerColor,
    "&:hover": {
      color: `${theme.color.footerColor} !important`
    },
  },
  badgeSize:{
    width: 16,
    color: theme.color.anchorMenuColor,
  }
}));

export const Wrapper = styled.div``;
