import styled from "styled-components";
import { makeStyles } from '@mui/styles';
import { Base } from "../cores";
import theme from 'themes';

export const useStyles = makeStyles({
  label: {
    alignItems: "center",
    backgroundColor: theme.color.bgItemExpansivel,
    borderLeftColor: (props) => (props.ativo ? props.color : Base.GreyDim),
    borderLeftWidth: "6px",
    borderLeftStyle: "solid",
    color: `${theme.color.secondaryText} !important`,
    display: "flex",
    fontSize: "18px",
    height: "54px",
    marginRight: "2px",
    opacity: (props) => (props.ativo ? 1 : 0.5),
    paddingLeft: "20px"
  },
  squareWidth: {
    height: "54px",
    maxWidth: "54px",
    width: "100%",
    "&:not(:last-child)": {
      marginRight: "2px"
    }
  },
  gridItem: {
    flexBasis: "100%",
    fontSize: "2.1875rem",
    margin: 0,
    maxWidth: "54px",
    width: "100%"
  },
  treeItem: {
    backgroundColor: theme.color.bgItemExpansivel,
    color: `${theme.color.secondaryText} !important`,
    height: "54px"
  },
  wrapper: {
    alignItems: "center",
    display: "flex",
    height: "100%",
    minHeight: "100%"
  },
  center: {
    justifyContent: "center"
  }
});

export const Label = styled.div``;
