import { makeStyles } from '@mui/styles';
import theme from "themes";
import { Base } from "../../../../componentes/cores";

export const useStyles = makeStyles({
  container: {
    margin: "0 0 24px",
    width: "100%"
  },
  pagination: {
    textAlign: "center"
  },
  select: {
    marginTop: "10px",
    "& label.Mui-focused": {
      color: `${theme.color.secondaryBorderColor} !important`
    },
    "& .MuiInput-underline:after": {
      borderColor: `${theme.color.secondaryBorderColor} !important`
    },
    "& .MuiFormLabel-root": {
      color: `${theme.color.secondaryBorderColor} !important`
    },
    "& .MuiOutlinedInput-root": {
      "& .MuiOutlinedInput-input": {
        color: `${theme.color.secondaryBorderColor} !important`
      },
      "& .MuiInputAdornment-root": {
        color: `${theme.color.secondaryBorderColor} !important`
      },
      "& fieldset": {
        borderColor: `${theme.color.secondaryBorderColor} !important`
      },
      "&:hover fieldset": {
        borderColor: `${theme.color.secondaryBorderColor} !important`
      },
      "&.Mui-focused fieldset": {
        borderColor: `${theme.color.secondaryBorderColor} !important`
      }
    }
  },
  search: {
    "& label.Mui-focused": {
      color: `${theme.color.secondaryBorderColor} !important`
    },
    "& .MuiInput-underline:after": {
      borderColor: `${theme.color.secondaryBorderColor} !important`
    },
    "& .MuiFormLabel-root": {
      color: `${theme.color.secondaryBorderColor} !important`
    },
    "& .MuiOutlinedInput-root": {
      "& .MuiOutlinedInput-input": {
        color: `${theme.color.secondaryBorderColor} !important`
      },
      "& .MuiInputAdornment-root": {
        color: `${theme.color.secondaryBorderColor} !important`
      },
      "& fieldset": {
        borderColor: `${theme.color.secondaryBorderColor} !important`
      },
      "&:hover fieldset": {
        borderColor: `${theme.color.secondaryBorderColor} !important`
      },
      "&.Mui-focused fieldset": {
        borderColor: `${theme.color.secondaryBorderColor} !important`
      }
    }
  },
  itemFiltro: {
    display: "flex",
    justifyContent: "flex-end"
  },
  buttonFiltro: {
    fontSize: "18px",
    maxWidth: "220px"
  },
  botao: {
    color: `${theme.color.botaoDownloadFatura} !important`,
    fontSize: "32px"
  },
  btnDownload: {
    backgroundColor: `${Base.BlueTuna} !important`,
    color: `${Base.WhiteGhost} !important`,
    fontSize: 16,
    "&:hover": {
      backgroundColor: `${Base.BlackRussian} !important`
    }
  },
  gridPaddingTopo: {
    marginBottom: 20,
    padding: 10,
    backgroundColor: Base.GrayMedidores,
    "& div": {
      marginBottom: 5,
      color: Base.BlackRussian
    },
    "& b": {
      marginRight: 15,
      color: Base.BlackRussian
    }
  },
  gridPadding: {
    "& div": {
      marginBottom: 5,
      color: Base.GrayMedidores
    },
    "& b": {
      marginRight: 7,
      color: Base.BlackRussian
    }
  },
  dataConsulta: {
    width: "100%",
    textAlign: "center",
    marginBottom: 5,
    color: Base.GrayMedidores,
    "& b": {
      fontWeight: "bold",
      marginRight: 10,
      color: Base.BlackRussian
    }
  },
  btnAcoes: {
    textAlign: "right",
    marginTop: 30
  },
  tabelaPaginada: {
    "& ::-webkit-scrollbar:horizontal": {
      height: "8px"
    }
  }
});
