import React from "react";
import PropTypes from "prop-types";

// Icones
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

// Componentes
import Grid from "@mui/material/Grid";
import { Checkbox } from "@mui/material";
import CustomizedTooltipForArvoreGerencialTreeview from "../../tooltip";

// Styles
import { useStyles, Item } from "./style";
import TreeViewParentIcon from "../treeViewParentIcon";

// Custom Componentes
import MaterialSwitch from "../../switch";

const TreeViewItem = ({
  level,
  childs,
  label,
  editable,
  onEditar,
  deletable,
  onDeletar,
  selectable,
  onSelecionar,
  selected,
  node,
  selecionados
}) => {
  const classes = useStyles({
    level,
    childs,
    ativo: node?.situacao === "AT"
  });

  const onClickEditar = (item) => {
    onEditar(item);
  };

  const onClickDeletar = () => {
    onDeletar(node);
  };

  const onClickSelecionar = (item) => {
    onSelecionar(item);
  };

  const empresaMarcada = selecionados.find(
    (empresa) => empresa.idEmpresa === node.id
  );

  return (
    <Item>
      <Grid container classes={{ root: classes.container }}>        
        {level >= 1 && childs ? <TreeViewParentIcon force /> : null}
        <Grid item xs className={`${classes.gridItem} ${classes.labelItem}`}>
          <div
            className={`${classes.treeItem} ${classes.wrapper} ${classes.spaceBetween}`}
          >
            {label}
            {level === 0 ? (
              <CustomizedTooltipForArvoreGerencialTreeview
                raizArvore={node}
                nestedComponents={[
                  <InfoOutlinedIcon className={classes.tooltipIcon} />
                ]}
              />
            ) : null}
          </div>
        </Grid>
        {editable ? (
          <Grid
            item
            xs={1}
            className={`${classes.squareWidth} ${classes.gridItem}`}
          >
            <div
              className={`${classes.treeItem} ${classes.wrapper} ${classes.center}`}
              onKeyDown={() => onClickEditar(node)}
              onClick={() => onClickEditar(node)}
              role="button"
              tabIndex={0}
            >
              <EditOutlinedIcon />
            </div>
          </Grid>
        ) : null}
        {deletable ? (
          <Grid
            item
            xs={1}
            className={`${classes.squareWidth} ${classes.gridItem}`}
          >
            <div
              className={`${classes.treeItem} ${classes.wrapper} ${classes.center} ${classes.enabledSwitch}`}
              onKeyDown={() => onClickDeletar(node)}
              onClick={() => onClickDeletar(node)}
              role="button"
              tabIndex={0}
            >
              <MaterialSwitch
                checked={node?.situacao === "AT"}
              />
            </div>
          </Grid>
        ) : null}
        {level >= 1 && selectable ? (
          <Grid
            item
            xs={1}
            className={`${classes.squareWidth} ${classes.gridItem}`}
          >
            <div
              className={`${classes.treeItem} ${classes.wrapper} ${classes.center}`}
              role="button"
            >
              <Checkbox
                checked={selected}
                className={classes.checkbox}
                onClick={() => onClickSelecionar(node)}
              />
            </div>
          </Grid>
        ) : null}
        {level === 0 && empresaMarcada != null ? (
          <Grid
            item
            xs={1}
            className={`${classes.squareWidth} ${classes.gridItem}`}
          >
            <div
              className={`${classes.treeItem} ${classes.wrapper} ${classes.center}`}
              role="button"
            >
              <Grid className={classes.cardSelecionado} />
            </div>
          </Grid>
        ) : null}
      </Grid>
    </Item>
  );
};

TreeViewItem.propTypes = {
  level: PropTypes.number.isRequired,
  childs: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]).isRequired,
  label: PropTypes.string.isRequired,
  editable: PropTypes.bool,
  onEditar: PropTypes.oneOfType([PropTypes.func]),
  deletable: PropTypes.bool,
  onDeletar: PropTypes.oneOfType([PropTypes.func]),
  selectable: PropTypes.bool,
  onSelecionar: PropTypes.oneOfType([PropTypes.func]),
  selected: PropTypes.bool,
  node: PropTypes.oneOfType([PropTypes.object]).isRequired,
  selecionados: PropTypes.oneOfType([PropTypes.array]),
};

TreeViewItem.defaultProps = {
  editable: false,
  onEditar: () => { },
  deletable: false,
  onDeletar: () => { },
  selectable: false,
  onSelecionar: () => { },
  selected: false,
  selecionados: [],
};

export default TreeViewItem;
