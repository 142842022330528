import { makeStyles } from '@mui/styles';
import { Base } from "../cores";
import { createTheme } from "@mui/material/styles";

const theme = createTheme();

export const useStyles = makeStyles(() => ({
  container: {
    backgroundColor: Base.OffWhite,
    border: "0 none",
    borderRadius: "4px",
    boxShadow: theme.shadows[5],
    height: "150px",
    left: "50%",
    outline: "none",
    position: "absolute",
    padding: theme.spacing(4),
    transform: "translate(-50%, -50%)",
    top: "50%",
    width: "640px !important",
    [theme.breakpoints.down("sm")]: {
      width: "375px !important" 
    }
  },
  button: {
    color: `${Base.OffWhite} !important`,
    fontSize: "14px"
  }
}));
