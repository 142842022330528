import { makeStyles } from '@mui/styles';
import theme from 'themes';

export const useStyles = makeStyles({
  squareWidth: {
    height: "100%",
    width: "100%"
  },
  treeViewItemExpand: {
    color: theme.color.treeItemArrowColor,
    justifyContent: "end",
    padding: 0,
    position: "relative"
  },
  treeViewBorder: {
    borderLeftColor: theme.color.treeItemArrowColor,
    borderLeftWidth: "6px",
    borderLeftStyle: "solid"
  },
  gridItem: {
    flexBasis: "100%",
    fontSize: "2.1875rem",
    margin: 0,
    maxWidth: "100%",
    "pointer-events": "all",
    width: "100%"
  },
  treeItem: {
    backgroundColor: theme.color.bgItemExpansivel,
    color: theme.color.treeItemArrowColor,
    height: "54px",
    opacity: (props) => (props.ativo ? 1 : 0.5)
  },
  wrapper: {
    alignItems: "center",
    display: "flex",
    height: "100%",
    minHeight: "100%"
  },
  start: {
    alignItems: "flex-start"
  },
  center: {
    justifyContent: "center"
  },
  left: {
    justifyContent: "start",
    paddingLeft: "20px"
  },
  right: {
    justifyContent: "flex-end"
  }
});
