
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((themes) => ({
 dataLuz: {
    backgroundColor:" #F4F4F4",
    width: "24px",
    height: "24px",
    cursor: "pointer",
    fontFamily: "Plau",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "18px",
    color: "#FA3EB1",
    border: 0,
    outline:0,
    
 },
  toolTip: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#332053",
      borderRadius: "8px",
      padding: "8px",
  },
  toolTipText: {
    fontFamily: "Plau",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "14px",
    color: "#FFFFFF",
  },
  dot: {
    width: "13.08px",
    height: "12.93px",  
    background: "linear-gradient(90deg, #F722B5 0%, #FD9507 100%)",
    borderRadius: "50%",
    display: "inline-block",
    marginRight: "5px",
  },

}));

