import styled from 'styled-components';
import { Base } from 'componentes/cores';
import theme from 'themes';

export const DashboardView = styled.section`
    color: ${Base.OffWhite};
`;

export const CategoriesContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-content: stretch;
    margin-bottom: 20px;
`;

export const CategorieCard = styled.div`
    background-color: ${Base.BlackBastille};
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(50% - 10px);
    padding-top: calc(20% - 10px);
    padding-bottom: calc(20% - 10px);
    background-image: url(${props => props.bg});
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    cursor: pointer;
`;

export const CategorieTitle = styled.div`
    font-family: CenturyGothic;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.76px;
    text-align: center;
    padding: 8px;

    @media (min-width: ${theme.size.md}) {
        width: 320px;
        display: flex;
        font-size: 34px;
    }
`;

export const CategorieTitleText = styled.div`
@media (min-width: ${theme.size.md}) {
    display: inline-block;
    margin-left: 20px;
}
`;

export const InfoContainer = styled.div`
    @media (min-width: ${theme.size.md}) {display: flex;
    justify-content: space-between;
    align-content: stretch;}
`;

export const InfoTitle = styled.div`
    font-family: CenturyGothic;
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 30px;
    letter-spacing: -0.53px;
    margin-bottom: 35px;

    .MuiSvgIcon-root {
        font-size: 30px;
        margin-right: 20px;
    }
`;

export const InfoText = styled.div`
    font-family: CenturyGothic;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.31px;
    text-align: justify;
`;

export const InfoCard = styled.div`
    padding: 30px;
    background-color: ${Base.BlackBastille};
    

    &.gestor ${InfoText} {
        font-size: 16px;
    }

    & + div {
        margin-top: 24px;
    }

    @media (min-width: ${theme.size.md}) {
        width: calc(33% - 10px);
        & + div {
            margin-top: 0;
        }
    }

`;

export const SocialList = styled.div`
    display: flex;
    justify-content: center;
    padding-top: 10px;
`;

export const SocialLink = styled.a`
    margin: 10px;
    color: ${Base.OffWhite};

    &:hover {
        color: ${Base.OffWhite};
    }
`;