import { makeStyles } from "@mui/styles";
import { createTheme } from "@mui/material/styles";
import theme from "../../../../../themes";

const themes = createTheme();

export const useStyles = makeStyles({
  container: {
    margin: "0",
    width: "100%",
    "& .MuiInputBase-root": {
      height: "46px"
    },
    "& .MuiFormLabel-root": {
      lineHeight: "1"
    },
    "& .MuiGrid-spacing-xs-4 > .MuiGrid-item": {
      padding: "12px"
    },
    "& .MuiTypography-body1": {
      width: "100%"
    },
    "& .MuiFormGroup-root label .Mui-checked span div svg:nth-child(1)": {
      fill: `${theme.color.radioButtonDefault} !important`
    },
    "& .MuiFormGroup-root label .Mui-checked span div svg:nth-child(2)": {
      fill: `${theme.color.radioButtonDefault} !important`
    }
  },
  cardCadastro: {
    backgroundColor: theme.color.bgColorCardCadastro,
    padding: "30px",
    [themes.breakpoints.down("sm")]: {
      padding: "15px"
    }
  },
  desabledForm: {
    pointerEvents: "none"
  },
  tituloModal: {
    backgroundcolor: "primary",
    backgroundImage: "linear-gradient(90deg,#F722B5 0%,#FD9507 100%)",
    backgroundSize: "100%",
    backgroundRepeat: "repeat",
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent"
  }
});
