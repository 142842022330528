import { makeStyles } from '@mui/styles';
import { Base } from "componentes/cores";
import themes from 'themes';
import { createTheme } from "@mui/material/styles";

const theme = createTheme();

export const useStyles = makeStyles(() => ({
  container: {
    backgroundColor: Base.OffWhite,
    border: "0 none",
    borderRadius: "4px",
    boxShadow: theme.shadows[5],
    left: "50%",
    outline: "none",
    position: "absolute",
    padding: theme.spacing(4),
    transform: "translate(-50%, -50%)",
    top: "50%",
    width: "70% !important",
    color: themes.color.thirdText
  },
  button: {
    "&:hover": {
      textDecoration: "none !important"
    },
    display: "flex !important",
    flexDirection: "row-reverse !important",
    backgroundColor: `${Base.Transparent} !important`,
    border: "none",
    fontSize: "16px !important",
    color: themes.color.button,
    paddingTop: "20%"
  },
  icon: {
    position: 'absolute',
    top: 10,
    right: 20,
    cursor: "pointer",
    display: "flex",
    flexDirection: "row-reverse"
  },
  labelTitulo: {
    fontFamily: themes.font.family,
    fontSize: "16px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "-0.4px"
  } 
}));
