import { makeStyles } from "@mui/styles";
import { Base } from "../../../../../componentes/cores";
import theme from "themes";

export const useStyles = makeStyles({
  inputDescontoDisabled: {
    "& input": {
      color: `${Base.White} !important`
    },
    "& input:disabled": {
      color: "#CCCCCC !important",
      "-webkit-text-fill-color": "#CCCCCC !important"
    }
  },
  inputDescontoEnabled: {
    backgroundColor: Base.White,
    color: `${Base.BlackRock} !important`
  },
  fundoCelula: {
    backgroundColor: "transparent",
    width: "100%",
    height: "100%"
  },
  fundoBotoes: {
    backgroundColor: theme.color.bgTabela
  },
  botaoCentral: {
    borderLeft: `3px ${theme.color.bgTabela} solid`,
    borderRight: `3px ${theme.color.bgTabela} solid`
  },
  botaoEditarDesconto: {
    borderLeft: `3px ${theme.color.bgTabela} solid`,
    borderRight: `0`
  },
  botao: {
    color: `${theme.color.secondaryText} !important`,
    width: "100%",
    height: "100%",
    justifyContent: "center",
    justifyItems: "center",
    alignContent: "center",
    alignItems: "center"
  },
  inativo: {
    opacity: 0.5
  },
  ativo: {
    opacity: 1
  }
});
