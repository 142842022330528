import { makeStyles } from '@mui/styles';
import { Base } from "componentes/cores";
import themes from 'themes'
import { createTheme } from "@mui/material/styles";

const theme = createTheme();

export const useStyles = makeStyles(() => ({
  root: {
    "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      display: "none",
      margin: 80
    },
    "& [type=number]": {
      WebkitAppearance: "none"
    },
    "& .MuiFormLabel-root": {
      [theme.breakpoints.down("md")]: {
        fontSize: ".9rem"
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: ".8rem"
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "1rem"
      }
    },
    "& .MuiSvgIcon-root": {
      color: themes.color.inputBuscar
    },
    "& label.Mui-focused": {
      color: themes.color.inputBuscar
    },
    "& .MuiInput-underline:after": {
      borderColor: themes.color.inputBuscar
    },
    "& .MuiFormLabel-root": {
      color: themes.color.inputBuscar
    },
    "& .MuiOutlinedInput-root": {
    "& .MuiOutlinedInput-input": {
      color: themes.color.inputBuscar
    },
    "& fieldset": {
      borderRadius:  themes.borda.input,
      borderColor: themes.color.inputBuscar
    },
    "&:hover fieldset": {
      borderColor: themes.color.inputBuscar
    },
    "&.Mui-focused fieldset": {
      borderColor: themes.color.inputBuscar
    }
  },
  // "& .MuiOutlinedInput-input": {
  //   padding: "13.5px 14px"
  // }
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200
  }
}));
