import React, { useState, useMemo, useCallback, useEffect } from "react";
import shortid from "shortid";
import PassosCotacoesGd from "./componentes/Passos";
import MensagemMulta from "./Etapas/componentes/MensagemMulta";
import ContratacaoPF from "./Etapas/contratacaoPF";
import ContratacaoPJ from "./Etapas/ContratacaoPJ";
import Contratado from "./Etapas/Contratado";
import Simulacao from "./Etapas/Simular";
import CotacaoGDHelper from "./helper";
import MensagemBaixaRenda from "./Etapas/componentes/MensagemBaixaRenda";

const CotacaoGD = () => {
  const [externoIdSemFatura, setExternoIdSemFatura] = useState(
    shortid.generate()
  );
  const [passoAtual, setPassoAtual] = useState("1simulacao");
  const [tipoCliente, setTipoCliente] = useState("PF");
  const [carregandoCep, setCarregandoCep] = useState(false);
  const [error, setError] = useState(false);
  const [nomeContratante, setNomeContratante] = useState("");
  const [emailContratante, setEmailContratante] = useState("");
  const [telefoneContratante, setTelefoneContratante] = useState("");
  const [dadosOfficer, setDadosOfficers] = useState([]);
  const [dadosFatura, setResultFatura] = useState([]);
  const [dadosMatrizGD, setDadosMatrizGD] = useState([]);
  const [dadosLeadSalesForce, setDadosLeadSalesForce] = useState([]);
  const [dadosCepCobertura, setDadosCepCobertura] = useState([]);
  const [dadosFormSimulador, setDadosFormSimulador] = useState([]);
  const [emails, setEmails] = useState("");
  const [dadosTiposPlanos, setDadosTiposPLanos] = useState([]);

  const obterTodosPlanos = useCallback(async () => {
    const { data, sucesso, mensagem } = await CotacaoGDHelper.obterTodosPlanos(
      false
    );
    if (!sucesso) {
      CotacaoGDHelper.exibirErro(mensagem);
      return;
    }

    setDadosTiposPLanos(data);
  });

  useEffect(() => {
    obterTodosPlanos();
  }, []);

  const obterCepCobertura = async (cepCobertura) => {
    const { data, sucesso, mensagem } = await CotacaoGDHelper.obterCepCobertura(
      cepCobertura,
      setCarregandoCep,
      setError
    );
    if (!sucesso) {
      CotacaoGDHelper.exibirErro(mensagem);
      setCarregandoCep(false);
    }

    const cobertura = {
      data,
      error,
      sucesso,
      cepCobertura
    };
    setDadosCepCobertura(cobertura);
    return cobertura;
  };

  const obterArvoreGerencialAltoNivel = async (dados) => {
    const {
      data,
      sucesso,
      mensagem
    } = await CotacaoGDHelper.obterArvoreGerencialAltoNivel(
      dados?.id,
      32,
      setCarregandoCep,
      setError
    );
    if (!sucesso) {
      CotacaoGDHelper.exibirErro(mensagem);
      setCarregandoCep(false);
    }

    const model = {
      officers: dados,
      arvore: data
    };

    setDadosOfficers(model);
  };

  const handlePassos = (passos) => {
    switch (passos) {
      case "1simulacao":
        return 1;
      case "2contratacao":
      case "2contratacaoMulta":
      case "2contratacaoBaixaRenda":
        return 2;
      case "3contratado":
        return 3;
      default:
        return passos;
    }
  };

  const passoAtualGD = useMemo(() => {
    switch (passoAtual) {
      case "1simulacao":
        setPassoAtual("1simulacao");
        return (
          <Simulacao
            externoIdSemFatura={externoIdSemFatura}
            setPassoAtual={setPassoAtual}
            setTipoCliente={setTipoCliente}
            obterCepCobertura={(cep) => obterCepCobertura(cep)}
            setNomeContratante={setNomeContratante}
            setEmailContratante={setEmailContratante}
            setTelefoneContratante={setTelefoneContratante}
            obterArvoreGerencialAltoNivel={(dados) =>
              obterArvoreGerencialAltoNivel(dados)
            }
            setResultFatura={setResultFatura}
            setDadosMatrizGD={setDadosMatrizGD}
            setDadosLeadSalesForce={setDadosLeadSalesForce}
            setDadosFormSimulador={setDadosFormSimulador}
          />
        );
      case "2contratacao":
        setPassoAtual("2contratacao");
        return (
          <>
            {tipoCliente === "PF" ? (
              <ContratacaoPF
                setPassoAtual={setPassoAtual}
                dadosCepCobertura={dadosCepCobertura}
                obterCepCobertura={(cep) => obterCepCobertura(cep)}
                nomeContratante={nomeContratante}
                emailContratante={emailContratante}
                telefoneContratante={telefoneContratante}
                carregandoCep={carregandoCep}
                dadosOfficer={dadosOfficer}
                dadosFatura={dadosFatura}
                dadosMatrizGD={dadosMatrizGD}
                dadosLeadSalesForce={dadosLeadSalesForce}
                dadosFormSimulador={dadosFormSimulador}
                externoIdSemFatura={externoIdSemFatura}
                setEmails={setEmails}
                dadosTiposPlanos={dadosTiposPlanos}
              />
            ) : (
              <ContratacaoPJ
                setPassoAtual={setPassoAtual}
                dadosCepCobertura={dadosCepCobertura}
                obterCepCobertura={(cep) => obterCepCobertura(cep)}
                nomeContratante={nomeContratante}
                emailContratante={emailContratante}
                telefoneContratante={telefoneContratante}
                carregandoCep={carregandoCep}
                dadosOfficer={dadosOfficer}
                dadosFatura={dadosFatura}
                dadosMatrizGD={dadosMatrizGD}
                dadosLeadSalesForce={dadosLeadSalesForce}
                dadosFormSimulador={dadosFormSimulador}
                externoIdSemFatura={externoIdSemFatura}
                setEmails={setEmails}
                dadosTiposPlanos={dadosTiposPlanos}
              />
            )}
          </>
        );
      case "2contratacaoMulta":
        setPassoAtual("2contratacaoMulta");
        return <MensagemMulta setPassoAtual={setPassoAtual} />;
      case "2contratacaoBaixaRenda":
        setPassoAtual("2contratacaoBaixaRenda");
        return <MensagemBaixaRenda setPassoAtual={setPassoAtual} />;

      case "3contratado":
        setPassoAtual("3contratado");
        return <Contratado email={emails} setPassoAtual={setPassoAtual} />;

      default:
        return <div>Tela não encontrada</div>;
    }
  }, [passoAtual]);

  return (
    <>
      <PassosCotacoesGd passoAtual={handlePassos(passoAtual)} totalPassos={3} />
      {passoAtualGD}
    </>
  );
};

export default CotacaoGD;
