import { makeStyles } from '@mui/styles';
import { Base } from "componentes/cores";
import styled from "styled-components";
import theme from 'themes';

import imagemExemplo from "../../Assets/exemplo.png";

export const useStyles = makeStyles({
  container: {
    paddingBottom: 0,
    margin: "0 !important",
    width: "100%",
    "& .MuiGrid-item": {
      paddingBottom: 0
    },
    "& .MuiInputBase-root": {
      height: "46px",
      paddingBottom: 0
    },
    "& .MuiFormLabel-root": {
      lineHeight: "1"
    },
    "& .MuiGrid-spacing-xs-4 > .MuiGrid-item": {
      padding: "12px"
    }
  },
  button: {
    fontSize: "18px"
  },
  buttonCancelar: {
    borderColor: `${theme.color.primaryBackgroud} !important`,
    borderWidth: 2,
    fontSize: "18px",
    "&:hover": {
      color: `${Base.BlackOpacity60} !important`
    }
  },
  containerAvancar: {
    margin: "0 0 16px !important"
  },
  iconBox: {
    display: "inline-block",
    fontSize: "40px",
    height: "36px",
    marginRight: 10,
    verticalAlign: "top",
    width: "36px"
  }
});

export const Instrucao = styled.div`
  align-items: center;
  border: 1px solid ${theme.color.primaryBackgroud};
  display: flex;
  height: 177px;
  justify-content: center;
  width: 323px;
  @media (max-width: 1366px) {
    width: 306.45px;
  }
  @media (max-width: 1024px) {
    width: 210.98px;
  }
`;

export const Conteudo = styled.div`
  margin: 0 auto;
  max-width: 280px;
  @media (max-width: 1024px) {
    width: 185px;
  }
`;

export const Subtitulo = styled.div`
  display: inline-block;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  max-width: 135px;
`;

export const Texto = styled.div`
  display: block;
  font-size: 16px;
  width: 100%;
`;

export const ExemploCaptura = styled.div`
  background-image: url(${imagemExemplo});
  background-size: contain;
  display: block;
  height: 592px;
  width: 687px;
  @media (max-width: 1024px) {
    height: 383px;
    width: 442.96px;
  }
`;

export const BotaoFoto = styled.input`
  opacity: 0;
  position: absolute;
  z-index: -1;
`;

export const LabelBotaoFoto = styled.label`
  background-color: ${theme.color.metalic};
  cursor: pointer;
`;
