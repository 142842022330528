import { makeStyles } from '@mui/styles';
import theme from 'themes';

import { createTheme } from "@mui/material/styles";

const themeResposive = createTheme();

export const useStyles = makeStyles({
  container: {
    background: theme.color.red,
    color: `${theme.color.secondaryBorderColor} !important`,
    height: "300px",
    marginTop: "2em",
    marginBottom: "2em",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  pagination: {
    textAlign: "center"
  },
  itemFiltro: {
    display: "flex",
    justifyContent: "flex-end"
  },
  buttonFiltro: {
    [themeResposive.breakpoints.down("lg")]: {
      width: "100% !important"
    }
  }
});
