import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";

// Icones
import TuneIcon from "@mui/icons-material/Tune";

// Form Hooks
import { useForm, useWatch } from "react-hook-form";

// Componentes
import { Grid } from "@mui/material";
import { cpfCnpjValido } from "../../../../global/utils/formatCpfCnpj";
import { Base } from "../../../../componentes/cores";
import ItemListaExpansivel from "../../../../componentes/itemListaExpansivel";
import BotaoCadastro from "../../../../componentes/botaoCadastro";
import Loader from "../../../../componentes/loader";
import ModalConfirmacao from "../../../../componentes/modalConfirmacao";
import BotaoFiltroOrdenacao from "../../../../componentes/botaoFiltroOrdenar";
import Paginacao from "../../../../componentes/paginacao";

// Serviços
import {
  listarEmpresasGrupoDelta,
  deletarEmpresaGrupoDelta,
  listarOrganizarPor,
  listarFiltrarPor
} from "../../../../servicos/empresaServico";

// Rotas
import { RotasDTO } from "../../../../global/rotas/rotasUrlDto";

// Redux
import { store } from "../../../../global/redux";
import { alertaExibir } from "../../../../global/redux/modulos/alertas/actions";
import { desabilitarFiltroLateral } from "../../../../global/redux/modulos/usuario/actions";

// Styles
import { useStyles } from "./style";
import theme from "../../../../themes";

// Útils
import { formataCnpj } from "../../../../servicos/utils";
import MaterialInputBusca from "../../../../componentes/inputBusca";
import TabelaSemDados from "../../../../componentes/tabelaPaginada/tabelaSemDados";

const EmpresaGrupoDeltaListar = () => {
  const classes = useStyles();
  const history = useHistory();

  const [paginaAtual, setPaginaAtual] = useState();
  const [totalPaginas, setTotalPaginas] = useState();
  const [
    carregandoEmpresaGrupoDelta,
    setCarregandoEmpresasGrupoDelta
  ] = useState(false);

  const [listaEmpresasGrupoDelta, setListaEmpresasGrupoDelta] = useState([]);

  const handleListarEmpresasGrupoDelta = async (ordernacao, filtro, atual) => {
    try {
      setCarregandoEmpresasGrupoDelta(true);
      const lista = await listarEmpresasGrupoDelta(ordernacao, filtro, atual);
      if (lista?.status === 200 && lista?.data.empresasGrupoDelta) {
        setListaEmpresasGrupoDelta(lista?.data.empresasGrupoDelta);
        setPaginaAtual(lista?.data?.paginaAtual);
        setTotalPaginas(lista?.data?.totalPaginas);
      }
      setCarregandoEmpresasGrupoDelta(false);
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
      setCarregandoEmpresasGrupoDelta(false);
    }
  };

  const obterEmpresasGrupoDelta = useCallback(async () => {
    handleListarEmpresasGrupoDelta("", "");
  }, []);

  useEffect(() => {
    if (!listaEmpresasGrupoDelta?.length) obterEmpresasGrupoDelta();
  }, [listaEmpresasGrupoDelta.length, obterEmpresasGrupoDelta]);

  const onClickNovaEmpresaGrupoDelta = () => {
    history.push(`${RotasDTO.EmpresaGrupoDelta}/cadastro`);
  };

  const onClickEditar = (id) => {
    history.push(`${RotasDTO.EmpresaGrupoDelta}/cadastro/${id}`);
  };

  const [modalExclusao, setModalExclusao] = useState(false);

  const deletar = async (item) => {
    try {
      setModalExclusao(false);
      setCarregandoEmpresasGrupoDelta(true);
      const deletou = await deletarEmpresaGrupoDelta(
        item?.id,
        !(modalExclusao?.situacao?.toUpperCase() === "AT")
      );
      if (deletou) {
        obterEmpresasGrupoDelta();
        store.dispatch(
          alertaExibir({
            tipo: "success",
            mensagem: `A Empresa foi ${
              !(modalExclusao?.situacao?.toUpperCase() === "AT")
                ? "ativada"
                : "inativada"
            } com sucesso!`
          })
        );
      }
      setCarregandoEmpresasGrupoDelta(false);
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
      setCarregandoEmpresasGrupoDelta(false);
    }
  };

  const onClickExcluir = (item) => {
    setModalExclusao(item);
  };

  const onConfirmarExclusao = (item) => {
    try {
      deletar(item);
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
    }
  };

  const { register, control } = useForm({
    reValidateMode: "onSubmit"
  });

  const textoBusca = useWatch({
    control,
    name: "textoBusca",
    defaultValue: ""
  });

  const filtrarEmpresasGrupoDelta = useCallback(() => {
    return listaEmpresasGrupoDelta.filter((empresaGrupoDelta) => {
      if (textoBusca && textoBusca?.length > 2) {
        return (
          empresaGrupoDelta?.nomeFantasia
            .toLowerCase()
            .includes(textoBusca.toLowerCase()) ||
          empresaGrupoDelta?.cpfCnpj
            .toLowerCase()
            .includes(cpfCnpjValido(textoBusca.toLowerCase()))
        );
      }
      return true;
    });
  }, [listaEmpresasGrupoDelta, textoBusca]);

  const [ordemSelecionada, setOrdemSelecionada] = useState("");
  const [filtroSelecionado, setFiltroSelecionado] = useState("");

  const handleClickOrdemSelecionada = (event) => {
    const val = ordemSelecionada === event ? "" : event;
    setOrdemSelecionada(val);
  };

  const handleClickFiltroSelecionado = (event) => {
    const val = filtroSelecionado === event ? "" : event;
    setFiltroSelecionado(val);
  };

  const handleClickAtivarAgora = async () => {
    const filtro = [];
    if (filtroSelecionado) filtro.push(filtroSelecionado);
    handleListarEmpresasGrupoDelta(ordemSelecionada, filtro);
  };

  const proximaPagina = "Go to next page";
  const paginaAnterior = "Go to previous page";

  const onChangePaginacao = (item) => {
    if (item.innerText !== "") {
      const pagina = parseInt(item.innerText, 0);
      handleListarEmpresasGrupoDelta(
        ordemSelecionada,
        filtroSelecionado,
        pagina
      );
    }

    if (item.innerText === "") {
      if (item.ariaLabel === proximaPagina) {
        handleListarEmpresasGrupoDelta(
          ordemSelecionada,
          filtroSelecionado,
          paginaAtual + 1
        );
      }

      if (item.ariaLabel === paginaAnterior) {
        handleListarEmpresasGrupoDelta(
          ordemSelecionada,
          filtroSelecionado,
          paginaAtual - 1
        );
      }
    }
  };

  useEffect(() => {
    store.dispatch(desabilitarFiltroLateral(true));

    return () => {
      store.dispatch(desabilitarFiltroLateral(false));
    };
  }, [desabilitarFiltroLateral]);

  return (
    <Loader loading={carregandoEmpresaGrupoDelta} className="w-auto">
      <ModalConfirmacao
        item={modalExclusao}
        onConfirmar={(item) => onConfirmarExclusao(item)}
        mensagem={`Tem certeza que deseja ${
          modalExclusao?.situacao?.toUpperCase() === "AT"
            ? "inativar"
            : "ativar"
        } essa Empresa?`}
        onCancelar={() => setModalExclusao(false)}
      />
      <Grid
        container
        spacing={0}
        alignItems="center"
        justifyContent="space-between"
        className={classes.container}
      >
        <Grid item lg={5} className="mb-4">
          <MaterialInputBusca
            type="text"
            id="textoBusca"
            name="textoBusca"
            label="Buscar"
            renderIconShowHide
            searchAdornment
            // defaultValue={textoBusca?.trimStart() ?? ""}
            defaultValue={textoBusca ?? ""}
            ref={register}
            permiteValorBranco
          />
        </Grid>
        <Grid item xs={3} className={classes.itemFiltro}>
          <BotaoFiltroOrdenacao
            type="button"
            color={theme.color.secondaryBorderColor}
            background="transparent"
            label="Filtrar / Ordernar"
            icon={<TuneIcon />}
            className={classes.buttonFiltro}
            ordenacao={listarOrganizarPor()}
            ordenadorUm="Ordenador"
            ordenadorDois="Filtrar"
            ordemSelecionada={ordemSelecionada}
            filtros={listarFiltrarPor()}
            filtrosSelecionados={filtroSelecionado}
            onClickOrdenacao={handleClickOrdemSelecionada}
            onClickFiltro={handleClickFiltroSelecionado}
            onClickAtivarAgora={handleClickAtivarAgora}
          />
        </Grid>
        <Grid item xs={12}>
          <BotaoCadastro
            label="Nova empresa do grupo delta"
            color={theme.color.buttonColor}
            onClick={onClickNovaEmpresaGrupoDelta}
          />
        </Grid>
        {filtrarEmpresasGrupoDelta() &&
        filtrarEmpresasGrupoDelta()?.length > 0 ? (
          <>
            {filtrarEmpresasGrupoDelta().map((empresaGrupoDelta) => {
              return (
                <ItemListaExpansivel
                  key={empresaGrupoDelta?.id}
                  label={empresaGrupoDelta?.nomeFantasia ?? "EmpresaGrupoDelta"}
                  color={Base.White}
                  edicao={!!empresaGrupoDelta?.id}
                  exclusao={!!empresaGrupoDelta?.id}
                  onClickEditar={() => onClickEditar(empresaGrupoDelta?.id)}
                  onClickExcluir={() =>
                    onClickExcluir(empresaGrupoDelta ?? false)
                  }
                  ativo={empresaGrupoDelta?.situacao.toUpperCase() === "AT"}
                >
                  <div>
                    <strong>CNPJ: </strong>
                    {formataCnpj(empresaGrupoDelta?.cpfCnpj) ?? ""}
                  </div>
                  <div>
                    <strong>Árvore Gerencial Vinculada: </strong>
                    {empresaGrupoDelta?.descricao}
                  </div>
                </ItemListaExpansivel>
              );
            })}
            <Grid
              container
              spacing={3}
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item>
                <Paginacao
                  paginaAtual={paginaAtual}
                  totalPaginas={totalPaginas}
                  onChangePagina={onChangePaginacao}
                />
              </Grid>
            </Grid>
          </>
        ) : (
          <Grid
            container
            spacing={3}
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <TabelaSemDados className={classes.containerSemDados} />
            </Grid>
          </Grid>
        )}
      </Grid>
    </Loader>
  );
};

export default EmpresaGrupoDeltaListar;
