import { createTheme } from "@mui/material";
import { makeStyles } from '@mui/styles';
import { Base } from "../cores";
import theme from "themes";

export const useStyles = makeStyles(() => ({
  root: {
    "& .MuiInputLabel-outlined": {
      color: theme.color.textoBarra
    }
  },
  container: {
    "& .MuiInputLabel-outlined": {
      color: theme.color.textoBarra
    },
    "& .MuiFormControl-marginNormal": {
      borderColor: theme.color.textoBarra,
      borderRadius: "4px",
      margin: "0"
    },
    "& .MuiFormLabel-root": {
      color: theme.color.textoBarra,
      backgroundColor: "transparent !important"
    },
    "& .MuiOutlinedInput-root": {
    "& .MuiOutlinedInput-input": {
      color: theme.color.textoBarra
    },
    "& fieldset": {
      borderColor: theme.color.textoBarra
    },
    "&:hover fieldset": {
      borderColor: theme.color.textoBarra
    },
    "&.Mui-focused fieldset": {
      borderColor: theme.color.textoBarra
    },
    "& .MuiSvgIcon-root": {
      color: theme.color.textoBarra
    },
    "& #dataMedicaoConsolidada": {
      color: theme.color.textoBarra
    },
    "& svg": {
      color: theme.color.textoBarra
    }
  },    
  }
}));

export const materialTheme = createTheme({
  palette: {
    primary: {
      main: theme.color.primary,
      contrastText: Base.White,
    },
  },
});

