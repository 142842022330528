import React, { useState } from "react";
import PropTypes from "prop-types";

// Componentes
import {
  FormControl,
  FormGroup,
  FormControlLabel,
  Switch
} from "@mui/material";

const Interruptor = React.forwardRef((props, ref) => {
  const { name, customValue } = props;

  const [state, setState] = useState(false);

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  return (
    <div>
      <FormControl variant="standard" component="fieldset">
        <FormGroup aria-label="position" row>
          <FormControlLabel
            value={name}
            control={<Switch color="primary" onChange={handleChange} />}
            label={customValue}
            labelPlacement="Top"
            ref={ref}
          />
        </FormGroup>
      </FormControl>
    </div>
  );
});

Interruptor.propTypes = {
  name: PropTypes.string,
  customValue: PropTypes.string
};

Interruptor.defaultProps = {
  name: "",
  customValue: ""
};

export default Interruptor;
