
import { atualizaLog, enviaLog } from "./logDeltaSdkServico";

export async function montaAtualizaLog(requestLog, responseLog,nestedField,searchField,searchContent,index) {
  try {
    var queryIndexId = {
      _source : searchField,
      query : {
        match : {
          [searchField] : searchContent
        }
      }
    };
    var doc = {
      doc : {
        [nestedField] : [{
            request : requestLog,
            response : responseLog,
            date : new Date()
        }]
      }
    };
    await atualizaLog(queryIndexId, doc, index, nestedField );
  } catch (error) {
    console.error(error);
  }
}

export async function montaEnviaLog(mainFields, requestLog, responseLog,nestedField,message,index,dataIndex) {
  try {
    
    const logElastic = {
        ...mainFields,
        [nestedField] : [{
            response : responseLog,
            request : requestLog
        }]
    };
  const res = await enviaLog(2, message, logElastic, index, dataIndex);
    return res
  } catch (error) {
    console.error(error);
  }
}
