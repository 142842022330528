import React from "react";
import PropTypes from "prop-types";

import { Grid, Button } from "@mui/material";
import SimCardDownloadOutline from "../../../../../assets/icones/downloads/simCardDownloadOutline.png";

import { Description } from "@mui/icons-material";

import { useStyles, Icone } from "./style";
import FaturasHelper from "../helper";

import { HtmlTooltip } from "../../../../Graficos/ConsumoAtual/style";

const ColunaBoleto = ({ parametros }) => {
  const classes = useStyles();

  const onClickDownloadBoleto = async (params) => {    
    const { sucesso, mensagem, data } = await FaturasHelper.BaixarBoleto(params);
    if (!sucesso) return FaturasHelper.exibirErro(mensagem);
  };

  return (
    <Grid container spacing={0} className={classes.fundoCelula}>
      <Grid item xs={12}>
        <Button
          onClick={() => onClickDownloadBoleto(parametros)}
          className={`text-white ${classes.botao}`}
        >
          <HtmlTooltip title="Baixar">
            <Description />
            {/* <Icone src={SimCardDownloadOutline} /> */}
          </HtmlTooltip>
        </Button>
      </Grid>
    </Grid>
  );
};

ColunaBoleto.propTypes = {
  onClick: PropTypes.oneOfType([PropTypes.func]).isRequired,
  parametros: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
    .isRequired
};

export default ColunaBoleto;
