import React, { useEffect, useState } from "react";

// Form Hooks
import { useForm, useWatch } from "react-hook-form";

// Componentes
import { Grid } from "@mui/material";
import TreeViewContainer from "./componentes/treeViewContainer";
import BotaoFiltroOrdenacao from "../../../componentes/botaoFiltroOrdenar";

// Styles
import { useStyles } from "./style";
import theme from 'themes';

// Serviços
import { listarFiltrarPor } from "../../../servicos/arvoresGerenciaisServico";

// Redux
import { desabilitarFiltroLateral } from "../../../global/redux/modulos/usuario/actions";
import { store } from "../../../global/redux";
import { Base } from "componentes/cores";
import MaterialInputBusca from "componentes/inputBusca";

const ArvoreGerencialCadastro = () => {
  const [filtroSelecionado, setFiltroSelecionado] = useState("");
  const [filtrosAtivos, setFiltrosAtivos] = useState("");

  const onClickFiltroSelecionado = (event) => {
    const val = filtroSelecionado === event ? "" : event;
    setFiltroSelecionado(val);
  };

  const onClickAtivarAgora = async () => {
    const filtro = [];
    filtro.push(filtroSelecionado);
    setFiltrosAtivos(filtro);
  };

  const classes = useStyles();
  const { register, control } = useForm({
    reValidateMode: "onSubmit"
  });

  const textoBusca = useWatch({
    control,
    name: "textoBusca",
    defaultValue: ""
  });

  const [nodeEdicao, setNodeEdicao] = useState({});

  const onEditar = (node) => {
    setNodeEdicao(node);
  };

  useEffect(() => {
    store.dispatch(desabilitarFiltroLateral(true));

    return () => {
      store.dispatch(desabilitarFiltroLateral(false));
    };
  }, [desabilitarFiltroLateral]);

  return (
    <Grid
      container
      spacing={0}
      className={classes.container}
      alignItems="center"
      justifyContent="space-between"
    >
      <Grid item lg={5} className="mb-4">
      <MaterialInputBusca
            type="text"
            id="textoBusca"
            name="textoBusca"
            label="Buscar"
            renderIconShowHide
            searchAdornment
            defaultValue={textoBusca ?? ""}
            ref={register}
            permiteValorBranco
          />
      </Grid>
      <Grid item lg={3} className={`mb-4 ${classes.itemFiltro}`}>
        <BotaoFiltroOrdenacao
          type="button"
          label="Filtrar"
          color={theme.color.secondaryBorderColor}
          background="transparent"
          filtros={listarFiltrarPor()}
          ordenadorDois="Filtrar"
          filtrosSelecionados={filtroSelecionado}
          onClickFiltro={onClickFiltroSelecionado}
          onClickAtivarAgora={onClickAtivarAgora}
        />
      </Grid>
      <Grid item lg={12} className="d-flex justify-content-end mb-4">
        <ul className={classes.filter}>
          <li>
            <div className="icon" />
            <div className="texto">Árvore Principal</div>
          </li>
          <li>
            <div className="icon" />
            <div className="texto">Árvore Nível 1</div>
          </li>
          <li>
            <div className="icon" />
            <div className="texto">Árvore Nível 2</div>
          </li>
          <li>
            <div className="icon" />
            <div className="texto">Árvore Nível 3</div>
          </li>
          <li>
            <div className="icon" />
            <div className="texto">Árvore Nível 4</div>
          </li>
        </ul>
      </Grid>
      <TreeViewContainer
        onBuscar={textoBusca ?? ""}
        onEditar={(node) => onEditar(node)}
        nodeEdicao={nodeEdicao}
        register={register}
        filtrosAtivos={filtrosAtivos}
      />
    </Grid>
  );
};

export default ArvoreGerencialCadastro;
