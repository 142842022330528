import React, { useCallback, useRef } from "react";

import { Grid } from "@mui/material";

import TabelaPaginada from "componentes/tabelaPaginada";
import Coluna from "componentes/tabelaPaginada/colunas/coluna";
import ResultadoPaginadoDto from "componentes/tabelaPaginada/resultadoPaginadoDto";
import ColunaComponentePersonalizado from "componentes/tabelaPaginada/colunas/colunaComponentePersonalizado";

import ListaDto from "./ListaDto";
import Helper from "../helper";
import NofificacaoPlataforma from "./colunasComplementares/notificacaoPlataforma";
import NotificacaoEmail from "./colunasComplementares/notificacaoEmail";

import { store } from "global/redux";
import { alertaExibir } from "global/redux/modulos/alertas/actions";

import { useStyles } from "./style";

const RelatoriosNotificacao = () => {

  const refTabela = useRef(null);
  const classes = useStyles();

  const onClickAtivarNotiifcacao = useCallback(async (TipoNotificacao, TipoRelatorioId, Ativo) => {

    const resultado = await Helper.AlterarParametrizacaoNotificacao({ TipoNotificacao, TipoRelatorioId, Ativo });

    if (resultado.sucesso) {
      store.dispatch(
        alertaExibir({
          tipo: "success",
          mensagem: `A notificação foi ${Ativo === false ? "inativada" : "ativada"} com sucesso!`
        })
      );
    }
    else {
      store.dispatch(
        alertaExibir({
          tipo: "danger",
          mensagem: resultado.mensagem
        })
      );
    }

    if (refTabela?.current) refTabela.current.obterDadosPaginados();
  }, []);

  const colunas = [
    new Coluna("nome", "Tipo de relatórios"),
    new Coluna("categoriaRelatorio", "Categoria de relatórios"),
    new ColunaComponentePersonalizado(
      "opcoes",
      "Notificação pela plataforma",
      NofificacaoPlataforma,
      onClickAtivarNotiifcacao,
      false,
      true,
      "15%",
      "15%",
      "pl-2"
    ),
    new ColunaComponentePersonalizado(
      "opcoes",
      "Notificação por E-mail",
      NotificacaoEmail,
      onClickAtivarNotiifcacao,
      false,
      true,
      "15%",
      "15%",
      "pl-2"
    )
  ];

  const obterTiposRelatoriosParametrizacao = async (parametros) => {

    const resultado = await Helper.ListaTipoRelatorioPorCliente(parametros);

    if (!resultado.sucesso) {
      store.dispatch(
        alertaExibir({
          tipo: "danger",
          mensagem: resultado.mensagem
        })
      );
      return new ResultadoPaginadoDto([], 1, 0, 0);
    }

    if (resultado.data.lista?.length === 0 ?? true)
      return new ResultadoPaginadoDto([], 1, 0, 0);

    const resLinhas = resultado.data.lista?.map((res) => {

      return new ListaDto(
        res.tipoRelatorioId,
        res.tipoRelatorioNome,
        res.nomeCategoriaRelatorio,
        res.notificacaoPorEmail,
        res.notificacaoPelaPlataforma
      );
    });

    return new ResultadoPaginadoDto(
      resLinhas,
      resultado.data.paginaAtual,
      resultado.data.totalItens,
      resultado.data.totalPaginas
    );
  };

  const onChangeFiltrosTabela = async (parametros) => {
    return obterTiposRelatoriosParametrizacao(parametros);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <TabelaPaginada
          className={classes.table}
          ref={refTabela}
          onChangeFiltrosTabela={onChangeFiltrosTabela}
          colunas={colunas}
          paginaAtual={1}
          noHeader
        />
      </Grid>
    </Grid>
  );
};

export default RelatoriosNotificacao;
