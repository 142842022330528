import React, { useState } from "react";
import { Grid } from "@mui/material";
import EsqueletoEtapa from "../../Componentes/EsqueletoEtapa";
import { useStyles } from "./style";
import { ProtocolosEtapas } from "global/constantes";

const Analise = ({
  prazo,
  responsavel,
  onProximaEtapa,
  esconderBotaoProximo,
  onVoltarEtapa
}) => {
  const classes = useStyles();

  const avancarEtapa = () => {
    onProximaEtapa(ProtocolosEtapas.AssinaturaConfirmacaoContrato, true);
  };

  const voltarEtapa = () => {
    onVoltarEtapa(ProtocolosEtapas.AssinarContratoProcuracao);
  }

  return (
    <EsqueletoEtapa
      prazo={prazo}
      responsavel={responsavel}
      titulo="Análise dos documentos"
      onClickProximo={() => avancarEtapa()}
      onClickVoltar={() => voltarEtapa()}
      esconderBotaoProximo={esconderBotaoProximo}
    >
      <Grid className={classes.containerContent}>
        <h4>
          <p>Aguarde enquanto realizamos a análise dos documentos enviados. </p>
          <p>
            Caso não receba retorno das análises até o prazo acima, a Adesão
            estará automaticamente cancelada.
          </p>
        </h4>
      </Grid>
    </EsqueletoEtapa>
  );
};

export default Analise;
