import RetornoEndpointDto from "../../../../global/dto/retornoEndpointDto";
import { listaTermoDeUso, obterTermoPorId } from "../../../../servicos/termoDeUsoServico";

export default class TermosHelper {
  static async listaTermoDeUso(filtro) {

    try {
      const resultado = await listaTermoDeUso(filtro);

      return RetornoEndpointDto.Sucesso(
        "Termos obtidos com sucesso",
        resultado.data
      );
    } catch (error) {
      return RetornoEndpointDto.Erro(
        "Ocorreu um erro ao obter a lista de termos"
      );
    }
  }

}
