import React, { useState, useMemo, useEffect, useCallback } from "react";
import PropTypes from "prop-types";

// Componentes
import {
  FormControl,
  InputLabel,
  TextField,
  MenuItem,
  InputAdornment,
  IconButton,
  MenuList
} from "@mui/material";

// Icones
import ClearIcon from "@mui/icons-material/Clear";

// Styles
import { useStyles, Label } from "./style";

// Servicos
import { buscarPorId } from "../../servicos/arvoresGerenciaisServico";

const SelectHierarquia = React.forwardRef((props, ref) => {
  const {
    id,
    name,
    value,
    onChange,
    dataSource,
    valueKey,
    valueName,
    label,
    maxHeight,
    maxWidth,
    marginBottom,
    placeholder,
    hideLabel,
    allowClear,
    errors,
    isUpdate,
    disabled
  } = props;

  const classes = useStyles({ maxHeight, maxWidth, marginBottom });

  const [aberto, setAberto] = useState(false);

  const selectProps = {
    onOpen: () => setAberto(true),
    onClose: () => setAberto(false)
  };

  const [customValue, setCustomValue] = useState("");

  useEffect(() => {
    if (dataSource?.length && value) setCustomValue(value);
  }, [dataSource, value]);

  const [labelValor, setLabelValor] = useState("");

  const retornaItemArvore = async (arvoreId) => {
    if (arvoreId) {
      const item = await buscarPorId(arvoreId);
      if (item?.data) {
        return item.data;
      }
    }
    return null;
  };

  const [listaDados, setListaDados] = useState([]);

  const tratarDados = useCallback(async () => {
    if (dataSource) {
      if (aberto) setListaDados(dataSource);
      if (!aberto && value) {
        if (isUpdate) {
          const itemArvore = await retornaItemArvore(value);
          if (itemArvore) {
            setListaDados([
              { id: itemArvore.id, descricao: itemArvore.descricao }
            ]);
          }
        }
        if (labelValor) {
          setListaDados([{ id: value, descricao: labelValor }]);
        }
      }
      if (!aberto && !value) setListaDados(dataSource);
    }
  }, [dataSource, aberto, value, isUpdate, labelValor]);

  useEffect(() => {
    tratarDados();
  }, [tratarDados]);

  const onClickClear = useCallback(() => {
    onChange();
    setCustomValue("");
  }, [onChange]);

  const showClear = useMemo(
    () =>
      allowClear && value ? (
        <InputAdornment position="start" className={classes.adornment}>
          <IconButton
            onClick={onClickClear}
            onMouseDown={onClickClear}
            size="small"
          >
            <ClearIcon fontSize="small" />
          </IconButton>
        </InputAdornment>
      ) : null,
    [allowClear, value, classes.adornment, onClickClear]
  );

  const handleItemSelecionado = (valor, nome) => {
    onChange(String(valor));
    setLabelValor(nome);
  };

  return (
    <FormControl
      variant="outlined"
      fullWidth
      classes={{ root: classes.formControl }}
    >
      {!hideLabel && (
        <InputLabel
          className={classes.label}
          hidden={Boolean(value)}
          htmlFor={`${id}Label`}
        >
          {placeholder}
        </InputLabel>
      )}
      <TextField
        select={Boolean(listaDados?.length)}
        variant="outlined"
        value={customValue}
        classes={{ root: classes.select }}
        fullWidth
        id={id}
        name={name}
        inputRef={ref}
        SelectProps={selectProps}
        label={!value ? "" : label}
        InputProps={{ endAdornment: showClear }}
        error={Boolean(errors[name]?.message)}
        helperText={errors[name]?.message ?? ""}
        disabled={disabled}
      >
        {listaDados?.length &&
          listaDados.map((item, i) => {
            return (
              <MenuItem
                className={`d-block mb-0 pb-0 ${classes.menuItem}`}
                key={i}
                value={String(item[valueKey])}
                onClick={(event) => event.preventDefault()}
              >
                <Label
                  onClick={() =>
                    handleItemSelecionado(item[valueKey], item[valueName])
                  }
                >
                  {item[valueName]}
                </Label>
                {item?.arvoreGerencialColecao?.length ? (
                  <MenuList
                    variant="menu"
                    className={`d-block mb-0 pb-0 ${classes.menuItem}`}
                  >
                    {item?.arvoreGerencialColecao.map((filho, f) => (
                      <MenuItem
                        className="d-block"
                        key={f}
                        value={String(filho[valueKey])}
                        onClick={(event) => event.preventDefault()}
                      >
                        <Label
                          onClick={() =>
                            handleItemSelecionado(
                              filho[valueKey],
                              filho[valueName]
                            )
                          }
                        >
                          {filho[valueName]}
                        </Label>
                        {filho?.arvoreGerencialColecao?.length ? (
                          <MenuList
                            variant="menu"
                            className={`d-block mb-0 pb-0 ${classes.menuItem}`}
                          >
                            {filho?.arvoreGerencialColecao.map((neto, n) => (
                              <MenuItem
                                className="d-block"
                                key={n}
                                value={String(neto[valueKey])}
                                onClick={(event) => event.preventDefault()}
                              >
                                <Label
                                  onClick={() =>
                                    handleItemSelecionado(
                                      neto[valueKey],
                                      neto[valueName]
                                    )
                                  }
                                >
                                  {neto[valueName]}
                                </Label>

                                {neto?.arvoreGerencialColecao?.length ? (
                                  <MenuList
                                    variant="menu"
                                    className={`d-block mb-0 pb-0 ${classes.menuItem}`}
                                  >
                                    {neto?.arvoreGerencialColecao.map(
                                      (bisneto, n) => (
                                        <MenuItem
                                          className="d-block"
                                          key={n}
                                          value={String(bisneto[valueKey])}
                                          onClick={(event) =>
                                            event.preventDefault()
                                          }
                                        >
                                          <Label
                                            onClick={() =>
                                              handleItemSelecionado(
                                                bisneto[valueKey],
                                                bisneto[valueName]
                                              )
                                            }
                                          >
                                            {bisneto[valueName]}
                                          </Label>
                                                    {bisneto?.arvoreGerencialColecao?.length ? (
                                                      <MenuList
                                                        variant="menu"
                                                        className={`d-block mb-0 pb-0 ${classes.menuItem}`}
                                                      >
                                                        {bisneto?.arvoreGerencialColecao.map(
                                                          (tataraneto, n) => (
                                                            <MenuItem
                                                              className="d-block"
                                                              key={n}
                                                              value={String(tataraneto[valueKey])}
                                                              onClick={(event) =>
                                                                event.preventDefault()
                                                              }
                                                            >
                                                              <Label
                                                                onClick={() =>
                                                                  handleItemSelecionado(
                                                                    tataraneto[valueKey],
                                                                    tataraneto[valueName]
                                                                  )
                                                                }
                                                              >
                                                                {tataraneto[valueName]}
                                                              </Label>
                                                              {tataraneto?.arvoreGerencialColecao?.length ? (
                                                      <MenuList
                                                        variant="menu"
                                                        className={`d-block mb-0 pb-0 ${classes.menuItem}`}
                                                      >
                                                        {tataraneto?.arvoreGerencialColecao.map(
                                                          (tataranetolv2, n) => (
                                                            <MenuItem
                                                              className="d-block"
                                                              key={n}
                                                              value={String(tataranetolv2[valueKey])}
                                                              onClick={(event) =>
                                                                event.preventDefault()
                                                              }
                                                            >
                                                              <Label
                                                                onClick={() =>
                                                                  handleItemSelecionado(
                                                                    tataranetolv2[valueKey],
                                                                    tataranetolv2[valueName]
                                                                  )
                                                                }
                                                              >
                                                                {tataranetolv2[valueName]}
                                                              </Label>
                                                              
                                                            </MenuItem>
                                                          )
                                                        )}
                                                      </MenuList>
                                                    ) : null}
                                                  </MenuItem>
                                                )
                                              )}
                                            </MenuList>
                                          ) : null}
                                        </MenuItem>
                                      )
                                    )}
                                  </MenuList>
                                ) : null}
                              </MenuItem>
                            ))}
                          </MenuList>
                        ) : null}
                      </MenuItem>
                    ))}
                  </MenuList>
                ) : null}
              </MenuItem>
            );
          })}
      </TextField>
    </FormControl>
  );
});

SelectHierarquia.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.any]),
  onChange: PropTypes.oneOfType([PropTypes.func]),
  dataSource: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  valueKey: PropTypes.string,
  valueName: PropTypes.string,
  label: PropTypes.string,
  maxHeight: PropTypes.string,
  maxWidth: PropTypes.string,
  marginBottom: PropTypes.string,
  placeholder: PropTypes.string,
  hideLabel: PropTypes.bool,
  allowClear: PropTypes.bool,
  errors: PropTypes.oneOfType([PropTypes.object]),
  isUpdate: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  disabled: PropTypes.bool
};

SelectHierarquia.defaultProps = {
  id: "",
  value: "",
  onChange: () => {},
  dataSource: [],
  valueKey: "",
  valueName: "",
  label: "",
  maxHeight: "auto",
  maxWidth: "auto",
  marginBottom: "10px",
  placeholder: "",
  hideLabel: false,
  allowClear: false,
  errors: {},
  isUpdate: false,
  disabled: false
};

export default SelectHierarquia;
