import React from "react";
import { useStyles } from './style';

const UnidadeNaoSelecionada = () => {

  const classes = useStyles();

  return (
    <div className={classes.root}>
      É necessário escolher uma unidade consumidora para continuar!
    </div>
  )
}
export default UnidadeNaoSelecionada;
