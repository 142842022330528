import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

// Styles
import { Button } from "./style";
import { Base } from "../cores";
import theme from "themes";
import { Checkbox } from "@mui/material";
import { useStyles } from "componentes/botaoCheckbox/style";
import CheckIcon from "@mui/icons-material/Check";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";

const BotaoCheckBox = React.forwardRef((props, ref) => {
  const {
    disabled,
    id,
    icon,
    label,
    bgColor,
    type,
    onCheckedChange,
    style,
    className,
    checked,
    stateless,
    selected
  } = props;

  const classes = useStyles();
  const [checkado, setCheckado] = useState(false);

  const classNameDesc = `btn btn-lg rounded-pill w-100 position-relative d-flex justify-content-center align-items-center ${className}`;

  const changeCheckState = () => {
    setCheckado((oldState) => !oldState);
  };

  useEffect(() => {
    setCheckado(checked);
  }, [checked]);

  useEffect(() => {
    onCheckedChange(checkado);
  }, [checkado]);

  return (
    <Button
      type={type}
      className={classNameDesc}
      id={id}
      disabled={disabled}
      ref={ref}
      bgColor={bgColor}
      stateless={stateless}
      selected={selected}
      onClick={changeCheckState}
      style={{ ...style }}
    >
      <>
        <Checkbox
          color={"default"}
          className={classes.checkbox}
          checkedIcon={<CheckIcon className={classes.iconeSelecionado} />}
          icon={
            <CheckBoxOutlineBlankIcon className={classes.iconeSemSelecao} />
          }
          checked={checkado}
          inputProps={{ "aria-label": label }}
        />
        {label}
        {icon && icon}
      </>
    </Button>
  );
});

BotaoCheckBox.propTypes = {
  disabled: PropTypes.bool,
  id: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.any]),
  label: PropTypes.string,
  bgColor: PropTypes.string,
  type: PropTypes.string,
  onCheckedChange: PropTypes.oneOfType([PropTypes.func]),
  style: PropTypes.oneOfType([PropTypes.any]),
  className: PropTypes.string,
  checked: PropTypes.bool,
  stateless: PropTypes.bool,
  selected: PropTypes.bool
};

BotaoCheckBox.defaultProps = {
  disabled: false,
  id: "",
  icon: false,
  label: "",
  bgColor: theme.color.checkButonColor,
  type: "button",
  onCheckedChange: () => {},
  style: {},
  className: "",
  checked: false,
  stateless: true,
  selected: false
};

export default BotaoCheckBox;
