import React, { useState } from "react";
import PropTypes from "prop-types";
import { generate } from "shortid";
import { Grid, Button, OutlinedInput, InputAdornment, IconButton } from "@mui/material";
import { Edit, Clear, Check, HourglassEmpty } from "@mui/icons-material";
import { useStyles } from "./style";
import { HtmlTooltip } from "../../../../Graficos/ConsumoAtual/style";
import ListagemCotacaoHelper from "../../helper";

const ColunaInputDescontos = ({ parametros, onClick }) => {
  const classes = useStyles();
  const [desconto, setDesconto] = useState(parametros.desconto);

  const [idCotacao, setIdCotacao] = useState(parametros.id);

  const [disabled, setDisabled] = useState(true);

  const [carregandoEditarDesconto, setCarregandoEditarDesconto] = useState(false);

  const onClickStatus = () => { disabled ? setStatus(false) : setStatus(true) }

  const onClickStatusInicial = () => { disabled ? inicialStatus(false) : inicialStatus(true) }

  let key = generate();

  const onClickSalvarDesconto = async () => {
    setCarregandoEditarDesconto(true);
    //const salvar = await ListagemCotacaoHelper.atualizarDesconto(desconto, idCotacao);

    const validarDescontos = await ListagemCotacaoHelper.validandoDesconto(idCotacao, desconto, parametros.consumidorLivre, parametros.valorMedioConta, parametros.cotacaoSelecionada, parametros.dataCotacaoJson, parametros.dataMigracao);

    if (validarDescontos.sucesso) {
      // console.log('IdCotação: '+ idCotacao)
      // console.log('Economia Anual: '+ validarDescontos.data.economiaAnual)
      // console.log('Economia Mensal: '+ validarDescontos.data.economiaMensal)
      // console.log('Economia Total: '+ validarDescontos.data.economiaTotal)

      const salvar = await ListagemCotacaoHelper.atualizarDesconto(desconto, idCotacao, validarDescontos.data.economiaAnual, validarDescontos.data.economiaMensal, validarDescontos.data.economiaTotal, parametros.dataMigracao);

      //setIdCotacao(idCotacao);
      setStatus(true)
      onClick();
      setCarregandoEditarDesconto(false);
    }
    else {
      alert('sem sucesso');
      //setIdCotacao(parametros.id);
      setStatus(true);
      setCarregandoEditarDesconto(false);
    }
  }

  const inicialStatus = (param) => {
    switch (param) {
      case true:
        setDesconto(parametros.desconto);
        setDisabled(true)
        break;
      default: break;
    }
  }

  const setStatus = (param) => {
    switch (param) {
      case true:
        setDisabled(true)
        break;
      case false:
        setDisabled(false);
        break;
      default: break;
    }
  }

  const botaoDescontoDesabilitado =
    parametros?.status === "Expirada" ||
    parametros?.status === "Fechada" ||
    parametros?.status === "Cancelada" ||
    parametros?.status === "Rejeitada" ||
    parametros?.status === "Anulada";



  return (
    <Grid container spacing={0} className={classes.fundoCelula}>
      <Grid item xs={8} className={classes.fundoBotoes}>
        {parametros?.desconto ?
          <OutlinedInput
            inputRef={(input) => {
              if (input != null) {
                input.focus();
              }
            }}
            id={key}
            type="text"
            disabled={disabled}
            value={desconto}
            onChange={e => setDesconto(e.target.value)}
            className={disabled ? classes.inputDescontoDisabled : classes.inputDescontoEnabled}
            endAdornment={
              disabled === false ?
                <InputAdornment position="end">
                  <HtmlTooltip title="Cancelar">
                    <IconButton
                      aria-label="Cancelar"
                      edge="end"
                      onClick={onClickStatusInicial}
                      size="large">
                      <Clear />
                    </IconButton>
                  </HtmlTooltip>
                </InputAdornment>
                :
                null
            }
          />
          : null
        }
      </Grid>

      <Grid item xs={4} className={`${classes.fundoBotoes} ${classes.botaoEditarDesconto}`}>
        {
          parametros?.desconto ?
            disabled ?
              <Button
                key={key}
                onClick={() => botaoDescontoDesabilitado ? null : onClickStatus()}
                className={`text-white ${classes.botao} ${classes.ativo}`}
                disabled={botaoDescontoDesabilitado}
              >
                <HtmlTooltip title="Editar desconto">
                  <Edit color={botaoDescontoDesabilitado ? "disabled" : ""}  />
                </HtmlTooltip>
              </Button>
              :
              <Button
                disabled={carregandoEditarDesconto}
                key={key}
                className={`text-white ${classes.botao} ${classes.ativo}`}
                onClick={() => { onClickSalvarDesconto() }}
              >
                <HtmlTooltip title="Salvar desconto" placement="right">
                  {carregandoEditarDesconto ? <HourglassEmpty /> : <Check />}
                </HtmlTooltip>
              </Button>
            :
            null
        }
      </Grid>
    </Grid>
  );
};

ColunaInputDescontos.propTypes = {
  onClick: PropTypes.oneOfType([PropTypes.func]).isRequired,
  parametros: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
    .isRequired
};

export default ColunaInputDescontos;
