import { makeStyles } from '@mui/styles';
import theme from 'themes';

export const useStyles = makeStyles({
  container: {
    margin: "0",
    width: "100%",
    "& .MuiInputBase-root": {
      height: "46px"
    },
    "& .MuiFormLabel-root": {
      lineHeight: "1"
    }
  },
  selectSiglaCCEE: {
    "& .MuiInputBase-root": {
      padding: "0 10px"
    }
  },
  button: {
    fontSize: "18px"
  },
  campoHora: {
    width: "100%"
  },
  containerSalvar: {
    margin: "0 0 16px"
  },
  cardCadastro: {
    backgroundColor: theme.color.bgColorCardCadastro
  }
});
