// Proptypes
import PropTypes from "prop-types";

// Componentes
import { Button, Card, Divider, Grid } from "@mui/material";

// Hooks
import React, { useMemo } from "react";
import { useStyles } from "./style";

// DTOs
import PlanoSimuladoDto from "../../../../../Cotacao/Planos/Dtos/planoSimuladoDto";
import PlanoConfiguracaoDto from "../../../../../Cotacao/Planos/Dtos/planoConfiguracaoDto";

const CardPlano = ({
  planoConfiguracao,
  dadosCards,
  onEscolherPlano,
  ultimoItem
}) => {
  const { icone: Icone } = planoConfiguracao;

  const classes = useStyles({
    planoCor: planoConfiguracao?.corCard,
    corTexto: planoConfiguracao?.corTexto
  });

  const aplicarMascara = (valor) => {
    return valor.toLocaleString("pt-br", {
      style: "currency",
      currency: "BRL",
      maximumFractionDigits: 0,
      minimumFractionDigits: 0
    });
  };

  const economiaMensal = useMemo(() => {
    if (!dadosCards) return "";

    return aplicarMascara(Math.round(dadosCards.economiaMensal));
  }, dadosCards);

  const economiaAnual = useMemo(() => {
    if (!dadosCards) return "";

    return aplicarMascara(Math.round(dadosCards.economiaAnual));
  }, dadosCards);

  const economiaTotal = useMemo(() => {
    if (!dadosCards) return "";

    return aplicarMascara(Math.round(dadosCards.economiaTotal));
  }, dadosCards);

  const reducaoCustos = useMemo(() => {
    if (!dadosCards) return "";

    const porcentagemString = dadosCards.reducaoCustos
      .toFixed(2)
      .toString()
      .replace(".", ",")
      .toLocaleString("pt-br");

    return `${porcentagemString}%`;
  }, dadosCards);

  const onClickEscolher = () => {
    onEscolherPlano(dadosCards);
  };

  return (
    <Grid
      container
      className={`${classes.containerPadding} ${
        ultimoItem ? "ultimoItem" : ""
      }`}
    >
      <Grid item xs={12}>
        <Card>
          <Grid container>
            <Grid item xs={12}>
              <Card className={classes.containerColorido}>
                <Grid container justifyContent="center">
                  <Grid item xl={2} lg={4} md={5} sm={3} xs={12}>
                    <Icone height={64} width={64} />
                  </Grid>
                  <Grid item xl={10} lg={8} md={7} sm={9} xs={12}>
                    <Grid container>
                      <Grid item xs={12}>
                        <span className={classes.labelPlano}>Plano</span>
                      </Grid>
                      <Grid item xs={12}>
                        <span className={classes.planoNome}>
                          {planoConfiguracao.nomePlano}
                        </span>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </Grid>
          <Grid container className={classes.containerBranco}>
            <Grid item xs={12} className={classes.containerInformacoes}>
              <Grid container>
                <Grid item xs={12}>
                  <span className={classes.labelInformacoes}>Período</span>
                </Grid>
                <Grid item xs={12}>
                  <span className={classes.valorInformacoes}>
                    {dadosCards.periodo} MESES
                  </span>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} className={classes.containerInformacoes}>
              <Grid container>
                <Grid item xs={12}>
                  <span className={classes.labelInformacoes}>
                    Flexibilidade
                  </span>
                </Grid>
                <Grid item xs={12}>
                  <span className={classes.valorInformacoes}>
                    {dadosCards.flexibilidade}
                  </span>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} className={classes.containerInformacoes}>
              <Grid container>
                <Grid item xs={12}>
                  <span className={classes.labelInformacoes}>
                    Economia mensal *
                  </span>
                </Grid>
                <Grid item xs={12}>
                  <span className={classes.valorInformacoes}>
                    {economiaMensal}
                  </span>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} className={classes.containerInformacoes}>
              <Grid container>
                <Grid item xs={12}>
                  <span className={classes.labelInformacoes}>
                    Economia anual *
                  </span>
                </Grid>
                <Grid item xs={12}>
                  <span className={classes.valorInformacoes}>
                    {economiaAnual}
                  </span>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} className={classes.containerInformacoes}>
              <Grid container>
                <Grid item xs={12}>
                  <span className={classes.labelInformacoes}>
                    Economia total *
                  </span>
                </Grid>
                <Grid item xs={12}>
                  <span className={classes.valorInformacoes}>
                    {economiaTotal}
                  </span>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} className={classes.containerInformacoes}>
              <Grid container>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} className={classes.containerInformacoes}>
              <Grid container>
                <Grid item xs={12}>
                  <span className={classes.labelInformacoes}>
                    Redução de custos
                  </span>
                </Grid>
                <Grid item xs={12}>
                  <span className={classes.valorReducaoCustos}>
                    {reducaoCustos}
                  </span>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} className={classes.containerInformacoes}>
              <Grid container>
                <Grid item xs={12}>
                  <Button
                    className={`${classes.botaoEscolher} ${classes.labelInformacoes}`}
                    onClick={() => onClickEscolher()}
                  >
                    Escolher
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
};

CardPlano.propTypes = {
  planoConfiguracao: PropTypes.oneOfType([
    PropTypes.instanceOf(new PlanoConfiguracaoDto())
  ]).isRequired,
  dadosCards: PropTypes.oneOfType([
    PropTypes.instanceOf(new PlanoSimuladoDto())
  ]).isRequired,
  onEscolherPlano: PropTypes.oneOfType([PropTypes.func]).isRequired,
  ultimoItem: PropTypes.oneOfType([PropTypes.any]).isRequired
};

export default CardPlano;
