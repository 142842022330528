import React, { useEffect, useState } from "react";
import moment from "moment";

// Componentes
import { Grid } from "@mui/material";
import DataTable from "react-data-table-component";
import colunas from "../../Constantes/Colunas";
import InputData from "../../../../componentes/inputTextoData";
import TabelaSemDados from "../TabelaSemDados";

// Styles
import { useStyles, customStyles } from "./style";

const TabelaPrecoConstantes = ({ linhas }) => {
  const classes = useStyles();
  const [linhasFormatadas, setLinhasFormatadas] = useState([]);

  const verificarData = (data) => {
    const dataFimVigencia = new Date(data);
    const dataAtual = new Date();
    return dataFimVigencia > dataAtual;
  };

  const formatarPreco = (media, prefixo) => {
    if (typeof media === "boolean" || typeof media === "number") {
      return prefixo + String(media.toFixed(2)).replace(".", ",");
    }

    return media;
  };

  const formatarLinhas = (dados) => {
    let dadosFormatados = [];

    if (dados?.length > 0) {
      const precosOrdenados = dados.sort((a, b) =>
        moment(a.dataFimVigencia).format("DD/MM/yyyy HH:mm:ss") <
        moment(b.dataFimVigencia).format("DD/MM/yyyy HH:mm:ss")
          ? 1
          : moment(a.dataFimVigencia).format("DD/MM/yyyy HH:mm:ss") >
            moment(b.dataFimVigencia).format("DD/MM/yyyy HH:mm:ss")
          ? -1
          : 0
      );

      dadosFormatados = precosOrdenados.map((item, index) => {
        return {
          dataInicioVigencia: (
            <div className={classes.tableCellBranco}>
              <InputData
                type="text"
                id={`dataInicioVigencia${index}`}
                name={`dataInicioVigencia${index}`}
                customValue={item.dataInicioVigencia ? new Date(item.dataInicioVigencia) : null}
                className={classes}
                disabled
              />
            </div>
          ),
          dataFimVigencia: (
            <div className={classes.tableCellBranco}>
              <InputData
                type="text"
                id={`dataFimVigencia${index}`}
                name={`dataFimVigencia${index}`}
                customValue={item.dataFimVigencia ? new Date(item.dataFimVigencia) : null}
                className={classes}
                disabled
              />
            </div>
          ),
          contribuicaoAssociativa: (
            <div
              className={`${
                verificarData(item.dataFimVigencia)
                  ? classes.tableCellAzul
                  : classes.tableCellCinza
              } ${classes.margin} ${classes.tableCellDestaque} ${
                classes.bordaEsquerda
              }`}
            >
              {formatarPreco(item.contribuicaoAssociativa, "R$/Mwh ")}
            </div>
          ),
          cosip: (
            <div
              className={`${
                verificarData(item.dataFimVigencia)
                  ? classes.tableCellAzul
                  : classes.tableCellCinza
              } ${classes.tableCellDestaque}`}
            >
              {formatarPreco(item.cosip, "R$ ")}
            </div>
          ),
          custoConexao: (
            <div
              className={`${
                verificarData(item.dataFimVigencia)
                  ? classes.tableCellAzul
                  : classes.tableCellCinza
              } ${classes.tableCellDestaque}`}
            >
              {formatarPreco(item.custoConexao, "R$/Mwh ")}
            </div>
          ),
          encargos: (
            <div
              className={`${
                verificarData(item.dataFimVigencia)
                  ? classes.tableCellAzul
                  : classes.tableCellCinza
              } ${classes.tableCellDestaque}`}
            >
              {formatarPreco(item.encargos, "R$/Mwh ")}
            </div>
          ),
          custosAdicionais: (
            <div
              className={`${
                verificarData(item.dataFimVigencia)
                  ? classes.tableCellAzul
                  : classes.tableCellCinza
              } ${classes.tableCellDestaque}`}
            >
              {formatarPreco(item.custosAdicionais, "R$/Mwh ")}
            </div>
          ),
          margemLuz: (
            <div
              className={`${
                verificarData(item.dataFimVigencia)
                  ? classes.tableCellAzul
                  : classes.tableCellCinza
              } ${classes.tableCellDestaque} ${classes.bordaDireita}`}
            >
              {formatarPreco(item.margemLuz, "R$/Mwh ")}
            </div>
          )
        };
      });
    }
    setLinhasFormatadas(dadosFormatados);
  };

  useEffect(() => {
    formatarLinhas(linhas);
  }, [linhas]);

  return (
    <Grid container>
      <DataTable
        columns={colunas}
        data={linhasFormatadas}
        theme="DeltaEnergia"
        customStyles={customStyles}
        noDataComponent={<TabelaSemDados />}
        noHeader
        noTableHead
        compact
        responsive={false}
      />
    </Grid>
  );
};

export default TabelaPrecoConstantes;
