// Componentes material UI
import { Card, Grid } from "@mui/material";

// React e Hooks
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

// React form Hook
import { useForm } from "react-hook-form";

// Moment para controle e validação das datas
import moment from "moment";

// Compoenentes
import BotaoRetornarListagem from "../../../componentes/botaoRetornarListagem";
import FormValidation from "../../../componentes/formValidation";
import MaterialInputTexto from "../../../componentes/inputTexto/materialInput";
import InputData from "../../../componentes/inputTextoData";
import Loader from "../../../componentes/loader";
import SelectArredondado from "../../../componentes/selectArredondado";
import Botao from "../../../componentes/botao";
import MaterialSwitch from "../../../componentes/switch";
import ModalInformacao from "../../../componentes/modalVerificacao";
import { alertaExibir } from "../../../global/redux/modulos/alertas/actions";

// Rotas
import { RotasDTO } from "../../../global/rotas/rotasUrlDto";

// Dtos
import PerfilAgenteDto from "./dto/perfilAgenteDto";

// Helper
import PerfilAgenteHelper from "./helper";

// Hook style
import { useStyles } from "./style";
import { Base } from "../../../componentes/cores";
import { store } from "../../../global/redux";
import { desabilitarFiltroLateral } from "../../../global/redux/modulos/usuario/actions";
import MaterialInputMascara from "../../../componentes/inputTextoMascara";
import SelectAutocomplete from "componentes/selectAutocomplete";

function PerfilAgente() {
  const classes = useStyles();
  const history = useHistory();
  const { id: idUrl } = useParams();

  // Inicialização do Controlador de formulario
  const { register, errors, handleSubmit, setValue } = useForm({
    reValidateMode: "onSubmit"
  });

  // Id do Registro, será utilizado para identificar se esta em modo de edição
  const [id, setId] = useState(0);

  // Estado default dos valores, geralmente utilizado na edição
  const [estadoEdicao, setEstadoEdicao] = useState(new PerfilAgenteDto());

  // Estado de controle do Loading geral da tela
  const [carregando, setCarregando] = useState(false);

  // Estado de controle do switch de ativo e inativo
  const [ativo, setAtivo] = useState(true);

  // Estado de controle de sigla Perfil
  const [siglaPerfil, setSiglaPerfil] = useState();
  const [codigoPerfil, setCodigoPerfil] = useState();

  // Estado de controle modal sigla cadastrada
  const [
    exibirModalSiglaCadastrada,
    setExibirModalSiglaCadastrada
  ] = useState();

  // Estado de carregamento do Loading
  const [carregandoSiglaAgente, setCarregandoSiglaAgente] = useState(false);
  const [carregandoSubmercado, setCarregandoSubmercado] = useState(false);
  const [carregandoClasse, setCarregandoClasse] = useState(false);
  const [carregandoPossuiVarejista, setCarregandoPossuiVarejista] = useState(
    false
  );

  // Estados para controle de dados do select de sigla agente
  const [siglaAgenteSelecionada, setSiglaAgenteSelecionada] = useState();
  const [siglaAgenteOnChange, setSiglaAgenteOnChange] = useState();
  const [listaSiglas, setListaSiglas] = useState([]);

  const handleAutoComplete = (value) => {
    setSiglaAgenteOnChange(value);
  };

  //Unidade Consumidora
  const [carregandoUnidade, setCarregandoUnidade] = useState(false);
  const [unidadeSelecionada,setUnidadeSelecionada] = useState();
  const [unidadeOnChange,setUnidadeOnChange] = useState();
  const [listaUnidades, setListaUnidades] = useState();

  const handleAutoCompleteUnidade = (value) => {    
    setUnidadeOnChange(value);
  };

  // Estados para controle de dados do select de submercado
  const [submercadoSelecionado, setSubmercadoSelecionado] = useState();
  const [listaSubmercado, setListaSubmercado] = useState([]);

  // Estados para controle de dados do select de classes
  const [classeSelecionada, setClasseSelecionada] = useState();
  const [listaClasse, setListaClasse] = useState([]);

  // Estados para controle de dados do select de varejista
  const [
    possuiVarejistaSelecionado,
    setPossuiVarejistaSelecionado
  ] = useState();
  const [listaPossuiVarejista, setListaPossuiVarejista] = useState([]);

  // Estado de datas
  const [dataInicial, setDataInicial] = useState(null);
  const [dataFinal, setDataFinal] = useState(null);

  // useMemo para obter Id do agente selecionado
  const idAgente = useMemo(() => {
    if (!siglaAgenteSelecionada) return null;

    if (!listaSiglas) return null;

    return siglaAgenteSelecionada
      ? siglaAgenteSelecionada
      : listaSiglas.find((x) => x.id === siglaAgenteSelecionada)?.id ?? null;
  }, [siglaAgenteSelecionada, listaSiglas]);

  // useMemo para controlar se é ou não edição
  const ehEdicao = useMemo(() => {
    return id && id > 0;
  }, [id]);

    // useMemo para obter Id do unidade selecionado
    const idUnidade = useMemo(() => {
      if (!unidadeSelecionada) return null;
  
      if (!listaUnidades) return null;
  
      return unidadeSelecionada
        ? unidadeSelecionada
        : listaUnidades.find((x) => x.id === unidadeSelecionada)?.id ?? null;
    }, [unidadeSelecionada, listaUnidades]);
  

  // Use Memo para controlar os estados de erro dos combos
  const siglaAgenteNaoPreenchida = useMemo(
    () => !siglaAgenteSelecionada && "Campo Sigla é Obrigátorio!",
    [siglaAgenteSelecionada]
  );

  const unidadeNaoPreenchida = useMemo(
    () => !unidadeSelecionada && "Campo Unidade consumidora é Obrigátorio!",
    [unidadeSelecionada]
  );

  const submercadoNaoPreenchido = useMemo(
    () => !submercadoSelecionado && "Campo submercado é obrigátorio!",
    [submercadoSelecionado]
  );

  const classeNaoPreenchida = useMemo(
    () => !classeSelecionada && "Campo classe do perfil é obrigátorio!",
    [classeSelecionada]
  );

  const possuiVarejistaNaoPreenchido = useMemo(
    () =>
      !possuiVarejistaSelecionado && "Campo perfil varejista é obrigátorio!",
    [possuiVarejistaSelecionado]
  );

  // Metodos comparativos de data
  const comparaSeDataMenor = (menor, maior) => {
    return moment(menor) < moment(maior);
  };

  const comparaSeDataMaior = (menor, maior) => {
    return moment(menor) > moment(maior);
  };

  // Metodos de controle da mudança de data
  const onChangePeriodoInicial = (data) => {
    if (
      !moment(data, "dd/MM/yyy").isValid() ||
      comparaSeDataMaior(data, dataFinal)
    )
      setDataInicial(null);
    else setDataInicial(data);
  };

  const onChangePeriodoFinal = (data) => {
    if (
      !moment(data, "dd/MM/yyy").isValid() ||
      comparaSeDataMenor(data, dataInicial)
    )
      setDataFinal(null);
    else setDataFinal(data);
  };

  // Metodo para construir a lista de Possui Varejista
  const obterPossuiVarejista = () => {
    setCarregandoPossuiVarejista(true);

    const lista = PerfilAgenteHelper.listarPossuiComercioVarejista();

    setListaPossuiVarejista(lista);

    setCarregandoPossuiVarejista(false);
  };

  // Metodo para a busca de submercados
  const obterSubmercados = async () => {
    setCarregandoSubmercado(true);

    const submercados = await PerfilAgenteHelper.listarSubmercadosHelper();

    if (!submercados.sucesso)
      PerfilAgenteHelper.exibirErro(submercados.mensagem);
    else setListaSubmercado(submercados.data);

    setCarregandoSubmercado(false);
  };

  // Metodo para obter lista de agentes
  const obterListaAgentes = async (siglaAgenteOnChange) => {
    setCarregandoSiglaAgente(true);

    const agentes = await PerfilAgenteHelper.listarAgentes(siglaAgenteOnChange);

    if (!agentes.sucesso) PerfilAgenteHelper.exibirErro(agentes.mensagem);
    else setListaSiglas(agentes.data);

    setCarregandoSiglaAgente(false);
  };

 // Metodo para obter lista de unidades
 const obterListaUnidades = async (unidadeOnChange) => {
  setCarregandoUnidade(true);

  const result = await PerfilAgenteHelper.listarUnidades(unidadeOnChange);

  if (!result.sucesso) PerfilAgenteHelper.exibirErro(result.mensagem);
  else setListaUnidades(result.data);

  setCarregandoUnidade(false);
};

  useEffect(() => {
    obterListaAgentes(siglaAgenteOnChange);
  }, [siglaAgenteOnChange]);

  useEffect(() => {
    obterListaUnidades(unidadeOnChange);
  }, [unidadeOnChange]);

  // Metodo para obter lista de classes
  const obterListaClasses = async () => {
    setCarregandoClasse(true);

    const listaClasses = await PerfilAgenteHelper.listarClassesPerfil();

    if (!listaClasses.sucesso)
      PerfilAgenteHelper.exibirErro(listaClasses.mensagem);
    else setListaClasse(listaClasses.data);

    setCarregandoClasse(false);
  };

  // Metodo que verifica no onBlur da sigla de perfil, se sigla já esta cadastrada
  const onBlurPerfil = async (sigla) => {
    if (!sigla) return;

    setCarregando(true);
    setSiglaPerfil(sigla);

    const siglaCadastrada = await PerfilAgenteHelper.verificarSeSiglaCadastrada(
      sigla
    );

    setExibirModalSiglaCadastrada(siglaCadastrada);

    setCarregando(false);
  };

  // Metodo para a busca de combos da tela
  const buscarCombos = useCallback(() => {
    obterPossuiVarejista();
    obterSubmercados();
    obterListaAgentes();
    obterListaClasses();
    obterListaUnidades();
  }, []);

  // Função chamada no submit do formulario
  const onSubmit = async (dados) => {
    
    const dto = new PerfilAgenteDto(
      id,
      idAgente,
      siglaAgenteSelecionada,
      dados.SiglaPerfilParticipanteMercado,
      Number(dados.PercentualEnergia ? dados.PercentualEnergia.replace("%", "") : 0),
      dados.DescricaoSituacaoPerfilParticipanteMercado,
      submercadoSelecionado,
      dados.DescricaoTipoFonteEnergia,
      dados.DescricaoSubTipoFonteEnergia,
      classeSelecionada,
      possuiVarejistaSelecionado,
      moment(dados.InicioPeriodo, "DD/MM/YYYY").toDate(),
      moment(dados.FimPeriodo, "DD/MM/YYYY").toDate(),
      ativo,
      unidadeSelecionada,
      dados.CodigoPerfil
    );

    const resultado = await PerfilAgenteHelper.salvarPerfilAgenteCCEE(dto);

    console.log("resultado",resultado)
    if (!resultado.sucesso) {
      PerfilAgenteHelper.exibirErro(resultado.mensagem);
      return;
    }

    PerfilAgenteHelper.exibirSucesso(resultado.mensagem);
    history.push(RotasDTO.Agentes);
  };

  // UseEffect executado quando a tela e renderizada pela primeira vez
  useEffect(() => {
    buscarCombos();
  }, [buscarCombos]);

  // UseEffect executado quando a tela é renderizada pela primeira vez
  // E executado o retorno quando a tela é destruida
  useEffect(() => {
    store.dispatch(desabilitarFiltroLateral(true));

    return () => store.dispatch(desabilitarFiltroLateral(false));
  }, [desabilitarFiltroLateral]);

  const obterPorIdEdicao = useCallback(async () => {
    if (!idUrl || idUrl <= 0) return;

    setCarregando(true);

    const retorno = await PerfilAgenteHelper.obterPorId(idUrl);

    console.log("retorno", retorno)
    if (!retorno.sucesso) {
      store.dispatch(
        alertaExibir({
          tipo: "danger",
          mensagem: retorno.mensagem
        })
      );
      return;
    }
    
    setEstadoEdicao(new PerfilAgenteDto(
      retorno.data.id,
      retorno.data.agenteCCEEId,
      retorno.data.siglaAgenteCCEE,
      retorno.data.siglaPerfilParticipanteMercado,
      retorno.data.percentualEnergia,
      retorno.data.descricaoSituacaoPerfilParticipanteMercado,
      retorno.data.subMercadoId,

      retorno.data.descricaoTipoFonteEnergia,
      retorno.data.codigoSubtipoFonteEnergia,
      retorno.data.classePerfilId,
      retorno.data.identificadorComercioVarejista,
      retorno.data.dataInicioVigenciaPerfilParticipante,
      retorno.data.dataFimVigenciaPefilParticipante,
      retorno.data.situacao,
      retorno.data.unidadeConsumidoraId,
      retorno.data.codigoPerfilParticipanteMercado
    ));

    setId(retorno.data.id)
    setSiglaAgenteSelecionada(retorno.data.agenteCCEEId)
    setSiglaAgenteOnChange(retorno.data.siglaAgenteCCEE);
    setSubmercadoSelecionado(retorno.data.subMercadoId)
    setPossuiVarejistaSelecionado(retorno.data.identificadorComercioVarejista)
    setClasseSelecionada(retorno.data.classePerfilId)
    setSiglaPerfil(retorno.data.siglaPerfilParticipanteMercado)
    setDataInicial(retorno.data.dataInicioVigenciaPerfilParticipante)
    setDataFinal(retorno.data.dataFimVigenciaPefilParticipante)
    setAtivo(retorno.data.situacao == "AT" ? true : false)
    
    setUnidadeSelecionada(retorno.data.unidadeConsumidoraId.toString());
    setCodigoPerfil(retorno.data.codigoPerfilParticipanteMercado);
    
    
    setCarregando(false);
  }, [idUrl]);

  useEffect(() => {
    obterPorIdEdicao();
  }, [obterPorIdEdicao]);

  return <>
    <ModalInformacao
      exibir={exibirModalSiglaCadastrada}
      titulo="Atenção"
      mensagem="Sigla já cadastrada. Não é possivel cadastrar um novo perfil com esta sigla?"
      labelBotao="OK"
      onClose={() => {
        setValue("SiglaPerfilParticipanteMercado", "");
        setExibirModalSiglaCadastrada(false);
        setSiglaPerfil("");
      }}
    />
    <Loader loading={carregando}>
      <FormValidation onSubmit={onSubmit} handleSubmit={handleSubmit}>
        <Card className={classes.cardCadastro}>
          <Grid container spacing={4} className={classes.container}>
            <Grid item xs={6} className="font-weight-bold">
              {`${ehEdicao ? "Editar" : "Novo"} Perfil de Agente`}
            </Grid>
            <BotaoRetornarListagem
              justify="flex-end"
              urlListagem={RotasDTO.Agentes}
            />
            <Grid
              item
              xl={3}
              lg={4}
              md={4}
              sm={6}
              xs={12}
              className={classes.selectSiglaCCEE}
            >                               
              <Loader loading={carregandoSiglaAgente}>
              <SelectAutocomplete
                  disableOpenOnFocus
                  id="siglaAgenteSelecionada"
                  name="siglaAgenteSelecionada"
                  label="Sigla agente CCEE"
                  options={listaSiglas}
                  getOptionLabel={(option) => option?.id ?? ""}
                  value={siglaAgenteOnChange}
                  defaultValue={siglaAgenteOnChange}
                  onChange={(e, item) => {
                    setSiglaAgenteSelecionada(item?.codigoAgente ?? 0);
                  }}
                  onInputChange={(e, item) => {
                    handleAutoComplete(item);
                  }}
                  allowClear
                  ref={register(
                    { name: "siglaAgenteSelecionada" },
                    {
                      required: siglaAgenteNaoPreenchida
                    }
                  )}
                  disabled={ehEdicao ? true : false}
                  error={errors}
                  valueName="id"
                />
              </Loader>
            </Grid>
            <Grid
              item
              xl={3}
              lg={4}
              md={4}
              sm={6}
              xs={12}
              className={classes.selectSiglaCCEE}
            >   
              <Loader loading={carregandoUnidade}>
                <SelectAutocomplete
                  disableOpenOnFocus
                  id="unidadeSelecionada"
                  name="unidadeSelecionada"
                  label="Unidade Consumidora"
                  options={listaUnidades}
                  getOptionLabel={(option) => option?.descricao ?? ""}
                  value={unidadeSelecionada}
                  defaultValue={unidadeSelecionada}
                  onChange={(e, item) => {               
                    setUnidadeSelecionada(item?.id.toString() ?? "0");
                  }}
                  allowClear
                  error={errors}
                  valueName="descricao"
                />
              </Loader>
            </Grid>
            <Grid item xl={3} lg={4} md={4} sm={6} xs={12}>
              <MaterialInputTexto
                type="text"
                id="CodigoPerfil"
                name="CodigoPerfil"
                label="Código do perfil"
                permiteValorBranco
                defaultValue={
                  estadoEdicao.CodigoPerfil || codigoPerfil
                }
                renderIconShowHide={false}
                // onBlur={(event) => onBlurPerfil(event.target.value)}
                ref={register({
                  required: "Campo Sigla Perfil é obrigatório!",
                  maxLength: {
                    value: 50,
                    message: "Quantidade máxima de 50 caracteres!"
                  }
                })}
                errors={errors}
              />
            </Grid>

            <Grid item xl={3} lg={4} md={4} sm={6} xs={12}>
              <MaterialInputTexto
                type="text"
                id="SiglaPerfilParticipanteMercado"
                name="SiglaPerfilParticipanteMercado"
                label="Sigla do perfil"
                permiteValorBranco
                defaultValue={
                  estadoEdicao.SiglaPerfilParticipanteMercado || siglaPerfil
                }
                renderIconShowHide={false}
                onBlur={(event) => onBlurPerfil(event.target.value)}
                ref={register({
                  required: "Campo Sigla Perfil é obrigatório!",
                  maxLength: {
                    value: 50,
                    message: "Quantidade máxima de 50 caracteres!"
                  }
                })}
                errors={errors}
              />
            </Grid>
            {/* <Grid item xl={3} lg={4} md={4} sm={6} xs={12}>
              <MaterialInputMascara
                type="text"
                id="PercentualEnergia"
                name="PercentualEnergia"
                sufixo="%"
                label="Percentual de energia"
                defaultValue={estadoEdicao.PercentualEnergia}
                renderIconShowHide={false}
                ref={register({
                  required: "Campo Percentual de Energia é obrigatório!",
                  maxLength: {
                    value: 5,
                    message: "Quantidade máxima de 5 caracteres!"
                  },
                  validate: (valor) =>
                    Number(valor?.replace("%", "")) ||
                    "Percentual de energia inválido"
                })}
                errors={errors}
              />
            </Grid> */}
            <Grid item xl={3} lg={4} md={4} sm={6} xs={12}>
              <MaterialInputTexto
                type="text"
                id="DescricaoSituacaoPerfilParticipanteMercado"
                name="DescricaoSituacaoPerfilParticipanteMercado"
                label="Desc. situação do perfil"
                defaultValue={
                  estadoEdicao.DescricaoSituacaoPerfilParticipanteMercado
                }
                renderIconShowHide={false}
                ref={register({
                  required:
                    "Campo Descrição da Situação do perfil é obrigatório!",
                  maxLength: {
                    value: 50,
                    message: "Quantidade máxima de 50 caracteres!"
                  }
                })}
                errors={errors}
              />
            </Grid>
            <Grid item xl={3} lg={4} md={4} sm={6} xs={12}>
              <Loader loading={carregandoSubmercado}>
                <SelectArredondado
                  id="CodigoSubmercado"
                  name="CodigoSubmercado"
                  valueKey="id"
                  valueName="descricao"
                  dataSource={listaSubmercado}
                  label="Submercado"
                  value={submercadoSelecionado}
                  onChange={(event) => {
                    setSubmercadoSelecionado(Number(event.target.value));
                  }}
                  placeholder="Submercado"
                  allowClear
                  ref={register(
                    { name: "CodigoSubmercado" },
                    {
                      required: submercadoNaoPreenchido
                    }
                  )}
                  errors={errors}
                />
              </Loader>
            </Grid>
            <Grid item xl={3} lg={4} md={4} sm={6} xs={12}>
              <MaterialInputTexto
                type="text"
                id="DescricaoTipoFonteEnergia"
                name="DescricaoTipoFonteEnergia"
                label="Desc. do tipo energia"
                defaultValue={
                  estadoEdicao.DescricaoTipoFonteEnergia
                }
                renderIconShowHide={false}
                ref={register({
                  required:
                    "Campo Descrição do Tipo de Energia é obrigatório!",
                  maxLength: {
                    value: 50,
                    message: "Quantidade máxima de 50 caracteres!"
                  }
                })}
                errors={errors}
              />
            </Grid>
            {/* <Grid item xl={3} lg={4} md={4} sm={6} xs={12}>
              <MaterialInputTexto
                type="text"
                id="DescricaoSubTipoFonteEnergia"
                name="DescricaoSubTipoFonteEnergia"
                label="Desc. Subtipo de energia"
                defaultValue={
                  estadoEdicao.DescricaoSubTipoFonteEnergia
                }
                renderIconShowHide={false}
                ref={register({
                  required:
                    "Campo descrição do subtipo de energia é obrigatório!",
                  maxLength: {
                    value: 50,
                    message: "Quantidade máxima de 50 caracteres!"
                  }
                })}
                errors={errors}
              />
            </Grid> */}
            <Grid item xl={3} lg={4} md={4} sm={6} xs={12}>
              <Loader loading={carregandoClasse}>
                <SelectArredondado
                  id="ClassePerfil"
                  name="ClassePerfil"
                  valueKey="id"
                  valueName="descricao"
                  dataSource={listaClasse}
                  label="Classe perfil"
                  value={classeSelecionada}
                  onChange={(event) => {
                    setClasseSelecionada(Number(event.target.value));
                  }}
                  placeholder="Classe perfil"
                  allowClear
                  ref={register(
                    { name: "ClassePerfil" },
                    {
                      required: classeNaoPreenchida
                    }
                  )}
                  errors={errors}
                />
              </Loader>
            </Grid>
            <Grid item xl={3} lg={4} md={4} sm={6} xs={12}>
              <Loader loading={carregandoPossuiVarejista}>
                <SelectArredondado
                  id="PossuiVarejista"
                  name="PossuiVarejista"
                  valueKey="id"
                  valueName="descricao"
                  dataSource={listaPossuiVarejista}
                  label="Perfil Varejista?"
                  value={possuiVarejistaSelecionado}
                  onChange={(event) => {
                    setPossuiVarejistaSelecionado(event.target.value);
                  }}
                  placeholder="Perfil Varejista?"
                  allowClear
                  ref={register(
                    { name: "PossuiVarejista" },
                    {
                      required: possuiVarejistaNaoPreenchido
                    }
                  )}
                  errors={errors}
                />
              </Loader>
            </Grid>
            <Grid item xl={3} lg={4} md={4} sm={6} xs={12}>
              <InputData
                type="text"
                id="InicioPeriodo"
                name="InicioPeriodo"
                label="Início do período"
                renderIconShowHide={false}
                className={classes}
                customValue={dataInicial}
                onChange={(data) => {
                  onChangePeriodoInicial(data);
                }}
                ref={register({
                  required: "O campo Início Periodo é obrigatório!",
                  validate: (value) =>
                    (moment(value, "dd/MM/yyyy").isValid() &&
                      moment(value, "dd/MM/yyyy") >
                        moment("01/01/1900", "dd/MM/yyyy")) ||
                    "Data de Início Periodo inválida!"
                })}
                errors={errors}
              />
            </Grid>
            <Grid item xl={3} lg={4} md={4} sm={6} xs={12}>
              <InputData
                type="text"
                id="FimPeriodo"
                name="FimPeriodo"
                label="Fim do período"
                renderIconShowHide={false}
                format="dd/MM/yyyy"
                className={classes}
                minDate={dataInicial}
                customValue={dataFinal}
                onChange={(data) => onChangePeriodoFinal(data)}
                ref={register({
                  validate: (value) =>
                    !value ||
                    (moment(value, "DD/MM/YYYY").isValid() &&
                      moment(value, "DD/MM/YYYY") >
                        moment("01/01/1900", "DD/MM/YYYY")) ||
                    "Data de Fim Periodo inválida!"
                })}
                errors={errors}
              />
            </Grid>
            {!!ehEdicao && (
              <Grid
                item
                xl={3}
                lg={4}
                md={4}
                sm={6}
                xs={12}
                justifyContent="flex-start"
              >
                <Grid container justifyContent="flex-start">
                  <Grid item xs={12} justifyContent="flex-start">
                    <MaterialSwitch
                      label="Ativo"
                      labelPlacement="top"
                      id="Ativo"
                      justify="flex-start"
                      name="Ativo"
                      checked={ativo}
                      onChange={(param) => setAtivo(param)}
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}
            <Grid item xs={12}>
              <Grid container>
                <Grid
                  item
                  xl={3}
                  lg={4}
                  md={4}
                  sm={6}
                  xs={12}
                  className={classes.containerSalvar}
                >
                  <Botao
                    type="submit"
                    label="Salvar"
                    color={Base.Mango}
                    className={classes.button}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </FormValidation>
    </Loader>
  </>;
}

export default PerfilAgente;
