import React from "react";
import PropTypes from "prop-types";

import { BotaoFiltroEstilo } from "./style";
import { Base } from "../../cores";
import theme from 'themes';

const BotaoFiltro = React.forwardRef((props, ref) => {
  const { disabled, id, icon, label, color, style, className, onClick } = props;

  const onHandleFiltrar = () => {
    onClick();
  };

  const classNameDesc = `btn btn-lg btn-primary w-100 position-relative d-flex justify-content-center align-items-center ${className}`;

  return (
    <div>
      <BotaoFiltroEstilo
        type="button"
        className={classNameDesc}
        id={id}
        disabled={disabled}
        ref={ref}
        color={color}
        onClick={() => onHandleFiltrar()}
        style={{ ...style }}
      >
        {label}
        {icon && icon}
      </BotaoFiltroEstilo>
    </div>
  );
});

BotaoFiltro.propTypes = {
  disabled: PropTypes.bool,
  id: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.any]),
  label: PropTypes.string,
  color: PropTypes.string,
  style: PropTypes.oneOfType([PropTypes.any]),
  className: PropTypes.string,
  onClick: PropTypes.oneOfType([PropTypes.func])
};

BotaoFiltro.defaultProps = {
  disabled: false,
  id: "",
  icon: false,
  label: "",
  color: `${theme.color.secondaryBorderColor} !important`,
  style: {},
  className: "",
  onClick: () => {}
};

export default BotaoFiltro;
