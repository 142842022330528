import React from "react";
import PropTypes from "prop-types";
import { generate } from "shortid";

import { Grid, Button } from "@mui/material";
import { Edit } from "@mui/icons-material";

import { HtmlTooltip } from "paginas/Graficos/ConsumoAtual/style";
import { useHistory } from "react-router-dom";
import { RotasDTO } from "../../../../global/rotas/rotasUrlDto";
import { useStyles } from "./style";

const ColunaBaixar = ({ onClick, parametros }) => {
  const classes = useStyles();
  const history = useHistory();

  const onClickEditar = () => {
    history.push(
      `${RotasDTO.AcompanhamentoCliente}/${
        parametros.unidadeId
      }/${encodeURIComponent(parametros.clienteNome)}`
    );
  };

  return (
    <Grid container spacing={0} className={classes.fundoCelula}>
      <Grid item xs={12}>
        <Button
          key={generate()}
          onClick={onClickEditar}
          className={`text-white ${classes.botao}`}
        >
          <HtmlTooltip title="Baixar">
            <Edit />
          </HtmlTooltip>
        </Button>
      </Grid>
    </Grid>
  );
};

ColunaBaixar.propTypes = {
  onClick: PropTypes.oneOfType([PropTypes.func]).isRequired,
  parametros: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
    .isRequired
};

export default ColunaBaixar;
