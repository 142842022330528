import { makeStyles } from '@mui/styles';
import { Base } from "../../../componentes/cores";
import theme from 'themes';

export const useStyles = makeStyles(() => ({
  titulo: {
    fontWeight: "bold"
  },
  container: {
    "& .MuiInputBase-root": {
      color: theme.color.labelColorGrafico
    },
    "& .MuiInputBase-input": {
      maxWidth: "85px"
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "0 none"
    },
    "& .MuiSvgIcon-root": {
      color: `${theme.color.secondaryText} !important`
    },
    "& .MuiIconButton-root": {
      display: "none"
    }
  },
  formControl: {
    "& .MuiInputBase-root": {
      backgroundColor: Base.BlueTuna,
      borderRadius: 4,
      height: "36px"
    },
    "& .MuiSelect-icon": {
      color: theme.color.labelColorSelect
    },
    "& option": {
      color: theme.color.labelColorSelect
    },
    "& .MuiSelect-select.MuiSelect-select": {
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      color: theme.color.labelColorSelect
    },
    "& .MuiSelect-outlined.MuiSelect-outlined": {
      padding: "0 40px 0 20px "
    }
  },
  dados: {
    fontFamily: theme.font.family,
    fontSize: "14px",
    marginLeft: "16px",
    marginTop: "30px",
    "& span": {
      fontSize: "20px",
      fontWeight: "bold",
      marginBottom: "15px",
      "& p": {
        display: "inline-block",
        fontSize: "20px"
      }
    }
  },
  containerLegenda: {
    display: "block"
  },
  boxLegenda: {
    width: "100%",
    margin: "-20px 40px 20px auto",
    textAlign: "right",
    color: theme.color.labelColorGrafico
  },
  containerGrafico: {
    textAlign: "right",
    marginTop: "70px"
  },
  noData: {
    textAlign: "center",
    width: "100%",
    minHeight: "200px",
    "& div": {
      margin: "50px auto 0",
      maxWidth: "80%"
    }
  }
}));
