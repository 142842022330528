import RetornoEndpointDto from "../../../../global/dto/retornoEndpointDto";
import { store } from "../../../../global/redux";
import { alertaExibir } from "../../../../global/redux/modulos/alertas/actions";
import { listarPreFaturamentoRastreamento } from "servicos/preFaturamentoServico";

export default class preFaturamentoRastreamentoHelper {

    static async listarPreFaturamentoRastreamento(id) {
        try {
            const resultado = await listarPreFaturamentoRastreamento(id);

            if (resultado.status === 204)
                return RetornoEndpointDto.Erro(
                    "Ocorreu um erro ao obter a lista de rastreamento"
                );

            return RetornoEndpointDto.Sucesso(
                "Rastreamento do Pré-faturamento obtido com sucesso",
                resultado.data
            );
        } catch (error) {
            return RetornoEndpointDto.Erro(
                "Ocorreu um erro ao obter a lista de rastreamento"
            );
        }
    }

    static exibirErro(mensagem) {
        store.dispatch(
            alertaExibir({
                tipo: "danger",
                mensagem
            })
        );
    }

    static exibirSucesso(mensagem) {
        store.dispatch(
            alertaExibir({
                tipo: "success",
                mensagem
            })
        );
    }
}
