import { makeStyles } from '@mui/styles';
import { Base } from "../cores";
import theme from 'themes';

export const useStyles = makeStyles(
  (th) => ({
  root: {
    backgroundColor: ()=> { 
      if(this && this.palette){
      return this.palette.background.paper}
    }
  },
  titleValidation: {
    padding: "20px",
    "text-align": "center"
  },
  container: {
    margin: "10px",
    width: "100%",
    "& .MuiGrid-root": {
      color: theme.color.fourthBackgroud
    },
    "& .MuiDropzoneArea-text": {
      color: theme.color.fourthBackgroud
    },
    "& .MuiInputBase-root": {
      height: "46px"
    },
    "& .MuiFormLabel-root": {
      lineHeight: "1"
    }
  },
  lineHeight: {
    "line-height": "1 !important",
    "&.Mui-focused": {
      color: Base.Mango
    }
  },
  listValues: {
    "max-height": "150px",
    overflow: "auto",
    display: "inline-grid"
  },
  button: {
    fontSize: "18px"
  },
  buttonCustom: {
    fontSize: "18px",
    color: "#FEBB2F !important",
    backgroundColor: "transparent !important",
    "&:hover": {
      color: "#212529"
    }
  },
  botao: {
    background: "none",
    color: "inherit",
    border: "none",
    padding: "0",
    font: "inherit",
    cursor: "pointer",
    outline: "inherit"
  }
}));
