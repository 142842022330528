import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";

// Styles
import { BotaoFiltro, useStyles } from "./style";

function TabelaSemDados({ children, className }) {
  const classes = useStyles();
  return (
    <Grid container>
      <Grid item xs={12} className={`${classes.container} ${className}`}>
        <h4>{children}</h4>
      </Grid>
    </Grid>
  );
}

TabelaSemDados.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};

TabelaSemDados.defaultProps = {
  children: "Nenhum registro encontrado"
};

export default TabelaSemDados;
