import { makeStyles } from '@mui/styles';
import { Base } from "../../../componentes/cores";
import styled from "styled-components";
import theme from 'themes';

export const useStyles = makeStyles({
  container: {
    margin: "0 0 24px",
    width: "100%"
  },
  search: {
    marginTop: "-5px",
    "& .MuiOutlinedInput-input": {
      padding: "13.5px 14px"
    },
    "& label.Mui-focused": {
      color: `${theme.color.secondaryBorderColor} !important`
    },
    "& .MuiInput-underline:after": {
      borderColor: `${theme.color.secondaryBorderColor} !important`
    },
    "& .MuiFormLabel-root": {
      color: `${theme.color.secondaryBorderColor} !important`
    },
    "& .MuiOutlinedInput-root": {
      "& .MuiOutlinedInput-input": {
        color: `${theme.color.secondaryBorderColor} !important`
      },
      "& .MuiInputAdornment-root": {
        color: `${theme.color.secondaryBorderColor} !important`
      },
      "& fieldset": {
        borderColor: `${theme.color.secondaryBorderColor} !important`
      },
      "&:hover fieldset": {
        borderColor: `${theme.color.secondaryBorderColor} !important`
      },
      "&.Mui-focused fieldset": {
        borderColor: `${theme.color.secondaryBorderColor} !important`
      }
    }
  },
  tabela: {
    "& .MuiButton-root": {
      minWidth: "0px",
      "& .MuiSvgIcon-root": {
        fontSize: "20px",
        marginLeft: "2px"
      }
    }
  },
  filtros: {
    display: "flex",
    justifyContent: "flex-end"
  },
  calendario: {
    "& .MuiTextField-root": {
      "& input": {
        padding: "13.5px 0px 13.5px 20px",
        color: Base.White
      }
    },
    "& .MuiFormControl-marginNormal": {
      borderRadius: "4px"
    },
    height: "54px",
    marginRight: "20px",
    "& .MuiIconButton-root": {
      padding: "12px 12px 12px 0px"
    }
  },
  buttonFiltro: {
    padding: "9.5px 5px"
  }
});

export const Icone = styled.img`
  margin-left: 2px;
  max-width: 18px;
  object-fit: cover;
`;
