import React from "react";

// Styles
import { useStyles } from "./style";

const CardItemNotificacao = (props) => {
  const classes = useStyles();

  return (
    <div className="Card">
      <div className="Title" className={classes.tituloCardStyle}>{props.titulo}</div>
      <div className="Content">
        {props.children}
      </div>
    </div>
  );
};

export default CardItemNotificacao;
