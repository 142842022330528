import PropTypes from "prop-types";
import React, { useMemo, useState } from "react";

import {
  CheckBox,
  CheckBoxOutlineBlank,
  EditOutlined,
  GetApp,
  Send
} from "@mui/icons-material";

import { Box, Collapse, Grid, IconButton } from "@mui/material";
import ToogleIcon from "./componentes/toggleIcon";

import { useStyles } from "./style";
import theme from "../../../../../themes";
import enumeradorBotao from "../../enumeradores/enumeradorBotao";

const ItemListaExpansivelResponsiva = ({ color, parametros, onClick }) => {
  const classes = useStyles();
  const [itemAberto, setItemAberto] = useState();

  const onClickEdicao = () => onClick(enumeradorBotao.Edicao, parametros);
  const onClickContrato = () => onClick(enumeradorBotao.Contrato, parametros);
  const onClickPDF = () => onClick(enumeradorBotao.PDF, parametros);
  const onClickParceiro = (tipoAprovacao) =>
    onClick(enumeradorBotao.Parceiro, parametros, tipoAprovacao);

  const exibirAprovaContratoMaster = useMemo(() => {
    return (
      parametros?.usuarioCargoId !== enumeradorBotao.Parceiro &&
      parametros?.cargoId === enumeradorBotao.Parceiro &&
      !parametros?.envelopeId &&
      parametros?.aprovaContratoMaster
    );
  }, [parametros?.aprovaContratoMaster]);

  const exibirAprovaContratoComercial = useMemo(() => {
    return (
      parametros?.usuarioCargoId !== enumeradorBotao.Parceiro &&
      parametros?.cargoId === enumeradorBotao.Parceiro &&
      !parametros?.envelopeId &&
      parametros?.aprovaContratoComercial
    );
  }, [parametros?.aprovaContratoComercial]);

  return (
    <Grid className={classes.boxItem} container>
      <Grid className={classes.gridItem} item xs={2}>
        <Box display="flex" justifyContent="center">
          <ToogleIcon
            aberto={itemAberto}
            color={color}
            onClick={() => setItemAberto(!itemAberto)}
          />
        </Box>
      </Grid>
      <Grid item xs={10}>
        {parametros?.nome && (
          <Grid xs={12} className={classes.label}>
            {parametros?.nome}
          </Grid>
        )}
        {parametros?.cpfCnpj && <Grid xs={12}>{parametros?.cpfCnpj}</Grid>}
        {parametros?.numeroInstalacao && (
          <Grid xs={12}>{parametros?.numeroInstalacao}</Grid>
        )}
        {parametros?.status && (
          <Grid xs={6}>{`Status: ${parametros?.status}`}</Grid>
        )}
        {parametros?.dataValidade && (
          <Grid xs={6}>{`Validade: ${parametros?.dataValidade}`}</Grid>
        )}
        {parametros?.planoSelecionado && (
          <Grid xs={12}>{`Plano: ${parametros?.planoSelecionado}`}</Grid>
        )}
      </Grid>

      <Collapse className="w-100" in={itemAberto}>
        <Box
          display="flex"
          justifyContent="space-around"
          alignItems="center"
          flexWrap="wrap"
        >
          <Box>
            <IconButton
              disabled={parametros?.desabledBtnContrato}
              onClick={onClickContrato}
              className={`${
                parametros?.desabledBtnContrato
                  ? classes.inativo
                  : classes.ativo
              }`}
            >
              <Box display="flex" flexDirection="column">
                <Box>
                  <Send cursor="pointer" htmlColor="#FA3EB1" />
                </Box>
                <Box
                  component="span"
                  fontSize="10px"
                  mt="7px"
                  color="#FA3EB1"
                  fontWeight="600"
                >
                  {parametros?.desabledBtnContrato
                    ? "Sem contrato"
                    : "Reenviar contrato"}
                </Box>
              </Box>
            </IconButton>
          </Box>
          <Box>
            <IconButton onClick={onClickEdicao}>
              <Box display="flex" flexDirection="column">
                <Box>
                  <EditOutlined cursor="pointer" htmlColor="#FA3EB1" />
                </Box>
                <Box
                  component="span"
                  fontSize="10px"
                  mt="7px"
                  color="#FA3EB1"
                  fontWeight="400"
                >
                  Editar
                </Box>
              </Box>
            </IconButton>
          </Box>
          <Box>
            <IconButton
              disabled={parametros?.desabledBtnDownload}
              onClick={onClickPDF}
              className={`${
                parametros?.desabledBtnDownload
                  ? classes.inativo
                  : classes.ativo
              }`}
            >
              <Box display="flex" flexDirection="column">
                <Box>
                  <GetApp
                    className="text-center"
                    cursor="pointer"
                    htmlColor="#FA3EB1"
                  />
                </Box>

                <Box
                  component="span"
                  fontSize="10px"
                  mt="7px"
                  color="#FA3EB1"
                  fontWeight="600"
                >
                  Download
                </Box>
              </Box>
            </IconButton>
          </Box>
          {exibirAprovaContratoComercial && (
            <Box className={classes.fundoBotoes}>
              <IconButton
                onClick={() => onClickParceiro("comercial")}
                size="large"
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                >
                  <Box>
                    {parametros?.liberaPropostaContrato === "S" ? (
                      <CheckBox
                        className="text-center"
                        cursor="pointer"
                        htmlColor="#FA3EB1"
                      />
                    ) : (
                      <CheckBoxOutlineBlank
                        className="text-center"
                        cursor="pointer"
                        htmlColor="#FA3EB1"
                      />
                    )}
                  </Box>

                  <Box
                    component="span"
                    fontSize="10px"
                    mt="7px"
                    fontWeight="600"
                    color="#FA3EB1"
                    alignContent="center"
                  >
                    Comercial
                  </Box>
                </Box>
              </IconButton>
            </Box>
          )}

          {exibirAprovaContratoMaster && (
            <Box className={classes.fundoBotoes}>
              <IconButton
                onClick={() => onClickParceiro("master")}
                size="large"
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                >
                  <Box>
                    {parametros?.liberaPropostaContratoMaster === "S" ? (
                      <CheckBox
                        className="text-center"
                        cursor="pointer"
                        htmlColor="#FA3EB1"
                      />
                    ) : (
                      <CheckBoxOutlineBlank
                        className="text-center"
                        cursor="pointer"
                        htmlColor="#FA3EB1"
                      />
                    )}
                  </Box>

                  <Box
                    component="span"
                    fontSize="10px"
                    mt="7px"
                    fontWeight="600"
                    color="#FA3EB1"
                    alignContent="center"
                  >
                    Master
                  </Box>
                </Box>
              </IconButton>
            </Box>
          )}
        </Box>
      </Collapse>
    </Grid>
  );
};

ItemListaExpansivelResponsiva.propTypes = {
  color: PropTypes.string,
  onClick: PropTypes.oneOfType([PropTypes.func]).isRequired,
  parametros: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
    .isRequired
};

ItemListaExpansivelResponsiva.defaultProps = {
  color: theme.color.primary
};

export default ItemListaExpansivelResponsiva;
