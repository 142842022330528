import React from "react";
import Select from "../Componentes/Select";
import { Celula, CelulaStatus } from "./style";

export default class ColunaFiltro {
  constructor(
    label,
    nome,
    opcoes,
    opcao,
    onSelecionar,
    minWidth = null,
    maxWidth = null
  ) {
    this.name = (
      <Select
        id={nome}
        name={nome}
        label={<strong>{label}</strong>}
        opcao={opcao}
        opcoes={opcoes}
        setOpcao={(item) => {
          onSelecionar(item);
        }}
      />
    );

    const verificarStatus = (params) => {
      if (params?.status)
        return (
          <>
            <span style={{ width: "auto", marginRight: "10px" }}>
              {params?.diasDesdeInicioMigracao}
            </span>
            <CelulaStatus corTexto="#BB032A" corFundo="#FFF0EE">
              {params?.status}
            </CelulaStatus>
          </>
        );

      return (
        <span style={{ width: "auto", marginRight: "10px" }}>
          {params?.diasDesdeInicioMigracao}
        </span>
      );
    };

    this.selector = label;
    this.cell = (row) => <Celula>{verificarStatus(row)}</Celula>;
    this.maxWidth = maxWidth;
    this.minWidth = minWidth;
  }
}
