import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Helmet } from "react-helmet";

// Componentes
import { CssBaseline } from "@mui/material";

// Redux
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./global/redux";

// Bibliotecas
import "./bibliotecas/moment";
import "./bibliotecas/bootstrap";
import "./bibliotecas/clicksign";

// Styles
import GlobalStyle from "./global/globalStyle";

// App
import App from "./App";
import clientData from "./global/clientData";
import getDomain from "global/getDomain";

ReactDOM.render(
  <>
    <CssBaseline />
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <BrowserRouter>
          <Helmet>
            <title>{clientData.document.title}</title>
            <link
              rel="icon"
              href={`../themes/${getDomain()}/assets/favicon.ico`}
            />
            <meta name="description" content={clientData.meta.description} />
          </Helmet>
          <GlobalStyle />
          <App />
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </>,
  document.getElementById("root")
);
