import styled from "styled-components";
import theme from "themes";

export const Button = styled.button`
  background-color: ${(props) =>
    !props.stateless
      ? props.selected
        ? theme.color.bgBotaoSelecionado
        : theme.color.bgBotaoInicial
      : theme.color.bgBotaoSelecionado};
  border-color: ${(props) =>
    !props.stateless
      ? props.selected
        ? theme.color.bgBotaoSelecionado
        : theme.color.btSelTextColor
      : theme.color.bgBotaoSelecionado};
  color: ${(props) => 
    props.selected ? theme.color.labelBotaoSelecionado : theme.color.labelBotaoInicial};
  outline: none;
  width: 100%;
  &:hover {
    background-color: ${(props) => (props.disabled ? '' : theme.color.primary)};
    border-color: ${(props) => (props.disabled ? '' : theme.color.primary)};
    color: ${(props) => (props.disabled ? theme.color.labelBotaoSelecionado : theme.color.labelBotaoSelecionado)};
  }
  &:focus {
    background-color: ${(props) =>
      !props.stateless && props.selected ? theme.color.primary : theme.color.primary};
    border-color: ${(props) =>
      !props.stateless && props.selected ? theme.color.primary : theme.color.primary};
    color: ${(props) =>
      !props.stateless && props.selected ? theme.color.labelBotaoSelecionado : theme.color.labelBotaoSelecionado};
  }
  &:active,
  &.active,
  &:not(:disabled):not(.disabled):active {
    background-color: ${(props) =>
      !props.stateless && props.selected ? theme.color.primary : theme.color.primary};
    border-color: ${(props) =>
      !props.stateless && props.selected ? theme.color.primary : theme.color.primary};
    color: ${(props) =>
      !props.stateless && props.selected ? theme.color.labelBotaoSelecionado : theme.color.labelBotaoSelecionado};
  }
`;
