import { Grid } from "@mui/material";
import React from "react";
import Loader from "../../../../../componentes/loader";

// import { Container } from './styles';

function CarregandoTabela({ className }) {
  return (
    <>
      <Grid container>
        <Grid item xs={12} className={className}>
          <h4>Carregando registros...</h4>
        </Grid>
      </Grid>
    </>
  );
}

export default CarregandoTabela;
