import RetornoEndpointDto from "global/dto/retornoEndpointDto";
import { store } from "global/redux";
import { alertaExibir } from "global/redux/modulos/alertas/actions";
import {
  salvarDocumentosDemandaContratoSocialProcuracao,
  salvarDocumentosDemandaFaturasCCER,
  indicarRepresentanteLegal,
  consultarRepresentanteLegal,
  listarDocumentosDemanda,
  baixarDocumentosDemandaAttend,
  salvarDocumentosDemandaDemonstrativoDRE,
  deleteArquivo
} from "servicos/workflowServico";
import RepresentanteLegalDto from "../../Dto/RepresentanteLegalDto";

export default class RepresentateLegalWorkflowHelper {
  static async salvarArquivosContratoSocialProcuracao(
    arquivosUplaoad,
    protocoloDemanda
  ) {
    try {
      const response = await salvarDocumentosDemandaContratoSocialProcuracao(
        arquivosUplaoad,
        protocoloDemanda
      );

      return RetornoEndpointDto.Sucesso(
        "Arquivos salvos com sucesso!",
        response
      );
    } catch (ex) {
      return RetornoEndpointDto.Erro(
        ex?.response?.data?.message ??
          "Não foi possivel salvar os arquivos do Workflow!"
      );
    }
  }
  static async deleteArquivo(nomeArquivo, protocoloDemanda,tipoArquivoDemanda) {
    try {
      const response = await deleteArquivo(
        nomeArquivo,
        protocoloDemanda,
        tipoArquivoDemanda
        
      );

      return response;
    } catch (ex) {
      return RetornoEndpointDto.Erro(
        ex?.response?.data?.message ??
          "Não foi possivel baixar os arquivos do Workflow!"
      );
    }
  }

  static async baixarArquivosDemanda(nomeArquivo, protocoloDemanda) {
    try {
      const response = await baixarDocumentosDemandaAttend(
        nomeArquivo,
        protocoloDemanda
      );

      return response;
    } catch (ex) {
      return RetornoEndpointDto.Erro(
        ex?.response?.data?.message ??
          "Não foi possivel baixar os arquivos do Workflow!"
      );
    }
  }

  static async salvarArquivosFaturasCCER(arquivosUplaoad, protocoloDemanda) {
    try {
      const response = await salvarDocumentosDemandaFaturasCCER(
        arquivosUplaoad,
        protocoloDemanda
      );

      return RetornoEndpointDto.Sucesso(
        "Arquivos salvos com sucesso!",
        response
      );
    } catch (ex) {
      return RetornoEndpointDto.Erro(
        ex?.response?.data?.message ??
          "Não foi possivel salvar os arquivos do Workflow!"
      );
    }
  }

  static async salvarArquivosDemonstrativoDRE(arquivosUplaoad, protocoloDemanda) {
    try {
      const response = await salvarDocumentosDemandaDemonstrativoDRE(
        arquivosUplaoad,
        protocoloDemanda
      );

      return RetornoEndpointDto.Sucesso(
        "Arquivos salvos com sucesso!",
        response
      );
    } catch (ex) {
      return RetornoEndpointDto.Erro(
        ex?.response?.data?.message ??
          "Não foi possivel salvar os arquivos do Workflow!"
      );
    }
  }

  static async consultarArquivosDemanda(protocoloDemanda) {
    try {
      const response = await listarDocumentosDemanda(protocoloDemanda);

      return RetornoEndpointDto.Sucesso(
        "Arquivos consultados com sucesso!",
        response.data
      );
    } catch (ex) {
      return RetornoEndpointDto.Erro(
        ex?.response?.data?.message ??
          "Não foi possivel consultar os arquivos da demanda!"
      );
    }
  }

  static async indicarRepresentantesLegais(representates, protocoloDemanda) {
    try {
      const response = await indicarRepresentanteLegal(
        representates,
        protocoloDemanda
      );

      return RetornoEndpointDto.Sucesso(
        "Representante legal indicado com sucesso!",
        response.data
      );
    } catch (ex) {
      return RetornoEndpointDto.Erro(
        ex?.response?.data?.message ??
          "Não foi possivel salvar os representantes legais!"
      );
    }
  }

  static async ListarRepresentatesLegais(protocoloDemanda) {
    try {
      const response = await consultarRepresentanteLegal(
        protocoloDemanda?.protocoloDemanda ?? ""
      );

      return RetornoEndpointDto.Sucesso(
        "Representante legal consultado com sucesso!",
        response?.data
      );
    } catch (ex) {
      return RetornoEndpointDto.Erro(
        ex?.response?.data?.message ??
          "Não foi possivel consultar os representantes legais!"
      );
    }
  }

  static exibirErro(mensagem) {
    store.dispatch(
      alertaExibir({
        tipo: "danger",
        mensagem
      })
    );
  }

  static exibirSucesso(mensagem) {
    store.dispatch(
      alertaExibir({
        tipo: "success",
        mensagem
      })
    );
  }
}
