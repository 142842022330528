import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import DataTable from "react-data-table-component";
import { useForm } from "react-hook-form";

// Componentes
import { Card, Grid } from "@mui/material";
import { Base } from "componentes/cores";
import { Loader, BotaoRetornarListagem, Botao } from "componentes";
import ModalInformacao from "componentes/modalVerificacao";
import MaterialInputMascara from "componentes/inputTextoMascara";
import MaterialInputTexto from "componentes/inputTexto/materialInput";
import Coluna from "componentes/tabelaPaginada/colunas/coluna";

// DTO
import { RotasDTO } from "global/rotas/rotasUrlDto";

// Ícones
import SubjectIcon from "@mui/icons-material/Subject";

// Redux
import { store } from "global/redux";
import {
  desabilitarCliente,
  desabilitarEmpresa,
  desabilitarUnidade
} from "../../../global/redux/modulos/usuario/actions";
import { alertaExibir } from "global/redux/modulos/alertas/actions";

// Serviços
import ValidarCotacaoHelper from "../Validar/helper";
import { baixarArquivoFatura } from "servicos/clienteArquivoFaturaService";

// Styles
import { useStyles, customStyles, TabelaSemDados } from "./style";

const ValidarCotacoes = () => {
  const { id } = useParams();
  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    store.dispatch(desabilitarEmpresa(true));
    store.dispatch(desabilitarCliente(true));
    store.dispatch(desabilitarUnidade(true));

    return () => {
      store.dispatch(desabilitarEmpresa(false));
      store.dispatch(desabilitarCliente(false));
      store.dispatch(desabilitarUnidade(false));
    };
  }, []);

  const { register, errors } = useForm({
    reValidateMode: "onSubmit"
  });

  // Dados informados
  const [carregandoDados, setCarregandoDados] = useState();

  const [limparCnpjDados, setLimparCnpjDados] = useState(false);

  const [cnpjDados, setCnpjDados] = useState();
  const [nomeDadosInformados, setNomeDadosInformados] = useState();
  const [distribuidoraDados, setDistribuidoraDados] = useState();
  const [custoMedioDados, setCustoMedioDados] = useState();
  const [consumoMedioDados, setConsumoMedioDados] = useState();
  const [demandaContratadaDados, setDemandaContratadaDados] = useState();

  const [tituloRF, setTituloRF] = useState();

  // Fatura
  const [cnpjFatura, setCnpjFatura] = useState();
  const [nomeFatura, setNomeFatura] = useState();
  const [distribuidoraFatura, setDistribuidoraFatura] = useState();
  const [custoMedioFatura, setCustoMedioFatura] = useState();
  const [consumoMedioFatura, setConsumoMedioFatura] = useState();
  const [demandaContratadaFatura, setDemandaContratadaFatura] = useState();

  // Histórico
  const [custoMedioHistorico, setCustoMedioHistorico] = useState();
  const [consumoMedioHistorico, setConsumoMedioHistorico] = useState();
  const [consumoForaPontaHistorico, setConsumoForaPontaHistorico] = useState();
  const [consumoPontaHistorico, setConsumoPontaHistorico] = useState();
  const [
    demandaContratadaHistorico,
    setDemandaContratadaHistorico
  ] = useState();

  const efetuarDownloadArquivoFatura = async (nomeArquivo) => {
    await baixarArquivoFatura(id, nomeArquivo);
  };

  const obterArquivosFatura = async () => {
    if (id) {
      const lista = await ValidarCotacaoHelper.obterFaturasPorId(id);
      if (lista?.data?.listaClienteArquivoFatura) {
        lista.data.listaClienteArquivoFatura.forEach((item) => {
          efetuarDownloadArquivoFatura(item?.nomeArquivo);
        });
      } else {
        store.dispatch(
          alertaExibir({
            tipo: "warning",
            mensagem: "Fatura(s) não encontrada(s)!"
          })
        );
      }
    }
  };

  const obterTituloRF = async (cnpj) => {
    const resultado = await ValidarCotacaoHelper.obterTituloRF(cnpj);

    if (!resultado.sucesso) {
      ValidarCotacaoHelper.exibirErro(resultado.mensagem);
      return;
    }

    if (resultado.data) {
      setTituloRF(resultado.data?.titulo);
    }
  };

  const obterCotacao = useCallback(async () => {
    if (!id) return;

    setCarregandoDados(true);

    const resultado = await ValidarCotacaoHelper.obterCotacaoPorId(id);

    if (!resultado.sucesso) {
      ValidarCotacaoHelper.exibirErro(resultado.mensagem);
      setCarregandoDados(false);
      history.push(RotasDTO.ValidarCotacoes);
      return;
    }

    if (resultado.data) {
      setCnpjDados(resultado?.data?.validarCotacoes?.cnpj);
      setNomeDadosInformados(resultado?.data?.validarCotacoes?.nomeFantasia);
      setDistribuidoraDados(resultado?.data?.validarCotacoes?.distribuidora);
      setCustoMedioDados(resultado?.data?.validarCotacoes?.consumoMedioConta);
      setConsumoMedioDados(resultado?.data?.validarCotacoes?.valorMedioConta);
      setDemandaContratadaDados(
        resultado?.data?.validarCotacoes?.demandaContratada
      );

      obterTituloRF(resultado?.data?.validarCotacoes?.cnpj);

      if (!resultado?.data?.fatura) {
        ValidarCotacaoHelper.exibirErro("Dados de fatura não encontrados");
      } else {
        setCnpjFatura(resultado?.data?.fatura?.cnpj);
        setNomeFatura(resultado?.data?.fatura?.nome);
        setDistribuidoraFatura(resultado?.data?.fatura?.fornecedor);
        setCustoMedioFatura(resultado?.data?.fatura?.custoMedio);
        setConsumoMedioFatura(resultado?.data?.fatura?.consumoMedio);
        setDemandaContratadaFatura(resultado?.data?.fatura?.demandaContratada);
      }
    }
    setCarregandoDados(false);
  }, [id]);

  useEffect(() => {
    obterCotacao();
  }, [obterCotacao]);

  // 15.000,00 -> 150000.00
  // 0,750 -> 0.75
  function formatarValorSalvar(valor) {
    return valor
      ? parseFloat(
          String(valor)
            ?.replace(/[^0-9,.]/g, "")
            ?.replace(".", "")
            ?.replace(",", ".")
            ?.trim()
        )
      : valor;
  }

  const onClickVerFatura = (event) => {
    obterArquivosFatura();
    event.preventDefault();
  };

  const onClickGerarNovaCotacao = async () => {
    const dados = {
      CotacaoId: id,
      ConsumoMedioConta: consumoMedioHistorico ?? 0,
      ConsumoForaPonta: consumoForaPontaHistorico ?? 0,
      ConsumoPonta: consumoPontaHistorico ?? 0,
      DemandaContratada: demandaContratadaHistorico ?? 0,
      ValorMedioConta: custoMedioHistorico ?? 0,
      TipoCalculo: custoMedioHistorico ? 2 : 1
    };
    const salvou = await ValidarCotacaoHelper.GerarNovaCotacao(dados);
    if (salvou?.sucesso) {
      ValidarCotacaoHelper.exibirSucesso(salvou.mensagem);
      setTimeout(() => {
        history.push(RotasDTO.ValidarCotacoes);
      }, 2000);
    } else {
      ValidarCotacaoHelper.exibirErro(salvou.mensagem);
    }
  };

  const onClickRejeitar = async () => {
    const salvou = await ValidarCotacaoHelper.AtualizaSituacao(id, "RE");
    if (salvou?.sucesso) {
      ValidarCotacaoHelper.exibirSucesso(salvou.mensagem);
      setTimeout(() => {
        history.push(RotasDTO.ValidarCotacoes);
      }, 2000);
    } else {
      ValidarCotacaoHelper.exibirErro(salvou.mensagem);
    }
  };

  const onClickValidar = async () => {
    const salvou = await ValidarCotacaoHelper.AtualizaSituacao(id, "VA");
    if (salvou?.sucesso) {
      ValidarCotacaoHelper.exibirSucesso(salvou.mensagem);
      setTimeout(() => {
        history.push(RotasDTO.ValidarCotacoes);
      }, 2000);
    } else {
      ValidarCotacaoHelper.exibirErro(salvou.mensagem);
    }
  };

  const [limparCusto, setLimparCusto] = useState();
  const [limparConsumo, setLimparConsumo] = useState();
  const [podeGerarNovaCotacao, setPodeGerarNovaCotacao] = useState(false);

  useEffect(() => {
    if (
      (custoMedioHistorico || (consumoForaPontaHistorico && consumoPontaHistorico)) &&
      demandaContratadaHistorico
    ) {
      setPodeGerarNovaCotacao(true);
    } else {
      setPodeGerarNovaCotacao(false);
    }
  }, [custoMedioHistorico, consumoForaPontaHistorico, consumoPontaHistorico, demandaContratadaHistorico]);

  useEffect(() => {
    if (custoMedioHistorico && (consumoForaPontaHistorico && consumoPontaHistorico)) {
      setConsumoForaPontaHistorico(0);
      setConsumoPontaHistorico(0);
      setLimparConsumo(true);
    }
  }, [custoMedioHistorico]);

  useEffect(() => {
    if ((consumoForaPontaHistorico && consumoPontaHistorico) && custoMedioHistorico) {
      setCustoMedioHistorico(0);
      setLimparCusto(true);
    }
  }, [consumoForaPontaHistorico, consumoPontaHistorico]);

  const colunas = [
    new Coluna("data", "Data", true, "50%", "50%"),
    new Coluna("value", "Consumo (MWh)", true, "50%", "50%")
  ];

  const [exibirModalHistorico, setExibirModalHistorico] = useState(false);
  const [objetoDadosHistorico, setObjetoDadosHistorico] = useState([]);

  const onClickHistoricoConsumo = (event) => {
    event.preventDefault();
    setExibirModalHistorico(true);
  };

  const obterHistorico = async () => {
    try {
      const lista = await ValidarCotacaoHelper.obterHistorico(id);
      if (lista?.sucesso && lista?.data) {
        setObjetoDadosHistorico(lista?.data?.dadosHistorico);
      } else {
        ValidarCotacaoHelper.exibirErro(lista.mensagem);
      }
    } catch (error) {
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro ao recuperar os dados de histórico"
        })
      );
    }
  };

  useEffect(() => {
    obterHistorico();
  }, []);

  const ModalHistorico = () => {
    return (
      <>
        <Grid container spacing={2} className={classes.container}>
          <Grid item xl={12} lg={12} className="font-weight-bold">
            Histórico de consumo
          </Grid>
        </Grid>
        <Grid container spacing={2} className={classes.container}>
          <Grid item xl={12} lg={12} className="font-weight-bold">
            <DataTable
              columns={colunas}
              noHeader
              persistTableHead
              keyField="id"
              data={objetoDadosHistorico}
              theme="DeltaEnergiaDemo"
              customStyles={customStyles}
              noDataComponent={
                <TabelaSemDados>Sem dados de histórico</TabelaSemDados>
              }
            />
          </Grid>
        </Grid>
      </>
    );
  };

  return (
    <Loader loading={carregandoDados}>
      <ModalInformacao
        telaInteira
        exibir={exibirModalHistorico}
        mensagem={<ModalHistorico />}
        labelBotao="Confirmar"
        onClose={() => {
          setExibirModalHistorico(false);
        }}
        showBotaoLink
        showBotao={false}
      />
      <Card className={classes.container}>
        <Grid container spacing={4} className={classes.container}>
          <Grid item xl={6} lg={6} className="font-weight-bold">
            {tituloRF !== "" ? tituloRF : "Comparar dados"}
          </Grid>
          <BotaoRetornarListagem
            urlListagem={RotasDTO.ValidarCotacoes}
            zerarCombo
          />
        </Grid>
        <Grid container spacing={4} className={classes.container}>
          <Grid item xl={4} lg={4} md={6} sm={12}>
            <Card className={`${classes.cardFormulario} shadow-none`}>
              <div className="mb-3">Dados informados</div>
              <Grid className="mb-4" item xl={12} lg={12} md={12} sm={12}>
                <MaterialInputMascara
                  type="text"
                  id="cnpjDadosInformados"
                  name="cnpjDadosInformados"
                  label="CNPJ"
                  mask="##.###.###/####-##"
                  limparValor={limparCnpjDados}
                  redefineLimpar={(estado) => setLimparCnpjDados(estado)}
                  renderIconShowHide={false}
                  defaultValue={cnpjDados}
                  readOnly
                />
              </Grid>
              <Grid className="mb-4" item xl={12} lg={12} md={12} sm={12}>
                <MaterialInputTexto
                  type="text"
                  id="nomeDadosInformados"
                  name="nomeDadosInformados"
                  permiteValorBranco
                  defaultValue={nomeDadosInformados}
                  label="Nome"
                  renderIconShowHide={false}
                  readOnly
                />
              </Grid>
              <Grid className="mb-4" item xl={12} lg={12} md={12} sm={12}>
                <MaterialInputTexto
                  type="text"
                  id="distribuidora"
                  name="distribuidora"
                  defaultValue={distribuidoraDados}
                  label="Distribuidora"
                  readOnly
                  renderIconShowHide={false}
                />
              </Grid>
              <Grid className="mb-4" item xl={12} lg={12} md={12} sm={12}>
                <MaterialInputMascara
                  type="text"
                  id="custoMedio"
                  name="custoMedio"
                  prefixo="R$ "
                  label="Custo médio"
                  defaultValue={consumoMedioDados}
                  renderIconShowHide={false}
                  onBlur={(e) =>
                    setCustoMedioDados(formatarValorSalvar(e.target.value))
                  }
                 
                  readOnly
                />
              </Grid>
              <Grid className="mb-4" item xl={12} lg={12} md={12} sm={12}>
                <MaterialInputMascara
                  type="text"
                  id="consumoMedio"
                  name="consumoMedio"
                  label="Consumo médio"
                  defaultValue={custoMedioDados}
                  renderIconShowHide={false}
                  onBlur={(e) =>
                    setConsumoMedioDados(formatarValorSalvar(e.target.value))

                  }
                  sufixo=" MWh"
                  readOnly
                />
              </Grid>
              <Grid className="mb-4" item xl={12} lg={12} md={12} sm={12}>
                <MaterialInputMascara
                  type="text"
                  id="demandaContratada"
                  name="demandaContratada"
                  label="Demanda contratada"
                  defaultValue={demandaContratadaDados}
                  renderIconShowHide={false}
                  onBlur={(e) =>
                    setDemandaContratadaDados(
                      formatarValorSalvar(e.target.value)
                    )
                  }
                  sufixo=" KW"
                  readOnly
                />
              </Grid>
              <Grid item xl={12} lg={12} md={12} sm={12}>
                <Botao
                  type="submit"
                  label="Validar"
                  className={classes.button}
                  onClick={(event) => onClickValidar(event)}
                />
              </Grid>
            </Card>
          </Grid>
          <Grid item xl={4} lg={4} md={6} sm={12}>
            <Card className={`${classes.cardFormulario} shadow-none`}>
              <div className="mb-3">Dados fatura</div>
              <Grid className="mb-4" item xl={12} lg={12} md={12} sm={12}>
                <MaterialInputMascara
                  type="text"
                  id="cnpjDadosInformados"
                  name="cnpjDadosInformados"
                  label="CNPJ"
                  mask="##.###.###/####-##"
                  renderIconShowHide={false}
                  defaultValue={cnpjFatura}
                  readOnly
                />
              </Grid>
              <Grid className="mb-4" item xl={12} lg={12} md={12} sm={12}>
                <MaterialInputTexto
                  type="text"
                  id="nomeDadosInformados"
                  name="nomeDadosInformados"
                  defaultValue={nomeFatura}
                  label="Nome"
                  renderIconShowHide={false}
                  readOnly
                />
              </Grid>
              <Grid className="mb-4" item xl={12} lg={12} md={12} sm={12}>
                <MaterialInputTexto
                  type="text"
                  id="distribuidora"
                  name="distribuidora"
                  defaultValue={distribuidoraFatura}
                  label="Distribuidora"
                  renderIconShowHide={false}
                  readOnly
                />
              </Grid>
              <Grid className="mb-4" item xl={12} lg={12} md={12} sm={12}>
                <MaterialInputMascara
                  type="text"
                  id="custoMedio"
                  name="custoMedio"
                  permiteValorBranco
                  prefixo="R$ "
                  defaultValue={custoMedioFatura}
                  onBlur={(e) =>
                    setCustoMedioFatura(formatarValorSalvar(e.target.value))
                  }
                  label="Custo médio"
                  renderIconShowHide={false}
                  readOnly
                />
              </Grid>
              <Grid className="mb-4" item xl={12} lg={12} md={12} sm={12}>
                <MaterialInputMascara
                  type="text"
                  id="consumoMedio"
                  name="consumoMedio"
                  permiteValorBranco
                  defaultValue={consumoMedioFatura/1000}
                  onBlur={(e) =>
                    setConsumoMedioFatura(formatarValorSalvar((e.target.value*1000)))
                  }
                  sufixo=" MWh"
                  label="Consumo médio"
                  renderIconShowHide={false}
                  readOnly
                />
              </Grid>
              <Grid className="mb-4" item xl={12} lg={12} md={12} sm={12}>
                <MaterialInputMascara
                  type="text"
                  id="demandaContratada"
                  name="demandaContratada"
                  permiteValorBranco
                  defaultValue={demandaContratadaFatura}
                  onBlur={(e) =>
                    setDemandaContratadaFatura(
                      formatarValorSalvar(e.target.value)
                    )
                  }
                  sufixo=" KW"
                  label="Demanda contratada"
                  renderIconShowHide={false}
                  readOnly
                />
              </Grid>
              <Grid item xl={12} lg={12} md={12} sm={12}>
                <Botao
                  type="submit"
                  label="Ver fatura"
                  className={classes.button}
                  onClick={(event) => onClickVerFatura(event)}
                  icon={<SubjectIcon />}
                />
              </Grid>
            </Card>
          </Grid>
          <Grid item xl={4} lg={4} md={6} sm={12}>
            <Card className={`${classes.cardFormulario} shadow-none`}>
              <div className="mb-4">Ajustar dados</div>
              <Grid className="mb-4" item xl={12} lg={12} md={12} sm={12}>
                Ajuste os dados caso haja alguma divergênncia entre os campos
                abaixo:
              </Grid>
              <Grid className="mb-4" item xl={12} lg={12} md={12} sm={12}>
                <Botao
                  type="submit"
                  label="Histórico de consumo"
                  className={classes.buttonHistorico}
                  onClick={(event) => onClickHistoricoConsumo(event)}
                  bgColor={Base.OffWhite}
                />
              </Grid>
              <Grid
                className={`mb-4 d-block ${classes.gridEmpty}`}
                item
                xl={12}
                lg={12}
                md={12}
                sm={12}
              />
              <Grid className="mb-4" item xl={12} lg={12} md={12} sm={12}>
                <MaterialInputMascara
                  type="text"
                  id="custoMedioHistorico"
                  name="custoMedioHistorico"
                  prefixo="R$ "
                  label="Custo médio"
                  defaultValue={custoMedioHistorico}
                  renderIconShowHide={false}
                  onBlur={(e) =>
                    setCustoMedioHistorico(formatarValorSalvar(e.target.value))
                  }
                  limparValor={limparCusto}
                  redefineLimpar={(estado) => setLimparCusto(estado)}
                  ref={register({
                    required: "Campo Custo médio é obrigatório!",
                    maxLength: {
                      value: 100,
                      message: "Quantidade máxima de 100 caracteres!"
                    }
                  })}
                  errors={errors}
                />
              </Grid>
              <Grid container spacing={2}>
              <Grid className="mb-4" item xl={6} lg={6} md={6} sm={6}>
                <MaterialInputMascara
                  type="text"
                  id="consumoForaPontaHistorico"
                  name="consumoForaPontaHistorico"
                  label="Consumo Fora Ponta"
                  defaultValue={consumoForaPontaHistorico}
                  renderIconShowHide={false}
                  onBlur={(e) =>
                    setConsumoForaPontaHistorico(
                      formatarValorSalvar(e.target.value)
                    )
                  }
                  sufixo=" MWh"
                  limparValor={limparConsumo}
                  redefineLimpar={(estado) => setLimparConsumo(estado)}
                  ref={register({
                    required: "Campo Consumo Fora Ponta é obrigatório!",
                    maxLength: {
                      value: 100,
                      message: "Quantidade máxima de 100 caracteres!"
                    }
                  })}
                  errors={errors}
                />
              </Grid>
              <Grid className="mb-4" item xl={6} lg={6} md={6} sm={6}>
                <MaterialInputMascara
                  type="text"
                  id="consumoPontaHistorico"
                  name="consumoPontaHistorico"
                  label="Consumo Ponta"
                  defaultValue={consumoPontaHistorico}
                  renderIconShowHide={false}
                  onBlur={(e) =>
                    setConsumoPontaHistorico(
                      formatarValorSalvar(e.target.value)
                    )
                  }
                  sufixo=" MWh"
                  limparValor={limparConsumo}
                  redefineLimpar={(estado) => setLimparConsumo(estado)}
                  ref={register({
                    required: "Campo Consumo Ponta é obrigatório!",
                    maxLength: {
                      value: 100,
                      message: "Quantidade máxima de 100 caracteres!"
                    }
                  })}
                  errors={errors}
                />
              </Grid>
              </Grid>
              <Grid className="mb-4" item xl={12} lg={12} md={12} sm={12}>
                <MaterialInputMascara
                  type="text"
                  id="demandaContratadaHistorico"
                  name="demandaContratadaHistorico"
                  label="Demanda contratada"
                  defaultValue={demandaContratadaHistorico}
                  renderIconShowHide={false}
                  onBlur={(e) =>
                    setDemandaContratadaHistorico(
                      formatarValorSalvar(e.target.value)
                    )
                  }
                  sufixo=" KW"
                  ref={register({
                    required: "Campo Demanda contratada é obrigatório!",
                    maxLength: {
                      value: 100,
                      message: "Quantidade máxima de 100 caracteres!"
                    }
                  })}
                  errors={errors}
                />
              </Grid>
              <Grid item xl={12} lg={12} md={12} sm={12}>
                <Botao
                  type="submit"
                  label="Gerar nova cotação"
                  className={classes.button}
                  onClick={(event) => onClickGerarNovaCotacao(event)}
                  disabled={!podeGerarNovaCotacao}
                />
              </Grid>
            </Card>
          </Grid>
          <Grid item xl={3} lg={3} sm={12}>
            <Botao
              type="submit"
              label="Rejeitar"
              className={classes.button}
              onClick={(event) => onClickRejeitar(event)}
            />
          </Grid>
        </Grid>
      </Card>
    </Loader>
  );
};

export default ValidarCotacoes;
