import { makeStyles } from '@mui/styles';
import { Base } from "../cores";
import theme from "themes";

export const useStyles = makeStyles(() => ({
  paginacaoContainer: {
    textAlign: "center",
    "& nav ul li button": {
      color: `${theme.color.secondaryBorderColor} !important`
    },
    "& nav ul li button:hover": {
      color: `${Base.OffWhite} !important`,
      backgroundColor: theme.color.primary
    },
    "& nav ul li div": {
      color: `${theme.color.secondaryBorderColor} !important`
    },
    "& .MuiPaginationItem-outlinedPrimary.Mui-selected": {
      backgroundColor: theme.color.primaryOpacity,
      border: theme.color.primary,
      color: `${theme.color.secondaryBorderColor} !important`
    },
    "& .MuiPaginationItem-outlinedPrimary.Mui-selected:hover": {
      backgroundColor: theme.color.primary
    },
    "& .MuiPaginationItem-page.Mui-selected:hover": {
      backgroundColor: theme.color.primary,
      color: `${Base.OffWhite} !important`
    }
  }
}));
