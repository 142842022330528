import { makeStyles } from '@mui/styles';
import { Base } from "../../../../../componentes/cores";
import theme from 'themes';

export const useStyles = makeStyles({
  label: {
    color: theme.color.textoBarra,
    fontFamily: theme.font.family,
    fontSize: "18px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "-0.4px"
  },
  botao: {
    color: Base.Preto,
    padding: "10px",
    fontFamily: theme.font.family,
    fontSize: "18px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "-0.4px",
    textAlign: "center"
  },
  container: {
    margin: "1.5rem"
  },
  containerBotao: {
    marginTop: "30%",
  },
  botaoVoltar: {
    borderRadius: "25px",
    padding: "10px",
    fontFamily: theme.font.family,
    fontSize: "18px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "-0.4px",
    textAlign: "center"
  }
});
