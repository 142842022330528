import React, { useState, useEffect } from "react";
import shortid from "shortid";

// Redux
import { alertaExibir } from "../../../global/redux/modulos/alertas/actions";
import { store } from "../../../global/redux";
import {
  desabilitarFiltroLateral,
  salvarNomeArquivoMedicao
} from "../../../global/redux/modulos/usuario/actions";
import { useSelector } from "react-redux";

// Componentes
import { Grid } from "@mui/material";
import UploadDropArea from "../../../componentes/upload/index";

// Serviços
import {
  uploadMedicao,
  getUploadProgress
} from "../../../servicos/uploadServico";

const MedicaoUpload = () => {
  const usuarioGlobal = useSelector((state) => state.usuario);

  const [progress, setProgress] = useState(0);
  const [carregandoArquivo, setCarregandoArquivo] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  const getProgress = (nomeArquivo, auxProgress) => {
    setCarregandoArquivo(true);
    setShowLoader(true);

    if (auxProgress >= 100) {
      setShowLoader(false);
      setCarregandoArquivo(false);
    }

    const timer = setInterval(() => {
      const response = getUploadProgress(nomeArquivo);
      response.then((res) => {
        setProgress((prevProgress) => (res === 0 ? prevProgress : res));
        auxProgress = res;
      });

      if (auxProgress >= 100) {
        clearInterval(timer);
        setShowLoader(false);
        setCarregandoArquivo(false);
      }
    }, 5000);
  };

  const fazerUpload = async (arquivo, nomeArquivo) => {
    try {
      const upload = await uploadMedicao(arquivo);

      if (upload?.status === 200 || upload?.status === 204) {
        store.dispatch(
          alertaExibir({
            tipo: "success",
            mensagem: `O arquivo ${nomeArquivo} foi processado com sucesso!`
          })
        );
      }
    } catch (error) {
      setCarregandoArquivo(false);
      store.dispatch(
        alertaExibir({
          tipo: "warning",
          mensagem:
            error?.response?.data?.message ??
            "Erro interno, entre em contato com o suporte!"
        })
      );
    }

    setProgress(100);

    const ocultarBarra = setInterval(() => {
      setShowLoader(false);
      setCarregandoArquivo(false);
      clearInterval(ocultarBarra);
    }, 3000);
  };

  const processarArquivo = (valor) => {
    const arquivo = valor[0];

    if (arquivo)
      store.dispatch(
        alertaExibir({
          tipo: "success",
          mensagem: `O arquivo ${arquivo.file.name} foi enviado para processamento!`
        })
      );

    var blob = arquivo.file.slice(0, arquivo.file.size, arquivo.file.type);
    var newFile = new File([blob], arquivo.file.name, {
      type: arquivo.file.type
    });
    setProgress(0);
    fazerUpload(newFile, arquivo.file.name);
    getProgress(newFile.name, 0);

    store.dispatch(salvarNomeArquivoMedicao(newFile.name));
  };

  const verificarNivelUpload = async (nomeArquivoMedicao) => {
    const checkUpload = await getUploadProgress(nomeArquivoMedicao);
    if (checkUpload && checkUpload != 0) {
      getProgress(nomeArquivoMedicao, checkUpload);
      if (checkUpload != 0) setProgress(checkUpload);
    }
  };

  useEffect(() => {
    if (usuarioGlobal.nomeArquivoMedicao) {
      verificarNivelUpload(usuarioGlobal.nomeArquivoMedicao);
    }
  }, []);

  const onAddUpload = (valor) => {
    processarArquivo(valor);
  };

  const onDropRejected = (valor) => {
    const arquivo = valor[0];
    store.dispatch(
      alertaExibir({
        tipo: "warning",
        mensagem: `Tipo do arquivo ${arquivo.name} não é permitido!`
      })
    );
  };

  useEffect(() => {
    store.dispatch(desabilitarFiltroLateral(true));

    return () => {
      store.dispatch(desabilitarFiltroLateral(false));
    };
  }, [desabilitarFiltroLateral]);

  return (
    <Grid container spacing={3} alignItems="center">
      <Grid item xs={12}>
        <UploadDropArea
          loader={carregandoArquivo}
          onAdd={onAddUpload}
          progressValue={progress}
          showLoader={showLoader}
          onDropRejected={onDropRejected}
          acceptedFiles={[
            '.xlsm,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
          ]}
          
        />
      </Grid>
    </Grid>
  );
};

export default MedicaoUpload;
