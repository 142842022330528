import { makeStyles } from '@mui/styles';
import theme from "themes";
import { Base } from "../../../../componentes/cores";

export const useStyles = makeStyles(() => ({
  tabelaPaginada: {
    display: "flex",
    height: "100%",
    width: "100%",
    "& ::-webkit-scrollbar:horizontal": {
      height: "8px"
    }
  },
  tabelaPrecosCelula: {
    paddingLeft: "10px",
    paddingRight: "10px"
  }
}));

export const customStylesMedia = {
  table: {
    style: {
      background: "transparent"
    }
  },
  headRow: {
    style: {
      background: "transparent",
      marginBottom: "10px"
    }
  },
  headCells: {
    style: {
      background: Base.White,
      fontSize: "16px",
      width: "100%",
      padding: "0",
      textAlign: "center",
      "& .rdt_TableCol_Sortable": {
        width: "100%",
        "& div": {
          width: "100%"
        }
      }
    }
  },
  rows: {
    style: {
      background: "transparent",
      marginBottom: "10px"
    }
  },
  cells: {
    style: {
      background: theme.color.primary,
      color: Base.White,
      fontSize: "14px",
      paddingLeft: "10px",
      paddingRight: "10px",
      textAlign: "center",
      "& div": {
        width: "100%"
      }
    }
  }
};
