import React, { useEffect, useState } from "react";

// Redux
import { Grid } from "@mui/material";
import { store } from "../../../global/redux";
import { alertaExibir } from "../../../global/redux/modulos/alertas/actions";

// Componentes
import UploadDropArea from "../../../componentes/upload/index";

// Serviços
import { desabilitarFiltroLateral } from "../../../global/redux/modulos/usuario/actions";
import UploadCorreiosHelper from "./helper";
import CustomLoader from "componentes/customLoader";
import { handleLoading } from "global/redux/modulos/loader/actions";
import { useDispatch } from "react-redux";

const CorreiosUpload = () => {

  const dispatch = useDispatch();

  const processarArquivos = async (arquivos) => {
    if (arquivos && arquivos?.length > 0) {
      dispatch(handleLoading(true));
      UploadCorreiosHelper.exibirSucesso(`Arquivos enviados para processamento!`);

        const result = await UploadCorreiosHelper.enviarArquivos(arquivos);

       if (!result.sucesso) {
        dispatch(handleLoading(false));
        UploadCorreiosHelper.exibirErro(result.mensagem);
        return false;
      }
      dispatch(handleLoading(false));
    }
  };

  const onAddUpload = (arquivos) => {
    processarArquivos(arquivos);
  };

  const onDropRejected = (valor) => {
    const arquivo = valor[0];
    store.dispatch(
      alertaExibir({
        tipo: "warning",
        mensagem: `Tipo do arquivo ${arquivo.name} não é permitido!`
      })
    );
  };

  useEffect(() => {
    store.dispatch(desabilitarFiltroLateral(true));

    return () => {
      store.dispatch(desabilitarFiltroLateral(false));
    };
  }, [desabilitarFiltroLateral]);

  return (
    <Grid container spacing={3} alignItems="center">
      <CustomLoader>
        <Grid item xs={12}>

        {/* <UploadDropAreaMultiplo
          imageOrPdfValidate={false}
          onAdd={onAddUpload}
          onDropRejected={onDropRejected}
          acceptedFiles={["text/plain", "application/x-zip-compressed"]}
          filesLimit={3}
          renderizaComponente={true}
          showPreviews={false}
          showLoader={false}
          // onPreviewChipClick={(nomeArquivo) =>
          //   onPreviewChipClick(nomeArquivo)
          // }
        /> */}

        <UploadDropArea
          onAdd={onAddUpload}
          limit={3}
          acceptedFiles={["text/plain", "application/x-zip-compressed"]}
          showLoader={false}
          onDropRejected={onDropRejected}
        />
        </Grid>
      </CustomLoader>
    </Grid>
  );
};

export default CorreiosUpload;
