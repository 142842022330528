import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

// Componentes
import Modal from "@mui/material/Modal";
import { Grid } from "@mui/material";
import Botao from "../botao";
import theme from 'themes';

// Styles
import { useStyles } from "./style";
import { Base } from "../cores";

const ModalConfirmacao = ({ labelBotaoConfirmar, item, onclose, onConfirmar, onCancelar, mensagem, esconderBotaoConfirmar, esconderBotaoCancelar }) => {
  const classes = useStyles();
  const [aberto, setAberto] = useState(false);

  useEffect(() => {
    setAberto(Boolean(item));
  }, [item]);

  const onClose = () => {
    setAberto(false);
    onCancelar();
    onclose();
  };

  return (
    <Modal open={aberto} onClose={onClose}>
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        className={classes.container}
        p={2}
        spacing={2}
      >
        <Grid item xs={12}>
          {mensagem}
        </Grid>
        {
          esconderBotaoConfirmar ?
            <></>
            :
            <Grid item xs={6} lg={2}>
              <Botao
                color={Base.GreenFern}
                onClick={() => onConfirmar(item)}
                label={labelBotaoConfirmar}
                className={classes.button}
              />
            </Grid>
        }
        {
          esconderBotaoCancelar ?
            <></>
            :
            <Grid item xs={6} lg={2}>
              <Botao
                color={Base.RedAmaranth}
                onClick={onClose}
                textColor={theme.color.botaoTextColor}
                label="Cancelar"
                className={classes.button}
              />
            </Grid>
        }
      </Grid>
    </Modal>
  );
};

ModalConfirmacao.propTypes = {
  labelBotaoConfirmar: PropTypes.string,
  item: PropTypes.oneOfType([PropTypes.any]).isRequired,
  onclose: PropTypes.oneOfType([PropTypes.func]),
  onConfirmar: PropTypes.oneOfType([PropTypes.func]),
  onCancelar: PropTypes.oneOfType([PropTypes.func]),
  mensagem: PropTypes.oneOfType([PropTypes.node, PropTypes.any]),
  esconderBotaoCancelar: PropTypes.bool,
  esconderBotaoCancelar: PropTypes.bool
};

ModalConfirmacao.defaultProps = {
  labelBotaoConfirmar: 'Confirmar',
  onConfirmar: () => { },
  onCancelar: () => { },
  onclose: () => { },
  mensagem: {},
  esconderBotaoConfirmar: false,
  esconderBotaoCancelar: false
};

export default ModalConfirmacao;
