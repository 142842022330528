import React from "react";
import PropTypes from "prop-types";
import { Wrapper,   useStyles} from "./style";
import DeltaGif from "../../../../assets/cotacoes/loading-delta.gif";
import LuzGif from "../../../../assets/cotacoes/loading-luz.gif";
import { mergeClasses } from "@mui/styles";
import getDomain from "global/getDomain";

const Loader = ({ className, msgLoading }) => {
  const classes = useStyles();
  return (
    <Wrapper className={`position-relative ${className}`}>
        <div className={classes.containerLoader}>
        <img src={getDomain() === 'luz' ? LuzGif : DeltaGif} className={classes.imagemLoader}/>
          <h4 className={classes.msgLoading}>{msgLoading}</h4>
        </div>
    </Wrapper>
  );
};

Loader.propTypes = {
  children: PropTypes.oneOfType([PropTypes.any]),
  className: PropTypes.string,
  msgLoading: PropTypes.string,
  loading: PropTypes.bool,
  dark: PropTypes.bool
};

Loader.defaultProps = {
  children: () => {},
  className: "",
  msgLoading: "",
  loading: false,
  dark: true
};

export default Loader;
